import React, { useState } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from '../shared_ui_components';

export default function LogOutDialog(props) {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        className={' dsp-dialogs logOutDialog'}
      >
        <DialogTitle className="modal-title" id="scroll-dialog-title">
          <h3 className="heading4 textCenter">{props.text}</h3>
        </DialogTitle>
        <CloseIcon className="dialog-close" onClick={() => props.handleCancel(false)} />
        <DialogContent>
          <Grid container justify="flex-end" direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} className="text-right">
              <List className="listInline">
                <ListItem className="listInlineItem">
                  <CustomButton
                    children={`No`}
                    className={`secondaryBtn`}
                    onClick={() => props.handleCancel(false)}
                  />
                </ListItem>
                <ListItem className="listInlineItem">
                  <CustomButton
                    children={loader ? 'Please wait...' : 'Yes'}
                    className={`primaryBtn ${loader ? 'disabledSubmit' : ''}`}
                    onClick={(e) => {
                      setLoader(true);
                      props.handleSuccess(e);
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { InputText, CustomButton, CustomTooltipWhite } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';

export default function IntegrateProviders(props) {
  const { currentIntegration, connectLoader, connectLoaderSet, handleConnent } = props;
  const [open, setOpen] = useState(false);
  const [token, tokenSet] = useState('');
  const [error, errorSet] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  useEffect(() => {
    setOpen(props.open);
    // if(currentIntegration.status === 1){
    //   tokenSet(currentIntegration.auth_key);
    // }
    errorSet(false);
    tokenSet('');
  }, [props.open]);

  const handleSubmit = () => {
    connectLoaderSet(true);
    if (token === '') {
      connectLoaderSet(false);
      errorSet(true);
    } else {
      errorSet(false);
      handleConnent(token);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.integrateWithGreenHouseDialog}`}
      >
        <DialogTitle
          id="add-video-title"
          className={styles.dialogTitle + ' backgroundProp'}
          style={{ backgroundImage: `url(${imageBasePath + 'integration_bg.png'})` }}
        >
          <List className="listInline flexCentered">
            <ListItem className="listInlineItem">
              <img src={currentIntegration.logo} alt={currentIntegration.name} />
            </ListItem>
            {/*
                      <ListItem className="listInlineItem">
                        <img src={imageBasePath+'jazzhr.svg'} alt="Jazz hr" />
                      </ListItem>
                    */}
            <ListItem className="listInlineItem">
              <img
                className={styles.integrateImg}
                src={imageBasePath + 'integration.svg'}
                alt="Integration"
              />
            </ListItem>
            <ListItem className="listInlineItem">
              <img src={imageBasePath + 'salestable_logo.svg'} alt="Salestable" />
            </ListItem>
          </List>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogBody + ' textCenter'}>
            <h1 className="heading2">Integrate with {currentIntegration.name}</h1>
            <p className="paragraph">
              Enter the Authorization token below to connect with {currentIntegration.name} and pull
              the insights of a particular job role tracked
            </p>
            <h2>
              <CustomTooltipWhite
                tooltipIcon="info_icon.svg"
                title={
                  <p className="supportText">
                    Job ID: <span style={{ color: '#193448' }}>1324I35139</span>
                  </p>
                }
              />
              <span className="supportText">
                Enter the Authorization token below to connect with {currentIntegration.name}
              </span>
            </h2>
            <div className={styles.formWrapper}>
              <InputText
                id="token"
                placeholder="Enter token here"
                value={token}
                onChange={(e) => {
                  tokenSet(e.target.value);
                  errorSet(false);
                }}
                error={error ? 'You must enter the token here first!' : ''}
                className={error ? 'errorGroup' : ' '}
              />
              <CustomButton
                className={`${connectLoader ? 'disabledBtn' : ''} primaryBtn submitBtn`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {connectLoader ? 'Connecting...' : 'Connect'}
              </CustomButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

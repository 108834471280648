import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath, goalUnits } from '../../../constants';
import { CustomTargetValue, CustomDeltaValue } from '../../../shared_ui_components';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../../../utils';
import TrackingFrequencyBadge from './TrackingFrequencyBadge';

export default function SalesGoalsStatus(props) {
  const { salesGoals = [], frequency } = props;
  return (
    <div className={styles.salesGoalsStatus}>
      <p className={styles.goalsStatusHeading}>
        Sales Activity <TrackingFrequencyBadge margin={false} frequency={frequency} />
      </p>
      <ul className={`listUnstyled ${styles.goalsListing}`}>
        {salesGoals.map((goal, index) => (
          <li key={index}>
            <div className={styles.goalItem}>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <h3 className={`supportTextSemibold ${styles.title}`}>
                    <img
                      src={`${imageBasePath}${
                        goalUnits.find((item) => item.id === goal.unit).icon
                      }`}
                      alt=""
                    />
                    <span>{goal.name}</span>
                  </h3>
                </Grid>
                <Grid item xs={5}>
                  <div className={styles.achievement + ' flexCentered'}>
                    {goal.delta_value ? <CustomDeltaValue value={goal.delta_value} /> : null}
                    <h3 className="heading3">
                      {goal.target && goal.unit == 2 ? (
                        <>
                          <span>$</span>
                          <NumberFormat
                            format={CustomNumberFormatter}
                            displayType="text"
                            value={goal?.target}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </>
                      ) : (
                        CustomNumberFormatter(goal.target)
                      )}
                    </h3>
                    <CustomTargetValue unit={goal.unit} value={goal.expected_target} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

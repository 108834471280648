import React, { useEffect, useMemo, useState } from 'react';
import styles from './LearningPaths.module.scss';
import { useLearningPathsStore } from '../LearningPaths.store';
import LearningPathInProgress from '../Components/LearningPathInProgress/LearningPathInProgress';
import AssignedLearningPathDetails from '../Components/AssignedLearningPathDetails/AssignedLearningPathDetails';
import { List, ListItem } from '@material-ui/core';
import {
  useGetLearningPathAssignedToMeQuery,
  useGetLearningPathInProgressQuery,
} from '../LearningPaths.queries';
import CardRep from '../Components/CardRep/CardRep';
import NoResults from '../Components/NoResults/NoResults';

const LearningPathViewRep = () => {
  const { selectedRepLearningPathID, setSelectedRepLearningPathID } = useLearningPathsStore();

  const [selectedTab, setSelectedTab] = useState('yet_to_start');
  const handleSelectTab = (selected) => {
    setSelectedTab(selected !== 'yet_to_start' ? 'completed' : 'yet_to_start');
  };
  const { data, isLoading } = useGetLearningPathAssignedToMeQuery();
  const { data: learningPathInProgress, isLoading: isLoadingProgress } =
    useGetLearningPathInProgressQuery();

  const hasInProgress = useMemo(
    () => learningPathInProgress && Object.keys(learningPathInProgress)?.length > 0,
    [learningPathInProgress]
  );
  useMemo(() => data?.to_start.sort((a,b) => new Date(a.learning_path.deadline) - new Date(b.learning_path.deadline)), [data]);
  useMemo(() => data?.completed.sort((a,b) => new Date(a.learning_path.deadline) - new Date(b.learning_path.deadline)), [data]);
  useEffect(() => {
    return () => {
      setSelectedRepLearningPathID('');
    };
  }, [setSelectedRepLearningPathID]);

  if (selectedRepLearningPathID)
    return <AssignedLearningPathDetails uuid={selectedRepLearningPathID} />;
  if (
    !isLoading &&
    !isLoadingProgress &&
    data?.to_start.length === 0 &&
    data?.completed.length === 0 &&
    !hasInProgress
  )
    return <NoResults />;
  return (
    <>
      <div className={styles.containerRep}>
        {!isLoadingProgress && hasInProgress && <LearningPathInProgress />}
        <div>
          <List className="listInline tabList" style={{ marginBottom: '1rem' }}>
            {data?.to_start.length > 0 && (
              <ListItem
                key={'yet_to_start'}
                className={`listInlineItem heading6 ${
                  selectedTab === 'yet_to_start' ? 'active' : ''
                }`}
                onClick={() => handleSelectTab('yet_to_start')}
              >
                Yet to Start ({data?.to_start.length})
              </ListItem>
            )}
            {data?.completed.length > 0 && (
              <ListItem
                key={'completed'}
                className={`listInlineItem heading6 ${
                  selectedTab === 'yet_to_start' ? '' : 'active'
                }`}
                onClick={() => handleSelectTab('completed')}
              >
                Completed ({data?.completed.length})
              </ListItem>
            )}
          </List>
          {selectedTab === 'yet_to_start' && data?.to_start.length > 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                columnGap: '1rem',
              }}
            >
              {data?.to_start?.map((data) => (
                <CardRep
                  hasInProgress={hasInProgress}
                  data={data}
                  isLoading={isLoading}
                  completed={0}
                />
              ))}
            </div>
          ) : (
            data?.completed.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  columnGap: '1rem',
                }}
              >
                {data?.completed?.map((data) => (
                  <CardRep data={data} isLoading={isLoading} completed={1} />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default LearningPathViewRep;

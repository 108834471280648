import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { DocumentViewer } from 'react-documents';
import FileViewerLogic from './FileViewer.logic';

const FileViewer = ({ activeStep, handleMarkAsRead = () => null }) => {
  const { isFile, loading, link, isDisabled, documentLoaded, onFilePreviewLoad } = FileViewerLogic({
    activeStep,
    handleMarkAsRead,
  });
  // Show preview only for document types
  if (!isFile) return null;

  // Show preview only for documents < 25mb
  if (isDisabled) return null;

  return (
    <div className="fileViewer" style={styleContainer}>
      {loading && <CircularProgress style={styleProgress} size={24} />}
      <div>
        <DocumentViewer
          queryParams="hl=en"
          url={link}
          viewer="google"
          loaded={onFilePreviewLoad}
          googleCheckContentLoaded
          googleMaxChecks={100}
          style={{ height: documentLoaded ? '65vh' : 0, width: '100%', 'margin-bottom': '25px' }}
        />
      </div>
    </div>
  );
};

export default FileViewer;

const styleProgress = {
  color: '#0494fd',
};

const styleContainer = {
  textAlign: 'center',
};

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { ProgressBar } from '../../../shared_ui_components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FeatureFlag } from 'controllers/_exports';
import RepTrainingHeader from './RepTrainingHeader';

export default function SetupOnboarding(props) {
  const { total, completed, isAdmin, readinessIndex, role } = props;
  const [note, setNote] = useState(true);
  const closeNote = () => setNote(false);

  return (
    <>
      {isAdmin ? (
        <>
          {note ? (
            <div className="note-block">
              <p className="pararaph">
                <img src={imageBasePath + 'info_darkgrey.svg'} alt="info" />
                {role == 'sales_lead'
                  ? "Help your admin to add content under enabled modules and publish. Kindly note that without publishing all enabled modules, sales rep won't be able to access content"
                  : "Make sure to add all content under enabled modules and publish. Kindly note that without publishing all enabled modules, sales rep won't be able to access content"}
              </p>
              <a onClick={() => closeNote()} className="closeNote">
                <img src={imageBasePath + 'close.svg'} alt="info" />
              </a>
            </div>
          ) : null}
          <section className={styles.setupTrainingIntroSection}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className={styles.titleWrapper}>
                  <div className={styles.imgBlk}>
                    <img src={imageBasePath + 'setup_training.svg'} alt="Image" />
                  </div>
                  <div className={styles.contentBlk}>
                    <h1 className="heading3">Setup Training</h1>
                    {/* <p className="supportText">We've provided you with a content library to use as a template. Be sure you add content to this library to increase the readiness of your sales team. And you must upload content in at least {total} modules to complete the training</p> */}
                    <p className="supportText">
                      Administer byte sized sales training programs that can help you and your team
                      to learn, better prepare to execute your sales plans
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className={styles.stepsWrapper}>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.progressBlk}>
                        <ProgressBar
                          value={completed}
                          max={total}
                          className={styles.stepProgress + ' stepProgress'}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.stepsBlk}>
                        <h2 className="heading3">
                          <span
                            className={styles.steps + ' heading3'}
                          >{`${completed}/${total}`}</span>
                          <span className={styles.supportText + ' supportText'}> Modules</span>
                        </h2>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <img
              className={styles.patternBg}
              src={imageBasePath + 'onboarding_setup_pattern.svg'}
              alt="Image"
            />
          </section>
        </>
      ) : FeatureFlag.enabled('LEARNING_PATHS') ? (
        <RepTrainingHeader completed={completed} readinessIndex={readinessIndex} total={total} />
      ) : (
        <section className={`${styles.setupTrainingIntroSection} ${styles.userTrainingIntro}`}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <div className={styles.titleWrapper}>
                <div className={styles.imgBlk}>
                  <img src={imageBasePath + 'training_dashboard_icon.svg'} alt="Image" />
                </div>
                <div className={styles.contentBlk}>
                  <h1 className="heading3">Training</h1>
                  <p className="supportText">
                    Please complete all of the modules to boost your sales readiness
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item lg={5} md={5} xs={12} sm={12}>
              <Grid container alignItems="center">
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  {readinessIndex ? (
                    <div className={styles.readinessInfoWrapper}>
                      <p className="supportText flexCentered">
                        <div className="circularProgressbar">
                          <CircularProgressbar
                            strokeWidth={10}
                            value={readinessIndex}
                            styles={buildStyles({
                              textColor: ' #778DA9',
                              pathColor: '#00D2AD',
                              trailColor: '#D8E1E9',
                              textSize: '16px',
                              strokeLinecap: 'butt',
                              pathTransition: 'none',
                            })}
                          />
                        </div>
                        <span className={styles.value}>{readinessIndex}%</span>
                        <span>Sales Readiness Index</span>
                      </p>
                    </div>
                  ) : null}
                </Grid>
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  <div className={styles.completedCountWrapper + ' flexCentered'}>
                    <div className={styles.progressBlk}>
                      <ProgressBar
                        value={completed}
                        max={total}
                        className={styles.stepProgress + ' stepProgress'}
                      />
                    </div>
                    <span className={`${styles.value} heading6`}>{`${completed || 0}/${
                      total || 0
                    }`}</span>
                    <span className="supportText" style={{ marginTop: 2 }}>
                      Modules Completed
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
}

import React from 'react';
import styles from './RecordVideo.module.css';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const RoleplayAttempts = ({ attempt, setAttempt = () => null }) => {
  const showRetake = useRoleplayStore((s) => s?.showRetake);
  if (!showRetake) return null;
  return (
    <div className={styles.listAttempts}>
      <button className={attempt === 1 ? styles.selected : ''} onClick={() => setAttempt(1)}>
        1 Attempt
      </button>
      <button className={attempt === 2 ? styles.selected : ''} onClick={() => setAttempt(2)}>
        2 Attempt
      </button>
    </div>
  );
};

export default RoleplayAttempts;

import { FeatureFlag } from 'controllers/_exports';
import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
} from '../../utils/globalApiServices';

export function getOnboardingSteps(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/onboarding/list/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getOnboardingTeamProgressApi({ query = {}, time = 'last-3-months' }) {
  return new Promise((resolve, reject) => {
    let url = '/onboarding/dashboard/team-progress/';
    if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
    globalGetService(url, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getOnboardingProgressRatingApi({ time = 'last-3-months' }) {
  return new Promise((resolve, reject) => {
    let url = '/onboarding/dashboard/progress-rating/';
    if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
    globalGetService(url)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getOnboardingAverageTimeTakenApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/onboarding/dashboard/avg-time-taken/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function enableDisableApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${query.uuid}/enable/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateContentApi(id, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${id}/update/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addCustomStepApi(id, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/module/${id}/step/create/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function deleteCustomStepApi(id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/onboarding/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function publishOnboardingApi() {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/publish/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function markAsCompleteApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/mark-complete/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default function onboardingRatingApi() {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/rating/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createOnboardingCommentApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/comment/create/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateOnboardingCommentApi(uuid, commentId, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/comment/${commentId}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reorderStepsApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/re-order/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function onboardingCommentlikeDislikeApi(uuid, commentId, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/comment/${commentId}/like/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function onboardinglikeDislikeApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/like/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function submitOnboardingFeedbackApi(formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/rating/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteOnboardingCommentApi(uuid, id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/onboarding/${uuid}/comment/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadReport() {
  return new Promise((resolve, reject) => {
    globalExportService(`/onboarding/dashboard/download/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getUserPreferencesApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/category/details/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function markAsReadApi(uuid) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/read-content/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function nudgeUserOnboardingApi(uuid) {
  return new Promise((resolve, reject) => {
    globalPostService(`/onboarding/${uuid}/nudge-notification/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

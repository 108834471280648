import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { SuccessDialog, ConfirmationDialog } from '../../../shared_elements';
import { CustomButton } from '../../../shared_ui_components';
import { publishTrackingApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

export default function SetupComplete(props) {
  const [loader, setLoader] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    setOpenSuccess(false);
    setLoader(false);
    setOpenConfirm(false);
  }, []);

  const handlePublish = () => {
    setLoader(true);
    publishTrackingApi().then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setIsPublished(true);
        toastFlashMessage(response.message, 'success');
        setOpenSuccess(true);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  return (
    <>
      <section className={styles.setupCompleteSection}>
        <div
          className={styles.setUpwrapper + ' backgroundProp flexCentered'}
          style={{ backgroundImage: `url(${imageBasePath + 'setup_complete_bg.png'})` }}
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={styles.titleWrapper}>
                <div className={styles.imgBlk}>
                  <img src={imageBasePath + 'tracking_setup_complete.svg	'} alt="Company" />
                </div>
                <div className={styles.contentBlk}>
                  <h1 className="heading2">Congratulations! </h1>
                  <p className="heading3">
                    You have successfully <span>Setup Goal Tracking</span> module
                  </p>
                </div>
              </div>
            </Grid>
            {isPublished ? null : (
              <Grid item lg={6} md={6} sm={12} xs={12} className="textRight">
                <CustomButton
                  className={`${styles.publishBtn} primaryBtn ${loader ? 'disabledBtn' : ''}`}
                  onClick={() => setOpenConfirm(true)}
                >
                  {loader ? 'Publishing...' : `Publish Now`}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </div>
      </section>
      <SuccessDialog
        open={openSuccess}
        title={<>Goals are published and goal tracking is on!</>}
        description={
          <>
            Once your team starts check-in the progress, you will see the <br /> progress on each
            goal
          </>
        }
        continueText="Stay in setup goal tracking"
        acceptText="See Goal Tracking overview"
        image={'tracking_published_logo.svg'}
        handleSubmit={() => {
          props.handlePublishSuccess();
          setOpenSuccess(false);
        }}
        handleClose={() => {
          setOpenSuccess(false);
        }}
      />
      <ConfirmationDialog
        open={openConfirm}
        title={<>Are you sure you want to publish your Goal Tracking setup?</>}
        description="This action can't be undone, but you still can add and update your goals"
        acceptText="Yes, Publish"
        rejectText="No, Cancel"
        image={imageBasePath + 'published_logo.png'}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          handlePublish();
          setOpenConfirm(false);
        }}
        loader={loader}
      />
    </>
  );
}

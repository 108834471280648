import React, { useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useLearningPathsStore } from '../../../LearningPaths.store';
import { useGetTrainingModulesForPathQuery } from '../../../LearningPaths.queries';
import TrainingSelectCard from './TrainingSelectCard';
import { CONTENT_TYPES } from '../../../LearningPaths.utils';
import { CustomButton, NoResult } from 'shared_ui_components';
import { imageBasePath } from 'constants';
import styles from './AddTrainingModules.module.scss';
import { useUpdateLearningPathContents } from '../../../useUpdateLearningContents';
import Loading from 'shared_ui_components/Loading';

const TrainingModulesModal = () => {
  const { addContents } = useUpdateLearningPathContents();
  const { trainingModulesModalOpen, closeTrainingModulesModal, contents } = useLearningPathsStore();

  const { data, isLoading } = useGetTrainingModulesForPathQuery({
    enabled: trainingModulesModalOpen,
  });

  const modules = useMemo(() => {
    if (!data) return [];
    return data
      ?.filter((m) => !contents?.find((c) => c?.content?.uuid === m?.uuid))
      ?.map((m) => ({ content_type: CONTENT_TYPES.TRAINING_MODULES, content: m }));
  }, [data, contents]);

  const [selectedModules, setSelectedModules] = useState([]);
  const toggleSelection = (id) => {
    if (selectedModules?.includes(id))
      return setSelectedModules((modules) => modules.filter((m) => m !== id));
    return setSelectedModules((modules) => [...modules, id]);
  };

  const addModules = () => {
    const _modules = [...modules]?.filter((m) =>
      selectedModules?.find((id) => m?.content?.uuid === id)
    );
    if (_modules?.length > 0) addContents(_modules);
    setSelectedModules([]);
    closeTrainingModulesModal();
  };

  return (
    <Dialog
      open={trainingModulesModalOpen}
      onClose={closeTrainingModulesModal}
      scroll={'paper'}
      aria-labelledby="add-video-title"
      aria-describedby="add-video-description"
      className={`dialog addVideoDialog ${styles.dialog}`}
    >
      <DialogTitle>
        <div>Learning path: Add modules</div>
        <Typography variant="body2">
          Provide a personalized learning path tailored to each sales rep needs.
        </Typography>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className="modalCloseIcon"
          onClick={() => closeTrainingModulesModal()}
        />
      </DialogTitle>
      <DialogContent style={{ padding: '0.5rem' }}>
        <div className={styles.titleModules}>
          <img src={imageBasePath + 'training_dashboard_icon.svg'} alt="icon" />
          <span className="heading6">Select modules to get it added to learning path</span>
        </div>
        <Loading loading={isLoading} circular />
        {modules?.map((module, i) => (
          <TrainingSelectCard
            key={i}
            module={module}
            toggleSelection={toggleSelection}
            selected={selectedModules?.includes(module?.content?.uuid)}
          />
        ))}
        {modules?.length === 0 && !isLoading && (
          <NoResult
            className={styles.noResultsContainer}
            image={`NoResultLearningPaths.svg`}
            title={'No training modules to add'}
          />
        )}
      </DialogContent>

      <DialogActions>
        <CustomButton className="secondaryBtn" onClick={() => closeTrainingModulesModal()}>
          Close
        </CustomButton>
        <CustomButton className="primaryBtn" onClick={addModules}>
          Add modules ({selectedModules?.length})
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default TrainingModulesModal;

import { create } from 'zustand';
import DateTime from 'infrastructure/Datetime';

export const useLearningPathsStore = create((set, get) => ({
  createModalOpen: false,
  openCreateModal: () => set(() => ({ createModalOpen: true })),
  closeCreateModal: () => set(() => ({ createModalOpen: false })),
  editModalOpen: false,
  openEditModal: () => set(() => ({ editModalOpen: true })),
  closeEditModal: () => set(() => ({ editModalOpen: false })),
  formDataLearningPath: {
    deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    users: [],
    title: '',
    description: '',
  },
  setFormDataLearningPath: (formDataLearningPath = {}) => set(() => ({ formDataLearningPath })),

  // Update contents
  learningPath: {}, // Learning path in details view
  setLearningPath: (learningPath) => set(() => ({ learningPath })),
  resetLearningPath: () => set(() => ({ learningPath: {} })),

  contents: [],
  setContents: (newContents) => set(() => ({ contents: newContents })),

  // Edition
  trainingModulesModalOpen: false,
  openTrainingModulesModal: () => set(() => ({ trainingModulesModalOpen: true })),
  closeTrainingModulesModal: () => set(() => ({ trainingModulesModalOpen: false })),
  currentLearningPath: {
    deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    users: [],
    title: '',
    description: '',
  },
  setCurrentLearningPath: (currentLearningPath = {}) => set(() => ({ currentLearningPath })),

  // Rep
  selectedRepLearningPathID: '',
  setSelectedRepLearningPathID: (selectedRepLearningPathID = '') =>
    set(() => ({ selectedRepLearningPathID })),
  activeTabRep: 'training',
  setActiveTabRep: (activeTabRep = '') => set(() => ({ activeTabRep })),
  userPreferencesLP: {},
  setUserPreferencesLP: (userPreferencesLP = {}) => set(() => ({ userPreferencesLP })),
}));

import { API } from 'utils/globalApiServices';

export async function getProvidersService() {
  const url = '/organization/providers/';
  const { data } = await API.get(url);
  return data;
}

export async function validateKeyService({ code, id }) {
  const url = `organization/provider/${id}/validate-key/`;
  const { data } = await API.post(url, { code });
  return data;
}

export async function updateStatusService({ id, status }) {
  const url = `/organization/providers/${id}/integration`;
  const { data } = await API.post(url, { status });
  return data;
}

export async function removeIntegrationService({ id }) {
  const url = `/organization/providers/${id}/delete/integration`;
  const { data } = await API.del(url);
  return data;
}

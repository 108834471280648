import { useMemo } from 'react';
import { AdminModuleDetailContainer, UserModuleDetailContainer } from '../components';
import styles from '../../../assets/styles/training.module.scss';
import Organization from 'infrastructure/Organization';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';

const ModuleDetail = ({ role }) => {
  const { isActive, isLoading } = useSalesRepView();

  const slug = useMemo(() => {
    if (isActive) return Organization.ROLES.REP;
    return role?.slug;
  }, [role, isActive]);

  const showRepContainer = useMemo(() => {
    if (isActive) return true;
    return slug === Organization.ROLES.REP;
  }, [isActive, slug]);

  if (isLoading) return null;
  return (
    <div className={styles.trainingPage}>
      {showRepContainer ? (
        <UserModuleDetailContainer role={slug} />
      ) : (
        <AdminModuleDetailContainer role={role?.slug} />
      )}
    </div>
  );
};

export default ModuleDetail;

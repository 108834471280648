import React from 'react';
import styles from '../../../assets/styles/onboarding.module.scss';
import { AdminOnboardingContainer, UserOnboardingContainer } from '../components';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';

const Onboarding = (props) => {
  const { isActive } = useSalesRepView();
  return (
    <div className={styles.onboardingPage}>
      {isActive ? (
        <UserOnboardingContainer />
      ) : ['primary_admin', 'admin'].includes(props.role.slug) ? (
        <AdminOnboardingContainer />
      ) : (
        <UserOnboardingContainer />
      )}
    </div>
  );
};

export default Onboarding;

import { useEffect, useState } from 'react';
import { getNotificationPreferences } from '../../apiServices';

const NotificationSettingsLogic = ({ onNotificationsChange }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotificationPreferences().then((response) => {
      if (response.statusCode === 200) {
        setNotifications(response.notifications);
      }
    });
  }, []);

  const onToggleChange = (identifier) => {
    const newNotifications = [...notifications];
    const notificationIndex = newNotifications.findIndex((x) => x.id === identifier);

    newNotifications[notificationIndex].is_enabled =
      !newNotifications[notificationIndex].is_enabled;

    setNotifications(newNotifications);
    onNotificationsChange(newNotifications);
  };

  return { onToggleChange, notifications };
};

export default NotificationSettingsLogic;

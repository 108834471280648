import React from 'react'
import styles from './Common.module.scss';
import { format, parseISO } from 'date-fns';
import { Avatar } from '@material-ui/core';
import User from 'infrastructure/User';

const RoleplayHeader = ({ roleplayView = {}, leadView = false, isForSubmitView }) => {
  if (Object.keys(roleplayView).length === 0) return null;
  return (
    <div
      className={`${styles.description_header} ${
        isForSubmitView ? styles.description_header_submit : ''
      }`}
    >
      <div>
        <div className={styles.description_header_title}>{!leadView ? 'Manager' : 'Assigned'}</div>
        <div className={styles.avatar}>
          <Avatar
            className={styles.img}
            alt=""
            src={
              !leadView
                ? roleplayView?.assigned_by_user.profile_pic
                : roleplayView?.user.profile_pic
            }
          >
            {!leadView
              ? User.getInitial(roleplayView?.assigned_by_user)
              : User.getInitial(roleplayView?.user)}
          </Avatar>
          <h4>
            {!leadView
              ? User.getUserName(roleplayView?.assigned_by_user)
              : User.getUserName(roleplayView?.user)}
          </h4>
        </div>
      </div>
      <div>
        <div className={styles.description_header_title}>Due Date</div>
        <h4 style={{ marginTop: '1rem' }}>
          {format(parseISO(roleplayView?.deadline), 'MMM dd, yyyy')}
        </h4>
      </div>
    </div>
  );
};

export default RoleplayHeader
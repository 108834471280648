import React from 'react';
import { Dialog } from '@material-ui/core';
import { useFunnelProvider } from 'features/funnel/FunnelProvider';
import styles from './ManageStagesModal.module.scss';
import { imageBasePath } from 'constants';
import { CustomButton } from 'shared_ui_components';
import FunnelStagesList from '../FunnelStagesList/FunnelStagesList';
import AddEditStageForm from '../AddEditStageForm/AddEditStageForm';

const ManageStagesModal = () => {
  const { isModalOpen, closeModal, onSaveChanges, showHint, loadingUpdate, loadingStages } =
    useFunnelProvider();

  return (
    <Dialog open={isModalOpen} onClose={closeModal} maxWidth="md" fullWidth scroll="body">
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <div className={styles.title}>Manage funnel stages</div>
            <div className={styles.subtitle}>
              “Stages” represent the sales process, which is illustrated in the funnel graph.
            </div>
          </div>
          <button onClick={closeModal}>
            <img src={imageBasePath + 'close.svg'} alt="X" />
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <AddEditStageForm />
          </div>
          <div className={styles.right}>
            <FunnelStagesList />
          </div>
        </div>
        <div className={styles.footer}>
          {showHint && (
            <span className={styles.hint}>Funnel chart needs to have at least 1 active stage</span>
          )}
          <CustomButton className="secondaryBtn" onClick={closeModal}>
            Close
          </CustomButton>
          <CustomButton
            disabled={loadingUpdate || loadingStages}
            className="primaryBtn"
            onClick={onSaveChanges}
          >
            {loadingUpdate ? 'Saving...' : 'Save changes'}
          </CustomButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ManageStagesModal;

import React, { useMemo, useState } from 'react';
import Picture from '../../components/Picture/Picture';
import styles from './PostNewComment.module.scss';
import { CustomButton } from 'shared_ui_components';
import { useCreateComment } from 'features/stream/Stream.queries';
import PostTextArea from '../../components/PostTextArea/PostTextArea';
import { FeatureFlag } from 'controllers/_exports';
import { useStreamStore } from 'features/stream/Stream.store';

const PostNewComment = ({ data }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const { mutate: createComment, isLoading } = useCreateComment();
  const [comment, setComment] = useState('');

  const newRTE = useMemo(() => FeatureFlag.enabled('NEW_POST_RTE'), []);

  const getComment = useStreamStore((state) => state.getCleanComment);
  const resetComment = useStreamStore((state) => state.resetComment);

  const handleComment = () => {
    const id = data.id;
    let newComment = comment;
    if (newRTE) newComment = getComment(id);
    if (!newComment?.trim().length > 0) return;
    createComment(
      { postId: id, comment: newComment },
      {
        onSuccess: () => {
          setComment('');
          resetComment(id);
        },
      }
    );
  };

  return (
    <div className={styles.newCommentContainer}>
      <Picture
        email={userInfo.user.email}
        profile_pic={userInfo.user.profile_pic}
        first_name={userInfo.user.first_name}
      />
      {newRTE ? (
        <PostTextArea isComment isNewPost postID={data?.id} />
      ) : (
        <input
          placeholder="Enter reply"
          className={styles.commentInput}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      )}
      <CustomButton disabled={isLoading} onClick={handleComment} style={{ padding: '0.7rem 1rem' }}>
        Comment
      </CustomButton>
    </div>
  );
};

export default PostNewComment;

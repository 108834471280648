import Organization from 'infrastructure/Organization';
const { FeatureFlag } = require('controllers/_exports');

const HubspotUtils = (function () {
  // HubSpot is integrated, keys exist in backend
  const isIntegrated = () => {
    return FeatureFlag.enabled('HUBSPOT') && !!Organization.getSelectedOrg()?.hubspot_integrated;
  };
  return {
    isIntegrated,
  };
})();

export default HubspotUtils;

import React from 'react';
import styles from './DefaultPayment.module.scss';
import { useChangeDefaultPaymentMethodMutation } from 'features/payment/Payment.queries';

const DefaultPayment = ({ cardId = '', isDefault = false }) => {
  const { mutate, isLoading } = useChangeDefaultPaymentMethodMutation();

  const handleMakeDefault = () => {
    mutate({ payment_method_id: cardId }, { onSuccess });
  };
  const onSuccess = () => {};
  return (
    <button
      onClick={!isDefault ? handleMakeDefault : null}
      className={!isDefault ? styles.makeDefaultButton : styles.default}
      disabled={isLoading}
    >
      {isDefault ? 'default' : isLoading ? 'Loading' : 'Make default'}
    </button>
  );
};

export default DefaultPayment;

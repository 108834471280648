import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid } from '@material-ui/core';
import TrackingInfoItem from './TrackingInfoItem';
import TrackingFrequencyBadge from './TrackingFrequencyBadge';

export default function TrackingOverview(props) {
  const { overview = {} } = props;

  return (
    <div className={styles.moduleOverview}>
      <OverviewTitle
        icon={`${imageBasePath}track_default.svg`}
        title={
          <>
            <span>Goal Tracking</span>
            <TrackingFrequencyBadge frequency={overview?.frequency} />
          </>
        }
        moduleLink={'/tracking'}
      />
      <Grid
        container
        className={`${styles.trackOverviewWrapper} ${styles.defaultOverviewWrapper}`}
        alignItems="center"
      >
        {overview.goals?.map((goal, index) => (
          <Grid item xs={12} md={6} key={index}>
            <TrackingInfoItem goal={goal} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';

export default function InitialAssesmentDialog(props) {
  const { assesmentInit, loader } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.handleAssesmentInit(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.assesmentInitDialog}`}
      >
        <div id="add-video-title" className={styles.dialogTitle}>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon hidden"
            onClick={handleClose}
          />
          <div className={styles.imgWrapper}>
            <img src={imageBasePath + 'admin_assesment_featured.png'} alt="image" />
            {/* user */}
            {/* <img src={imageBasePath+'user_assement_featured.png'} alt="image" /> */}
          </div>
        </div>
        <DialogContent>
          <div className={styles.dialogBody}>
            <h1 className="heading4">
              Seems you have updated all Training <br /> modules
            </h1>
            <p className="paragraph">
              Now you can do training assessment to check <br /> the training status
            </p>
            {/* user */}
            {/* <h1 className="heading4">Seems you have completed all Training <br/> modules</h1>
            <p className="paragraph">Now you can start assessment test</p> */}
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.handleClose();
            }}
          >
            Will do it later
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {loader ? 'Starting Setup...' : 'Start Setup'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

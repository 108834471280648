import React from 'react';
import StartRecordingLogic from './StartRecording.logic';
import '../VideoRecorder.scss';

import Recording from '../../../assets/Recording.png';

const StartRecordingButton = ({
  onRecordingStart = () => null,
  onRecordingDiscard = () => null,
  onRecordingEnd = () => null,
  finalRecordingID,
  bypassSubscription,
}) => {
  const { showButton, handleStartRecording } = StartRecordingLogic({
    onRecordingStart,
    onRecordingDiscard,
    onRecordingEnd,
    finalRecordingID,
    bypassSubscription,
  });

  return showButton ? (
    <>
      <div onClick={handleStartRecording} className="recording-button">
        <img src={Recording} alt="" />
        <div className="recording-text">
          <h6 className="paragraph">
            <span className="linkBtn">Record</span> and upload
          </h6>
          <p className="textCenter supportText">(Max 5 minutes per video)</p>
        </div>
      </div>
    </>
  ) : null;
};

export default StartRecordingButton;

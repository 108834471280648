import React, { useState, useEffect } from 'react';
import { ListItem } from '@material-ui/core';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomCheckbox } from 'shared_ui_components';
import styles from './AssignRoleplay.module.scss';
const RoleplayScenarios = () => {
  const scenariosList = useRoleplayStore((state) => state?.scenariosList);
  const [scenarios, setScenarios] = useState([]);
  const setScenariosList = useRoleplayStore((state) => state?.setScenariosList);
  const handleChange = (value, scenario_) => {
    const index = scenarios.findIndex((scenario) => scenario?.uuid === scenario_.uuid);
    scenarios[index].isSelected = value;
    setScenariosList([...scenariosList]);
  };

  useEffect(() => {
    setScenarios(scenariosList);
  }, [scenariosList]);

  return (
    <>
      {Object.keys(scenarios).length !== 0 && (
        <>
          <p className={styles.deadlineTitle}>Scenarios</p>
          <ListItem
            className="listUnstyled"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            {scenarios.map((scenario, index) => {
              return (
                <CustomCheckbox
                  label={scenario?.name}
                  value={scenario.isSelected ? true : false}
                  onChange={(value) => handleChange(value, scenario)}
                  noMargin={true}
                  key={index}
                />
              );
            })}
          </ListItem>
        </>
      )}
    </>
  );
};

export default RoleplayScenarios;

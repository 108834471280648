import Tracking from './containers/Tracking';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const trackingRoutes = [
  {
    path: '/tracking',
    component: pageLayoutHoc(Tracking),
    key: 'tracking',
  },
  {
    path: '/tracking/publisedview',
    component: pageLayoutHoc(Tracking),
    key: 'tracking-publisedview',
  },
  {
    path: '/tracking/overview',
    component: pageLayoutHoc(Tracking),
    key: 'tracking-overview',
  },
  {
    path: '/tracking/useroverview',
    component: pageLayoutHoc(Tracking),
    key: 'tracking-useroverview',
  },
];

import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { imageBasePath } from '../constants';
import { CustomButton } from '../shared_ui_components';
import styles from '../assets/styles/onboarding.module.scss';
export default function SuccessDialog(props) {
  const { image, title, description, acceptText, continueText, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleClose}
        scroll={'paper'}
        className={`${styles.publishDialog + ' ' + props.classNames}`}
      >
        <div className={styles.dialogBody + ' textCenter'}>
          <img
            src={imageBasePath + 'publish_dialog_bg.png'}
            alt="publish banner"
            className={styles.publishBanner}
          />
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className={styles.modalCloseIcon}
            onClick={props.handleClose}
          />
          {image ? (
            <img src={imageBasePath + image} alt="Publish logo" className={styles.publishLogo} />
          ) : (
            <img
              src={imageBasePath + 'published_logo.png'}
              alt="Publish logo"
              className={styles.publishLogo}
            />
          )}
          <h4 className="heading4">{title}</h4>
          <p className="paragraph">{description}</p>
          <ul className="listInline">
            {continueText ? (
              <li className="listInlineItem">
                <CustomButton
                  onClick={props.handleContinue ? props.handleContinue : props.handleClose}
                  className="secondaryBtn"
                >
                  {continueText}
                </CustomButton>
              </li>
            ) : null}
            {acceptText ? (
              <li className="listInlineItem">
                <CustomButton onClick={props.handleSubmit} className="primaryBtn ">
                  {acceptText}
                </CustomButton>
              </li>
            ) : null}
          </ul>
        </div>
      </Dialog>
    </div>
  );
}

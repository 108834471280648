import React, { useMemo } from 'react';

import styles from './Comment.module.scss';
import Picture from 'features/stream/UI/components/Picture/Picture';
import StreamUtils from 'features/stream/StreamConstants';
import Timestamp from '../../../components/Timestamp/Timestap';
import Reactions from '../../PostReactions/Reactions';
import { FeatureFlag } from 'controllers/_exports';
import PostTextArea from 'features/stream/UI/components/PostTextArea/PostTextArea';

const Comments = ({ data = {}, postId = '' }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const ownComment = useMemo(() => userInfo.user.uuid === data.user.uuid, [userInfo, data]);

  return (
    <div className={styles.comment}>
      <div className={styles.picture}>
        <Picture
          profile_pic={data.user.profile_pic}
          email={data.user.email}
          first_name={data.user.first_name}
        />
      </div>

      <div className={styles.commentContent}>
        <span className={styles.name}>
          {ownComment ? `You` : `${StreamUtils.getUsername(data?.user)}`}
          <Timestamp creationDate={data?.created_at} />
        </span>
        <div className={styles.commentText}>
          <PostTextArea isComment initialValue={data.comment} />
        </div>
        <div className={styles.reactions}>
          {FeatureFlag.enabled('STREAM_IMPROVEMENTS') && (
            <Reactions data={data} isComment={true} commentId={data?.id} postId={postId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;

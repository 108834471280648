import React from 'react';

const Star = ({ width = 16, height = 16, filled = true }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4883 7.0422L12.3172 9.8097L13.2671 13.93C13.3174 14.1454 13.303 14.3709 13.2259 14.5781C13.1487 14.7854 13.0121 14.9654 12.8333 15.0955C12.6544 15.2256 12.4411 15.3001 12.2202 15.3096C11.9992 15.3192 11.7803 15.2634 11.5909 15.1492L7.99718 12.9695L4.41124 15.1492C4.2218 15.2634 4.0029 15.3192 3.78193 15.3096C3.56095 15.3001 3.34769 15.2256 3.16882 15.0955C2.98994 14.9654 2.85339 14.7854 2.77623 14.5781C2.69907 14.3709 2.68472 14.1454 2.73499 13.93L3.68351 9.81392L0.51171 7.0422C0.34395 6.89752 0.222643 6.70652 0.162999 6.49317C0.103356 6.27981 0.108032 6.0536 0.17644 5.84289C0.244849 5.63218 0.373945 5.44636 0.54754 5.30873C0.721135 5.1711 0.931501 5.08779 1.15226 5.06924L5.33304 4.70713L6.96499 0.814625C7.05021 0.610379 7.19396 0.435913 7.37814 0.313197C7.56231 0.190481 7.77868 0.125 7.99999 0.125C8.2213 0.125 8.43767 0.190481 8.62185 0.313197C8.80602 0.435913 8.94977 0.610379 9.03499 0.814625L10.6719 4.70713L14.8512 5.06924C15.072 5.08779 15.2824 5.1711 15.456 5.30873C15.6296 5.44636 15.7586 5.63218 15.8271 5.84289C15.8955 6.0536 15.9001 6.27981 15.8405 6.49317C15.7809 6.70652 15.6595 6.89752 15.4918 7.0422H15.4883Z"
        fill={filled ? '#EB9B42' : '#eb9b424d'}
      />
    </svg>
  );
};

export default Star;

import React, { useState } from 'react';
import styles from './EditPaymentDetails.module.scss';
import { imageBasePath, months, years } from 'constants';
import { useEditPaymentMethodMutation } from '../../../Payment.queries';
import { CustomButton, InputSelect, InputText } from 'shared_ui_components';
import DeleteCardModal from '../DeleteCard/DeleteCardModal';
const EditPaymentDetails = ({ setShowModal = () => null, cardInfo = {}, isDefault = false }) => {
  const { mutate, isLoading } = useEditPaymentMethodMutation({});
  const [Month, setMonth] = useState(
    months[
      months.findIndex((m) => {
        return m.label == cardInfo?.card?.exp_month;
      })
    ]
  );
  const [Year, setYear] = useState(
    years[
      years.findIndex((y) => {
        return y.label == cardInfo?.card?.exp_year;
      })
    ]
  );
  const [fullName, setFullName] = useState(cardInfo?.name);

  const handleSubmit = () => {
    const data = {
      billing_details: {
        name: fullName,
      },
      card: {
        exp_month: Month.value,
        exp_year: Year.value,
      },
    };
    mutate({ body: data, payment_method_id: cardInfo?.id }, { onSuccess });
  };

  const onSuccess = () => {
    setShowModal(false);
  };

  return (
    <div style={{ minHeight: '25rem', minWidth: '25rem' }}>
      <div className={styles.editHeader}>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className={styles.cancelCloseIcon}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className={styles.editBody}>
        <div className={styles.bodyForm}>
        <InputText
            label={'Card number'}
            id="cardNumber"
            placeholder={`**** **** **** ${cardInfo?.card?.last4}`}
            disabled
          />
          <div className={styles.bodyInput}>
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect={true}
              label={`Month`}
              options={months}
              isSearchable={true}
              placeholder={'January'}
              noOptionsMessage={() => 'No matches found'}
              onChange={(selectedOption) => {
                setMonth(selectedOption);
              }}
              value={Month}
            />
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect={true}
              label={`Year`}
              options={years}
              isSearchable={true}
              placeholder={`2023`}
              noOptionsMessage={() => 'No matches found'}
              onChange={(selectedOption) => {
                setYear(selectedOption);
              }}
              value={Year}
            />
          </div>

          <InputText
            label={'Full name'}
            id="fullname"
            placeholder="Full Name"
            className="nameInputGroup"
            multiline
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className={styles.bodyButtons}>
          <DeleteCardModal cardInfo={cardInfo} isDefault={isDefault}/>
          <div>
            <button disabled={isLoading} onClick={() => setShowModal(false)} className={styles.cancel}>
              Cancel
            </button>
            <CustomButton disabled={isLoading} onClick={handleSubmit} textTransform="none">
              Save
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentDetails;

import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { NoResult, CustomButton } from '../../../shared_ui_components';
import { CheckinGoalsList } from './';
import { format, parseISO } from 'date-fns';
import { FeatureFlag } from 'controllers/_exports';

export default function TeamCheckinDetails(props) {
  const { checkinDetails = {}, activeCheckinDate } = props;
  const viewFile = (content) => {
    const win = window.open(content, '_blank');
    win.focus();
  };
  return (
    <>
      {checkinDetails?.checkin_exists ? (
        <div className={styles.teamCheckinDetails}>
          <h6 className={styles.checkinTitle + ' heading6'}>
            Check-in Details
            <span className="supportText">
              <img src={imageBasePath + 'checkin_icon.svg'} alt="image" />
              {`On ${format(new Date(activeCheckinDate + 'T00:00:00'), 'do MMM yyyy')}`}
            </span>
          </h6>
          <CheckinGoalsList
            tableTitle
            orgTargetValue="1000"
            salesTargetValue="1000"
            categories={checkinDetails?.categories}
          />
          {checkinDetails.need_customer_attention ? (
            <div className={styles.lessonWrapper}>
              <h6 className="supportTextSemibold">Elaborate issues you're facing</h6>
              <div className={styles.content}>
                <p className="supportText">
                  {checkinDetails?.remarks ? checkinDetails?.remarks : '--'}
                </p>
              </div>
            </div>
          ) : null}
          <div className={styles.lessonWrapper}>
            <h6 className="supportTextSemibold">Lessons learned</h6>
            <div className={styles.content}>
              <p className="supportText">
                {checkinDetails?.lessons_learned ? checkinDetails?.lessons_learned : '--'}
              </p>
            </div>
          </div>
          <div className={styles.lessonWrapper}>
            <h6 className="supportTextSemibold">Best practices</h6>
            <div className={styles.content}>
              <p className="supportText">
                {checkinDetails?.best_practices ? checkinDetails?.best_practices : '--'}
              </p>
            </div>
          </div>
          {FeatureFlag.enabled('CHECKINWFILE') && checkinDetails.additional_file && (
            <div className={styles.lessonWrapper}>
              <h6 className="supportTextSemibold">Additional File</h6>
              <CustomButton
                onClick={(e) => {
                  viewFile(checkinDetails.additional_file);
                  e.preventDefault();
                }}
              >
                Download{' '}
                {
                  checkinDetails.additional_file.split('/')[
                    checkinDetails.additional_file.split('/').length - 1
                  ]
                }
              </CustomButton>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.noCheckinResult}>
          <NoResult
            image="no_checkin.svg"
            title="No check-in yet"
            subtext={`No Check-in was completed on ${format(
              new Date(activeCheckinDate + 'T00:00:00'),
              'MM/dd/yyyy'
            )}`}
            className={styles.noResult}
          />
        </div>
      )}
    </>
  );
}

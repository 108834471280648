import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
// import {} from '../../../shared_elements';
import { AboutOrganization, OrganizationExist, ChooseOrganization } from '../components';
import { fieldValidation } from '../../../utils/formValidation';
import { organisationSelectApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

export default class SelectOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: [],
      user: '',
      availalable_org: [],
      formData: {
        ...props.formData,
      },
    };
  }

  componentWillMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.setState({
      formData:
        this.props.history && this.props.history.location && this.props.history.location.state
          ? this.props.history.location.state.formData
          : this.state.formData,
    });
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.setState({
        organisation: userInfo.organisations ? userInfo.organisations : {},
      });
    } else {
      localStorage.clear();
      this.props.history.push('/');
    }
  }

  selectOrg = (org, index) => {
    if (org?.deleted_for_retention) return this.props.history.push(`/retained?org=${index}`);
    const { formData } = this.state;
    organisationSelectApi({ organisation_id: org.slug, remember_me: formData.remember_me }).then(
      (response) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          let responseUpdated = {
            ...response.data,
            selectedOrg: org,
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          const redirection = localStorage.getItem('redirection');
          if (redirection) {
            localStorage.removeItem('redirection');
            this.props.history.push(redirection);
          } else {
            this.props.history.push('/');
          }
        } else if (response.statusCode === 6001) {
          let responseUpdated = {
            ...response.data,
            currentOrg: org,
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          this.props.history.push('/verifydomain');
        } else if (response.statusCode === 6002) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo = {
            ...userInfo,
            currentOrg: org,
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.props.history.push('/verificationrejected');
        } else {
          toastFlashMessage(response.message, 'error');
          this.setState((prevState) => ({
            ...prevState,
            loader: false,
          }));
        }
      }
    );
  };

  render() {
    const { organisation } = this.state;

    return (
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ChooseOrganization organisation={organisation} selectOrg={this.selectOrg} />
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

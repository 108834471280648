import React from 'react';
import styles from './OAuth.module.scss';
import { CustomButton } from 'shared_ui_components';

// TODO purpose of this component is to handle any oAUth integration
// It only supports hubspot integration for now

const extraInfo = [
  {
    icon: '',
    title: 'Manage and View your Contacts data',
    list: [
      'Create, edit, delete and view details of contacts',
      'Create, edit, delete and view details of emails',
      'Create, edit, delete and view details of calls',
      'Create, edit, delete and view details of tasks',
    ],
  },
  {
    icon: '',
    title: 'Manage and View your deals data',
    list: ['Create, edit, delete and view details of deals'],
  },
];

const AUTH_URL = process.env.REACT_APP_HUBSPOT_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
const SCOPES = process.env.REACT_APP_HUBSPOT_SCOPES;
const REDIRECT_URI = process.env.REACT_APP_HUBSPOT_REDIRECT_URI;

const OAuthModal = ({ data }) => {
  const { name = '' } = data || {};

  const handleClick = () => {
    // TODO get URL from backend
    const authUrl =
      AUTH_URL +
      `?client_id=${encodeURIComponent(CLIENT_ID)}` +
      `&scope=${encodeURIComponent(SCOPES)}` +
      `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}` +
      `&state={"activeTab":"integrations","providerID":"${data.id}"}`;
    window.location.href = authUrl;
  };

  return (
    <div className={styles.oAuthContainer}>
      <h4>
        This app is requesting access to your {name} account. Continue connecting if you agree.
      </h4>
      <div className={styles.extraInfo}>
        {/* icon */}
        {extraInfo.map(({ icon, title, list }, index) => (
          <div key={index} className={styles.infoBlock}>
            <h3>{title}</h3>
            {list.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        ))}
      </div>
      <CustomButton onClick={handleClick}>Connect App</CustomButton>
    </div>
  );
};

export default OAuthModal;

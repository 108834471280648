import Organization from 'infrastructure/Organization';
import { subscriptionStore } from './Subscription.store';
import { queryClient } from 'hocs/QueryWrapper';

const Subscription = (function () {
  // Subscription product tiers
  const tiers = {
    freemium: 1,
    accelerate: 2,
    optimize: 3,
    basic: 4,
    beta: 5,
    customOptimize: 7,
  };

  const keys = {
    video: 'VIDEO_RECORDER',
    users: 'INVITE_USERS',
    modules: 'TRAINING_MODULES',
    nudge: 'USER_NUDGE',
    assessment: 'TRAINING_ASSESSMENTS',
    edit_logo: 'EDIT_LOGO',
    hubspot: 'HUBSPOT',
    stream: 'STREAM',
    upload_size: 'UPLOAD_SIZE',
    roleplay: 'ROLEPLAY',
  };

  const intervals = {
    YEAR: 'year',
    MONTHLY: 'month',
  };

  const getQuerySub = () => ({
    ...Organization.getSelectedOrg()?.subscription,
    ...queryClient.getQueryData(['subscription_data']),
  });
  const getSub = () => Organization.getSelectedOrg()?.subscription || {};

  const isTier = (tier) => getQuerySub()?.product_id === tier || getQuerySub()?.product === tier;

  const isBasic = () => isTier(tiers.basic);
  const isBeta = () => isTier(tiers.beta);
  const isAccelerate = () => isTier(tiers.accelerate);
  const isOptimize = () => isTier(tiers.optimize);
  const isCustom = () => isTier(tiers.customOptimize);
  // Consider user free except if it is in trial period
  const isFreemium = () => isTier(tiers.freemium) && !isInTrialPeriod();
  const isStarter = () => isTier(tiers.freemium);

  const isYearlyOptimize = () => {
    const { interval = '' } = getQuerySub();
    const isYearly = interval === intervals.YEAR;
    return isOptimize() && isYearly;
  };

  const hasPayment = () => isAccelerate() || isOptimize();

  const currentOrganizationTier = () => {
    const subscription = getQuerySub();
    return subscription?.product_id || subscription?.product;
  };

  // Feature access handler
  const hasAccessByTier = (
    tiersAccepted = [tiers.optimize, tiers.basic, tiers.beta, tiers.customOptimize]
  ) => tiersAccepted.includes(currentOrganizationTier());

  const isInTrialPeriod = () => {
    // A free user can access optimize features within first 14 days of subscription
    const trial = Organization.getSelectedOrg()?.is_free_trial_active;
    return trial;
  };

  const showInviteButton = (extra_users = 0) => {
    if (!isCustom()) return true;
    const currentUsers = getSub()?.active_users;
    const billedUsers = getSub()?.billed_users;
    return currentUsers + extra_users < billedUsers;
  };

  const features = [
    {
      key: keys.edit_logo,
      access: () => !isFreemium(),
      message:
        'Free plan does not allow branding, if you want more customization please update your plan.',
    },
    {
      key: keys.video,
      access: () => hasAccessByTier() || isInTrialPeriod(),
      message: 'Video recording is a feature for optimize organizations.',
    },
    {
      key: keys.users,
      access: (data) => {
        if (!isTier(tiers.freemium)) return true;

        const { billed_users, active_users } = getSub();
        return active_users + (data?.extraUsers || 0) < billed_users;
      },
      message: 'Free plan only includes 3 users, if you want to add more please update your plan.',
    },
    {
      key: keys.modules,
      access: () => {
        if (!isFreemium()) return true;

        const { total_modules } = getSub();
        return total_modules < 7;
      },
      message:
        'Free plan only includes 7 training modules, if you want to create custom modules please update your plan.',
    },
    {
      key: keys.nudge,
      access: () => {
        return !isFreemium();
      },
      message:
        'Free plan does not include user nudges, if you want to contact your sales reps update your plan.',
    },
    {
      key: keys.assessment,
      access: (data) => {
        if (!isFreemium()) return true;
        const { total_assessments } = getSub();
        return total_assessments + (data?.extra || 0) < 5;
      },
      message:
        'Free plan only includes 5 assessment questions, if you want to add more please update your plan.',
    },
    {
      key: keys.hubspot,
      access: () => {
        return hasAccessByTier() || isInTrialPeriod();
      },
      message: 'HubSpot integration is a feature only for Optimize users',
    },
    {
      key: keys.stream,
      access: () => {
        return hasAccessByTier() || isInTrialPeriod();
      },
      message: 'Pulse is a feature only for Optimize users',
    },
    {
      key: keys.upload_size,
      access: () => {
        return !isTier(tiers.freemium);
      },
      message: 'Free users can only upload files up to 75mb',
    },
  ];

  const show = (identifier, extraData) => {
    const feature = features.find((f) => f?.key === identifier);
    if (!feature) return { access: true };

    return {
      access: feature?.access(extraData),
      message: feature.message || '',
    };
  };

  // Returns true if user org subscription's has access to the feature
  // Optionally opens a modal if accessing a blocked feature
  const allow = ({ key, data, openModal = true }) => {
    const { access, message } = show(key, data);
    if (!access && openModal) subscriptionStore.setState({ isModalOpen: true, message });
    return access;
  };

  return {
    keys,
    allow,
    getSub,
    isAccelerate,
    isYearlyOptimize,
    isBeta,
    isBasic,
    isCustom,
    hasPayment,
    showInviteButton,
    isStarter,
    customID: tiers.customOptimize,
    tiers,
    intervals,
  };
})();

export default Subscription;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getHubspotSettingsService,
  getHubspotTrackingService,
  updateHubspotInfoService,
  updateHubspotSettingsService,
} from './Hubspot.services';
import { provider_cache_keys } from 'features/providers/Providers.queries';
import { FeatureFlag } from 'controllers/_exports';
import { useTrackingStore } from 'features/tracking/tracking.store';
import { useHubspotStore } from './Hubspot.store';

export const hubspotCacheKeys = {
  SETTINGS: 'hubspot_settings',
  TRACKING: 'hubspot_tracking',
};

export const useGetHubspotSettingsQuery = ({ enabled }) => {
  const setIsEnabled = useHubspotStore((state) => state?.setIsEnabled);
  const saveGoalsInStore = useTrackingStore((state) => state?.setHubSpotGoalsData);
  return useQuery([hubspotCacheKeys.SETTINGS], getHubspotSettingsService, {
    enabled,
    onSuccess: (response) => {
      setIsEnabled(!!response?.status);
      saveGoalsInStore(response);
    },
  });
};

export const useGetHubspotTrackingQuery = ({ userId, start_date, end_date }) => {
  const enabled = FeatureFlag.enabled('HUBSPOT');
  const saveHubspotTracking = useTrackingStore((state) => state?.setHubspotTracking);
  return useQuery(
    [hubspotCacheKeys.TRACKING, userId, start_date, end_date],
    () => getHubspotTrackingService({ userId, start_date, end_date }),
    {
      enabled,
      onSuccess: (res) => saveHubspotTracking(res?.length > 0 ? res[0] : {}),
    }
  );
};

export const useUpdateHubspotSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateHubspotSettingsService, {
    onSuccess: () => {
      queryClient.invalidateQueries([provider_cache_keys.PROVIDERS]);
      queryClient.invalidateQueries([hubspotCacheKeys.SETTINGS]);
    },
  });
};

export const useUpdateHubspotInfoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateHubspotInfoService, {
    onSuccess: () => {
      queryClient.invalidateQueries([hubspotCacheKeys.TRACKING]);
    },
  });
};

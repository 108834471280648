import React from 'react';
import RoleplaySelectAssign from './RoleplaySelectAssign';
import RoleplayDeadline from './RoleplayDeadline';
import RoleplayScenarios from './RoleplayScenarios';
import Organization from 'infrastructure/Organization';
import RoleplaySelectAssignRep from './RoleplaySelectAssignRep';

const RoleplayModalBodyDetails = () => {
  const isRep = Organization.isRep();

  return (
    <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
      <p className="heading5" style={{ marginBottom: '0.5rem' }}>
        Roleplay details
      </p>
      <RoleplayScenarios />
      {!isRep ? <RoleplaySelectAssign /> : <RoleplaySelectAssignRep />}
      <RoleplayDeadline />
    </div>
  );
};

export default RoleplayModalBodyDetails;

import React from 'react';

import styles from '../../Payment.module.scss';
import { usePaymentStore } from 'features/payment/Payment.store';
import { Payment } from 'features/payment/Payment.utils';

const ProductSummary = ({ processType }) => {
  // Payment store data
  const selectedProduct = usePaymentStore((state) => state?.selectedProduct);
  const { selectedPrice } = selectedProduct;

  // Free product
  if (selectedPrice?.isFree)
    return (
      <div className={styles.summaryCard}>
        <h3>Free plan - {selectedProduct?.name}</h3>
        <p>{selectedProduct?.description}</p>
      </div>
    );

  if ([Payment.PROCESS_TYPE.UPGRADE, Payment.PROCESS_TYPE.NEW_SUBSCRIPTION]?.includes(processType))
    return (
      <div className={styles.summaryCard}>
        {selectedPrice?.new_price >= 0 && (
          <h3 className={styles.strokedPrice}>{selectedPrice?.price}</h3>
        )}
        <h3 style={{ display: 'inline-block' }}>
          {selectedPrice?.new_price ?? selectedPrice?.price} {selectedPrice?.currency}
        </h3>
        <h4>
          {selectedProduct?.name} {selectedPrice?.name}
        </h4>
        <p>{selectedProduct?.description}</p>
        {processType === Payment.PROCESS_TYPE.NEW_SUBSCRIPTION && (
          <>
            <hr />
            <p>{Payment.getTrialText()}</p>
          </>
        )}
      </div>
    );

  return null;
};

export default ProductSummary;

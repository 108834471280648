import React from 'react';
import { Grid, Card } from '@material-ui/core';
import styles from './ProductCard.module.scss';
import UpgradeSubscriptionModal from 'features/payment/components/PaymentButtons/UpgradeSubscriptionModal';
import { Subscription } from 'controllers/_exports';
import PriceLabel from './PriceLabel';
import PriceCouponInfo from './PriceCouponInfo';

const ProductCard = ({ product = {}, subscription, index }) => {
  const { subscription_status } = subscription || {};
  const isOptimize = product?.id === Subscription.tiers.optimize;

  return (
    <Grid key={index} item xs={12} md={6}>
      <Card className={`${styles.card} ${isOptimize ? styles.cardOptimized : ''}`}>
        <div className={styles.cardHeader}>
          <div className={styles.headerText}>
            <div className={styles.first}>
              <div className={styles.mainLabel}>{product?.name}</div>
              {subscription_status && subscription_status?.value === 2 && (
                <div className={styles.label}>Active</div>
              )}
              {product?.price?.interval && (
                <div className={`${styles.labelFre} ${product?.id === 3 ? styles.labelFreOp : ''}`}>
                  {product?.price?.interval}ly
                </div>
              )}
              <div className={styles.badge}>
                <PriceCouponInfo product={product} isBadge />
              </div>
            </div>
            <div className={styles.second}>{product?.description}</div>
          </div>
          <PriceLabel isOptimize={isOptimize} product={product} />
        </div>
        <PriceCouponInfo product={product} />
        <div className={styles.cardActionButtons}>
          {Subscription.isYearlyOptimize() ? (
            <div className={styles.optimized}>
              <span>
                Congratulations! | <strong>You're already in the best plan</strong>
              </span>
            </div>
          ) : (
            <UpgradeSubscriptionModal />
          )}
        </div>
      </Card>
    </Grid>
  );
};

export default ProductCard;

import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { goalFrequencies } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import {
  OverviewTitle,
  OrganisationGoalsView,
  UsersSalesGoalView,
  UserCheckinCard,
  UserNotification,
} from '.';
import UserOverviewInitial from './UserOverviewIntitial';
import { getOrganisationGoalsOverviewApi, getSalesGoalsOverviewApi } from '../apiServices';
import Shimmer from './Shimmer';
import HubspotTrackingOverview from 'features/hubspot/components/HubspotTrackingOverview/HubspotTrackingOverview';
import { FeatureFlag } from 'controllers/_exports';
import RefreshHubspotData from 'features/hubspot/components/RefreshHubspotData';
import FunnelGraphContainer from 'features/funnel/components/FunnelGraphContainer/FunnelGraphContainer';

export default function UserOverview(props) {
  const {
    chekcinOpenSet,
    currentCheckin,
    checkinExist,
    weekCheckinExist,
    latestCheckin,
    role,
    refrsh,
    hanldeEditCheckin,
  } = props;
  const [tab, tabSet] = useState(1);
  const [downloadReportLoader, downloadReportLoaderSet] = useState(false);
  const [sOverview, sOverviewSet] = useState(true);
  const [orgOverview, orgOverviewSet] = useState(true);
  const [organisationOverview, setOrganisationOverview] = useState('');
  const [salesOverview, setSalesOverview] = useState('');
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('userInfo'))?.user?.uuid);
  const [organisationAppliedFilters, setOrganisationAppliedFilters] = useState({});
  const [salesAppliedFilters, setSalesAppliedFilters] = useState({});
  const [organisationLoader, setOrganisationLoader] = useState(false);
  const [salesLoader, setSalesLoader] = useState(false);

  useEffect(() => {
    if (props.tabReset) {
      tabSet(1);
    }
  }, [props.tabReset]);

  const fetchOrganisationGoalsOverview = (query = {}) => {
    // orgOverviewSet(true);
    setOrganisationLoader(true);
    setOrganisationAppliedFilters(query);
    getOrganisationGoalsOverviewApi({ ...query, user: userId }).then((response) => {
      orgOverviewSet(false);
      setOrganisationLoader(false);
      if (response.statusCode == 200) {
        setOrganisationOverview(response.data[0] ? response.data[0] : {});
      }
    });
  };
  const fetchSalesGoalsOverview = (query = {}) => {
    // sOverviewSet(true);
    setSalesLoader(true);
    setSalesAppliedFilters(query);
    getSalesGoalsOverviewApi({ ...query, user: userId }).then((response) => {
      setSalesLoader(false);
      sOverviewSet(false);
      if (response.statusCode == 200) {
        setSalesOverview(response.data[0] ? response.data[0] : {});
      }
    });
  };
  useEffect(() => {
    if (checkinExist && userId) {
      fetchOrganisationGoalsOverview();
      fetchSalesGoalsOverview();
    }
  }, [checkinExist, refrsh]);

  return (
    <>
      {!checkinExist ? (
        <UserOverviewInitial chekcinOpenSet={chekcinOpenSet} currentCheckin={currentCheckin} />
      ) : sOverview || orgOverview ? (
        <Shimmer type="UserOverview" />
      ) : (
        <section className={styles.userOverviewGoalsSection + ' ' + styles.overViewSection}>
          <div className={styles.overviewWrapper}>
            <Grid container spacing={3} direction="row" className={styles.topRow}>
              <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
                  <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}>
                    <OverviewTitle
                      image="organisation_goals.svg"
                      title="Sales Goals"
                      frequency={organisationOverview?.frequency_text}
                      description={
                        organisationOverview?.description ? organisationOverview?.description : ''
                      }
                      handleApplyFilter={fetchOrganisationGoalsOverview}
                      appliedFilters={organisationAppliedFilters}
                      minDate={organisationOverview?.published_date}
                      defaultFilter={{
                        start_date: organisationOverview?.filter_from_date,
                        end_date: organisationOverview?.filter_end_date,
                      }}
                      filterFrequency={organisationOverview?.frequency}
                    />
                    <OrganisationGoalsView
                      overview={organisationOverview}
                      role={role}
                      loader={organisationLoader}
                    />
                  </div>
                )}
                <div className={styles.salesWrapper + ' ' + styles.contentOverviewWrapper}>
                  <OverviewTitle
                    image="sales_goals.svg"
                    title="Sales Activity"
                    frequency={salesOverview?.frequency_text}
                    description={salesOverview?.description ? salesOverview?.description : ''}
                    handleApplyFilter={fetchSalesGoalsOverview}
                    appliedFilters={salesAppliedFilters}
                    filterFrequency={salesOverview?.frequency}
                    minDate={salesOverview?.published_date}
                    defaultFilter={{
                      start_date: salesOverview?.filter_from_date,
                      end_date: salesOverview?.filter_end_date,
                    }}
                    type="salesGoals"
                    noMargin
                  />
                  <FunnelGraphContainer isOverview={true} />
                  {FeatureFlag.enabled('SWAP_SALES_GOALS') && <RefreshHubspotData id={userId} />}
                  <UsersSalesGoalView
                    appliedFilters={salesAppliedFilters}
                    overview={salesOverview}
                    userId={userId}
                    loader={salesLoader}
                  />
                </div>
                {!FeatureFlag.enabled('SWAP_SALES_GOALS') && (
                  <HubspotTrackingOverview userId={userId} filters={salesAppliedFilters} />
                )}
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                <UserCheckinCard
                  currentCheckin={currentCheckin}
                  weekCheckinExist={weekCheckinExist}
                  chekcinOpenSet={chekcinOpenSet}
                  latestCheckin={latestCheckin}
                  hanldeEditCheckin={hanldeEditCheckin}
                />
              </Grid>
            </Grid>
          </div>
        </section>
      )}
      {/* <UserNotification /> */}
    </>
  );
}

import React, { useMemo } from 'react';
import { CustomButton } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { PATH_STATUS, usePathValidations } from '../../LearningPaths.utils';
import { useUpdateStatusLearningPath } from '../../LearningPaths.queries';
import { TooltipCustom } from 'utils';

const ChangeStatusButton = () => {
  const { learningPath, contents } = useLearningPathsStore();
  const { isPathOwner } = usePathValidations({ learningPath });

  const enabled = useMemo(
    () => contents?.length > 0 && learningPath?.users?.length > 0,
    [contents, learningPath?.users]
  );

  const label = useMemo(() => {
    if (contents?.length === 0 && learningPath?.users?.length === 0)
      return 'add contents and assign users';
    if (contents?.length === 0) return 'add contents';
    if (learningPath?.users?.length === 0) return 'assign users';
  }, [contents, learningPath?.users]);

  const { mutate, isLoading } = useUpdateStatusLearningPath();

  const toPublish = () => {
    if (!enabled) return;
    mutate({ status: PATH_STATUS.PUBLISH, learningPathUuid: learningPath?.uuid });
  };

  if (!isPathOwner) return null;

  if (learningPath?.status === PATH_STATUS.PUBLISH) return null;
  if (learningPath?.status === PATH_STATUS.DRAFT && !enabled)
    return (
      <TooltipCustom title={`Please ${label} in order to publish`} placement="top">
        <div>
          <CustomButton onClick={toPublish} disabled className="primaryBtn">
            Publish
          </CustomButton>
        </div>
      </TooltipCustom>
    );

  if (learningPath?.status === PATH_STATUS.DRAFT && enabled)
    return (
      <CustomButton onClick={toPublish} disabled={isLoading} className="primaryBtn">
        Publish
      </CustomButton>
    );

  return null;
};

export default ChangeStatusButton;

import React, { useState, useEffect } from 'react';
import Card from './Card';
import styles from './SeeLearningPaths.module.scss';
import { Grid, List, ListItem } from '@material-ui/core';
import { useGetListLearningPathsQuery } from '../../LearningPaths.queries';
import NoResults from '../NoResults/NoResults';

const TABS = {
  PUBLISHED: 'published',
  DRAFTS: 'drafts',
};

const SeeLearningPaths = () => {
  const { data: learningPaths = {}, isLoading } = useGetListLearningPathsQuery();
  const [learningPathsList, setLearningPathsList] = useState(learningPaths?.list || []);
  const [selected, setSelected] = useState(TABS.PUBLISHED);

  const handleChangeTab = (actualTab) => {
    setSelected(actualTab !== TABS.PUBLISHED ? TABS.DRAFTS : TABS.PUBLISHED);
  };

  useEffect(() => {
    if (!Object.keys(learningPaths).length > 0) return;
    if (selected === TABS.PUBLISHED) {
      setLearningPathsList(
        learningPaths?.list.filter((learningPath) => learningPath.published_at !== null)
      );
    } else {
      setLearningPathsList(
        learningPaths?.list.filter((learningPath) => learningPath.published_at === null)
      );
    }
  }, [selected, learningPaths]);

  useEffect(() => {
    if (learningPaths?.total_published === 0 && learningPaths.my_total_drafts > 0)
      setSelected(TABS.DRAFTS);
    if (learningPaths?.total_published > 0 && learningPaths.my_total_drafts === 0)
      setSelected(TABS.PUBLISHED);
  }, [learningPaths]);

  if (!isLoading && learningPaths.total_published === 0 && learningPaths.my_total_drafts === 0)
    return <NoResults />;
  
  return (
    <>
      <List className="listInline tabList" style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
        {learningPaths?.total_published > 0 && (
          <ListItem
            key={'Published'}
            className={`listInlineItem heading6 ${selected === 'published' ? 'active' : ''}`}
            onClick={() => handleChangeTab('published')}
          >
            Published
          </ListItem>
        )}
        {learningPaths?.my_total_drafts > 0 && (
          <ListItem
            key={'Drafts'}
            className={`listInlineItem heading6 ${selected === 'published' ? '' : 'active'}`}
            onClick={() => handleChangeTab('drafts')}
          >
            Drafts
          </ListItem>
        )}
      </List>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={styles.seeLearningPaths}
      >
        {learningPathsList?.map((learningPath) => {
          return (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Card learningPath={learningPath} selected={selected} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SeeLearningPaths;

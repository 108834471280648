import React from 'react';
import styles from './Picture.module.scss';
import { Avatar } from '@material-ui/core';

const Picture = ({ profile_pic = '', first_name = '', email = '' }) => {
  
  if (profile_pic)
    return (
      <img className={styles.profilePic + ' profilePic'} src={profile_pic} alt="Profile pic" />
    );

  return (
    <Avatar className={styles.avatar + ' avatar'}>
      {first_name ? first_name.substring(0, 1) : email ? email.substring(0, 1) : ''}
    </Avatar>
  );
};

export default Picture;

import styles from '../../../assets/styles/onboarding.module.scss';
import { Grid } from '@material-ui/core';
import { IndexSidebar, IntroductionCard, UploadedFilePreview } from './';

export default function OnboardingLibrary(props) {
  const {
    steps,
    handleChangeStep,
    activeStep = {},
    handleAddCustomStep,
    handleMarkAsComplete,
    handleReorderStep,
    handleDeleteCustomStep,
    handleEnabelDisable,
    handleUpdateContent,
    isAdmin,
    onboardinglikeDislike,
    updateActiveStep,
    isPublished,
    handleMarkAsRead,
    showModal, 
    setShowModal,
    time, 
    setTime
  } = props;
  return (
    <section className={styles.onboardingStepsection}>
      <Grid container direction="row" spacing={2} className={styles.stepsRow}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <IndexSidebar
            steps={steps}
            handleChangeStep={handleChangeStep}
            activeStep={activeStep}
            handleAddCustomStep={handleAddCustomStep}
            handleReorderStep={handleReorderStep}
            isAdmin={isAdmin}
            // disabledSteps={steps.filter(step => step.status === 0)}
            // steps={steps.filter(step => step.status === 1)}
            // handleDeleteCustomStep={() => setDeleteContent(true)}
          />
        </Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          {activeStep.is_content_added || !isAdmin ? (
            <UploadedFilePreview
              handleDeleteCustomStep={handleDeleteCustomStep}
              handleEnabelDisable={handleEnabelDisable}
              handleEditContent={handleUpdateContent}
              activeStep={activeStep}
              isAdmin={isAdmin}
              handleLikeDislike={onboardinglikeDislike}
              handleMarkAsComplete={handleMarkAsComplete}
              handleMarkAsRead={handleMarkAsRead}
              updateActiveStep={updateActiveStep}
              isPublished={isPublished}
              setShowModal={setShowModal}
              showModal={showModal}
              time={time}
              setTime={setTime}
              page="onboarding"
            />
          ) : (
            <IntroductionCard
              activeStep={activeStep}
              handleEnabelDisable={handleEnabelDisable}
              handleAddContent={handleUpdateContent}
            />
          )}
        </Grid>
      </Grid>
    </section>
  );
}

import { useEffect } from 'react';
import { FeatureFlag } from 'controllers/_exports';
import { useSalesRepView } from '../SalesRepView.hook';

const SalesRepViewHandler = () => {
  const { isPathSupported, isActive, toastUnsupported, switchOff } = useSalesRepView({
    requestValue: true,
  });

  // Handle unsupported paths
  useEffect(() => {
    if (!FeatureFlag.enabled('SALES_REP_POV')) return;
    if (!isPathSupported && isActive) {
      toastUnsupported();
      switchOff({ withToast: false });
    }
  }, [isPathSupported, isActive, switchOff, toastUnsupported]);

  return null;
};

export default SalesRepViewHandler;

import React, { useMemo } from 'react';
import User from 'infrastructure/User';
import styles from './AssignedLearningPathDetails.module.scss';
import ContentsChip from './ContentsChip';
import DateChip from './DateChip';
import { CustomButton } from 'shared_ui_components';
import { useHistory } from 'react-router-dom';
import { REP_PATH_STATUS } from '../../LearningPaths.utils';

const LearningPathTopCard = ({ learningPath }) => {
  const history = useHistory();
  const {
    title = '',
    description = '',
    created_by = {},
    modules_completion = 0,
    modules_in_progress = 0,
    total_contents = 0,
    updated_at,
  } = learningPath;

  const resume = () => {
    let id = '';
    learningPath?.contents.forEach((content) => {
      if (content?.status !== REP_PATH_STATUS.COMPLETED && !id) id = content?.content?.uuid;
    });
    history.push(`/training/${id}`, { rep_lp_id: learningPath.uuid });
  };

  const completedStyles = useMemo(
    () => ({
      left: 0,
      width: `${Math.round((modules_completion * 100) / total_contents)}%`,
    }),
    [modules_completion, total_contents]
  );

  const progressStyles = useMemo(
    () => ({
      left: completedStyles.width,
      width: `${Math.round((modules_in_progress * 100) / total_contents)}%`,
    }),
    [modules_in_progress, total_contents, completedStyles]
  );

  return (
    <div className={styles.topCard}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>{title}</span>
        <div className={styles.authorContainer}>
          <span> by </span>
          <span className={styles.author}>{User.getUserName(created_by)}</span>
        </div>
      </div>
      <p className={styles.description}>{description}</p>
      <div>
        <div>
          <strong className={styles.countStrong}>
            {modules_completion}/{total_contents}
          </strong>
          <span className={styles.count}> Modules completed</span>
        </div>
        <div className={styles.progressBar}>
          <div className={styles.completed} style={completedStyles} />
          <div className={styles.progress} style={progressStyles} />
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <ContentsChip total={total_contents} />
          <DateChip date={updated_at} isUpdated />
        </div>
        {total_contents !== modules_completion && (
          <CustomButton className="primaryBtn" onClick={resume}>
            Resume
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default LearningPathTopCard;

import Organization from 'infrastructure/Organization';
import React, { useMemo } from 'react';
import { CustomCheckbox, CustomSwitch, CustomTooltipWhite } from 'shared_ui_components';
import styles from './UserSettings.module.scss';

const SilentMode = ({ settings = {}, onChange, variant = 'switch' }) => {
  const showSilentMode = useMemo(
    () => Organization.isAdmin() || Organization.isOwner() || Organization.isLead,
    []
  );

  const checked = useMemo(() => !!settings?.silent_mode, [settings]);

  if (!showSilentMode) return null;

  return (
    <div style={optionStyles}>
      <CustomTooltipWhite
        tooltipIcon="info_icon.svg"
        title={
          <div className="customTooltipContent">
            <p className="supportText">
              Silent mode allow your sales reps to see check-ins and tracking information without
              being considered in your organization goals statistics
            </p>
          </div>
        }
      />
      <label>Silent mode</label>
      {variant === 'switch' && (
        <CustomSwitch
          defaultChecked={true}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
      {variant === 'checkbox' && (
        <div className={styles.silentCheckbox}>
          <CustomCheckbox value={checked} onChange={(value) => onChange(value)} />
        </div>
      )}
    </div>
  );
};

export default SilentMode;

const optionStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
};

import React, { useMemo } from 'react';
import { useUpdateHubspotInfoMutation } from '../Hubspot.queries';
import HubspotUtils from '../Hubspot.utils';
import { imageBasePath } from 'constants';
import { useTrackingStore } from 'features/tracking/tracking.store';
import { FeatureFlag } from 'controllers/_exports';

const RefreshHubspotData = ({
  data,
  lastUpdate,
  align = 'end',
  id = '',
  translate = true,
  refetchCheckIns = () => null,
}) => {
  const storeData = useTrackingStore((s) => s?.hubspotTrackingData);
  const { mutate, isLoading } = useUpdateHubspotInfoMutation();

  const onClick = () => {
    mutate({ id }, { onSuccess: () => refetchCheckIns() });
  };

  // Data handler
  const _data = useMemo(
    () => (FeatureFlag.enabled('SWAP_SALES_GOALS') ? storeData : data),
    [data, storeData]
  );

  const text = useMemo(() => {
    const updated = lastUpdate || _data?.last_update;
    return updated || '';
  }, [_data?.last_update, lastUpdate]);

  const show = useMemo(() => {
    const hasGoals = (_data?.goals || []).length > 0;
    return hasGoals && HubspotUtils.isIntegrated() && !!text;
  }, [text, _data?.goals]);

  if (!show) return null;
  return (
    <div
      style={{
        textAlign: align,
        transform: translate ? 'translateY(-12px)' : '',
        margin: translate ? '' : '1rem 0',
      }}
    >
      {text && (
        <span
          style={{
            background: '#e5e9ec',
            padding: '2px 8px',
            margin: '0 8px',
            borderRadius: '4px',
          }}
        >
          <img
            alt=""
            style={{ transform: 'translateY(3px)', marginRight: '4px' }}
            src={imageBasePath + 'hubspot_logo.svg'}
            height={16}
          />
          {text}
        </span>
      )}
      {!isLoading && (
        <span style={{ cursor: 'pointer' }} onClick={onClick}>
          Refresh ⟲
        </span>
      )}
      {isLoading && <span>Loading...</span>}
    </div>
  );
};

export default RefreshHubspotData;

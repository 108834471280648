import React, { useCallback, useContext, useMemo } from 'react';
import { List, ListItem } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { imageBasePath } from '../constants';
import { roleConfig } from './roleConfiguration';
import Hidden from '@material-ui/core/Hidden';
import { GlobalContext } from '../context/GlobalContext';
import { showSubscriptionMenuButton } from '../features/payment/Payment.utils';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';
import Organization from 'infrastructure/Organization';

const PATHS = {
  HOME: '/',
  PULSE: '/pulse',
  RECRUITMENT: '/recruitment',
  ONBOARDING: '/onboarding',
  TRAINING: '/training',
  TRACKING: '/tracking',
  ROLEPLAY: '/roleplay',
  USERS: '/users',
  CHAT: '/chatAssist',
  SETTINGS: '/settings',
  BILLING: '/subscription',
};

const ENABLED_IN_REP_VIEW = [PATHS.TRAINING, PATHS.ONBOARDING];

function Sidebar() {
  const { state } = useContext(GlobalContext);
  const { isActive } = useSalesRepView();
  let location = useLocation();

  const items = useMemo(
    () => [
      { path: PATHS.HOME, label: 'Home', img: 'home' },
      {
        path: PATHS.PULSE,
        label: 'Pulse',
        img: 'stream',
        onClick: () => Subscription.allow({ key: Subscription.keys.stream }),
        disabled: !Subscription.allow({ key: Subscription.keys.stream, openModal: false }),
      },
      { path: PATHS.RECRUITMENT, label: 'Recruit', img: 'recruit' },
      { path: PATHS.ONBOARDING, label: 'Onboard', img: 'onboard' },
      { path: PATHS.TRAINING, label: 'Train', img: 'training' },
      { path: PATHS.TRACKING, label: 'Track', img: 'track' },
      { path: PATHS.ROLEPLAY, label: 'Roleplay', img: 'roleplay' },
      { path: PATHS.USERS, label: 'Users', img: 'users' },
      { path: PATHS.CHAT, label: 'Assist', img: 'assist' },
      { path: PATHS.SETTINGS, label: 'Setting', img: 'settings' },
      { path: PATHS.BILLING, label: 'Billing', img: '' },
    ],
    []
  );

  const show = useCallback(
    (path) => {
      if (isActive) return ENABLED_IN_REP_VIEW.includes(path);
      try {
        switch (path) {
          case PATHS.HOME:
          case PATHS.TRAINING:
          case PATHS.ONBOARDING:
          case PATHS.TRACKING:
            return true;

          case PATHS.PULSE:
            return FeatureFlag.enabled('STREAM');
          case PATHS.ROLEPLAY:
            return FeatureFlag.enabled('ROLEPLAY');

          case PATHS.USERS:
            return roleConfig[state.role?.id].canInviteUser;
          case PATHS.CHAT:
            return (
              FeatureFlag.enabled('CHAT_ASSISTANT') && Organization.getSelectedOrg()?.ai_integration
            );
          case PATHS.SETTINGS:
            return roleConfig[state.role?.id].settings;

          case PATHS.BILLING:
            return showSubscriptionMenuButton({ role: state?.role?.slug });
          case PATHS.RECRUITMENT:
            return (
              Organization.getSelectedOrg()?.recruiting_module_enabled &&
              roleConfig[state.role?.id].recruitment
            );

          default:
            return false;
        }
      } catch (error) {
        return false;
      }
    },
    [isActive, state?.role]
  );

  const className = (path) =>
    `listItem ${
      (path === PATHS.HOME ? location.pathname === path : location.pathname?.includes(path))
        ? 'active'
        : ''
    }`;

  const getImg = ({ path, active = true }) => {
    const item = items.find((i) => i.path === path);
    let img = `${item.img}_${active ? 'active' : 'default'}.svg`;
    if (path === PATHS.BILLING)
      img = active ? 'subscription_active2.png' : 'subscription_default2.png';
    const src = imageBasePath + img;
    return <img className={active ? 'activeImg' : 'defaultImg'} src={src} alt={item.label} />;
  };

  return (
    <Hidden>
      <div className="sideBar">
        <img className="topRadiusPattern" src={imageBasePath + 'top_radius_pattern.svg'} alt="" />
        <div className="menuListWrapper">
          <List className="listUnstyled">
            {items.map(({ label, path, onClick = () => null, disabled = false }) => {
              return !show(path) ? null : (
                <ListItem className={className(path)}>
                  <Link to={disabled ? (l) => l : path} onClick={onClick}>
                    {getImg({ path, active: true })}
                    {getImg({ path, active: false })}
                    <p className="supportText">{label}</p>
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <div className="sales-Img">
        <img src={imageBasePath + 'logo_single.png'} alt="Subs" />
      </div>
    </Hidden>
  );
}

export default Sidebar;

import React, { useMemo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { imageBasePath } from 'constants';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayHeader from '../Common/RoleplayHeader';
import styles from './SubmitReview.module.scss';
const SubmitReviewHeader = () => {
    let location = useLocation();
  let history = useHistory();
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [scenarios, setScenarios] = useState([]);
  const handleBack = () => {
    history.push({
      pathname: `/roleplay`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };
  useEffect(() => {
    setScenarios(roleplayView?.scenarios || []);
  }, [roleplayView]);

  const scenariosCompleted = useMemo(
    () => scenarios.filter((item) => item.response && item.response.video).length,
    [scenarios]
  );
  return (
    <div className={styles.roleplayHeader}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
          <button onClick={handleBack} className={styles.backBtn}>
            <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
          </button>
          <p className="heading4">{roleplayView?.name || ''}</p>
        </div>
        <div>
          <span className={`${styles.value} heading6`}>
            {`${scenariosCompleted || 0}/${scenarios?.length || 0}`}{' '}
          </span>
          <span className="supportText" style={{ marginTop: 2 }}>
            Scenarios
          </span>
        </div>
      </div>
      <RoleplayHeader roleplayView={roleplayView} leadView={true} isForSubmitView />
    </div>
  );
}

export default SubmitReviewHeader
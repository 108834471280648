import React, { useEffect, useMemo, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { useRecorderStore } from '../shared_ui_components/VideoRecorder/VideoRecorder.store';

export default function FileUploader(props) {
  const { acceptedFiles, setAcceptedFiles, setFormData } = props;
  let [loaders, setLoaders] = useState([]);
  // specify upload params and url for your files
  const getUploadParams = ({ meta, file }) => {
    const suggest_duration = ((Math.ceil(meta?.duration / 60)) * 60) || 300;
    setFormData((prev) => ({ ...prev, suggest_duration }));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.user) {
      let body = new FormData();
      body.append('file', file, file.name);
      return {
        url: `${process.env.REACT_APP_BASE_URL}/file/upload/`,
        body,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: userInfo.access,
        },
      };
    }
  };

  // Handle recording file
  const singleRecordedVideo = useRecorderStore((s) => s?.video);
  const recordedVideos = useRecorderStore((s) => s?.recordedVideos);
  const removeRecording = useRecorderStore((s) => s.cleanVideo);

  const recordedVideo = useMemo(() => {
    try {
      if (props?.page === 'assessment') {
        const { video } = recordedVideos.find((v) => v?.id === props?.finalRecordingID);
        return video;
      }
      return singleRecordedVideo;
    } catch (error) {
      return null;
    }
  }, [singleRecordedVideo, props?.page, recordedVideos, props?.finalRecordingID]);

  useEffect(() => {
    return function cleanup() {
      // Removes video recording when modal closes
      if (recordedVideo) removeRecording();
    };
  }, [recordedVideo, removeRecording]);

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === 'getting_upload_params') {
      setLoaders([...loaders, loaders.length + 1]);
      props.onImageUpload(false);
    }
    if (status === 'removed') {
      if (recordedVideo) removeRecording();
      if (!acceptedFiles.filter((acceptedFile) => acceptedFile.name === file.name).length) {
        setLoaders(loaders.filter((loader) => loader !== loaders.length));
        if (loaders.filter((loader) => loader !== loaders.length).length == 0) {
          props.onImageUpload(true);
        }
      }
      const files = acceptedFiles.filter((acceptedFile) => acceptedFile.name !== file.name);
      if (!files.length) {
        props.onImageUpload(false);
      }
      setAcceptedFiles(files);
    }

    if (status === 'done') {
      if (recordedVideo) removeRecording();

      let newFiles = {
        name: file.name,
        ...JSON.parse(xhr.response),
      };
      const files = [...acceptedFiles, ...[newFiles]];
      setAcceptedFiles(files);
      setLoaders(loaders.filter((loader) => loader !== loaders.length));
      if (loaders.filter((loader) => loader != loaders.length).length === 0) {
        props.onImageUpload(true);
        props.uploadImage(files);
      }
    }
  };

  const disabled = useMemo(() => {
    if (props?.page !== 'assessment') return false;
    return !!recordedVideo;
  }, [props?.page, recordedVideo]);

  return (
    <div>
      <Dropzone
        initialFiles={!!recordedVideo ? [recordedVideo] : []}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept={props.accept}
        inputContent={props.inputContent}
        maxFiles={props.maxFiles}
        PreviewComponent={props.PreviewComponent}
        maxSizeBytes={props.maxSizeBytes}
        disabled={disabled}
      />
    </div>
  );
}

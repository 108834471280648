import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from '../Payment.module.scss';
import PaymentForm from '../PaymentForm/PaymentForm';
import { Subscription } from 'controllers/_exports';
import { Payment } from '../../Payment.utils';

const UpgradeSubscriptionModal = ({ text = 'Upgrade plan' }) => {
  const [showModal, setShowModal] = useState(false);
  const onSuccess = () => setShowModal(false);

  const sub = Subscription.getSub();

  const hide = useMemo(
    () => Subscription.isYearlyOptimize() || Subscription.isCustom() || Subscription.isBeta(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sub]
  );

  if (hide) return null;
  return (
    <>
      <button onClick={() => setShowModal(true)} className={styles.updateButton}>
        {text}
      </button>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent className={styles.paymentDialog}>
          <PaymentForm
            fullPage={false}
            onSuccess={onSuccess}
            processType={Payment.PROCESS_TYPE.UPGRADE}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpgradeSubscriptionModal;

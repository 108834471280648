import React from 'react';
import InputText from 'shared_ui_components/InputText';
import styles from './TrackTime.module.scss';

const Tracktime = ({ formData, setFormData }) => {

  return (
    <>
      <p className="supportText">Content suggested duration</p>
      <div className={styles.inputMinutes}>
        <InputText
          type="number"
          onChange={(e) => {
            setFormData({ ...formData, suggest_duration: parseInt(e.target.value) * 60 });
          }}
          value={Math.round(formData?.suggest_duration / 60)}
          InputProps={{ inputProps: { min: 1 } }}
        />
        <p className={styles.inputLabel}>Minutes</p>
      </div>
    </>
  );
};

export default Tracktime;

import React, { useMemo } from 'react';
import styles from './ReviewQualifiedRoleplay.module.css';
import { CustomButton } from 'shared_ui_components';

const QualifiedNavigationButtons = ({
  scenarios = [],
  selectedScenario = {},
  changeScenario = () => null,
}) => {
  const index = useMemo(
    () => scenarios.findIndex((r) => r?.uuid === selectedScenario?.uuid),
    [scenarios, selectedScenario]
  );

  const showPrev = useMemo(() => index > 0, [index]);
  const showNext = useMemo(() => index >= 0 && index < scenarios?.length - 1, [index, scenarios]);

  const onPrev = () => showPrev && changeScenario(-1);
  const onNext = () => showNext && changeScenario(1);

  if (scenarios?.length <= 1) return null;
  return (
    <div className={styles.navigationButtons}>
      {showPrev && (
        <CustomButton className="secondaryBtn" onClick={onPrev}>
          Previous Scenario
        </CustomButton>
      )}
      {showNext && <CustomButton onClick={onNext}>Next</CustomButton>}
    </div>
  );
};

export default QualifiedNavigationButtons;

import React from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

export default function EmailVerifiedCard(props) {
  return (
    <div className={styles.cardWrapper + ' ' + styles.passwordSuccesWrapper}>
      <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
      <img
        className={styles.emailVerified}
        src={imageBasePath + 'password_recovered.svg'}
        alt="salestable"
      />
      <h1 className="heading3">Thank you!</h1>
      <p className="paragraph">
        We've sent password reset instructions to your email address. If no email is received within
        ten minutes, please check that you've submitted the correct address.
      </p>
      <div className="hidden">
        <CustomLink
          linktext="Continue"
          className={`primaryBtn submitBtn`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
}

import React, { useMemo } from 'react';
import styles from './MyTeam.module.scss';
import { STATUS_ID } from 'features/roleplay/RoleplayConstants';
import { CustomButton } from 'shared_ui_components';
import { useLocation, useHistory } from 'react-router-dom';

const MyTeamButton = ({ status, roleplay }) => {
  let location = useLocation();
  let history = useHistory();
  
  const disabled = useMemo(() => {
    if (roleplay?.is_submitted) return false;
    return ![STATUS_ID.QUALIFIED, STATUS_ID.SUBMITTED, STATUS_ID.FAILED].includes(status?.id);
  }, [status?.id, roleplay]);

  const qualified = useMemo(() => {
    return [STATUS_ID.QUALIFIED, STATUS_ID.FAILED].includes(status?.id);
  }, [status?.id]);

  const label = useMemo(() => (qualified ? 'Details' : 'Review'), [qualified]);
  const style = useMemo(() => (qualified ? 'secondaryBtn' : 'primaryBtn'), [qualified]);

  const handleClick = () => {
    if (disabled) return;
    if (qualified) return history.push(`/roleplay-review-qualified/${roleplay?.uuid}`);
    history.push({
      pathname: `/roleplay-review/${roleplay?.uuid}`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };

  return (
    <CustomButton
      onClick={handleClick}
      className={`${style} ${styles.teamButton}`}
      disabled={disabled}
    >
      {label}
    </CustomButton>
  );
};

export default MyTeamButton;

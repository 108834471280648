import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import icon from 'assets/hubspot/tooltip.svg';

const TooltipCustom = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: '0.9rem',
    padding: '0.125rem 0.75rem; ',
    boxShadow: '0px 4px 9px rgba(170, 170, 170, 0.25)',
    borderRadius: '4px',
    maxWidth: 230,
  },
  arrow: {
    color: '#ffffff',
  },
}))(Tooltip);

export default function HubspotTooltip() {
  return (
    <TooltipCustom title={<div>This data is taken from HubSpot</div>} arrow>
      <img alt="" src={icon} />
    </TooltipCustom>
  );
}

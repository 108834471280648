import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { imageBasePath } from 'constants';
import styles from './integrationModal.module.scss';
import OAuthModal from './Modals/OAuth/OAuthModal';

const IntegrationModal = ({ open = false, onClose = () => null, data }) => {
  const { type = 'oauth', color = '#FF5D36' } = data || {};

  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      className={`dialog ${styles.integrationModalContainer}`}
    >
      <DialogTitle className={styles.dialogTitleContainer}>
        <div className={styles.dialogTitle}>
          <img src={imageBasePath + 'logo_single.png'} alt="Salestable" />
          <div className={styles.dots}>
            <div style={{ backgroundColor: color }} />
            <div style={{ backgroundColor: color }} />
            <div style={{ backgroundColor: color }} />
          </div>
          <img src={data?.logo} alt={data?.name} />
        </div>

        <p className={styles.title}>
          Connecting <b>SalesTable</b> with <b>{data?.name}</b>
        </p>

        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className="modalCloseIcon"
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>{type === 'oauth' && <OAuthModal data={data} />}</DialogContent>
    </Dialog>
  );
};

export default IntegrationModal;

import React from 'react';
import styles from './MyTeam.module.scss';
import { Avatar, Grid } from '@material-ui/core';
import User from 'infrastructure/User';
import RoleplayLabel from '../Common/RoleplayLabel';
import RoleplayRating from '../Common/RoleplayRating/RoleplayRating';
import MyTeamButton from './MyTeamButton';
import UnassignRoleplay from '../UnassignRoleplay/UnassignRoleplay';

const MyTeamTableRow = ({ roleplayData, isHeader }) => {
  if (isHeader)
    return (
      <Grid container direction="row" spacing={0} alignItems="center" className={styles.rowHeader}>
        <Grid item lg={3}>
          <div className={`${styles.headerCell} ${styles.userCell}`}>Users</div>
        </Grid>
        <Grid item lg={2}>
          <div className={`${styles.headerCell} ${styles.roleplayCell}`}>Roleplay</div>
        </Grid>
        <Grid item lg={3}>
          <div className={`${styles.headerCell}`}>Status</div>
        </Grid>
        <Grid item lg={2}>
          <div className={`${styles.headerCell}`}>Rating</div>
        </Grid>
        <Grid item lg={2}>
          <div className={`${styles.headerCell}`}>Action</div>
        </Grid>
      </Grid>
    );

  return (
    <Grid container direction="row" spacing={0} alignItems="center" className={styles.row}>
      <Grid item lg={3}>
        <div className={`${styles.cell} ${styles.userCell}`}>
          <Avatar src={roleplayData?.user?.profile_pic}>
            {User.getInitial(roleplayData?.user)}
          </Avatar>
          <div className={styles.info}>
            <h1>{User.getUserName(roleplayData?.user)}</h1>
            <span>{roleplayData?.roleplay_summary}</span>
          </div>
        </div>
      </Grid>
      <Grid item lg={2}>
        <div className={`${styles.cell} ${styles.roleplayCell}`}>
          <h1>{roleplayData?.name}</h1>
          {!!roleplayData?.total_scenarios && (
            <span>{roleplayData?.total_scenarios} scenarios</span>
          )}
        </div>
      </Grid>
      <Grid item lg={3}>
        <div className={`${styles.cell} ${styles.statusOnDate}`}>
          <RoleplayLabel
            {...(roleplayData?.archive?.status ? roleplayData?.archive : roleplayData?.status)}
            width="70px"
          />
          <span className={styles.dateLabel}>
            {roleplayData?.date !== '-' ? `on ${roleplayData?.date}` : ''}
          </span>
        </div>
      </Grid>
      <Grid item lg={2}>
        <div className={`${styles.cell} `}>
          {typeof roleplayData?.stars === 'number' ? (
            <RoleplayRating withTooltip stars={roleplayData?.stars} />
          ) : (
            <span>{roleplayData?.stars}</span>
          )}
        </div>
      </Grid>
      <Grid item lg={2}>
        <div className={`${styles.cell} ${styles.actions}`}>
          <MyTeamButton status={roleplayData?.status} roleplay={roleplayData} />
          <UnassignRoleplay roleplay={roleplayData} />
        </div>
      </Grid>
    </Grid>
  );
};

export default MyTeamTableRow;

import React, { useState, useEffect } from 'react';

import styles from './HubspotSettings.module.scss';
import { useGetHubspotSettingsQuery, useUpdateHubspotSettingsMutation } from '../Hubspot.queries';
import { CustomButton, CustomSwitch } from 'shared_ui_components';
import HubspotGoal from './HubspotGoal';
import { imageBasePath } from 'constants';
import { useHubspotStore } from '../Hubspot.store';
import { toastFlashMessage } from 'utils';
import logo from 'assets/hubspot/mdi_hubspot.svg';
import HubspotRemoveIntegration from '../components/HubspotRemoveIntegration';
import InfoFrequency from 'features/tracking/components/NewSettingsModal/InfoFrequency';

const HubspotSettings = () => {
  const onClose = useHubspotStore((state) => state?.closeSettings);

  const { data, isLoading: loadingData } = useGetHubspotSettingsQuery({ enabled: true });

  // Integration status
  const [status, setStatus] = useState(0);
  const toggleStatus = (e) => setStatus(e.target.checked ? 1 : 0);

  useEffect(() => {
    setStatus(data?.status);
  }, [data]);

  // HubSpot goals
  const [goals, setGoals] = useState([]);

  const handleGoalTargetChange = (uuid, value) => {
    const newGoals = [...goals];
    newGoals.find((goal) => goal?.uuid === uuid).target = value;
    setGoals(newGoals);
  };

  const handleGoalStatusChange = (uuid, status) => {
    const newGoals = [...goals];
    newGoals.find((goal) => goal?.uuid === uuid).status = status;
    setGoals(newGoals);
  };

  useEffect(() => {
    if (!data?.goals > 0) return;
    setGoals(data?.goals);
  }, [data]);

  // Submit data
  const { mutate, isLoading } = useUpdateHubspotSettingsMutation();
  const handleSubmit = () => {
    const settings = { goals, status, description: data?.description };
    mutate(
      { settings, id: data?.id },
      {
        onSuccess: () => {
          toastFlashMessage('HubSpot settings updated', 'success');
          onClose();
        },
      }
    );
  };

  if (loadingData) return null;
  return (
    <div className={styles.modalContent}>
      <div className={styles.leftSection}>
        <img className={styles.backgroundLogo} alt="" src={logo} />
        <div className={styles.circleImage}>
          <img src={imageBasePath + 'hubspot_logo.svg'} alt="HubSpot" />
        </div>
        <div className={styles.integrationSwitch}>
          <label className={styles.heading}>HubSpot Integration</label>
          <CustomSwitch
            checked={!!status}
            label={!!status ? 'Enabled' : 'Disabled'}
            onChange={toggleStatus}
          />
        </div>

        <div>
          <label>Description</label>
          <div className={styles.textarea}>{data?.description}</div>
        </div>

        <HubspotRemoveIntegration />
      </div>

      <div className={styles.rightSection}>
        <InfoFrequency />

        <div className={styles.goals}>
          {goals.map((goal) => (
            <HubspotGoal
              onStatusChange={handleGoalStatusChange}
              onTargetChange={handleGoalTargetChange}
              goal={goal}
              key={goal?.uuid}
            />
          ))}
        </div>
        <CustomButton disabled={isLoading} onClick={handleSubmit}>
          Save and continue
        </CustomButton>
      </div>
    </div>
  );
};

export default HubspotSettings;

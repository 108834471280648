import React from 'react';
import icon from 'assets/hubspot/tooltip.svg';

const HubspotSubtleIcon = ({ size = 26, margin = true }) => {
  return (
    <img
      style={{
        position: 'relative',
        borderRadius: '50%',
        margin: 0,
        background: '#e9ebee',
        opacity: '0.9',
        width: `${size}px`,
        height: `${size}px`,
        aspectRatio: '1 / 1',
        marginLeft: margin ? '1rem' : '',
        marginBottom: margin ? '0.25rem' : '',
        transform: margin ? '' : 'translateY(2px)',
      }}
      src={icon}
      alt="h"
      title="HubSpot data"
    />
  );
};

export default HubspotSubtleIcon;

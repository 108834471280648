import React from 'react';
import iconSubmit from 'assets/roleplay/iconSubmit.svg';
import styles from './SubmitRoleplay.module.scss';
const SubmitRoleplayCard = ({ title = '', description = '', completed = false, index = 1 }) => {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.cardTitle}>
          <div className={`${styles.cardIndex} ${!completed ? styles.cardIndexUncompleted : ''}`}>
            <strong>{index}</strong>
          </div>
          <img src={iconSubmit} alt="icon" className={`${styles.cardIcon}`} />
          <div className="heading6">{title}</div>
        </div>

        <div className="supportText">{description}</div>
      </div>
    </>
  );
};

export default SubmitRoleplayCard;

import { useState, useEffect, useCallback } from 'react';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SalesGoalDndCard from './SalesGoalDndCard/SalesGoalDndCard';
import update from 'immutability-helper';
import { InputText, CustomButton, TextArea } from '../../../shared_ui_components';
import { imageBasePath, goalFrequencies, goalUnits } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { createGoalsApi } from '../apiServices';
import { toastFlashMessage, NumberFormatCustom } from '../../../utils';
import GoalDescription from './GoalDescription';

export default function OrganisationGoalsDialog(props) {
  const { open, goalsData = {}, salesGoalsFrequency } = props;
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (open) {
      setFormData({
        frequency: goalsData?.frequency ? goalsData.frequency : 2,
        description: goalsData?.description ? goalsData?.description : '',
        goals: goalsData?.goals ? goalsData.goals : [],
      });
      setError({});
      setLoader(false);
    }
  }, [open]);

  const handleChangeTarget = (goalId, value) => {
    let updatedGoal = Object.assign(
      {},
      formData.goals.find((goal) => goal.uuid === goalId)
    );
    updatedGoal['target'] = value;
    setFormData((prevData) => ({
      ...prevData,
      goals: prevData.goals.map((goal) => (goal.uuid === goalId ? updatedGoal : goal)),
    }));
    setError({
      ...error,
      [goalId]: '',
    });
  };
  const handleChange = (key, val) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: val,
    }));
    setError({
      ...error,
      [key]: key == 'frequency' ? validateFrequency(val) : '',
    });
  };
  const handleClose = () => {
    props.handleClose();
  };
  const validateFrequency = (val) => {
    if (val < salesGoalsFrequency) {
      return `Sales goals frequency should be greater than sales activity frequency`;
    } else {
      return ``;
    }
  };
  const handleSubmit = () => {
    setLoader(true);
    let validateNewInput = {
      frequency: formData.frequency
        ? validateFrequency(formData.frequency)
        : 'Please select frequency',
    };
    formData.goals.forEach((item) => {
      validateNewInput[item.uuid] = item.target
        ? item.target < 0
          ? 'Invalid target'
          : ''
        : item.target === 0
        ? ''
        : 'Please enter target';
    });
    console.log(`validateNewInput`, validateNewInput);
    if (Object.keys(validateNewInput).every((key) => validateNewInput[key] === '')) {
      console.log(`formData`, formData);
      createGoalsApi(goalsData.id, formData).then((response) => {
        setLoader(false);
        if (response.statusCode == 200) {
          props.fetchTrackingGoals();
          toastFlashMessage(response.message, 'success');
          props.handleClose();
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };

  const [reorderedIds, setReorderedIds] = useState('');

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const _reordered = update(
        update(formData.goals, {
          $splice: [[dragIndex, 1, formData.goals[hoverIndex]]],
        }),
        { $splice: [[hoverIndex, 1, formData.goals[dragIndex]]] }
      );

      const _reordered_ids = _reordered.map((item) => item.uuid).join(',');

      setReorderedIds(_reordered_ids);
      setFormData({ ...formData, goals: _reordered });
    },
    [formData.goals]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.goalsDialog} ${styles.orgGoalsDialog}`}
      >
        <DialogContent>
          <div className={styles.dialogBody}>
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className={styles.modalCloseIcon}
              onClick={handleClose}
            />
            <Grid container direction="row" spacing={2} className={styles.dialogRow}>
              <Grid item xs={5}>
                <div className={styles.organizationGoalsLeftWrapper}>
                  <img
                    className={styles.featuredImg}
                    src={imageBasePath + 'organisation_goals.svg'}
                    alt="Sales Goals"
                  />
                  <h1 className={styles.customHeading + ' heading4 '}>Sales Goals</h1>
                  <p className={styles.customSupportText + ' supportText '}>Frequency</p>

                  <ul className={styles.goalsDurationList}>
                    {goalFrequencies.map(
                      (item) =>
                        item.id != 1 && (
                          <li key={item.id}>
                            <a
                              className={`${styles.goalsDurationBtn} linkBtn ${
                                formData.frequency == item.id ? styles.goalsActiveBtn : ''
                              } ${item.id < salesGoalsFrequency ? styles.disabledBtn : ''}`}
                              onClick={() => handleChange('frequency', item.id)}
                            >
                              {item.label}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                  {error.frequency ? (
                    <div className={styles.goalsLeftInfoBox + ' paragraph '}>
                      <img className={styles.infoIcon} src={imageBasePath + 'info_icon.svg'} />
                      <p>
                        {' '}
                        Make sure to set your sales goals frequency greater than the frequency of
                        sales activity{' '}
                      </p>
                    </div>
                  ) : null}
                  <TextArea
                    id="description"
                    label={`Description`}
                    placeholder="Sales Goals are constantly tracked, making sure your company is on track. Note that the Sales goals apply for the entire organization"
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    className={styles.goalsDescriptionInput}
                  />
                  <img
                    className={styles.dialogPattern}
                    src={imageBasePath + 'tracking_pattern.svg'}
                    alt="Image"
                  />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.organizationGoalsRightWrapper}>
                  <div className={styles.goalsInfoBox + ' paragraph '}>
                    <img className={styles.infoIcon} src={imageBasePath + 'info_icon.svg'} />
                    <p>
                      {' '}
                      Targets for the whole company are set on{' '}
                      <span className={styles.infoBoxHighlight}>
                        {' '}
                        {
                          goalFrequencies?.find((item) => item.id === formData.frequency)?.label
                        }{' '}
                      </span>{' '}
                      basis
                    </p>
                  </div>
                  <div className={styles.goalsInputWrapperBox + ' ' + styles.noPadRight}>
                    <DndProvider backend={HTML5Backend}>
                      {formData.goals?.map((goal, index) => (
                        <SalesGoalDndCard
                          reorderedIds={reorderedIds}
                          moveCard={moveCard}
                          goal={goal}
                          index={index}
                        >
                          <div key={goal.uuid} className={styles.goalsInputWrapper}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className={styles.goalsTextBlk}>
                                  {
                                    <img
                                      src={`${imageBasePath}${
                                        goalUnits.find((unit) => unit.id === goal.unit)?.icon
                                      }`}
                                      className={styles.goalsImgIcon}
                                    />
                                  }
                                  <div className={styles.inputTextWrapper}>
                                    <p className={styles.goalsBlkHeading + ' heading5 '}>
                                      {goal.name}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={styles.goalsInputBlk}>
                                  <InputText
                                    id="goalTarget"
                                    label={`Set a target for the goal`}
                                    placeholder="eg, 150"
                                    value={
                                      goalsData.is_content_added || goal.target ? goal.target : ''
                                    }
                                    // onChange={(e) => handleChangeTarget(goal.uuid, e.target.value)}
                                    onChange={(e) =>
                                      Number.isInteger(Number(e.target.value)) &&
                                      Number(e.target.value) > 0
                                        ? handleChangeTarget(goal.uuid, parseInt(e.target.value))
                                        : e.target.value === '' || e.target.value === '0'
                                        ? handleChangeTarget(goal.uuid, e.target.value)
                                        : console.log(e.target.value)
                                    }
                                    className={
                                      goal.unit === 2
                                        ? `${styles.customGoalInput} ${
                                            error[goal?.uuid] ? `errorGroup` : ''
                                          }`
                                        : `${error[goal?.uuid] ? `errorGroup` : ''}`
                                    }
                                    InputProps={
                                      goal.unit === 2
                                        ? {
                                            inputComponent: NumberFormatCustom,
                                          }
                                        : { inputmode: 'numeric', pattern: '[0-9]*' }
                                    }
                                  />
                                  {goal.unit === 2 ? (
                                    <img
                                      src={imageBasePath + 'dollar.svg'}
                                      className={styles.inputIcon}
                                    />
                                  ) : null}
                                  {error[goal?.uuid] ? (
                                    <h6 className={`${styles.customErrorMsg} errorMsg`}>
                                      {error[goal?.uuid]}
                                    </h6>
                                  ) : null}
                                </div>
                              </Grid>
                            </Grid>
                            <GoalDescription value={goal?.description} />
                          </div>
                        </SalesGoalDndCard>
                      ))}
                    </DndProvider>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${
              loader || Object.keys(error).some((k) => error[k] != '') ? 'disabledBtn' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { imageBasePath } from '../constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function NoResult(props) {
  return (
    <div className={props.className + ' noResultFound flexCentered'}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="contentRow textCenter"
      >
        <Grid item xs={12}>
          {props.image ? (
            <img src={imageBasePath + props.image} alt="Image" />
          ) : (
            <img src={imageBasePath + 'no_result_found.png'} alt="Image" />
          )}
          {props.title ? <h5 className="heading3">{props.title}</h5> : null}
          <Hidden only={['xs', 'sm']}>
            {props.subtext ? <h6 className="paragraph">{props.subtext}</h6> : null}
            {props.btnText ? (
              <p>
                <Link
                  to={props.link ? props.link : null}
                  className={'primaryBtn'}
                  onClick={props.onClick}
                >
                  {props.linkimg ? (
                    <img
                      src={imageBasePath + props.linkimg}
                      alt={props.alt ? props.alt : 'image'}
                      className="linkimg"
                    />
                  ) : null}
                  {props.btnText ? <span>{props.btnText}</span> : <span>+ Add New</span>}
                </Link>
              </p>
            ) : null}
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
}

NoResult.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
  addNewFun: PropTypes.func,
  btnText: PropTypes.string,
  link: PropTypes.string,
  btnClassName: PropTypes.string,
};

NoResult.defaultProps = {
  image: '',
  title: '',
  subtext: '',
  addNewFun: () => {},
  btnText: '',
  link: '',
  btnClassName: '',
};

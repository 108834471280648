import React, { useState, useEffect, useMemo } from 'react';
import styles from './EditBillingInfo.module.scss';
import { imageBasePath } from 'constants';
import { useEditPaymentMethodMutation } from '../../../Payment.queries';
import { CustomButton, InputSelect, InputText } from 'shared_ui_components';
import { Country, State, City } from 'country-state-city';

const EditBillingInfo = ({ setShowModal = () => null, cardInfo = {} }) => {
  const { mutate, isLoading } = useEditPaymentMethodMutation({});
  const countries = Country.getAllCountries().map((country) => {
    return { ...country, value: country.name, label: country.name };
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState(
    countries[
      cardInfo?.billing_details?.country !== null
        ? countries.findIndex((c) => {
            return c.isoCode == cardInfo?.billing_details?.country;
          })
        : 0
    ]
  );
  const [state, setState] = useState([]);
  const [city, setCity] = useState(cardInfo?.billing_details?.city || '');
  const [address, setAddress] = useState(
    cardInfo?.billing_details?.line1 ? cardInfo?.billing_details?.line1 : ''
  );
  const [addressTwo, setAddressTwo] = useState(
    cardInfo?.billing_details?.line2 ? cardInfo?.billing_details?.line2 : ''
  );

  const handleSubmit = () => {
    const data = {
      billing_details: {
        address: {
          line1: address,
          line2: addressTwo,
          city: city.label ? city.label : '' || city,
          state: state.label ? state.label : '',
          country: country.isoCode,
        },
      },
    };
    mutate({ body: data, payment_method_id: cardInfo?.id }, { onSuccess });
  };

  const onSuccess = () => {
    setShowModal(false);
  };

  useMemo(
    () =>
      setStates(
        State.getStatesOfCountry(country.isoCode).map((state) => {
          return { ...state, value: state.name, label: state.name };
        })
      ),
    [country]
  );

  useEffect(() => {
    setState(
      states[
        cardInfo?.billing_details?.state !== null
          ? states.findIndex((s) => {
              return s.value == cardInfo?.billing_details?.state;
            })
          : 0
      ] || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useMemo(
    () =>
      setCities(
        City.getCitiesOfState(state.countryCode, state.isoCode).map((city) => {
          return { ...city, value: city.name, label: city.name };
        })
      ),
    [state]
  );

  useEffect(() => {
    setCity(
      cities[
        cardInfo?.billing_details?.city !== null
          ? cities.findIndex((c) => {
              return c.value == cardInfo?.billing_details?.city;
            })
          : 0
      ] || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div style={{ minHeight: '33rem', minWidth: '30rem' }}>
      <div className={styles.editHeader}>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className={styles.cancelCloseIcon}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className={styles.editBody}>
        <div className={styles.bodyForm}>
          <InputSelect
            className="reactSelect"
            closeMenuOnSelect={true}
            label={`Country`}
            options={countries}
            isSearchable={true}
            noOptionsMessage={() => 'No matches found'}
            onChange={(selectedOption) => {
              setCountry(selectedOption);
            }}
            value={country}
          />
          <div className={styles.StateCity}>
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect={true}
              label={`State`}
              options={states}
              isSearchable={true}
              noOptionsMessage={() => 'No matches found'}
              onChange={(selectedOption) => {
                setState(selectedOption);
              }}
              value={state}
            />
            {cities.length === 0 ? (
              <InputText
                label={'City'}
                id="city"
                placeholder="City"
                className="nameInputGroup"
                multiline
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            ) : (
              <InputSelect
                className="reactSelect"
                closeMenuOnSelect={true}
                label={`City`}
                options={cities}
                isSearchable={true}
                noOptionsMessage={() => 'No matches found'}
                onChange={(selectedOption) => {
                  setCity(selectedOption);
                }}
                value={city}
              />
            )}
          </div>

          <InputText
            label={'Address line 1'}
            id="full nme"
            placeholder="Address line 1"
            className="nameInputGroup"
            multiline
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <InputText
            label={'Address line 2'}
            id="full nme"
            placeholder="This line is optional"
            className="nameInputGroup"
            multiline
            value={addressTwo}
            onChange={(e) => setAddressTwo(e.target.value)}
          />
        </div>
        <div className={styles.bodyButtons}>
          <button
            disabled={isLoading}
            onClick={() => setShowModal(false)}
            className={styles.cancel}
          >
            Cancel
          </button>
          <CustomButton disabled={isLoading} onClick={handleSubmit} textTransform="none">
            Save
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default EditBillingInfo;

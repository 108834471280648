import React, { useMemo } from 'react';
import { Avatar } from '@material-ui/core';

import RoleplayLabel from '../Common/RoleplayLabel';
import User from 'infrastructure/User';

import styles from './RoleplayDashboardList.module.scss';
import Organization from 'infrastructure/Organization';
import RoleplayCardButton from './RoleplayCardButton';
import UnassignRoleplay from '../UnassignRoleplay/UnassignRoleplay';
import DateTime from 'infrastructure/Datetime';

const DashboardRoleplayCard = ({ roleplay }) => {
  const user = useMemo(() => {
    if (Organization.isRep()) return roleplay?.assigned_by_user || {};
    return roleplay?.user || {};
  }, [roleplay?.assigned_by_user, roleplay?.user]);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Avatar className={styles.img} alt="" src={user?.profile_pic}>
          {User.getInitial(user)}
        </Avatar>
        <span className={styles.headerLabel}>
          <RoleplayLabel {...roleplay?.status} />
          <UnassignRoleplay roleplay={roleplay} small />
        </span>
      </div>
      <div className={styles.names}>
        <h3>{User.getUserName(user)}</h3>
        <p>{roleplay?.name || ''}</p>
        <p className={styles.deadline}>Deadline: {DateTime.formatMMMddyyyy(roleplay?.deadline)}</p>
      </div>
      <RoleplayCardButton roleplay={roleplay} />
    </div>
  );
};

export default DashboardRoleplayCard;

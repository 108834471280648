import { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/users.module.scss';
import { imageBasePath, roles as userRoles, userStatus } from '../../../constants';
import { fetchAllUsersApi } from '../apiServices';
import { UserItem, Shimmer } from '../components';
import queryString from 'query-string';
import { Pagination } from '@material-ui/lab';
import { InputSelect, NoResult } from '../../../shared_ui_components';
import { GlobalContext } from '../../../context/GlobalContext';
import { InviteUser } from '../components';
import UserSettingsModal from '../components/Settings/UserSettingsModal';

export default class Users extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,

      loader: true,
      filter: {
        role: '',
        status: 1,
      },
      loggedInUser: {},
      usersInfo: {},
      userRole: props.role,
      user_Status: props.statusUser,
    };
  }
  fetchAllUsers = (query = {}) => {
    fetchAllUsersApi(query).then((response) => {
      if (response.statusCode === 200) {
        const usersInfo = response.data;
        this.setState((prevState) => ({
          ...prevState,
          usersInfo: usersInfo,
          loader: false,
        }));
        if (usersInfo?.pagination?.current_page != query.page && query.page) {
          // const lastPageNo = Math.ceil(usersInfo.pagination?.total / 10)
          this.props.history.push({
            search: `?${queryString.stringify(
              { ...query, page: usersInfo?.pagination?.current_page },
              { encode: false }
            )}`,
          });
        }
      }
    });
  };
  componentDidMount() {
    if (this.props.location.search) {
      const querys = queryString.parse(this.props.location.search, {
        decode: true,
      });
      let updatedFilter = this.state.filter;
      Object.keys(querys).forEach((key) => {
        if (key !== 'page') {
          updatedFilter = {
            ...updatedFilter,
            [key]: querys[key],
          };
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        filter: updatedFilter,
      }));
      this.fetchAllUsers({
        ...updatedFilter,
        page: querys.page ? querys.page : 1,
      });
    } else {
      this.fetchAllUsers(this.state.filter);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search != this.props.location.search) {
      this.parseSearchParams(this.props.location.search);
    }
    const { state, dispatch } = this.context;
    if (JSON.stringify(state.user) !== JSON.stringify(this.state.loggedInUser)) {
      this.setState({
        loggedInUser: state.user,
      });
    }
    if (state.updateUsersListingFlag) {
      dispatch({
        type: `UPDATE_USERS_LISTING`,
        payload: false,
      });
      this.parseSearchParams(this.props.location.search);
    }
  }
  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.filter;
    const querys = queryString.parse(params, { decode: true });
    updatedFilter = {
      role: querys.role || '',
      status: querys.status || '',
    };
    this.setState((prevState) => ({
      ...prevState,
      filter: updatedFilter,
    }));
    this.fetchAllUsers({
      ...updatedFilter,
      page: querys.page ? querys.page : 1,
    });
  };
  // handleSorting = (sort) => {
  //   const { usersInfo, filter } = this.state
  //   this.setState(prevState => ({
  //     ...prevState,
  //     filter: {
  //         ...prevState.filter,
  //         sort_by: prevState.filter.sort_by == "asc" ? 'desc' : 'asc',
  //         sort
  //     }
  //   }))
  //   if(usersInfo.pagination?.total){
  //     this.props.history.push({
  //         search: `?${queryString.stringify({...filter, page: usersInfo?.pagination?.current_page ? usersInfo?.pagination?.current_page : 1, sort, sort_by: filter.sort_by == "asc" ? `desc` : `asc`}, { encode: false })}`
  //     })
  //   }
  // }
  handleChangePage = (event, newPage) => {
    const { filter } = this.state;
    this.props.history.push({
      search: `?${queryString.stringify({ ...filter, page: newPage }, { encode: false })}`,
    });
  };

  handleSelect = (selectedOption) => {
    const { filter, usersInfo } = this.state;
    this.setState({ loader: true });
    this.props.history.push({
      search: `?${queryString.stringify(
        {
          ...filter,
          page: usersInfo?.pagination?.current_page,
          role: selectedOption?.value || '',
        },
        { encode: false }
      )}`,
    });
  };

  handleSelectStatus = (selectedOption) => {
    const { filter, usersInfo } = this.state;
    this.setState({ loader: true });
    this.props.history.push({
      search: `?${queryString.stringify(
        {
          ...filter,
          page: usersInfo?.pagination?.current_page,
          status: selectedOption?.value ?? '',
        },
        { encode: false }
      )}`,
    });
  };

  render() {
    const { role, loader, usersInfo, loggedInUser, userRole, filter} = this.state;

    return (
      <div className={styles.usersPage}>
        <section className={styles.usersSection}>
          <Grid container direction="row" spacing={5} className={styles.titleRow}>
            <Grid item xs={6}>
              <div className={styles.usersTitleWrapper}>
                <img
                  className={styles.usersFeatureIcon}
                  src={imageBasePath + 'users_featured.svg'}
                  alt="Users"
                />
                <div className={styles.usersSectionTitle}>
                  <h4 className="heading4">All Users</h4>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.usersRoleFilter}>
                <InviteUser />
                <div className={styles.filterSelectWrapper}>
                  <InputSelect
                    className="reactSelect"
                    closeMenuOnSelect={true}
                    options={userRoles}
                    isSearchable={false}
                    placeholder={'Select Role'}
                    onChange={this.handleSelect}
                    noOptionsMessage={() => 'No matches found'}
                    isClearable
                    value={
                      filter.role
                        ? userRoles.find((item) => item.value === Number(filter.role))
                        : null
                    }
                  />
                </div>
                <div className={styles.filterSelectWrapper}>
                  <InputSelect
                    className="reactSelect"
                    closeMenuOnSelect={true}
                    options={userStatus}
                    isSearchable={false}
                    placeholder={'Select Status'}
                    onChange={this.handleSelectStatus}
                    noOptionsMessage={() => 'No matches found'}
                    isClearable
                    value={
                      filter.status
                        ? userStatus.find((item) => item.value === Number(filter.status))
                        : null
                    }
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={styles.usersTableTitleWrapper}>
            <Grid container direction="row" spacing={2} className={styles.tableRow}>
              <Grid item xs={7}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <p className={styles.usersTableTitle + ' heading6 '}>Name</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p className={styles.usersTableTitle + ' heading6 '}>Email</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className={styles.usersTableTitle + ' heading6 '}>User Role </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={5}>
                    <p className={styles.usersTableTitle + ' heading6 '}>TimeZone </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p className={styles.usersTableTitle + ' heading6 '}>
                      Joined On{' '}
                      {/* <img
                        className={styles.usersTableSortIcon}
                        src={imageBasePath + "collapse.svg"}
                      /> */}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <p className={styles.usersTableTitle + ' heading6 '}>Status </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={styles.usersTableContentWrapper}>
            <UserSettingsModal
              fetchAllUsers={() => {
                const querys = queryString.parse(this.props.location.search, {
                  decode: true,
                });
                this.fetchAllUsers({ page: querys.page ? querys.page : 1 });
              }}
            />
            {!loader &&
            loggedInUser &&
            usersInfo?.pagination?.current_page === 1 &&
            ((!filter.role && filter.role == userRole?.value)) ? (
              <UserItem
                user={{ user: loggedInUser }}
                userRole={userRole}
                currentUser={true}
              />
            ) : null}
            {loader ? (
              <Shimmer type="Users" />
            ) : usersInfo && usersInfo.pagination?.total ? (
              usersInfo?.list.map((user, index) => (
                <UserItem
                  key={user.user.uuid + user.user_role}
                  index={index}
                  user={user}
                  role={role}
                  fetchAllUsers={this.fetchAllUsers}
                />
              ))
            ) : (filter.role ) || (filter.role != userRole?.value) ? (
              <NoResult
                image={`no_user_found.svg`}
                title={`Sorry, We could not find a user with this role, Please try again`}
                subtext={`Kindly clear and apply a new filter.`}
              />
            ) : null}
          </div>
          {!loader && usersInfo?.pagination?.number_of_pages > 1 ? (
            <div className={styles.usersTablePaginationWrapper}>
              <Pagination
                variant="outlined"
                shape="rounded"
                className={`customPagination ${styles.customPagination}`}
                count={usersInfo?.pagination?.number_of_pages}
                page={usersInfo?.pagination?.current_page}
                onChange={this.handleChangePage}
              />
            </div>
          ) : null}
        </section>
        {/* <InviteUserDialog
          open={openInvitePop}
          handleClose={() => setInvitePop(false)}
          email={querys.email}
        /> */}
      </div>
    );
  }
}

// <p className={styles.usersStatus + " paragraph " + styles.usersPending }> Pending</p>
// styles.usersInActive

import styles from '../../../assets/styles/home.module.scss';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../../../utils';
import OverallInfoTooltip from './OverallInfoTooltip';

export default function OverallDetailItem(props) {
  const { image, value, title, unit, description } = props;

  return (
    <div className={styles.overallDetailWrapper + ' flexCentered'}>
      <img src={image} className={styles.featuredImg} alt="" />
      <div className={styles.contentBlk}>
        <h3 className="heading2">
          {unit === 'dollar' ? (
            <>
              <span>$</span>
              <NumberFormat
                format={CustomNumberFormatter}
                displayType="text"
                value={value}
                thousandSeparator={true}
                prefix={'$'}
              />
            </>
          ) : (
            CustomNumberFormatter(value)
          )}
        </h3>
        <p className="paragraph">{title}</p>
      </div>
      <OverallInfoTooltip description={description} />
    </div>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import styles from './Training.module.scss';
import { imageBasePath } from 'constants';
import { List, ListItem } from '@material-ui/core';
import { useLearningPathsStore } from './LearningPaths/LearningPaths.store';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { ProgressBar } from 'shared_ui_components';
import { getUserPreferencesApi } from '../apiServices';
import Organization from 'infrastructure/Organization';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';

const RepTrainingHeader = ({ readinessIndex, completed, total }) => {
  const {
    activeTabRep,
    setSelectedRepLearningPathID,
    setActiveTabRep,
    userPreferencesLP,
    setUserPreferencesLP,
    selectedRepLearningPathID,
  } = useLearningPathsStore();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getOnboardingInfo = async () => {
      try {
        setLoading(true);
        const response = await getUserPreferencesApi();
        if (response.statusCode === 200) {
          setUserPreferencesLP(response?.data);
        }
      } finally {
        setLoading(false);
      }
    };
    getOnboardingInfo();
  }, [setUserPreferencesLP]);

  const handleSelectTab = (selected) =>
    setActiveTabRep(selected === 'training' ? 'learning' : 'training');

  const handleBack = () => {
    setSelectedRepLearningPathID('');
    handleSelectTab('training');
  };

  const { isActive } = useSalesRepView();
  const showTab = useMemo(() => {
    if (Organization.isAdmin() && isActive) return true;
    if (Object.keys(userPreferencesLP?.onboarding || {}).length > 0)
      return userPreferencesLP?.onboarding?.completed || !userPreferencesLP?.onboarding?.required;
    return !loading;
  }, [userPreferencesLP, loading]);

  return (
    <div className={styles.newRepTrainingHeader}>
      <div className={styles.firstContainer}>
        {/* Text header */}
        {selectedRepLearningPathID && (
          <button className={styles.backButton} onClick={handleBack}>
            <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
          </button>
        )}
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <img src={imageBasePath + 'training_dashboard_icon.svg'} alt="" />
            <h1 className="heading3">Training</h1>
          </div>
          <p className="supportText">Complete all of the modules to boost your sales readiness</p>
        </div>
        {/* Tab buttons */}
        <div className={styles.tabs}>
          <List className="listInline tabList">
            <ListItem
              key={'training'}
              className={`listInlineItem heading6 ${activeTabRep === 'training' ? 'active' : ''}`}
              onClick={() => handleSelectTab('learning')}
            >
              Training Modules
            </ListItem>
            {showTab ? (
              <ListItem
                key={'learning'}
                className={`listInlineItem heading6 ${activeTabRep === 'training' ? '' : 'active'}`}
                onClick={handleBack}
              >
                Learning Paths
              </ListItem>
            ) : null}
          </List>
        </div>
      </div>

      {/* Progress bars */}
      {activeTabRep === 'training' && (
        <ProgressBars readinessIndex={readinessIndex} completed={completed} total={total} />
      )}
    </div>
  );
};

const ProgressBars = ({ readinessIndex, completed, total }) => {
  if (!readinessIndex) return null;

  return (
    <div className={styles.secondContainer}>
      <div className={styles.readinessInfoWrapper}>
        <div className="circularProgressbar">
          <CircularProgressbar
            strokeWidth={10}
            value={readinessIndex}
            styles={buildStyles({
              textColor: ' #778DA9',
              pathColor: '#00D2AD',
              trailColor: '#D8E1E9',
              textSize: '16px',
              strokeLinecap: 'butt',
              pathTransition: 'none',
            })}
          />
        </div>
        <div className="supportText">
          <p className={styles.value}>{readinessIndex}%</p>
          <p>Sales Readiness Index</p>
        </div>
      </div>

      <div className={styles.completedCountWrapper}>
        <p>
          <span className={`${styles.value} heading6`}>{`${completed || 0}/${total || 0} `}</span>
          <span className="supportText" style={{ marginTop: 2 }}>
            Modules Completed
          </span>
        </p>
        <div className={styles.progressBlk}>
          <ProgressBar
            value={completed}
            max={total}
            className={styles.stepProgress + ' stepProgress'}
          />
        </div>
      </div>
    </div>
  );
};

export default RepTrainingHeader;

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
  getTrackingGoalsApi,
  getCurrentCheckinApi,
  checkinUpdateApi,
  checkinHistory,
  getUserPreferencesApi,
  checkinEditApi,
} from '../apiServices';
import styles from '../../../assets/styles/tracking.module.scss';
import { DashboardHeader } from '../../../shared_elements';
import { UserOverview, CheckinHistory, GoalListing, CheckinDrawer } from './';
import { imageBasePath } from '../../../constants';
import queryString from 'query-string';
import { toastFlashMessage } from '../../../utils';
import { NoResult } from '../../../shared_ui_components';
import Shimmer from './Shimmer';
import { useTrackingStore } from '../tracking.store';
import { useGoalsOrderQuery } from '../tracking.queries';
import { FeatureFlag } from 'controllers/_exports';

// function mergeHistory(mergedArray){
//     let output = [];
//     mergedArray.forEach(function(item) {
//         var existing = output.filter(function(v, i) {
//           return v.checkin_date == item.checkin_date;
//         });
//         if (existing.length) {
//             var existingIndex = output.indexOf(existing[0]);
//             output[existingIndex] = {...output[existingIndex], ...item};
//         } else {
//             output.push(item);
//         }
//     });
//     return output;
// }

export default function UserTrackingContainer(props) {
  const { role } = props;
  let history = useHistory();
  let location = useLocation();
  const [goals, setGoals] = useState('');
  const [loader, setLoader] = useState({
    goals: true,
    userPreferences: true,
    history: true,
  });
  const [checkinLoader, checkinLoaderSet] = useState(false);
  const [checkinExist, checkinExistSet] = useState(false);
  const [chekcinOpen, chekcinOpenSet] = useState(false);
  const [currentCheckin, currentCheckinSet] = useState(false);
  const [currentEditCheckin, currentEditCheckinSet] = useState(false);
  const [isEdit, isEditSet] = useState(false);
  const [isEditLoader, isEditLoaderSet] = useState(false);
  const [checkins, checkinsSet] = useState([]);
  const [lastUpdate, setLastUpdate] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [latestCheckin, latestCheckinSet] = useState(false);
  const [latestEditCheckin, latestEditCheckinSet] = useState(false);
  const [userPreferences, setUserPreferences] = useState({});
  const [refrsh, refrshSet] = useState(false);
  const [salesGoals, salesGoalsSet] = useState({});

  const { isLoading } = useGoalsOrderQuery();

  const saveGoalsInStore = useTrackingStore((state) => state?.setGoalsData);

  const fetchTrackingGoals = () => {
    getTrackingGoalsApi().then((response) => {
      if (response.statusCode == 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          goals: false,
        }));
        const categories = FeatureFlag.enabled('REMOVE_SALES_GOALS')
          ? response?.data?.categories?.filter((c) => !c?.is_organisation)
          : response?.data?.categories;
        setGoals({ ...response.data, categories });
        // Save sales activity goals in store
        saveGoalsInStore(response?.data);
      }
    });
  };

  const fetchCurrentCheckin = () => {
    getCurrentCheckinApi().then((response) => {
      if (response.statusCode === 200) {
        currentCheckinSet(response.data.categories);
        checkinExistSet(response.data.checkin_exists);
        latestCheckinSet(response.data);
        let salesGoals = response.data.categories.find((cat) => !cat.is_organisation);
        response.data.checkin_exists ? isEditSet(true) : isEditSet(false);
      } else {
        toastFlashMessage('Something went wrong please try again by reloading', 'error');
      }
    });
  };

  const fetchCheckinHistory = () => {
    checkinHistory().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          history: false,
        }));
        checkinsSet(response.data.checkins);
        setLastUpdate(response.data?.last_update);
      } else {
        toastFlashMessage('Something went wrong please try again by reloading', 'error');
      }
    });
  };
  useEffect(() => {
    getUserPreferencesApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          userPreferences: false,
        }));
        setUserPreferences(response.data);
      }
    });
  }, []);
  useEffect(() => {
    fetchCurrentCheckin();
    fetchTrackingGoals();
    fetchCheckinHistory();
  }, []);

  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (Object.keys(querys).length && querys.active) {
      const active = querys.active;
      if (activeTab != active) {
        setActiveTab(active);
      }
    } else {
      setActiveTab('overview');
    }
  }, [location]);

  const handleChageTab = (val) => {
    history.push(`/tracking?active=${val}`);
  };

  const updateCheckin = ({ data, date, isEdit, onSuccess }) => {
    if (isEdit) {
      checkinEditApi(data, date)
        .then((response) => {
          if (response.statusCode === 200) {
            setTimeout(() => {
              chekcinOpenSet(false);
              setTimeout(() => {
                checkinLoaderSet(false);
              }, 500);
              toastFlashMessage(response.message, 'success');
              refrshSet(!refrsh);
              currentEditCheckinSet(false);
              latestEditCheckinSet(false);
              latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
              fetchCurrentCheckin();
            }, 0);
            fetchCheckinHistory();
            onSuccess();
          } else if (response.statusCode === 250) {
            setTimeout(() => {
              chekcinOpenSet(false);
              setTimeout(() => {
                checkinLoaderSet(false);
              }, 500);
              toastFlashMessage(response.message, 'warning');
              refrshSet(!refrsh);
              currentEditCheckinSet(false);
              latestEditCheckinSet(false);
              latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
              fetchCurrentCheckin();
            }, 0);
            fetchCheckinHistory();
            onSuccess();
          } else {
            checkinLoaderSet(false);
            toastFlashMessage(response.message, 'error');
          }
        })
        .catch((error) => {
          checkinLoaderSet(false);
        })
        .finally(() => {
          checkinLoaderSet(false);
        });
    } else {
      checkinUpdateApi(data, date)
        .then((response) => {
          if (response.statusCode === 200) {
            setTimeout(() => {
              chekcinOpenSet(false);
              toastFlashMessage(response.message, 'success');
              refrshSet(!refrsh);
              setTimeout(() => {
                checkinLoaderSet(false);
              }, 500);
              latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
              fetchCurrentCheckin();
            }, 0);
            fetchCheckinHistory();
            onSuccess();
          } else {
            checkinLoaderSet(false);
            toastFlashMessage(response.message, 'error');
          }
        })
        .catch((error) => {
          checkinLoaderSet(false);
        })
        .finally(() => {
          checkinLoaderSet(false);
        });
    }
  };

  const userpreferenceVerification = () => {
    const requiredPreferences = Object.keys(userPreferences).filter(
      (key) => userPreferences[key].required
    );
    return requiredPreferences.every(
      (key) =>
        (key == 'tracking' || userPreferences[key].completed) &&
        userPreferences['tracking'].is_published
    );
  };

  const hanldeEditCheckin = (checkin, is_current_edit = false) => {
    isEditLoaderSet(true);
    isEditSet(true);
    fetchCurrentEditCheckin(checkin, is_current_edit);
    chekcinOpenSet(true);
  };

  const fetchCurrentEditCheckin = (checkin, is_current_edit) => {
    getCurrentCheckinApi(!is_current_edit ? { date: checkin } : null).then((response) => {
      if (response.statusCode === 200) {
        isEditLoaderSet(false);
        currentEditCheckinSet(response.data.categories);
        latestEditCheckinSet({
          ...response.data,
          checkin_date: is_current_edit ? response.data?.last_checkin : response.data?.date,
        });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const getNoResultText = () => {
    const requiredPreferences = Object.keys(userPreferences).filter(
      (key) => userPreferences[key].required
    );
    let onboardingTrainingCompleted = requiredPreferences.every(
      (key) => userPreferences[key].completed || key == 'tracking'
    );
    if (!onboardingTrainingCompleted) {
      return 'Please finish onboarding and training first';
    } else if (!userPreferences['tracking'].is_published) {
      return 'Seems goal tracking module is not yet published, kindly check later';
    } else {
      return '';
    }
  };

  return (
    <div className={styles.trackingOuterWrapper}>
      <div className={styles.dashboard}>
        {isLoading || (loader && (loader.goals || loader.userPreferences || loader.history)) ? (
          <Shimmer type="UserOverview" />
        ) : userpreferenceVerification() ? (
          <>
            <DashboardHeader
              dashboardLogo={imageBasePath + 'tracking_menu_icon.svg'}
              title="Goal Tracking"
              tabs={[
                {
                  value: 'overview',
                  title: 'Overview',
                  isActive: activeTab == 'overview' ? true : false,
                },
                {
                  value: 'checkin',
                  title: 'Check-in',
                  isActive: activeTab == 'checkin' ? true : false,
                },
                {
                  value: 'goals',
                  title: 'Goals',
                  isActive: activeTab == 'goals' ? true : false,
                },
              ]}
              handleChange={handleChageTab}
            />
            <div className={styles.dashboardContentWrapper}>
              {activeTab === 'overview' ? (
                <UserOverview
                  chekcinOpenSet={chekcinOpenSet}
                  currentCheckin={currentCheckin}
                  checkinExist={Object.keys(checkins).length ? true : false}
                  weekCheckinExist={checkinExist}
                  latestCheckin={latestCheckin}
                  role={role}
                  refrsh={refrsh}
                  hanldeEditCheckin={hanldeEditCheckin}
                />
              ) : activeTab === 'checkin' ? (
                <CheckinHistory
                  chekcinOpenSet={chekcinOpenSet}
                  checkins={checkins}
                  lastUpdate={lastUpdate}
                  checkinExist={checkinExist}
                  currentCheckin={currentCheckin}
                  latestCheckin={latestCheckin}
                  hanldeEditCheckin={hanldeEditCheckin}
                  refetchCheckIns={() => {
                    fetchCheckinHistory();
                    fetchCurrentCheckin();
                  }}
                />
              ) : activeTab === 'goals' ? (
                <>
                  {goals.categories?.map((category) => (
                    <GoalListing
                      featuredImg={
                        category.is_organisation ? 'organisation_goals.svg' : `sales_goals.svg`
                      }
                      category={category}
                      type={category.is_organisation ? `orgGoals` : `salesGoals`}
                      isAdmin={false}
                    />
                  ))}
                </>
              ) : (
                <UserOverview />
              )}
            </div>
            <CheckinDrawer
              open={chekcinOpen}
              handleClose={() => {
                latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
                currentEditCheckinSet(false);
                chekcinOpenSet(false);
                checkinLoaderSet(false);
              }}
              currentCheckin={isEdit ? currentEditCheckin : currentCheckin}
              updateCheckin={updateCheckin}
              role={role}
              isEdit={isEdit}
              isEditLoader={isEditLoader}
              latestEditCheckin={latestEditCheckin}
              latestCheckin={latestCheckin}
              checkinLoaderSet={checkinLoaderSet}
              checkinLoader={checkinLoader}
            />
          </>
        ) : (
          <>
            <DashboardHeader
              dashboardLogo={imageBasePath + 'tracking_menu_icon.svg'}
              title="Goal Tracking"
            />
            <NoResult
              image="no_checkin.svg"
              title="Goal Tracking not available"
              subtext={getNoResultText()}
            />
          </>
        )}
      </div>
    </div>
  );
}

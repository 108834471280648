import { create } from 'zustand';

const useRecorderStore = create((set, get) => ({
  isRecording: false, // Global flag to know if user is recording
  isEnableRecording: false,
  resourceUrl: '', // URL to redirect after recording
  // Video data
  video: null,
  videoID: '',
  recordedVideos: [],

  startRecording: () => set(() => ({ isRecording: true })),
  stopRecording: () => set(() => ({ isRecording: false })),

  startEnableRecording: () => set(() => ({ isEnableRecording: true })),
  stopEnableRecording: () => set(() => ({ isEnableRecording: false })),

  setResourceUrl: (url) => set(() => ({ resourceUrl: url })),
  setVideoID: (id) => set(() => ({ videoID: id })),

  setRecordingData: (video) => {
    if (get()?.videoID !== '')
      return set(() => ({
        recordedVideos: [...get()?.recordedVideos, { id: get()?.videoID, video }],
      }));
    return set(() => ({
      video,
    }));
  },
  cleanVideo: () => set(() => ({ video: null })),
  cleanVideos: () => set(() => ({ recordedVideos: [] })),
  cleanVideoID: (id) => {
    const newVideos = [...get()?.recordedVideos].filter((v) => v?.id !== id);
    return set(() => ({ recordedVideos: newVideos }));
  },

  onDiscard: () => null,
  setOnDiscard: (callback) => set(() => ({ onDiscard: callback })),
  onEnd: () => null,
  setOnEnd: (callback) => set(() => ({ onEnd: callback })),

  // Assessments
  assessmentQuestions: [],
  setAssessmentQuestions: (assessmentQuestions) => set(() => ({ assessmentQuestions })),
}));

export { useRecorderStore };

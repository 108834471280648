import React from 'react';
import styles from './Banner.module.scss';
import { CloseBannerButton, useBanner } from 'shared_ui_components/CloseBannerButton';

const Banner = () => {
  const [isBannerOpen, closeBanner] = useBanner('pulse_banner');
  if (!isBannerOpen) return <div style={{ height: '2rem' }} />;
  return (
    <div className={styles.streamBanner}>
      <h3>Embark on a Social Journey with SalesTable</h3>
      <p>Connect, Learn, and Grow Together!</p>
      <CloseBannerButton isPulse onClose={closeBanner} />
    </div>
  );
};

export default Banner;

import React from 'react';

const HalfStar = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.35592 14.8405L7.99714 12.6875L8 0C7.77527 0 7.55557 0.06468 7.36856 0.185896C7.18155 0.307112 7.03558 0.479444 6.94904 0.681193L5.29193 4.5261L1.04669 4.88378C0.82253 4.9021 0.60892 4.9844 0.432649 5.12035C0.256378 5.25629 0.125291 5.43984 0.055828 5.64797C-0.0136354 5.8561 -0.0183833 6.07955 0.0421798 6.2903C0.102743 6.50104 0.225921 6.6897 0.396267 6.83262L3.61697 9.57045L2.65383 13.6362C2.60278 13.849 2.61735 14.0717 2.6957 14.2764C2.77405 14.4812 2.91271 14.6589 3.09434 14.7874C3.27597 14.9159 3.49252 14.9895 3.7169 14.999C3.94129 15.0084 4.16356 14.9533 4.35592 14.8405Z"
        fill="#EB9B42"
      />
      <path
        opacity="0.3"
        d="M11.6441 14.8405L8.00286 12.6875L8 0C8.22473 0 8.44443 0.06468 8.63144 0.185896C8.81845 0.307112 8.96442 0.479444 9.05096 0.681193L10.7081 4.5261L14.9533 4.88378C15.1775 4.9021 15.3911 4.9844 15.5674 5.12035C15.7436 5.25629 15.8747 5.43984 15.9442 5.64797C16.0136 5.8561 16.0184 6.07955 15.9578 6.2903C15.8973 6.50104 15.7741 6.6897 15.6037 6.83262L12.383 9.57045L13.3462 13.6362C13.3972 13.849 13.3827 14.0717 13.3043 14.2764C13.226 14.4812 13.0873 14.6589 12.9057 14.7874C12.724 14.9159 12.5075 14.9895 12.2831 14.999C12.0587 15.0084 11.8364 14.9533 11.6441 14.8405Z"
        fill="#EB9B42"
      />
    </svg>
  );
};

export default HalfStar;

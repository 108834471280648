import React, { useMemo, useState } from 'react';
import ContentCard from '../LearningPathDetails/ContentCard';
import { CONTENT_TYPES, REP_PATH_STATUS } from '../../LearningPaths.utils';
import styles from './AssignedLearningPathDetails.module.scss';
import { useHistory } from 'react-router-dom';

const TABS = {
  ALL: 'all',
  YET: 'yet_to_start',
  PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

const LearningPathContents = ({ learningPath = {} }) => {
  const [tab, setTab] = useState(TABS.ALL);

  const paths = useMemo(() => {
    switch (tab) {
      case TABS.ALL:
        return learningPath?.contents || [];

      case TABS.YET:
        return learningPath?.contents?.filter(
          (content) => content?.status === REP_PATH_STATUS.YET_TO_START
        );

      case TABS.PROGRESS:
        return learningPath?.contents?.filter(
          (content) => content?.status === REP_PATH_STATUS.IN_PROGRESS
        );

      case TABS.COMPLETED:
        return learningPath?.contents?.filter(
          (content) => content?.status === REP_PATH_STATUS.COMPLETED
        );

      default:
        return [];
    }
  }, [tab, learningPath]);

  // Navigate to selected module
  const history = useHistory();
  const goToModule = (uuid) => {
    if (!uuid) return;
    history.push(`/training/${uuid}`, { rep_lp_id: learningPath.uuid });
  };

  const { modules_completion, modules_in_progress, modules_yet_to_start } = learningPath;

  return (
    <div className={styles.contentsContainer}>
      <div className={styles.tabs}>
        {modules_yet_to_start > 0 && (
          <button
            onClick={() => setTab(TABS.YET)}
            className={`${styles.tabItem} ${tab === TABS.YET ? styles.active : ''}`}
          >
            Yet to start ({modules_yet_to_start})
          </button>
        )}
        {modules_in_progress > 0 && (
          <button
            onClick={() => setTab(TABS.PROGRESS)}
            className={`${styles.tabItem} ${tab === TABS.PROGRESS ? styles.active : ''}`}
          >
            In progress ({modules_in_progress})
          </button>
        )}
        {modules_completion > 0 && (
          <button
            onClick={() => setTab(TABS.COMPLETED)}
            className={`${styles.tabItem} ${tab === TABS.COMPLETED ? styles.active : ''}`}
          >
            Completed ({modules_completion})
          </button>
        )}

        <button
          onClick={() => setTab(TABS.ALL)}
          className={`${styles.tabItem} ${tab === TABS.ALL ? styles.active : ''}`}
        >
          All modules
        </button>
      </div>
      {/* TODO Ask backend to return content_type per content in LP contents */}
      <div className={styles.contents}>
        {paths.map((path) => (
          <div key={path?.content?.uuid} onClick={() => goToModule(path?.content?.uuid)}>
            <ContentCard contentData={{ ...path, content_type: CONTENT_TYPES.TRAINING_MODULES }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearningPathContents;

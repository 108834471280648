import React, { useMemo } from 'react';

import styles from '../../Payment.module.scss';
import poweredByStripe from 'assets/poweredbystripe.png';
import { usePaymentStore } from '../../../Payment.store';

const PaymentPagination = ({ steps = [] }) => {
  const paymentStore = usePaymentStore((state) => state);
  const { currentStep, setStep } = paymentStore;

  const stepIndex = useMemo(() => steps.indexOf(currentStep), [currentStep, steps]);

  const Step = ({ index }) => {
    const disabled = stepIndex < index;
    const active = index === stepIndex;
    const onClick = () => !disabled && setStep(steps[index]);
    return (
      <div
        onClick={onClick}
        className={active ? styles.active : disabled ? styles.disabled : styles.normal}
      />
    );
  };

  return (
    <>
      <div>
        <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">
          <img height={24} src={poweredByStripe} alt="Powered by stripe" />
        </a>
      </div>
      <div className={styles.pagination}>
        <div className={styles.container}>
          {steps.map((p, index) => (
            <Step key={index} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentPagination;

import queryString from 'query-string';

import { Subscription } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';

// Date in which old organizations start to pay
const oldOrgsPaymentDate = '2023-06-30';
const alertDays = 14;

// Date in which an organization is considered new
const newOrganizationDate = '2022-12-21';

// Functions related to Dates

export const isOldOrganization = () => {
  // Checks if the org was created before newOrganizationDate
  const createdAt = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg?.created_at;
  if (!createdAt) return false;
  const d1 = new Date(createdAt);
  const d2 = new Date(newOrganizationDate);
  return d1.getTime() < d2.getTime();
};

export const getPaymentDate = () => {
  return oldOrgsPaymentDate;
};

export const isPaymentDay = () => {
  // Checks if the current date is after oldOrgsPaymentDate
  const d1 = new Date();
  const d2 = new Date(oldOrgsPaymentDate);
  return d1.getTime() > d2.getTime();
};

export const shouldRedirectToPayment = ({ role = '' }) => {
  // Check if the user should be redirected to payment screen
  if (role !== 'primary_admin') return false;
  return !userHasSubscription();
};

export const shouldRedirectToHome = ({ role = '' }) => {
  if (!role) return false;
  return !shouldRedirectToPayment({ role });
};

const calcTrialDays = () => {
  const d1 = new Date();
  const d2 = new Date(oldOrgsPaymentDate);

  const diffTime = Math.abs(d2 - d1);
  const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return days;
};

export const getTrialDays = () => {
  if (isOldOrganization()) {
    // Old orgs get trial days based on days before oldOrgsPaymentDate
    if (isPaymentDay()) return 0;
    return calcTrialDays();
  }
  // New orgs get 14 days free trial
  return 14;
};

export const showTrialDaysAlert = () => {
  // Remove trial alert as part of issue #269
  return false;
  // const isTrial = getSubscriptionStatusValue() === 1;
  // return userHasSubscription() && isTrial;
};

export const showPreviousUsersAlert = () => {
  if (isPaymentDay()) return false;
  const days = calcTrialDays();
  return days <= alertDays;
};

export const showSubscriptionMenuButton = ({ role = '' }) => {
  return role === 'primary_admin';
};

export const showSubscriptionPage = ({ role = '' }) => {
  return showSubscriptionMenuButton({ role });
};

export const allowInviteUsers = () => {
  // TODO create logic for old organizations
  if (!isOldOrganization()) return true;
  // Old orgs cannot invite users during the alert period
  // (14 days before oldOrgsPaymentDate)
  return true;
};

export const showCreateSubscriptionButton = () => {
  if (userHasSubscription()) return false;
  return isOldOrganization();
};

export const getTrialDaysText = ({ days, variant }) => {
  // Specific organizations get a custom message
  if (['fiMUrwC8', 'zA6PLBsf', 'ELRDdAKo', 'yb7h2E27'].includes(getOrganizationSlug())) {
    return 'Your subscription will start immediately';
  }

  // Variant 1: For trial period alerts
  // Variant 2: When subscribing
  if (isOldOrganization()) {
    if (variant === 1)
      return 'You are currently under the trial period valid until June 30th 2023.';
    if (variant === 2) return `You will be charged after June 30th 2023.`;
  } else {
    if (variant === 1) {
      const msg = `You have ${days} more day${days > 1 ? 's' : ''} of salestable free trial`;
      return msg;
    }
  }
  return `You will be charged after a ${days}-day trial.`;
};

export const showAddPaymentMethodButton = () => {
  if (!userHasSubscription()) return false;
  // Show only when payment has an error
  return paymentHasError();
};

// Other functions

export const updateLocalSubscription = (subscription = {}) => {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  userInfo.selectedOrg = {
    ...userInfo.selectedOrg,
    subscription: { ...userInfo.selectedOrg?.subscription, ...subscription },
    max_file_upload_size:
      subscription?.max_file_upload_size ||
      subscription?.product?.max_file_upload_size ||
      userInfo.selectedOrg?.max_file_upload_size,
  };
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const updateSubscriptionModules = (quantity) => {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const total_modules = userInfo.selectedOrg.subscription.total_modules + quantity;
  userInfo.selectedOrg = {
    ...userInfo.selectedOrg,
    subscription: { ...userInfo.selectedOrg?.subscription, total_modules },
  };
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const updateSubscriptionAssessments = (quantity) => {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const total_assessments = userInfo.selectedOrg.subscription.total_assessments + quantity;
  userInfo.selectedOrg = {
    ...userInfo.selectedOrg,
    subscription: { ...userInfo.selectedOrg?.subscription, total_assessments },
  };
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const decreaseSubscriptionUsers = () => {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const active_users = userInfo.selectedOrg.subscription.active_users - 1;
  userInfo.selectedOrg = {
    ...userInfo.selectedOrg,
    subscription: { ...userInfo.selectedOrg?.subscription, active_users },
  };
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const userHasSubscription = () => {
  const organization = Organization.getSelectedOrg();
  return organization?.subscription?.product !== 6;
};

export const getSubscriptionStatusValue = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg?.subscription_status?.value;

const getOrganizationSlug = () => JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg?.slug;

export const paymentHasError = () => getSubscriptionStatusValue() === 3;

export const Payment = (function () {
  // Constants
  const PROCESS_TYPE = {
    NEW_SUBSCRIPTION: 'payment',
    UPGRADE: 'upgrade',
    ADD_PAYMENT: 'addNewPayment',
  };

  const STEPS = {
    INTRODUCTION: 'introduction',
    BILLING: 'billing',
    ADDRESS: 'address',
    CARD: 'payment',
    SUMMARY: 'summary',
  };

  // User / organization related info

  // Payment flow handlers
  const getFormSteps = ({ product, process }) => {
    const isFree = product?.selectedPrice?.isFree;
    const userHasPaymentAssociated = Subscription.hasPayment();
    const skipToSummary = isFree || userHasPaymentAssociated;

    switch (process) {
      case PROCESS_TYPE.NEW_SUBSCRIPTION:
      case PROCESS_TYPE.UPGRADE:
        if (skipToSummary) return [STEPS.INTRODUCTION, STEPS.SUMMARY];
        return [STEPS.INTRODUCTION, STEPS.BILLING, STEPS.ADDRESS, STEPS.CARD, STEPS.SUMMARY];

      case PROCESS_TYPE.ADD_PAYMENT:
        return [STEPS.BILLING, STEPS.ADDRESS, STEPS.CARD, STEPS.SUMMARY];

      default:
        return [];
    }
  };

  const getTrialText = () => {
    const days = 14;
    return `You will be charged after a ${days}-day trial.`;
  };

  const storePriceAndCoupon = ({ location }) => {
    const queryParams = queryString.parse(location.search);
    const paymentData = JSON.parse(localStorage.getItem('paymentData')) || {};

    // Detect if a price has been selected from webpage
    if (!!queryParams?.price_id) paymentData.preSelectedPrice = queryParams.price_id;
    // Coupon sent in URL to apply during payment process
    if (!!queryParams?.coupon) paymentData.urlCoupon = queryParams.coupon;

    localStorage.removeItem('preSelectedPrice');
    localStorage.setItem('paymentData', JSON.stringify(paymentData));
  };

  const getPriceAndCoupon = () => {
    try {
      const data = localStorage.getItem('paymentData') || {};
      const { urlCoupon = '', preSelectedPrice = '' } = JSON.parse(data);
      return { urlCoupon, preSelectedPrice };
    } catch (error) {
      return { urlCoupon: '', preSelectedPrice: '' };
    }
  };

  const removePriceAndCoupon = () => {
    localStorage.removeItem('paymentData');
  };

  return {
    PROCESS_TYPE,
    STEPS,
    getFormSteps,
    getTrialText,
    storePriceAndCoupon,
    getPriceAndCoupon,
    removePriceAndCoupon,
  };
})();

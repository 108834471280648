import React from 'react';
import styles from './PostReactions.module.scss';
import commentIcon from 'assets/stream/comment.svg';
import { useMemo } from 'react';
import Reactions from './Reactions';

const PostReactions = ({ data = {}, handleComments = () => null }) => {
  const commentLabel = useMemo(() => `comment${data.total_comments > 1 ? 's' : ''}`, [data]);

  return (
    <div className={styles.reactionMenu}>
      <div className={styles.comments}>
        <img className={styles.icon} src={commentIcon} alt="comments" />
        {data.total_comments > 0 ? (
          <div className={styles.commentsCounter} onClick={handleComments}>
            <span className={styles.number}>{data.total_comments}</span>
            <span className={styles.text}>{commentLabel}</span>
          </div>
        ) : (
          <span>No comments, be the first to reply!</span>
        )}
      </div>
      <Reactions data={data} />
    </div>
  );
};

export default PostReactions;

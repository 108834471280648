import React, { useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './RecordVideo.module.css';
import { CustomButton } from 'shared_ui_components';
import { useSubmitRoleplay, useUpdateRecordingInScenarioMutation } from 'features/roleplay/Roleplay.queries';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const ContinueButtons = ({
  recordingURL = '',
  submitBtn = false,
  disabled = false,
  loading = false,
  scenarioData = {},
  scenarios = [],
  showReRecord = false,
  onReRecordClick = () => null,
  setAttempt = () => null,
  attempt,
}) => {
  // Retake handlers
  const showRetake = useRoleplayStore((state) => state?.showRetake);

  // Scenario data
  const id = useMemo(() => scenarioData?.uuid, [scenarioData]);
  const indexInScenarios = useMemo(
    () => scenarios?.findIndex((s) => s?.uuid === id),
    [id, scenarios]
  );

  // Navigation
  const history = useHistory();
  let location = useLocation();
  const { uuid } = useParams();

  const setScenarioSelected = useRoleplayStore((state) => state?.setScenarioSelected);
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const { mutate: submitRoleplay } = useSubmitRoleplay();
  const navigateNext = () => {
    if (isLastScenario) {
      if (scenarios.length === 1) {
        submitRoleplay(
          { roleplay_id: roleplayView.uuid },
          {
            onSuccess: () => {
              history.push({
                pathname: `/roleplay`,
                state: {
                  prevPath: `${location.pathname}${location.search ? location.search : ''}`,
                },
              });
            },
          }
        );
      } else 
      return history.push(`/roleplay/${uuid}/submit`);
    }
    const newArr = [...scenarios];
    for (let index = 0; index < newArr.length - indexInScenarios; index++) {
      newArr.unshift(newArr.pop());
    }
    newArr.shift();
    const scenario = newArr.find((s) => {
      const retake = Object.keys(s?.response_retake || {})?.length > 0;
      const video = retake ? s?.response_retake?.video : s?.response?.video || '';
      return !video;
    });
    if (scenario) setScenarioSelected(scenario);
  };

  const navigatePrev = () => {
    if (indexInScenarios <= 0) return;
    setScenarioSelected(scenarios[indexInScenarios - 1]);
  };

  const handleClick = () => {
    if (showRetake && attempt === 1) setAttempt(2); // Change to 2nd attempt
    else if (submitBtn) onSubmit(); // Submits a video and navigates
    else navigateNext(); // Only navigates
  };

  // Update URL in the scenario
  const { mutate, isLoading } = useUpdateRecordingInScenarioMutation();
  const onSubmit = () => {
    if (!recordingURL || !id) return;
    const response_uuid = showRetake
      ? scenarioData?.response_retake?.uuid
      : scenarioData?.response?.uuid;
    mutate({ url_video: recordingURL, roleplay_id: uuid, response_uuid }, { onSuccess });
  };

  const onSuccess = () => navigateNext();

  // UI handlers
  const isLastScenario = useMemo(() => {
    if (scenarios?.length === 1) return true;
    const allHaveVideos = scenarios
      ?.filter((s) => s?.uuid !== id)
      ?.map((s) => s?.response_retake || s?.response)
      ?.every((response) => !!response?.video);
    return allHaveVideos;
  }, [scenarios, id]);

  const label = useMemo(() => {
    if (loading || isLoading) return 'Loading...';
    if (showRetake && attempt === 1) return 'Retake';
    if (isLastScenario && !disabled && !submitBtn) return 'Submit';
    if (isLastScenario) return 'Save and submit';
    if (!disabled && !submitBtn) return 'Continue';
    return 'Save and continue';
  }, [loading, isLoading, submitBtn, disabled, isLastScenario, showRetake, attempt]);

  const isDisabled = useMemo(
    () => disabled || loading || isLoading,
    [disabled, isLoading, loading]
  );

  return (
    <div className={styles.continueButtons}>
      {showReRecord && (
        <button className={styles.resetButton} onClick={onReRecordClick}>
          <svg
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 21C7.75 21 6.57917 20.7625 5.4875 20.2875C4.39583 19.8125 3.44583 19.1708 2.6375 18.3625C1.82917 17.5542 1.1875 16.6042 0.7125 15.5125C0.2375 14.4208 0 13.25 0 12C0 10.75 0.2375 9.57917 0.7125 8.4875C1.1875 7.39583 1.82917 6.44583 2.6375 5.6375C3.44583 4.82917 4.39583 4.1875 5.4875 3.7125C6.57917 3.2375 7.75 3 9 3H9.15L7.6 1.45L9 0L13 4L9 8L7.6 6.55L9.15 5H9C7.05 5 5.39583 5.67917 4.0375 7.0375C2.67917 8.39583 2 10.05 2 12C2 13.95 2.67917 15.6042 4.0375 16.9625C5.39583 18.3208 7.05 19 9 19C10.95 19 12.6042 18.3208 13.9625 16.9625C15.3208 15.6042 16 13.95 16 12H18C18 13.25 17.7625 14.4208 17.2875 15.5125C16.8125 16.6042 16.1708 17.5542 15.3625 18.3625C14.5542 19.1708 13.6042 19.8125 12.5125 20.2875C11.4208 20.7625 10.25 21 9 21Z"
              fill="#0494FD"
            />
          </svg>
          Re-record
        </button>
      )}
      {indexInScenarios > 0 && (
        <CustomButton className="secondaryBtn" onClick={navigatePrev}>
          Previous scenario
        </CustomButton>
      )}
      <CustomButton
        className={`${styles.continueButton} primaryBtn`}
        disabled={isDisabled}
        onClick={handleClick}
      >
        {label}
      </CustomButton>
    </div>
  );
};

export default ContinueButtons;

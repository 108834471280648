import React from 'react';
import { useHubspotStore } from '../Hubspot.store';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import ProviderConstants from 'features/providers/Providers.constants';
import ProviderCard from 'features/providers/components/ProviderCard/ProviderCard';

const HubspotIntegrationCard = ({ providers }) => {
  const openModal = useHubspotStore((state) => state?.openSettings);

  const allow = () => Subscription.allow({ key: Subscription.keys.hubspot });

  const handleSetup = () => allow() && openModal();
  const handleIntegrate = () => allow();

  return (
    FeatureFlag.enabled('HUBSPOT') && (
      <ProviderCard
        providers={providers}
        code={ProviderConstants.providerCodes.HUBSPOT}
        onBeginIntegration={handleIntegrate}
        onSetupIntegration={handleSetup}
      />
    )
  );
};

export default HubspotIntegrationCard;

import { imageBasePath, goalFrequencies } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../../../utils';

export default function GoalCard(props) {
  const { goal = {}, type = '' } = props;
  return (
    <div className={styles.goalCard + ' ' + props.className}>
      <div className={styles.cardWrapper}>
        <div className={styles.topBlk + ' ' + props.bgclassName}>
          {goal?.isFromHubspot ? (
            <div className={styles.hubspot}>
              <img src={imageBasePath + 'hubspot_logo.svg'} alt="HubSpot" />
            </div>
          ) : goal.unit === 1 ? (
            <img src={imageBasePath + 'hash_icon.svg'} alt="image" />
          ) : (
            <img src={imageBasePath + `revenue_icon.svg`} alt="image" />
          )}
          <h3 className="heading6">{goal.name}</h3>
        </div>
        <div className={styles.bottomBlk}>
          <h4 className="supportText">
            {props.goalIcon ? (
              <img className={styles.goalIcon} src={imageBasePath + props.goalIcon} alt="image" />
            ) : (
              <img className={styles.goalIcon} src={imageBasePath + 'goal.svg'} alt="image" />
            )}
            {props.goalTargetText ? props.goalTargetText : 'Goal Target'}
          </h4>
          <h5 className="heading2">
            {goal.unit === 1 ? (
              CustomNumberFormatter(goal.target)
            ) : (
              <>
                <span>$</span>
                <NumberFormat
                  format={CustomNumberFormatter}
                  value={goal.target}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </>
            )}
          </h5>
          {type == 'setup' ? (
            <p className={styles.tag + ' supportText'}>
              <span>{goalFrequencies.find((item) => item.id === goal.frequency)?.label}</span>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

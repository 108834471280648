import React from 'react';

import styles from './Comment.module.scss';
import { Skeleton } from '@material-ui/lab';

const CommentShimmer = () => {
  return (
    <div className={styles.comment}>
      <div className={styles.picture}>
        <Skeleton width={32} height={32} variant="circle" />
      </div>
      <div className={styles.commentContent}>
        <span className={styles.name}>
          <Skeleton variant="text" width={90} />
        </span>
        <p className={styles.commentText}>
          <Skeleton variant="text" />
        </p>
      </div>
    </div>
  );
};

export default CommentShimmer;

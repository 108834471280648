import React from 'react';
import styles from '../../../assets/styles/onboarding.module.scss';
import { imageBasePath } from '../../../constants';
import { ReactHtmlParser, TextArea } from '../../../shared_ui_components';
import { fetchContentType } from '../../../utils';

export default function EmbedVideo(props) {
  const { formData, page = 'onboarding', error = {} } = props;
  return (
    <div className={styles.embedVideoBlock}>
      <div className={styles.noteBlock}>
        <img src={imageBasePath + 'info_icon.png'} alt="info icon" />
        <p className="supportText">
          By embedding, you can add any content from your favorite platform
        </p>
      </div>
      <TextArea
        label={`Embed code`}
        placeholder="Enter the embed code"
        rows={3}
        onChange={(e) => {
          props.handleChange('content', e.target.value);
          if (props.isCustom || (page == 'training' && e.target.value && e.target.value.trim())) {
            fetchContentType(e.target.value, props.handleChange);
          }
        }}
        onBlur={(e) => {
          if (e.target.value != e.target.value.trim()) {
            props.handleChange('content', e.target.value.trim());
          }
        }}
        value={formData.content}
        className={error.content ? styles.errorGroup + ' errorGroup' : ' '}
      />
      {error.content ? <h6 className={`errorMsg ${styles.errorMsg}`}>{error.content}</h6> : null}
      <div className={styles.previewBlock}>
        {formData.content && formData.content.trim() ? (
          <ReactHtmlParser content={formData.content} />
        ) : (
          <>
            <img src={imageBasePath + 'dragdrop.png'} alt="drag and drop" />
            <p className="textCenter supportText">Video preview will come here</p>
          </>
        )}
      </div>
    </div>
  );
}

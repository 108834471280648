import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import Subscription from './containers/Subscription';

export const subscriptionRoutes = [
  {
    path: '/subscription',
    component: pageLayoutHoc(Subscription),
    key: 'subscription',
  },
];

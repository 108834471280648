import React from 'react';
import { CircularProgress } from '@material-ui/core';

import './Nudge.scss';
import NudgeLogic from './Nudge.logic';
import { imageBasePath } from './../../constants/index';

const Nudge = ({ user, resource = 'training' }) => {
  const { handleClick, showNudge, disabled, loading } = NudgeLogic({ user, resource });

  return !showNudge ? null : (
    <div className="nudge-container">
      {!loading ? (
        <img
          onClick={handleClick}
          className={`nudge ${disabled ? 'disabled' : ''} `}
          title="nudge user"
          height="23px"
          alt="nudge"
          src={imageBasePath + 'nudge_icon.svg'}
        />
      ) : (
        <CircularProgress size={22} color="inherit" />
      )}
    </div>
  );
};

export default Nudge;

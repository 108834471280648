import React, { useEffect } from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

export default function OrganizationExist(props) {
  const { submitLoader } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  useEffect(() => {
    if (!userInfo || !userInfo?.available_organisation) {
      localStorage.clear();
      window.location.href = '/';
    }
  }, []);
  return (
    <>
      {userInfo && userInfo?.available_organisation ? (
        <div className={styles.cardWrapper + ' ' + styles.organizationExistWrapper}>
          <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
          {userInfo.available_organisation.logo ? (
            <img
              className={styles.organizationLogo}
              src={userInfo.available_organisation.logo}
              alt="salestable"
            />
          ) : (
            <img src={imageBasePath + 'upload_company_logo.png'} alt="Upload company Logo" />
          )}
          <h1 className="heading3">
            The organization "{userInfo.available_organisation.name}" <br /> already exists!
          </h1>
          <p className="paragraph">
            If you are a member of this organization, please contact the administrator of this
            account
          </p>
          <CustomButton
            children={submitLoader ? 'Request Access' : 'Request Access'}
            className={`primaryBtn submitBtn ${submitLoader ? ' disabledBtn' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.handleContactAdmin();
            }}
          />
        </div>
      ) : null}
    </>
  );
}

import { create } from 'zustand';

const initialState = {
  session_id: '',
  messages: [],
};
export const useSalesAssistStore = create((set, get) => ({
  ...initialState,
  setId: (id) => set(() => ({ session_id: id })),
  updateMessages: (newMessage) => {
    const temp = [newMessage, ...get().messages];
    return set({ messages: temp });
  },
  reset: () => {
    set(initialState);
  },
}));

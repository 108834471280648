import React, { useState } from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';
import { CustomButton, CustomTooltipWhite } from '../../../shared_ui_components';
import { CheckinDrawer, AddJobDialog } from './';
import { ConfirmationDialog } from '../../../shared_elements';
import { deleteJobManually, updateJobManually } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import Shimmer from './Shimmer';

export default function OverviewTable(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isProvider, job } = props;
  const [openCheckinDrawer, setOpenCheckinDrawer] = useState(false);
  const [updateJob, updateJobSet] = useState(false);
  const [deleteJob, deleteJobSet] = useState(false);
  const [checkinJob, checkinJobSet] = useState({});
  const [currentAnchor, currentAnchorSet] = useState({});
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [closeJob, setCloseJob] = useState(false);

  const handleClick = (event, jitem) => {
    setAnchorEl(event.currentTarget);
    currentAnchorSet(jitem);
  };

  const handleDeleteJob = () => {
    setDeleteLoader(true);
    deleteJobManually(checkinJob.uuid).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode === 200) {
        props.updateManualJob('delete', checkinJob);
        setTimeout(() => {
          checkinJobSet({});
        }, 500);
        deleteJobSet(false);
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const handleUpdate = (item) => {
    currentAnchorSet({});
    setAnchorEl(null);
    checkinJobSet(item);
    setTimeout(() => {
      updateJobSet(true);
    }, 100);
  };

  const handleDelete = (item) => {
    currentAnchorSet({});
    setAnchorEl(null);
    checkinJobSet(item);
    setTimeout(() => {
      deleteJobSet(true);
    }, 100);
  };
  const handleCloseJob = () => {
    setDeleteLoader(true);
    updateJobManually(checkinJob.uuid, { status: 2 }).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode === 200) {
        props.updateManualJob('close', response.data);
        setTimeout(() => {
          checkinJobSet({});
        }, 500);
        setCloseJob(false);
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  return (
    <div className={styles.overviewWrapper}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={styles.tableTitleRow}
      >
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <h4 className={styles.jobTitle + ' supportTextSemibold'}>Job Title</h4>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <h4 className="supportTextSemibold">
            Total Applicants
            {/* <img src={imageBasePath + 'collapse.svg'} alt="image" /> */}
          </h4>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <h4 className="supportTextSemibold">
            Interviews Stage
            {/* <img src={imageBasePath + 'collapse.svg'} alt="image" /> */}
          </h4>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <h4 className="supportTextSemibold">
            Offers Made
            {/* <img src={imageBasePath + 'collapse.svg'} alt="image" /> */}
          </h4>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <h4 className="supportTextSemibold">
            Candidates Accepted
            {/* <img src={imageBasePath + 'collapse.svg'} alt="image" /> */}
          </h4>
        </Grid>
      </Grid>
      {isProvider && job && job?.recruitments?.length ? (
        <div className={styles.contentWrapper}>
          {job?.recruitments.map((item, key) => (
            <Grid container direction="row" spacing={2} className={styles.tableContentRow}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <div className={styles.jobTitleBlk}>
                  <img
                    className={styles.jobIcon}
                    src={imageBasePath + 'job_icon.svg'}
                    alt="Image"
                  />
                  <div className={styles.info}>
                    <h3 className="heading6">
                      {item.job_title}
                      <CustomTooltipWhite
                        tooltipIcon="info_icon.svg"
                        title={
                          <p className="supportText">
                            Job ID: <span style={{ color: '#193448' }}>{item.job_id}</span>
                          </p>
                        }
                      />
                    </h3>
                    <p className="supportText">{item.location}</p>
                  </div>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{item.total_application}</p>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{item.interviews_conducted}</p>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{item.offers_made}</p>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className={styles.candidateAcceptedBlk}>
                <span className={styles.count + ' heading5'}>{item.accepted_candidates}</span>
              </Grid>
            </Grid>
          ))}
        </div>
      ) : !isProvider && job ? (
        <div className={styles.contentWrapper}>
          {job.map((jitem, key) => (
            <Grid
              key={key}
              container
              direction="row"
              spacing={2}
              className={styles.tableContentRow}
            >
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <div className={styles.jobTitleBlk}>
                  <img
                    className={styles.jobIcon}
                    src={imageBasePath + 'job_icon.svg'}
                    alt="Image"
                  />
                  <div className={styles.info}>
                    <h3 className="heading6">
                      {jitem.job_title}
                      {jitem.status === 2 ? (
                        <span className={styles.closedLabel + ' supportTextSemibold'}>Closed</span>
                      ) : null}
                      {/* <CustomTooltipWhite tooltipIcon="info_icon.svg" title={<p className="supportText">Job ID: <span style={{ color: '#193448' }}>{job.job_id}</span></p>} /> */}
                    </h3>
                    <p className="supportText">{jitem.location}</p>
                  </div>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{jitem.total_application}</p>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{jitem.interviews_conducted}</p>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <p className="heading5">{jitem.offers_made}</p>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className={styles.candidateAcceptedBlk}>
                <span className={styles.count + ' heading5'}>{jitem.accepted_candidates}</span>
                {!isProvider ? (
                  <>
                    <CustomButton
                      className={
                        styles.setupIntegrationBtn +
                        ` ${jitem.status === 2 ? `hidden` : ''} secondaryBtn smallBtn`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        checkinJobSet(jitem);
                        setOpenCheckinDrawer(!openCheckinDrawer);
                      }}
                    >
                      Check-in Status
                    </CustomButton>
                    <span className={styles.more}>
                      <a
                        aria-controls={'simple-menu-' + jitem.uuid}
                        className={'more'}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleClick(e, jitem);
                        }}
                      >
                        <img
                          className="more"
                          src={imageBasePath + 'more_vertical_gray.svg	'}
                          alt="More"
                          style={{ verticalAlign: 'middle' }}
                        />
                      </a>
                    </span>
                    <Menu
                      id={'simple-menu-' + jitem.uuid}
                      anchorEl={anchorEl}
                      keepMounted
                      open={currentAnchor.uuid === jitem.uuid ? true : false}
                      onClose={(e) => {
                        setAnchorEl(null);
                        e.preventDefault();
                        e.stopPropagation();
                        currentAnchorSet({});
                      }}
                      className="moreMenu"
                    >
                      {jitem.status != 2 ? (
                        <div>
                          <MenuItem
                            onClick={(e) => {
                              checkinJobSet(jitem);
                              e.preventDefault();
                              e.stopPropagation();
                              currentAnchorSet({});
                              setAnchorEl(null);
                              setCloseJob(true);
                            }}
                          >
                            <img src={imageBasePath + 'close.svg'} alt="Edit" />
                            <span className="supportText">Close</span>
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAnchorEl(null);
                              handleUpdate(jitem);
                            }}
                          >
                            <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                            <span className="supportText">Edit</span>
                          </MenuItem>
                        </div>
                      ) : null}
                      <MenuItem
                        className="deleteAction"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          deleteJobSet(true);
                          handleDelete(jitem);
                        }}
                      >
                        <img src={imageBasePath + 'delete_red.svg'} alt="Delete" />
                        <span className="supportText">Delete</span>
                      </MenuItem>
                    </Menu>
                  </>
                ) : null}
              </Grid>
            </Grid>
          ))}
        </div>
      ) : null}
      <CheckinDrawer
        open={openCheckinDrawer}
        handleClose={() => {
          setOpenCheckinDrawer(false);
          checkinJobSet({});
        }}
        job={checkinJob}
        updateManualJob={props.updateManualJob}
      />
      <AddJobDialog
        type="edit"
        open={updateJob}
        handleClose={() => {
          updateJobSet(false);
          checkinJobSet({});
        }}
        job={checkinJob}
        updateManualJob={props.updateManualJob}
      />
      {deleteJob || closeJob ? (
        <ConfirmationDialog
          open={true}
          handleSubmit={() => {
            if (deleteJob) {
              handleDeleteJob();
            } else {
              handleCloseJob();
            }
          }}
          handleClose={() => {
            deleteJobSet(false);
            checkinJobSet({});
            setCloseJob(false);
          }}
          classNames={styles.deleteJobDialog}
          image={deleteJob ? imageBasePath + 'delete_job.svg' : imageBasePath + 'close_job_img.svg'}
          title={
            deleteJob ? (
              <p className="heading3">
                Are you sure you want to <span>delete</span> <br /> your job{' '}
                <span>“{checkinJob.job_title}”</span>?
              </p>
            ) : (
              <p className="heading3">
                Are you sure you want to <span>close</span> <br /> job{' '}
                <span>“{checkinJob.job_title}”</span>?
              </p>
            )
          }
          // description={deleteJob ? `This action can't be undone once deleted` : `This action can't be undone once closed`}
          rejectText={`No, Cancel`}
          acceptText={deleteJob ? `Yes, Delete` : `Yes, Close`}
          isDelete={deleteJob ? true : false}
          loader={deleteLoader}
        />
      ) : null}
      {/* <Shimmer type="RecruitmentOverviewTable" /> */}
    </div>
  );
}

import React, { useState } from 'react';
import classes from './RepPovSwitch.module.css';
import { MenuItem, Switch, withStyles } from '@material-ui/core';

import Organization from 'infrastructure/Organization';
import ConfirmationDialog from '../../../../shared_elements/ConfirmationDialog';
import { imageBasePath } from 'constants';
import { FeatureFlag } from 'controllers/_exports';
import classNames from 'classnames';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';

const RepPovSwitch = () => {
  const { isPathSupported, isActive, switchOff, switchOn, toastUnsupported } = useSalesRepView();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const close = () => setOpenConfirmation(false);
  const handleSubmit = () => {
    if (isActive) switchOff({ withToast: true });
    else if (!isPathSupported) toastUnsupported();
    else switchOn();

    close();
  };

  if (!FeatureFlag.enabled('SALES_REP_POV') || !Organization.isAdmin()) return null;
  return (
    <div className={classes.item}>
      <MenuItem
        onClick={() => setOpenConfirmation(true)}
        className={classNames(['supportText', 'menuItem'])}
      >
        <span>View as Sales Rep</span>
        <GreenSwitch checked={isActive} />
      </MenuItem>
      <ConfirmationDialog
        open={openConfirmation}
        title={
          <span>
            Are you sure you want to switch to <b>{isActive ? 'Admin' : 'Sales Rep'}</b> view?
          </span>
        }
        handleClose={close}
        rejectText="No, cancel"
        acceptText="Yes, change view"
        image={imageBasePath + 'admin_view_switch.svg'}
        handleSubmit={handleSubmit}
        description={isActive ? '' : 'Please note that some features may be limited in this view.'}
      />
    </div>
  );
};

const GreenSwitch = withStyles({
  root: {
    cursor: 'pointer',
    width: 28,
    height: 16,
    padding: 0,
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#FFF',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#2DC38D',
        borderColor: '#2DC38D',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #BFBFBF`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#87A0B4',
  },
  checked: {},
})(Switch);

export default RepPovSwitch;

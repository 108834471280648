import React from 'react';
import { Redirect } from 'react-router-dom';

import Organization from 'infrastructure/Organization';
import { ProviderSelector } from '../_exports';

const Providers = () => {
  if (!Organization.isOwner() && !Organization.isAdmin()) return <Redirect to="/" />;
  return <ProviderSelector />;
};

export default Providers;

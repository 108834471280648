import React, { Component } from 'react';
import newStyles from '../../../assets/styles/signIn.module.scss';
import { NewSignInForm as SignInForm } from '../components';
import { regExpression } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { signInApi, socialSignUpApi, handleSiginResponse } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntercomIntegration from 'features/intercom/components/IntercomIntegration';
import SignInCarousel from '../components/SignInCarousel';

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
        remember_me: 0,
      },
      error: {},
      errorCode: {
        email: {
          0: '',
          1: 'Please enter your Email Address to Signin',
          4: 'Invalid Email Address, Please try again',
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
        password: {
          0: '',
          1: 'Please enter your Password!',
        },
        passwordObj: {
          requiredFlag: true,
        },
        remember_me: {
          0: '',
          1: '',
        },
        remember_meObj: {
          requiredFlag: false,
        },
      },
      submitLoader: false,
      socialLoginLoader: false,
      socialLinkedinLoader: false,
      organisations: [],
    };
    this.handleSiginResponse = handleSiginResponse.bind(this);
  }

  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: '',
      },
    });
  };

  togglePassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        password_show: !this.state.formData.password_show,
      },
    });
  };

  componentDidMount() {
    var base_url = window.location.origin;
    const querys = queryString.parse(this.props.location.search);
    const token = querys.token;
    const google_access_token = queryString.parse(this.props.location.hash)
      ? queryString.parse(this.props.location.hash).access_token
      : '';
    const linkedIn_token = querys.code;

    if (google_access_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState({
        socialLoginLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          access_token: google_access_token,
          social_type: 'google',
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else if (linkedIn_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState({
        socialLinkedinLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          access_token: linkedIn_token,
          social_type: 'linkedin',
          redirect_uri: `${base_url}/signin`,
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else {
      this.setState({
        submitLoader: false,
      });
    }

    const params = queryString.parse(window.location.search);
    if (params.error) {
      const errorMessage = params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          { error: params.error, state: params.state, errorMessage, from: 'Linked In' },
          window.location.origin
        );
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }
    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          { code: params.code, state: params.state, from: 'Linked In' },
          window.location.origin
        );
    }
    if (params.linkedin_redirect_url) {
      window.location.href = params.linkedin_redirect_url;
    }
  }

  handleSubmit = () => {
    const { errorCode, formData } = this.state;
    const validateNewInput = {
      email:
        errorCode['email'][
          fieldValidation({
            ...errorCode['emailObj'],
            fieldval: formData.email,
          })
        ],
      password:
        errorCode['password'][
          fieldValidation({
            ...errorCode['passwordObj'],
            fieldval: formData.password,
          })
        ],
      remember_me:
        errorCode['remember_me'][
          fieldValidation({
            ...errorCode['remember_meObj'],
            fieldval: formData.remember_me,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      this.setState({
        submitLoader: true,
      });
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      signInApi({ formData: { ...formData, email: formData.email.trim() } })
        .then((response) => {
          this.handleSiginResponse(response, formData);
        })
        .catch((error) => {
          this.setState({
            submitLoader: false,
          });
          toastFlashMessage(error?.message || 'Unexpected error', 'error');
        });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  render() {
    const { formData, error, submitLoader, socialLoginLoader, socialLinkedinLoader } = this.state;
    return (
      <div className={newStyles.signInPage}>
        <div className={newStyles.signInCarousel}>
          <SignInCarousel />
        </div>
        <div className={newStyles.signInSection}>
          <SignInForm
            formData={formData}
            error={error}
            submitLoader={submitLoader}
            socialLoginLoader={socialLoginLoader}
            socialLinkedinLoader={socialLinkedinLoader}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            togglePassword={this.togglePassword}
          />
        </div>
        {(socialLoginLoader || socialLinkedinLoader) && (
          <div className={newStyles.loaderBlk}>
            <CircularProgress />
          </div>
        )}
        <IntercomIntegration />
      </div>
    );
  }
}

import React, { useEffect } from 'react';
import { useGetLPAssignedToMeQuery } from '../../LearningPaths.queries';
import Loading from 'shared_ui_components/Loading';
import LearningPathTopCard from './LearningPathTopCard';
import styles from './AssignedLearningPathDetails.module.scss';
import LearningPathContents from './LearningPathContents';
import { useLearningPathsStore } from '../../LearningPaths.store';

const AssignedLearningPathDetails = ({ uuid }) => {
  const { data = {}, isLoading, isError } = useGetLPAssignedToMeQuery({ uuid });
  const { setSelectedRepLearningPathID } = useLearningPathsStore();

  useEffect(() => {
    if (isError) setSelectedRepLearningPathID('');
  }, [setSelectedRepLearningPathID, isError]);

  if (isLoading) return <Loading loading circular />;
  return (
    <div className={styles.container}>
      <LearningPathTopCard learningPath={data} />
      <LearningPathContents learningPath={data} />
    </div>
  );
};

export default AssignedLearningPathDetails;

import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { imageBasePath } from './../../../../constants/index';
import './SalesGoalDndCard.css';
import { FeatureFlag } from 'controllers/_exports';

const SalesGoalDndCard = ({
  goal,
  index,
  moveCard,
  reorderedIds,
  handleUpdateGoalsOrder,
  children,
}) => {
  const name = goal?.name;

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { name, index };
    },
    collect: (monitor) => ({
      dragId: monitor.isDragging() ? name : '',
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        hoverId: monitor.isOver() ? name : '',
        handlerId: monitor.getHandlerId(),
        hovered: monitor.isOver(),
      };
    },
    drop() {
      if (handleUpdateGoalsOrder) {
        handleUpdateGoalsOrder(reorderedIds);
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = useRef(null);
  drag(drop(ref));

  const [hover, setHover] = useState(false);

  return FeatureFlag.enabled('MOVE_GOAL_CARDS') ? (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-handler-id={handlerId}
      ref={ref}
      style={{ position: 'relative' }}
    >
      {hover && <img className="dragButton" alt=":::" src={imageBasePath + 'drag_to_do.svg'} />}
      {children}
    </div>
  ) : (
    children
  );
};

export default SalesGoalDndCard;

import React from 'react';
import { imageBasePath } from '../constants';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
export default function CustomLink(props) {
  return (
    <>
      {props.to ? (
        <Link to={props.to} className={props.className} onClick={props.onClick}>
          {props.linkimg ? (
            <img
              src={imageBasePath + props.linkimg}
              alt={props.alt ? props.alt : 'image'}
              className="defaultImg"
            />
          ) : null}
          {props.linkimghover ? (
            <img
              src={imageBasePath + props.linkimghover}
              alt={props.alt ? props.alt : 'image'}
              className="hoverImg"
            />
          ) : null}
          <span> {props.linktext} </span>
        </Link>
      ) : (
        <a
          // href="javascript:void(0)"
          className={props.className}
          onClick={props.onClick}
        >
          {props.linkimg ? (
            <img
              src={imageBasePath + props.linkimg}
              alt={props.alt ? props.alt : 'image'}
              className="defaultImg"
            />
          ) : null}
          {props.linkimghover ? (
            <img
              src={imageBasePath + props.linkimghover}
              alt={props.alt ? props.alt : 'image'}
              className="hoverImg"
            />
          ) : null}
          <span> {props.linktext} </span>
        </a>
      )}
    </>
  );
}

CustomLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  linkimgRight: PropTypes.string,
  linktext: PropTypes.string,
};

CustomLink.defaultProps = {
  to: '',
  className: '',
  alt: '',
  onClick: () => {},
  linkimgright: '',
  linktext: '',
};

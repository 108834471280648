import { Avatar } from '@material-ui/core';
import React from 'react';
import { components } from 'react-select';
import { InputSelect } from 'shared_ui_components';
import './StreamInputSelect.scss';

const getLetter = (label) => label.substring(0, 1)?.toUpperCase();

const StreamInputSelect = ({ containerClassName, className, withoutIcon, ...rest }) => {
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {!props.data.hideIcon && (
        <>
          {props.data.icon ? (
            <div
              style={{
                background: `${props.data.iconColor}1a`,
                borderRadius: '50%',
              }}
              className="select-option-image-container"
            >
              <img
                style={{
                  maxWidth: 32,
                  maxHeight: 32,
                }}
                src={props.data.icon}
                alt=""
              />
            </div>
          ) : (
            <Avatar style={{ width: 20, height: 20, fontSize: '0.75rem', background: '#87a0b4' }}>
              {getLetter(props.data.label)}
            </Avatar>
          )}
        </>
      )}
      {props.data.label}
    </Option>
  );

  return (
    <div className={`stream-input-select-container ${containerClassName}`}>
      <InputSelect
        {...rest}
        className={`stream-input-selector ${className}`}
        components={withoutIcon ? {} : { Option: IconOption }}
      />
    </div>
  );
};

export default StreamInputSelect;

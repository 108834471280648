import { useState, useEffect, useRef } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { getContentTypeIcons, TooltipCustom, getContentTypeURL } from '../../../utils';
import styles from '../../../assets/styles/onboarding.module.scss';
import { CustomButton } from '../../../shared_ui_components';

export default function IndexSidebar(props) {
  const {
    activeStep,
    isAdmin,
    page = 'onboarding',
    handleReorderStep,
    handleReorderTraining,
  } = props;
  const [steps, setSteps] = useState(props.steps);
  const [reorderedIds, setReorderedIds] = useState('');

  useEffect(() => {
    const _steps = props.steps.map((item, id) => {
      return { ...item };
    });
    setSteps(_steps);
  }, [props.steps]);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = steps[dragIndex];
    const _reordered = update(steps, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    const _reordered_ids = _reordered.map((item) => item.uuid).join(',');
    setReorderedIds(_reordered_ids);
    setSteps(_reordered);
  };

  return (
    <div className={styles.indexListWrapper}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <h5 className="heading6">Index</h5>
        </Grid>
        {props.handleAddCustomStep && isAdmin ? (
          <Grid item lg={7} md={7} sm={12} xs={12} className="textRight">
            <CustomButton
              onClick={props.handleAddCustomStep}
              className="secondaryBtn smallBtn"
              children={page == 'onboarding' ? `+ Add` : `+ Add content`}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
        <Grid item xs={12}>
          <div className="indexList">
            <DndProvider backend={HTML5Backend}>
              {steps.map((step, index) => (
                <IndexItemDnD
                  key={step.uuid}
                  step={step}
                  index={index}
                  handleChangeStep={props.handleChangeStep}
                  activeStep={activeStep}
                  isAdmin={isAdmin}
                  page={page}
                  moveCard={moveCard}
                  id={step.id}
                  reorderedIds={reorderedIds}
                  handleReorderStep={handleReorderStep}
                  handleReorderTraining={handleReorderTraining}
                />
              ))}
            </DndProvider>
          </div>
        </Grid>
      </Grid>
      {/* {
        (props.disabledSteps && props.disabledSteps.length && isAdmin) ? <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12} className={`${styles.titleRow} ${styles.disabledSteps}`}>
            <h5 className="heading6">Disabled</h5>
          </Grid>
          <Grid item xs={12} className={styles.titleRow}>
            <div className="indexList">
              <DndProvider backend={HTML5Backend}>
                {
                  props.disabledSteps ?.map((step, index) => <IndexItem key={step.uuid} item={step} handleChangeStep={props.handleChangeStep} activeStep={activeStep} isAdmin={isAdmin} />)
                  }
              </DndProvider>
            </div>
          </Grid>
        </Grid> : null
      } */}
    </div>
  );
}

function IndexItemDnD(props) {
  const {
    step,
    index,
    activeStep = {},
    isAdmin,
    page,
    moveCard,
    id,
    handleReorderStep,
    reorderedIds,
    handleReorderTraining,
  } = props;
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    drop(item, monitor) {
      const drop_result = monitor.getDropResult();
      if (reorderedIds) {
        if (handleReorderStep) {
          handleReorderStep(reorderedIds);
        }
        if (handleReorderTraining) {
          handleReorderTraining(reorderedIds);
        }
      }
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  dragPreview(drop(ref));

  return (
    <div
      key={step.uuid}
      className="listItem"
      ref={ref}
      style={{ ...opacity }}
      data-handler-id={handlerId}
    >
      <a
        onClick={() => props.handleChangeStep(step)}
        className={`${activeStep?.uuid === step?.uuid ? 'active' : ''} ${
          (step?.is_content_added && isAdmin) ||
          (step?.completed_at && !isAdmin) ||
          (step.status == 1 && isAdmin && page == 'training')
            ? 'completed'
            : ''
        } ${step?.is_custom && isAdmin ? 'customStep' : ``} ${
          step.status === 0 ? 'disabledStep' : ''
        }`}
      >
        <div className="typeBlk">
          {isAdmin ? <span className="dragWrapper" ref={drag} data-handler-id={handlerId} /> : null}
          <span className={'number supportText'}>{index + 1 ? index + 1 : '--'}</span>
          {getContentTypeIcons(
            step.content ? getContentTypeURL(step.content)?.id : step?.content_type
          )}
        </div>
        <span
          className={`title supportTextSemibold ${
            page === 'training' && !isAdmin && activeStep.is_new ? `marginRight` : ''
          }`}
        >
          {step.name}
        </span>
        {page === 'training' && !isAdmin && step.is_new ? (
          <span className={`newAlert supportText`}>NEW</span>
        ) : step.status === 0 && isAdmin ? (
          <TooltipCustom title={`Disabled`} arrow placement="top">
            <img
              src={`${imageBasePath}disabled_icon.svg`}
              className={`customStepIcon disabledIcon`}
              alt=""
            />
          </TooltipCustom>
        ) : step.is_custom && isAdmin ? (
          <img src={`${imageBasePath}custom_step_icon.svg`} className={`customStepIcon`} alt="" />
        ) : null}
      </a>
    </div>
  );
}

import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/home.module.scss';
import { Grid } from '@material-ui/core';
import { ProgressBar } from '../../../shared_ui_components';

export default function AdminModuleSetup(props) {
  const { moduleData = {}, type } = props;
  return (
    <div className={styles.moduleSetup}>
      <img src={moduleData.icon} />
      <h3 className="heading3">{moduleData.id}</h3>
      <p className={`paragraph ${styles.description}`}>{moduleData.description}</p>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={5}>
          <p className={styles.btnLink}>
            <Link
              to={`${moduleData.link}`}
              className={`${
                moduleData.progress && moduleData.progress?.completed
                  ? 'primaryBtn'
                  : 'secondaryBtn'
              } submitBtn`}
            >
              {moduleData.progress?.completed ? `Continue setup` : `Setup ${moduleData.id}`}
            </Link>
          </p>
        </Grid>
        {moduleData.progress && moduleData.progress?.completed ? (
          <Grid item xs={7}>
      
            <div className={styles.progressInfo + ' flexCentered'}>
              <div className={styles.progressValue}>
                <p className="supportTextSemibold">
                  {`${moduleData.progress?.completed} / ${moduleData.progress?.total}`}{' '}
                  <span>{type == 'training' ? `Modules` : `Steps`}</span>
                </p>
              </div>
              <div className={styles.progressBarBlk}>
                <ProgressBar
                  className={`${styles.progressBar} progressBar`}
                  value={(moduleData.progress?.completed / moduleData.progress?.total) * 100}
                  max="100"
                />
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

import React, { useMemo } from 'react';
import styles from './ReviewQualifiedRoleplay.module.css';
import { Avatar } from '@material-ui/core';
import Organization from 'infrastructure/Organization';
import User from 'infrastructure/User';
import DateTime from 'infrastructure/Datetime';

const ScenarioHeader = ({ roleplay }) => {
  const user = useMemo(
    () => (Organization.isRep() ? roleplay?.assigned_by_user : roleplay?.user),
    [roleplay]
  );

  return (
    <div className={styles.scenarioHeader}>
      <div className={styles.column}>
        <span>{Organization.isRep() ? 'Manager' : 'Assigned'}</span>
        <div className={styles.row}>
          <Avatar style={{ height: '2rem', width: '2rem' }} src={user?.profile_pic}>
            {User.getInitial(user)}
          </Avatar>
          <span className={styles.bold}>{User.getUserName(user)}</span>
        </div>
      </div>
      <div className={styles.column}>
        <span>Due date</span>
        <span className={styles.bold}>{DateTime.formatMMMddyyyy(roleplay?.deadline)}</span>
      </div>
    </div>
  );
};

export default ScenarioHeader;

import React, { useRef } from 'react';
import { TextArea } from 'shared_ui_components';

const GoalDescription = ({ value, onChange, custom, isActivity }) => {
  const textareaRef = useRef(null);

  const label = isActivity ? 'Activity description' : 'Goal description';

  if (custom)
    return (
      <div onClick={(e) => e.stopPropagation()} style={{ margin: '0.75rem 0' }}>
        <TextArea
          label={label}
          placeholder="Enter a description for this goal so your sales reps can understand it better"
          value={value}
          onChange={onChange}
          my_ref={textareaRef}
          onClick={() => {
            textareaRef.current.setSelectionRange(value?.length, value?.length);
          }}
        />
      </div>
    );

  return (
    <div style={{ margin: '0.75rem 0' }}>
      <p style={{ fontSize: '0.75rem', marginBottom: '6px' }}>{label}</p>
      <div
        style={{
          background: '#f0f0f0ff',
          borderRadius: '0.25rem',
          border: '1px solid #e7f0f5',
          padding: '10px',
        }}
      >
        <p style={{ color: '#4f4f4f' }}>{value}</p>
      </div>
    </div>
  );
};

export default GoalDescription;

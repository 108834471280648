import React from 'react';
import { TooltipCustom, CustomNumberFormatter } from '../utils';

export default function ProgressBar(props) {
  const { isMulti, value } = props;
  const { completed, inProgress, notStarted } = value || 0;

  // Value percentages
  const completed_percentage = completed + '%';
  const inProgress_percentage = inProgress + '%';

  const notStarted_percentage =
    notStarted === 0 || notStarted === 100 ? `100%` : `${100 - (inProgress + completed)}%`;

  // Show labels if div width is enough
  const completed_label = completed >= 10 ? `${Math.round(completed)}%` : ``;
  const inProgress_label = inProgress >= 10 ? `${Math.round(inProgress)}%` : ``;
  const notStarted_label = notStarted >= 10 ? `${Math.round(notStarted)}%` : ``;

  if (isMulti)
    return (
      <div className="progressBar multiple flexNotCentered">
        <div
          title={completed_percentage}
          className={`status1 status1Bg flexCentered ${props?.colorClass}`}
          style={{ width: completed_percentage, display: completed ? '' : 'none' }}
        >
          <span>{completed_label}</span>
        </div>

        <div
          title={inProgress_percentage}
          className="status2 status2Bg flexCentered"
          style={{ width: inProgress_percentage, display: inProgress ? '' : 'none' }}
        >
          <span>{inProgress_label}</span>
        </div>

        <div
          title={notStarted_percentage}
          className="status3 status3Bg flexCentered"
          style={{
            width: notStarted_percentage,
            display: notStarted ? '' : 'none',
          }}
        >
          <span>{notStarted === 0 || notStarted === 100 ? `0%` : notStarted_label}</span>
        </div>
      </div>
    );

  return (
    <div className="progressBar">
      <TooltipCustom
        title={
          <div className="supportText" style={{ color: `#fff` }}>
            {CustomNumberFormatter(Math.round(props.value)) + '%'}
          </div>
        }
        arrow
        placement="top"
      >
        <progress {...props}></progress>
      </TooltipCustom>
      {!props.hideLabel && 
      (Math.round(props.value) ? (
        <span className="supportText progressValue">
          {CustomNumberFormatter(Math.round(props.value))}%
        </span>
      ) : (
        <span className="supportText progressValue initialValue">0%</span>
      ))}
    </div>
  );
}

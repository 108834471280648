import { imageBasePath } from 'constants';
import DateTime from 'infrastructure/Datetime';
import React from 'react';
import styles from './bubbles.module.scss';

const DeadlineBubble = ({ deadline }) => {
  if (deadline)
    return (
      <div className={styles.deadlineBubble}>
        <img src={`${imageBasePath}Deadlineinactive.svg`} alt="icon" />
        Deadline on {DateTime.formatMMMddyyyy(deadline)}
      </div>
    );

  return null;
};

export default DeadlineBubble;

import React, { useEffect } from 'react';
import dragImage from '../../../assets/recorder/drag.svg';
import { setDragElement } from '../DragContainer/Drag.logic';

const DragButton = ({ recorderBarId, ...rest }) => {
  useEffect(() => {
    setDragElement({
      dragElement: document.getElementById('drag-recording-button'),
      element: document.getElementById(recorderBarId),
      elementHeight: document.getElementById(recorderBarId)?.offsetHeight || 0,
      elementWidth: document.getElementById(recorderBarId)?.offsetWidth || 0,
    });
  }, [recorderBarId]);

  return (
    <img
      id="drag-recording-button"
      style={{ cursor: 'grab', marginRight: '0.25rem' }}
      src={dragImage}
      alt="drag"
      {...rest}
    />
  );
};

export default DragButton;

import React from 'react';
import { List, ListItem } from '@material-ui/core';
import CustomRadioButton from 'shared_ui_components/CustomRadioButton';
import styles from '../ScheduleTraining.module.scss';
const Repetition = ({
  setRepetition = () => null,
  repetition = 'always',
  setFormData = () => null,
  formData,
}) => {
  const formatDate = (date) => {
    const dateString = new Date(date);

    let month = dateString.getUTCMonth() + 1;
    if (month < 10) month = `0${month}`;
    let day = dateString.getUTCDate();
    if (day < 10) day = `0${day}`;
    let year = dateString.getUTCFullYear();

    const newDate = year + '-' + month + '-' + day;
    return newDate;
  };
  return (
    <div>
      <p className="supportText">Repetition</p>
      <List className={styles.radioBtnList + ' listInline'}>
        <ListItem className="listInlineItem">
          <CustomRadioButton
            label="Always"
            checked={repetition === 'always' ? true : false}
            onChange={() => {
              delete formData.until_date;
              setRepetition('always');
              setFormData({ ...formData, number_of_retakes: -1, until_date: null });
            }}
          />
        </ListItem>
        <ListItem className="listInlineItem">
          <CustomRadioButton
            label="No. of Times"
            checked={repetition === 'times' ? true : false}
            onChange={() => {
              delete formData.until_date;
              setRepetition('times');
              setFormData({ ...formData, number_of_retakes: 1, until_date : null});
            }}
          />
        </ListItem>
        <ListItem className="listInlineItem">
          <CustomRadioButton
            label="Until"
            checked={repetition === 'until' ? true : false}
            onChange={() => {
              setRepetition('until');
              setFormData({ ...formData, number_of_retakes: -1, until_date: formatDate(new Date()) + ' 00:00:00' });
            }}
          />
        </ListItem>
      </List>
    </div>
  );
};

export default Repetition;

import Training from './containers/Training';
import TrainingAssesment from './containers/TrainingAssesment';
import ModuleDetail from './containers/ModuleDetail';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const trainingRoute = [
  {
    path: '/training',
    component: pageLayoutHoc(Training),
    key: 'training',
  },
  {
    path: '/training/overview', // dashboard
    component: pageLayoutHoc(Training),
    key: 'overview',
  },
  {
    path: '/training/detail', // module detail
    component: pageLayoutHoc(Training),
    key: 'detail',
  },
  {
    path: '/training/preview',
    component: pageLayoutHoc(Training),
    key: 'preview',
  },
  {
    path: '/training/:id',
    component: pageLayoutHoc(ModuleDetail),
    key: 'preview',
  },
  // {
  //   path: '/trainingassesment',
  //   component : pageLayoutHoc(TrainingAssesment),
  //   key: 'trainingassesment'
  // },
];

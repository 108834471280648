import { useState, useEffect, useCallback } from 'react';
import { Grid, Menu, Dialog, DialogActions, DialogContent, MenuItem } from '@material-ui/core';
import { InputText, CustomButton, TextArea, CustomSwitch } from '../../../shared_ui_components';
import { imageBasePath, goalFrequencies, goalUnits } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { createGoalsApi } from '../apiServices';
import { toastFlashMessage, NumberFormatCustom } from '../../../utils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SalesGoalDndCard from './SalesGoalDndCard/SalesGoalDndCard';
import update from 'immutability-helper';
import GoalDescription from './GoalDescription';

export default function SalesGoalsDialog(props) {
  const { open, goalsData = {}, orgGoalsFrequency } = props;
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [customGoals, setCustomGoals] = useState([]);
  const [deletedGoalIds, setDeletedGoalIds] = useState([]);
  const [goals, setGoals] = useState([]);

  useEffect(() => {
    if (open) {
      setFormData({
        frequency: goalsData?.frequency ? goalsData.frequency : 1,
        description: goalsData?.description ? goalsData?.description : '',
        goals: goalsData?.goals ? goalsData.goals : [],
      });
      setError({});
      setLoader(false);
      setCustomGoals([]);
      setDeletedGoalIds([]);
    }
  }, [open]);

  const handleChangeGoal = (goalId, key, value) => {
    let updatedGoal = Object.assign(
      {},
      formData.goals.find((goal) => goal.uuid === goalId)
    );
    if (key == 'target') {
      updatedGoal['target'] = value;
    } else {
      updatedGoal[key] = value;
    }
    setFormData((prevData) => ({
      ...prevData,
      goals: prevData.goals.map((goal) => (goal.uuid === goalId ? updatedGoal : goal)),
    }));
    setError({
      ...error,
      [goalId]: '',
    });
  };
  const handleChange = (key, val) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: val,
    }));
    setError({
      ...error,
      [key]: key == 'frequency' ? validateFrequency(val) : '',
    });
  };

  const handleClose = () => {
    props.handleClose();
  };
  const validateFrequency = (val) => {
    if (val > orgGoalsFrequency) {
      return `Sales activity frequency should be less than sales goals frequency`;
    } else {
      return ``;
    }
  };
  const handleAddCustomGoalClick = () => {
    let uuid = new Date();
    const customGoal = {
      is_custom: true,
      name: '',
      status: 1,
      target: '',
      frequency: formData.frequency,
      uuid: uuid.getTime(),
      unit: 1,
    };
    setCustomGoals([...customGoals, customGoal]);
  };
  const handleAddCustomGoal = (goal) => {
    setFormData((prevData) => ({
      ...prevData,
      goals: [...prevData.goals, goal],
    }));
    setCustomGoals(customGoals.filter((item) => item.uuid != goal.uuid));
  };
  const handleUpdateCustomGoal = (goal) => {
    setFormData((prevData) => ({
      ...prevData,
      goals: prevData.goals.map((item) => (item.uuid == goal.uuid ? goal : item)),
    }));
  };
  const handleSubmit = () => {
    setLoader(true);
    let validateNewInput = {
      frequency: formData.frequency
        ? validateFrequency(formData.frequency)
        : 'Please select frequency',
    };
    formData.goals.forEach((item) => {
      // validateNewInput[item.uuid] = (item.target || !item.status) ? '' : 'Please enter target'
      validateNewInput[item.uuid] =
        item.target === ''
          ? 'Please enter targets'
          : item.status == 0 || item.target == 0
          ? ''
          : item.target
          ? item.target < 0
            ? 'Invalid target'
            : ''
          : '';
    });
    if (Object.keys(validateNewInput).every((key) => validateNewInput[key] === '')) {
      formData.goals.forEach((goal) => {
        if (!goal.created_by && goal.is_custom) {
          delete goal[`uuid`];
        } else if (goal.is_custom) {
          delete goal['is_edit'];
        }
      });
      createGoalsApi(goalsData.id, { ...formData, removed_goals: deletedGoalIds }).then(
        (response) => {
          setLoader(false);
          if (response.statusCode == 200) {
            props.fetchTrackingGoals();
            toastFlashMessage(response.message, 'success');
            props.handleClose();
          } else {
            toastFlashMessage(response.message, 'error');
          }
        }
      );
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };
  const handleDeleteCustomGoal = (id) => {
    let index = formData.goals.findIndex((item) => item.uuid == id);
    if (index != -1) {
      setFormData((prevData) => ({
        ...prevData,
        goals: prevData.goals.filter((goal) => goal.uuid != id),
      }));
      setError({
        ...error,
        [id]: '',
      });
      setDeletedGoalIds((prevData) => [...prevData, id]);
    }
  };
  const handleCloseCustomGoal = (id) => {
    let index = formData.goals.findIndex((item) => item.uuid == id);
    if (index !== -1) {
      // present in formData means already added
      setFormData((prevData) => ({
        ...prevData,
        goals: prevData.goals.map((goal) => (goal.uuid == id ? { ...goal, is_edit: false } : goal)),
      }));
    } else {
      setCustomGoals(customGoals.filter((item) => item.uuid != id));
    }
  };
  const handleEditCustomGoal = (id) => {
    let index = formData.goals.findIndex((item) => item.uuid == id);
    if (index !== -1) {
      setFormData((prevData) => ({
        ...prevData,
        goals: prevData.goals.map((goal) => (goal.uuid == id ? { ...goal, is_edit: true } : goal)),
      }));
    }
  };

  const [reorderedIds, setReorderedIds] = useState('');

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const _reordered = update(
        update(formData.goals, {
          $splice: [[dragIndex, 1, formData.goals[hoverIndex]]],
        }),
        { $splice: [[hoverIndex, 1, formData.goals[dragIndex]]] }
      );

      const _reordered_ids = _reordered.map((item) => item.name).join(',');

      setReorderedIds(_reordered_ids);
      setFormData({ ...formData, goals: _reordered });
    },
    [formData.goals]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.goalsDialog} ${styles.salesGoalssDialog}`}
      >
        <DialogContent>
          <div className={styles.dialogBody}>
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className={styles.modalCloseIcon}
              onClick={handleClose}
            />
            <Grid container direction="row" spacing={2} className={styles.dialogRow}>
              <Grid item xs={5}>
                <div
                  className={`${styles.organizationGoalsLeftWrapper} ${styles.salesGoalssWrapper}`}
                >
                  <img
                    className={styles.featuredImg}
                    src={imageBasePath + 'sales_goals.svg'}
                    alt="Sales Activity"
                  />
                  <h2 className="heading4">Sales Activity</h2>
                  <p className={styles.customSupportText + ' supportText '}>Frequency</p>

                  <ul className={styles.goalsDurationList}>
                    {goalFrequencies.map((item) => (
                      <li key={item.id}>
                        <a
                          className={`${styles.goalsDurationBtn} linkBtn ${
                            formData.frequency == item.id ? styles.goalsActiveBtn : ''
                          } ${item.id > orgGoalsFrequency ? styles.disabledBtn : ''}`}
                          onClick={() => handleChange('frequency', item.id)}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {error.frequency ? (
                    <div className={styles.goalsLeftInfoBox + ' paragraph '}>
                      <img className={styles.infoIcon} src={imageBasePath + 'info_icon.svg'} />
                      <p>
                        {' '}
                        Make sure to set your sales goals frequency greater than the frequency of
                        sales activity{' '}
                      </p>
                    </div>
                  ) : null}
                  <TextArea
                    id="description"
                    label={`Description`}
                    placeholder="Sales Activity are constantly tracked, making sure your company is on track. Note that the Sales activity are applicable for specific sales rep"
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    className={styles.goalsDescriptionInput}
                  />
                  <img
                    className={styles.dialogPattern}
                    src={imageBasePath + 'tracking_pattern.svg'}
                    alt="Image"
                  />
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={styles.organizationGoalsRightWrapper}>
                  <div className={styles.goalsInfoBox + ' paragraph '}>
                    <img className={styles.infoIcon} src={imageBasePath + 'info_icon.svg'} />
                    <p>
                      {' '}
                      Targets for{' '}
                      <span className={styles.infoBoxHighlight}>each sales representative</span> are
                      set on{' '}
                      <span
                        className={styles.infoBoxHighlight}
                        style={{
                          background: 'rgba(106, 130, 148, 0.29)',
                          padding: '0px 6px 1px',
                          borderRadius: '4px',
                        }}
                      >
                        {' '}
                        {goalFrequencies?.find((item) => item.id === formData.frequency)?.label}
                      </span>{' '}
                      basis
                    </p>
                  </div>
                  <div
                    className={styles.goalsInputWrapperBox + ' ' + styles.salesGoalsInputWrapperBox}
                  >
                    <DndProvider backend={HTML5Backend}>
                      {formData.goals?.map((goal, index) => (
                        <SalesGoalDndCard
                          reorderedIds={reorderedIds}
                          moveCard={moveCard}
                          goal={goal}
                          index={index}
                        >
                          {goal.is_edit ? (
                            <CustomGoal
                              key={goal.uuid}
                              goal={goal}
                              handleCloseCustomGoal={handleCloseCustomGoal}
                              handleUpdateCustomGoal={handleUpdateCustomGoal}
                              type="edit"
                            />
                          ) : (
                            <SalesGoalItem
                              key={goal.uuid}
                              goal={goal}
                              handleChangeGoal={handleChangeGoal}
                              error={error}
                              index={index}
                              handleEditCustomGoal={handleEditCustomGoal}
                              handleDeleteCustomGoal={handleDeleteCustomGoal}
                              goalsData={goalsData}
                            />
                          )}
                        </SalesGoalDndCard>
                      ))}
                      {customGoals.map((goal) => (
                        <CustomGoal
                          key={goal.uuid}
                          goal={goal}
                          handleCloseCustomGoal={handleCloseCustomGoal}
                          handleAddCustomGoal={handleAddCustomGoal}
                        />
                      ))}
                    </DndProvider>
                    <a
                      className={styles.addGoalBtn + ' linkBtn '}
                      onClick={handleAddCustomGoalClick}
                    >
                      + Add new sales activity
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${
              loader || Object.keys(error).some((k) => error[k] != '') ? 'disabledBtn' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loader ? 'Save' : 'Save'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function CustomGoal(props) {
  const { goal, type = 'add' } = props;
  const [formData, setFormData] = useState(goal);
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const handleSubmit = () => {
    setLoader(true);
    let validateNewInput = {
      name: formData.name && formData.name.trim() ? '' : 'Please enter your goal name',
      unit: formData.unit ? '' : 'Please select unit',
      target: formData.target
        ? formData.target < 0
          ? 'Invalid goal target'
          : ''
        : 'Please enter your goal target',
    };
    if (Object.keys(validateNewInput).every((key) => validateNewInput[key] === '')) {
      if (type == 'edit') {
        props.handleUpdateCustomGoal({
          ...formData,
          name: formData.name.trim(),
          is_edit: false,
        });
      } else {
        props.handleAddCustomGoal({
          ...formData,
          name: formData.name.trim(),
        });
      }
      setLoader(false);
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    setError({
      ...error,
      [key]: '',
    });
  };

  return (
    <div className={styles.goalsInputWrapper + ' ' + styles.goalsAddWrapper + ' customNewGoal'}>
      <img
        src={imageBasePath + 'close.svg'}
        className={styles.addGoalCloseBtn}
        onClick={() => props.handleCloseCustomGoal(goal.uuid)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputText
            id="goalTarget"
            label={`Goal Name`}
            placeholder="eg, Revenue"
            value={formData.name}
            className={error.name ? `errorGroup` : ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          {error.name ? <h6 className={`errorMsg`}>{error.name}</h6> : null}
        </Grid>
        <Grid item xs={6}>
          <label className={styles.unitListingLabel + ' supportText'}>Unit</label>
          <ul className={`listInline ${styles.unitListing}`}>
            <li
              className={`listInlineItem supportTextSemibold ${
                formData.unit == 1 ? `${styles.activeUnit} activeUnit unit` : 'unit'
              }`}
              onClick={(e) => handleChange('unit', 1)}
            >
              # Number
            </li>
            <li
              className={`listInlineItem supportTextSemibold ${
                formData.unit == 2 ? `${styles.activeUnit} activeUnit unit` : 'unit'
              }`}
              onClick={(e) => handleChange('unit', 2)}
            >
              $ US Dollars
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <InputText
            id="goalTarget"
            label={`Set a target for the goal`}
            placeholder="eg, 150"
            value={formData.target}
            onChange={(e) => handleChange('target', e.target.value)}
            className={
              formData.unit == 2
                ? `${styles.customGoalInput} ${error.target ? `errorGroup` : ''}`
                : `${error.target ? `errorGroup` : ''}`
            }
            InputProps={
              formData.unit === 2
                ? {
                    inputComponent: NumberFormatCustom,
                  }
                : { inputmode: 'numeric', pattern: '[0-9]*' }
            }
          />
          {error.target ? <h6 className={`errorMsg`}>{error.target}</h6> : null}
        </Grid>
        <Grid item xs={12} className={styles.addBtnRight}>
          <CustomButton
            onClick={handleSubmit}
            className={`primaryBtn ${
              loader || Object.keys(error).some((k) => error[k] != '') ? `disabledBtn` : ``
            }`}
          >
            {type == 'edit' || goal?.is_editing ? `Save` : `Add`}
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
}

export function SalesGoalItem(props) {
  const {
    goal = {},
    handleChangeGoal,
    error = {},
    index,
    handleEditCustomGoal,
    handleDeleteCustomGoal,
    goalsData,
  } = props;

  return (
    <div key={index} className={styles.goalsInputWrapper}>
      {goal.is_custom ? <div className={styles.customLabel + ' supportText'}>CUSTOM</div> : null}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className={styles.goalsTextBlk}>
            {
              <img
                src={`${imageBasePath}${goalUnits.find((unit) => unit.id == goal.unit)?.icon}`}
                className={styles.goalsImgIcon}
              />
            }
            <div className={styles.inputTextWrapper}>
              <p className={styles.goalsBlkHeading + ' heading5 '}> {goal.name}</p>
              <CustomSwitch
                checked={goal.status == 1 ? true : false}
                onChange={() => handleChangeGoal(goal.uuid, 'status', goal.status == 1 ? 0 : 1)}
              />
              <span className="supportText">{goal.status === 1 ? `Enabled` : `Disabled`}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.goalsInputBlk}>
            <InputText
              id="goalTarget"
              label={`Set a target for the goal`}
              placeholder="eg, 150"
              value={goalsData.is_content_added || goal.target ? goal.target : ''}
              // onChange={(e) => handleChangeGoal(goal.uuid, 'target', e.target.value)}
              onChange={(e) =>
                Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                  ? handleChangeGoal(goal.uuid, 'target', parseInt(e.target.value))
                  : e.target.value === '' || e.target.value === '0'
                  ? handleChangeGoal(goal.uuid, 'target', e.target.value)
                  : console.log(e.target.value)
              }
              className={
                goal.unit === 2
                  ? `${styles.customGoalInput} ${error[goal?.uuid] ? `errorGroup` : ''}`
                  : `${error[goal?.uuid] ? `errorGroup` : ''}`
              }
              disabled={goal.status == 0 ? true : false}
              InputProps={
                goal.unit === 2
                  ? {
                      inputComponent: NumberFormatCustom,
                    }
                  : { inputmode: 'numeric', pattern: '[0-9]*' }
              }
            />
            {goal.unit === 2 ? (
              <img src={imageBasePath + 'dollar.svg'} className={styles.inputIcon} />
            ) : null}
            {error[goal?.uuid] ? (
              <h6 className={`${styles.customErrorMsg} errorMsg`}>{error[goal?.uuid]}</h6>
            ) : null}
          </div>
        </Grid>
      </Grid>

      <GoalDescription
        isActivity
        custom={goal.is_custom}
        value={goal.description}
        onChange={(e) => handleChangeGoal(goal.uuid, 'description', e.target.value)}
      />

      <GoalMenu
        handleDelete={handleDeleteCustomGoal}
        handleEdit={handleEditCustomGoal}
        goal={goal}
      />
    </div>
  );
}

export const GoalMenu = ({ goal = {}, handleEdit = () => null, handleDelete = () => null }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!goal.is_custom) return null;
  return (
    <span className={styles.more}>
      <a
        aria-controls={'simple-menu-' + goal?.uuid}
        className={'more'}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <img
          className="more"
          src={imageBasePath + 'more.svg'}
          alt="More"
          style={{ verticalAlign: 'middle' }}
        />
      </a>
      <Menu
        id={'simple-menu-' + goal?.uuid}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) ? true : false}
        onClose={(e) => {
          setAnchorEl(null);
          e.preventDefault();
          e.stopPropagation();
        }}
        className="moreMenu moreMenuHoirzontal"
      >
        <MenuItem onClick={() => handleEdit(goal.uuid)}>
          <span className="supportText">Edit</span>
        </MenuItem>
        <MenuItem onClick={() => handleDelete(goal.uuid)}>
          <span className="supportText">Remove</span>
        </MenuItem>
      </Menu>
    </span>
  );
};

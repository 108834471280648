import { imageBasePath } from 'constants';
import {
  useGetProvidersQuery,
  useRemoveIntegrationMutation,
} from 'features/providers/Providers.queries';
import Organization from 'infrastructure/Organization';
import React, { useMemo, useState } from 'react';
import { ConfirmationDialog } from 'shared_elements';
import { toastFlashMessage } from 'utils';
import { useHubspotStore } from '../Hubspot.store';
import { useQueryClient } from '@tanstack/react-query';
import { hubspotCacheKeys } from '../Hubspot.queries';
import { FeatureFlag } from 'controllers/_exports';

const HubspotRemoveIntegration = () => {
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const onClick = () => setShowModal(true);
  const onClose = () => setShowModal(false);

  // Integration id
  const { data } = useGetProvidersQuery();
  const id = useMemo(() => data?.providers?.find((p) => p?.code === 'hubspot')?.id || 0, [data]);

  const show = useMemo(() => {
    return FeatureFlag.enabled('REMOVE_HUBSPOT') && id && Organization.isOwner();
  }, [id]);

  // Remove integration
  const { mutate, isLoading } = useRemoveIntegrationMutation();
  const onRemove = () => {
    mutate({ id }, { onSuccess });
  };

  const closeSettings = useHubspotStore((state) => state?.closeSettings);

  const onSuccess = () => {
    onClose();
    closeSettings();
    toastFlashMessage('HubSpot integration removed', 'success');
    queryClient.invalidateQueries([hubspotCacheKeys.SETTINGS]);
  };

  if (!show) return null;
  return (
    <>
      <div
        style={{ fontWeight: 600, color: '#901010', cursor: 'pointer', width: 'fit-content' }}
        onClick={onClick}
      >
        Remove integration
      </div>
      <ConfirmationDialog
        isDelete
        open={showModal}
        title="Remove hubspot integration?"
        handleClose={onClose}
        rejectText="Cancel"
        acceptText="Remove"
        image={imageBasePath + 'hubspot_logo.svg'}
        handleSubmit={onRemove}
        description="You'll lose the ability to track sales rep activity on HubSpot by removing integration. But don't worry your existing check-in data will be preserved"
        loader={isLoading}
      />
    </>
  );
};

export default HubspotRemoveIntegration;

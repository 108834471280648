import React, { useState, useEffect } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';

const DropdownListScenarios = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const scenarioSelected = useRoleplayStore((state) => state?.scenarioSelected);
  const setScenarioSelected = useRoleplayStore((state) => state?.setScenarioSelected);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(
      roleplayView?.scenarios.map((scenario) => {
        return {
          ...scenario,
          label: scenario?.name,
          name: scenario?.name,
          value: scenario?.uuid,
          description: scenario?.description,
          example_video: scenario?.example_video,
        };
      })
    );
  }, [roleplayView]);

  const handleChange = (value) => {
    setScenarioSelected(value)
  };

  return (
    <InputSelect
      placeholder="Select scenario"
      onChange={(value) => handleChange(value)}
      options={options}
      value={scenarioSelected}
      closeMenuOnSelect={true}
      noOptionsMessage={() => 'No matches found'}
    />
  );
};

export default DropdownListScenarios;

import React from 'react';
import { imageBasePath } from 'constants';
import { CustomTooltipWhite } from 'shared_ui_components';

const RatingCriteriaTooltip = () => {
  return (
    <span>
      <span style={{ marginRight: '0.5rem' }}>Rating criteria</span>
      <CustomTooltipWhite
        tooltipIcon="info_icon.svg"
        title={
          <div>
            <p>
              <strong>5 - Expert: </strong>
              Develops new ways of applying this competence measured on the world stage.
            </p>
            <p>
              <strong>4 - Proficient: </strong>
              Encourages or supervises others in this competency.
            </p>
            <p>
              <strong>3 - Competent: </strong>
              Demonstrates this competency independent of supervision or encouragement.
            </p>
            <p>
              <strong>2 - Advanced Beginner: </strong>
              Demonstrates this competency under supervision or with encouragement.
            </p>
            <p>
              <strong>1 - Novice: </strong>
              Understands the competency and its importance.
            </p>
          </div>
        }
      >
        <img width={12} src={imageBasePath + 'info_icon.svg'} alt="" />
      </CustomTooltipWhite>
    </span>
  );
};

export default RatingCriteriaTooltip;

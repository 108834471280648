import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/onboarding.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { publishOnboardingApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { FeedbackDialog } from '.';
import { ConfirmationDialog, InviteUserDialog } from '../../../shared_elements';

export default function SetupComplete(props) {
  const [loader, setLoader] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openInvitePop, setOpenInvitePop] = useState(false);

  useEffect(() => {
    setOpenFeedback(false);
    setLoader(false);
    setOpenConfirm(false);
  }, []);

  const handlePublish = () => {
    setLoader(true);
    publishOnboardingApi().then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setIsPublished(true);
        toastFlashMessage(response.message, 'success');
        setOpenFeedback(true);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  return (
    <>
      <section className={styles.setupCompleteSection}>
        <div
          className={styles.setUpwrapper + ' backgroundProp flexCentered'}
          style={{ backgroundImage: `url(${imageBasePath + 'setup_complete_bg.png'})` }}
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={styles.titleWrapper}>
                <div className={styles.imgBlk}>
                  <img src={imageBasePath + 'setup_complete_featured.svg'} alt="Company" />
                </div>
                <div className={styles.contentBlk}>
                  <h1 className="heading2">Congratulations! </h1>
                  <p className="heading3">
                    Your <span>onboarding setup</span> is complete!
                  </p>
                </div>
              </div>
            </Grid>
            {isPublished ? null : (
              <Grid item lg={6} md={6} sm={12} xs={12} className="textRight">
                {/* <CustomButton className={`${styles.publishBtn} primaryBtn ${loader ? 'disabledBtn': ''}`} onClick={handlePublish}>{loader ? 'Publishing...' : `Publish Now`}</CustomButton> */}
                <CustomButton
                  className={`${styles.publishBtn} primaryBtn ${loader ? 'disabledBtn' : ''}`}
                  onClick={() => setOpenConfirm(true)}
                >
                  {loader ? 'Publishing...' : `Publish Now`}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </div>
      </section>
      <FeedbackDialog
        type="publish"
        title={`Wow, the Onboarding library has been published!`}
        subtitle={`You have successfully published your Onboarding library`}
        open={openFeedback}
        handleSubmit={() => {
          setOpenFeedback(false);
          setOpenInvitePop(true);
        }}
        handleContinue={() => {
          setOpenFeedback(false);
          props.handlePublished();
        }}
        handleClose={() => {
          props.handlePublished();
          setOpenFeedback(false);
        }}
      />
      <ConfirmationDialog
        open={openConfirm}
        title={<>Are you sure you want to publish your Onboarding library?</>}
        description="This action can't be undone, but you still can add and update your onboarding steps"
        acceptText="Yes, Publish"
        rejectText="No, Cancel"
        image={imageBasePath + 'published_logo.png'}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          handlePublish();
          setOpenConfirm(false);
        }}
        loader={loader}
      />
      <InviteUserDialog
        open={openInvitePop}
        handleClose={() => {
          props.handlePublished();
          setOpenInvitePop(false);
        }}
      />
    </>
  );
}

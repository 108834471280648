import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { regExpression } from '../../../constants';
// import {} from '../../../shared_elements';
import { AboutOrganization, OrganizationExist, ChooseOrganization } from '../components';
import { fieldValidation } from '../../../utils/formValidation';
import { organisationCreateApi, organisationSelectApi, contactAdminApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        size: '',
        category: '',
        designation: '',
        logo: '',
        website: '',
      },
      error: {},
      errorCode: {
        name: {
          0: '',
          1: 'Please add your company name here',
        },
        nameObj: {
          requiredFlag: true,
        },
        size: {
          0: '',
          1: 'Please select company size',
        },
        sizeObj: {
          requiredFlag: true,
        },
        category: {
          0: '',
          1: 'Please select company category',
        },
        categoryObj: {
          requiredFlag: true,
        },
        designation: {
          0: '',
          1: 'Please enter your role in the company ',
        },
        designationObj: {
          requiredFlag: true,
        },
        website: {
          0: '',
          1: 'Please enter your website link',
          4: 'Please enter a valid website link',
        },
        websiteObj: {
          requiredFlag: true,
          regexPattern: regExpression.simpleUrl,
        },
      },
      submitLoader: false,
      organizationCreated: false,
      available_organisation: [],
      user: [],
    };
    this.onChange = this.onChange.bind(this);
  }

  handleChange = (key, val) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: '',
      },
    }));
  };

  handleSubmit = () => {
    let { formData, errorCode } = this.state;
    const validateNewInput = {
      name: errorCode['name'][
        fieldValidation({
          ...errorCode['nameObj'],
          fieldval: formData.name,
        })
      ],
      size: errorCode['size'][
        fieldValidation({
          ...errorCode['sizeObj'],
          fieldval: formData.size,
        })
      ],
      category:
        errorCode['category'][
          fieldValidation({
            ...errorCode['categoryObj'],
            fieldval: formData.category,
          })
        ],
      designation:
        errorCode['designation'][
          fieldValidation({
            ...errorCode['designationObj'],
            fieldval: formData.designation,
          })
        ],
      website:
        errorCode['website'][
          fieldValidation({
            ...errorCode['websiteObj'],
            fieldval: formData.website,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k]?.trim() == '')) {
      this.setState({
        submitLoader: true,
      });
      formData = {
        ...formData,
        category: formData?.category?.value,
        size: formData?.size?.value,
        // logo: formData?.logo
      };
      let uploadedImage = formData.logo ? formData.logo[0] : '';

      const data = new FormData();
      // if (uploadedImage) {
      //   data.append('logo', uploadedImage, uploadedImage.file);
      // }
      if (formData.logo) {
        for (let file of formData.logoData) {
          data.append('logo', file, file.name);
        }
      }
      data.append('name', formData.name);
      data.append('category', formData.category);
      data.append('size', formData.size);
      data.append('designation', formData.designation);
      data.append('website', formData.website);
      organisationCreateApi(data).then((response) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          this.setState((prevState) => ({
            ...prevState,
            loader: true,
            organizationCreated: true,
            submitLoader: false,
          }));
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo = {
            ...userInfo,
            access: response.data.access,
            organisations: [response.data.organisation],
            selectedOrg: response.data.organisation,
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.props.history.push('/');
        } else if (response.statusCode === 6001) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo = {
            ...userInfo,
            currentOrg: response.data.organisation,
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.props.history.push('/verifydomain');
        } else if (response.statusCode === 6002) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo = {
            ...userInfo,
            currentOrg: response.data.organisation,
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.props.history.push('/verificationrejected');
        } else {
          toastFlashMessage(response.message, 'error');
          this.setState((prevState) => ({
            ...prevState,
            loader: false,
            organizationCreated: false,
            submitLoader: false,
          }));
        }
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  onChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          logo: URL.createObjectURL(event.target.files[0]),
          logoData: event.target.files,
        },
      }));
    }
  };

  resetFile = (event) => {
    event.preventDefault();
    this.setState({
      formData: {
        ...this.state.formData,
        logo: null,
      },
    });
  };

  handleContactAdmin = (org) => {
    this.setState({
      submitLoader: true,
    });
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    contactAdminApi({ organisation_id: userInfo.available_organisation.slug }).then((response) => {
      if (response.statusCode === 200) {
        toastFlashMessage(response.message, 'success');
        if (response.data?.can_proceed) {
          let responseUpdated = {
            ...response.data,
            selectedOrg: response.data.organisations[0],
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          this.props.history.push('/');
        } else {
          localStorage.clear();
          this.props.history.push('/signin');
        }
      } else if (response.statusCode === 5002) {
        localStorage.clear();
        this.props.history.push('/signin');
      } else {
        toastFlashMessage(response.message, 'error');
      }
      this.setState((prevState) => ({
        ...prevState,
        loader: false,
        submitLoader: false,
      }));
    });
  };

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo) {
      this.props.history.push('/');
    }
    if (
      userInfo &&
      !userInfo?.can_create_organisation &&
      !userInfo.organisations.length &&
      userInfo.available_organisation
    ) {
      this.props.history.push('/requestaccess');
    }
    if (userInfo && userInfo.access && userInfo.selectedOrg && userInfo.organisations?.length) {
      this.props.history.push('/');
    }
  }

  render() {
    const { formData, error, submitLoader, organizationCreated, available_organisation } =
      this.state;

    return (
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {this.props.location.pathname == '/requestaccess' ? (
                  <OrganizationExist
                    handleContactAdmin={this.handleContactAdmin}
                    submitLoader={submitLoader}
                  />
                ) : (
                  <AboutOrganization
                    formData={formData}
                    error={error}
                    submitLoader={submitLoader}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    onChange={this.onChange}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react';
import styles from './NewSettings.module.scss';
import { goalFrequencies } from 'constants';
import { useTrackingStore } from 'features/tracking/tracking.store';

const FrequencyOptions = ({ onFrequencyChange, frequency }) => {
  const orgFrequency = useTrackingStore((state) => state.organizationGoalsData?.frequency);

  const [activeFrequency, setActiveFrequency] = useState('');

  useEffect(() => {
    setActiveFrequency(frequency);
  }, [frequency]);

  const handleChange = (value) => {
    if (value > orgFrequency) return;
    setActiveFrequency(value);
    onFrequencyChange(value);
  };

  return (
    <>
      <p style={{ fontSize: '0.75rem', marginBottom: '6px' }}>Frequency</p>
      <div className={styles.frequencyButtons}>
        {goalFrequencies.map(({ label, id: value }) => (
          <button
            className={`${styles.btnFrequency} 
            ${activeFrequency === value ? styles.active : ''} 
            ${value > orgFrequency ? styles.disabled : ''}
            `}
            onClick={() => handleChange(value)}
          >
            {label}
          </button>
        ))}
      </div>
    </>
  );
};

export default FrequencyOptions;

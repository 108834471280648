import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SeeLearningPaths.module.scss';
import { imageBasePath } from 'constants';
import { Menu, MenuItem } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { ConfirmationDialog } from 'shared_elements';
import { useUpdateStatusLearningPath } from '../../LearningPaths.queries';
import { PATH_STATUS, usePathValidations } from '../../LearningPaths.utils';
import DeadlineBubble from '../common/DeadlineChip';

const Card = ({ learningPath = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const { mutate: changeStatus, isLoading } = useUpdateStatusLearningPath();

  const handleClick = (event, key) => {
    // if (readOnly) return;
    setAnchorEl(event.currentTarget);
  };

  const history = useHistory();
  const onCardClick = () => history.push(`/learning-path/${learningPath?.uuid}`);

  const { canPerformActions, canDelete, canPublish } = usePathValidations({
    learningPath,
  });

  const handleClickDelete = () => setShowModal(true);
  const onSuccess = () => setShowModal(false);

  const handleToPublished = () => {
    changeStatus(
      { status: PATH_STATUS.PUBLISH, learningPathUuid: learningPath?.uuid },
      { onSuccess }
    );
  };
  const deleteLearningPath = () => {
    changeStatus(
      { status: PATH_STATUS.DELETE, learningPathUuid: learningPath?.uuid },
      { onSuccess }
    );
  };

  const contentsLabel = useMemo(() => {
    if (learningPath?.total_contents === 1) return 'Module';
    return 'Modules';
  }, [learningPath]);

  const usersLabel = useMemo(() => {
    if (learningPath?.total_users === 1) return 'User';
    return 'Users';
  }, [learningPath]);

  return (
    <>
      <ConfirmationDialog
        open={showModal}
        title={
          <>
            Are you sure you want to delete the learning path?
            <br />
          </>
        }
        description="Information will not be recovered"
        acceptText="Yes, Delete"
        rejectText="No, Cancel"
        isDelete={true}
        image={imageBasePath + 'delete_confirmation.svg'}
        handleClose={() => {
          setShowModal(false);
          setDeleteLoader(false);
        }}
        handleSubmit={deleteLearningPath}
        loader={deleteLoader || isLoading}
      />
      <div className={styles.card} onClick={onCardClick}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            <div className={styles.headerTextTitle}>{learningPath?.title}</div>
            <div className={styles.headerTextAuthor}>
              By {`${learningPath?.created_by.first_name} ${learningPath?.created_by.last_name}`}
            </div>
          </div>
          {canPerformActions && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(e);
              }}
            >
              <img
                className="more"
                src={imageBasePath + 'more_black.svg'}
                alt="More"
                style={{ verticalAlign: 'middle' }}
              />
              <Menu
                id={'simple-menu-' + 1}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) ? true : false}
                onClose={(e) => {
                  setAnchorEl(null);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="moreMenu"
              >
                {canPublish && (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleToPublished();
                      setAnchorEl(null);
                    }}
                  >
                    <img src={imageBasePath + 'check_grey3.svg'} alt="Edit" />
                    <span className="supportText">To published</span>
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem
                    className="deleteAction"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClickDelete();
                      setAnchorEl(null);
                    }}
                  >
                    <img src={imageBasePath + 'delete_red.svg'} alt="Edit" />
                    <span className="supportText">Delete</span>
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </div>
        <div className={styles.description}>
          {learningPath?.description.slice(0, 250)}
          {learningPath?.description.length >= 250 ? '...' : ''}
        </div>
        <div className={styles.bubblesContainer}>
          {learningPath?.published_at && (
            <div>
              <img src={`${imageBasePath}PublishedActive.svg`} alt="icon" />
              <strong>{format(parseISO(learningPath?.published_at), 'MMM dd, yyyy')}</strong>{' '}
              Published
            </div>
          )}
          {learningPath?.updated_at && (
            <div>
              <img src={`${imageBasePath}UpdatedActive.svg`} alt="icon" />
              <strong>{format(parseISO(learningPath?.updated_at), 'MMM dd, yyyy')} </strong> Updated
            </div>
          )}
          <div>
            <img src={`${imageBasePath}contentActiveDesign.svg`} alt="icon" />
            <strong>{learningPath?.total_contents}</strong> {contentsLabel}
          </div>
          <div>
            <img src={`${imageBasePath}UsersActive.svg`} alt="icon" />
            <strong>{learningPath?.total_users}</strong> {usersLabel}
          </div>
        </div>
        {learningPath?.deadline && <DeadlineBubble deadline={learningPath?.deadline} />}
      </div>
    </>
  );
};

export default Card;

import React, { useState, useEffect } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import styles from '../../../assets/styles/tracking.module.scss';
import { CustomTargetValue, CustomDeltaValue } from '../../../shared_ui_components';
import { imageBasePath, goalUnits } from '../../../constants';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import Shimmer from './Shimmer';
import { CustomNumberFormatter } from '../../../utils';

export default function OrganisationGoalsViewNew(props) {
  const { overview = {}, role, loader } = props;

  return (
    <>
      {loader ? (
        <Shimmer type="TrackingOrgOverview" />
      ) : (
        <div className={styles.viewContentWrapper + ' ' + styles.orgViewWrapper}>
          <Grid container direction="row" className={styles.tableTitleRow}>
            <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
              <span className={`supportTextSemibold ${styles.noTransform}`}>Goal</span>
              <div className={styles.cardItem}>
                {overview?.goals && overview?.goals.length
                  ? overview.goals?.map((goal, index) => (
                      <div className={styles.titleBlk}>
                        <h3 className="heading5">
                          <img
                            src={`${imageBasePath}${
                              goalUnits?.find((item) => item.id == goal.unit)?.icon
                            }`}
                            alt="Leads"
                          />
                          <span>{goal.name}</span>
                        </h3>
                      </div>
                    ))
                  : ''}
              </div>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
              <List className="listInline">
                {' '}
                {/* add class active for active month */}
                {overview?.date_list && overview?.date_list.length
                  ? overview.date_list?.map((goal, index) => (
                      <OrganizationGoalItem index={index} goal={goal} key={goal.uuid} role={role} />
                    ))
                  : ''}
              </List>
            </Grid>
          </Grid>
        </div>
      )}

      {/* <Shimmer type="TrackingSalesOverview" /> */}
    </>
  );
}

function OrganizationGoalItem(props) {
  const { goal = {}, role, index } = props;
  return (
    <>
      <ListItem key={index} className="listInlineItem">
        <p className={styles.monthTitle + ' ' + `${goal.current ? styles.active : ''}`}>
          <span className={`supportTextSemibold ${styles.noTransform}`}>{goal.header_text}</span>
        </p>
        {goal?.goal_data &&
          goal?.goal_data?.map((item, index) => (
            <div className={styles.content}>
              <h4
                className="heading2"
                style={{ color: `${item?.total_users_target == '0' ? '#8C99A3' : '#193448'}` }}
              >
                {item?.delta_value ? <CustomDeltaValue value={item?.delta_value} /> : null}
                {goal.unit === 2 && item?.total_users_target ? (
                  <>
                    <span>$</span>
                    <NumberFormat
                      displayType="text"
                      format={CustomNumberFormatter}
                      value={item?.total_users_target}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </>
                ) : (
                  CustomNumberFormatter(item?.total_users_target)
                )}
              </h4>
              {['user'].includes(role?.slug) ? null : (
                <div className={styles.customTarget}>
                  <CustomTargetValue unit={goal.unit} value={item.expected_target} />
                </div>
              )}
            </div>
          ))}
      </ListItem>
    </>
  );
}

import React from 'react';
import { Typography } from '@material-ui/core';
import creation from 'assets/icons/mdi_creation.svg';
import styles from '../chatAssist.module.scss';
const PromptContainer = ({ title, text }) => {
  return (
    <div className={styles.tipContainer}>
      <div className={styles.textContainer}>
        <Typography variant="body2" className={styles.title}>
          {title}
        </Typography>
        <Typography className={styles.text}>{text}</Typography>
      </div>
      <img src={creation} alt="creation" width={24} height={24} />
    </div>
  );
};

export default PromptContainer;

import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';

import { useHubspotStore } from 'features/hubspot/Hubspot.store';
import HubspotSettings from 'features/hubspot/settings/hubspotSettings';
import styles from './HubspotSettings.module.scss';

const HubspotSettingsModal = () => {
  const open = useHubspotStore((state) => state?.settingsModalOpen);
  const onClose = useHubspotStore((state) => state?.closeSettings);

  useEffect(() => {
    return function clean() {
      onClose();
    };
  }, [onClose]);

  if (!open) return null;
  return (
    <Dialog className={styles.hubspotSettingsModal} onClose={onClose} open={open}>
      <HubspotSettings />
    </Dialog>
  );
};

export default HubspotSettingsModal;

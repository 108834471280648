import React from 'react';
import { TooltipCustom } from '../../../utils';
import './RecorderButton.scss';

const RecorderButton = ({ src, alt, onClick, tooltip, preventHide, withBorder }) => {
  return (
    <TooltipCustom title={tooltip} arrow placement="bottom">
      <div
        className={`recorder-btn-container ${preventHide ? 'prevent-hide' : ''}`}
        onClick={onClick}
        style={{ border: withBorder ? '1px solid #EBEDF0' : '' }}
      >
        <img alt={alt} src={src} />
      </div>
    </TooltipCustom>
  );
};

export default RecorderButton;

import React from 'react';
import Post from '../Post/Post';
import styles from './LatestPostList.module.scss';
import { useStreamStore } from 'features/stream/Stream.store';
import NoResults from '../../components/NoResults';

const LatestPostList = () => {
  const latestPosts = useStreamStore((state) => state?.latestPosts);
  const hasFilters = useStreamStore((state) => state?.hasFilters);

  return (
    <div className={styles.latestPosts}>
      {latestPosts.length === 0 ? (
        <NoResults hasFilters={hasFilters} />
      ) : (
        latestPosts?.map((post) => <Post key={post?.id} postData={post} />)
      )}
    </div>
  );
};

export default LatestPostList;

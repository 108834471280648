import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { InputText, TextArea } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { addNewCustomModuleApi, updateModuleApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { ConfirmationDialog } from '../../../shared_elements';
import { updateSubscriptionModules } from 'features/payment/Payment.utils';

export default function AddModule(props) {
  let history = useHistory();
  const { open, activeModule, type = 'add' } = props;
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [module, setModule] = useState('');

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (open) {
      setFormData({
        title: type == 'edit' && activeModule ? activeModule?.title : '',
        description: type == 'edit' && activeModule ? activeModule?.description : '',
      });
      setError({});
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loader) return;
    setLoader(true);
    let validateNewInput = {
      title: formData.title && formData.title.trim() ? '' : 'Please enter title',
      description:
        formData.description && formData.description.trim() ? '' : 'Please enter description',
    };
    if (
      Object.keys(validateNewInput) &&
      Object.keys(validateNewInput).every((k) => validateNewInput[k] === '')
    ) {
      if (type == 'add') {
        addNewCustomModuleApi(formData)
          .then((response) => {
            if (response.statusCode === 200) {
              toastFlashMessage(response.message, 'success');
              setModule(response.data);
              updateSubscriptionModules(1);
              props.fetchMouleList();
              props.handleClose();
              setAddSuccess(true);
            } else {
              toastFlashMessage(response.message, 'error');
              setLoader(false);
            }
          })
          .catch(() => setLoader(false));
      } else {
        updateModuleApi(activeModule?.uuid, {
          description: formData.description,
          title: formData.title,
        }).then((response) => {
          if (response.statusCode === 200) {
            toastFlashMessage(response.message, 'success');
            props.fetchMouleList();
            props.handleClose();
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
      }
    } else {
      setError(validateNewInput);
      setLoader(false);
    }
  };

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    setError({
      ...error,
      [key]: '',
    });
  };
  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={props.handleClose}
          scroll={'paper'}
          className={`dialog ${styles.addNewModule}`}
        >
          <DialogTitle id="add-video-title">
            {type == 'edit' ? `Edit module` : `Add new module`}{' '}
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className="modalCloseIcon"
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent>
            <div className={styles.dialogBody}>
              <form onSubmit={handleSubmit}>
                <InputText
                  label={`Title`}
                  placeholder="Module name"
                  onChange={(e) => handleChange('title', e.target.value)}
                  value={formData.title}
                  disabled={type == 'edit' && activeModule.is_predefined ? true : false}
                  error={error.title}
                />
                <TextArea
                  label={`Description`}
                  rows={3}
                  placeholder="Add module description here"
                  onChange={(e) => handleChange('description', e.target.value)}
                  value={formData.description}
                  error={error.description}
                />
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} className="tertiaryBtn">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={loader}
              className={`primaryBtn ${
                loader ||
                !formData.title ||
                !formData.description ||
                Object.keys(error).some((k) => error[k] != '')
                  ? `disabledBtn`
                  : ''
              }`}
            >
              {type == 'add' ? `Create` : `Save`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ConfirmationDialog
        open={addSuccess}
        title={
          <>
            <strong>{module?.title}</strong> module has been added!
          </>
        }
        description="You can go ahead and start adding content to this module"
        acceptText="Add Content"
        rejectText="Will do it later"
        image={imageBasePath + 'module_added_success.svg'}
        handleClose={() => setAddSuccess(false)}
        handleSubmit={() => history.push(`/training/${module?.uuid}`)}
      />
    </>
  );
}

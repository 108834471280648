import SignUp from './containers/SignUp';
import SignIn from './containers/SignIn';
import NewSignIn from './containers/NewSignIn';
import NewSignUp from './containers/NewSignUp';
import InviteUser from './containers/InviteUser';
import VerifyEmail from './containers/VerifyEmail';
import EmailVerified from './containers/EmailVerified';
import Organization from './containers/Organization';
import SelectOrganization from './containers/SelectOrganization';
import PasswordRecovery from './containers/PasswordRecovery';
import PasswordReset from './containers/PasswordReset';
import redirectAuth from '../../hocs/redirectAuth';
import VerifyDomain from './components/VerifyDomain';
import VerificationRejected from './components/VerificationRejected';
import AccessOwnership from './containers/AccessOwnership';
import DeletedForRetention from './components/DeletedForRetention';
import { FeatureFlag } from 'controllers/_exports';

export const authRoutes = [
  {
    path: '/signup',
    component: redirectAuth(FeatureFlag.enabled('SIGN_IN_REDESIGN') ? NewSignUp : SignUp),
    key: 'signup',
  },
  {
    path: '/signin',
    component: redirectAuth(FeatureFlag.enabled('SIGN_IN_REDESIGN') ? NewSignIn : SignIn),
    key: 'signup',
  },
  {
    path: '/verifyemail',
    component: VerifyEmail,
    key: 'verifyemail',
  },
  {
    path: '/emailverification',
    component: redirectAuth(EmailVerified),
    key: 'emailverified',
  },

  {
    path: '/forgotpassword',
    component: redirectAuth(PasswordRecovery),
    key: 'forgotpassword',
  },
  {
    path: '/passwordreset',
    component: redirectAuth(PasswordReset),
    key: 'passwordreset',
  },
  {
    path: '/requestaccess',
    component: Organization,
    key: 'organizationexist',
  },
  {
    path: '/createorganization',
    component: Organization,
    key: 'createorganization',
  },
  {
    path: '/inviteuser',
    component: InviteUser,
    key: 'invite',
  },
  {
    path: '/access-ownership',
    component: AccessOwnership,
    key: 'access-ownership',
  },
  {
    path: '/selectorganization',
    component: SelectOrganization,
    key: 'selectorganization',
  },
  {
    path: '/verifydomain',
    component: VerifyDomain,
    key: 'verifydomain',
  },
  {
    path: '/verificationrejected',
    component: VerificationRejected,
    key: 'verificationrejected',
  },
  {
    path: '/retained',
    component: DeletedForRetention,
    key: 'retained',
  },
];

export const organisationRoutes = [
  //
  // {
  //   path: '/selectorganization',
  //   component: SelectOrganization,
  //   key: 'selectorganization',
  // },
];

import React from 'react';
import styles from './FunnelGraph.module.scss';

const FunnelTooltip = ({ part }) => {
  return (
    <div className={styles.tooltip}>
      <span>{part?.data?.label}: </span>
      <b>{part?.data?.percentage}%</b>
    </div>
  );
};

export default FunnelTooltip;

import React from 'react';
import styles from './ReviewQualifiedRoleplay.module.css';
import Video from '../Common/Video';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const RoleplayVideo = ({ selectedScenario }) => {
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);

  return (
    <div className={styles.videoContainer}>
      {!secondAttempt && <Video source={selectedScenario?.response?.video} />}
      {(selectedScenario.response_retake !== null && secondAttempt) && <Video source={selectedScenario?.response_retake?.video} />}
    </div>
  );
};

export default RoleplayVideo;

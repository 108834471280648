import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import styles from '../../Payment.module.scss';
import Loading from 'shared_ui_components/Loading';
import { usePaymentStore } from '../../../Payment.store';

const CardInfo = ({ onNextPage = () => null }) => {
  // Data from store
  const billing_details = usePaymentStore((state) => state?.billingData);
  const set_payment_data = usePaymentStore((state) => state?.setPaymentMethodData);

  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleCardChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmitPaymentMethod = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details,
    });

    if (!error) {
      set_payment_data(paymentMethod);
      onNextPage();
    } else setError(error?.message);

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmitPaymentMethod}>
      <div className={styles.cardInput}>
        <CardElement id="card-element" onChange={handleCardChange} options={cardElementStyle} />
      </div>
      {error && (
        <Alert style={{ marginBottom: '1rem' }} severity="error">
          {error}
        </Alert>
      )}
      <div
        style={{
          fontSize: '0.8rem',
          color: '#4e4e4e',
          margin: '20px',
        }}
      >
        I authorize Salestable.ai to save this payment method and automatically charge this payment
        method whenever a subscription is associated with it.
      </div>

      <div className={styles.nextButtonContainer}>
        <Button type="submit" variant="contained" disabled={disabled || loading}>
          Next
        </Button>
      </div>
      <Loading loading={loading} />
    </form>
  );
};

export default CardInfo;

const cardElementStyle = {
  style: {
    base: {
      color: '#fff',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#fff',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

import { FeatureFlag } from 'controllers/_exports';
import { useMemo } from 'react';
import { useMultipleChoice } from './useMultipleChoice';

const baseFeedbackContentTypes = [
  {
    value: '6',
    label: 'Text (1000 words)',
    title: 'Text',
    platforms: [],
    extensions: [],
    icon: 'txt.svg',
  },
  {
    value: '1',
    label: 'Video (.mp4, .mov)',
    platforms: [
      'youtube.com',
      'youtu.be',
      'loom.com',
      'vimeo.com',
      'video/quicktime',
      'video/mp4',
      'video/ogg',
    ],
    extensions: [
      '.WEBM',
      '.MPG',
      '.MP2',
      '.MPEG',
      '.MPE',
      '.MPV',
      '.OGG',
      '.MP4',
      '.M4P',
      '.M4V',
      '.MOV',
      '.QT',
      '.AVI',
      '.MKV',
    ],
    title: 'Video',
    icon: 'video.svg',
  },
  {
    value: '2',
    label: 'Audio (.mp3)',
    platforms: [
      'podcast.co',
      'soundtrap.com',
      'soundcloud.com',
      'anchor.fm',
      'audio/mpeg',
      'audio/wav',
      'audio/mp3',
      'audio/mp4',
    ],
    extensions: ['.mp3', '.mp4', '.wav'],
    title: 'Audio',
    icon: 'audio.svg',
  },
  {
    value: '3',
    label: 'Document (.pdf, .docx)',
    platforms: ['docs', 'document', 'application/pdf', 'officedocument'],
    extensions: ['.docx', '.odt', '.rtf', '.pdf'],
    title: 'Document',
    icon: 'word.svg',
  },
  {
    value: '4',
    label: 'Spreadsheet (.csv, .xls)',
    platforms: ['spreadsheet', 'text/csv', 'text/xls', 'text/ods', 'text/tsv', 'text/xlsx'],
    extensions: ['.xls', '.csv', '.ods', '.tsv', '.xlsx'],
    title: 'Spreadsheet',
    icon: 'xls.svg',
  },
  {
    value: '5',
    label: 'Presentation (.pptx, .jpg, .png)',
    platforms: ['presentation', 'image/png', 'image/jpeg', 'image/jpg', 'image/svg'],
    extensions: ['.pptx', '.jpg', '.jpeg', '.png', '.svg'],
    title: 'Presentation',
    icon: 'ppt.svg',
  },
];

const useAssessment = () => {
  // New feedback content types
  const feedbackContentTypes = useMemo(() => {
    const _types = [...baseFeedbackContentTypes];
    if (FeatureFlag.enabled('ASSESSMENT_URL'))
      _types.push(
        ...[
          {
            value: '7',
            label: 'Video recording (max 5 mins)',
            extensions: [],
            title: 'Video recording',
            icon: 'video.svg',
          },
          {
            value: '8',
            label: 'Link (URL)',
            platforms: [],
            extensions: ['URL'],
            title: 'Link',
            icon: 'txt.svg',
          },
        ]
      );
    if (FeatureFlag.enabled('ASSESSMENT_MULTIPLE'))
      _types.push({
        value: '9',
        label: 'Multiple choice',
        extensions: [],
        title: 'Multiple choice',
        icon: 'txt.svg',
      });
    return _types;
  }, []);

  const multipleChoice = useMultipleChoice();

  return { feedbackContentTypes, ...multipleChoice };
};

export default useAssessment;

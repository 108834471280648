import React from 'react';
import RecorderButton from './RecorderButton';

import trash from '../../../assets/recorder/trash.svg';

const DiscardButton = ({ onClick = () => null }) => {
  return (
    <RecorderButton
      onClick={onClick}
      src={trash}
      alt="discard"
      tooltip="Discard Recording"
      preventHide
    />
  );
};

export default DiscardButton;

import { useState, useContext, useEffect, useMemo } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { imageBasePath } from '../constants';
import { GlobalContext } from '../context/GlobalContext';
import ReactHtmlParser from './ReactHtmlParser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ToastNotify(props) {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const { toastLists } = state;

  useEffect(() => {
    if (toastLists.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [toastLists.length]);

  // const toastImg = props.type? props.type : null
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: 'HIDE_TOAST',
      payload: {},
    });
    setOpen(false);
  };

  const iconImg = useMemo(() => {
    if (toastLists?.length === 0) return '';
    if (toastLists[0].toastType === 'disabled') return imageBasePath + 'info_icon.svg';
    return imageBasePath + toastLists[0].toastType + '.svg';
  }, [toastLists]);

  const closeImg = useMemo(() => {
    if (toastLists?.length === 0) return '';
    if (toastLists[0].toastType === 'disabled') return imageBasePath + 'close.svg';
    return imageBasePath + toastLists[0].toastType + '_close.svg';
  }, [toastLists]);

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        style={{ zIndex: '999999', marginTop: '0' }}
        autoHideDuration={props.delay}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className="snackbar"
      >
        {toastLists[0] ? (
          <div className={toastLists[0].toastType}>
            <p className={toastLists[0].toastType + ' paragraph'}>
              {/* {console.log(toastLists[0].type)} */}
              <img alt="" className="toastIcon" src={iconImg} />
              {/* {toastLists[0].message} */}
              {<ReactHtmlParser content={toastLists[0].message} />}
              <img className="closeIcon" src={closeImg} onClick={() => handleClose()} alt="" />
            </p>
          </div>
        ) : null}
      </Snackbar>
    </div>
  );
}

ToastNotify.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  message: PropTypes.string,
  delay: PropTypes.number,
};

ToastNotify.defaultProps = {
  type: 'success',
  message: '',
  delay: 5000,
};

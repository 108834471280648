import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath, goalFrequencies } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { getStartEndDateOfCheckin } from '../../../utils';
import { CheckinAlert, OrganizationGoalsStatus, SalesGoalsStatus } from './';
import { NoResult } from '../../../shared_ui_components';
import { format } from 'date-fns';
import { FeatureFlag } from 'controllers/_exports';

export default function TrackingProgress(props) {
  const { tracking = {} } = props;
  const [date, setDate] = useState({ start_date: '', end_date: '' });
  const [salesGoals, salesGoalsSet] = useState({});

  // Frequencies
  const [organizationGoalsFrequency, setOrganizationGoalsFrequency] = useState(null);
  const [salesGoalsFrequency, setSalesGoalsFrequency] = useState(null);

  useEffect(() => {
    if (tracking && tracking.checkin?.categories && tracking.checkin?.categories?.length) {
      setDate(getStartEndDateOfCheckin(tracking.checkin?.categories));
      let salesGoals = tracking.checkin?.categories?.find((cat) => !cat.is_organisation);
      salesGoalsSet(tracking.checkin?.categories?.find((cat) => !cat.is_organisation));

      // Frequencies
      setSalesGoalsFrequency(salesGoals?.frequency);
      setOrganizationGoalsFrequency(
        tracking.checkin?.categories?.find((cat) => cat.is_organisation)?.frequency
      );
    }
  }, [tracking]);

  return (
    <div className={`${styles.progressContentWrapper}`}>
      <OverviewTitle
        icon={`${imageBasePath}track_default.svg`}
        title={`Goal Tracking`}
        moduleLink={'/tracking'}
      />
      <Grid
        container
        alignItems=""
        className={`${styles.trackingProgressContent} ${styles.defaultProgressContent}`}
        spacing={2}
      >
        {(tracking?.previous_checkin &&
          Object.keys(tracking?.previous_checkin) &&
          Object.keys(tracking?.previous_checkin).length) ||
        tracking?.checkin?.checkin_exists ? (
          <>
            <Grid item xs={12}>
              <CheckinAlert
                handleOpenCheckin={props.handleOpenCheckin}
                hanldeEditCheckin={props.hanldeEditCheckin}
                type={tracking?.checkin?.checkin_exists ? `editCheckin` : `checkin`}
                btnText={tracking?.checkin?.checkin_exists ? `Edit check-in` : `Check-in`}
                message={
                  tracking?.checkin?.checkin_exists
                    ? `Great Job! You have already checked in for ${
                        goalFrequencies?.find((goal) => goal.id === salesGoals?.frequency)
                          ?.singleLable
                      } ${
                        salesGoals?.frequency === 1 && date?.start_date
                          ? format(date.start_date, 'dd MMM yyyy')
                          : date?.start_date && date?.end_date
                          ? format(date.start_date, 'dd MMM yyyy') +
                            ' - ' +
                            format(date.end_date, 'dd MMM yyyy')
                          : null
                      }`
                    : `We know you are busy. As a reminder, you need to complete your ${
                        goalFrequencies?.find((goal) => goal.id === salesGoals?.frequency)
                          ?.singleLable
                      } check-in`
                }
                last_checkin={tracking?.checkin?.last_checkin}
              />
            </Grid>
            {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
              <Grid item xs={12} md={4}>
                <OrganizationGoalsStatus
                  frequency={organizationGoalsFrequency}
                  organizationGoals={
                    (tracking?.checkin?.categories || [])?.find((item) => item?.is_organisation)
                      ?.goals || []
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 12 : 8}>
              <SalesGoalsStatus
                frequency={salesGoalsFrequency}
                salesGoals={
                  tracking?.checkin?.categories?.find((item) => !item.is_organisation).goals
                }
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <NoResult
              image={`no_checkin_icon.svg`}
              className={styles.noStateWrapper}
              subtext={`Review your organizational and individual goals. Start your checkin and add your achievements`}
              btnText={`Go to goal tracking`}
              link={`/tracking`}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

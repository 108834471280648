import React, { useState, useEffect, useMemo } from 'react';
import { FeatureFlag } from 'controllers/_exports';

function Timonthtamp({ creationDate, post = false }) {
  const [timestamp, setTimestamp] = useState('');

  useEffect(() => {
    const calculateTimestamp = () => {
      const tiempoActual = new Date().getTime();
      const tiempoCreacion = new Date(creationDate).getTime();
      const tiempoDiferencia = tiempoActual - tiempoCreacion;

      const seconds = Math.floor(tiempoDiferencia / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years > 0) {
        return years + (years === 1 ? ' year' : ' years') + ' ago';
      } else if (months > 0) {
        return months + (months === 1 ? ' month' : ' months') + ' ago';
      } else if (days === 7) {
        return 1 + 'week ago';
      } else if (days > 0) {
        return days + (days === 1 ? ' day' : ' days') + ' ago';
      } else if (hours > 0) {
        return hours + 'h ago';
      } else if (minutes > 0) {
        return minutes + ' min ago';
      } else {
        return seconds + ' secs ago';
      }
    };

    const updateTime = () => {
      const tiempo = calculateTimestamp();
      setTimestamp(tiempo);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 5000); // Actualiza cada minute (60000 ms)

    return () => {
      clearInterval(intervalId);
    };
  }, [creationDate]);
  const hide = useMemo(() => !FeatureFlag.enabled('STREAM_IMPROVEMENTS'), []);

  if (hide) return null;
  return (
    <div
      style={{
        fontSize: '12px',
        fontWeight: '400',
        marginTop: post ? '-5px' : 0,
        color: post ? '' : '#87a0b4',
      }}
    >
      {timestamp}
    </div>
  );
}

export default Timonthtamp;

import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { CustomButton } from '../../../shared_ui_components';

export default function AssesmentSetupPending(props) {
  const { loader } = props;
  return (
    <>
      <div className={styles.assesmentPendingWrapper}>
        <Grid container direction="row" alignItems="center" className={styles.titleRow}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className={styles.titleBlk}>
              <div className={styles.imgBlk}>
                <img src={imageBasePath + 'admin_assesment_featured.png'} alt="Image" />
              </div>
              <div className={styles.contentBlk}>
                <h1 className="heading4">
                  <span>Our Training Assessment is now available!</span>
                </h1>
                <p className="paragraph">Use it to check on your training status</p>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className="textRight">
            <CustomButton
              className={`${styles.startBtn} primaryBtn ${loader ? 'disabledBtn' : ''}`}
              onClick={() => {
                props.handleStartAsssesmentSetup(true);
              }}
            >{`Start Setup`}</CustomButton>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export const useMultipleChoice = () => {
  // Multiple choice question type uses rating criteria to save multiple choice options under the hood
  const isMultipleChoice = ({ item }) => item?.answer_type?.value == 9 || item?.answer_type == 9;

  const max_rating_criteria = ({ item }) => {
    return isMultipleChoice({ item }) ? 4 : 3;
  };

  // Labels
  const max_criteria_label = ({ item }) => {
    if (isMultipleChoice({ item })) return 'You’ve reached the limit of responses';
    return `You have reached maximum limit for adding rating criteria`;
  };
  const add_criteria_label = ({ item }) => {
    return isMultipleChoice({ item }) ? '+ Add choice' : '+ Add rating criteria';
  };
  const criteria_placeholder = ({ item, index }) => {
    return isMultipleChoice({ item }) ? `Option ${index + 1}` : 'Add rating Criteria';
  };
  const criteria_title = ({ item }) => {
    return isMultipleChoice({ item }) ? 'Possible Answers:' : 'Rating Criteria:';
  };
  const repeated_error = ({ item }) => {
    return isMultipleChoice({ item })
      ? 'Please do not provide same options'
      : 'Please do not provide same rating criteria';
  };

  const needs_description = ({ item }) => !isMultipleChoice({ item });

  const can_delete_criteria = ({ assessmentQuestion, criteria, feedbackIndex }) => {
    const len = assessmentQuestion.feedback_questions.length;
    const isCorrect = criteria?.is_correct;

    if (isMultipleChoice({ item: assessmentQuestion })) {
      return !isCorrect && feedbackIndex > 0 && len >= 3;
    }
    return !isCorrect && len >= 2;
  };

  return {
    max_rating_criteria,
    max_criteria_label,
    add_criteria_label,
    criteria_placeholder,
    needs_description,
    isMultipleChoice,
    criteria_title,
    can_delete_criteria,
    repeated_error,
  };
};

import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getGoalsOrderService,
  reorderGoalsService,
  updateGoalsSettingsService,
} from './tracking.services';
import { useTrackingStore } from './tracking.store';

const trackingCacheKeys = {
  ORDER: 'tracking_order',
};

export const useGoalsOrderQuery = () => {
  const setOrder = useTrackingStore((state) => state?.setOrder);
  return useQuery([trackingCacheKeys.ORDER], getGoalsOrderService, {
    onSuccess: (response) => setOrder(response),
  });
};

export const useReorderGoalsMutation = () => {
  const setAllGoalsOrder = useTrackingStore((state) => state?.setAllGoalsOrder);
  return useMutation(reorderGoalsService, {
    onSuccess: (response, { goals }) => {
      setAllGoalsOrder(goals);
    },
  });
};

export const useUpdateGoalsSettingsMutation = () => {
  // const setAllGoalsOrder = useTrackingStore((state) => state?.setAllGoalsOrder);
  return useMutation(updateGoalsSettingsService, {
    onSuccess: (response, { goals }) => {
      // setAllGoalsOrder(goals);
    },
  });
};

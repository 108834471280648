import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { FeatureSlider, SignUpForm } from '../components';
import { regExpression } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { signUpApi, socialSignUpApi, handleSiginResponse } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Payment } from 'features/payment/Payment.utils';

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      error: {},
      errorCode: {
        email: {
          0: '',
          1: 'Please add your Work Email Address',
          4: 'Invalid Email Address, Please try again',
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
        password: {
          0: '',
          1: 'Please enter your Password!',
          4: 'Invalid password',
        },
        passwordObj: {
          requiredFlag: true,
          regexPattern: regExpression.password,
        },
      },
      passwordValidation: {
        minCharacter: false,
        oneNumber: false,
        oneUpperCase: false,
        oneLowerCase: false,
      },
      submitLoader: false,
      socialLoginLoader: false,
      socialLinkedinLoader: false,
    };
    this.handleSiginResponse = handleSiginResponse.bind(this);
    // this.signUpApi = signUpApi.bind(this);
  }

  componentDidMount() {
    var base_url = window.location.origin;
    const querys = queryString.parse(this.props.location.search);

    Payment.storePriceAndCoupon({ location: this.props.location });

    const token = querys.token;
    const google_access_token = queryString.parse(this.props.location.hash)
      ? queryString.parse(this.props.location.hash).access_token
      : '';
    const linkedIn_token = querys.code;

    if (google_access_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState({
        socialLoginLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          access_token: google_access_token,
          social_type: 'google',
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else if (linkedIn_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState({
        socialLinkedinLoader: true,
      });
      setTimeout(() => {
        socialSignUpApi({
          access_token: linkedIn_token,
          social_type: 'linkedin',
          redirect_uri: `${base_url}/signup`,
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else {
      this.setState({
        submitLoader: false,
      });
    }
    // if (localStorage.getItem('userInfo') && localStorage.getItem('access')) {
    //   this.props.history.push('/');
    // }

    const params = queryString.parse(window.location.search);
    if (params.error) {
      const errorMessage = params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          { error: params.error, state: params.state, errorMessage, from: 'Linked In' },
          window.location.origin
        );
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }
    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          { code: params.code, state: params.state, from: 'Linked In' },
          window.location.origin
        );
    }
    if (params.linkedin_redirect_url) {
      window.location.href = params.linkedin_redirect_url;
    }
  }

  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: '',
      },
    });
    if (key == 'password') {
      this.setState({
        passwordValidation: {
          ...this.state.passwordValidation,
          minCharacter: this.hasLength(val),
          oneNumber: this.hasNumber(val),
          oneUpperCase: this.hasUpperCase(val),
          oneLowerCase: this.hasLowerCase(val),
        },
      });
    }
  };

  togglePassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        password_show: !this.state.formData.password_show,
      },
    });
  };

  hasNumber = (str) => {
    return /\d/.test(str);
  };

  hasLength = (str) => {
    if (str.length > 7) {
      return true;
    } else {
      return false;
    }
  };

  hasLowerCase = (str) => {
    return /[a-z]/.test(str);
  };

  hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
  };

  handleSubmit = () => {
    const { formData, errorCode, passwordValidation } = this.state;
    const validateNewInput = {
      email:
        errorCode['email'][
          fieldValidation({
            ...errorCode['emailObj'],
            fieldval: formData.email,
          })
        ],
      password:
        errorCode['password'][
          fieldValidation({
            ...errorCode['passwordObj'],
            fieldval: formData.password,
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      this.setState({
        submitLoader: true,
      });
      // this.signUpApi({formData: formData});
      signUpApi({ formData: { ...formData, email: formData.email.trim() } }).then((response) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          if (response.data && response.data.user) {
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            const redirection = localStorage.getItem('redirection');
            if (redirection) {
              this.props.history.push({
                pathname: redirection,
                search: '',
                state: { prevPath: '/' },
              });
            } else {
              setTimeout(() => this.props.history.push('/'), 500);
            }
          } else {
            setTimeout(
              () =>
                this.props.history.push({
                  pathname: '/verifyemail',
                  search: '',
                  state: { formData: { email: formData.email } },
                }),
              500
            );
          }
          this.setState((prevState) => ({
            ...prevState,
            submitLoader: false,
          }));
        } else {
          toastFlashMessage(response.message, 'error');
          this.setState((prevState) => ({
            ...prevState,
            submitLoader: false,
          }));
        }
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  render() {
    const {
      formData,
      error,
      submitLoader,
      passwordValidation,
      socialLoginLoader,
      socialLinkedinLoader,
    } = this.state;

    return (
      <div className={styles.signUpPage}>
        {socialLoginLoader || socialLinkedinLoader ? (
          <div className={styles.loaderBlk}>
            <CircularProgress />
          </div>
        ) : (
          ''
        )}
        <section className={styles.signUpSection}>
          <div className={styles.signUpWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signUpRow}>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <FeatureSlider />
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <SignUpForm
                  formData={formData}
                  error={error}
                  submitLoader={submitLoader}
                  socialLoginLoader={socialLoginLoader}
                  socialLinkedinLoader={socialLinkedinLoader}
                  passwordValidation={passwordValidation}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  togglePassword={this.togglePassword}
                />
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

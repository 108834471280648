import React from 'react';

import styles from './ProductCard.module.scss';
import { Subscription } from 'controllers/_exports';

const PriceLabel = ({ product = {}, isOptimize }) => {
  const { price = {}, id } = product;

  const originalPrice = price?.monthly_price?.price || price?.price;
  const newPrice = price?.monthly_price?.new_price || price?.new_price;
  const isFree = id === Subscription.tiers.freemium;

  if (id === Subscription.tiers.beta) return null;

  return (
    <div className={`${styles.headerInfo} ${isFree ? styles.free : ''}`}>
      {!isFree ? (
        <div>
          {newPrice ? (
            <>
              <span className={styles.headerInfoGray}>${originalPrice}</span>
              <span className={isOptimize ? styles.headerInfoLight : styles.headerInfoDark}>
                ${newPrice}
              </span>
            </>
          ) : (
            <span className={isOptimize ? styles.headerInfoLight : styles.headerInfoDark}>
              ${originalPrice}
            </span>
          )}
          <span className={isOptimize ? styles.headerInfoLight : styles.headerInfoDark}>
            /month
          </span>
        </div>
      ) : (
        <span className={styles.headerInfoBold}>FREE</span>
      )}
    </div>
  );
};

export default PriceLabel;

import React, {useMemo} from 'react'
import { Grid, List, ListItem } from '@material-ui/core';
import { ProgressBar } from 'shared_ui_components';
import styles from 'assets/styles/training.module.scss';
import usersLearningPath from 'assets/icons/usersLearningPath.svg'
const LearningPathItem = ({learningPath}) => {

    const colorClass = useMemo(() => {
      if (learningPath?.percentage_progress >= 90) return 'status1';
      return 'status2Bg';
    }, [learningPath?.percentage_progress]);
  
    return (
      <div className="item">
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item md={3}>
            <h5 className="heading5">{learningPath.learning_path_title}</h5>
          </Grid>
          <Grid item md={4}>
            <ProgressBar
              colorClass={colorClass}
              className="stepProgress thick-progress"
              value={{
                completed: learningPath.percentage_progress,
                inProgress: 0,
                notStarted: 0,
              }}
              max="100"
              isMulti={true}
            />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={3}>
            <List className="listInline flexCentered usersVoteList">
              <ListItem className="listInlineItem" style={{ paddingLeft: '0' }}>
                <div className={styles.upvote}>
                  <img src={usersLearningPath} alt="users" />
                  <span className="supportText">{learningPath.total_sales_rep}</span>
                </div>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    );
}

export default LearningPathItem
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useMemo } from 'react';
import { getTrialDaysText, userHasSubscription } from '../../payment/Payment.utils';
import { Subscription } from 'controllers/_exports';

const subscriptionStatusLabels = {
  trial_period: 'TRIAL',
  active_subscription: 'ACTIVE',
  active_suscription: 'ACTIVE',
  payment_failed: 'PAYMENT FAILED',
  no_subscription: 'NO SUBSCRIPTION',
};

const subscriptionStatusSeverity = {
  trial_period: 'info',
  active_subscription: 'success',
  active_suscription: 'success',
  payment_failed: 'error',
  no_subscription: 'warning',
};

const SubscriptionAlert = ({ subscription, isLoading, isFree }) => {
  const subscriptionLabel = subscription?.subscription_status?.label;

  const severity = useMemo(
    () =>
      subscriptionLabel in subscriptionStatusSeverity
        ? subscriptionStatusSeverity[subscriptionLabel]
        : 'info',
    [subscriptionLabel]
  );

  const status = useMemo(() => {
    if (!subscriptionLabel && Subscription.isBeta()) return 'ACTIVE';
    return subscriptionLabel in subscriptionStatusLabels
      ? subscriptionStatusLabels[subscriptionLabel]
      : subscriptionLabel;
  }, [subscriptionLabel]);

  const trialDays = useMemo(() => {
    return subscription?.subscription_status?.value === 1
      ? getTrialDaysText({
          days: subscription?.remaining_free_days,
          variant: 1,
        })
      : '';
  }, [subscription?.remaining_free_days, subscription?.subscription_status?.value]);

  if (isFree)
    return (
      <Alert severity="info">
        You have a free subscription, take your experience to the next level by upgrading your plan:
      </Alert>
    );

  return (
    <>
      {userHasSubscription() ? (
        <Alert severity={severity}>
          {`Subscription status: ${isLoading ? 'Loading...' : ''} ${status || ''} ${
            trialDays || ''
          }`}
        </Alert>
      ) : (
        <Alert severity="warning">You don't have a subscription yet</Alert>
      )}
    </>
  );
};

export default SubscriptionAlert;

import { useMutation } from '@tanstack/react-query';
import { API } from 'utils/globalApiServices';

async function updateBannerService({ isHome, isPulse }) {
  const url = '/views/change-banner/';
  const body = {};
  if (isHome) body.welcome_banner = false;
  if (isPulse) body.pulse_banner = false;
  const { data } = await API.put(url, body);
  return data;
}

export const useCloseBannerMutation = () => {
  return useMutation(updateBannerService);
};

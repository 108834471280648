import React from 'react';

import styles from '../../Payment.module.scss';
import { usePaymentStore } from 'features/payment/Payment.store';
import { Subscription } from 'controllers/_exports';

const ProductsList = ({ data }) => {
  const selectedProduct = usePaymentStore((state) => state?.selectedProduct);
  const setSelectedProduct = usePaymentStore((state) => state?.setSelectedProduct);

  return data?.products?.map((product) => {
    const { name, prices, stripe_id, id } = product;

    if (prices?.length === 0 && id === Subscription.tiers.freemium) {
      product.stripe_id = product?.id;
      prices[0] = { isFree: true, description: '', stripe_id: product?.id };
    }

    return (
      <div key={stripe_id} className={styles.productSelectorContainer}>
        <h2 className={styles.priceName}>{name}</h2>
        {/* List prices of each plan */}
        {prices?.map((priceData = {}) => {
          const { stripe_id, description, billing_cycle } = priceData;
          const selected = selectedProduct?.selectedPrice?.stripe_id === stripe_id;

          const newPrice = getPriceText({ priceData, original: false });
          const originalPrice = getPriceText({ priceData, original: true });

          const intervalLabel =
            billing_cycle === Subscription.intervals.MONTHLY
              ? 'Monthly subscription'
              : billing_cycle === Subscription.intervals.YEAR
              ? 'Yearly subscription'
              : '';

          return (
            <div
              key={stripe_id}
              className={`${styles.priceContainer} ${selected ? styles.selected : ''}`}
              onClick={() => setSelectedProduct({ ...product, selectedPrice: priceData })}
            >
              <h2>{newPrice}</h2>
              <h2 className={!!newPrice ? styles.strokedPrice : styles.normalPrice}>
                {originalPrice}
              </h2>

              <h4>{intervalLabel}</h4>

              <p>{product?.description}</p>
              <p>{description || product?.specification || ''}</p>
            </div>
          );
        })}
      </div>
    );
  });
};

export default ProductsList;

const getPriceText = ({ priceData, original }) => {
  const { isFree, price, currency, monthly_price, new_price } = priceData;
  const { price: m_price, currency: m_currency, new_price: m_new_price } = monthly_price || {};

  if (original) {
    if (isFree) return 'Free';
    if (m_price >= 0) return `${m_price} ${m_currency}`;
    return `${price} ${currency} `;
  }

  if (m_new_price >= 0) return `${m_new_price} ${m_currency} `;
  if (new_price >= 0) return `${new_price} ${currency} `;
  return '';
};

import React from 'react';
import RoleplayModalBody from './RoleplayModalBody';
import RoleplayModalBodyDetails from './RoleplayModalBodyDetails';
import { Grid } from '@material-ui/core';
import styles from './AssignRoleplay.module.scss';
const AssignRoleplayModalBody = () => {
  return (
    <div className={styles.dialogBody}>
      <Grid container>
        <Grid item xs={6} className="flexNotCentered">
          <div className={styles.leftContent}>
            <RoleplayModalBody />
          </div>
        </Grid>
        <Grid item xs={6} className="flexNotCentered">
          <div className={styles.rightContent}>
            <RoleplayModalBodyDetails />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssignRoleplayModalBody;

import React from 'react';
import ErrorPaymentAlert from './ErrorPaymentAlert';
import PreviousUsersAlert from './PreviousUsersAlert';
import TrialPeriodAlert from './TrialPeriodAlert';

const AllPaymentAlerts = () => {
  return (
    <>
      <ErrorPaymentAlert />
      <PreviousUsersAlert />
      <TrialPeriodAlert />
    </>
  );
};

export default AllPaymentAlerts;

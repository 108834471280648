import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/';
import styles from './ReviewQualifiedRoleplay.module.css';
import { imageBasePath } from 'constants';
import RoleplayLabel from '../Common/RoleplayLabel';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const RoleplayHeader = ({ roleplay, selectedScenario }) => {
  const history = useHistory();
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);
  const showSecondAttempt = useRoleplayStore((state) => state?.showSecondAttempt);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);
  const handleBack = () => history.push('/roleplay');

  const index = useMemo(
    () => roleplay?.scenarios?.findIndex((r) => r?.uuid === selectedScenario?.uuid) + 1,
    [roleplay, selectedScenario]
  );

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <button onClick={handleBack}>
          <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
        </button>
        <p className="heading4">{roleplay?.name || ''}</p>
        <RoleplayLabel {...roleplay?.status} />
        {roleplay?.scenarios?.length > 1 && (
          <div className={styles.count}>
            <span className="heading6" s>
              <strong>
                {index}/{roleplay?.scenarios?.length}
              </strong>{' '}
              Scenarios
            </span>
          </div>
        )}
      </div>

      {selectedScenario?.response_retake !== null && (
        <div className={`${styles.listAttempts} heading6`}>
          <p
            className={secondAttempt ? '' : styles.selected}
            onClick={() => {
              hideSecondAttempt();
            }}
          >
            1 Attempt
          </p>
          <p
            className={secondAttempt ? styles.selected : ''}
            onClick={() => {
              showSecondAttempt();
            }}
            p
          >
            2 Attempt
          </p>
        </div>
      )}
    </div>
  );
};

export default RoleplayHeader;

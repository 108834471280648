import React from 'react';
import { components } from 'react-select';
import styles from './AddEditStageForm.module.scss';
import { InputSelect, InputText } from 'shared_ui_components';
import { useFunnelProvider } from 'features/funnel/FunnelProvider';
import classNames from 'classnames';
import { imageBasePath } from 'constants';

const AddEditStageForm = () => {
  const {
    isEditing,
    nameError,
    nameValue,
    goalsError,
    goalsValue,
    goalsOptions,
    onNameChange,
    cancelEdit,
    onUpdateStage,
    onAddNewStage,
    onGoalsChange,
    resetGoalsError,
  } = useFunnelProvider();

  const onSubmit = (e) => {
    e.preventDefault();
    isEditing ? onUpdateStage() : onAddNewStage();
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputText
          value={nameValue}
          onChange={(e) => onNameChange({ name: e?.target?.value })}
          label={isEditing ? 'Stage name' : 'New stage name'}
          placeholder='E.g. "Leads"'
          error={nameError ? 'Please enter a name for the stage.' : ''}
          className={classNames([nameError && 'errorGroup'])}
        />

        <div className={classNames([goalsError && styles.error])} onClick={resetGoalsError}>
          <InputSelect
            isMulti
            isClearable
            closeMenuOnSelect={true}
            options={goalsOptions}
            label="Sales activity goals"
            placeholder="Select the sales goals of the stage"
            noOptionsMessage={() => 'No more sales goals'}
            onChange={onGoalsChange}
            value={goalsValue}
            error={goalsError ? 'Please select at least one goal for this stage.' : ''}
            menuPosition="fixed"
            components={{ Option: CustomOption }}
          />
        </div>

        <div className={styles.buttonsContainer}>
          {isEditing && (
            <button className={styles.secondary} onClick={cancelEdit}>
              Discard changes
            </button>
          )}
          <button
            className={classNames([styles.primary, !isEditing && styles.addBtn])}
            type="submit"
          >
            {isEditing ? 'Save changes' : '+ Add stage'}
          </button>
        </div>
      </form>
    </>
  );
};

const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <components.Option {...rest}>
        <div className={styles.opt}>
          <span>{rest?.data?.label}</span>
          {rest?.data?.isFromHubspot && (
            <img alt="hubspot" src={imageBasePath + 'hubspot_logo.svg'} />
          )}
        </div>
      </components.Option>
    </div>
  );
};

export default AddEditStageForm;

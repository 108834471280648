import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from './ListPaymentMethods.module.scss';
import ListContent from './ListContent';
const ListPaymentMethods = ({ cardInfo = {}, payments = {} }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button variant="outlined" onClick={() => setShowModal(true)} className={styles.listPayments}>
        Manage payment methods
      </button>
      <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth={true} maxWidth="md">
        <DialogContent style={{ padding: 0 }}>
          <ListContent setShowModal={setShowModal} cardInfo={cardInfo} payments={payments} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListPaymentMethods;

import React from 'react';
import styles from './ListPaymentMethods.module.scss';
import { imageBasePath } from 'constants';
import AddPaymentModal from '../../PaymentButtons/AddPaymentModal';
import Card from 'assets/Card.svg';
import EditBillingInfoModal from '../EditBillingInfo/EditBillingInfoModal';
import EditPaymentDetailsModal from '../EditPaymentDetails/EditPaymentDetailsModal';
import DefaultPayment from '../DefaultPayment/DefaultPayment';

const ListContent = ({ setShowModal = () => null, cardInfo = {}, payments = {} }) => {
  return (
    <div style={{ minHeight: '28rem' }}>
      <div className={styles.listHeader}>
        <span className={styles.titleText}>Manage Payment Methods</span>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className={styles.cancelCloseIcon}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className={styles.listBody}>
        <div className={styles.payments}>
          <div className={styles.payment}>
            <div className={styles.paymentInfo}>
              <div className={styles.paymentInfoFirst}>
                <img className={styles.icon} onClick={null} src={Card} alt="Card icon" />
                <span>CARD ending in {cardInfo?.card?.last4} </span>
              </div>
              <span className={styles.paymentInfoSecond}>
                Expiry {cardInfo?.card?.exp_month}/{cardInfo?.card?.exp_year}
              </span>
            </div>
            <div className={styles.paymentActions}>
              <EditBillingInfoModal cardInfo={cardInfo} />
              <EditPaymentDetailsModal cardInfo={cardInfo} isDefault={true} />
              <DefaultPayment cardId={cardInfo?.id} isDefault={true} />
            </div>
          </div>
          <div className={styles.paymentNoDefault}>
            <div className={styles.scrollbar}>
              {payments?.map((cardInfo) => (
                <>
                  {cardInfo?.default_payment_method !== 1 && (
                    <div className={styles.payment}>
                      <div className={styles.paymentInfo}>
                        <div className={styles.paymentInfoFirst}>
                          <img className={styles.icon} onClick={null} src={Card} alt="Card icon" />
                          <span>CARD ending in {cardInfo?.card?.last4} </span>
                        </div>
                        <span className={styles.paymentInfoSecond}>
                          Expiry {cardInfo?.card?.exp_month}/{cardInfo?.card?.exp_year}
                        </span>
                      </div>
                      <div className={styles.paymentActions}>
                        <EditBillingInfoModal cardInfo={cardInfo} />
                        <EditPaymentDetailsModal cardInfo={cardInfo} />
                        <DefaultPayment cardId={cardInfo?.id} />
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.bodyButtons}>
          <button onClick={() => setShowModal(false)} className={styles.close}>
            Close
          </button>
          <AddPaymentModal />
        </div>
      </div>
    </div>
  );
};

export default ListContent;

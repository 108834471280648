import { create } from 'zustand';

export const useSmartMentorStore = create((set, get) => ({
  messages: JSON.parse(localStorage.getItem('SM_CONVERSATION')) || [],
  resetConversation: () => {
    localStorage.removeItem('SM_CONVERSATION');
    return set(() => ({ messages: [] }));
  },
  addMessage: (message, from) => {
    const body = [...get()?.messages, { message, from: from }];
    localStorage.setItem('SM_CONVERSATION', JSON.stringify(body));
    return set(() => ({
      messages: body,
    }));
  },
}));

import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorMessage(props) {
  const error = props;
  return (
    <h6 className={error.errorMsg ? 'errorMsg' : ''}>{error.errorMsg ? error.errorMsg : ''}</h6>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: '',
};

import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const LoadMorePosts = ({ loadMore = () => null, showLoader }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (!showLoader) return null;

  return (
    <div ref={ref}>
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '0.5rem' }}>
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="text" width={120} height={40} />
      </div>
      <Skeleton variant="rect" height={90} />
    </div>
  );
};

export default LoadMorePosts;

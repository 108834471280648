import { useGetProvidersQuery, useValidateKeyMutation } from 'features/providers/Providers.queries';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SuccessModal from '../SuccessModal/SuccessModal';

const RedirectionHandler = () => {
  // Redirection handlers
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);

  // Detects if we come from a redirection
  const [providerID, setProviderID] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const code = queryParams.get('code');
    if (!code) return;

    const state = queryParams.get('state');

    try {
      const data = JSON.parse(state);
      if (data?.providerID) setProviderID(data.providerID);
      handleCode(code, data.providerID);
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Send code to verify provider
  const { mutate } = useValidateKeyMutation();
  const handleCode = async (code, id) => {
    mutate(
      { code, id },
      {
        onSuccess: () => setOpenDialog(true),
      }
    );
  };

  // Retrieve provider data
  const { data = {} } = useGetProvidersQuery();

  const providerData = useMemo(() => {
    if (!data?.providers?.length > 0 || !providerID) return null;

    const provider = data?.providers?.find((p) => p?.id === parseInt(providerID));
    if (provider) return provider;

    return null;
  }, [providerID, data?.providers]);

  if (openDialog && providerData)
    return <SuccessModal onClose={() => setOpenDialog(false)} data={providerData} />;
  return null;
};

export default RedirectionHandler;

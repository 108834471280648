import React, { useState } from 'react';

import styles from '../../Payment.module.scss';
import { imageBasePath } from '../../../../../constants';
import { usePaymentStore } from '../../../Payment.store';

const headerTitles = {
  introduction: 'Your order',
  billing: 'Add your billing details',
  address: 'Add your billing address',
  payment: 'Add your payment details',
  summary: 'Complete your order',
};

// Changes according to page key
const PaymentHeader = () => {
  const current_page = usePaymentStore((state) => state?.currentStep);

  const companyIcon = JSON.parse(localStorage.getItem('userInfo')).selectedOrg?.logo;

  const [src, setSrc] = useState(companyIcon);

  return (
    <div className={styles.formHeader}>
      {['introduction', 'summary'].includes(current_page) && (
        <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="Salestable" />
      )}

      {['billing', 'address', 'payment'].includes(current_page) && src && (
        <img
          className={styles.companyLogo}
          src={src}
          onError={() => setSrc(null)}
          alt="Company logo"
        />
      )}

      {headerTitles[current_page]}
    </div>
  );
};

export default PaymentHeader;

import React, { useState, useRef } from 'react';
import {
  Button,
  Grow,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Avatar,
} from '@material-ui/core';
import { imageBasePath } from '../constants';
import { withRouter } from 'react-router-dom';
import LogOut from './LogOutDialog';
import ProfileDrawer from './ProfileDrawer';
import { toastFlashMessage } from '../utils';
import RepPovSwitch from 'features/salesRepView/components/RepPovSwitch/RepPovSwitch';

function ProfileDropdown(props) {
  const [open, setOpen] = useState(false);
  const [openLogPop, setLogPop] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    props.history.push('/signin');
    toastFlashMessage(`You've Logged out`, 'success');
  };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).user
    : '';
  const org = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).selectedOrg
    : '';

  return (
    <div className="profileWrapper">
      <>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className=""
        >
          {userInfo && userInfo.profile_pic ? (
            <img className="profilePic" src={userInfo.profile_pic} alt="Profile pic" />
          ) : (
            <Avatar className="avatar">
              {userInfo && userInfo.first_name
                ? userInfo.first_name.substring(0, 1)
                : userInfo && userInfo.email
                ? userInfo.email.substring(0, 1)
                : ''}
            </Avatar>
          )}
          <img className="caret" src={imageBasePath + 'caret_down.svg'} alt="img" />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className="profileDropdown"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {org.name ? (
                      <MenuItem className="menuItem supportText orgItem">
                        <p>
                          {org.logo ? (
                            <img src={org.logo} alt="Organization" />
                          ) : (
                            <img
                              src={imageBasePath + 'upload_company_logo.png'}
                              alt="Upload company Logo"
                            />
                          )}
                          <span className="heading6">{org.name}</span>
                        </p>
                      </MenuItem>
                    ) : null}
                    <RepPovSwitch />
                    {userInfo ? (
                      <MenuItem
                        onClick={() => setOpenProfile(true)}
                        className="menuItem supportText logOut"
                      >
                        Profile
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      onClick={() => setLogPop(true)}
                      className="menuItem supportText logOut"
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {openLogPop ? (
          <LogOut
            text="Are you sure you want to logout?"
            handleSuccess={logout}
            handleCancel={setLogPop}
          />
        ) : null}
        {<ProfileDrawer handleClose={() => setOpenProfile(false)} open={openProfile} />}
      </>
    </div>
  );
}

export default withRouter(ProfileDropdown);

import Onboarding from './containers/Onboarding';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const onboardingRoutes = [
  {
    path: '/onboarding',
    component: pageLayoutHoc(Onboarding),
    key: 'onboarding',
  },
  {
    path: '/uploadpreview',
    component: pageLayoutHoc(Onboarding),
    key: 'uploadpreview',
  },
  {
    path: '/dashboard',
    component: pageLayoutHoc(Onboarding),
    key: 'dashboard',
  },
  {
    path: '/setupcomplete',
    component: pageLayoutHoc(Onboarding),
    key: 'setupcomplete',
  },
];

import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/home.module.scss';
import { Grid } from '@material-ui/core';
import { ProgressBar } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

export default function ModuleInit(props) {
  const { moduleData, disabled = false, type } = props;
  return (
    <div className={`${styles.moduleInitWrapper} ${disabled ? 'disabled' : ''}`}>
      <Grid container spacing={0} style={{ height: `100%` }}>
        <Grid item xs={12} md={6}>
          <img src={moduleData.featuredImg} className={styles.featuredImg} alt="icon" />
        </Grid>
        <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
          <div className={styles.contentWrapper}>
            <h3 className={styles.title + ' heading4'}>
              <img src={moduleData.icon} alt="icon" />
              <span>{moduleData.title}</span>
            </h3>
            <p className={`paragraph ${styles.description}`}>{moduleData.description}</p>
            {moduleData.progress &&
            moduleData.progress?.completed &&
            moduleData.progress?.completed < moduleData.progress?.total &&
            !disabled ? (
              <div className={styles.progressInfoWrapper + ' flexCentered'}>
                <div className={styles.progressValue}>
                  <p className="supportTextSemibold">
                    {`${moduleData.progress?.completed}/${moduleData.progress?.total}`}{' '}
                    <span>{type == `training` ? `Modules completed` : `Steps`}</span>
                  </p>
                </div>
                <div
                  className={`${styles.progressBarBlk} ${
                    type == `training` ? styles.training : ''
                  }`}
                >
                  <ProgressBar
                    className={`${styles.progressBar} progressBar`}
                    value={(moduleData.progress?.completed / moduleData.progress?.total) * 100}
                    max="100"
                  />
                </div>
              </div>
            ) : null}
            {disabled ? null : moduleData.progress?.completed &&
              moduleData.progress?.completed == moduleData.progress?.total ? (
              <div
                className={styles.completedAlert}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div>
                  <img src={`${imageBasePath}success_icon.svg`} alt="icon" />
                  <div className={styles.message}>
                    <p className="supportTextSemibold">
                      Great!! <br />
                      you've completed the {moduleData.title?.toLowerCase()}{' '}
                    </p>
                  </div>
                </div>
                <p className={styles.btnLink}>
                  <Link to={`${moduleData.link}`} className={`primaryBtn`}>
                    {`${`Go To`} ${moduleData.title}`}
                  </Link>
                </p>
              </div>
            ) : (
              <p className={styles.btnLink}>
                <Link to={`${moduleData.link}`} className={`primaryBtn`}>{`${
                  moduleData.progress?.completed ? `Continue` : `Start`
                } ${moduleData.title}`}</Link>
              </p>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

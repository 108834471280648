import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/signIn.module.scss';
import { imageBasePath } from 'constants';

const SignInCarousel = () => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    const pages = 3;
    const timeID = setInterval(() => {
      setPage((prev) => (prev + 1 > pages ? 1 : prev + 1));
    }, 4000);

    return function clean() {
      clearInterval(timeID);
    };
  }, [page]);

  const PageIndicator = () => {
    return (
      <div className={styles.pagination}>
        <span onClick={() => setPage(1)} className={page === 1 ? styles.active : ''}></span>
        <span onClick={() => setPage(2)} className={page === 2 ? styles.active : ''}></span>
        <span onClick={() => setPage(3)} className={page === 3 ? styles.active : ''}></span>
      </div>
    );
  };

  const Quote = ({ quote, stars = 5, name, role }) => {
    return (
      <div className={styles.testimony}>
        <img src={`${imageBasePath}quote_symbol.svg`} className={styles.quoteSymbol} alt="" />
        <p className={styles.quote}>{quote}</p>
        <span className={styles.stars}>
          {[...new Array(stars)].map((x, i) => (
            <img key={i} height={16} src={`${imageBasePath}star_icon.svg`} alt="★" />
          ))}
        </span>
        <p className={styles.name}>{name}</p>
        <p className={styles.role}>{role}</p>
      </div>
    );
  };

  return (
    <div className={styles.carouselContainer}>
      {page === 2 && (
        <img
          src={`${imageBasePath}login_background.jpg`}
          className={styles.backgroundTestimony}
          alt=""
        />
      )}
      <img className={styles.backImage} alt="" src={imageBasePath + 'logo_single.png'} />
      <div className={styles.page}>
        {page === 1 && <h1 className={styles.faded}>{title1}</h1>}
        {page === 2 && <h1 className={styles.faded}>{title2}</h1>}
        {page === 3 && <h1 className={styles.faded}>{title3}</h1>}

        {page === 1 && (
          <div className={`${styles.grid} ${styles.faded}`}>
            {logos.map((l, id) => (
              <div key={id} title={l?.name}>
                <img src={l?.src} alt={l?.name} />
              </div>
            ))}
          </div>
        )}
        {page === 2 && (
          <div className={`${styles.testimonials} ${styles.faded}`}>
            <Quote
              name="Rob L"
              role="Director, Sales Operations @ HiTech Plastics & Molds"
              quote="With Salestable, I can ensure that our sales team is equipped with in-depth knowledge of the various aspects of plastic injection molding required to be an effective sales professional"
            />
            <Quote
              name="Wendy L"
              role="Co-founder - ContentBacon"
              quote="Salestable has been a great partner for ContentBacon. We've gone from being a company where the founders are driving the sales to an organization with an effective sales team that is growing and thriving"
            />
          </div>
        )}
        {page === 3 && (
          <img
            className={`${styles.previewImage} ${styles.faded}`}
            alt="Salestable preview"
            style={{ textAlign: 'center' }}
            src={imageBasePath + 'preview.png'}
          />
        )}
        <PageIndicator />
      </div>
    </div>
  );
};

export default SignInCarousel;

const logos = [
  {
    name: 'Allegro learning solutions',
    src: `${imageBasePath}allegro.jpeg`,
  },
  {
    name: 'Fyrii',
    src: `${imageBasePath}fyrii.png`,
  },
  {
    name: 'Content bacon',
    src: `${imageBasePath}content_bacon.webp`,
  },
  {
    name: 'Skills union',
    src: `${imageBasePath}skills_union.png`,
  },
  {
    name: 'Novacy',
    src: `${imageBasePath}novacy.jpg`,
  },
  {
    name: 'Inspiration digital',
    src: `${imageBasePath}inspiration.jpg`,
  },
  {
    name: 'Sun realty',
    src: `${imageBasePath}sun_realty.jpeg`,
  },
  {
    name: 'HiTech plastics & molds',
    src: `${imageBasePath}hitech.jpg`,
  },
  {
    name: 'Metagrowth ventures',
    src: `${imageBasePath}metagrowth.png`,
  },
];

const title1 = 'Growing companies rely on Salestable';
const title2 = 'What Our Clients Say';
const title3 =
  'Boost your revenue through accelerated onboarding, ongoing coaching, and seamless collaboration within your internal sales team';

import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from '../../Payment.module.scss';
import CancelForm from './CancelForm';
import { Subscription, FeatureFlag } from 'controllers/_exports';
import { TooltipCustom } from 'utils';

const CancelSubscriptionModal = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [showModal, setShowModal] = useState(false);
  const [cancelActive, setCancelActive] = useState(userInfo.selectedOrg.is_cancel_sub_sent);
  const onCanceled = () => setCancelActive(true);

  const show = useMemo(
    () => !Subscription.isStarter() && FeatureFlag.enabled('CANCEL_SUBSCRIPTION'),
    []
  );

  if (!show) return null;

  return (
    <>
      {!cancelActive ? (
        <div onClick={() => setShowModal(true)} className={styles.cancelText}>
          Cancel my subscription
        </div>
      ) : (
        <TooltipCustom
          placement="top"
          arrow
          title={
            <div className="supportText" style={{ color: `#fff` }}>
              Cancellation request already sent, Salestable team will revert soon
            </div>
          }
        >
          <div className={styles.cancelTextDisabled}>Cancel my subscription</div>
        </TooltipCustom>
      )}
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent className={styles.CancelDialog}>
          <CancelForm setShowModal={setShowModal} onCanceled={onCanceled} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CancelSubscriptionModal;

import React from 'react';
import styles from './Header.module.scss';
import { imageBasePath } from 'constants';

const Header = ({ handleOpen = () => null }) => {
  return (
    <div className={styles.smartMentorHeader}>
      <h2 className={styles.headerTitle}>Smart mentor</h2>
      <div className={styles.headerActions}>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className={styles.modalCloseIcon}
          onClick={handleOpen}
        />
      </div>
    </div>
  );
};

export default Header;

import { createContext, useState, useReducer } from 'react';
import { produce } from 'immer';
import { saveStore } from '../store/GlobalStore';

export const GlobalContext = createContext(); // central context store

const initialState = {
  toastLists: [],
  updateUsersListingFlag: false,
};
function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_TOAST':
      if (action.payload.toastType === 'error') {
        if (state.toastLists.filter((toast) => toast.toastType === 'error').length < 1) {
          return {
            ...state,
            toastLists: produce(state.toastLists, (draft) => {
              draft.push(action.payload);
            }),
          };
        } else {
          return state;
        }
      } else {
        return {
          ...state,
          toastLists: produce(state.toastLists, (draft) => {
            draft.push(action.payload);
          }),
        };
      }
    case 'CLEAR_TOAST':
      return {
        ...state,
        toastLists: [],
      };
    case 'HIDE_TOAST':
      if (state.toastLists) {
        return {
          ...state,
          toastLists: [],
        };
      } else {
        return state;
      }
    case 'SET_ROLE':
      return {
        ...state,
        role: action.payload,
      };
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'UPDATE_USERS_LISTING': {
      return {
        ...state,
        updateUsersListingFlag: action.payload,
      };
    }
    case 'NOTOFICATION_COUNT': {
      return {
        ...state,
        notificationCount: action.payload,
      };
    }
    default:
      return state;
  }
}
const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { Provider } = GlobalContext;
  saveStore({ state, dispatch });
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
export default GlobalContextProvider;

import ChatAssist from './containers/ChatAssist';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';

export const chatAssistRoutes = [
  {
    path: '/chatAssist',
    component: pageLayoutHoc(ChatAssist),
    key: 'chat',
  },
];

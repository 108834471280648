import React, { useMemo } from 'react';
import styles from './LearningPathInProgress.module.scss';
import { format, parseISO } from 'date-fns';
import { imageBasePath } from 'constants';
import { Doughnut } from 'react-chartjs-2';
import { useGetLearningPathInProgressQuery } from '../../LearningPaths.queries';
import { Grid } from '@material-ui/core';
import { useLearningPathsStore } from '../../LearningPaths.store';
import DeadlineBubble from '../common/DeadlineChip';
import { TooltipCustom } from 'utils';

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  cutout: '80%',
  radius: '90%',
};
const LearningPathInProgress = () => {
  const { data, isLoading } = useGetLearningPathInProgressQuery();
  const { setSelectedRepLearningPathID } = useLearningPathsStore();
  const learningPath = useMemo(() => {
    return data?.learning_path;
  }, [data]);
  const chartData = useMemo(
    () => ({
      labels: ['Overall completion', 'No completed'],
      datasets: [
        {
          label: '',
          data: [data?.overall_completion || 0, 100 - data?.overall_completion],
          borderWidth: 1,
          backgroundColor: ['#0494FD', '#CFD8DC'],
          borderColor: ['#0494FD', '#CFD8DC'],
          hoverBorderColor: ['#0494FD', '#CFD8DC'],
          hoverBackgroundColor: ['#0494FD', '#CFD8DC'],
          hoverBorderWidth: 0,
          hoverBorderRadius: 0,
          hoverOffset: 0,
        },
      ],
    }),
    [data?.overall_completion]
  );
  if (isLoading) return null;

  return (
    <div className={styles.container}>
      <TooltipCustom
        placement="bottom"
        title={
          <p>Overall Completion percentage is displayed based on your previous training progress</p>
        }
      >
        <div
          className={styles.main}
          onClick={() => setSelectedRepLearningPathID(learningPath?.uuid)}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ height: '180px', position: 'relative', maxWidth: '200px' }}
          >
            <div className={styles.totalContainer}>
              <h6 className="heading1">{data?.overall_completion}%</h6>
              <p className="paragraph">Overall</p>
              <p className="paragraph">Completion</p>
            </div>
            <Doughnut data={chartData} options={options} />
          </Grid>
          <div className={styles.mainText}>
            <div className={styles.headerText}>
              <div className={styles.headerTextTitle}>{learningPath?.title}</div>
              <div className={styles.headerTextAuthor}>
                By {`${learningPath?.created_by.first_name} ${learningPath?.created_by.last_name}`}
              </div>
            </div>
            <div className={styles.description}>
              {learningPath?.description.slice(0, 250)}
              {learningPath?.description.length >= 250 ? '...' : ''}
            </div>
            <div className={styles.bubblesContainer}>
              {learningPath?.updated_at && (
                <div>
                  <img src={`${imageBasePath}UpdatedActive.svg`} alt="icon" />
                  <strong>
                    {format(parseISO(learningPath?.updated_at), 'dd MMM. yyyy')}{' '}
                  </strong>{' '}
                  Updated
                </div>
              )}
              <div>
                <img src={`${imageBasePath}contentActiveDesign.svg`} alt="icon" />
                <strong>{learningPath?.total_contents}</strong> Modules
              </div>
            </div>
            <DeadlineBubble deadline={learningPath?.deadline} />
          </div>
        </div>
      </TooltipCustom>

      <div className={`${styles.deadline}`}>
        <img src={`${imageBasePath}calendarActive.svg`} alt="calendar" className={styles.icon} />
        <div>
          {data?.days_until_deadline > 0 ? (
            <>
              <div className={styles.numberRed}>{data?.days_until_deadline}</div>
              <div className={styles.numberText}>
                {data?.days_until_deadline * -1 === 1 ? 'Day' : 'Days'} exceeded
              </div>
            </>
          ) : (
            <>
              <div className={styles.number}>{data?.days_until_deadline * -1}</div>
              <div className={styles.numberText}>
                {data?.days_until_deadline * -1 === 1 ? 'Day' : 'Days'} until deadline
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className={`${styles.completion}`}>
        <img src={`${imageBasePath}trophyActive.svg`} alt="trophy" className={styles.icon} />
        <div>
          <div className={styles.number}>{data?.modules_completion}%</div>
          <div className={styles.numberText}>Module Completion</div>
        </div>
      </div> */}
    </div>
  );
};

export default LearningPathInProgress;

import React, { useContext, useMemo } from 'react';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '../../../../context/GlobalContext';
import { Subscription } from 'controllers/_exports';

const PreviousUsersAlert = () => {
  const history = useHistory();

  const { state: globalState } = useContext(GlobalContext);
  const isOwner = ['primary_admin'].includes(globalState?.role?.slug);
  const handleAlertClick = () => isOwner && history.push('/subscription');

  const text = useMemo(() => {
    const d1 = new Date();
    const d2 = new Date('2023-07-15');
    const showNewText = d1.getTime() >= d2.getTime();

    if (showNewText)
      return 'Your subscription will change to the "starter" plan on August 1, to avoid interruptions, please register on the billing page and upgrade your plan.';
    return 'You are currently enrolled in our beta program, which will expire on June 30th, 2023. To ensure uninterrupted access and continue enjoying all the benefits of Salestable, renew your subscription by visiting the "Billing" page.';
  }, []);

  const showByDate = useMemo(() => {
    const d1 = new Date();
    const d2 = new Date('2023-08-01');
    return d1.getTime() < d2.getTime();
  }, []);

  return Subscription.isBeta() && showByDate ? (
    <Alert
      style={{
        top: '64px',
        zIndex: '200',
        cursor: isOwner ? 'pointer' : '',
        position: '',
      }}
      severity={'info'}
      onClick={handleAlertClick}
    >
      {text}
    </Alert>
  ) : null;
};

export default PreviousUsersAlert;

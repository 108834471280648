import React from 'react';
import styles from './MessageInput.module.scss';
import send from 'assets/send.svg';
const MessageInput = ({
  setMessage = () => null,
  message = '',
  isLoading = false,
  handleSend = () => null,
}) => {
  const handleSend_ = (e) =>{
    e.preventDefault()
    handleSend()
  }
  return (
    <form className={styles.contentInut} onSubmit={handleSend_}>
      <input
        placeholder="Send a message!"
        className={styles.commentInput}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <img
        src={send}
        alt="send icon"
        className={styles.iconSend}
        onClick={isLoading ? null : handleSend}
      />
    </form>
  );
};

export default MessageInput;

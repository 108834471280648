import { FormControl } from '@material-ui/core';
import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

const ModuleFilterSelector = ({ filter, handleFilterChange }) => {
  const opts = useMemo(
    () => [
      {
        label: 'All Modules',
        value: 'all',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Disabled',
        value: 'disabled',
      },
      {
        label: 'Published',
        value: 'published',
      },
    ],
    []
  );

  return (
    <FormControl className="formControl" style={{ width: 'fit-content', padding: '0 1rem' }}>
      <ReactSelect
        unstyled
        closeMenuOnSelect={true}
        options={opts}
        placeholder={opts?.find((o) => o?.value === filter)?.label}
        onChange={handleFilterChange}
        isSearchable={false}
        styles={{
          control: () => ({
            width: '120px',
            textAlign: 'start',
            background: '#0494fd',
            padding: '0 1rem',
            height: 'calc(2rem - 1px)',
            minHeight: '0',
            borderRadius: '5px',
            color: 'white',
            fontWeight: 600,
            cursor: 'pointer',
            display: 'flex',
            '&:hover': {
              background: '#0081de',
            },
          }),
          option: (base, state) => ({
            ...base,
            textAlign: 'start',
            padding: '0.35rem',
            paddingLeft: '1rem',
            cursor: 'pointer',
            color: '#415766',
            '&:hover': {
              background: '#E7F0F5',
            },
            background: state.isSelected ? '#E7F0F5' : 'white',
          }),

          menuList: () => ({ margin: '0.5rem 0' }),
        }}
        defaultValue={opts[1]}
      />
    </FormControl>
  );
};

export default ModuleFilterSelector;

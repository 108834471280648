import React, { useEffect, useMemo, useState } from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';
import FunnelLabels from './FunnelLabels';
import FunnelTooltip from './FunnelTooltip';
import FunnelSeparators from './FunnelSeparators';
import Loading from 'shared_ui_components/Loading';
import styles from './FunnelGraph.module.scss';
import { imageBasePath } from 'constants';
import { useFunnelProvider } from 'features/funnel/FunnelProvider';

const transform = (number) =>
  number > 150 ? 150 : number < 30 ? 30 - Math.abs(number - 16) : number;

const FunnelGraph = ({ data = [], isLoading, isFetching }) => {
  const { timeLapse } = useFunnelProvider();
  const formattedData = useMemo(() => {
    if (!data || data?.length === 0) return [];
    return data?.map((stage) => {
      const { funnel_stage_data, name = '', uuid } = stage;
      return {
        id: uuid,
        label: name,
        value: transform(funnel_stage_data?.percentage_value),
        percentage: funnel_stage_data?.percentage_value,
        count: funnel_stage_data?.total_users_target,
      };
    });
  }, [data]);

  // Force a re-render to refresh graph SV
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    setChanged(true);
    setTimeout(() => setChanged(false), 1);
  }, [timeLapse]);

  if (isLoading || (data?.length === 0 && isFetching))
    return (
      <div className={styles.loadingContainer}>
        <Loading loading circular />
      </div>
    );
  if (data?.length === 0)
    return (
      <div className={styles.emptyContainer}>
        <img alt="" src={imageBasePath + 'empty_funnel.svg'} />
        <h1>"Oops! No funnel stages here.</h1>
        <p>Time to add some stages and turn this empty funnel into a productivity machine!"</p>
      </div>
    );
  if (changed) return <div style={{ height: '300px' }} />;

  return (
    <div style={{ height: '300px' }}>
      <ResponsiveFunnel
        data={formattedData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        direction="horizontal"
        interpolation="linear"
        shapeBlending={0}
        valueFormat=">-.4s"
        borderWidth={10}
        borderColor="#ffffff00"
        labelColor="#000000"
        beforeSeparatorLength={80}
        enableAfterSeparators={false}
        afterSeparatorLength={100}
        currentPartSizeExtension={6}
        currentBorderWidth={10}
        motionConfig="gentle"
        layers={['separators', 'parts', FunnelLabels, FunnelSeparators]}
        colors={['#e3f3fe', '#c8e6fc', '#acdafb']}
        tooltip={FunnelTooltip}
      />
    </div>
  );
};

export default FunnelGraph;

import React from 'react';
import { Grid } from '@material-ui/core';
import { DashboardHeader } from 'shared_elements';
import RoleplaysDashboardsList from '../RoleplaysDashboardList/RoleplaysDashboardsList';
import RoleplayProgress from '../RoleplayProgress/RoleplayProgress';
import History from '../History/History';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import AsignRoleplay from '../AsignRoleplay/AsignRoleplay';
import MyTeam from '../MyTeam/MyTeam';
import logo from 'assets/roleplay/roleplay_logo.svg';
import DateTime from 'infrastructure/Datetime';

const RoleplayContainer = () => {
  const close = useRoleplayStore((state) => state?.closeAssignModal);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const handleClose = () => {
    setFormDataAssignRoleplay({
      scenarios: [],
      deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    })
    close();
  }
  const assignModal = useRoleplayStore((state) => state?.assignModalOpen);

  return (
    <>
      <DashboardHeader dashboardLogo={logo} title="Roleplay" isRoleplayPage={true} />
      {assignModal && <AsignRoleplay open={assignModal} handleClose={handleClose} />}
      <RoleplaysDashboardsList />
      <Grid container spacing={4} style={{ margin: '2rem -16px -8px -16px' }}>
        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
          <RoleplayProgress />
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}></Grid>
      </Grid>
      <History />
      <MyTeam />
    </>
  );
};

export default RoleplayContainer;

import React from 'react';
import RoleplayContainer from '../Components/RoleplayContainer/RoleplayContainer';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import { Redirect } from 'react-router-dom';

const Roleplay = () => {
  if (
    !FeatureFlag.enabled('ROLEPLAY') ||
    !Subscription.allow({ key: Subscription.keys.roleplay, openModal: false })
  )
    return <Redirect to="/" />;
  return (
    <div style={{ padding: '1.5rem', position: 'relative' }}>
      <RoleplayContainer />
    </div>
  );
};

export default Roleplay;

import { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, List, ListItem, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { CustomButton, CustomTooltipWhite, ProgressBar } from '../../../shared_ui_components';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { publishModuleApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import styles from '../../../assets/styles/training.module.scss';
import { ConfirmationDialog } from '../../../shared_elements';
import { Shimmer } from '../components';
import { useDrag, useDrop } from 'react-dnd';
import { isReadOnly } from 'controllers/Subscriptions/ReadOnlyWrapper/ReadOnlyWrapper';
import Recurring from 'assets/icons/Recurring.svg';
import { FeatureFlag } from 'controllers/_exports';
import User from 'infrastructure/User';
import DateTime from 'infrastructure/Datetime';
import StatusLabel from 'shared_elements/StatusLabel/StatusLabel';

export default function ModuleCardDnD(props) {
  const ref = useRef(null);
  let location = useLocation();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    index,
    module,
    role,
    modules,
    moveCard,
    id,
    reorderedIds,
    handleUpdateModulesOrder,
    allowDrag,
  } = props;
  const [loader, setLoader] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const readOnly = useMemo(() => isReadOnly(module), [module]);

  const handleClick = (event, key) => {
    if (readOnly) return;
    setAnchorEl(event.currentTarget);
  };
  const handlePublishModule = () => {
    setLoader(true);
    publishModuleApi(module?.uuid).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        props.fetchTrainingModulesList();
        props.handlePublishSuccess(module);
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const canUserPerformAction = (uuid) => {
    if (readOnly) return;
    const loggedInUser = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).user
      : '';
    if (
      (loggedInUser && loggedInUser?.uuid === uuid) ||
      ['admin', 'primary_admin'].includes(role)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleClickAddContent = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (readOnly) return;
    history.push({
      pathname: `/training/${module?.uuid}`,
      state: {
        addContent: true,
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };
  const verifyModuleStatus = useMemo(() => {
    const predefinedEnabledModulesListCount = modules?.filter(
      (mod) => mod.status === 1 && mod.is_predefined
    )?.length;
    if (module.status === 1 && module.is_predefined && predefinedEnabledModulesListCount <= 1) {
      return false;
    } else {
      return true;
    }
  }, [module, modules]);

  const [{ handlerId, hovered, hoverId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        hoverId: monitor.isOver() ? id : '',
        handlerId: monitor.getHandlerId(),
        hovered: monitor.isOver(),
      };
    },
    drop(item, monitor) {
      const drop_result = monitor.getDropResult();
      if (isReadOnly(module)) return;
      if (handleUpdateModulesOrder) {
        handleUpdateModulesOrder(reorderedIds);
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging, dragId }, drag, dragPreview] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    canDrag: allowDrag,
    collect: (monitor) => ({
      dragId: monitor.isDragging() ? id : '',
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div data-handler-id={handlerId} ref={ref}>
      <div
        className={`${styles.cardWrapper} ${styles.adminCardWrapper}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (readOnly) return;
          history.push({
            pathname: `/training/${module?.uuid}`,
            state: {
              prevPath: `${location.pathname}${location.search ? location.search : ''}`,
            },
          });
        }}
        style={{ opacity: isDragging ? 0 : 1 }}
      >
        <div>
          <div
            className={styles.topBlk}
            style={module.published_at && module.status ? { background: module.color_code } : {}}
          >
            {allowDrag && (
              <span
                className={styles.dragElement}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <div className={styles.content}>
              <div style={{ display: 'flex', height: 'auto', gap: '0.5rem' }}>
                {module.icon && module.status && module.published_at ? (
                  <img
                    src={module.icon}
                    className={`${styles.featuredImg} featuredImg`}
                    alt="icon"
                  />
                ) : (
                  <svg
                    className={`${styles.featuredImg} featuredImg`}
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="17" cy="17" r="17" fill="#0494FD" />
                    <path
                      d="M10.9987 23C10.8219 23 10.6523 22.9298 10.5273 22.8047C10.4023 22.6797 10.332 22.5101 10.332 22.3333V11.6667C10.332 11.4899 10.4023 11.3203 10.5273 11.1953C10.6523 11.0702 10.8219 11 10.9987 11H15.9414L17.2747 12.3333H22.332C22.5088 12.3333 22.6784 12.4036 22.8034 12.5286C22.9285 12.6536 22.9987 12.8232 22.9987 13V15H21.6654V13.6667H16.7227L15.3894 12.3333H11.6654V20.332L12.6654 16.3333H23.9987L22.4587 22.4953C22.4226 22.6395 22.3393 22.7675 22.2222 22.8589C22.105 22.9503 21.9607 23 21.812 23H10.9987ZM22.2907 17.6667H13.7067L12.7067 21.6667H21.2907L22.2907 17.6667Z"
                      fill="white"
                    />
                  </svg>
                )}
                <div
                  style={{
                    display: 'flex',
                    columnGap: '0.5rem',
                    marginBottom: '1.5rem',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <p className="heading3">{module.title}</p>
                  <p className="byLabel" style={{ background: '#F3F5F800', fontSize: '0.75rem' }}>
                    by {!module.is_predefined ? User.getUserName(module.created_by) : 'Salestable'}
                  </p>
                </div>
              </div>
              <p className="supportText" style={{ marginBottom: '1rem' }}>
                {module.description && module.description.length > 140
                  ? `${module.description.slice(0, 135)}...`
                  : module.description}
              </p>
              {['user'].includes(role) ? null : module.content_count ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      marginBottom: '0.75rem',
                      fontWeight: 300,
                      marginTop: 'auto',
                    }}
                  >
                    <img src={`${imageBasePath}calendarDeactiveDesign.svg`} />
                    {module?.published_at !== null ? (
                      <span>
                        Published on {DateTime.trainingCardDateFormat(module?.published_at)}
                      </span>
                    ) : (
                      <span>Not published</span>
                    )}
                  </div>
                  <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
                    {FeatureFlag.enabled('SHOW_RECURRING_CONTENT') &&
                    module?.total_contents_schedule > 0 ? (
                      <div className={styles.contentCountWrapperNew + ' supportTextSemibold'}>
                        <img src={`${imageBasePath}contentActiveDesign.svg`} />
                        {module.content_count}
                        <span
                          className={styles.contentCountWrapper + ' supportTextSemibold'}
                        >{`/ ${module?.total_contents_schedule} Rec. content added`}</span>
                        <img
                          src={Recurring}
                          alt="content icon"
                          style={{ marginLeft: '0.5rem', marginRight: 0 }}
                        />
                      </div>
                    ) : (
                      <h4 className={styles.contentCountWrapperNew + ' supportTextSemibold'}>
                        <img src={`${imageBasePath}contentActiveDesign.svg`} />
                        {module.content_count}
                        <span>Content added</span>
                      </h4>
                    )}
                    <Tooltip title="Estimated time of completion">
                      <h4 className={styles.contentCountWrapperNew + ' supportTextSemibold'}>
                        <img src={`${imageBasePath}clockActiveDesign.svg`} />
                        <strong>{module.estimated_duration} min.</strong>
                      </h4>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      marginBottom: '0.75rem',
                      fontWeight: 300,
                      marginTop: 'auto',
                    }}
                  >
                    <img src={`${imageBasePath}calendarDeactiveDesign.svg`} />
                    {module?.published_at !== null ? (
                      <span>
                        Published on {DateTime.trainingCardDateFormat(module?.published_at)}
                      </span>
                    ) : (
                      <span>Not published</span>
                    )}
                  </div>
                  <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    <h4 className={styles.contentCountWrapperNew + ' supportTextSemibold'}>
                      <img src={`${imageBasePath}contentActiveDesign.svg`} />
                      {module.content_count}
                      <span>Content added</span>
                    </h4>
                    <Tooltip title="Estimated time of completion">
                      <h4 className={styles.contentCountWrapperNew + ' supportTextSemibold'}>
                        <img src={`${imageBasePath}clockActiveDesign.svg`} />
                        <strong>{module.estimated_duration} min.</strong>
                      </h4>
                    </Tooltip>
                  </div>
                </div>
              )}
              <img
                className={styles.trainingPattern}
                src={imageBasePath + 'training_pattern.png'}
                alt="image"
              />
              {['user'].includes(role) ? (
                module.is_new ? (
                  <span className={styles.newAlert + ' supportText'}>NEW</span>
                ) : null
              ) : (
                <span className={styles.more}>
                  <a
                    aria-controls={'simple-menu-' + index}
                    className={'more'}
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClick(e);
                    }}
                  >
                    <img
                      className="more"
                      src={imageBasePath + 'more_black.svg'}
                      alt="More"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </a>
                </span>
              )}
              <Menu
                id={'simple-menu-' + index}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) ? true : false}
                onClose={(e) => {
                  setAnchorEl(null);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="moreMenu"
              >
                {!module?.disable_edition && (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.handleEditModule(module);
                      setAnchorEl(null);
                    }}
                  >
                    <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                    <span className="supportText">Edit</span>
                  </MenuItem>
                )}
                {canUserPerformAction(module.created_by?.uuid) && verifyModuleStatus ? (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(null);
                      props.handleEnabelDisable(module);
                    }}
                  >
                    {module.status === 0 ? (
                      <img src={imageBasePath + 'check_grey3.svg'} alt="Disable" />
                    ) : (
                      <img src={imageBasePath + 'disable.svg'} alt="Disable" />
                    )}
                    <span className="supportText">{module.status ? `Disable` : `Enable`}</span>
                  </MenuItem>
                ) : null}
                {module.is_custom && canUserPerformAction(module.created_by?.uuid) ? (
                  <MenuItem
                    className="deleteAction"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(null);
                      props.handleDeleteModule(module);
                    }}
                  >
                    <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                    <span className="supportText">Delete</span>
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
          </div>
          <div className={styles.bottomBlk}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.moduleActionRow}
            >
              {['user'].includes(role) ? (
                <>
                  <Grid item lg={7} md={7} sm={12} xs={12}>
                    <p>
                      {module.completed_count ? (
                        <span
                          className={styles.contentCount + ' paragraph'}
                        >{`${module.completed_count}/${module.content_count} contents completed`}</span>
                      ) : (
                        <span
                          className={styles.contentCount + ' paragraph'}
                        >{`${module.content_count} content`}</span>
                      )}
                    </p>
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    {module.completed_count ? (
                      <div className={styles.progressBlk}>
                        <ProgressBar
                          value={(module.completed_count / module.content_count) * 100}
                          max={100}
                          className={styles.stepProgress + ' stepProgress'}
                        />
                      </div>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <>
                  {module.status == 0 ? (
                    <div
                      style={{
                        padding: '0.5rem',
                        marginRight: 'auto',
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <StatusLabel disabled />
                      {module?.updated_at && (
                        <Tooltip title="Last updated on">
                          <div
                            className={styles.contentCountWrapperNew + ' supportTextSemibold'}
                            style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src={`${imageBasePath}clockDeactiveDesign.svg`}
                              style={{
                                marginRight: '8px',
                                position: 'relative',
                                top: '2px',
                                height: '14px',
                              }}
                            />
                            <div style={{ paddingTop: '0.2rem' }}>
                              {DateTime.trainingCardDateFormat(module?.updated_at)}
                            </div>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  ) : module.published_at ? (
                    <div
                      style={{
                        paddingInline: '0.5rem',
                        marginRight: 'auto',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        <StatusLabel published />
                        {module?.updated_at && (
                          <Tooltip title="Last updated on">
                            <div
                              className={styles.contentCountWrapperNew + ' supportTextSemibold'}
                              style={{
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={`${imageBasePath}clockDeactiveDesign.svg`}
                                style={{
                                  marginRight: '8px',
                                  position: 'relative',
                                  top: '2px',
                                  height: '14px',
                                }}
                              />
                              <div style={{ paddingTop: '0.2rem' }}>
                                {DateTime.trainingCardDateFormat(module?.updated_at)}
                              </div>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ) : (
                    module.content_count === 0 && (
                      <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginRight: 'auto' }}>
                        <p>
                          <span className={styles.contentCount + ' paragraph'}>
                            0 Content added
                          </span>
                          <CustomTooltipWhite
                            placement="top"
                            tooltipIcon="info_icon.svg"
                            title={
                              <div className="customTooltipContent">
                                <p className="heading6">What content to add ? </p>
                                <List className="listInline">
                                  <ListItem className="supportText">
                                    Include what sales process is
                                  </ListItem>
                                  <ListItem className="supportText">
                                    What is followed in your company
                                  </ListItem>
                                  <ListItem className="supportText">
                                    Best practices in sales
                                  </ListItem>
                                </List>
                              </div>
                            }
                          />
                        </p>
                      </Grid>
                    )
                  )}
                  {module.status == 1 && module.content_count && !module.published_at ? (
                    <Grid item xs={12}>
                      <List className={styles.listInline + ' listInline'}>
                        <ListItem className="listInlineItem ">
                          <CustomButton
                            className={`${styles.addContentBtn} primaryBtn smallBtn ${
                              loader ? `disabled` : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              // handlePublishModule()
                              if (readOnly) return;
                              setOpenConfirm(true);
                            }}
                          >{`Publish`}</CustomButton>
                        </ListItem>
                        <ListItem className="listInlineItem">
                          <CustomButton
                            className={styles.addContentBtn + ' secondaryBtn smallBtn'}
                            onClick={handleClickAddContent}
                          >
                            + Add content
                          </CustomButton>
                        </ListItem>
                      </List>
                    </Grid>
                  ) : (
                    <div style={{ padding: '0.5rem 0.5rem 0.5rem 0' }}>
                      <List className={styles.listInline + ' listInline'}>
                        <ListItem className="listInlineItem">
                          <CustomButton
                            className={styles.addContentBtn + ' secondaryBtn smallBtn'}
                            onClick={handleClickAddContent}
                          >
                            + Add content
                          </CustomButton>
                        </ListItem>
                      </List>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        open={openConfirm}
        title={
          <>
            Are you sure you want to publish module{' '}
            <strong className="heading3">"{module.title}"</strong>?
          </>
        }
        description="This action can't be undone, but you still can add and update your content in this module"
        acceptText="Yes, Publish"
        rejectText="No, Cancel"
        image={imageBasePath + 'module_added_success.svg'}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          handlePublishModule();
          setOpenConfirm(false);
        }}
        loader={loader}
      />
    </div>
  );
}

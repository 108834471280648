import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../../assets/styles/auth.module.scss';
import { imageBasePath } from '../../../constants';

export default function ChooseOrganization(props) {
  const { organisation, selectOrg, user_name } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let history = useHistory();
  return (
    <div className={styles.cardWrapper + ' ' + styles.chooseOrganizationWrapper}>
      <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
      <h1 className="heading3">
        Welcome back,{' '}
        <span className={styles.userName}>
          {userInfo?.user?.first_name ?? ''} {userInfo?.user?.last_name ?? ' '}
        </span>
      </h1>
      <div className={styles.organizationList}>
        {organisation && organisation.length ? (
          <>
            {organisation.map((org, index) => (
              <div className={styles.organizationListItem} onClick={() => selectOrg(org, index)}>
                <div className={styles.logoBlk}>
                  {org.logo ? (
                    <img src={org.logo} alt="" />
                  ) : (
                    <img src={imageBasePath + 'default_choose_company_logo.svg'} alt="" />
                  )}
                </div>
                <div className={styles.contentBlk}>
                  <h2 className="heading5">{org.name}</h2>
                  <h3 className="supportText">
                    <span>{org.members_count}</span> Members
                  </h3>
                </div>
                <img className={styles.goArrow} src={imageBasePath + 'next_arrow.svg'} alt="Go" />
              </div>
            ))}
          </>
        ) : (
          ''
        )}
        {userInfo?.can_create_organisation ? (
          <div
            className={styles.organizationListItem + ' ' + styles.createOrg}
            onClick={() => history.push('/createorganization')}
          >
            <div className={styles.logoBlk}>
              <img src={imageBasePath + 'default_company_logo.svg'} alt="" />
            </div>
            <div className={styles.contentBlk}>
              <h2 className="heading5">Create new organization</h2>
            </div>
            <img className={styles.goArrow} src={imageBasePath + 'next_arrow.svg'} alt="Go" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

import Roleplay from './containers/Roleplay';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import RoleplayView from './Components/RoleplayView/RoleplayView';
import SubmitRoleplay from './Components/SubmitRoleplay/SubmitRoleplay';
import ReviewRoleplay from './Components/ReviewRoleplay/ReviewRoleplay';
import SubmitReview from './Components/SubmitReview/SubmitReview';
import ReviewQualifiedRoleplay from './Components/ReviewQualifiedRoleplay/ReviewQualifiedRoleplay';
export const roleplayRoutes = [
  {
    path: '/roleplay',
    component: pageLayoutHoc(Roleplay),
    key: 'roleplay',
  },
  {
    path: '/roleplay/:uuid',
    component: pageLayoutHoc(RoleplayView),
    key: 'roleplay-view',
  },
  {
    path: '/roleplay/:uuid/submit',
    component: pageLayoutHoc(SubmitRoleplay),
    key: 'roleplay-view',
  },
  {
    path: '/roleplay-review/:uuid',
    component: pageLayoutHoc(ReviewRoleplay),
    key: 'roleplay-review',
  },
  {
    path: '/roleplay-review/:uuid/submit',
    component: pageLayoutHoc(SubmitReview),
    key: 'roleplay-review',
  },
  {
    path: '/roleplay-review-qualified/:uuid',
    component: pageLayoutHoc(ReviewQualifiedRoleplay),
    key: 'roleplay-review',
  },
];

import React, { useEffect } from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton, CustomLink, ExternalLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { Grid } from '@material-ui/core';

export default function VerifyDomain(props) {
  const { submitLoader } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <>
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={styles.cardWrapper + ' ' + styles.organizationExistWrapper}>
                  <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
                  {userInfo?.currentOrg?.logo ? (
                    <img
                      style={{
                        marginBottom: '8px',
                        objectFit: 'contain',
                        border: '1px solid #E7F0F5',
                      }}
                      className={styles.organizationLogo}
                      src={userInfo?.currentOrg?.logo}
                      alt="salestable"
                    />
                  ) : userInfo?.selectedOrg?.logo ? (
                    <img
                      style={{ marginBottom: '8px', objectFit: 'contain', borderRadius: '50%' }}
                      className={styles.organizationLogo}
                      src={userInfo?.selectedOrg?.logo}
                      alt="salestable"
                    />
                  ) : (
                    <img
                      src={imageBasePath + 'upload_company_logo.png'}
                      alt="Upload company Logo"
                    />
                  )}
                  <h1 className="heading3" style={{ margin: '16px 0' }}>
                    Your organization details are under verification
                  </h1>
                  <p className={styles.supportEmail + ' paragraph'}>
                    We are reviewing your organization details. We will notify you once the
                    verification is done. Please reach out to{' '}
                    <ExternalLink
                      href="mailto:info@salestable.ai"
                      target="_blank"
                      linktext="info@salestable.ai"
                      className="linkBtn"
                    />{' '}
                    for any queries.
                  </p>
                  <CustomLink
                    to="/signin"
                    linktext={submitLoader ? 'Try it later' : 'Try it later'}
                    className={`primaryBtn submitBtn ${submitLoader ? ' disabledBtn' : ''}`}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </>
  );
}

import React, { useState, useMemo, useEffect } from 'react';
import feature from 'assets/stream/Featured.svg';
import featureActive from 'assets/stream/featuredActive.svg';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';
import { useCreateFeaturedPost, useFeaturedPostsCountQuery } from 'features/stream/Stream.queries';
import styles from './FeaturePost.module.scss';
import { TooltipCustom } from 'utils';

const FeaturedPost = ({ postData = {} }) => {
  const [featured, setFeatured] = useState(postData?.featured);
  const { mutate: createFeaturedPost } = useCreateFeaturedPost({});
  const { data } = useFeaturedPostsCountQuery({});
  const [tooltipTex, setTooltipTex] = useState('Pinned post');
  const handleFeatured = () => {
    if (data?.data < 5) {
      createFeaturedPost(
        { postId: postData?.id },
        {
          onSuccess: () => setFeatured(!featured),
        }
      );
    }
    if (data?.data === 5 && featured) {
      createFeaturedPost(
        { postId: postData?.id },
        {
          onSuccess: () => setFeatured(!featured),
        }
      );
    }
  };

  useEffect(() => {
    if (data?.data === 5) setTooltipTex('Already five post pinned');
    else if (!featured) setTooltipTex('Pin post');
    else setTooltipTex('Post pinned');
    if (featured) setTooltipTex('Post pinned');
  }, [data, featured]);

  const hide = useMemo(() => !FeatureFlag.enabled('STREAM_IMPROVEMENTS_TWO'), []);
  if (hide) return null;

  return (
    <>
      {Organization.isAdmin() || Organization.isOwner() ? (
        <TooltipCustom
          placement="top"
          arrow
          title={
            <div className="supportText" style={{ color: `#fff` }}>
              {tooltipTex}
            </div>
          }
        >
          <img
            className={featured ? styles.iconActive : styles.icon}
            src={featured ? featureActive : feature}
            alt="icon"
            onClick={handleFeatured}
          />
        </TooltipCustom>
      ) : (
        <div onClick={null} className={styles.smartMentor}>
          {postData?.featured && (
            <TooltipCustom
              placement="top"
              arrow
              title={
                <div className="supportText" style={{ color: `#fff` }}>
                  Post pinned
                </div>
              }
            >
              <img
                className={styles.icon}
                style={{ cursor: 'default' }}
                src={featureActive}
                alt="icon"
              />
            </TooltipCustom>
          )}
        </div>
      )}
    </>
  );
};

export default FeaturedPost;

import React, { Component } from 'react';
import styles from '../../../assets/styles/tracking.module.scss';
import { AdminTrackingContainer, UserTrackingContainer } from '../components';
import { timezoneUpdateApi } from '../../home/apiServices';
import FunnelProvider from 'features/funnel/FunnelProvider';

export default class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,
    };
    this.timezoneUpdateApi = timezoneUpdateApi.bind(this);
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo?.user?.timezone === 'UTC' || !userInfo.user?.timezone) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timezoneUpdateApi({ timezone: timezone });
    }
  }

  render() {
    const { role } = this.state;
    return (
      <FunnelProvider>
        <div className={styles.trackingPage}>
          {['primary_admin', 'admin', 'sales_lead'].includes(role) ? (
            <AdminTrackingContainer role={role} />
          ) : (
            <UserTrackingContainer role={this.props.role} />
          )}
        </div>
      </FunnelProvider>
    );
  }
}

import { globalPostService, globalGetService } from '../../utils/globalApiServices';
import { toastFlashMessage } from '../../utils';

export function signInApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/login/`, query.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signUpApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/signup/`, query.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resendEmailForVerificationApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/resend/activation-email/`, query.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyEmailApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/verify-email/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function forgotPasswordApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/forgot-password/`, query.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPasswordApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/reset-password/`, query.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function organisationCreateApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/organisation/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function organisationSelectApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/organisation/select/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function socialSignUpApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/social/signup/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function contactAdminApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/organisation/contact/admin/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function handleSiginResponse(response, formData) {
  if (response.statusCode === 200) {
    localStorage.setItem('userInfo', JSON.stringify(response.data));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.user.email_verified == false) {
      this.props.history.push({
        pathname: '/verifyemail',
        search: '',
        state: { formData: { email: userInfo.user.email } },
      });
    } else if (
      userInfo &&
      userInfo.user.email_verified == true &&
      userInfo.available_organisation
    ) {
      this.props.history.push('/requestaccess');
    } else if (userInfo && userInfo.organisations && userInfo.organisations.length == 0) {
      this.props.history.push('/createorganization');
    } else if (
      (userInfo && userInfo.organisations && userInfo.organisations.length > 1) ||
      (userInfo && userInfo.can_create_organisation)
    ) {
      // this.props.history.push('/selectorganization');
      this.props.history.push({
        pathname: '/selectorganization',
        search: '',
        state: { formData: { remember_me: formData?.remember_me } },
      });
    } else if (userInfo && userInfo.organisations && userInfo.organisations.length == 1) {
      if (userInfo.organisations[0]?.deleted_for_retention)
        return this.props.history.push(`/retained`);
      
      organisationSelectApi({
        organisation_id: response.data?.organisations[0].slug,
        remember_me: formData?.remember_me,
      }).then((orgRes) => {
        this.setState((prevState) => ({
          ...prevState,
          submitLoader: false,
          socialLoginLoader: false,
          socialLinkedinLoader: false,
        }));
        toastFlashMessage(response.message, 'success');
        if (orgRes.statusCode === 200) {
          let responseUpdated = {
            ...orgRes.data,
            selectedOrg: response.data.organisations[0],
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          const redirection = localStorage.getItem('redirection');
          if (redirection) {
            localStorage.removeItem('redirection');
            this.props.history.push(redirection);
          } else {
            setTimeout(() => this.props.history.push('/payment'), 500);
          }
          // this.props.history.push('/');
        } else if (orgRes.statusCode === 6001) {
          let responseUpdated = {
            ...orgRes.data,
            currentOrg: response.data.organisations[0],
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          this.props.history.push('/verifydomain');
        } else if (orgRes.statusCode === 6002) {
          let responseUpdated = {
            ...orgRes.data,
            currentOrg: response.data.organisations[0],
          };
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          this.props.history.push('/verificationrejected');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else if (
      userInfo &&
      userInfo.user.email_verified == true &&
      !userInfo?.organisations?.length
    ) {
      this.props.history.push('/createorganization');
    } else {
      localStorage.clear();
    }
  } else {
    toastFlashMessage(response.message, 'error');
    this.props.history.push('/');
    this.setState((prevState) => ({
      ...prevState,
      submitLoader: false,
      socialLoginLoader: false,
      socialLinkedinLoader: false,
    }));
  }
}

export function acceptInvitation(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/invite/accept/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setPassword(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/set-password/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyOwnership(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/auth/ownership/accept/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

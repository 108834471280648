import React, { useState, useEffect } from 'react';
import {
  getProvidersApi,
  getManualJobsApi,
  connectApi,
  providerJobUpdateApi,
  enableDisableApi,
} from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { SuccessDialog } from '../../../shared_elements';
import {
  IntroductionCard,
  AddJobDialog,
  IntegrateProviders,
  UpdateProviderJobs,
  Overview,
  Integration,
  Shimmer,
} from './index';

export function AdminRecruitmentContainer(props) {
  const [loader, loaderSet] = useState(true);
  const [tabReset, tabResetSet] = useState(false);
  const [mloader, mloaderSet] = useState(true);
  const [syncLoader, setSyncLoader] = useState(false);
  const [connectLoader, connectLoaderSet] = useState(false);
  const [confirmLoader, confirmLoaderSet] = useState(false);
  const [jobProviders, jobProvidersSet] = useState([]);
  const [jobs, jobsSet] = useState([]);
  const [availableJobs, availableJobsSet] = useState([]);
  const [addJobManually, addJobManuallySet] = useState(false);
  const [openIntegrateProvider, openIntegrateProviderSet] = useState(false);
  const [currentIntegration, currentIntegrationSet] = useState({});
  const [selectJobs, selectJobsSet] = useState(false);
  const [successFlag, successFlagSet] = useState(false);
  const [publish, publishSet] = useState(false);
  const [totalSelectedJob, totalSelectedJobSet] = useState(false);
  const [configure, configureSet] = useState(false);
  const [fetchJobLoader, fetchJobLoaderSet] = useState(false);
  const [changeToken, changeTokenSet] = useState(false);

  useEffect(() => {
    getProviders();
    getJobs();
  }, []);

  const getProviders = () => {
    getProvidersApi().then((response) => {
      if (response.statusCode === 200) {
        loaderSet(false);
        jobProvidersSet(response.data.list);
      } else {
        toastFlashMessage(response.message, 'error');
      }
      setSyncLoader(false);
    });
  };

  const getJobs = () => {
    getManualJobsApi().then((response) => {
      if (response.statusCode === 200) {
        mloaderSet(false);
        jobsSet(response.data);
        setIsPublished(response.data);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const handleIntegrateProviders = (val, job = null) => {
    if (publish) {
      currentIntegrationSet(job);
      openIntegrateProviderSet(val);
    } else {
      openIntegrateProviderSet(val);
    }
  };

  const handleConnent = (token, job = {}) => {
    connectApi(currentIntegration.id ? currentIntegration.id : job.id, { auth_key: token }).then(
      (response) => {
        connectLoaderSet(false);
        if (response.statusCode === 200) {
          if (changeToken) {
            openIntegrateProviderSet(false);
            changeTokenSet(false);
            toastFlashMessage(response.message, 'success');
          } else if (job && job.id) {
            availableJobsSet(response.data);
            fetchJobLoaderSet(false);
          } else {
            availableJobsSet(response.data);
            selectJobsSet(true);
            openIntegrateProviderSet(false);
          }
        } else {
          toastFlashMessage(response.message, 'error');
        }
      }
    );
  };

  const providerJobUpdate = (selectedJobs) => {
    providerJobUpdateApi(currentIntegration.id, { jobs: selectedJobs }).then((response) => {
      confirmLoaderSet(false);
      if (response.statusCode === 200) {
        if (configure) {
          currentIntegrationSet({});
          jobsSet({
            manual: jobs.manual,
            providers: jobs.providers.map((pjob) =>
              pjob.id === currentIntegration.id ? response.data : pjob
            ),
          });
          jobProvidersSet([
            ...jobProviders.map((pjob) =>
              pjob.id === currentIntegration.id ? response.data : pjob
            ),
          ]);
          configureSet(false);
          toastFlashMessage(response.message, 'success');
        } else {
          successFlagSet(true);
          totalSelectedJobSet(selectedJobs.length);
        }
        availableJobsSet([]);
        selectJobsSet(false);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const setIsPublished = (jobs) => {
    if (jobs && jobs.manual && jobs.manual.length) {
      publishSet(true);
    } else if (jobs && jobs.providers && jobs.providers.length) {
      const activeJob = jobs.providers.filter((job) => job.status >= 1);
      if (activeJob && activeJob.length) {
        publishSet(true);
      }
    } else {
      publishSet(false);
      return false;
    }
  };

  const syncJob = (id, jobs) => {
    setSyncLoader(true);
    providerJobUpdateApi(id, { jobs: jobs }).then((response) => {
      setSyncLoader(false);
      if (response.statusCode === 200) {
        toastFlashMessage(response.message, 'success');
        getProviders();
        getJobs();
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const canGetjob = () => {
    if (props.isPublished) {
      getJobs();
    }
  };

  const updateManualJob = (key, ujob) => {
    if (key === 'update' || key == 'close') {
      jobsSet({
        providers: jobs.providers,
        manual: jobs.manual.map((mjob) => (mjob.uuid === ujob.uuid ? ujob : mjob)),
      });
    } else if (key === 'delete') {
      jobsSet({
        providers: jobs.providers,
        manual: jobs.manual.filter((mjob) => mjob.uuid !== ujob.uuid),
      });
      if (!jobs.providers.length && !jobs.manual.filter((mjob) => mjob.uuid !== ujob.uuid).length) {
        getProviders();
        getJobs();
        publishSet(false);
      }
    }
  };

  const handleConfigure = (job) => {
    configureSet(true);
    // availableJobsSet(job.recruitments);
    connectLoaderSet(false);
    selectJobsSet(true);
    openIntegrateProviderSet(false);
    fetchJobLoaderSet(true);
    handleConnent(job.auth_key, job);
  };

  const handleChangeToken = (job) => {
    handleIntegrateProviders(true, job);
    currentIntegrationSet(job);
    changeTokenSet(true);
  };

  const enableDisable = (ejob) => {
    let enable = ejob.status === 1 ? 3 : 1;
    enableDisableApi(ejob.id, { enable: enable }).then((response) => {
      if (response.statusCode === 200) {
        jobsSet({
          manual: jobs.manual,
          providers: jobs.providers.map((pjob) =>
            pjob.id === ejob.id ? { ...pjob, status: enable } : pjob
          ),
        });
        jobProvidersSet([
          ...jobProviders.map((pjob) => (pjob.id === ejob.id ? { ...pjob, status: enable } : pjob)),
        ]);
        if (selectJobs) {
          currentIntegrationSet({ ...currentIntegration, status: enable });
        }
        getJobs();
        getProviders();
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  return (
    <div>
      {loader || mloader ? (
        <Shimmer type="RecruitmentOption" />
      ) : publish ? (
        <>
          <Overview
            getProviders={getProviders}
            jobProviders={jobProviders}
            allJobs={jobs}
            syncLoader={syncLoader}
            syncJob={syncJob}
            getJobs={getJobs}
            handleIntegrateProviders={handleIntegrateProviders}
            currentIntegrationSet={currentIntegrationSet}
            tabReset={tabReset}
            addJobManuallySet={addJobManuallySet}
            updateManualJob={updateManualJob}
            handleConfigure={handleConfigure}
            handleChangeToken={handleChangeToken}
            enableDisable={enableDisable}
            fetchJobLoader={fetchJobLoader}
          />
        </>
      ) : (
        <>
          <IntroductionCard
            jobProviders={jobProviders}
            getJobs={getJobs}
            getProviders={getProviders}
          />
          <Integration
            getProviders={getProviders}
            jobProviders={jobProviders}
            jobs={jobs}
            getJobs={getJobs}
            handleIntegrateProviders={handleIntegrateProviders}
            currentIntegrationSet={currentIntegrationSet}
            addJobManuallySet={addJobManuallySet}
            enableDisable={enableDisable}
            handleConfigure={handleConfigure}
          />
        </>
      )}
      <IntegrateProviders
        open={openIntegrateProvider}
        handleIntegrateProviders={handleIntegrateProviders}
        currentIntegration={currentIntegration}
        connectLoader={connectLoader}
        connectLoaderSet={connectLoaderSet}
        handleConnent={handleConnent}
        handleClose={() => {
          changeTokenSet(false);
          openIntegrateProviderSet(false);
        }}
      />
      <UpdateProviderJobs
        open={selectJobs}
        handleClose={() => {
          selectJobsSet(false);
          configureSet(false);
        }}
        availableJobs={availableJobs}
        currentIntegration={currentIntegration}
        providerJobUpdate={providerJobUpdate}
        confirmLoader={confirmLoader}
        confirmLoaderSet={confirmLoaderSet}
        enableDisable={enableDisable}
        fetchJobLoader={fetchJobLoader}
      />
      <SuccessDialog
        open={successFlag}
        handleSubmit={() => {
          tabResetSet(true);
          successFlagSet(false);
          getProviders();
          getJobs();
          setTimeout(() => {
            currentIntegrationSet({});
          }, 1000);
        }}
        handleClose={() => {
          successFlagSet(false);
          currentIntegrationSet({});
          getProviders();
          canGetjob();
        }}
        title={`Great! You've added ${totalSelectedJob} jobs from ${currentIntegration.name}`}
        description={`You've successfully added your jobs from ${currentIntegration.name}`}
        acceptText={`See recruitment overview`}
        continueText={`Continue integration`}
        totalSelectedJob={totalSelectedJob}
      />
      <AddJobDialog
        open={addJobManually}
        handleClose={() => addJobManuallySet(false)}
        callBackFn={() => {
          getProviders();
          getJobs();
        }}
      />
    </div>
  );
}

export default AdminRecruitmentContainer;

import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { paymentHasError } from '../../Payment.utils';
import { useHistory } from 'react-router-dom';

const ErrorPaymentAlert = () => {
  const { state: globalState } = useContext(GlobalContext);
  const hasError = paymentHasError();
  const isOwner = ['primary_admin'].includes(globalState?.role?.slug);

  const history = useHistory();
  const handleClick = () => {
    if (isOwner) history.push('/subscription');
  };

  if (!hasError) return null;
  return (
    <>
      <Alert
        style={{
          position: 'sticky',
          top: '64px',
          zIndex: '201',
          cursor: isOwner ? 'pointer' : '',
        }}
        severity="error"
        onClick={handleClick}
      >
        {isOwner ? (
          <>
            <p>
              Your payment to salestable has failed, please provide a new payment method as soon as
              possible to continue using salestable normally.
            </p>
            <p>Click here to go to the subscription page and add a new payment method.</p>
          </>
        ) : (
          <p>
            The payment to salestable of your organization has failed, please alert your
            organization's owner as soon as possible to continue using salestable normally.
          </p>
        )}
      </Alert>
    </>
  );
};

export default ErrorPaymentAlert;

import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import { useLocation, Redirect, useHistory } from 'react-router-dom';

import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

const DeletedForRetention = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const history = useHistory();

  let location = useLocation();
  const index = queryString.parse(location.search, { decode: true })?.org || 0;

  const selectedOrg = useMemo(
    () => userInfo?.organisations[index],
    [index, userInfo?.organisations]
  );

  const showLogout = useMemo(
    () => userInfo?.organisations?.every((x) => x?.deleted_for_retention),
    [userInfo?.organisations]
  );

  const logout = () => {
    localStorage.clear();
    history.push('/');
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Single organization
    if (userInfo?.organisations?.length <= 1) return logout();
    // Multiple organizations
    history.push('/selectorganization');
  };

  if (!!userInfo?.selectedOrg || !userInfo) return <Redirect to="/" />;

  return (
    <div className={styles.signInPage}>
      <section className={styles.signInSection + ' backgroundProp'}>
        <div className={styles.signInWrapper}>
          <Grid container direction="row" alignItems="center" className={styles.signInRow}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={styles.cardWrapper + ' ' + styles.organizationExistWrapper}>
                <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />

                <img
                  className={styles.organizationLogo}
                  src={selectedOrg.logo || imageBasePath + 'upload_company_logo.png'}
                  alt="salestable"
                />

                <h1 className="heading3">
                  Important: Action Required for Your
                  <br /> {selectedOrg?.name} Account
                </h1>

                <p className="paragraph">
                  Reach out to your admin to enable your user name or inquire about our upgrade
                  options at{' '}
                  <a
                    className="link"
                    href="https://www.salestable.ai/pricing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.salestable.ai/pricing/
                  </a>{' '}
                  or send an email to{' '}
                  <a className="link" href="mailto:support@salestable.ai">
                    support@salestable.ai
                  </a>
                  .
                </p>
                <CustomButton
                  children={'Go Back'}
                  className={`primaryBtn submitBtn`}
                  onClick={handleGoBack}
                />
                {showLogout && (
                  <>
                    <p />
                    <CustomButton children={'Logout'} className={`secondaryBtn`} onClick={logout} />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
};

export default DeletedForRetention;

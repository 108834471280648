import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Hidden } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { imageBasePath } from '../../../constants';
import { videoBasePath } from '../../../constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: 'fixed',
    right: 24,
    top: 24,
    color: theme.palette.grey[500],
    padding: 0,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function VideoDialog(props) {
  const { activeMedia } = props;

  return (
    <div>
      <Dialog
        maxWidth="md"
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="sampleVideoDialog"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.onClose}
          style={{ padding: '0' }}
        ></DialogTitle>
        <DialogContent dividers>
          <video
            controls="controls"
            autoPlay
            width="100%"
            height="600"
            style={{ objectFit: 'contain' }}
          >
            <source
              src={`${videoBasePath}loom_recording.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid, Avatar } from '@material-ui/core';
import { CustomDoughnutChart } from '../../../shared_elements';
import { TooltipCustom } from '../../../utils';

export default function OnboardingOverview(props) {
  const { onboarding } = props;

  return (
    <div className={styles.moduleOverview}>
      <OverviewTitle
        icon={`${imageBasePath}onboard_default.svg`}
        title={`Onboarding`}
        moduleLink={'/onboarding'}
      />
      <Grid
        container
        className={`${styles.onboardingOverviewWrapper}  ${styles.defaultOverviewWrapper}`}
        alignItems="center"
      >
        <Grid item xs={12} md={5}>
          <div className={styles.infoWrapper}>
            <h3 className="heading2">{onboarding?.progress?.onboarded_users_count}</h3>
            <p className="paragraph">Total onboarded users</p>
          </div>
          {onboarding?.progress?.completed_user?.length ? (
            <div className={`${styles.infoWrapper} ${styles.usersListInfo}`}>
              <ul className={`${styles.usersList} listInline flexCentered`}>
                {onboarding?.progress?.completed_user?.map((user, index) =>
                  index < 4 ? (
                    <li key={index} className="listInlineItem">
                      <TooltipCustom title={user.email} arrow placement="top">
                        {user.profile_pic ? (
                          <Avatar
                            src={user.profile_pic}
                            className={`${styles.profilePic}`}
                          ></Avatar>
                        ) : (
                          <Avatar className={`imageBlk avatar ${styles.profilePic}`}>
                            {user.first_name
                              ? user.first_name.substring(0, 1)
                              : user.email.substring(0, 1)}
                          </Avatar>
                        )}
                      </TooltipCustom>
                    </li>
                  ) : null
                )}
                {onboarding?.progress?.completed_user?.length > 4 ? (
                  <li className="listInlineItem">
                    {/* <TooltipCustom title={<ul className="listUnstyled">
                                    {onboarding?.progress?.completed_user?.map((user, index) => index >=4 ? <li>{user.email}</li>: null)}
                                </ul>} arrow placement="right">
                                    <Avatar className={`imageBlk avatar ${styles.profilePic}`}>+{onboarding?.progress?.completed_user?.length - 4}</Avatar>
                                </TooltipCustom> */}
                    <Avatar className={`imageBlk avatar ${styles.profilePic}`}>
                      +{onboarding?.progress?.completed_user?.length - 4}
                    </Avatar>
                  </li>
                ) : null}
              </ul>
              <p className="paragraph">Finished onboarding</p>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={7} style={{ maxWidth: '100%' }}>
          <div>
            <CustomDoughnutChart
              page={`onboarding`}
              type="dashboard"
              chartData={onboarding}
              direction="row-reverse"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React from 'react';
import RecorderButton from './RecorderButton';

import play from '../../../assets/recorder/play.svg';
import stop from '../../../assets/recorder/stop.svg';

const StopButton = ({ isRecording, onStart = () => null, onStop = () => null }) => {
  return (
    <RecorderButton
      preventHide={!isRecording}
      src={isRecording ? stop : play}
      alt={isRecording ? 'stop' : 'play'}
      onClick={isRecording ? onStop : onStart}
      tooltip={isRecording ? 'Stop Recording' : 'Start Recording'}
    />
  );
};

export default StopButton;

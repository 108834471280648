import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSmartMentorStore } from 'features/smartMentor/SmartMentor.store';
import { useSalesAssistStore } from 'features/chatAssist/ChatAssist.store';

const ClearCache = ({ children }) => {
  const queryClient = useQueryClient();
  const location = useLocation();

  const resetSmartMentor = useSmartMentorStore((s) => s?.resetConversation);
  const resetSalesAssist = useSalesAssistStore((state) => state?.reset);

  useEffect(() => {
    if (!location.pathname.includes('signin')) return;
    // Clean cache from react query
    queryClient.clear();

    // Clean zustand stores
    resetSmartMentor();
    // Clean chat AI store
    resetSalesAssist();
  }, [location, queryClient, resetSmartMentor, resetSalesAssist]);

  return <div>{children}</div>;
};

export default ClearCache;

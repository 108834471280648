import React from 'react';
import { InputBase } from '@material-ui/core';
const ChatInput = ({ disabled, value, onChange = () => null, onKeyPress = () => null }) => {
  return (
    <InputBase
      fullWidth
      placeholder="Ask or search anything within Salestable"
      disabled={disabled}
      value={value}
      onChange={onChange}
      multiline
      maxRows={3}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onKeyPress();
        }
      }}
    />
  );
};

export default ChatInput;

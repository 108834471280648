import { format, parseISO } from 'date-fns';

const DateTime = (function () {
  // Organization info
  const formatSecondsToHHMM = (seconds, message = '--') =>
    !seconds
      ? message
      : ((hours) =>
          !hours
            ? `${Math.floor(seconds / 60)} mins`
            : `${hours} hrs ${Math.floor((seconds % 3600) / 60)} mins`)(Math.floor(seconds / 3600));

  const formatDate = (date) => {
    const dateString = new Date(date);

    let month = dateString.getUTCMonth() + 1;
    if (month < 10) month = `0${month}`;
    let day = dateString.getUTCDate();
    if (day < 10) day = `0${day}`;
    let year = dateString.getUTCFullYear();

    const newDate = year + '-' + month + '-' + day;
    return newDate;
  };

  const formatMMMddyyyy = (date = '') => {
    try {
      return format(parseISO(date), 'MMM dd, yyyy');
    } catch (error) {
      return JSON.stringify(date);
    }
  };
  const formatddMMMYYYY = (date = '') => {
    try {
      return format(parseISO(date), 'dd MMM. yyyy');
    } catch (error) {
      return JSON.stringify(date);
    }
  };
  const formatMMMMddyyyy = (date = '') => {
    try {
      return format(parseISO(date), 'MMMM dd, yyyy');
    } catch (error) {
      return JSON.stringify(date);
    }
  };

  const trainingCardDateFormat = (date = '') => {
    try {
      const ddMMM = format(parseISO(date), 'dd MMM');
      const yy = format(parseISO(date), 'yy');
      return `${ddMMM}'${yy}`;
    } catch (error) {
      return JSON.stringify(date);
    }
  };

  return {
    formatSecondsToHHMM,
    formatDate,
    formatMMMddyyyy,
    formatMMMMddyyyy,
    trainingCardDateFormat,
    formatddMMMYYYY,
  };
})();

export default DateTime;

import React, { useEffect, useMemo, useState } from 'react';

import styles from './CouponInput.module.scss';
import { InputText } from 'shared_ui_components';
import { usePaymentStore } from 'features/payment/Payment.store';
import { Payment } from 'features/payment/Payment.utils';
import { FeatureFlag } from 'controllers/_exports';

const CouponInput = ({ disabled, data = {}, header }) => {
  const enabled = useMemo(() => FeatureFlag.enabled('COUPONS'), []);

  // Coupon data once applied
  const { coupon: couponData = {} } = data;
  const success = useMemo(() => couponData?.statusCode === 200, [couponData]);

  const errorMsg = useMemo(() => {
    if (!Object.keys(couponData)?.length > 0) return '';
    return success ? '' : couponData?.message || 'This coupon is invalid';
  }, [couponData, success]);

  // Save promo code ID from backend if success
  const setPromoID = usePaymentStore((state) => state?.setPromoID);
  useEffect(() => {
    if (!success) return;
    const id = couponData?.promo_code_id;
    setPromoID(id);
  }, [success, couponData, setPromoID]);

  // Coupon input handlers
  const [value, setValue] = useState('');
  const onChange = (e) => setValue(e?.target?.value);

  const coupon = usePaymentStore((state) => state?.couponData.coupon);
  const setCoupon = usePaymentStore((state) => state?.setCoupon);
  const resetCoupon = usePaymentStore((state) => state?.resetCoupon);

  useEffect(() => {
    return function cleanCoupon() {
      resetCoupon();
    };
  }, [resetCoupon]);

  const applyCoupon = (couponValue) => enabled && setCoupon(couponValue?.trim());
  const onClick = () => value?.trim() && applyCoupon(value);

  // URL coupon
  const { urlCoupon } = Payment.getPriceAndCoupon();

  useEffect(() => {
    if (!urlCoupon) return;
    setValue(urlCoupon);
    applyCoupon(urlCoupon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCoupon]);

  if (!enabled) return null;

  if (header)
    return success ? (
      <div className={styles.success}>
        Your coupon <span className={styles.coupon}>{coupon?.toUpperCase()}</span> has been applied
      </div>
    ) : null;

  return success ? null : (
    <div className={styles.couponContainer}>
      <p className={styles.error}>{errorMsg}</p>
      <div className={styles.couponInput}>
        <InputText placeholder="Code" value={value} onChange={onChange} />
        <span
          onClick={onClick}
          className={`${styles.button} ${disabled || !value?.trim() ? styles.disabled : ''}`}
        >
          Apply
        </span>
      </div>
    </div>
  );
};

export default CouponInput;

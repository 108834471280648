import React from 'react';
import { getContentTypeImgURL } from '../../../utils';
import { imageBasePath } from '../../../constants';

import styles from '../../../assets/styles/training.module.scss';

const FileLink = ({ question }) => {
  return (
    <div>
      <a
        className={styles.uploadedFileName + ' link '}
        href={question?.answer?.link ? question?.answer?.link : question?.answer?.value}
        target="_blank"
        rel="noreferrer"
      >
        {question.answer?.value ? (
          getContentTypeImgURL(question?.answer?.value)
        ) : (
          <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />
        )}
      </a>
    </div>
  );
};

export default FileLink;

import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import Logout from '../components/PaymentForm/Other/Logout';
import { shouldRedirectToHome } from '../Payment.utils';
import PaymentForm from '../components/PaymentForm/PaymentForm';
import { GlobalContext } from '../../../context/GlobalContext';

const PaymentContainer = () => {
  const { state: globalState } = useContext(GlobalContext);

  if (shouldRedirectToHome({ role: globalState?.role?.slug })) return <Redirect to="/" />;
  return (
    <>
      <PaymentForm fullPage processType="payment" />
      <Logout />
    </>
  );
};

export default PaymentContainer;

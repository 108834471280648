import React from 'react';
import styles from '../../../assets/styles/tracking.module.scss';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { TooltipCustom } from '../../../utils';

function getBackgroundColor(weight) {
  const styles = {
    backgroundColor: 'rgba(1, 22, 39, 0.1)',
  };

  let result;
  switch (weight) {
    case 1:
      result = { ...styles, backgroundColor: 'rgb(0,163,134)' };
      break;
    case 0.9:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.9)' };
      break;
    case 0.8:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.8)' };
      break;
    case 0.7:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.7)' };
      break;
    case 0.6:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.6)' };
      break;
    case 0.5:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.5)' };
      break;
    case 0.4:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.4)' };
      break;
    case 0.3:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.3)' };
      break;
    case 0.2:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.2)' };
      break;
    case 0.1:
      result = { ...styles, backgroundColor: 'rgba(0,163,134,0.1)' };
      break;
    default:
      result = styles;
  }
  return result;
}

export default function DataBox(props) {
  const { weight, date, day, checkins, f } = props;

  return (
    <TooltipCustom
      placement="bottom"
      disableFocusListener
      arrow
      title={
        <div className="supportText tooltipWrapper" style={{ color: `#fff` }}>
          {`${date}, ${day}`}
          <Grid container>
            <Grid item md={9}>
              <p className="supportText" style={{ color: `#fff` }}>
                Total check-ins
              </p>
            </Grid>
            <Grid item md={3} className="textRight">
              <p className="supportText" style={{ color: `#fff` }}>
                {' '}
                {checkins}
              </p>
            </Grid>
          </Grid>
        </div>
      }
    >
      <div className={styles.databoxWrapper} style={{ ...getBackgroundColor(weight) }}>
        <img src={`${imageBasePath}active_checkin_icon.svg`} className={styles.activeCheckin} />
      </div>
    </TooltipCustom>
  );
}

import { imageBasePath } from '../../../constants';
import './InputContent.scss';

const inputContent = (props) => {
  return (
    <div>
      <div className="fileUpload">
        <img src={imageBasePath + 'dragdrop.png'} alt="drag and drop" />
        <div className="FileUploadText">
          <h6 className="paragraph">
            Drag and drop or <span className="linkBtn">Upload</span>
          </h6>
          <p className="textCenter supportText">
            Recommended file types: All <br />
            Max file size 200 MB
          </p>
        </div>
      </div>
    </div>
  );
};

export default inputContent;

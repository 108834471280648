import React from 'react';
import styles from './Reaction.module.scss';

const Reaction = ({
  reaction = '',
  reactionActive = '',
  total = 0,
  isReacted = false,
  handleReaction = null,
  ownPost = false,
}) => {
  return (
    <div className={styles.reactionContainer}>
      <img
        className={styles.icon}
        onClick={handleReaction}
        src={`${isReacted ? reactionActive : reaction}`}
        alt="reaction"
      />
      {total !== 0 && <span className={styles.number}>{total}</span>}
    </div>
  );
};

export default Reaction;

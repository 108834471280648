import React, { useState, useEffect } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import styles from './RoleplayView.module.scss';
const RoleplayViewDescriptionFooter = ({ title = 'Rating Criteria', points_ = [] }) => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [points, setPoints] = useState(points_);

  useEffect(() => {
    setPoints(roleplayView?.rating_criteria || []);
  }, [roleplayView]);
  return (
    <>
      <div>
        <div className={styles.noteBlock}>
          <p className="heading6">{title}</p>
          <ul style={{ lineHeight: '1rem' }}>
            {points.map((point, index) => {
              return (
                <li key={index} className="supportText">
                  {point.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RoleplayViewDescriptionFooter;

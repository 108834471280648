import React from 'react';
import styles from './HubspotSettings.module.scss';
import { CustomSwitch, InputText } from 'shared_ui_components';
import GoalDescription from 'features/tracking/components/GoalDescription';

const HubspotGoal = ({ goal, onTargetChange = () => null, onStatusChange = () => null }) => {
  const handleTarget = (e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    onTargetChange(goal?.uuid, value);
  };

  const toggleStatus = (e) => {
    onStatusChange(goal?.uuid, e.target.checked ? 1 : 0);
  };

  return (
    <div className={styles.goal} style={{ marginBottom: '20px' }}>
      <div className={styles.goalContent}>
        <div>
          <h3>{goal?.name}</h3>
          <CustomSwitch
            checked={!!goal?.status}
            label={!!goal?.status ? 'Enabled' : 'Disabled'}
            onChange={toggleStatus}
          />
        </div>
        <div>
          <label>
            Set a target for the goal
            <InputText placeholder="eg. 150" value={goal?.target} onChange={handleTarget} />
          </label>
        </div>
      </div>
      <GoalDescription value={goal?.description || 'HubSpot goal description'} />
    </div>
  );
};

export default HubspotGoal;

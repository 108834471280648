const StreamUtils = (function () {
  const getUsername = (user) => {
    if (user?.first_name || user?.last_name)
      return `${user?.first_name || ''} ${user?.last_name || ''}`;
    return user?.email;
  };

  return { getUsername };
})();

export default StreamUtils;

import React, { useMemo } from 'react';
import ReviewRoleplayDescriptionHeader from './ReviewRoleplayDescriptionHeader';
import styles from './ReviewRoleplay.module.scss';
import ReviewRoleplayDescriptionRating from './ReviewRoleplayDescriptionRating';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayHeader from '../Common/RoleplayHeader';
const ReviewRoleplayDescription = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);
  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);
  const showHeader = useMemo(
    () => reviewScenarioSelected?.response?.status !== null,
    [reviewScenarioSelected]
  );
  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);
  return (
    <div className={`${styles.background} ${styles.descriptionContainer}`}>
      <RoleplayHeader roleplayView={roleplayView} leadView={true} />
      {(!showHeader || (showRetake && !isRetakeQualified)) && <ReviewRoleplayDescriptionHeader />}
      <ReviewRoleplayDescriptionRating />
    </div>
  );
};

export default ReviewRoleplayDescription;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecorderStore } from './VideoRecorder.store';
import { useReactMediaRecorder } from 'react-media-recorder';
import { toastFlashMessage } from './../../utils/index';

const VideoRecorderLogic = () => {
  const history = useHistory();

  // Store handlers
  const store_start = useRecorderStore((s) => s.startRecording);
  const store_handleStop = useRecorderStore((s) => s.stopRecording);
  const store_isRecording = useRecorderStore((s) => s.isRecording);
  const store_isEnableRecording = useRecorderStore((s) => s.isEnableRecording);
  const store_saveVideo = useRecorderStore((s) => s.setRecordingData);
  const store_cleanVideo = useRecorderStore((s) => s.cleanVideo);
  const store_url = useRecorderStore((s) => s.resourceUrl);
  const store_stopEnableRecording = useRecorderStore((s) => s.stopEnableRecording);
  const onDiscard = useRecorderStore((s) => s.onDiscard);
  const onEnd = useRecorderStore((s) => s.onEnd);

  // Media recorder hook

  const {
    error: screen_error,
    status: screen_status,
    startRecording: screen_startRecording,
    stopRecording: screen_stopRecording,
    pauseRecording: screen_pause,
    resumeRecording: screen_resume,
    clearBlobUrl: screen_clearBlobUrl,
    mediaBlobUrl: screen_blobUrl,
    isAudioMuted: screen_isMuted,
    muteAudio: screen_mute,
    unMuteAudio: screen_unmute,
  } = useReactMediaRecorder({
    screen: true,
    audio: true,
  });

  // Error handler
  useEffect(() => {
    if (screen_error) {
      toastFlashMessage('Recording Cancelled', 'info');
      handleRecordingStopped();
    }
  }, [screen_error]);

  // Status control
  const [wasDiscarded, setWasDiscarded] = useState(false);
  const [wasRestarted, setWasRestarted] = useState(false);
  const isRecordingActive = store_isRecording; // User is recording a video
  const isEnableRecording = store_isEnableRecording;
  const isMuted = screen_isMuted;
  const isPaused = screen_status === statusType.paused;
  const isStopped = screen_status === statusType.stopped;

  // Recording handlers

  const handleRecordingStopped = () => {
    store_handleStop(); // Tells the store that the recording stopped

    // Handle save video info to store if not discarded
    if (!wasDiscarded && !wasRestarted) saveRecordingInfo(screen_blobUrl);

    // Resets default configuration
    resetConfig();

    // Redirect where recording feature was selected
    if (!wasDiscarded && !wasRestarted) {
      store_stopEnableRecording();
      onEnd();
      history.push(store_url);
    }

    if (wasRestarted) {
      store_start();
      start();
    }
  };

  const resetConfig = () => {
    setTime(0);
    screen_unmute();
    setShowWebcam(true);
    setIsFullWebcam(false);
    setWasDiscarded(false);
    setWasRestarted(false);
    setIsExpanded(true);
  };

  const saveRecordingInfo = async (blobUrl) => {
    if (!blobUrl) return;

    // Saves video recorded info to store for global access
    const video = await fetch(blobUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], `recording.mp4`, {
            type: 'video/mp4',
          })
      );
    store_saveVideo(video);
  };

  // Call with button, stops the recording with hook
  const handleStop = () => {
    if (isRecordingActive) screen_stopRecording();
  };

  const handleStart = () => {
    store_start();
    // Minimize bar when recording starts
    if (isExpanded) toggleMinimizedBar();
  };

  // Handle stop if screen is stopped with "stop sharing" or button
  useEffect(() => {
    if (isStopped) handleRecordingStopped();
  }, [isStopped]);

  const handleMute = () => (isMuted ? screen_unmute() : screen_mute());
  const handlePause = () => (isPaused ? screen_resume() : screen_pause());

  const handleDiscard = () => {
    setWasDiscarded(true);
    handleStop();
    store_handleStop();
    if (!isRecordingActive) resetConfig();
    store_stopEnableRecording();
    onDiscard();
    history.push(store_url);
  };

  const handleRestart = () => {
    setWasRestarted(true);
    handleStop();
  };

  // Media recorder initialization

  const start = () => {
    screen_clearBlobUrl(); // Cleans previous recording
    store_cleanVideo(); // Cleans previous recording data on store
    screen_startRecording(); // Start recording automatically
  };

  useEffect(() => {
    if (isRecordingActive) start();
  }, [isRecordingActive]);

  // Time control of the recording

  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = null;
    // Starts timer when recording
    if (screen_status === statusType.recording) {
      interval = setInterval(() => setTime((t) => t + 1), 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [screen_status]);

  useEffect(() => {
    // Alert when reaching limit time
    if (time === 240) toastFlashMessage('1 minute left of recording', 'warning');
    // Auto save recording where 5 minutes limit reached
    if (time === 300) {
      toastFlashMessage('Recording ended', 'success');
      handleStop();
    }
  }, [time]);

  // Webcam handler
  const [showWebcam, setShowWebcam] = useState(true);
  const [isFullWebcam, setIsFullWebcam] = useState(false);

  // Recording options
  const onScreenAndCamera = () => {
    setShowWebcam(true);
    setIsFullWebcam(false);
  };

  const onScreenOnly = () => {
    setShowWebcam(false);
    setIsFullWebcam(false);
  };

  const onWebcamOnly = () => {
    setShowWebcam(true);
    setIsFullWebcam(true);
  };

  // Handle minimize recording bar
  const videoRecordingBar = useRef(null);

  const className = 'minimized';
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleMinimizedBar = () => {
    const bar = videoRecordingBar.current;
    const expanded = !bar.classList.contains(className);
    setIsExpanded(!expanded);

    if (expanded) bar.classList.add(className);
    else bar.classList.remove(className);
  };

  return {
    isPaused,
    isMuted,
    isRecordingActive,
    isEnableRecording,
    time,
    showWebcam,
    isFullWebcam,
    handleMute,
    handlePause,
    handleStop,
    handleStart,
    handleDiscard,
    handleRestart,
    //
    isExpanded,
    videoRecordingBar,
    toggleMinimizedBar,
    // recording options
    onScreenAndCamera,
    onScreenOnly,
    onWebcamOnly,
  };
};

export default VideoRecorderLogic;

const statusType = {
  paused: 'paused',
  recording: 'recording',
  stopped: 'stopped',
};

import { Grid, Avatar } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { InputSelect, ProgressBar } from '../../../shared_ui_components';
import { format, parseISO } from 'date-fns';
import { TooltipCustom } from '../../../utils';
import Shimmer from './Shimmer';

const options = [
  { value: '1', label: 'This week' },
  { value: '2', label: 'This week' },
  { value: '3', label: 'This week' },
];

export default function SalesPerformers(props) {
  const { users, type = '' } = props;
  return (
    <div className={styles.salesPerformersWrapper}>
      <div className={styles.topBlk}>
        <Grid container direction="row" alignItems="center" className={styles.topRow}>
          <Grid item lg={8} md={8} sm={12} xs={12} className={styles.leftBlk}>
            <h4 className="heading6">
              {type == 'top'
                ? `Doing great`
                : `Needs attention
`}
            </h4>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={styles.rightBlk}>
            <div className={styles.filterBlk + ' hidden'}>
              <InputSelect
                className="reactSelect"
                closeMenuOnSelect={true}
                options={options}
                isSearchable={false}
                placeholder={'Select'}
                noOptionsMessage={() => 'No matches found'}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.titleBlk}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={`${styles.titleRow} ${styles.noTransform}`}
        >
          <Grid item lg={7} md={7} sm={12} xs={12} className={styles.leftBlk}>
            <span className="supportTextSemibold">Name</span>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} className={styles.rightBlk}>
            <span className={styles.total + ' supportTextSemibold'}>Total Progress</span>
          </Grid>
        </Grid>
      </div>
      <div className={styles.bottomBlk}>
        {users && users.length
          ? users.map((user, key) => (
              <div className={styles.performersItem}>
                <Grid container direction="row" alignItems="center" className={styles.usersRow}>
                  <Grid item lg={7} md={5} sm={12} xs={12} className={styles.leftBlk}>
                    <div className={styles.profileWrapper}>
                      <div className={styles.imgBlk}>
                        {user.profile_pic ? (
                          <img
                            className={styles.profilePic}
                            src={user.profile_pic}
                            alt="Profile pic"
                          />
                        ) : (
                          <Avatar className={styles.profilePic + ' avatar ' + styles.avatar}>
                            {user.full_name ? user.full_name[0] : 'S'}
                          </Avatar>
                        )}
                      </div>
                      <div className={styles.contentBlk}>
                        {user?.full_name.length < 26 ? (
                          <h5 className="supportTextSemibold">{user?.full_name}</h5>
                        ) : (
                          <TooltipCustom
                            placement="top"
                            arrow
                            title={<div className="">{user?.full_name}</div>}
                          >
                            <h5 className="supportTextSemibold">
                              {user?.full_name.substring(0, 22)}...
                            </h5>
                          </TooltipCustom>
                        )}
                        <h6 className="supportText">{`Last check-in: ${
                          user.last_checkin ? format(parseISO(user.last_checkin), 'do MMM') : '--'
                        }`}</h6>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12} className={styles.rightBlk}>
                    <div className={styles.progressBlk}>
                      <ProgressBar
                        max={100}
                        value={user.total_target}
                        className={`${styles.salesProgressBar} ${
                          type == 'bottom' || user.total_target < 50 ? styles.danger : ''
                        }`}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))
          : 'No data available'}
      </div>
      {/* <Shimmer type="TrackingOverviewSalesPerformance" /> */}
    </div>
  );
}

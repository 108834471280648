import React, { useMemo, useState } from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath, goalFrequencies } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import GoalCard from './GoalCard';
import Organization from 'infrastructure/Organization';
import { useHubspotStore } from 'features/hubspot/Hubspot.store';
import { useGetHubspotSettingsQuery } from 'features/hubspot/Hubspot.queries';
import { FeatureFlag } from 'controllers/_exports';
import { useGoalsSettingsStore } from './NewSettingsModal/NewSettingsModal.store';
import { useTrackingStore } from '../tracking.store';
import HubspotUtils from 'features/hubspot/Hubspot.utils';

export default function GoalListing(props) {
  const { category: propCategory = {}, featuredImg, type = '', isAdmin } = props;

  const enabled = HubspotUtils.isIntegrated();
  const { data, isLoading } = useGetHubspotSettingsQuery({ enabled });
  const openHubspot = useHubspotStore((s) => s?.openSettings);
  const isOpen = useHubspotStore((s) => s?.settingsModalOpen);

  const openNewSettings = useGoalsSettingsStore((state) => state?.openSettings);
  const order = useTrackingStore((state) => state?.all_goals_order);
  const activityGoalsData = useTrackingStore((state) => state?.activityGoalsData);

  const category = useMemo(() => {
    if (propCategory.is_organisation) return propCategory;
    return activityGoalsData;
  }, [propCategory, activityGoalsData]);

  const ConfigMenu = () => {
    // Menu settings
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenSettings = (event) => setAnchorEl(event.currentTarget);

    const menu = useMemo(() => {
      if (FeatureFlag.enabled('SWAP_SALES_GOALS') && isAdmin && type === 'salesGoals')
        return [
          {
            label: `Configure ${category.name}`,
            icon: imageBasePath + 'settings.svg',
            onClick: () => {
              openNewSettings();
              setAnchorEl(null);
            },
          },
        ];

      const newMenu = [];
      if (isAdmin)
        newMenu.push({
          label: `Configure ${category.name}`,
          icon: imageBasePath + 'settings.svg',
          onClick: () => {
            props.handleEditGoals(type);
            setAnchorEl(null);
          },
        });

      if (!data || !Object.keys(data)?.length > 0) return newMenu;
      if (!!data?.status && !Organization.isRep() && !category.name?.includes('Goals') && isAdmin)
        newMenu.push({
          label: `Configure HubSpot`,
          icon: imageBasePath + 'hubspot_logo.svg',
          onClick: () => {
            openHubspot();
            setAnchorEl(null);
          },
        });

      return newMenu;
    }, []);

    if (!menu?.length > 0 || (enabled && isLoading)) return <></>;

    return (
      <Grid item lg={4} md={4} sm={12} xs={12} className="textRight">
        {menu?.length === 1 && (
          <img
            className={styles.usersMoreAction}
            src={imageBasePath + 'configure.svg'}
            alt="Settings"
            onClick={menu[0]?.onClick}
            style={{ cursor: 'pointer' }}
          />
        )}
        {menu?.length > 1 && (
          <>
            <img
              className={styles.usersMoreAction}
              src={imageBasePath + 'configure.svg'}
              alt="Settings"
              onClick={handleOpenSettings}
              style={{ cursor: 'pointer' }}
            />
            <Menu
              keepMounted={false}
              id={'goals-menu'}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={(e) => setAnchorEl(null)}
            >
              {menu.map(({ onClick, label, icon }, index) => (
                <MenuItem key={index} onClick={onClick}>
                  <span style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <img alt="" src={icon} height={18} /> {label}
                  </span>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Grid>
    );
  };

  const getGoals = useTrackingStore((state) => state?.getOrderedGoals);
  const isHubspotEnabled = useHubspotStore((state) => state?.isEnabled);

  const goals = useMemo(() => {
    if (!category.goals?.length > 0) return [];
    if (type === 'orgGoals') return [...category.goals?.filter((g) => !!g?.status)];
    return [...getGoals()?.filter((g) => !!g?.status)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, data, type, isOpen, order, isHubspotEnabled]);

  return (
    <>
      <section className={styles.goalListingSection}>
        <div className={styles.goalContentWrapper}>
          <Grid container direction="row" spacing={3} className={styles.titleRow}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <div className={styles.titleWrapper}>
                <img
                  className={styles.featuredImg}
                  src={`${imageBasePath}${featuredImg}`}
                  alt="Sales Activity"
                />
                <div className={styles.info}>
                  <h2 className="heading5">
                    {category.name}
                    <span className={styles.tag + ' supportText'}>
                      {goalFrequencies.find((item) => item.id == category.frequency)?.label}
                    </span>
                  </h2>
                  <p className="paragraph">{category.description}</p>
                </div>
              </div>
            </Grid>
            {<ConfigMenu />}
          </Grid>
          <Grid container direction="row" spacing={3} className={styles.contentRow}>
            {goals.map((goal) => (
              <Grid item lg={3} md={3} sm={12} xs={12} key={goal.uuid}>
                <GoalCard
                  key={goal.uuid}
                  goal={goal}
                  bgclassName={
                    category.is_organisation ? styles.backgroundBlue : styles.backgroundGreen
                  }
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </>
  );
}

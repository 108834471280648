import React, { useEffect, useState, useContext } from 'react';
import { Grid, List, ListItem, Switch } from '@material-ui/core';
import { imageBasePath, goalFrequencies } from '../../../constants';
import { lastActivity, getStartEndDateOfCheckin, changeTimezone } from '../../../utils';
import styles from '../../../assets/styles/tracking.module.scss';
import { NoResult, CustomLink } from '../../../shared_ui_components';
import { CheckinGoalsList } from './';
import { format, parseISO, startOfWeek, endOfWeek } from 'date-fns';
import { GlobalContext } from '../../../context/GlobalContext';

export default function UserCheckinCard(props) {
  const { currentCheckin, weekCheckinExist, chekcinOpenSet, latestCheckin, hanldeEditCheckin } =
    props;
  const [date, setDate] = useState({ start_date: '', end_date: '' });
  const [salesGoals, salesGoalsSet] = useState({});
  const [frequency, frequencySet] = useState(null);
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    if (props.currentCheckin && props.currentCheckin.length) {
      let salesGoals = props.currentCheckin.find((cat) => !cat.is_organisation);
      if (salesGoals) {
        salesGoalsSet(salesGoals);
        frequencySet(salesGoals.frequency);
      }
      setDate(getStartEndDateOfCheckin(props.currentCheckin));
    }
  }, [props.currentCheckin]);

  return (
    <div className={styles.userCheckinCardOuterWrapper}>
      {latestCheckin.checkin_exists ? (
        <div className={styles.editCheckinTitle}>
          <div className={styles.info}>
            <img src={imageBasePath + 'info_icon.svg'} alt="info" />
            <p className="supportText">
              Congratulations, you have already checked in for the{' '}
              {goalFrequencies.find((goal) => goal.id === salesGoals?.frequency)?.singleLable}{' '}
              <br />
              Latest check-in,{' '}
              <span className="supportTextSemibold">
                {salesGoals?.frequency === 1 && date?.start_date
                  ? format(date.start_date, 'dd MMM yyyy')
                  : date?.start_date && date?.end_date
                  ? format(date.start_date, 'dd MMM yyyy') +
                    ' - ' +
                    format(date.end_date, 'dd MMM yyyy')
                  : null}{' '}
              </span>
            </p>
          </div>
          <CustomLink
            className="primaryBtn smallBtn"
            linkimg="goal_icon_white.svg"
            linktext="Edit Check-in"
            onClick={(e) => {
              hanldeEditCheckin(
                format(changeTimezone(new Date(latestCheckin.last_checkin)), 'yyyy-MM-dd'),
                true
              );
              chekcinOpenSet(true);
            }}
          />
        </div>
      ) : null}
      {!latestCheckin.checkin_exists ? (
        <div className={styles.noCheckinWrapper}>
          <div className={styles.noCheckinContent}>
            <h5 className="heading6">Latest check-in</h5>
            <NoResult
              image="no_user_checkin.svg"
              subtext={`Just a reminder that you still need to check in every ${
                goalFrequencies.find((goal) => goal.id === salesGoals?.frequency)?.singleLable
              }!`}
              btnText="Check-in"
              linkimg="goal_icon_white.svg"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                chekcinOpenSet(true);
              }}
            />
          </div>
        </div>
      ) : null}
      {latestCheckin.checkin_exists ? (
        <div className={styles.userCheckinContentWrapper}>
          <div className={styles.tableTitleBlk}>
            <Grid container direction="row" alignItems="center" className={styles.tableTitleRow}>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                <h6 className="heading6">Latest check-in</h6>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.rightBlk + ' textRight'}>
                <p className="supportText">
                  <img src={imageBasePath + 'checkin_icon.svg'} alt="image" />
                  <span>{lastActivity(latestCheckin.last_checkin)}</span>
                </p>
              </Grid>
            </Grid>
          </div>
          <div className={styles.userTableContentBlk}>
            <CheckinGoalsList categories={currentCheckin} role={state.role} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

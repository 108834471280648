import { useMemo } from 'react';
import { FeatureFlag } from 'controllers/_exports';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath, goalUnits } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { CustomTargetValue, NoResult, CustomDeltaValue } from '../../../shared_ui_components';
import { CustomNumberFormatter } from '../../../utils';

export default function CheckinGoalsList(props) {
  const { categories = [], tableTitle, role } = props;
  const filteredCategories = useMemo(() => {
    if (!categories?.length > 0) return [];
    if (FeatureFlag.enabled('REMOVE_SALES_GOALS'))
      return categories.filter((c) => !c.is_organisation);
    return categories;
  }, [categories]);
  return (
    <div className={styles.checkinGoalContainer}>
      <div className={styles.goalContainer}>
        {filteredCategories.map((category) => (
          <div
            key={category.id}
            className={`${styles.goalOuterWrapper} ${
              category?.is_organisation ? styles.orgGoalOuterWrapper : styles.salesGoalOuterWrapper
            }`}
          >
            <div className={styles.titleBlk}>
              <div className={styles.info}>
                {category?.is_organisation ? (
                  <img src={imageBasePath + 'organisation_goals.svg'} alt="Sales Goals" />
                ) : category?.name?.toLowerCase().includes('hubspot') ? (
                  <img src={imageBasePath + 'hubspot_logo.svg'} alt="HubSpot" />
                ) : (
                  <img src={imageBasePath + 'sales_goals.svg'} alt="Sales activity" />
                )}
                <p className="supportTextSemibold">{category?.name}</p>
              </div>
            </div>
            {props.tableTitle ? (
              <div className={styles.tableTitleBlk}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={`${styles.tableTitleRow} ${styles.noTransform}`}
                >
                  <Grid item xs={6} className={styles.leftBlk}>
                    <span className="supportTextSemibold">Goal</span>
                  </Grid>
                  <Grid item xs={6} className={styles.rightBlk + ' textCenter'}>
                    <span className="supportTextSemibold">Achievement</span>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            <div className={styles.tableContentBlk}>
              {category?.goals?.map((goal) => (
                <div key={goal.uuid} className={styles.tableItem}>
                  {
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.contentRow}
                    >
                      <Grid item xs={6} className={styles.leftBlk}>
                        <div className={styles.info}>
                          <img
                            src={`${imageBasePath}${
                              goalUnits.find((item) => item.id == goal.unit)[
                                category.is_organisation ? `iconBlue` : `iconGreen`
                              ]
                            }`}
                            alt=""
                          />
                          <p className="supportTextSemibold">{goal?.name}</p>
                        </div>
                      </Grid>
                      <Grid item xs={6} className={styles.rightBlk + ' textCenter'}>
                        <div className={styles.achivementBlk}>
                          <List className="listInline  flexCentered">
                            {goal?.delta_value ? (
                              <ListItem className="listInlineItem">
                                <CustomDeltaValue value={goal?.delta_value} />
                              </ListItem>
                            ) : null}
                            <ListItem className="listInlineItem">
                              <span className={styles.value + ' heading5'}>
                                {goal.unit == 2 && goal.target
                                  ? `$${CustomNumberFormatter(goal.target)}`
                                  : `${CustomNumberFormatter(goal.target)}`}
                              </span>
                            </ListItem>
                            {['user'].includes(role?.slug) && category?.is_organisation ? null : (
                              <ListItem className={styles.customTargetBlk + ' listInlineItem'}>
                                <CustomTargetValue unit={goal.unit} value={goal?.expected_target} />
                              </ListItem>
                            )}
                          </List>
                        </div>
                      </Grid>
                    </Grid>
                  }
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import { useState, useEffect, useMemo } from 'react';
import {
  SetupOnboarding,
  AddDocument,
  OnboardingDashboard,
  OnboardingLibrary,
  SetupComplete,
  Shimmer,
} from './';
import { imageBasePath } from '../../../constants';
import {
  getOnboardingSteps,
  enableDisableApi,
  deleteCustomStepApi,
  onboardinglikeDislikeApi,
  reorderStepsApi,
} from '../apiServices';
import styles from '../../../assets/styles/onboarding.module.scss';
import { DashboardHeader, ConfirmationDialog } from '../../../shared_elements';
import { toastFlashMessage } from '../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useRecorderStore } from '../../../shared_ui_components/VideoRecorder/VideoRecorder.store';

export default function AdminOnboardingContainer(props) {
  let history = useHistory();
  let location = useLocation();
  const [loader, setLoader] = useState(true);
  const [isPublished, setIsPublished] = useState('');
  const [state, setState] = useState({
    steps: [],
    activeStep: {},
    moduleId: '',
  });
  const [addContent, setAddContent] = useState(false);
  const [deleteContent, setDeleteContent] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [addCustomStep, setAddCustomStep] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const { steps, activeStep, moduleId } = state;

  const fetchOnboardingSteps = (activeStep = '') => {
    if (activeStep) {
      setState((prevState) => ({
        ...prevState,
        activeStep: activeStep,
      }));
    }
    getOnboardingSteps().then((response) => {
      if (response.statusCode === 200) {
        setIsPublished(response.data?.is_published);
        if (!activeStep) {
          setState((prevState) => ({
            ...prevState,
            activeStep: response.data?.onboarding?.steps?.find((item) => item.status === 1),
          }));
        }
        setState((prevState) => ({
          ...prevState,
          steps: response.data?.onboarding?.steps.map((item, id) => {
            return { ...item, id };
          }),
          moduleId: response.data?.onboarding?.uuid,
        }));
        setLoader(false);
        if (!response.data?.is_published) {
          history.push(`/onboarding`);
        }
      }
    });
  };

  useEffect(() => {
    fetchOnboardingSteps();
  }, []);

  const isReadyToPublish = (steps = []) => {
    return !steps.some((step) => step.is_required && !step.is_content_added && step.status == 1);
  };

  const handleChangeStep = (step) => {
    setState({
      ...state,
      activeStep: step,
    });
  };
  const handleReorderStep = (steps_order) => {
    reorderStepsApi({ steps_id: steps_order }).then((response) => {
      if (response.statusCode === 200) {
        const { steps } = response.data;
        setState({ ...state, steps });
      }
    });
  };

  const handleAddContent = () => {
    setAddContent(true);
  };

  // Handle automatic open after video recording
  const recorded_file = useRecorderStore((s) => s.video);

  useEffect(() => {
    if (recorded_file) handleAddCustomStep();
  }, [recorded_file]);

  const handleEnabelDisable = () => {
    enableDisableApi({ uuid: activeStep.uuid, enable: activeStep.status ? 0 : 1 }).then(
      (response) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          fetchOnboardingSteps(response.data);
        }
      }
    );
  };

  const handleDeleteCustomStep = () => {
    setDeleteLoader(true);
    deleteCustomStepApi(activeStep.uuid).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        fetchOnboardingSteps();
        setTimeout(() => {
          setDeleteContent(false);
        }, 500);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handlePublished = () => {
    // loader
    fetchOnboardingSteps();
  };
  const handleAddCustomStep = () => {
    setAddCustomStep(true);
  };

  const updateActiveStep = (key, val) => {
    switch (key) {
      case 'comments':
        setState({
          ...state,
          activeStep: {
            ...state.activeStep,
            comments: val,
          },
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid ? { ...state.activeStep, comments: val } : step
          ),
        });
        return true;
      case 'likeDislike':
        let activeStepUpdated = {
          ...state.activeStep,
          comments: state.activeStep.comments.map((comment) =>
            comment.id === val.id ? val : comment
          ),
        };
        setState({
          ...state,
          activeStep: activeStepUpdated,
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid ? activeStepUpdated : step
          ),
        });
        return true;
      case 'delete':
        setState({
          ...state,
          activeStep: {
            ...state.activeStep,
            comments: state.activeStep.comments.filter(({ id }) => id !== val.id),
          },
          steps: state.steps.map((step) =>
            step.uuid === activeStep.uuid
              ? {
                  ...state.activeStep,
                  comments: state.activeStep.comments.filter(({ id }) => id !== val.id),
                }
              : step
          ),
        });
        return true;
      default:
        return false;
    }
  };

  const returnFormData = (activeStep = {}) => {
    const formData = {
      name: activeStep?.name ? activeStep.name : '',
      description: activeStep?.description ? activeStep.description : '',
      tags:
        activeStep?.tags && activeStep?.tags.length
          ? activeStep.tags.map((tag) => {
              return { label: tag.name, value: tag.name };
            })
          : [],
      is_required: activeStep?.is_required ? true : activeStep.is_required === false ? false : true,
      content: activeStep?.is_content_added ? activeStep.content : '',
      input_type: activeStep?.input_type ? activeStep.input_type : 1,
      content_type: activeStep?.content_type ? activeStep?.content_type : 0,
      suggest_duration: activeStep?.suggest_duration ? activeStep?.suggest_duration : 300
    };
    return formData;
  };

  const onboardinglikeDislike = (val) => {
    onboardinglikeDislikeApi(activeStep.uuid, { like: val }).then((response) => {
      if (response.statusCode === 200) {
        setState({
          ...state,
          activeStep: response.data,
        });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (Object.keys(querys).length && querys.active) {
      const active = querys.active;
      if (activeTab != active) {
        setActiveTab(active);
      }
    } else {
      setActiveTab('overview');
    }
  }, [location]);
  const handleChageTab = (val) => {
    history.push(`/onboarding?active=${val}`);
  };
  return (
    <>
      {loader ? (
        <Shimmer type="OnboardingLanding" />
      ) : isPublished === true ? (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'onboarding_icon.svg'}
            title="Onboarding"
            tabs={[
              {
                value: 'overview',
                title: 'Overview',
                isActive: activeTab == 'overview' ? true : false,
              },
              {
                value: 'library',
                title: 'Library',
                isActive: activeTab == 'library' ? true : false,
              },
            ]}
            handleChange={handleChageTab}
            steps={steps}
          />
          {activeTab == 'overview' ? (
            <OnboardingDashboard />
          ) : (
            <OnboardingLibrary
              steps={steps}
              handleChangeStep={handleChangeStep}
              activeStep={activeStep}
              handleAddCustomStep={handleAddCustomStep}
              handleDeleteCustomStep={() => setDeleteContent(true)}
              handleEnabelDisable={handleEnabelDisable}
              handleUpdateContent={handleAddContent}
              handleReorderStep={handleReorderStep}
              isAdmin={true}
              onboardinglikeDislike={onboardinglikeDislike}
              updateActiveStep={updateActiveStep}
              isPublished={true}
            />
          )}
        </div>
      ) : isPublished === false && steps && steps.length ? (
        <>
          {isReadyToPublish(steps) ? (
            <SetupComplete handlePublished={handlePublished} />
          ) : (
            <SetupOnboarding
              totalSteps={steps.filter((step) => step.is_required && step.status == 1)?.length}
              attendedSteps={
                steps.filter(
                  (step) => step.is_required && step.status == 1 && step.is_content_added
                )?.length
              }
            />
          )}
          <OnboardingLibrary
            steps={steps}
            handleChangeStep={handleChangeStep}
            activeStep={activeStep}
            handleAddCustomStep={handleAddCustomStep}
            handleDeleteCustomStep={() => setDeleteContent(true)}
            handleEnabelDisable={handleEnabelDisable}
            handleReorderStep={handleReorderStep}
            handleUpdateContent={handleAddContent}
            isAdmin={true}
            onboardinglikeDislike={onboardinglikeDislike}
            updateActiveStep={updateActiveStep}
            isPublished={false}
          />
        </>
      ) : null}
      <AddDocument
        id={addContent ? activeStep.uuid : addCustomStep ? moduleId : ''}
        open={addContent || addCustomStep}
        handleClose={() => {
          setAddContent(false);
          setAddCustomStep(false);
        }}
        type={
          addContent
            ? activeStep?.is_content_added
              ? 'editContent'
              : 'addContent'
            : addCustomStep
            ? 'addStep'
            : ''
        }
        formData={addContent ? returnFormData(activeStep) : addCustomStep ? returnFormData() : {}}
        handleUpdateList={(updatedStep) => fetchOnboardingSteps(updatedStep)}
        isCustom={addContent ? (activeStep.is_predefined ? false : true) : true}
      />
      <ConfirmationDialog
        open={deleteContent}
        title={
          <>
            Are you sure you want to delete the content <br />{' '}
            <strong className="heading3">{`${activeStep?.name}?`}</strong>
          </>
        }
        description="This action can't be undone once its deleted"
        acceptText="Yes, Delete"
        rejectText="No, Cancel"
        isDelete={true}
        image={imageBasePath + 'delete_confirmation.svg'}
        handleClose={() => {
          setDeleteContent(false);
          setDeleteLoader(false);
        }}
        handleSubmit={handleDeleteCustomStep}
        loader={deleteLoader}
      />
    </>
  );
}

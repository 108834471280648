import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Goal from './Goal';
import { useGoalsSettingsStore } from './NewSettingsModal.store';
import {
  useReorderGoalsMutation,
  useUpdateGoalsSettingsMutation,
} from 'features/tracking/tracking.queries';
import {
  CustomButton,
  CustomSwitch,
  CustomTooltipWhite,
  TextArea,
} from 'shared_ui_components/index.js';
import { useTrackingStore } from 'features/tracking/tracking.store';
import styles from './NewSettings.module.scss';
import FrequencyOptions from './FrequencyOptions';
import HubspotUtils from 'features/hubspot/Hubspot.utils';
import { imageBasePath } from 'constants';
import InfoFrequency from './InfoFrequency';
import { useUpdateHubspotSettingsMutation } from 'features/hubspot/Hubspot.queries';
import { CustomGoal } from '../SalesGoalsDialog';

const NewSettingsModal = () => {
  const open = useGoalsSettingsStore((state) => state?.settingsModalOpen);
  const onClose = useGoalsSettingsStore((state) => state?.closeSettings);

  const setGoalsData = useTrackingStore((state) => state?.setActivityGoalsData);
  const goalsData = useTrackingStore((state) => state?.activityGoalsData);
  const hubSpotData = useTrackingStore((state) => state?.hubSpotGoalsData);
  const getGoals = useTrackingStore((state) => state?.getOrderedGoals);

  useEffect(() => {
    return function clean() {
      onClose();
    };
  }, [onClose]);

  // Settings data
  const [newGoalsData, setNewGoalsData] = useState({});

  useEffect(() => {
    if (!goalsData || !Object.keys(goalsData).length > 0) return;
    setNewGoalsData({
      description: goalsData?.description,
      frequency: goalsData?.frequency,
      removed_goals: [],
    });
  }, [goalsData, open]);

  const handleChange = (key, value) => setNewGoalsData((prev) => ({ ...prev, [key]: value }));

  // Hubspot data
  const [newHubSpotData, setNewHubSpotData] = useState({});

  useEffect(() => {
    if (!Object.keys(hubSpotData).length > 0) return;
    setNewHubSpotData({
      description: hubSpotData?.description,
      status: hubSpotData?.status,
    });
  }, [hubSpotData, open]);

  const hubSpotChangeStatus = (e) =>
    setNewHubSpotData((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }));

  // Goals list to reorder and modify
  const [goals, setGoals] = useState([]);

  useEffect(() => {
    if (!open) {
      setAddCustomGoal(false);
      return;
    }
    setGoals(getGoals());
  }, [open, getGoals]);

  // Individual goal data change handler
  const modifyGoal = (uuid, property, newValue) =>
    setGoals((prev) => {
      const newGoals = [...prev];
      const goal = newGoals?.find((g) => g?.uuid === uuid);
      goal[property] = newValue;
      return newGoals;
    });

  const handleDescription = (uuid, value) => modifyGoal(uuid, 'description', value);
  const handleStatus = (uuid, value) => modifyGoal(uuid, 'status', value);
  const handleTarget = (uuid, value) => modifyGoal(uuid, 'target', value);

  const { mutate: saveOrder, isLoading: load_order } = useReorderGoalsMutation();
  const { mutate: saveHubspot, isLoading: load_hub } = useUpdateHubspotSettingsMutation();
  const { mutate: saveGoals, isLoading: load_goals } = useUpdateGoalsSettingsMutation();
  const loading = load_hub || load_order || load_goals;

  const handleSave = () => {
    // Save hubspot data
    const hubSpotBody = {
      ...newHubSpotData,
      goals: goals?.filter((g) => g?.isFromHubspot),
    };
    saveHubspot({ settings: hubSpotBody, id: hubSpotData?.id });

    // Save new activity goals data
    const newGoals = goals
      ?.filter((g) => g?.isActivityGoal || g?.is_new)
      .map((g) => {
        if (!g?.is_new) return g;
        delete g.uuid;
        return g;
      });

    const body = {
      ...newGoalsData,
      goals: newGoals,
    };

    saveGoals(
      { id: goalsData?.id, body },
      {
        onSettled: () => {
          onClose();
        },
        onSuccess: (response) => {
          saveOrder({
            goals: goals?.map((goal) => {
              if (goal?.uuid) return goal?.uuid;
              const newGoal = response?.goals?.find((g) => g?.is_custom && g?.name === goal?.name);
              return newGoal?.uuid || '';
            }),
          });
          setGoalsData(response);
        },
      }
    );
  };

  // Custom goals
  const [addCustomGoal, setAddCustomGoal] = useState(false);
  const handleAddCustomGoal = (goal) => {
    setGoals((prev) => [...prev, goal]);
    setAddCustomGoal(false);
  };

  const handleDelete = (uuid) => {
    setNewGoalsData((prev) => ({ ...prev, removed_goals: [...prev?.removed_goals, uuid] }));
    setGoals((prev) => prev?.filter((goal) => goal?.uuid !== uuid));
  };

  // Edit custom contents
  const onStartEditing = (uuid) =>
    setGoals((goals) => goals.map((g) => (g?.uuid !== uuid ? g : { ...g, is_editing: true })));

  const onCancelEdit = (uuid) =>
    setGoals((goals) => goals.map((g) => (g?.uuid !== uuid ? g : { ...g, is_editing: false })));

  const handleEditCustomGoal = (goal) => {
    setGoals((goals) =>
      goals.map((g) => (g?.uuid !== goal?.uuid ? g : { ...goal, is_editing: false }))
    );
  };

  // DND handler
  const moveCard = (dragIndex, hoverIndex) => {
    const dragItem = goals[dragIndex];
    if (!dragItem) return;
    setGoals((prevState) => {
      const newOrder = [...prevState];
      const prevItem = newOrder.splice(hoverIndex, 1, dragItem);
      newOrder.splice(dragIndex, 1, prevItem[0]);
      return newOrder;
    });
  };

  if (!open) return null;
  return (
    <Dialog
      className={styles.newSettingsDialog}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <div className={styles.newSettingsModal}>
        <div className={styles.settingsSection}>
          <img alt="" src={imageBasePath + 'hash_icon.svg'} />
          <span>Sales Activity</span>
          <FrequencyOptions
            frequency={newGoalsData?.frequency}
            onFrequencyChange={(value) => handleChange('frequency', value)}
          />
          <TextArea
            label="Description"
            placeholder={placeholder}
            value={newGoalsData.description}
            onChange={(e) => handleChange('description', e.target.value)}
            className={styles.descriptionInput}
          />
          {HubspotUtils.isIntegrated() && (
            <div className={styles.hubspotSwitchContainer}>
              <CustomTooltipWhite tooltipIcon="info_icon.svg" title={newHubSpotData.description} />
              <p>Hubspot Integration</p>
              <span className={styles.hubspotSwitch}>
                <CustomSwitch
                  checked={!!newHubSpotData?.status}
                  label={!!newHubSpotData?.status ? 'Enabled' : 'Disabled'}
                  onChange={hubSpotChangeStatus}
                />
              </span>
            </div>
          )}
        </div>
        <div className={styles.goalsSection}>
          <div className={styles.goals}>
            <InfoFrequency />
            <br />
            <DndProvider backend={HTML5Backend}>
              {goals?.map((goal, index) =>
                goal?.is_editing ? (
                  <div className={styles.newGoal}>
                    <CustomGoal
                      goal={goal}
                      handleCloseCustomGoal={onCancelEdit}
                      handleAddCustomGoal={handleEditCustomGoal}
                    />
                  </div>
                ) : (
                  <Goal
                    index={index}
                    goal={goal}
                    moveCard={moveCard}
                    onStatusChange={handleStatus}
                    onTargetChange={handleTarget}
                    onDescriptionChange={handleDescription}
                    handleDelete={handleDelete}
                    handleEdit={onStartEditing}
                  />
                )
              )}
            </DndProvider>

            {addCustomGoal ? (
              <div className={styles.newGoal}>
                <CustomGoal
                  goal={{
                    is_custom: true,
                    is_new: true,
                    name: '',
                    status: 1,
                    unit: 1,
                    target: '',
                    uuid: new Date().getTime(),
                    frequency: newGoalsData.frequency,
                  }}
                  handleCloseCustomGoal={() => setAddCustomGoal(false)}
                  handleAddCustomGoal={handleAddCustomGoal}
                />
              </div>
            ) : (
              <span
                className="linkBtn"
                style={{ cursor: 'pointer', padding: '2rem 0' }}
                onClick={() => setAddCustomGoal(true)}
              >
                + Add new sales activity
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.saveContainer}>
        <span style={{ marginRight: '1rem' }}>
          <CustomButton className="tertiaryBtn" onClick={onClose}>
            Cancel
          </CustomButton>
        </span>

        <CustomButton disabled={loading} onClick={handleSave}>
          Save
        </CustomButton>
      </div>
    </Dialog>
  );
};

export default NewSettingsModal;

const placeholder =
  'Sales Activity are constantly tracked, making sure your company is on track. Note that the Sales activity are applicable for specific sales rep';

import React from 'react';
import RoleplayDropdownList from './RoleplayDropdownList';
import RoleplayDescription from './RoleplayDescription';
import RoleplayRatingCriteria from './RoleplayRatingCriteria';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayTitle from './RoleplayTitle';
import CreateScenarios from './CreateScenarios';

const RoleplayModalBody = () => {
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);

  return (
    <>
      <RoleplayDropdownList />
      {formDataAssignRoleplay?.roleplay?.value === -1 && <RoleplayTitle />}
      <RoleplayDescription />
      <RoleplayRatingCriteria />
      {formDataAssignRoleplay?.roleplay?.value === -1 && <CreateScenarios />}
    </>
  );
};

export default RoleplayModalBody;

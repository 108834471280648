import React from 'react';
import { imageBasePath } from 'constants';
import DateTime from 'infrastructure/Datetime';

import styles from './AssignedLearningPathDetails.module.scss';

const DateChip = ({ isUpdated, date = '' }) => {
  if (isUpdated)
    return (
      <div className={styles.chip}>
        <img src={`${imageBasePath}UpdatedActive.svg`} alt="" width={12} />
        <strong>{DateTime.formatddMMMYYYY(date)} </strong>
        <span>Updated</span>
      </div>
    );

  return (
    <div className={styles.chip}>
      <img src={`${imageBasePath}PublishedActive.svg`} alt="" width={12} />
      <strong>{DateTime.formatddMMMYYYY(date)}</strong>
      <span>Published date</span>
    </div>
  );
};

export default DateChip;

import React from 'react';
import { IconButton as MaterialButton, withStyles } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const IconButton = ({ onClick = () => null, disabled }) => {
  const IconButtonStyled = withStyles(() => ({
    root: {
      backgroundColor: '#0494FD',
      borderRadius: '8px',
      color: 'white',
      '&:hover': {
        backgroundColor: '#0284d6',
      },
      '&:active': {
        backgroundColor: 'rgba(4, 148, 253, 0.7)',
      },
      '&:disabled': {
        backgroundColor: '#ECEFF1',
      },
    },
    label: {
      margin: '0 5px',
    },
  }))(MaterialButton);

  return (
    <IconButtonStyled onClick={onClick} disabled={disabled}>
      <Send />
    </IconButtonStyled>
  );
};

export default IconButton;

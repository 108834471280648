import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import React from 'react';
import DatePicker from 'shared_ui_components/ScheduleTraining/Components/DatePicker';
import DateTime from 'infrastructure/Datetime' 
import styles from './AssignRoleplay.module.scss';
const RoleplayDeadline = () => {
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);

  const handleAcceptDate = (query = {}) => {
    setFormDataAssignRoleplay({ ...formDataAssignRoleplay, deadline: DateTime.formatDate(new Date(query)) + ' 00:00:00' });
  };
  return (
    <div>
      <p className={styles.deadlineTitle}>Deadline</p>
      <DatePicker
      InputLabel={'Deadline'}
        handleApply={(date) => handleAcceptDate(date)}
        defaultFilter={{
          date: formDataAssignRoleplay?.deadline
            ? DateTime.formatDate(new Date(formDataAssignRoleplay?.deadline))
            : DateTime.formatDate(new Date()),
        }}
      />
    </div>
  );
};

export default RoleplayDeadline;

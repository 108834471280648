import React from 'react';
import { Grid } from '@material-ui/core';

import IntegrationModal from './IntegrationModal/IntegrationModal';
import RedirectionHandler from './IntegrationModal/Modals/OAuth/RedirectionHandler';
import { useGetProvidersQuery } from '../Providers.queries';
import { useProvidersStore } from '../Providers.store';
import HubspotIntegrationCard from 'features/hubspot/components/HubspotIntegrationCard';

const ProviderSelector = () => {
  const { data = {} } = useGetProvidersQuery();

  const providerData = useProvidersStore((state) => state?.providerData);
  const onClose = useProvidersStore((state) => state?.deleteProviderData);

  const open = !!providerData;

  return (
    <div style={{ margin: '1.5rem 0' }}>
      <Grid lg={4} md={4} sm={12} xs={12}>
        <HubspotIntegrationCard providers={data?.providers} />
      </Grid>

      <IntegrationModal data={providerData} open={open} onClose={onClose} />
      <RedirectionHandler />
    </div>
  );
};

export default ProviderSelector;

import React from 'react';
import { useLearningPathsStore } from '../../../LearningPaths.store';
import AddContentBtn from '../AddContentBtn';

const AddTrainingModulesBtn = () => {
  const { openTrainingModulesModal } = useLearningPathsStore();
  return <AddContentBtn label="Add modules" onClick={openTrainingModulesModal} />;
};

export default AddTrainingModulesBtn;

import React, { useMemo } from 'react';
import styles from './RecordVideo.module.css';
import Timer from 'shared_ui_components/VideoRecorder/Timer/Timer';
import RecorderButton from 'shared_ui_components/VideoRecorder/RecorderButtons/RecorderButton';

import img_micOff from 'assets/recorder/mic_off.svg';
import img_micOn from 'assets/recorder/mic_on.svg';
import img_pause from 'assets/recorder/pause.svg';
import img_play from 'assets/recorder/play.svg';
import img_reset from 'assets/recorder/reset.svg';
import img_stop from 'assets/recorder/stop.svg';
import img_trash from 'assets/recorder/trash.svg';
import img_close from 'assets/recorder/cancel.svg';

const RecorderButtons = ({
  isRecording,
  prevURL,
  onCancelRecord,
  status,
  seconds,
  isAudioMuted,
  webcam,
  error,
  intervalID,
  onStart = () => null,
  onPause = () => null,
  onStop = () => null,
  onResume = () => null,
  onMute = () => null,
  onUnmute = () => null,
  onDelete = () => null,
  onRestart = () => null,
  toggleWebcam = () => null,
}) => {
  const isStatusRecording = useMemo(() => status === 'recording', [status]);

  return (
    <div>
      {!isRecording && !prevURL && (
        <div className={styles.recordButtons}>
          <RecorderButton
            withBorder
            alt="Cancel"
            tooltip="Cancel"
            onClick={onCancelRecord}
            src={img_close}
          />
          {/* <RecorderButton
            alt={webcam ? 'Disable webcam' : 'Enable webcam'}
            tooltip={webcam ? 'Disable webcam' : 'Enable webcam'}
            onClick={toggleWebcam}
          /> */}
          {!error && <Mic isAudioMuted={isAudioMuted} onMute={onMute} onUnmute={onUnmute} />}
          {!error && !intervalID && (
            <button className={styles.startButton} onClick={onStart}>
              Start Record
            </button>
          )}
        </div>
      )}
      {isRecording && !prevURL && !error && (
        <div className={styles.recordButtons}>
          <Mic isAudioMuted={isAudioMuted} onMute={onMute} onUnmute={onUnmute} />
          <RecorderButton
            withBorder
            onClick={isStatusRecording ? onPause : onResume}
            alt={isStatusRecording ? 'Pause' : 'Resume'}
            tooltip={isStatusRecording ? 'Pause' : 'Resume'}
            src={isStatusRecording ? img_pause : img_play}
          />
          <RecorderButton withBorder onClick={onStop} alt="Stop" tooltip="Stop" src={img_stop} />
          <Timer seconds={seconds} />
          <RecorderButton
            withBorder
            onClick={onRestart}
            tooltip="Restart"
            alt="Restart"
            src={img_reset}
          />
          <RecorderButton
            withBorder
            onClick={onDelete}
            alt="Delete"
            tooltip="Delete"
            src={img_trash}
          />
        </div>
      )}
    </div>
  );
};

const Mic = ({ isAudioMuted, onMute, onUnmute }) => (
  <RecorderButton
    withBorder
    alt={isAudioMuted ? 'Unmute' : 'Mute'}
    tooltip={isAudioMuted ? 'Unmute' : 'Mute'}
    onClick={isAudioMuted ? onUnmute : onMute}
    src={isAudioMuted ? img_micOff : img_micOn}
  />
);

export default RecorderButtons;

import React, { useEffect, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Card } from '@material-ui/core';

import styles from '../Payment.module.scss';
import {
  PaymentHeader,
  PaymentPagination,
  Introduction,
  Summary,
  BillingAddress,
  BillingDetails,
  CardInfo,
} from './Steps';

import { usePaymentStore } from '../../Payment.store';
import { Payment } from '../../Payment.utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentForm = ({ fullPage = true, processType, onSuccess }) => {
  // Store variables
  const paymentStore = usePaymentStore((state) => state);
  const { selectedProduct, currentStep, setStep } = paymentStore;

  const isVisible = (step) => ({
    display: currentStep === step ? 'block' : 'none',
  });

  // Steps handler

  const steps = useMemo(() => {
    const pages = Payment.getFormSteps({ process: processType, product: selectedProduct });
    return pages;
  }, [processType, selectedProduct]);

  useEffect(() => {
    setStep(steps[0]); // Set first step by default
  }, [steps, setStep]);

  const onNextStep = () => {
    const index = steps.indexOf(currentStep);
    if (index + 1 > steps?.length) return;
    setStep(steps[index + 1]);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className={fullPage ? styles.paymentContainer : ''}>
        <Card className={styles.paymentForm}>
          <PaymentHeader />

          <div className={styles.paymentBody}>
            <div style={isVisible(Payment.STEPS.INTRODUCTION)}>
              <Introduction processType={processType} onNextPage={onNextStep} />
            </div>
            <div style={isVisible(Payment.STEPS.BILLING)}>
              <BillingDetails onNextPage={onNextStep} />
            </div>
            <div style={isVisible(Payment.STEPS.ADDRESS)}>
              <BillingAddress onNextPage={onNextStep} />
            </div>
            <div style={isVisible(Payment.STEPS.CARD)}>
              <CardInfo onNextPage={onNextStep} />
            </div>
            <div style={isVisible(Payment.STEPS.SUMMARY)}>
              <Summary onSuccess={onSuccess} processType={processType} />
            </div>
          </div>

          <PaymentPagination steps={steps} />
        </Card>
      </div>
    </Elements>
  );
};

export default PaymentForm;

import React from 'react';
import { REP_PATH_STATUS } from '../../LearningPaths.utils';
import { imageBasePath } from 'constants';
import styles from './AssignedLearningPathDetails.module.scss';

const RepStatusLabel = ({ status }) => {
  if (status === REP_PATH_STATUS.IN_PROGRESS)
    return (
      <div className={`${styles.repStatusLabel} ${styles.inProgress}`}>
        <img src={`${imageBasePath}bookOpenPage.svg`} alt="" />
        <span>In progress</span>
      </div>
    );

  if (status === REP_PATH_STATUS.COMPLETED)
    return (
      <div className={`${styles.repStatusLabel} ${styles.completed}`}>
        <img src={`${imageBasePath}greenCheck.svg`} alt="" />
        <span>Completed</span>
      </div>
    );

  return null;
};

export default RepStatusLabel;

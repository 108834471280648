import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';
import { useGetStagesQuery, useUpdateStagesMutation } from './Funnel.queries';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';
import { useTrackingStore } from 'features/tracking/tracking.store';

export const FunnelContext = createContext();

const STATUS = {
  ACTIVE: 1,
  DISABLED: 0,
};

const initialNewStage = {
  name: '',
  all_goals: [],
  error_name: false,
  error_goals: false,
};

const initialState = {
  funnel_stages: [],
  removed_funnel_stages: [],
  new_stage: initialNewStage,
  stage_to_update: { uuid: '', ...initialNewStage },
};

const FunnelProvider = ({ children }) => {
  const enabled = FeatureFlag.enabled('FUNNEL_VIEW');
  const canEdit = !Organization.isRep();
  const [state, setState] = useImmer(initialState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => (canEdit ? setIsModalOpen(true) : null);
  const closeModal = () => {
    setIsModalOpen(false);
    setState(initialState);
  };

  // Load stages data
  const { data: savedStages = [], isFetching: loadingStages } = useGetStagesQuery({
    enabled: enabled && isModalOpen,
  });

  useEffect(() => {
    if (savedStages.length === 0 || !isModalOpen) return;

    const stages = savedStages?.map((stage) => {
      const goals = (stage?.goals || [])?.map((g) => ({
        ...g, // uuid, name, status
        label: g?.name,
        value: g?.uuid,
        isFromHubspot: false,
      }));
      const h_goals = (stage?.goals_hubspot || [])?.map((g) => ({
        ...g,
        label: g?.name,
        value: g?.uuid,
        isFromHubspot: true,
      }));

      return {
        uuid: stage?.uuid,
        name: stage?.name,
        status: stage?.status,
        all_goals: [...goals, ...h_goals],
      };
    });

    setState((draft) => {
      draft.funnel_stages = stages;
    });
  }, [savedStages, isModalOpen, setState]);

  // Values used in forms
  const isEditing = Boolean(state.stage_to_update.uuid);

  const nameValue = useMemo(
    () => (isEditing ? state.stage_to_update?.name : state.new_stage?.name),
    [isEditing, state.new_stage?.name, state.stage_to_update?.name]
  );
  const nameError = useMemo(
    () => (isEditing ? state.stage_to_update?.error_name : state.new_stage?.error_name),
    [isEditing, state.new_stage?.error_name, state.stage_to_update?.error_name]
  );

  const goalsValue = useMemo(
    () => (isEditing ? state.stage_to_update?.all_goals : state.new_stage?.all_goals),
    [isEditing, state.new_stage?.all_goals, state.stage_to_update?.all_goals]
  );
  const goalsError = useMemo(
    () => (isEditing ? state.stage_to_update?.error_goals : state.new_stage?.error_goals),
    [isEditing, state.new_stage?.error_goals, state.stage_to_update?.error_goals]
  );

  const showHint = useMemo(() => {
    return state.funnel_stages?.length > 0 && state.funnel_stages.every((s) => !s?.status);
  }, [state.funnel_stages]);

  const validate = () => {
    const stage = isEditing ? state.stage_to_update : state.new_stage;
    const error_name = !stage.name?.trim();
    const error_goals = stage.all_goals.length < 1;
    const error = error_name || error_goals;

    if (error) {
      setState((draft) => {
        if (isEditing) {
          draft.stage_to_update.error_name = error_name;
          draft.stage_to_update.error_goals = error_goals;
        } else {
          draft.new_stage.error_name = error_name;
          draft.new_stage.error_goals = error_goals;
        }
      });
    }
    return !error;
  };

  // Goals handlers
  const { activityGoals = [], hubSpotGoals = [] } = useTrackingStore();
  const goalsOptions = useMemo(() => {
    const goals = [...activityGoals, ...hubSpotGoals];
    if (goals?.length <= 0) return [];
    const availableGoals = goals
      ?.filter((g) => g?.status === STATUS.ACTIVE)
      ?.map((g) => ({ ...g, label: g?.name, value: g?.uuid }));
    return availableGoals;
  }, [activityGoals, hubSpotGoals]);

  const onGoalsChange = (selectedOptions) => {
    setState((draft) => {
      if (isEditing) draft.stage_to_update.all_goals = selectedOptions;
      else draft.new_stage.all_goals = selectedOptions;

      draft.stage_to_update.error_goals = false;
      draft.new_stage.error_goals = false;
    });
  };

  const resetGoalsError = () => {
    setState((draft) => {
      if (isEditing) draft.stage_to_update.error_goals = false;
      else draft.new_stage.error_goals = false;
    });
  };

  // New stage
  const onNameChange = ({ name }) => {
    setState((draft) => {
      if (isEditing) draft.stage_to_update.name = name;
      else draft.new_stage.name = name;

      draft.stage_to_update.error_name = false;
      draft.new_stage.error_name = false;
    });
  };

  const onAddNewStage = () => {
    if (!validate()) return;
    const stage = state.new_stage;
    const uuid = String(new Date().getTime());

    const new_stage = {
      uuid,
      isNew: true,
      all_goals: stage.all_goals,
      name: stage.name,
      status: STATUS.ACTIVE,
    };

    setState((draft) => {
      draft.funnel_stages.push(new_stage);
      draft.new_stage = initialNewStage;
    });
  };

  // Edit stage
  const setStageToEdit = (uuid) => {
    const stage = state.funnel_stages.find((s) => s?.uuid === uuid);
    if (!stage?.uuid) return;
    setState((draft) => {
      draft.stage_to_update = stage;
      draft.new_stage = initialNewStage;
    });
  };
  const cancelEdit = () => {
    setState((draft) => {
      draft.stage_to_update = { uuid: '', ...initialNewStage };
    });
  };
  const onUpdateStage = () => {
    if (!validate()) return;
    const index = state.funnel_stages.findIndex((s) => s?.uuid === state.stage_to_update?.uuid);
    if (index !== -1)
      setState((draft) => {
        draft.funnel_stages[index] = state.stage_to_update;
      });
    cancelEdit();
  };

  const toggleStatus = (uuid) => {
    setState((draft) => {
      const stage = draft.funnel_stages.find((s) => s?.uuid === uuid);
      if (stage) {
        const newStatus = Boolean(stage?.status) ? STATUS.DISABLED : STATUS.ACTIVE;
        stage.status = newStatus;
      }
    });
  };

  const onDelete = (uuid) => {
    if (!uuid) return;
    setState((draft) => {
      const index = draft.funnel_stages.findIndex((s) => s?.uuid === uuid);
      if (index !== -1) {
        draft.funnel_stages.splice(index, 1);
        draft.removed_funnel_stages.push(uuid);
      }
    });
    if (uuid === state.stage_to_update.uuid) cancelEdit();
  };

  const reorderStages = (source, destination) => {
    setState((draft) => {
      const [moved] = draft.funnel_stages?.splice(source, 1);
      draft.funnel_stages?.splice(destination, 0, moved);
    });
  };

  // Save all changes
  const { mutate, isLoading: loadingUpdate } = useUpdateStagesMutation();
  const onSaveChanges = () => {
    if (loadingUpdate || loadingStages || !canEdit) return;
    const funnel_stages = state.funnel_stages?.map((stage) => {
      const goals = stage.all_goals.filter((s) => !s?.isFromHubspot)?.map((s) => s?.uuid);
      const goals_hubspot = stage.all_goals.filter((s) => s?.isFromHubspot)?.map((s) => s?.uuid);
      const data = { name: stage?.name, status: stage?.status, goals, goals_hubspot };
      if (!stage?.isNew) data.uuid = stage?.uuid;
      return data;
    });
    const body = { funnel_stages, removed_funnel_stages: state.removed_funnel_stages };
    mutate({ body }, { onSuccess: closeModal });
  };

  // Time filters
  const [timeLapse, setTimeLapse] = useState(TIME_OPTIONS[0]);

  return (
    <FunnelContext.Provider
      value={{
        enabled,
        isModalOpen,
        funnel_stages: state.funnel_stages,
        isEditing,
        nameValue,
        nameError,
        goalsValue,
        goalsError,
        goalsOptions,
        setStageToEdit,
        cancelEdit,
        onUpdateStage,
        closeModal,
        openModal,
        onSaveChanges,
        onAddNewStage,
        onNameChange,
        onGoalsChange,
        onDelete,
        toggleStatus,
        reorderStages,
        showHint,
        timeLapse,
        setTimeLapse,
        loadingUpdate,
        loadingStages,
        resetGoalsError,
        canEdit,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
};

const TIME_OPTIONS = [
  { value: 'last-7-days', label: 'Last 7 days' },
  { value: 'this-month', label: 'This month' },
  { value: 'last-3-months', label: 'Last 3 months' },
  { value: 'last-1-year', label: 'Last year' },
];

export const useFunnelProvider = () => {
  const data = useContext(FunnelContext);
  return { ...data, TIME_OPTIONS };
};

export default FunnelProvider;

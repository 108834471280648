import React from 'react';
import { useMemo } from 'react';

const Seconds = ({ seconds }) => {
  const secondsRemaining = useMemo(() => 300 - seconds, [seconds]);

  const minutesLabel = useMemo(() => {
    return `0${Math.trunc(secondsRemaining / 60)}`;
  }, [secondsRemaining]);

  const secondsLabel = useMemo(() => {
    const sec = secondsRemaining % 60;
    if (sec < 10) return `0${sec}`;
    return sec;
  }, [secondsRemaining]);

  return (
    <span className="timeLabel">
      {minutesLabel}:{secondsLabel}
    </span>
  );
};

export default Seconds;

import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styles from '../../Payment.module.scss';

import { Payment, updateLocalSubscription } from '../../../Payment.utils';
import { Flag } from '../Other/_exports';
import Loading from 'shared_ui_components/Loading';
import { usePaymentStore } from '../../../Payment.store';
import {
  useAddPaymentMethodMutation,
  useCreateNewSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from '../../../Payment.queries';
import { Subscription } from 'controllers/_exports';
import ProductSummary from '../ProductSelector/ProductSummary';

const Summary = ({ processType, onSuccess = () => null }) => {
  const history = useHistory();

  // Data from store
  const paymentMethod = usePaymentStore((state) => state?.paymentMethodData);
  const selectedProduct = usePaymentStore((state) => state?.selectedProduct);
  const isFree = usePaymentStore((state) => state?.selectedProduct?.selectedPrice?.isFree);
  const couponID = usePaymentStore((state) => state?.couponData?.promo_code_id);
  const resetCoupon = usePaymentStore((state) => state?.resetCoupon);

  const set_page = usePaymentStore((state) => state?.setStep);

  // Data to display
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const billingData = useMemo(() => paymentMethod?.billing_details, [paymentMethod]);
  const cardData = useMemo(() => paymentMethod?.card, [paymentMethod]);

  const rows = [
    {
      title: 'Account',
      details: (
        <>
          <p>{userInfo?.selectedOrg?.name}</p>
          <p>{userInfo?.user?.email}</p>
        </>
      ),
    },
    {
      title: 'Billing data',
      pageKey: 'billing',
      details: (
        <>
          <p>{billingData?.email}</p>
          <p>{billingData?.name}</p>
          {billingData?.phone && <p>+{billingData?.phone}</p>}
        </>
      ),
    },
    {
      title: 'Billing address',
      pageKey: 'address',
      details: (
        <>
          <p style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <Flag country={billingData?.address?.country} />
            {billingData?.address?.city}, {billingData?.address?.state},{' '}
            {billingData?.address?.postal_code}.
          </p>
          <p>{billingData?.address?.line1}</p>
          <p>{billingData?.address?.line2}</p>
        </>
      ),
    },
    {
      title: 'Payment data',
      pageKey: 'payment',
      details: (
        <>
          <p>
            {cardData?.brand} ending {cardData?.last4}
          </p>
        </>
      ),
    },
  ];

  // SUBMIT handlers
  const handleSubmit = () => {
    // Submit according to processType
    if (processType === Payment.PROCESS_TYPE.NEW_SUBSCRIPTION) addSubscription();
    else if (processType === Payment.PROCESS_TYPE.ADD_PAYMENT) addPayment();
    else if (processType === Payment.PROCESS_TYPE.UPGRADE) upgradeSubscription();
  };

  // Mutations
  const { mutate: addPaymentMethod, isLoading: addPaymentLoading } = useAddPaymentMethodMutation();
  const { mutate: upgrade, isLoading: upgradeLoading } = useUpgradeSubscriptionMutation();
  const { mutate: createSubscription, isLoading: newSubscriptionLoading } =
    useCreateNewSubscriptionMutation();

  const getBody = () => {
    const paymentID = paymentMethod.id;
    const body = {
      payment_method_id: paymentID || '',
      product_id: selectedProduct?.stripe_id,
      price_id: selectedProduct?.selectedPrice?.stripe_id,
    };
    if (couponID) body.promo_code_id = couponID;
    return body;
  };

  // Creates a subscription for the first time
  const handleSubscriptionSuccess = (response) => {
    Payment.removePriceAndCoupon();
    resetCoupon();
    updateLocalSubscription(response);
    onSuccess();
    history.push('/subscription');
  };

  const addSubscription = async () => {
    createSubscription(getBody(), {
      onSuccess: handleSubscriptionSuccess,
    });
  };

  // Upgrades to a higher subscription
  const upgradeSubscription = () => {
    upgrade(getBody(), {
      onSuccess: handleSubscriptionSuccess,
    });
  };

  // Adds another payment method
  const handleAddPaymentSuccess = () => {
    onSuccess();
  };
  const addPayment = async () => {
    addPaymentMethod({ payment_method: paymentMethod.id }, { onSuccess: handleAddPaymentSuccess });
  };

  const buttonText = useMemo(() => {
    switch (processType) {
      case 'payment':
        return 'Subscribe';
      case 'addNewPayment':
        return 'Add payment method';
      case 'upgrade':
        return 'Upgrade';
      default:
        return 'Finish';
    }
  }, [processType]);

  const [checked, setChecked] = useState(false); // Terms and conditions checkbox

  const loading = newSubscriptionLoading || addPaymentLoading || upgradeLoading;
  const proceedToCheckout = processType === 'upgrade' && (isFree || Subscription.hasPayment());

  const disabled = useMemo(() => {
    if (isFree || proceedToCheckout) return !checked;
    return loading || !checked || !paymentMethod.id;
  }, [loading, checked, isFree, paymentMethod.id, proceedToCheckout]);

  const goToIntroduction = () => set_page('introduction');

  return (
    <div className={styles.summaryBody}>
      <ProductSummary processType={processType} />
      {[Payment.PROCESS_TYPE.NEW_SUBSCRIPTION, Payment.PROCESS_TYPE.UPGRADE].includes(
        processType
      ) && (
        <p
          className={isFree ? styles.changePlanFree : styles.changePlan}
          onClick={goToIntroduction}
        >
          ← Change plan
        </p>
      )}

      {!isFree &&
        !proceedToCheckout &&
        rows.map(({ title, details, pageKey }, key) => (
          <SummaryRow
            set_page={set_page}
            key={key}
            title={title}
            details={details}
            pageKey={pageKey}
          />
        ))}

      <div
        style={{ fontSize: '0.9rem', margin: '20px 0', textAlign: 'center' }}
        className={styles.termsAndConditions}
      >
        <input
          checked={checked}
          type="checkbox"
          style={{ marginRight: '5px', cursor: 'pointer' }}
          onChange={() => setChecked(!checked)}
        />
        <TermsAndConditions />
      </div>

      <div className={styles.nextButtonContainer}>
        <Button variant="contained" disabled={disabled} onClick={handleSubmit}>
          {buttonText}
        </Button>
      </div>

      <Loading loading={loading} />
    </div>
  );
};

export default Summary;

const termsLink = 'https://www.salestable.ai/terms-and-conditions/';
const privacyLink = 'https://www.salestable.ai/privacy-policy/';

const TermsAndConditions = () => (
  <span>
    I agree to
    <a href={termsLink} target="_blank" rel="noreferrer">
      Terms of Service
    </a>
    and
    <a href={privacyLink} target="_blank" rel="noreferrer">
      Privacy policy
    </a>
  </span>
);

const SummaryRow = ({ title, details, pageKey, set_page }) => {
  return (
    <div className={styles.summaryRow}>
      <div>
        <h4>{title}</h4>
        {details}
      </div>
      {pageKey && (
        <div onClick={() => set_page(pageKey)} className={styles.summaryRowEdit}>
          Edit →
        </div>
      )}
    </div>
  );
};

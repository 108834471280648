import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import styles from '../../Payment.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { usePaymentStore } from '../../../Payment.store';

const BillingDetails = ({ onNextPage = () => null }) => {
  const set_billing_details = usePaymentStore((state) => state?.setBillingDetails);

  const [billingData, setBillingData] = useState({
    name: '',
    phone: '',
  });

  const onInputChange = (e) => setBillingData({ ...billingData, [e.target.name]: e.target.value });

  const disabled = useMemo(() => !billingData.name?.trim(), [billingData]);

  const saveBillingData = (e) => {
    e.preventDefault();
    if (disabled) return;

    const phone = billingData.phone.length < 6 ? '' : billingData.phone;
    set_billing_details({ ...billingData, phone });
    onNextPage();
  };

  return (
    <form className={styles.billingForm} onSubmit={saveBillingData}>
      <PhoneInput
        specialLabel="Phone number (optional)"
        country={'us'}
        value={billingData.phone}
        onChange={(phone) => setBillingData({ ...billingData, phone })}
        inputStyle={{ height: '45px', width: '100%' }}
      />

      <TextField
        size="small"
        label="Full Name"
        name="name"
        value={billingData.name}
        onChange={onInputChange}
        variant="outlined"
        required
      />

      <div className={styles.nextButtonContainer}>
        <Button type="submit" variant="contained">
          Save and proceed to billing address
        </Button>
      </div>
    </form>
  );
};

export default BillingDetails;

import React from 'react';
import styles from './ReviewQualifiedRoleplay.module.css';

const ShowFeedback = ({ comment = '' }) => {
  return (
    <div>
      Feedback
      <div className={styles.feedback}>{comment}</div>
    </div>
  );
};

export default ShowFeedback;

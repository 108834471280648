import React, { useEffect, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { imageBasePath } from 'constants';
import styles from './AssignRoleplay.module.scss';
import AssignRoleplayModalBody from './AssignRoleplayModalBody';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import {
  useCreateAssignRoleplay,
  useCreateCustomRoleplay,
  useCreateSelfAssignRoleplay,
  useGetListRoleplaysQuery,
} from 'features/roleplay/Roleplay.queries';
import Organization from 'infrastructure/Organization';
import DateTime from 'infrastructure/Datetime';
import { CustomButton } from 'shared_ui_components';

const AsignRoleplay = ({ open = false, handleClose = () => null }) => {
  // Get roleplays information
  const { data: roleplaysList = [] } = useGetListRoleplaysQuery();

  const firstRoleplay = useMemo(() => {
    if (!roleplaysList?.length > 0) return {};
    return {
      label: roleplaysList[0]?.name,
      value: roleplaysList[0]?.uuid,
      description: roleplaysList[0]?.description,
      scenarios: roleplaysList[0]?.scenarios,
      rating_criteria: roleplaysList[0]?.rating_criteria,
      isSelected: true,
    };
  }, [roleplaysList]);

  useEffect(() => {
    if (!Object.keys(firstRoleplay).length > 0) return;
    setFormDataAssignRoleplay({
      roleplay: firstRoleplay,
      ...formDataAssignRoleplay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRoleplay]);

  // Handlers

  const isRep = Organization.isRep();
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const setScenariosList = useRoleplayStore((state) => state?.setScenariosList);
  const scenariosList = useRoleplayStore((state) => state?.scenariosList);
  const formDataCreateRoleplay = useRoleplayStore((state) => state?.formDataCreateRoleplay);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);
  const isRoleplayAssignedToMe = useRoleplayStore((state) => state?.isRoleplayAssignedToMe);

  const { mutate: assignRoleplay, isLoading: loadingAdmin } = useCreateAssignRoleplay();
  const { mutate: selfAssignRoleplay, isLoading: loadingRep } = useCreateSelfAssignRoleplay();
  const { mutate: createCustomRoleplay, isLoading: loadingCustom } = useCreateCustomRoleplay();

  const enable = useMemo(() => {
    if (isRep) {
      if (
        isRoleplayAssignedToMe ||
        !formDataAssignRoleplay?.users ||
        formDataAssignRoleplay.users?.length < 1
      )
        return false;
      return !loadingRep;
    } else {
      if (!formDataAssignRoleplay?.users || formDataAssignRoleplay?.users?.length < 1) return false;
      return !loadingAdmin || !loadingCustom;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataAssignRoleplay.users, loadingAdmin, loadingRep, loadingCustom]);
  const handleAssign = () => {
    const scenarios = scenariosList.map((scenario) => {
      if (scenario.isSelected) return scenario.uuid;
      else return '';
    });
    if (isRep) {
      const data = {
        roleplay_uuid: formDataAssignRoleplay?.roleplay.value,
        scenarios_uuid: scenarios.filter((uuid) => uuid !== ''),
        admin_uuid: formDataAssignRoleplay?.users.value,
        deadline: formDataAssignRoleplay?.deadline,
      };
      selfAssignRoleplay(
        { roleplay: data },
        {
          onSuccess: () => {
            setFormDataAssignRoleplay({
              scenarios: [],
              deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
              roleplay: firstRoleplay,
            });
            onClose();
          },
        }
      );
    } else {
      if (formDataAssignRoleplay?.roleplay.value !== -1) {
        const users_uuid = formDataAssignRoleplay?.users.map((user) => {
          return user.value;
        });
        const data = {
          roleplay_uuid: formDataAssignRoleplay?.roleplay.value,
          scenarios_uuid: scenarios.filter((uuid) => uuid !== ''),
          users_uuid: users_uuid,
          deadline: formDataAssignRoleplay?.deadline,
        };
        assignRoleplay(
          { roleplay: data },
          {
            onSuccess: () => {
              setFormDataAssignRoleplay({
                scenarios: [],
                deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
                roleplay: firstRoleplay,
              });
              onClose();
            },
          }
        );
      } else {
        const sales_rep_uuid_ = formDataAssignRoleplay?.users.map((user) => {
          return user.value;
        });
        const scenarios_ = formDataCreateRoleplay?.scenarios
          .map((scenario) => {
            if (scenario.name.length > 0) return { name: scenario.name };
            return {};
          })
          .filter((scenario) => Object.keys(scenario).length > 0);

        const rating_criterial_ = formDataCreateRoleplay?.rating_criterials
          .map((criteria) => {
            if (criteria.name.length > 0) return { name: criteria.name };
            return {};
          })
          .filter((criteria) => Object.keys(criteria).length > 0);

        let data = {
          roleplay: {
            name: formDataCreateRoleplay.name,
            description: formDataCreateRoleplay.description,
          },
          sales_rep_uuid: sales_rep_uuid_,
          rating_criterials: rating_criterial_,
          deadline: formDataAssignRoleplay?.deadline,
        };

        if (scenarios_.length > 0 && Object.keys(scenarios_[0]).length > 0) {
          data = { ...data, scenarios: scenarios_ };
        }

        createCustomRoleplay(
          { roleplay: data },
          {
            onSuccess: () => {
              setFormDataAssignRoleplay({
                scenarios: [],
                deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
                roleplay: firstRoleplay,
              });
              setFormDataCreateRoleplay({
                scenarios: [{ name: '', id: 1 }],
                rating_criterials: [{ name: '', id: 1 }],
                deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
              });
              onClose();
            },
          }
        );
      }
    }
  };

  const onClose = () => {
    setScenariosList([]);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll={'paper'}
        aria-labelledby="add-video-title"
        aria-describedby="add-video-description"
        className={`dialog addVideoDialog ${styles.dialog}`}
      >
        <DialogTitle id="add-video-title">
          <div>Assign Roleplay</div>
          <Typography variant="body2">
            Involve participants being simulated scenarios to practice effective delegation and
            communication skills.
          </Typography>

          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <AssignRoleplayModalBody />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={onClose} className="tertiaryBtn">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleAssign} disabled={!enable}>
            Assign
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AsignRoleplay;

import { API } from '../../utils/globalApiServices';

export async function getPaymentPlansService({ coupon }) {
  let url = '/subscription/products/';

  const params = {};
  if (coupon) params.promo_code = coupon;

  const { data } = await API.get(url, { params });
  return data;
}

export async function createNewSubscriptionService(body) {
  const url = '/subscription/create-new/';
  const { data } = await API.post(url, body);
  return data;
}

export async function getSubscriptionDetailsService() {
  const url = '/subscription/details/';
  const { data } = await API.get(url);
  return data;
}

export async function createOldSubscriptionService({ payment_method, trial_days, price }) {
  const url = '/subscription/create/';
  const body = {
    payment_method,
    trial_days,
    price,
  };
  const { data } = await API.post(url, body);
  return data;
}

export async function addPaymentMethodService({ payment_method }) {
  const url = '/subscription/add-payment-method/';
  const response = await API.post(url, { payment_method });
  return response;
}

export async function upgradeSubscriptionService(body) {
  const url = '/subscription/change/';
  const response = await API.post(url, body);
  return response;
}
export async function cancelSubscriptionService(body) {
  const url = '/subscription/cancel-request/';
  const response = await API.post(url, body);
  return response;
}

export async function editPaymentMethodService({ body, payment_method_id }) {
  const url = `/subscription/update/${payment_method_id}/payment-method/`;
  const response = await API.put(url, body);
  return response;
}

export async function changeDefaultPaymentMethodService({ payment_method_id }) {
  const url = `/subscription/update/default-payment-method/`;
  const response = await API.put(url, { payment_method_id: payment_method_id });
  return response;
}

export async function deletePaymentMethodService({ payment_method_id }) {
  const url = `/subscription/delete/${payment_method_id}/payment-method/`;
  const response = await API.del(url);
  return response;
}

export async function getSubscriptionDataService() {
  const url = `/settings/details/`; // TODO change to api exclusive to get sub data
  const { data = {} } = await API.get(url);

  const {
    subscription_status,
    stripe_customer_id,
    stripe_subscription_id,
    active_users,
    billed_users,
    max_file_upload_size,
    total_modules,
    total_assessments,
    product: product_id,
    price,
  } = data?.subscription;

  const sub = {
    product_id,
    interval: price?.billing_cycle,
    subscription_status,
    stripe_customer_id,
    stripe_subscription_id,
    active_users,
    billed_users,
    max_file_upload_size,
    total_modules,
    total_assessments,
  };
  return sub;
}

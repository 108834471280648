import React, { useEffect }from 'react';
import { InputSelect } from 'shared_ui_components';
import { timeOptions } from 'constants';
import { useHomeStore } from 'features/home/Home.stores';
import { useOnboardingStore } from 'features/onboarding/Onboarding.store';
import { useTrainingStore } from 'features/training/Training.store';
import { useStreamStore } from 'features/stream/Stream.store';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
const Filter = ({ page = 'home' }) => {
  const { selectedDateFilter, setSelectedDateFilter } = useHomeStore();
  const { selectedDateFilterOnboarding, setSelectedDateFilterOnboarding } = useOnboardingStore();
  const { selectedDateFilterTraining, setSelectedDateFilterTraining } = useTrainingStore();
  const { selectedDateFilterPulse, setSelectedDateFilterPulse } = useStreamStore();
  const { selectedDateFilterRoleplay, setSelectedDateFilterRoleplay } = useRoleplayStore();
  const handleChange = (e) => {
    if (page === 'onboarding') {
      setSelectedDateFilterOnboarding(timeOptions.filter((option) => option.value === e.value)[0]);
    } else if (page === 'training') {
      setSelectedDateFilterTraining(timeOptions.filter((option) => option.value === e.value)[0]);
    } else if (page === 'pulse') {
      setSelectedDateFilterPulse(timeOptions.filter((option) => option.value === e.value)[0]);
    } else if (page === 'roleplay') {
      setSelectedDateFilterRoleplay(timeOptions.filter((option) => option.value === e.value)[0]);
    }
    setSelectedDateFilter(timeOptions.filter((option) => option.value === e.value)[0]);
  };

  useEffect(() => {
    setSelectedDateFilterOnboarding(timeOptions.find(opt=>opt.value===2))
    setSelectedDateFilterTraining(timeOptions.find(opt=>opt.value===2))
    setSelectedDateFilterPulse(timeOptions.find(opt=>opt.value===2))
    setSelectedDateFilterRoleplay(timeOptions.find(opt=>opt.value===2))
    setSelectedDateFilter(timeOptions.find(opt=>opt.value===2))
  }, [setSelectedDateFilter, setSelectedDateFilterOnboarding, setSelectedDateFilterPulse, setSelectedDateFilterRoleplay, setSelectedDateFilterTraining])
  
  return (
    <div>
      <InputSelect
        className="reactSelect"
        closeMenuOnSelect={true}
        options={timeOptions}
        isSearchable={false}
        placeholder={'Select time'}
        onChange={(e) => handleChange(e)}
        noOptionsMessage={() => 'No matches found'}
        value={
          page === 'onboarding'
            ? selectedDateFilterOnboarding
            : page === 'training'
            ? selectedDateFilterTraining
            : page === 'pulse' 
            ? selectedDateFilterPulse
            : page === 'roleplay' 
            ? selectedDateFilterRoleplay
            : selectedDateFilter
        }
      />
    </div>
  );
};

export default Filter;

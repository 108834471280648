import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const history = useHistory();
  return (
    <Button
      size="small"
      style={{
        cursor: 'pointer',
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '5px 10px',
        fontSize: '0.7rem',
      }}
      onClick={() => {
        localStorage.clear();
        history.push('/signin');
      }}
    >
      Log out
    </Button>
  );
};

export default Logout;

import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import GoalCard from './GoalCard';

export default function TrackingSetup(props) {
  const { loader, featuredImg, category = {}, type = '' } = props;
  return (
    <>
      <section className={styles.trackingSetupSection}>
        <div className={styles.setupCardWrapper}>
          <Grid container direction="row" spacing={2} className={styles.trackingSetupRow}>
            <Grid item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
              <div
                className={`${styles.setupCard} ${type == 'salesGoals' ? styles.salesCard : ''}`}
              >
                <img
                  className={styles.featuredImg}
                  src={`${imageBasePath}${featuredImg}`}
                  alt="Goals"
                />
                <h2 className="heading4">{category?.name}</h2>
                <p className="paragraph">{category.description}</p>
                <img
                  className={styles.patternImg}
                  src={imageBasePath + 'tracking_pattern.svg'}
                  alt="pattern img"
                />
              </div>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <div className={styles.cardsListWrapper}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={3}
                  className={styles.contentRow}
                >
                  {category?.goals &&
                    category?.goals?.map((goal) => (
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <GoalCard key={goal.uuid} type="setup" goal={goal} />
                      </Grid>
                    ))}
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={3}
                  className={styles.btnRow + ' textCenter'}
                >
                  <Grid item xs={12}>
                    <CustomButton
                      className={`primaryBtn`}
                      onClick={() => {
                        props.handleOpenAddGoals(type);
                      }}
                    >{`Setup ${category?.name?.toLowerCase()}`}</CustomButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}

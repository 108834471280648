import React from 'react';
import styles from './FunnelGraphContainer.module.scss';
import { imageBasePath } from 'constants';
import { useFunnelProvider } from 'features/funnel/FunnelProvider';
import ManageStagesModal from '../ManageStagesModal/ManageStagesModal';
import FunnelGraph from '../FunnelGraph/FunnelGraph';
import { InputSelect } from 'shared_ui_components';
import { useGetFunnelGraphQuery } from 'features/funnel/Funnel.queries';
import Loading from 'shared_ui_components/Loading';

const FunnelGraphContainer = ({ isOverview, salesRepId }) => {
  const { enabled, canEdit, openModal, setTimeLapse, timeLapse, TIME_OPTIONS } =
    useFunnelProvider();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetFunnelGraphQuery({
    enabled,
    isOverview,
    salesRepId,
    timeLapse: timeLapse?.value,
  });

  if (!enabled) return null;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          Activity Funnel
          {!isLoading && isFetching && (
            <Loading loading circular size={12} margin={0} width="fit-content" />
          )}
        </h1>
        <div className={styles.rightContainer}>
          <InputSelect value={timeLapse} onChange={setTimeLapse} options={TIME_OPTIONS} />

          {canEdit && (
            <button onClick={openModal} className={styles.button}>
              <img src={imageBasePath + 'settings.svg'} alt="settings" />
            </button>
          )}
        </div>
      </div>
      <FunnelGraph data={data} isLoading={isLoading} isFetching={isFetching} />
      {canEdit && <ManageStagesModal />}
    </div>
  );
};

export default FunnelGraphContainer;

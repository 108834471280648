import { Avatar } from '@material-ui/core';
import User from 'infrastructure/User';
import React from 'react';
import { TooltipCustom } from 'utils';

const UserAvatars = ({ users = [] }) => {
  const usersToShow = users?.slice(0, 3);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        marginRight: 'auto',
        marginLeft: '2rem',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
        {usersToShow.map((user) => {
          return (
            <TooltipCustom key={user?.email} title={User?.getUserName(user)} placement="top">
              <Avatar
                style={{
                  width: '1.75rem',
                  height: '1.75rem',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  border: '0.20rem solid #FFF',
                  marginLeft: '-0.5rem',
                }}
                alt=""
                src={user?.profile_pic}
              >
                {User.getInitial(user)}
              </Avatar>
            </TooltipCustom>
          );
        })}
      </div>
      {users.length > 3 ? `+${users.length - 3}` : ''}
    </div>
  );
};

export default UserAvatars;

import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from './DeleteCard.module.scss';
import DeleteCard from './DeleteCard';
import { useDeletePaymentMethodMutation } from '../../../Payment.queries';

const DeleteCardModal = ({cardInfo = {}, isDefault = false}) => {
  const [showModal, setShowModal] = useState(false);
  const { mutate, isLoading } = useDeletePaymentMethodMutation({});


  const handleDelete = () => {
    mutate({ payment_method_id: cardInfo?.id }, { onSuccess });
  }
  const onSuccess = () => {
    setShowModal(false);
  };

  if(isDefault) return <div></div>;
  return (
    <>
      <button variant="outlined" onClick={() => setShowModal(true)} className={styles.deleteButton}>
        Delete
      </button>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent className={styles.CancelDialog}>
          <DeleteCard setShowModal={setShowModal} handleDelete={handleDelete} disabled={isLoading} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteCardModal;

import { FeatureFlag } from 'controllers/_exports';
import { API } from 'utils/globalApiServices';

export const getHomeOnboardingCompletion = async ({ time = 'all-time' }) => {
  const url = `home/onboarding-completion/?time_lapse=${time}`;
  const { data } = await API.get(url);
  return data;
};

export const getHomeTrainingCompletion = async ({ time = 'all-time' }) => {
  let url = 'home/training-completion/';
  if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
  const { data } = await API.get(url);
  return data;
};

export const getHomeRoleplayCompletion = async ({ time = 'all-time' }) => {
  let url = 'home/roleplays-completion/';
  if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
  const { data } = await API.get(url);
  return data;
};

export const getHomeRampTime = async ({ time = 'all-time' }) => {
  let url = 'home/ramp-time/';
  if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
  const { data } = await API.get(url);
  return data;
};

import React, { useMemo } from 'react';
import { useGetUsersQuery } from 'features/stream/Stream.queries';
import Organization from 'infrastructure/Organization';
import StreamInputSelect from '../StreamInputSelect/StreamInputSelect';
import StreamUtils from 'features/stream/StreamConstants';

const UserFilter = ({ onChange = () => null }) => {
  const user = Organization.getUserInfo();
  const { data = [] } = useGetUsersQuery({});

  const handleChange = (value) => onChange(value);

  const users = useMemo(() => {
    const options = data
      ?.map(({ user }) => {
        const { profile_pic } = user;
        const label = StreamUtils.getUsername(user);

        return {
          value: user?.uuid || '',
          label,
          icon: profile_pic,
        };
      })
      .filter((u) => u?.value !== user?.uuid);

    options?.unshift({ label: 'My posts', value: 'mine', hideIcon: true });
    options?.unshift({ label: 'All users', value: 'all', hideIcon: true });

    return options;
  }, [data, user?.uuid]);

  if (!users.length > 0) return null;
  return (
    <StreamInputSelect
      options={users}
      size="small"
      onChange={({ value }) => handleChange(value)}
      defaultValue={users[0]}
      containerClassName="stream-user-filter"
    />
  );
};

export default UserFilter;

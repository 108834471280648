import React from 'react';
import RecorderButton from './RecorderButton';

import reset from '../../../assets/recorder/reset.svg';

const RestartButton = ({ onClick = () => null }) => {
  return (
    <RecorderButton
      disabled
      onClick={onClick}
      src={reset}
      alt="restart"
      tooltip="Restart Recording"
    />
  );
};

export default RestartButton;

import React, { useState } from 'react';
import styles from './FunnelStagesList.module.scss';
import { useFunnelProvider } from 'features/funnel/FunnelProvider';
import { CustomSwitch } from 'shared_ui_components';
import { imageBasePath } from 'constants';
import { TooltipCustom } from 'utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, MenuItem } from '@material-ui/core';
import { ConfirmationDialog } from 'shared_elements';

const FunnelStagesList = () => {
  const { funnel_stages, setStageToEdit, toggleStatus, reorderStages, onDelete } =
    useFunnelProvider();

  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedItem, setClickedItem] = useState('');

  // Delete handler
  const [itemToDelete, setItemToDelete] = useState('');
  const handleDelete = () => {
    setItemToDelete('');
    onDelete(clickedItem);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index && source.droppableId === destination.droppableId)
      return;
    reorderStages(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.header}>
        <span>Order of the stages </span>
        <TooltipCustom
          arrow
          placement="top"
          title="The order of the stage will impact the way the funnel chart will be represented and how its values will be calculated."
        >
          <img src={imageBasePath + 'question_mark.svg'} alt="?" />
        </TooltipCustom>
      </div>
      <Droppable droppableId="stagesList">
        {(droppableProvider) => (
          <div
            {...droppableProvider.droppableProps}
            ref={droppableProvider.innerRef}
            className={styles.listContainer}
          >
            {funnel_stages?.map((s, index) => (
              <Draggable key={s?.uuid} draggableId={s?.uuid} index={index}>
                {(draggableProvider) => (
                  <div
                    {...draggableProvider.draggableProps}
                    ref={draggableProvider.innerRef}
                    {...draggableProvider.dragHandleProps}
                    className={styles.itemContainer}
                  >
                    <div className={styles.contentContainer}>
                      <div className={styles.title}>
                        <CustomSwitch
                          label=""
                          checked={Boolean(s?.status)}
                          onChange={() => toggleStatus(s.uuid)}
                          onClick={(e) => e?.stopPropagation()}
                        />
                        <span className={styles.name}>{s?.name}</span>
                      </div>
                      {[...s?.all_goals].length === 0 && (
                        <span>
                          <span style={{ color: '#000000' }}>No goals left</span>
                          <TooltipCustom
                            arrow
                            placement="top"
                            title="All goals of this stage have been deleted"
                          >
                            <img
                              className={styles.info}
                              src={imageBasePath + 'info_icon.svg'}
                              alt="?"
                            />
                          </TooltipCustom>
                        </span>
                      )}
                      <ul className={styles.goalsContainer}>
                        {[...s?.all_goals].map((g) => (
                          <li>
                            {g?.name}
                            {g?.isFromHubspot && (
                              <img
                                alt=""
                                className={styles.hubspot}
                                src={imageBasePath + 'hubspot_logo.svg'}
                              />
                            )}

                            {g?.status === 0 && (
                              <TooltipCustom
                                arrow
                                placement="top"
                                title="This goal is currently disabled"
                              >
                                <img
                                  className={styles.info}
                                  src={imageBasePath + 'info_icon.svg'}
                                  alt="?"
                                />
                              </TooltipCustom>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.grabContainer}>
                      <img
                        alt="+"
                        className={styles.moreIcon}
                        src={imageBasePath + 'more_vertical.svg	'}
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setClickedItem(s?.uuid);
                        }}
                      />
                      <img
                        className={styles.dragIcon}
                        src={imageBasePath + 'drag_to_do.svg'}
                        alt="..."
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
      <ConfirmationDialog
        isDelete
        open={Boolean(itemToDelete)}
        handleClose={() => setItemToDelete('')}
        rejectText="No, cancel"
        acceptText="Yes, delete stage"
        handleSubmit={handleDelete}
        title="Are you sure you want to delete this stage?"
        description="This action cannot be undone."
        withCloseIcon={false}
      />
      <Menu
        id={`stage-menu`}
        className="moreMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setClickedItem('');
        }}
      >
        <MenuItem
          onClick={() => {
            setStageToEdit(clickedItem);
            setAnchorEl(null);
          }}
        >
          <img src={imageBasePath + 'edit.svg'} alt="" />
          <span className="supportText">Edit stage</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setItemToDelete(clickedItem);
            setAnchorEl(null);
          }}
        >
          <img src={imageBasePath + 'delete_red.svg'} alt="" />
          <span className="supportText">Remove stage</span>
        </MenuItem>
      </Menu>
    </DragDropContext>
  );
};

export default FunnelStagesList;

import React from 'react';
import ContentCard from './ContentCard';
import { useLearningPathsStore } from '../../LearningPaths.store';
import styles from './LearningPathDetails.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { usePathValidations } from '../../LearningPaths.utils';
import { useUpdateLearningPathContents } from '../../useUpdateLearningContents';

const ListOfContents = () => {
  const { contents = [], learningPath } = useLearningPathsStore();
  const { removeContents, reorderContents } = useUpdateLearningPathContents();

  const handleRemove = (uuid) => removeContents(uuid);

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const { isPathOwner } = usePathValidations({ learningPath });
  if (!isPathOwner)
    return (
      <div className={styles.listContainer}>
        {contents?.map((content) => (
          <div key={content?.uuid} className={styles.listElement}>
            <ContentCard handleRemove={handleRemove} contentData={content} />
          </div>
        ))}
      </div>
    );
  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
          return;
        }
        if (!isPathOwner) return;
        reorderContents(reorder(contents, source.index, destination.index));
      }}
    >
      <Droppable droppableId="modules">
        {(droppableProvided) => (
          <ul
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            className={styles.listContainer}
          >
            {contents?.map((content, index) => (
              <Draggable
                key={content?.content.uuid}
                draggableId={content?.content.uuid}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.dragHandleProps}
                    className={styles.listElement}
                  >
                    <ContentCard
                      handleRemove={handleRemove}
                      contentData={content}
                      isPathOwner={isPathOwner}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListOfContents;

import React from 'react';
import styles from '../../../assets/styles/onboarding.module.scss';
import { imageBasePath } from '../../../constants';
import { TextArea } from '../../../shared_ui_components';
import { getContentTypeImg, fetchContentType, isValidVideoLink } from '../../../utils';

export default function InsertLink(props) {
  const { formData, page = 'onboarding', error = {} } = props;

  return (
    <div className={styles.embedVideoBlock}>
      <div className={styles.noteBlock}>
        <img src={imageBasePath + 'info_icon.png'} alt="info icon" />
        <p className="supportText">
          The Inserted URL redirects you to that platform on a different tab
        </p>
      </div>
      <TextArea
        label={`Insert URL`}
        placeholder="Enter the URL here"
        rows={3}
        onChange={(e) => {
          props.handleChange('content', e.target.value);
          if ((props.isCustom || page == 'training') && e.target.value && e.target.value.trim()) {
            fetchContentType(e.target.value, props.handleChange, 'insertLink');
          }
        }}
        onBlur={(e) => {
          if (e.target.value != e.target.value.trim()) {
            props.handleChange('content', e.target.value.trim());
          }
        }}
        value={formData.content}
        className={error.content ? styles.errorGroup : ' '}
      />
      {error.content ? <h6 className={`errorMsg ${styles.errorMsg}`}>{error.content}</h6> : null}
      <div className={styles.previewBlock}>
        {formData.content && formData.content.trim() ? (
          <div className={styles.linkPreviewBlk}>
            {formData.content_type == 1 &&
            (isValidVideoLink(formData.content) || props.isCustom || page == 'training') ? (
              <video controls key={formData.content}>
                <source src={formData.content} type="video/mp4" />
                <source src={formData.content} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : (
              getContentTypeImg(formData.content_type)
            )}
            <p className="textCenter linkBtn">
              <a target="_blank" href={formData.content}>
                {formData.content}
              </a>
            </p>
          </div>
        ) : (
          <>
            <img src={imageBasePath + 'dragdrop.png'} alt="drag and drop" />
            <p className="textCenter supportText">Video preview will come here</p>
          </>
        )}
      </div>
    </div>
  );
}

import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { imageBasePath } from 'constants';
import styles from './CreateLearningPath.module.scss';
import { CustomButton } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import CreateLearningPathContent from './CreateLearningPathContent';
import { useCreateLearningPath } from '../../LearningPaths.queries';
import DateTime from 'infrastructure/Datetime';

const CreateLearningPath = () => {
  const history = useHistory();
  const createModal = useLearningPathsStore((state) => state?.createModalOpen);
  const closeModal = useLearningPathsStore((state) => state?.closeCreateModal);
  const formDataLearningPath = useLearningPathsStore((state) => state?.formDataLearningPath);
  const setFormDataLearningPath = useLearningPathsStore((state) => state?.setFormDataLearningPath);
  const { mutate: createLearningPath, isLoading } = useCreateLearningPath();

  const enable = useMemo(() => {
    if (formDataLearningPath?.title?.trim().length === 0) return false;
    return !isLoading;
  }, [formDataLearningPath, isLoading]);

  const onClose = () => {
    closeModal();
  };
  const handleCreate = () => {
    let users_uuid;
    if (formDataLearningPath?.users.length > 0) {
      users_uuid = formDataLearningPath?.users.map((user) => {
        return user.value;
      });
    }
    const data = {
      title: formDataLearningPath.title,
      description: formDataLearningPath.description || '',
      deadline: formDataLearningPath.deadline || '',
      users_uuid: users_uuid || [],
    };
    createLearningPath(
      { learningPath: data },
      {
        onSuccess: (response) => {
          setFormDataLearningPath({
            deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
            users: [],
            title: '',
            description: '',
          });
          onClose();
          history.push(`/learning-path/${response?.data?.learning_path_uuid}`);
        },
      }
    );
  };
  return (
    <>
      <Dialog
        open={createModal}
        onClose={onClose}
        scroll={'paper'}
        aria-labelledby="add-video-title"
        aria-describedby="add-video-description"
        className={`dialog addVideoDialog ${styles.dialog}`}
      >
        <DialogTitle>
          <div>Learning path: Add new Learning path</div>
          <Typography variant="body2">
            Provide a personalized learning path tailored to each sales rep needs.
          </Typography>

          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <CreateLearningPathContent />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={onClose} className="tertiaryBtn">
            Cancel
          </CustomButton>
          <CustomButton disabled={!enable} onClick={handleCreate}>
            Create
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateLearningPath;

import React from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

export default function EmailVerifiedCard(props) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <div className={styles.cardWrapper + ' ' + styles.verifiedEmailWrapper}>
      <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
      <img
        className={styles.emailVerified}
        src={imageBasePath + 'email_verified.svg'}
        alt="salestable"
      />
      <h1 className="heading3">Your email has been verified!</h1>
      <p className="paragraph">
        Welcome to Salestable. Let’s start by setting up the
        <br /> onboarding of your sales team.
      </p>
      {userInfo && userInfo.available_organisation ? (
        <CustomLink linktext="Continue" className={`primaryBtn submitBtn`} to="/requestaccess" />
      ) : (
        <CustomLink
          linktext="Continue"
          className={`primaryBtn submitBtn`}
          to="/createorganization"
        />
      )}
    </div>
  );
}

import { useEffect, useMemo, useState } from 'react';

const FileViewerLogic = ({ activeStep, handleMarkAsRead }) => {
  // File properties
  const { content: link, content_size, name: title, uuid } = activeStep;

  // Preview only works for file sizes < 25mb
  const isDisabled = useMemo(() => Math.trunc(content_size / (1024 * 1024)) >= 25, [content_size]);

  const isFile = useMemo(() => {
    if (!link) return false;
    return ['PDF', 'Power_point', 'Excel', 'Word_document','CSV', '.xlsx', '.docx', '.pptx', '.pdf', '.csv'].some(
      (extension) => link.includes(extension)
    );
  }, [link]);

  // Loading handlers
  const [loading, setLoading] = useState(true);
  const [documentLoaded, setDocumentLoaded] = useState(false);

  useEffect(() => {
    return function resetLoaded() {
      setDocumentLoaded(false);
      setLoading(true);
    };
  }, [uuid]);

  //File preview has loaded successfully
  const onFilePreviewLoad = (e) => {
    setLoading(false);
    setDocumentLoaded(true);
    handleMarkAsReadTrigger();
  };

  const handleMarkAsReadTrigger = (e) => {
    !activeStep.content_read ? handleMarkAsRead(activeStep) : e.preventDefault();
  };

  return {
    isFile,
    isDisabled,
    loading,
    title,
    link,
    documentLoaded,
    onFilePreviewLoad,
  };
};

export default FileViewerLogic;

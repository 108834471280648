import React from 'react';
import ReactWebcam from 'react-webcam';

import WebcamLogic from './Webcam.logic';
import './Webcam.scss';

const webcamSize = 120;

const Webcam = ({ show, isFullWebcam }) => {
  WebcamLogic({ size: webcamSize });
  return (
    <ReactWebcam
      id="webcam"
      audio={false}
      hidden={!show}
      allowFullScreen
      className={isFullWebcam ? 'fullWebcam' : 'smallWebcam'}
      // onUserMediaError={(e) => console.log('WEBCAM MEDIA ERROR', e)}
    />
  );
};

export default Webcam;

import React, { useState, useEffect } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { lastActivity } from '../../../utils';
import styles from '../../../assets/styles/recruitment.module.scss';
import { CustomButton, CustomTooltipWhite } from '../../../shared_ui_components';
import OverviewTable from './OverviewTable';
import { Integration } from './index';
import { downloadFileType } from '../../../utils';
import { downloadReport } from '../apiServices';
import Shimmer from './Shimmer';

export default function Overview(props) {
  const {
    handleConfigure,
    getProviders,
    jobProviders,
    allJobs,
    syncLoader,
    getJobs,
    handleIntegrateProviders,
    currentIntegrationSet,
    addJobManuallySet,
    handleChangeToken,
    enableDisable,
  } = props;
  const [tab, tabSet] = useState(1);
  const [downloadReportLoader, downloadReportLoaderSet] = useState(false);

  const downloadSample = (content) => {
    const win = window.open(content, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (props.tabReset) {
      tabSet(1);
    }
  }, [props.tabReset]);

  const downloadRecruitmemtReport = () => {
    downloadReportLoaderSet(true);
    downloadReport()
      .then((response) => {
        downloadReportLoaderSet(false);
        if (response) {
          downloadFileType(response, 'Recruitment Dashboard', '.pdf');
        } else {
          console.log(response, 'err');
        }
      })
      .catch((err) => {
        // console.error(err,"err");
      });
  };

  return (
    <section className={styles.overviewSection}>
      <div className={styles.titleRowWrapper}>
        <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <List className="listInline">
              <ListItem className={styles.title + ' listInlineItem'}>
                <h1 className="heading4">
                  <img src={imageBasePath + 'recruitment_icon.svg'} alt="Image" />
                  <span>Recruitment</span>
                </h1>
              </ListItem>
              <ListItem className="listInlineItem">
                <a
                  onClick={() => tabSet(1)}
                  className={`heading6 ${tab === 1 ? styles.active : null} `}
                >
                  <span>Overview</span>
                </a>
              </ListItem>
              <ListItem className="listInlineItem">
                <a
                  onClick={() => tabSet(2)}
                  className={`heading6 ${tab === 2 ? styles.active : null} `}
                >
                  <span>Integration</span>
                </a>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
      {tab == 1 ? (
        <Grid container direction="row" spacing={3} className={styles.downloadRow}>
          <Grid item xs={12} className="textRight">
            <button
              className={`paragraph downloadBtn ${downloadReportLoader ? 'disabledBtn' : ''}`}
              onClick={() => downloadRecruitmemtReport()}
            >
              <img src={imageBasePath + 'download.svg'} alt="download icon" />
              {downloadReportLoader ? 'Downloading...' : 'Download Report'}
            </button>
          </Grid>
        </Grid>
      ) : null}

      <div className={styles.recruitmentContentWrapper}>
        {tab == 1 ? (
          <>
            {allJobs && allJobs?.providers?.length ? (
              <>
                {allJobs.providers.map((job) => (
                  <>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      className={styles.headingRow}
                    >
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className={styles.titleBlk}>
                          <img src={imageBasePath + 'greenhouse.svg'} alt="GreenHouse" />
                          <div className={styles.info}>
                            <h3 className="heading5">
                              {job.name} ({job.recruitments.length})
                            </h3>
                            <p className="supportText">Integrate with {job.name}</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <List className={styles.syncBlk + ' listInline textRight'}>
                          <ListItem className="listInlineItem">
                            <span className="supportText">
                              <i>Last updated {lastActivity(job.updated_at)}</i>
                            </span>
                          </ListItem>
                          {job.status === 2 ? (
                            <ListItem className={styles.invalidAuth + ' listInlineItem'}>
                              <span className="supportTextSemibold">
                                Authorization token is invalid
                              </span>
                            </ListItem>
                          ) : null}
                          {job.status === 2 ? (
                            <ListItem className="listInlineItem">
                              <CustomButton
                                className={`${styles.syncJobBtn} secondaryBtn smallBtn ${
                                  syncLoader ? 'disabled' : ''
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleIntegrateProviders(true, job);
                                }}
                              >
                                Enter valid token
                              </CustomButton>
                              {/* <img className={styles.arrowDown} src={imageBasePath+'down_arrow.svg'} alt="image" /> */}
                            </ListItem>
                          ) : (
                            <>
                              <ListItem className="listInlineItem">
                                <CustomButton
                                  className={`${styles.syncJobBtn} primaryBtn smallBtn ${
                                    syncLoader ? 'disabled' : ''
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleConfigure(job);
                                    currentIntegrationSet(job);
                                  }}
                                >
                                  Configure
                                </CustomButton>
                                {/* <img className={styles.arrowDown} src={imageBasePath+'down_arrow.svg'} alt="image" /> */}
                              </ListItem>
                              {job.recruitments.length ? (
                                <ListItem className="listInlineItem">
                                  <CustomButton
                                    className={`${styles.syncJobBtn} secondaryBtn smallBtn ${
                                      syncLoader ? 'disabled' : ''
                                    }`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      props.syncJob(job.id, job.recruitments);
                                    }}
                                  >
                                    {syncLoader ? 'Syncing...' : 'Sync job'}
                                  </CustomButton>
                                  {/* <img className={styles.arrowDown} src={imageBasePath+'down_arrow.svg'} alt="image" /> */}
                                </ListItem>
                              ) : null}
                            </>
                          )}
                        </List>
                      </Grid>
                    </Grid>
                    <OverviewTable
                      isProvider={true}
                      open={props.deleteJob}
                      handleDeleteJob={props.handleDeleteJob}
                      addJobManually={props.addJobManually}
                      handleAddJob={props.handleAddJob}
                      job={job}
                    />
                  </>
                ))}
              </>
            ) : null}
            {allJobs && allJobs?.manual?.length ? (
              <>
                {
                  <>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      className={styles.headingRow}
                    >
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className={styles.titleBlk}>
                          <img
                            src={imageBasePath + 'track_job_manually.svg'}
                            alt="Tracked Manually"
                          />
                          <div className={styles.info}>
                            <h3 className="heading5">Tracked Manually ({allJobs.manual.length})</h3>
                            <p className="supportText">Manual track by check-in status regularly</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <List className={styles.syncBlk + ' listInline textRight'}>
                          <ListItem className="listInlineItem">
                            <CustomButton
                              className={`${styles.syncJobBtn} secondaryBtn smallBtn `}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                addJobManuallySet(true);
                              }}
                            >
                              Add job
                            </CustomButton>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    {/* <Shimmer type="RecruitmentOverview" /> */}
                    {
                      <OverviewTable
                        isProvider={false}
                        open={props.deleteJob}
                        handleDeleteJob={props.handleDeleteJob}
                        addJobManually={props.addJobManually}
                        handleAddJob={props.handleAddJob}
                        job={allJobs.manual}
                        updateManualJob={props.updateManualJob}
                      />
                    }
                  </>
                }
              </>
            ) : null}
          </>
        ) : (
          <>
            <Integration
              isPublished={true}
              getProviders={getProviders}
              jobProviders={jobProviders}
              jobs={allJobs.providers}
              getJobs={getJobs}
              handleIntegrateProviders={handleIntegrateProviders}
              currentIntegrationSet={currentIntegrationSet}
              addJobManuallySet={addJobManuallySet}
              handleConfigure={handleConfigure}
              handleChangeToken={handleChangeToken}
              enableDisable={enableDisable}
            />
          </>
        )}
      </div>
    </section>
  );
}

import { useLocation } from 'react-router-dom';
import { useRecorderStore } from '../VideoRecorder.store';
import { toastFlashMessage } from './../../../utils/index';
import { Subscription } from 'controllers/_exports';
import VideoRecorderModule from '../VideoRecorder.module';

const StartRecordingLogic = ({
  onRecordingStart = () => null,
  onRecordingDiscard = () => null,
  onRecordingEnd = () => null,
  finalRecordingID = '',
  bypassSubscription,
}) => {
  const location = useLocation();

  // If a video is being recorded don't show record button
  const showButton = !useRecorderStore((s) => s.isRecording);

  //Store handlers
  // const store_start = useRecorderStore((s) => s.startRecording);
  const store_isEnableRecording = useRecorderStore((s) => s.startEnableRecording);
  const store_setURL = useRecorderStore((s) => s.setResourceUrl);
  const setVideoID = useRecorderStore((s) => s.setVideoID);
  const setOnDiscard = useRecorderStore((s) => s.setOnDiscard);
  const setOnEnd = useRecorderStore((s) => s.setOnEnd);

  const handleStartRecording = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!bypassSubscription) {
      const allowFeature =
        VideoRecorderModule.show() || Subscription.allow({ key: Subscription.keys.video });
      if (!allowFeature) return;
    }

    if (isMobile())
      return toastFlashMessage('Sorry, your device does nos support this feature', 'warning');

    // Save the current location info to redirect later
    const url = `${location.pathname}${location.search}`;
    store_setURL(url);

    onRecordingStart();
    setOnDiscard(onRecordingDiscard);
    setOnEnd(onRecordingEnd);
    setVideoID(finalRecordingID);
    // store_start();
    store_isEnableRecording();
  };

  return { showButton, handleStartRecording };
};

export default StartRecordingLogic;

const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

import React from 'react';
import { withRouter } from 'react-router-dom';
export default function (ComposedComponent) {
  class RedirectAuth extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loggedIn: false,
      };
    }

    componentWillReceiveProps(nextProps) {
      if (
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo')).user
      ) {
        this.setState({ loggedIn: true });
        this.props.history.push('/');
      }
    }

    componentWillMount() {
      if (
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo')).user
      ) {
        this.setState({ loggedIn: true });
        this.props.history.push('/');
      }
    }

    render() {
      if (!this.state.loggedIn) {
        return <ComposedComponent {...this.props} />;
      }
      return null;
    }
  }
  return withRouter(RedirectAuth);
}

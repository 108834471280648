import { imageBasePath } from '../../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: '#F3F5F8',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#415766',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontFamily: 'Open Sans',
    fontWeight: '400',
  },
}));

export default function OverallInfoTooltip({ description }) {
  const classes = useStyles();

  return (
    <Tooltip arrow classes={classes} title={description}>
      <img
        width={24}
        style={{
          cursor: 'help',
          marginLeft: 'auto',
          marginBottom: 'auto',
          marginTop: 'auto',
          paddingLeft: '1rem',
        }}
        src={imageBasePath + 'feedback_info.svg'}
        alt=""
      />
    </Tooltip>
  );
}

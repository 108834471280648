import React from 'react';
import RecorderButton from './RecorderButton';

import mic_on from '../../../assets/recorder/mic_on.svg';
import mic_off from '../../../assets/recorder/mic_off.svg';

const MicButton = ({ isMuted, onClick = () => null }) => {
  return (
    <RecorderButton
      onClick={onClick}
      src={isMuted ? mic_off : mic_on}
      alt={isMuted ? 'unmute' : 'mute'}
      tooltip={isMuted ? 'Enable Mic' : 'Disable Mic'}
    />
  );
};

export default MicButton;

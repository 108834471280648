import React from 'react';
import styles from './Post.module.scss';

import PostContent from '../PostContent/PostContent';
import PostReactions from '../PostReactions/PostReactions';
import PostCommentsSection from '../PostComments/PostComments';
import PostNewComment from '../PostNewComment/PostNewComment';
import { useState } from 'react';

const Post = ({ postData }) => {
  const [showComments, setShowComments] = useState(false);
  const handleComments = () => setShowComments((s) => !s);
  const closeComments = () => setShowComments(false);

  return (
    <div className={styles.post}>
      <PostContent data={postData} />
      <PostReactions handleComments={handleComments} data={postData} />
      <PostCommentsSection
        showComments={showComments}
        post_id={postData?.id}
        total_comments={postData?.total_comments}
        closeComments={closeComments}
      />
      <PostNewComment data={postData} />
    </div>
  );
};

export default Post;

import React from 'react';

import styles from '../../Payment.module.scss';

const Flag = ({ country, big = false }) => {
  return !country ? null : (
    <img
      className={big ? styles.bigFlag : styles.flag}
      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
      alt={country}
      title={country}
    />
  );
};

export default Flag;

import React from 'react';
import styles from '../../Payment.module.scss';
import { imageBasePath } from 'constants';
import { useCancelSubscriptionMutation } from '../../../Payment.queries';
import { CustomButton } from 'shared_ui_components';

const CancelForm = ({ setShowModal = () => null, onCanceled = () => null }) => {
  const { mutate } = useCancelSubscriptionMutation({});

  const handleCancel = () => {
    mutate({}, { onSuccess });
  };
  const onSuccess = () => {
    setShowModal(false);
    onCanceled();
  };
  return (
    <div style={{ minHeight: '15rem' }}>
      <div className={styles.cancelTitle}>
        <img
          src={imageBasePath + 'close_gray.svg'}
          alt="close icon"
          className={styles.cancelCloseIcon}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className={styles.cancelBody}>
        <div className={styles.bodyText}>
          <strong>Are you sure you want to cancel your subscription to Salestable?</strong> <br />
          <br />
          By cancelling, you'll lose access to all the fantastic features that have been helping you
          scale your team.
        </div>
        <div className={styles.bodyButtons}>
          <button onClick={handleCancel} className={styles.cancel}>
            Yes, Cancel my Subscription
          </button>
          <CustomButton onClick={() => setShowModal(false)} textTransform="none">
            No, Keep my Subscription
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default CancelForm;

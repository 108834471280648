import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function CustomSwitch(props) {
  return (
    <div className="switchWrapper">
      <FormControlLabel control={<Switch {...props} />} label={props.label ? props.label : ''} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Grid, DialogActions } from '@material-ui/core';
import {
  InputText,
  CustomButton,
  TextArea,
  ProgressBar,
  FlexiblePeriod,
  CustomTooltipWhite,
} from '../../../shared_ui_components';
import { imageBasePath, goalFrequencies } from '../../../constants';
import { changeTimezone, getStartEndDateOfCheckin, NumberFormatCustom } from '../../../utils';
import styles from '../../../assets/styles/tracking.module.scss';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { Shimmer } from './';
import { CustomNumberFormatter } from '../../../utils';
import { getCurrentCheckinApi } from '../apiServices';
import FileUploader from 'shared_ui_components/FileUploader/FileUploader';
import { FeatureFlag } from 'controllers/_exports';
import HubspotTooltip from 'features/hubspot/components/HubspotToltip';

export default function CheckinDrawer(props) {
  const {
    checkinLoader,
    open,
    updateCheckin,
    isEditLoader,
    latestEditCheckin,
    latestCheckin,
    isEdit,
  } = props;
  const [state, setState] = useState({
    right: false,
  });
  const [currentCheckin, setCurrentCheckin] = useState([]);
  const [salesGoals, salesGoalsSet] = useState({});
  const [otherInput, otherInputSet] = useState({
    need_customer_attention: 0,
    lessons_learned: '',
    best_practices: '',
    remarks: '',
  });
  const [error, errorSet] = useState({});
  const [date, setDate] = useState({ start_date: '', end_date: '' });
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [replaceFile, setReplaceFile] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const filterCheckin = (checkIn = []) => {
    try {
      return FeatureFlag.enabled('REMOVE_SALES_GOALS')
        ? checkIn?.filter((c) => !c?.is_organisation)
        : checkIn;
    } catch (error) {
      return checkIn;
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    if (!open) {
      props.handleClose();
    }
  };

  const [gracePeriodDate, setGracePeriodDate] = useState(null);
  const [isCustomEdit, setIsCustomEdit] = useState(isEdit);
  const handleSelectNewDate = ({ end_date }) => {
    getCurrentCheckinApi({ date: end_date }).then((response) => {
      if (response.statusCode === 200) {
        const { checkin_exists, categories, additional_file } = response?.data;
        initialize({ checkin: categories, isEdit: checkin_exists, additional_file });
        const lateCheckinDate = checkin_exists ? null : end_date;
        setGracePeriodDate(lateCheckinDate);
      }
    });
  };

  useEffect(() => {
    setIsCustomEdit(isEdit);
  }, [isEdit]);

  const initialize = ({ checkin, isEdit, additional_file }) => {
    setIsCustomEdit(isEdit);
    setState({
      right: open,
    });
    setCurrentCheckin(filterCheckin(checkin));
    if (checkin && checkin.length) {
      salesGoalsSet(checkin.find((cat) => !cat.is_organisation));
      const newDate = getStartEndDateOfCheckin(checkin);
      setDate(newDate);
    }
    if (open) errorSet({});
    if (isEdit) {
      otherInputSet({
        need_customer_attention: latestEditCheckin?.need_customer_attention ? 1 : 0,
        lessons_learned: latestEditCheckin?.lessons_learned,
        best_practices: latestEditCheckin?.best_practices,
        remarks: latestEditCheckin?.remarks,
        additional_file:
          additional_file === null
            ? null
            : additional_file
            ? additional_file
            : latestEditCheckin?.additional_file,
      });
    } else {
      otherInputSet({
        need_customer_attention: latestCheckin?.need_customer_attention ? 1 : 0,
        lessons_learned: latestCheckin?.lessons_learned,
        best_practices: latestCheckin?.best_practices,
        remarks: latestCheckin?.remarks,
        additional_file: latestCheckin?.additional_file,
      });
    }
  };

  useEffect(() => {
    initialize({
      checkin: props.currentCheckin,
      isEdit: props.isEdit,
      additional_file: props.additional_file,
    });
    setGracePeriodDate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, props.currentCheckin, props.isEdit, props.latestEditCheckin, props.latestCheckin]);

  const handleChange = (uuid, value) => {
    let newValue = value + '';
    if (newValue.length > 9) {
      newValue = newValue.slice(0, 9);
    }
    const currentCheckinUpdated = currentCheckin.map((org) => ({
      ...org,
      goals: org.goals.map((goal) =>
        goal.uuid === uuid ? { ...goal, target: parseInt(newValue) } : goal
      ),
    }));
    setCurrentCheckin(filterCheckin(currentCheckinUpdated));
    errorSet({
      ...error,
      [uuid]: '',
    });
  };

  const handleSubmit = () => {
    let validate = currentCheckin;
    if (validate && validate.length) {
      validate.forEach((category) => {
        category.goals.forEach((goal) => {
          if (!goal.target || goal.target === '') {
            goal.target = 0;
          }
        });
      });
    }
    const formattedDate = gracePeriodDate ? `${gracePeriodDate} 00:00:00` : null;
    props.checkinLoaderSet(true);
    updateCheckin({
      data: {
        categories: currentCheckin,
        ...otherInput,
      },
      date: formattedDate,
      isEdit: isCustomEdit,
      onSuccess: () => {
        setGracePeriodDate(null);
        props.checkinLoaderSet(false);
      },
    });
  };

  const getProgressPercentage = (goal) => {
    let percentage;
    if (goal.expected_target === 0 && goal.target == 0) {
      return 100;
    }
    if (goal.metric === 2) {
      if (goal.target === null || goal.target === '') {
        return 0;
      }
      percentage = ((goal.expected_target - goal.target) / goal.expected_target) * 100;
      percentage = percentage < 0 ? 0 : percentage;
    } else {
      percentage = (goal.target / goal.expected_target) * 100;
      percentage = parseInt(percentage);
    }
    return percentage;
    // return percentage > 100 ? 100 : percentage;
  };
  const viewFile = (content) => {
    const win = window.open(content, '_blank');
    win.focus();
  };
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={styles.checkinDrawer}
          >
            <div className={styles.checkinDrawerWrapper}>
              <div className={styles.checkingDrawerHeader}>
                <img
                  src={imageBasePath + 'close.svg'}
                  className={styles.checkinDrawerCloseIcon}
                  onClick={toggleDrawer(anchor, false)}
                />
                <img
                  src={imageBasePath + 'checkin_drawer_icon.svg'}
                  className={styles.checkinDrawerIcon}
                />
                <h4 className={styles.checkinDrawerHeading + ' heading4 '}>Check-in</h4>
                {salesGoals && salesGoals.frequency ? (
                  <p className={styles.checkinDrawerDurationTag}>
                    {goalFrequencies.find((goal) => goal.id === salesGoals?.frequency)?.label}
                  </p>
                ) : null}
                {isEditLoader ? null : isEdit ? (
                  <p className={styles.checkinDrawerDuration + ' supportTextSemibold '}>
                    {salesGoals?.frequency === 1 && latestEditCheckin?.checkin_date
                      ? format(
                          changeTimezone(new Date(latestEditCheckin?.checkin_date)),
                          'dd MMM yyyy'
                        )
                      : salesGoals?.frequency === 2 && latestEditCheckin?.checkin_date
                      ? format(
                          startOfWeek(new Date(latestEditCheckin?.checkin_date), {
                            weekStartsOn: 1,
                          }),
                          'dd MMM yyyy'
                        ) +
                        ' - ' +
                        format(
                          endOfWeek(new Date(latestEditCheckin?.checkin_date), { weekStartsOn: 1 }),
                          'dd MMM yyyy'
                        )
                      : salesGoals?.frequency === 2 && latestEditCheckin?.checkin_date
                      ? format(
                          startOfMonth(new Date(latestEditCheckin?.checkin_date)),
                          'dd MMM yyyy'
                        ) +
                        ' - ' +
                        format(endOfMonth(new Date(latestEditCheckin?.checkin_date)), 'dd MMM yyyy')
                      : date?.start_date && date?.end_date
                      ? format(date.start_date, 'dd MMM yyyy') +
                        ' - ' +
                        format(date.end_date, 'dd MMM yyyy')
                      : null}
                  </p>
                ) : (
                  <p className={styles.checkinDrawerDuration + ' supportTextSemibold '}>
                    {salesGoals?.frequency === 1 && date?.start_date
                      ? format(date.start_date, 'dd MMM yyyy')
                      : date?.start_date && date?.end_date
                      ? format(date.start_date, 'dd MMM yyyy') +
                        ' - ' +
                        format(date.end_date, 'dd MMM yyyy')
                      : null}
                  </p>
                )}
                <FlexiblePeriod
                  date={date}
                  salesGoals={salesGoals}
                  handleSelectNewDate={handleSelectNewDate}
                />
              </div>
              <div className={styles.checkinDrawerBody}>
                {isEditLoader ? (
                  <Shimmer type="CheckinDrawer" />
                ) : currentCheckin && currentCheckin.length ? (
                  <>
                    {currentCheckin.map((org, index) => (
                      <div key={index} className={styles.checkinCustomInputWrapper}>
                        <Grid container direction="row" spacing={3}>
                          {org.goals.map((goal, key) => (
                            <Grid key={key} item xs={6}>
                              <div className={styles.checkinDrawerInput}>
                                <label
                                  className={styles.checkinLabel + ' supportText '}
                                  style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <span>
                                    <span className={styles.checkinMessage}>
                                      {goal.checkin_message ? goal.checkin_message : goal.name}
                                    </span>

                                    {goal?.description && (
                                      <CustomTooltipWhite
                                        tooltipIcon="info_icon.svg"
                                        title={
                                          <p
                                            className={styles.checkinTooltipContent + ' heading6 '}
                                          >
                                            {goal.description}
                                          </p>
                                        }
                                        className={styles.checkinTooltip}
                                      />
                                    )}
                                  </span>
                                  {org?.name?.toLowerCase().includes('hubspot') && (
                                    <HubspotTooltip />
                                  )}
                                </label>

                                <div className={styles.checkinInputBlk}>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      xs={
                                        goal.expected_target
                                          ? 8
                                          : goal.expected_target == '0'
                                          ? 8
                                          : 12
                                      }
                                      className="flexNotCentered"
                                    >
                                      <div className={styles.checkinDrawerCustomInput}>
                                        <InputText
                                          disabled={org?.name?.toLowerCase().includes('hubspot')}
                                          placeholder="eg, 55"
                                          className={styles.checkinTarget}
                                          value={goal.target || '0'}
                                          onChange={(e) =>
                                            Number.isInteger(Number(e.target.value)) &&
                                            Number(e.target.value) > 0
                                              ? handleChange(goal.uuid, parseInt(e.target.value))
                                              : e.target.value === '' || e.target.value === '0'
                                              ? handleChange(goal.uuid, e.target.value)
                                              : e.preventDefault()
                                          }
                                          InputProps={
                                            goal.unit === 2
                                              ? {
                                                  inputComponent: NumberFormatCustom,
                                                }
                                              : { inputmode: 'numeric', pattern: '[0-9]*' }
                                          }
                                        />
                                      </div>
                                      {error[goal.uuid] ? (
                                        <h6 className="errorMsg">{error[goal.uuid]}</h6>
                                      ) : null}
                                    </Grid>

                                    <Grid item xs={4} className="flexNotCentered">
                                      <div className={styles.checkinDrawerGoalInputCountWrapper}>
                                        {goal.unit === 1 ? (
                                          <img
                                            src={imageBasePath + 'goal.svg'}
                                            className={styles.checkinDrawerGoalInputIcon}
                                          />
                                        ) : (
                                          '$'
                                        )}
                                        <p
                                          className={
                                            styles.checkinDrawerGoalInputCount + ' heading6 '
                                          }
                                        >
                                          {CustomNumberFormatter(goal.expected_target)}
                                        </p>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className={styles.checkinDrawerProgressWrapper}>
                                  <ProgressBar
                                    value={getProgressPercentage(goal)}
                                    max="100"
                                    className={
                                      styles.checkinProgressBar +
                                      `${getProgressPercentage(goal) < 50 ? ' error ' : ''}`
                                    }
                                  />
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    ))}
                  </>
                ) : null}
                {isEditLoader ? null : (
                  <div className={styles.checkinCustomInputWrapper}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12}>
                        <label className={styles.checkinCustomLabel + ' supportText '}>
                          Do any of your customer needs attention?
                        </label>
                        <div className={styles.checkinInputBtnBlk}>
                          <CustomButton
                            onClick={() =>
                              otherInputSet({ ...otherInput, need_customer_attention: 1 })
                            }
                            className={
                              styles.checkinInputBtn +
                              ' linkBtn ' +
                              `${otherInput.need_customer_attention === 1 ? styles.activeBtn : ''}`
                            }
                          >
                            Yes
                          </CustomButton>
                          <CustomButton
                            onClick={() =>
                              otherInputSet({ ...otherInput, need_customer_attention: 0 })
                            }
                            className={
                              styles.checkinInputBtn +
                              ' linkBtn ' +
                              `${otherInput.need_customer_attention === 0 ? styles.activeBtn : ''}`
                            }
                          >
                            No
                          </CustomButton>
                        </div>
                        {otherInput.need_customer_attention === 1 ? (
                          <div className={styles.explanationTextArea}>
                            <TextArea
                              label={`Elaborate issues you're facing`}
                              placeholder=""
                              className={styles.checkinTextArea}
                              rows="3"
                              onChange={(e) =>
                                otherInputSet({ ...otherInput, remarks: e.target.value })
                              }
                              value={otherInput.remarks}
                            />
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <TextArea
                          id="checkinTarget"
                          label={`Lesson learned?`}
                          placeholder="Share a key lesson learned today that you wish like to highlight as part of the Sales process checkin"
                          className={styles.checkinTextArea}
                          rows="3"
                          onChange={(e) =>
                            otherInputSet({ ...otherInput, lessons_learned: e.target.value })
                          }
                          value={otherInput.lessons_learned}
                        />
                        <TextArea
                          id="checkinTarget"
                          label={`Best practices?`}
                          placeholder="Share a best practice that is consistently delivering positive results for you as part of the Sales process"
                          className={styles.checkinTextArea}
                          rows="3"
                          onChange={(e) =>
                            otherInputSet({ ...otherInput, best_practices: e.target.value })
                          }
                          value={otherInput.best_practices}
                        />
                        {FeatureFlag.enabled('CHECKINWFILE') && (
                          <>
                            <div className="justifyItems">
                              <label className={styles.checkinCustomLabel + ' supportText '}>
                                {replaceFile ? 'Replace File? ' : 'Additional File? '}
                              </label>
                              {replaceFile && (
                                <label className="helpText" onClick={() => setReplaceFile(false)}>
                                  Cancel
                                </label>
                              )}
                            </div>
                            {otherInput.additional_file && !replaceFile && (
                              <div className="alignItems">
                                <CustomButton
                                  onClick={(e) => {
                                    viewFile(otherInput.additional_file);
                                    e.preventDefault();
                                  }}
                                >
                                  Download{' '}
                                  {
                                    otherInput.additional_file.split('/')[
                                      otherInput.additional_file.split('/').length - 1
                                    ]
                                  }
                                </CustomButton>
                                <CustomButton
                                  className={`tertiaryBtn`}
                                  onClick={() => {
                                    !confirmDelete ? setReplaceFile(true) : setConfirmDelete(false);
                                  }}
                                >
                                  {!confirmDelete ? 'Replace File' : 'Cancel Delete'}
                                </CustomButton>
                                {!confirmDelete && (
                                  <CustomButton
                                    className={`errorBtn`}
                                    onClick={() => {
                                      setConfirmDelete(true);
                                    }}
                                  >
                                    <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                                    Delete{' '}
                                    {
                                      otherInput.additional_file.split('/')[
                                        otherInput.additional_file.split('/').length - 1
                                      ]
                                    }
                                  </CustomButton>
                                )}
                                {confirmDelete && (
                                  <CustomButton
                                    className={`errorBtn`}
                                    onClick={() => {
                                      otherInputSet({ ...otherInput, additional_file: null });
                                      setConfirmDelete(false);
                                    }}
                                  >
                                    <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                                    Confirm Delete
                                  </CustomButton>
                                )}
                              </div>
                            )}
                            <FileUploader
                              maxFiles={1}
                              accept="*"
                              maxSizeBytes={1024 * 1024 * 200}
                              acceptedFiles={acceptedFiles}
                              setAcceptedFiles={setAcceptedFiles}
                              otherInputSet={otherInputSet}
                              otherInput={otherInput}
                              replaceFile={replaceFile}
                              setReplaceFile={setReplaceFile}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
              <div className={styles.checkinDrawerFooter}>
                <DialogActions className={styles.dialogActions}>
                  <CustomButton className={`tertiaryBtn`} onClick={toggleDrawer(anchor, false)}>
                    Cancel
                  </CustomButton>
                  <CustomButton
                    className={`primaryBtn ${checkinLoader ? 'disabledBtn' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {checkinLoader ? 'Saving...' : 'Save'}
                  </CustomButton>
                </DialogActions>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

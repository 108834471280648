import { useState } from 'react';
import { Popover } from '@material-ui/core';
import { format } from 'date-fns';
import { Calendar } from 'react-date-range';
import CustomButton from 'shared_ui_components/CustomButton';
export default function DatePicker(props) {
  const { defaultFilter = {}, handleApply = () => null } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(new Date(defaultFilter.date));

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    handleApply(format(new Date(date), 'yyyy-MM-dd'));
    handleClose();
  };
  const handleChangeDate = (range) => {
    setDate(range);
  };

  const handleOpenFilter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className="dateRangeFilterWrapper">
      <div className="dateValues" onClick={handleOpenFilter}>
        <p className="heading6">
          <span>
            {defaultFilter.date
              ? `${format(new Date(defaultFilter.date + 'T00:00:00'), 'MMM dd, yyyy')}`
              : `Select date`}
          </span>
        </p>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="dateRangeSelectPopup"
      >
        <div className="dateRangeSelectWrapper">
          <div className="dateRangePicker">
            <Calendar
              onChange={(item) => handleChangeDate(item)}
              date={date}
              minDate={new Date()}
            />
          </div>
          <div className="footer">
            <ul className="listInline">
              <li className="listInlineItem">
                <CustomButton className="tertiaryBtn smallBtn" onClick={handleClose}>
                  {'Cancel'}
                </CustomButton>
              </li>
              <li className="listInlineItem">
                <CustomButton onClick={handleSubmit} className={`primaryBtn smallBtn`}>
                  Apply
                </CustomButton>
              </li>
            </ul>
          </div>
        </div>
      </Popover>
    </div>
  );
}

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { imageBasePath, roles } from '../../../constants';
import styles from '../../../assets/styles/settings.module.scss';
import {
  CustomButton,
  InputText,
  InputSelect,
  ExternalLink,
  CustomTooltipWhite,
} from '../../../shared_ui_components';
import { GlobalContext } from '../../../context/GlobalContext';
import { TooltipCustom } from '../../../utils';
import { Redirect } from 'react-router-dom';
import ImageUploader from './ImageUploader';

import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { Subscription } from 'controllers/_exports';

const companySizes = [
  { value: 1, label: '1 to 10' },
  { value: 2, label: '11 to 50' },
  { value: 3, label: '51 to 250' },
  { value: 4, label: '251 or more' },
];

const companyCategory = [
  { value: 1, label: 'Product' },
  { value: 2, label: 'Service' },
];

const getTimezoneOptions = (timezones) => {
  if (timezones && Object.keys(timezones).length) {
    let options = [];
    Object.keys(timezones).forEach((key) => {
      options = [...options, ...[{ value: timezones[key].key, label: timezones[key].label }]];
    });
    return options;
  } else {
    return [];
  }
};

const getCompanyValue = (org) => {
  if (org && org.size) {
    let val = companySizes.find(({ value }) => value == org.size.value);
    if (val) {
      return val;
    }
    return '';
  }
  return '';
};

export default function SettingsForm(props) {
  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const { timezones, org, submitLoader, userEmail } = props;
  const [state, setState] = useState({
    logo: '',
    brandedLogo: '',
    size: getCompanyValue(org),
    timezone: getTimezoneOptions(timezones).find(({ key }) => key === org.timezone),
    sales_lead_require_onboarding: '',
    sales_rep_require_onboard_training: '',
    recruiting_module_enabled: '',
    flexible_checkin_windows: '',
    support_email: '',
  });
  const [openAlert, setOpenAlert] = useState(false);
  const { state: globalState, dispatch } = useContext(GlobalContext);
  const copyText = useRef(null);

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  useEffect(() => {
    setState({
      name: org.name,
      logo: org.logo,
      brandedLogo: org.brandedLogo,
      size: getCompanyValue(org),
      timezone: getTimezoneOptions(timezones).find(({ value }) => value === org.timezone),
      category: companyCategory.find((cat) => cat.value === org?.category?.value),
      website: org.website,
      sales_lead_require_onboarding: org.sales_lead_require_onboarding,
      sales_rep_require_onboard_training: org.sales_rep_require_onboard_training,
      recruiting_module_enabled: org.recruiting_module_enabled,
      flexible_checkin_windows: org.flexible_checkin_windows,
      support_email: org.support_email,
    });
  }, [org, timezones]);

  // Redirect if not admin or primary admin
  const canViewPage = () => {
    let role = globalState?.role?.slug;
    if (!role) role = roles.find(({ id }) => id === selectedOrg?.user_role)?.slug;
    return ['primary_admin', 'admin'].includes(role);
  };
  if (!canViewPage()) return <Redirect to="/" />;

  // Submit handler
  const handleSubmit = () => {
    props.setSubmitLoader(true);
    const data = new FormData();
    if (state.logoData) {
      for (let file of state.logoData) {
        data.append('logo', file, file.name);
      }
    } else if (!state.logo) {
      data.append('logo', '');
    }
    if (state.brandedLogoData) {
      for (let file of state.brandedLogoData) {
        data.append('brandedLogo', file, file.name);
      }
    } else if (!state.brandedLogo) {
      data.append('brandedLogo', '');
    }
    if (state.size?.value) {
      data.append('size', state.size?.value);
    }
    if (state.timezone?.value) {
      data.append('timezone', state.timezone?.value);
    }
    if (state?.category?.value) {
      data.append('category', state.category.value);
    }
    if (state?.name) {
      data.append('name', state?.name);
    }
    if (state?.website) {
      data.append('website', state?.website);
    }
    if (state?.support_email) {
      data.append('support_email', state?.support_email);
    }
    data.append('sales_lead_require_onboarding', state?.sales_lead_require_onboarding ? 1 : 0);
    data.append(
      'sales_rep_require_onboard_training',
      state?.sales_rep_require_onboard_training ? 1 : 0
    );
    data.append('recruiting_module_enabled', state?.recruiting_module_enabled ? 1 : 0);
    data.append('flexible_checkin_windows', state?.flexible_checkin_windows ? 1 : 0);
    props.handleSubmit(data);
  };

  const onIconChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setState({
        ...state,
        logo: URL.createObjectURL(event.target.files[0]),
        logoData: event.target.files,
      });
    }
  };

  const onBrandedLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setState({
        ...state,
        brandedLogo: URL.createObjectURL(event.target.files[0]),
        brandedLogoData: event.target.files,
      });
    }
  };

  const DeleteAndRestoreImage = ({ name }) => {
    const showRestore = !state[`${name}Data`] && !state[name] && org[name];
    const showDelete = org[name] || state[name];

    const onDeleteClick = () => {
      setState({ ...state, [name]: '', [`${name}Data`]: null });
    };
    const onRestoreClick = () => {
      if (!org[name]) return;
      setState({ ...state, [name]: org[name], [`${name}Data`]: null });
    };

    const style = {
      padding: '3px 0',
    };

    return showRestore ? (
      <Button className="secondaryBtn" style={style} onClick={onRestoreClick}>
        <RestoreIcon />
      </Button>
    ) : showDelete ? (
      <Button className="secondaryBtn" style={style} onClick={onDeleteClick}>
        <DeleteIcon />
      </Button>
    ) : null;
  };

  const copyEmail = () => {
    copyText?.current?.select();
    document.execCommand('copy');
    setOpenAlert(true);
  };
  return (
    <div className={styles.settingsFormSection}>
      {/* Company logo and icon */}
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={styles.settingsCompanyLogoWrapper}>
            <div className={styles.settingsCompanyBrandedLogo}>
              <img
                className={styles.logo}
                src={state.logo || `${imageBasePath}add_company_logo_full.svg`}
                alt="icon"
              />
            </div>
          </div>
          <div style={{ marginBottom: '1rem', display: 'flex', gap: 10 }}>
            <ImageUploader
              identifier="brandedIcon"
              title="Upload icon"
              handleChange={onIconChange}
            />
            <DeleteAndRestoreImage name={'logo'} />
          </div>
          <p className="heading6" style={{ color: '#87A0B4', marginBottom: '16px' }}>
            We will be using this uploaded icon in all reports as well as profile.
          </p>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className={styles.settingsCompanyLogoWrapper}>
            <div className={styles.settingsCompanyBrandedLogo}>
              <div className={styles.centerVertical}>
                <img
                  className={styles.brandedLogo}
                  src={state?.brandedLogo || imageBasePath + 'no-logo.png'}
                  alt="Company logo"
                />
              </div>
            </div>
          </div>
          <div style={{ marginBottom: '1rem', display: 'flex', gap: 10 }}>
            <ImageUploader
              title="Upload logo"
              identifier="brandedLogo"
              handleChange={onBrandedLogoChange}
            />
            <DeleteAndRestoreImage name={'brandedLogo'} />
          </div>
          <p className="heading6" style={{ color: '#87A0B4', marginBottom: '16px' }}>
            We will be using your company logo on the top left corner of the application for
            branding.
          </p>
        </Grid>
      </Grid>

      <div className={styles.settingsFormInputWrapper}>
        <Grid container direction="row" spacing={3} className={styles.settingsFormInputRow}>
          <Grid item xs={4} className={styles.settingsFormInput}>
            {['primary_admin'].includes(globalState?.role?.slug) ? (
              <InputText
                label="Company Name"
                placeholder="Please add your company name here"
                value={state.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            ) : (
              <>
                <label>Company Name</label>
                <p className={styles.customDisabledInput + ' heading6 '}>{org?.name}</p>
              </>
            )}
          </Grid>
          <Grid item xs={4} className={styles.settingsFormInput}>
            {['primary_admin'].includes(globalState?.role?.slug) ? (
              <InputSelect
                closeMenuOnSelect={true}
                label={`Share as`}
                options={companyCategory}
                isSearchable={false}
                placeholder={'We need you to add your company category'}
                noOptionsMessage={() => 'No matches found'}
                onChange={(selectedOption) => handleChange('category', selectedOption)}
                value={state.category}
              />
            ) : (
              <>
                <label>Company Category</label>
                <p className={styles.customDisabledInput + ' heading6 '}>{org?.category?.label}</p>
              </>
            )}
          </Grid>
          <Grid item xs={4} className={styles.settingsFormInput}>
            {['primary_admin'].includes(globalState?.role?.slug) ? (
              <InputText
                label="Company Website"
                placeholder="Please add your company website link here"
                value={state.website || ''}
                onChange={(e) => handleChange('website', e.target.value)}
              />
            ) : (
              <>
                {org.website ? (
                  <>
                    <label>Company Website</label>
                    <p className={styles.customDisabledInput + ' heading6 '}>{org?.website}</p>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3} className={styles.settingsFormInputRow}>
          <Grid item xs={4} className={styles.settingsFormInput}>
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect={true}
              label={`Company Size`}
              options={companySizes}
              isSearchable={true}
              placeholder={'Please disclose the size of your company'}
              noOptionsMessage={() => 'No matches found'}
              onChange={(selectedOption) => {
                handleChange('size', selectedOption);
              }}
              value={state.size}
            />
          </Grid>
          <Grid item xs={4} className={styles.settingsFormInput}>
            <InputSelect
              className="reactSelect"
              closeMenuOnSelect={true}
              label={`Time Zone`}
              options={getTimezoneOptions(timezones)}
              isSearchable={true}
              placeholder={'Africa/Abidjan'}
              noOptionsMessage={() => 'No matches found'}
              onChange={(selectedOption) => handleChange('timezone', selectedOption)}
              value={state.timezone}
            />
          </Grid>
          <Grid item xs={4} className={styles.settingsFormInput}>
            <div className="inputGroup">
              {['primary_admin'].includes(globalState?.role?.slug) ? (
                <InputText
                  id="email"
                  label="Support Email"
                  placeholder="Enter email address"
                  value={state.support_email || ''}
                  onChange={(e) => handleChange('support_email', e.target.value)}
                />
              ) : state.support_email ? (
                <>
                  <p className={styles.supportEmailLabel}>
                    Support Email
                    <span className={styles.supportEmailTootltip}>
                      <CustomTooltipWhite
                        placement="top"
                        tooltipIcon="info_icon.svg"
                        title={
                          <div className="customTooltipContent">
                            <p className="supportText">{`Clicking contact button allows you to write an email to your support team`}</p>
                          </div>
                        }
                      />
                    </span>
                  </p>
                  <p className={styles.supportEmailWrapper + ' ' + ' supportText '}>
                    <span
                      className={styles.supportEmail + ' heading6 '}
                    >{`${state.support_email}`}</span>
                    <TooltipCustom
                      onClose={() =>
                        setTimeout(() => {
                          setOpenAlert(false);
                        }, 300)
                      }
                      arrow
                      title={
                        <div className="supportText" style={{ color: `#fff` }}>
                          {openAlert ? `Email copied to clipboard` : 'Copy email'}
                        </div>
                      }
                      {...props}
                      placement="top"
                    >
                      <img
                        className="copyText"
                        onClick={() => copyEmail()}
                        src={imageBasePath + 'copy_icon.svg'}
                        alt="copy"
                        style={{ margin: '0 0 -3px 5px', cursor: 'pointer' }}
                      />
                    </TooltipCustom>
                  </p>
                  <p className={styles.contactMailBtn}>
                    <ExternalLink
                      target="_blank"
                      linktext="Contact"
                      href={`mailto:${state.support_email}?subject=<${userEmail}> needs your assistance`}
                      className="tertiaryBtn"
                    />
                  </p>
                  <input
                    type="text"
                    value={state.support_email}
                    ref={copyText}
                    className={styles.contactMailHiddenInput}
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.settingsFormInputRow}>
          <Grid item xs={4} className={styles.settingsFormInput}>
            <label className={styles.settingsCustomLabel + ' supportText '}>
              Sales Reps can sell only after Onboarding and Training?
            </label>
            <div className={styles.checkinInputBtnBlk}>
              <CustomButton
                onClick={() => handleChange('sales_rep_require_onboard_training', true)}
                className={
                  styles.settingsInputBtn +
                  ' linkBtn ' +
                  `${state.sales_rep_require_onboard_training ? styles.activeBtn : ''}`
                }
              >
                Yes
              </CustomButton>
              <CustomButton
                onClick={() => handleChange('sales_rep_require_onboard_training', false)}
                className={
                  styles.settingsInputBtn +
                  ' linkBtn ' +
                  `${!state.sales_rep_require_onboard_training ? styles.activeBtn : ''}`
                }
              >
                No
              </CustomButton>
            </div>
          </Grid>
          {['primary_admin', 'admin'].includes(globalState?.role?.slug) ? (
            <Grid item xs={4} className={styles.settingsFormInput}>
              <label className={styles.settingsCustomLabel + ' supportText '}>
                Sales Leads need to finish Onboarding?
              </label>
              <div className={styles.checkinInputBtnBlk}>
                <CustomButton
                  onClick={() => handleChange('sales_lead_require_onboarding', true)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${state.sales_lead_require_onboarding ? styles.activeBtn : ''}`
                  }
                >
                  Yes
                </CustomButton>
                <CustomButton
                  onClick={() => handleChange('sales_lead_require_onboarding', false)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${!state.sales_lead_require_onboarding ? styles.activeBtn : ''}`
                  }
                >
                  No
                </CustomButton>
              </div>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          style={{ marginTop: '1rem' }}
          container
          spacing={3}
          className={styles.settingsFormInputRow}
        >
          {['primary_admin'].includes(globalState?.role?.slug) ? (
            <Grid item xs={4} className={styles.settingsFormInput}>
              <label className={styles.settingsCustomLabel + ' supportText '}>
                Enable Recruiting Module?
              </label>
              <div className={styles.checkinInputBtnBlk}>
                <CustomButton
                  onClick={() => handleChange('recruiting_module_enabled', true)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${state.recruiting_module_enabled ? styles.activeBtn : ''}`
                  }
                >
                  Yes
                </CustomButton>
                <CustomButton
                  onClick={() => handleChange('recruiting_module_enabled', false)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${!state.recruiting_module_enabled ? styles.activeBtn : ''}`
                  }
                >
                  No
                </CustomButton>
              </div>
            </Grid>
          ) : null}
          {['primary_admin', 'admin'].includes(globalState?.role?.slug) ? (
            <Grid item xs={4} className={styles.settingsFormInput}>
              <label className={styles.settingsCustomLabel + ' supportText '}>
                Flexible Check-In Window?
              </label>
              <div className={styles.checkinInputBtnBlk}>
                <CustomButton
                  onClick={() => handleChange('flexible_checkin_windows', true)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${state.flexible_checkin_windows ? styles.activeBtn : ''}`
                  }
                >
                  Yes
                </CustomButton>
                <CustomButton
                  onClick={() => handleChange('flexible_checkin_windows', false)}
                  className={
                    styles.settingsInputBtn +
                    ' linkBtn ' +
                    `${!state.flexible_checkin_windows ? styles.activeBtn : ''}`
                  }
                >
                  No
                </CustomButton>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <Grid container direction="row" spacing={2} className={styles.settingsFormBtnRowWrapper}>
        <Grid item xs={12} className={styles.settingsFormBtnRow}>
          <CustomButton
            className={
              styles.settingsFormBtn + ' primaryBtn ' + `${submitLoader ? ' disabledBtn ' : ''}`
            }
            children={submitLoader ? 'Saving...' : 'Save'}
            onClick={() => handleSubmit()}
          />
          {/* <CustomButton
            className={styles.settingsFormBtn + " tertiaryBtn "}
            children="Cancel"
          /> */}
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { Dialog } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { CustomButton, InputText } from '../../../shared_ui_components';
import styles from '../../../assets/styles/onboarding.module.scss';
export default function FeedbackDialog(props) {
  const { open, type, title, subtitle, handleSubmit, loader } = props;
  const [formData, setFormData] = useState({
    rating: '',
    comment: '',
  });
  const [error, setError] = useState({});

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    let validateNewInput = {
      rating: formData.rating ? '' : 'Please provide rating',
      comment: '',
    };
    if (Object.keys(validateNewInput).every((key) => validateNewInput[key] === '')) {
      props.handleSubmit({ ...formData, comment: formData.comment ? formData.comment.trim() : '' });
    } else {
      setError({
        validateNewInput,
      });
    }
  };
  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
    setError({
      ...error,
      [key]: '',
    });
  };
  useEffect(() => {
    if (open) {
      setError({});
      setFormData({
        rating: '',
        comment: '',
      });
    }
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        scroll={'paper'}
        className={`${styles.publishDialog + ' ' + styles.feedBackDialog}`}
      >
        <div className={styles.dialogBody + ' textCenter'}>
          <img
            src={imageBasePath + 'publish_dialog_bg.png'}
            alt="publish banner"
            className={styles.publishBanner}
          />
          {type == 'publish' ? (
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className={styles.modalCloseIcon}
              onClick={props.handleClose}
            />
          ) : null}
          <img
            src={imageBasePath + 'published_logo.png'}
            alt="Publish logo"
            className={styles.publishLogo}
          />
          <h4 className="heading4">{title}</h4>
          <p className="paragraph">{subtitle}</p>
          {type == 'publish' ? (
            <form className={`${styles.ratingForm} ${styles.publish}`}>
              <ul className="listInline">
                <li className="listInlineItem">
                  <CustomButton className="secondaryBtn" onClick={props.handleContinue}>
                    Track Onboarding
                  </CustomButton>
                </li>
                <li className="listInlineItem">
                  <CustomButton className="primaryBtn" onClick={handleSubmit}>
                    Invite Users
                  </CustomButton>
                </li>
              </ul>
            </form>
          ) : (
            <form className={styles.ratingForm}>
              <Rating
                name="simple-controlled"
                value={formData.rating}
                precision={1}
                onChange={(e) => handleChange('rating', e.target.value)}
              />
              <InputText
                placeholder="Add additional comments"
                multiline
                rows="2"
                onChange={(e) => handleChange('comment', e.target.value)}
              />
              <ul className="listInline">
                <li className="listInlineItem">
                  <CustomButton
                    className={`primaryBtn ${!formData.rating || loader ? 'disabledBtn' : ''} `}
                    onClick={handleSubmitFeedback}
                  >
                    Submit
                  </CustomButton>
                </li>
              </ul>
            </form>
          )}
        </div>
      </Dialog>
    </div>
  );
}

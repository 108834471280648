import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { imageBasePath } from 'constants';
import styles from './EditLearningPath.module.scss';
import { CustomButton } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { useUpdateLearningPathMutation } from '../../LearningPaths.queries';
import DateTime from 'infrastructure/Datetime';
import EditLearningPathContent from './EditLearningPathContent';

const EditLearningPath = () => {
  const {
    currentLearningPath,
    setCurrentLearningPath,
    editModalOpen,
    closeEditModal,
  } = useLearningPathsStore();
  const { mutate: editLearningPath, isLoading } = useUpdateLearningPathMutation();

  const enable = useMemo(() => {
    if (currentLearningPath?.title?.trim().length === 0) return false;
    return !isLoading;
  }, [currentLearningPath, isLoading]);

  const onClose = () => {
    closeEditModal();
  };
  const handleSave = () => {
    let users_uuid;
    if (currentLearningPath?.users.length > 0) {
      users_uuid = currentLearningPath?.users.map((user) => {
        return user.value;
      });
    }
    const data = {
      title: currentLearningPath.title,
      description: currentLearningPath.description || '',
      deadline: currentLearningPath.deadline || '',
      users_uuid: users_uuid || [],
    };
    editLearningPath(
      { uuid: currentLearningPath?.uuid, body: data },
      {
        onSuccess: () => {
          setCurrentLearningPath({
            deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
            users: [],
            title: '',
            description: '',
          });
          onClose();
        },
      }
    );
  };
  return (
    <>
      <Dialog
        open={editModalOpen}
        onClose={onClose}
        scroll={'paper'}
        aria-labelledby="add-video-title"
        aria-describedby="add-video-description"
        className={`dialog addVideoDialog ${styles.dialog}`}
      >
        <DialogTitle>
          <div>Learning path: Add new Learning path</div>
          <Typography variant="body2">
            Provide a personalized learning path tailored to each sales rep needs.
          </Typography>

          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <EditLearningPathContent />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={onClose} className="tertiaryBtn">
            Cancel
          </CustomButton>
          <CustomButton disabled={!enable} onClick={handleSave}>
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLearningPath;

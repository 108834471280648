import React, { useEffect } from 'react';

import { useGetPaymentPlansQuery } from '../../../Payment.queries';
import { usePaymentStore } from '../../../Payment.store';
import Loading from 'shared_ui_components/Loading';
import ProductsList from './ProductsList';
import CouponInput from './CouponInput/CouponInput';
import { Payment } from 'features/payment/Payment.utils';

const ProductSelector = () => {
  // Payment store data
  const setSelectedProduct = usePaymentStore((state) => state?.setSelectedProduct);
  const coupon = usePaymentStore((state) => state?.couponData.coupon);

  // Retrieve selected price from webpage
  const { preSelectedPrice: price } = Payment.getPriceAndCoupon();

  // Retrieve plans from backend
  const { data, isFetching, isLoading } = useGetPaymentPlansQuery({ coupon, price });

  useEffect(() => {
    // Set first price selected by default
    if (!data?.products.length > 0) return;
    setSelectedProduct({
      ...data.products[0],
      selectedPrice: data?.products[0]?.prices[0] || {},
    });
  }, [data, setSelectedProduct]);

  return (
    <div>
      <CouponInput data={data} header />
      <Loading loading={isLoading || isFetching} circular />
      <ProductsList data={data} />
      <CouponInput data={data} disabled={isLoading} />
    </div>
  );
};

export default ProductSelector;

import React from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import {
  InputText,
  CustomButton,
  ExternalLink,
} from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { List, ListItem, Tooltip, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function InviteForm(props) {
  const {
    formData,
    error,
    submitLoader,
    socialLoginLoader,
    socialLinkedinLoader,
    passwordValidation,
  } = props;
  var base_url = window.location.origin;

  return (
    <div className={styles.cardWrapper + ' ' + styles.signInFormWrapper} key={1}>
      <div className={styles.authInnerWrapper}>
        <Link to="/">
          <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
        </Link>
        <h1 className="heading3">Join the salestable</h1>
        <div className={styles.socialLoginWrapper}>
          <List className={styles.listInline + ' listInline'}>
            <ListItem className="listInlineItem">
              <a
                href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=1CXFac0eAkqBarEbqCbABBUkFppA788811uX7jE&redirect_uri=${base_url}/signin&client_id=621423238106-1riec29e2sjd9ha0p4mmprnkmm5vh451.apps.googleusercontent.com`}
              >
                <img src={imageBasePath + 'google.svg'} alt="Google" />
                <span>{socialLoginLoader ? 'Signing in with Google' : 'Sign in with Google'}</span>
              </a>
            </ListItem>
            <ListItem className="listInlineItem">
              <a
                href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=IZOJKueOfkkSSaoG&scope=email%20profile%20openid%20w_member_social&client_id=86urjwofatbv8j&redirect_uri=${base_url}/signin`}
              >
                <img src={imageBasePath + 'linkedin.svg'} alt="LinkedIn" />
                <span>
                  {socialLinkedinLoader ? 'Signing in with Linkedin' : 'Sign in with Linkedin'}
                </span>
              </a>
            </ListItem>
          </List>
        </div>
        <p className={styles.optionHeading}>
          <span> Sign in using email</span>
        </p>
        <div className={styles.formWrapper}>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputText
                label="First Name"
                placeholder="eg. John"
                value={formData.first_name ? formData.first_name : ''}
                onChange={(e) => props.handleChange('first_name', e.target.value)}
                error={error.first_name}
                className={error.first_name ? 'errorGroup' : ''}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputText
                label="Last Name"
                placeholder="eg. Doe"
                value={formData.last_name ? formData.last_name : ''}
                onChange={(e) => props.handleChange('last_name', e.target.value)}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
          </Grid>
          <div className={`${styles.disabledEmailBlock} inputGroup`}>
            <label>Work email address</label>
            <p className="paragraph">{formData.email}</p>
            <input
              type="email"
              style={{ position: 'absolute', opacity: 0, width: 0 }}
              value={formData.email}
            />
          </div>
          <div className="inputGroup passwordGroup">
            <Tooltip
              title={
                <div className={styles.tooltipWrapper}>
                  <p className="supportText">Password must contain</p>
                  <ul className="listUnstyled">
                    <li className="paragraph">
                      {passwordValidation.minCharacter ? (
                        <img src={imageBasePath + 'right.svg'} alt="image" />
                      ) : (
                        <img src={imageBasePath + 'wrong.svg'} alt="image" />
                      )}
                      <span>
                        <b> 8</b> Characters minimum
                      </span>
                    </li>
                    <li className="paragraph">
                      {passwordValidation.oneNumber ? (
                        <img src={imageBasePath + 'right.svg'} alt="image" />
                      ) : (
                        <img src={imageBasePath + 'wrong.svg'} alt="image" />
                      )}
                      <span>
                        <b> 1</b> Number atleast
                      </span>
                    </li>
                    <li className="paragraph">
                      {passwordValidation.oneUpperCase ? (
                        <img src={imageBasePath + 'right.svg'} alt="image" />
                      ) : (
                        <img src={imageBasePath + 'wrong.svg'} alt="image" />
                      )}
                      <span>
                        <b> 1</b> Uppercase letter atleast (A-Z)
                      </span>
                    </li>
                    <li className="paragraph">
                      {passwordValidation.oneLowerCase ? (
                        <img src={imageBasePath + 'right.svg'} alt="image" />
                      ) : (
                        <img src={imageBasePath + 'wrong.svg'} alt="image" />
                      )}
                      <span>
                        <b> 1</b> Lowercase letter atleast (a-z)
                      </span>
                    </li>
                  </ul>
                </div>
              }
              placement="right"
              PopperProps={{
                disablePortal: true,
              }}
              disableHoverListener
            >
              <InputText
                id="password"
                label="Create password"
                type={formData.password_show ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder="********"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => props.handleChange('password', e.target.value.trim())}
                value={formData.password}
                error={error.password}
                className={error.password ? 'errorGroup' : ' '}
              />
            </Tooltip>
            <span className="passwordShow metaText" onClick={() => props.togglePassword()}>
              {formData.password_show ? (
                <img src={imageBasePath + 'eye_hide.svg'} alt="Hide" />
              ) : (
                <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
              )}
            </span>
          </div>
          <div className={styles.agreeBlk}>
            <p className={styles.agreeTitle + ' supportText'}>
              By continuing, you're agreeing to the Salestable
              <ExternalLink
                href={`/termsandcondition`}
                linktext=" terms & conditions  "
                target={'_blank'}
                className={styles.externalLink + ' linkBtn'}
              />
              and
              <ExternalLink
                href={`/privacypolicy`}
                linktext=" Privacy Policy "
                target={'_blank'}
                className={styles.externalLink + ' linkBtn'}
              />
            </p>
          </div>
          <div className={styles.submitBlk}>
            <CustomButton
              children={submitLoader ? 'Please wait...' : 'Join'}
              className={`primaryBtn submitBtn ${submitLoader ? 'disabledBtn' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import styles from '../Payment.module.scss';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import PaymentForm from '../PaymentForm/PaymentForm';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import { Payment } from '../../Payment.utils';

const AddPaymentModal = ({ onSuccess = () => null }) => {
  const [showModal, setShowModal] = useState(false);
  if (Subscription.isStarter() || !FeatureFlag.enabled('EDIT_SUBSCRIPTION')) return null;
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Add payment method</Button>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent className={styles.paymentDialog}>
          <PaymentForm
            processType={Payment.PROCESS_TYPE.ADD_PAYMENT}
            fullPage={false}
            onSuccess={() => {
              setShowModal(false);
              onSuccess();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPaymentModal;

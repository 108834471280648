import React from 'react';
import useAssessment from '../hooks/useAssessment';
import styles from './FeedbackQuestionTItle.module.scss';

const FeedbackQuestionTitle = ({ item, index }) => {
  const { isMultipleChoice } = useAssessment();

  const isCorrect =
    item?.answer?.value == item?.feedback_questions?.find((fq) => fq?.is_correct)?.id;

  return (
    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyItems: 'center' }}>
      <h3 className="heading5">
        <span>{index + 1}. </span> {item.name}
      </h3>
      {isMultipleChoice({ item }) &&
        (isCorrect ? (
          <div className={styles.correct}>
            <svg
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4192 1.66665L4.41925 9.66665L0.752579 5.99999L1.69258 5.05999L4.41925 7.77999L11.4792 0.726654L12.4192 1.66665Z"
                fill="#2DC38D"
              />
            </svg>
            <span>Correct</span>
          </div>
        ) : (
          <div className={styles.incorrect}>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0859 1.27334L9.1459 0.333344L5.41923 4.06001L1.69256 0.333344L0.752563 1.27334L4.47923 5.00001L0.752563 8.72668L1.69256 9.66668L5.41923 5.94001L9.1459 9.66668L10.0859 8.72668L6.35923 5.00001L10.0859 1.27334Z"
                fill="#C32D2D"
              />
            </svg>
            <span>Incorrect</span>
          </div>
        ))}
    </div>
  );
};

export default FeedbackQuestionTitle;

import React from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayHeader from '../Common/RoleplayHeader';

const RoleplayViewDescriptionHeader = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  return <RoleplayHeader roleplayView={roleplayView} />;
};

export default RoleplayViewDescriptionHeader;

import { API } from 'utils/globalApiServices';

export async function getGoalsOrderService() {
  const url = `/tracking/goal-categories/sales-activity/order/`;
  const { data } = await API.get(url);
  return data;
}

export async function reorderGoalsService({ goals = [] }) {
  const url = `/tracking/goal-categories/sales-activity/re-order/`;
  const { data } = await API.post(url, { goals_uuid: goals.join(',') });
  return data;
}

export async function updateGoalsSettingsService({ id, body }) {
  if (!body?.goals?.length > 0) return {};
  const url = `/tracking/category/${id}/goals/`;
  const { data } = await API.post(url, body);
  return data;
}

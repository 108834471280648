import React from 'react';
import { Grid, Card } from '@material-ui/core';
import styles from '../../../assets/styles/subscriptions.module.scss';
import { imageBasePath } from '../../../constants';
import Flag from '../../payment/components/PaymentForm/Other/Flag';
import SubscriptionAlert from './SubscriptionAlert';
import AddPaymentModal from '../../payment/components/PaymentButtons/AddPaymentModal';
import { useGetSubscriptionDetailsQuery } from '../../payment/Payment.queries';
import UpgradeSubscriptionModal from 'features/payment/components/PaymentButtons/UpgradeSubscriptionModal';
import CancelSubscriptionModal from 'features/payment/components/PaymentButtons/CancelSubscription/CancelSubscriptionModal';

const SubscriptionDetailsOld = () => {
  const { data = {}, refetch, isLoading } = useGetSubscriptionDetailsQuery();
  const { payment_methods: payments = [], subscription = {}, product: products = [] } = data;

  return (
    <div>
      {/* Subscription details */}
      <div className={styles.paymentTitleWrapper}>
        <img src={imageBasePath + 'subscription_default2.png'} alt="" />
        <h4 className="heading4">Plans & Billing</h4>
      </div>

      <SubscriptionAlert
        isLoading={isLoading}
        subscription={subscription}
        isFree={products[0]?.id === 1}
      />
      <br />

      <AddPaymentModal onSuccess={refetch} />
      <UpgradeSubscriptionModal />

      {/* Products section */}
      {products?.length > 0 && (
        <div className={styles.paymentTitleWrapper}>
          {/* <img src={imageBasePath + 'subscription_default2.png'} alt="" /> */}
          <h4 className="heading4">Product</h4>
        </div>
      )}
      <Grid container spacing={2}>
        {products?.length > 0 &&
          products?.map(
            ({ name, type, quantity, price }, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Card className={styles.card}>
                  <h3>Salestable {price?.nickname || name || 'Basic'}</h3>
                  <p>
                    Type:<span> {type}</span>
                  </p>
                  <p>
                    Users: <b>{quantity}</b>
                  </p>
                </Card>
              </Grid>
            ),
            []
          )}
      </Grid>

      {/* Payment methods section */}
      {payments?.length > 0 && (
        <div className={styles.paymentTitleWrapper}>
          {/* <img src={imageBasePath + 'subscription_default2.png'} alt="" /> */}
          <h4 className="heading4">Payment method</h4>
        </div>
      )}
      <Grid container spacing={2}>
        {payments?.map(({ billing_details, card, name, email, phone }, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <Card className={styles.card}>
              <h4>Billing data</h4>
              <p>{email}</p>
              <p>{name}</p>
              {phone && <p>+{phone}</p>}
              <h4>Billing address</h4>
              <p style={{ display: 'flex' }}>
                <Flag country={billing_details?.country} />
                <span>
                  {billing_details?.country}, {billing_details?.state}
                </span>
              </p>
              <p>City: {billing_details?.city}</p>
              <p>Postal code: {billing_details?.postal_code}</p>
              <p>Address: {billing_details?.line1}</p>
              {billing_details?.line2 && <p>Address line 2: {billing_details?.line2}</p>}
              <h4>Payment data</h4>
              <p>
                Card: {card?.brand} ending {card?.last4}
              </p>
            </Card>
          </Grid>
        ))}
      </Grid>
      <CancelSubscriptionModal />

    </div>
  );
};

export default SubscriptionDetailsOld;

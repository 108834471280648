import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../../assets/styles/auth.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

const features = [
  {
    featuredImg: 'track_step_1.png',
    title: 'Track recruitment progress in one view',
    description: 'Track the progress of your candidates with the interview process',
  },
  {
    featuredImg: 'onboard_step_2.png',
    title: 'Dedicated Onboarding for your company',
    description: 'Keep your team in sync with a dedicated Onboarding',
  },
  {
    featuredImg: 'train_step_3.png',
    title: 'A Training Library benefits your sales team',
    description: "A Training Library takes your sales team's performance to the next level",
  },
];
// const classes = useStyles();

export default class Review extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: styles.featuresSlider + ' featuresSlider',
      autoplay: true,
      // prevArrow: <img src={imageBasePath+'back_arrow.svg'} alt="Prev" />,
      // nextArrow: <img src={imageBasePath+'back_arrow.svg'} alt="Next" />,
      // centerMode: isMobile ? true : false,
      // centerPadding: '20px',
    };
    return (
      <div className={styles.featuresWrapper}>
        <Link to="/">
          <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="logo" />
        </Link>
        <div className={styles.featuresSliderOuterWrapper}>
          <Slider {...settings}>
            {features.map((feature, key) => {
              return (
                <div key={key} className={styles.slideWrapper}>
                  <div className={styles.imgBlk}>
                    <img
                      className={styles.featuredImg}
                      src={imageBasePath + feature.featuredImg}
                      alt={feature.title}
                    />
                  </div>
                  <div className={styles.contentBlk}>
                    <h2 className="heading5">{feature.title}</h2>
                    <p className="paragraph">{feature.description}</p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <img className={styles.patternImg} src={imageBasePath + 'signup_pattern.png'} alt="Image" />
      </div>
    );
  }
}

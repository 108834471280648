import React from 'react';
import styles from '../chatAssist.module.scss';
import { msgInfo } from '../constants';
const MessagesContainer = ({ messages }) => {
  return messages.map((msg, index) => (
    <div
      key={index}
      className={msg.type === msgInfo?.recipient ? styles.messageIA : styles.messageUser}
    >
      {msg.text}
    </div>
  ));
};

export default MessagesContainer;

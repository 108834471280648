import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
} from '../../utils/globalApiServices';

export function getProvidersApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/recruitment/providers/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getManualJobsApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/recruitment/jobs/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function connectApi(id, data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/provider/${id}/fetch-jobs/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function providerJobUpdateApi(id, data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/provider/${id}/jobs/update/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addJobManually(data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/job/add/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateJobManually(id, data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/job/${id}/update/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteJobManually(uuid) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/recruitment/job/${uuid}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function enableDisableApi(id, enable) {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/provider/${id}/status/`, enable)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function contactSupportApi() {
  return new Promise((resolve, reject) => {
    globalPostService(`/recruitment/contact/support/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadReport() {
  return new Promise((resolve, reject) => {
    globalExportService(`/recruitment/dashboard/download/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

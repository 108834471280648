import React, { useState } from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import {
  InputText,
  InputSelect,
  CustomButton,
  CustomTooltipWhite,
} from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';

const companyCategory = [
  { value: '1', label: 'Product' },
  { value: '2', label: 'Service' },
];

const companySizes = [
  { value: '1', label: '1 to 9' },
  { value: '2', label: '10 to 49' },
  { value: '3', label: '50 to 249' },
  { value: '4', label: '250 or more' },
];

// const companyCategory = [
//   'Product',
//   'Service'
// ]
//
// const companySizes = [
//   '1 to 9',
//   '10 to 49',
//   '50 to 249',
//   '250 or more'
// ];

export default function AboutOrganization(props) {
  const { formData, error, submitLoader, file } = props;

  return (
    <div className={styles.cardWrapper + ' ' + styles.organizationCardWrapper}>
      <div className={styles.innerWrapper}>
        <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
        <h1 className="heading3">Provide us with information about your organization</h1>
        <div className={styles.innerWrapper}>
          <div className={styles.formWrapper}>
            <div className={styles.inputFileGroup + ' inputFileGroup'}>
              <div className={styles.imgBlk + ' imgBlk'}>
                {formData.logo ? (
                  <img className="profileImage" src={formData.logo} alt="" />
                ) : (
                  <img src={imageBasePath + 'upload_company_logo.png'} alt="Upload company Logo" />
                )}
              </div>
              <a className={styles.hiddenUploadBtn}>
                <input
                  id="email"
                  type="file"
                  label="Upload"
                  accept="image/png,image/jpg"
                  title=""
                  onChange={(e) => props.onChange(e)}
                />
              </a>
              <span className="paragraph linkBtn">Upload your company Logo</span>
            </div>
            <div className="inputGroup">
              <InputText
                id="company-name"
                label="Company Name"
                placeholder="Enter your company name"
                value={formData.name ? formData.name : ''}
                onChange={(e) => props.handleChange('name', e.target.value)}
                error={error.name}
                className={error.name ? 'errorGroup' : ' '}
                // onKeyPress={(e) => {
                //   if (e.key === 'Enter') {
                //     props.handleSubmit()
                //   }
                // }}
              />
            </div>
            <div className={`inputGroup selectGroup ${error.category ? 'errorGroup' : ' '}`}>
              <InputSelect
                closeMenuOnSelect={true}
                label={`Company Category`}
                options={companyCategory}
                isSearchable={true}
                placeholder={'Select company category'}
                noOptionsMessage={() => 'No matches found'}
                onChange={(selectedOption) =>
                  props.handleChange('category', selectedOption ? selectedOption : [])
                }
                value={formData.category}
                // isCreatable={true}
                // isClearable
              />
              {error.category ? <h6 className="errorMsg">{error.category}</h6> : null}
            </div>
            <div className="inputGroup">
              <InputText
                id="company-website"
                label={
                  <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <span>Company Website</span>
                    <CustomTooltipWhite
                      placement="top"
                      tooltipIcon="info_icon.svg"
                      title={
                        <>
                          <p>Please enter the website URL in the format of https://example.com</p>
                          <p>(e.g. https://salestable.ai)</p>
                        </>
                      }
                    />
                  </span>
                }
                placeholder="Enter your company website link"
                value={formData.website ? formData.website : ''}
                onChange={(e) => props.handleChange('website', e.target.value)}
                error={error.website}
                className={error.website ? 'errorGroup' : ' '}
              />
            </div>
            <div className={`inputGroup selectGroup ${error.size ? 'errorGroup' : ' '}`}>
              <InputSelect
                closeMenuOnSelect={true}
                label={`Size of the Company`}
                options={companySizes}
                isSearchable={true}
                placeholder={'Select company size'}
                noOptionsMessage={() => 'No matches found'}
                onChange={(selectedOption) =>
                  props.handleChange('size', selectedOption ? selectedOption : [])
                }
                value={formData.size}
                // isCreatable={true}
                // isClearable
              />
              {error.size ? <h6 className="errorMsg">{error.size}</h6> : null}
            </div>
            <div className="inputGroup">
              <InputText
                id="your-role"
                label="Your Role"
                placeholder="Enter your role in the company"
                value={formData.designation ? formData.designation : ''}
                onChange={(e) => props.handleChange('designation', e.target.value)}
                error={error.designation}
                className={error.designation ? 'errorGroup' : ' '}
              />
            </div>
            <div className={styles.submitBlk}>
              <CustomButton
                children={submitLoader ? 'Continuing...' : 'Continue'}
                className={`primaryBtn submitBtn ${submitLoader ? 'disabledBtn' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const STATUS_ID = {
  INITIATED: 1,
  IN_PROGRESS: 2,
  SUBMITTED: 3,
  RETAKEN: 4,
  QUALIFIED: 5,
  FAILED: 6,
  ONE_DAY_LEFT: 7,
  LAST_DAY: 8,
  OUT_OF_TIME: 9,
};

export const TO_REVIEW_OPTIONS = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Submitted',
    value: STATUS_ID.SUBMITTED,
  },
  {
    label: 'Exceeded',
    value: STATUS_ID.OUT_OF_TIME,
  },
];

export const RATING_CRITERIA_POINTS = [
  { text: 'Uncovered Motivation' },
  { text: 'Provided Clear Options' },
  { text: 'Proof Points' },
  { text: 'Mutual Gain' },
  { text: 'Next Steps' },
];

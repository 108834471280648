import React, { Component } from 'react';
import VideoRecorder from './VideoRecorder';

class VideoRecorderWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const videoError = error.toString()?.includes('screen capturing');
    if (videoError) {
      console.log('This browser does not support screen capture');
      this.setState({
        hasError: true,
      });
    }
  }

  render() {
    return this.state.hasError ? null : <VideoRecorder />;
  }
}
export default VideoRecorderWrapper;

import { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  InputText,
  TextArea,
  CustomRadioButton,
  CustomTooltipWhite,
  InputSelect,
} from '../../../shared_ui_components';
import { imageBasePath, contentTypes } from '../../../constants';
import styles from '../../../assets/styles/onboarding.module.scss';
import { Upload, EmbedVideo, InsertLink } from '.';
import { updateContentApi, addCustomStepApi } from '../apiServices';
import { fetchContentType, toastFlashMessage } from '../../../utils';
import { addNewContentApi, editContentApi } from '../../training/apiServices';
import { ConfirmationDialog } from '../../../shared_elements';
import ScheduleTraining from 'shared_ui_components/ScheduleTraining/ScheduleTraining';
import { FeatureFlag } from 'controllers/_exports';
import Tracktime from 'shared_ui_components/TrackTime/Tracktime';

export default function AddDocument(props) {
  const { open, type, id, isCustom, page = 'onboarding', moduleTitle = '' } = props;
  const [formData, setFormData] = useState({
    is_required: true,
    is_retake: false,
    frequency: -1,
    interval: 0,
    number_of_retakes: 0,
    suggest_duration: 300,
  });
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [changeIndex, setChangeIndex] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [key]: '',
      content:
        key == 'input_type' || key == 'content' ? '' : prevState.content ? prevState.content : '',
    }));
  };

  const handleUploadChange = (val) => {
    setFormData({
      ...formData,
      content: val[0].data.path,
    });
    setError({
      ...error,
      content: '',
    });
    if (isCustom || page == 'training') {
      fetchContentType(val[0].data.path, handleChange);
    }
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  useEffect(() => {
    if (open) {
      setError({});
      setLoader(false);
      setFormData(props.formData);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let validateNewInput = {
      name: formData.name ? '' : 'Please enter Title',
      description: formData.description ? '' : 'Please enter Description',
      content: validateInputContent(formData.content, formData.input_type),
    };
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === '';
      })
    ) {
      let updatedTags = formData.tags.map((tag) => tag.label);
      if (page == 'onboarding') {
        if ((type == 'addContent' || type == 'editContent') && id) {
          updateContentApi(id, { ...formData, tags: updatedTags }).then((response) => {
            setLoader(false);
            if (response.statusCode === 200) {
              toastFlashMessage(response.message, 'success');
              props.handleUpdateList(response.data);
              props.handleClose();
            } else {
              toastFlashMessage(response.message, 'error');
            }
          });
        } else if (type == 'addStep' && id) {
          // updatedFormData.content_type = 6
          addCustomStepApi(id, { ...formData, tags: updatedTags }).then((response) => {
            setLoader(false);
            if (response.statusCode === 200) {
              toastFlashMessage(response.message, 'success');
              props.handleUpdateList(response.data);
              setTimeout(() => {
                props.handleClose();
              }, 500);
            } else {
              toastFlashMessage(response.message, 'error');
            }
          });
        }
      } else if (page == 'training') {
        if (type == 'addContent') {
          addNewContentApi(id, { ...formData, tags: updatedTags }).then((response) => {
            setLoader(false);
            if (response.statusCode == 200) {
              setLoader(false);
              props.handleUpdateList(response.data);
              toastFlashMessage(response.message, 'success');
              props.handleClose();
            } else {
              toastFlashMessage(response.message, 'error');
            }
          });
        } else if (type == 'editContent') {
          editContentApi(id, { ...formData, tags: updatedTags }).then((response) => {
            setLoader(false);
            if (response.statusCode == 200) {
              setLoader(false);
              props.handleUpdateList(response.data);
              toastFlashMessage(response.message, 'success');
              props.handleClose();
            } else {
              toastFlashMessage(response.message, 'error');
            }
          });
        }
      }
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };
  const validateInputContent = (content, inputType) => {
    //perform the content validation based on the inputType and formData.content_type
    if (inputType == 2 && content) {
      if (
        (content.toLowerCase().includes(`<iframe`) || content.toLowerCase().includes(`<iframe>`)) &&
        content.toLowerCase().includes(`</iframe>`)
      ) {
        return '';
      } else {
        return 'Your embedded code is invalid!';
      }
    } else if (inputType == 3 && content) {
      let pattern = new RegExp(
        '((http|https)://)(www.)?' +
          '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
          '{2,8}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
        'i'
      );
      if (pattern.test(content)) {
        return '';
      } else {
        return 'Sorry, but this URL is invalid';
      }
    } else {
      return '';
    }
  };
  const isSubmitDisabled = () => {
    if (
      formData.name &&
      formData.name.trim() &&
      formData.description.trim() &&
      formData.content &&
      formData.content.trim() &&
      formData.input_type &&
      !loader &&
      (error && Object.keys(error) ? Object.keys(error).every((key) => error[key] == '') : true)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateSwitchTab = (index) => {
    if (formData.content && formData.input_type !== index) {
      setChangeIndex(index);
      setOpenConfirm(true);
    } else {
      handleChange('input_type', index);
    }
  };

  const handleChangeTab = () => {
    setFormData((prevState) => ({
      ...prevState,
      input_type: changeIndex,
      content: '',
    }));
    setOpenConfirm(false);
    setChangeIndex('');
  };

  return (
    <div>
      <ConfirmationDialog
        open={openConfirm}
        title={
          <>
            Are you sure you want to update
            <br /> the content?
          </>
        }
        description="Please note that only latest updated content will be available for all users"
        acceptText="Yes, Proceed"
        rejectText="Cancel"
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          handleChangeTab();
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="add-video-title"
        aria-describedby="add-video-description"
        className={`dialog addVideoDialog ${styles.addVideoDialog}`}
      >
        {page == 'onboarding' ? (
          <DialogTitle id="add-video-title">
            {`${
              formData.name && (type == 'addContent' || type == 'editContent')
                ? `${formData.name}: ${type == 'editContent' ? `Edit` : `Add`} ${
                    contentTypes.find((item) => item.id == formData.content_type)?.label
                  }`
                : `Add custom content`
            }`}
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className="modalCloseIcon"
              onClick={handleClose}
            />
          </DialogTitle>
        ) : (
          <DialogTitle id="add-video-title" className={styles.customTitle}>
            <h3 className="heading4">
              <span>{moduleTitle.title}</span>
              <CustomTooltipWhite
                placement="right"
                tooltipIcon="info_icon.svg"
                title={
                  <div className="customTooltipContent">
                    <p className="heading6">What content to add ? </p>
                    <List className="listInline">
                      <ListItem className="supportText">Include what sales process is</ListItem>
                      <ListItem className="supportText">What is followed in your company</ListItem>
                      <ListItem className="supportText">Best practices in sales</ListItem>
                    </List>
                  </div>
                }
              />
            </h3>

            <p className="paragraph">{moduleTitle.description}</p>
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className="modalCloseIcon"
              onClick={handleClose}
            />
          </DialogTitle>
        )}
        <DialogContent>
          <div className={styles.dialogBody}>
            <Grid container>
              <Grid item xs={6} className="flexNotCentered">
                <div className={styles.leftContent}>
                  <List className="listInline tabList">
                    <ListItem
                      className={`listInlineItem heading6 ${
                        formData.input_type === 1 ? 'active' : ''
                      } ${formData.content && formData.input_type != 1 ? '' : ''}`}
                      onClick={() => {
                        handleUpdateSwitchTab(1);
                      }}
                    >
                      Upload
                    </ListItem>
                    <ListItem
                      className={`listInlineItem heading6 ${
                        formData.input_type === 2 ? 'active' : ''
                      } ${formData.content && formData.input_type != 2 ? '' : ''}`}
                      onClick={() => {
                        handleUpdateSwitchTab(2);
                      }}
                    >
                      Embed
                    </ListItem>
                    <ListItem
                      className={`listInlineItem heading6 ${
                        formData.input_type === 3 ? 'active' : ''
                      } ${formData.content && formData.input_type != 3 ? '' : ''}`}
                      onClick={() => {
                        handleUpdateSwitchTab(3);
                      }}
                    >
                      Insert URLs
                    </ListItem>
                  </List>
                  {formData.input_type === 1 ? (
                    <Upload
                      handleUploadChange={handleUploadChange}
                      handleChange={handleChange}
                      handleClose={props.handleClose}
                      formData={formData}
                      isCustom={isCustom}
                      page={page}
                      setFormData={setFormData}
                    />
                  ) : formData.input_type === 2 ? (
                    <EmbedVideo
                      handleChange={handleChange}
                      formData={formData}
                      isCustom={isCustom}
                      page={page}
                      error={error}
                    />
                  ) : formData.input_type === 3 ? (
                    <InsertLink
                      handleChange={handleChange}
                      formData={formData}
                      isCustom={isCustom}
                      page={page}
                      error={error}
                    />
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={6} className="flexNotCentered">
                <div className={styles.rightContent}>
                  <h2 className="heading5">Content details</h2>
                  <form>
                    <InputText
                      label={`Title`}
                      value={formData.name}
                      className={page == 'onboarding' ? (isCustom ? '' : `disabledInputGroup`) : ''}
                      onChange={(e) => handleChange('name', e.target.value)}
                      onBlur={(e) => handleChange('name', e.target.value.trim())}
                    />
                    <TextArea
                      label={`Description`}
                      rows={3}
                      value={formData.description}
                      onChange={(e) => handleChange('description', e.target.value)}
                      onBlur={(e) => handleChange('description', e.target.value.trim())}
                    />
                    <InputSelect
                      isMulti
                      label={`Tags`}
                      isCreatable={true}
                      placeholder="Add tag"
                      value={formData.tags}
                      onChange={(values) => handleChange('tags', values)}
                    />
                    <h3 className={`heading6 ${styles.userPreference}`}>User preference</h3>
                    <p className="supportText">
                      Define the importance of this file to be shown to your users
                    </p>
                    <List className={styles.radioBtnList + ' listInline'}>
                      <ListItem className="listInlineItem">
                        <CustomRadioButton
                          label="Mandatory"
                          checked={formData.is_required}
                          onChange={(e) => handleChange('is_required', true)}
                        />
                      </ListItem>
                      <ListItem className="listInlineItem">
                        <CustomRadioButton
                          label="Optional"
                          checked={!formData.is_required}
                          onChange={(e) => handleChange('is_required', false)}
                        />
                      </ListItem>
                    </List>
                    {FeatureFlag.enabled('TRACK_TIME') && (
                      <Tracktime formData={formData} setFormData={setFormData}/>
                    )}
                    {FeatureFlag.enabled('SCHEDULE_TRAINING') && (
                      <ScheduleTraining formData={formData} setFormData={setFormData} />
                    )}
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="tertiaryBtn">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className={`primaryBtn ${isSubmitDisabled() ? `disabledBtn` : ''}`}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/tracking.module.scss';
import { imageBasePath } from '../../../constants';
import { TooltipCustom } from '../../../utils';

export default function CheckinDataBox(props) {
  const { checkinData, date, day } = props;
  return (
    <TooltipCustom
      placement="bottom"
      disableFocusListener
      arrow
      title={
        <div className="supportText tooltipWrapper" style={{ color: `#fff` }}>
          {`${date}, ${day}`}
          <Grid container>
            <Grid item md={9}>
              <p className="supportText" style={{ color: `#fff` }}>
                Total check-ins
              </p>
            </Grid>
            <Grid item md={3} className="textRight">
              <p className="supportText" style={{ color: `#fff` }}>
                {' '}
                {checkinData.check_in}
              </p>
            </Grid>
          </Grid>
        </div>
      }
    >
      <div
        className={`${styles.databoxWrapper} ${styles.checkinDataBoxWrapper}`}
        style={{
          backgroundColor: checkinData.check_in
            ? `rgba(45, 195, 141, 0.4)`
            : `rgba(1, 22, 39, 0.1)`,
        }}
        onClick={() => props.handleViewCheckinDetails(checkinData.date)}
      >
        {<img src={`${imageBasePath}active_checkin_icon.svg`} className={styles.activeCheckin} />}
        {<img src={`${imageBasePath}active_checkin_nostate.svg`} className={styles.active} />}
      </div>
    </TooltipCustom>
  );
}

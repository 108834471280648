import { Grid, Avatar } from '@material-ui/core';
import { TooltipCustom, changeTimezone } from '../../../utils';
import styles from '../../../assets/styles/tracking.module.scss';
import { format } from 'date-fns';
import SilentModeIcon from 'features/users/components/Settings/SilentModeIcon';

export default function SalesTeamMembers(props) {
  const { users = [], activeUser = {} } = props;

  return (
    <div className={styles.bottomBlk}>
      <div className={styles.membersList}>
        {users?.length ? (
          users.map((user, index) => (
            <div
              key={index}
              onClick={() => props.handleChangeUser(user)}
              className={
                activeUser?.user?.uuid == user?.user?.uuid
                  ? styles.active + ' ' + styles.memberItem
                  : styles.memberItem
              }
            >
              <Grid container direction="row" alignItems="center" className={styles.usersRow}>
                <Grid item lg={12} md={12} sm={12} xs={12} className={styles.leftBlk}>
                  <div
                    className={styles.profileWrapper}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className={styles.imgBlk}>
                      {user?.user?.profile_pic ? (
                        <img
                          className={styles.profilePic}
                          src={user?.user?.profile_pic}
                          alt="Profile pic"
                        />
                      ) : (
                        <Avatar className={styles.profilePic + ' avatar ' + styles.avatar}>
                          {user?.user?.first_name
                            ? user?.user?.first_name[0]
                            : user?.user?.email
                            ? user?.user?.email[0]
                            : 'S'}
                        </Avatar>
                      )}
                    </div>
                    <div className={styles.contentBlk}>
                      {user?.user?.first_name || user?.user?.last_name ? (
                        <TooltipCustom
                          placement="top"
                          arrow
                          title={
                            <div className="supportText" style={{ color: `#fff` }}>
                              {user?.user?.email}
                            </div>
                          }
                        >
                          <h5 className="supportTextSemibold">
                            {`${user?.user?.first_name} ${
                              user?.user?.last_name ? user?.user?.last_name : ''
                            }`}
                          </h5>
                        </TooltipCustom>
                      ) : user?.user?.email.length < 29 ? (
                        <h5 className="supportTextSemibold">{user?.user?.email}</h5>
                      ) : (
                        <TooltipCustom
                          placement="top"
                          arrow
                          title={
                            <div className="supportText" style={{ color: `#fff` }}>
                              {user?.user?.email}
                            </div>
                          }
                        >
                          <h5 className="supportTextSemibold">
                            {user?.user?.email.substring(0, 25)}...
                          </h5>
                        </TooltipCustom>
                      )}
                      <h6 className="supportText">
                        {`Last check-in: ${
                          user?.last_checkin
                            ? format(changeTimezone(new Date(user.last_checkin)), 'do MMM')
                            : `--`
                        }`}
                        <SilentModeIcon user={user} />
                      </h6>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <p className="paragraph" style={{ paddingLeft: 24 }}>
            Sorry, but these users aren't available
          </p>
        )}
      </div>
    </div>
  );
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'utils/globalApiServices';

const SALES_REP_KEY = 'sales_rep_pov';

export const useGetSalesRepViewQuery = ({ requestValue = false, enabled = false }) => {
  return useQuery({
    queryKey: [SALES_REP_KEY],
    queryFn: async () => {
      const url = '/get/sales-rep-view/';
      const { data } = await API.get(url);
      return data?.sales_rep_view || false;
    },
    enabled: enabled && requestValue,
  });
};

export const useChangeSalesRepViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ status }) => {
      const url = '/change/sales-rep-view/';
      const { data } = await API.post(url, { status });
      return data?.sales_rep_view;
    },
    onSuccess: (response) => {
      queryClient.setQueryData([SALES_REP_KEY], () => response);
    },
  });
};

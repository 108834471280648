import React, { useEffect } from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton, CustomLink, ExternalLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function VerificationRejected(props) {
  const { submitLoader } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <>
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={styles.cardWrapper + ' ' + styles.organizationExistWrapper}>
                  <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
                  <div className={styles.rejectedImgBlk}>
                    {userInfo?.currentOrg?.logo ? (
                      <img
                        style={{ marginBottom: '8px', objectFit: 'contain', borderRadius: '50%' }}
                        className={styles.organizationLogo}
                        src={userInfo?.currentOrg?.logo}
                        alt="salestable"
                      />
                    ) : userInfo?.selectedOrg?.logo ? (
                      <img
                        style={{ marginBottom: '8px', objectFit: 'contain', borderRadius: '50%' }}
                        className={styles.organizationLogo}
                        src={userInfo?.selectedOrg?.logo}
                        alt="salestable"
                      />
                    ) : (
                      <img
                        src={imageBasePath + 'upload_company_logo.png'}
                        alt="Upload company Logo"
                      />
                    )}
                    <img
                      src={imageBasePath + 'error_white.svg'}
                      className={styles.closeIcon}
                      alt="image"
                    />
                  </div>
                  <h1 className="heading3" style={{ margin: '24px 0 16px' }}>
                    Your organization verification failed
                  </h1>
                  {userInfo?.currentOrg?.rejection_reason ? (
                    <p className={styles.supportEmail + ' paragraph'}>
                      Unfortunately, we're unable to approve your request, due to "
                      {userInfo?.currentOrg?.rejection_reason}". <br /> Please reach out to{' '}
                      <ExternalLink
                        href="mailto:info@salestable.ai"
                        target="_blank"
                        linktext="info@salestable.ai"
                        className="linkBtn"
                      />{' '}
                      for more details.
                    </p>
                  ) : userInfo?.selectedOrg?.rejection_reason ? (
                    <p className={styles.supportEmail + ' paragraph'}>
                      Unfortunately, we're unable to approve your request, due to "
                      {userInfo?.selectedOrg?.rejection_reason}". <br /> Please reach out to{' '}
                      <ExternalLink
                        href="mailto:info@salestable.ai"
                        target="_blank"
                        linktext="info@salestable.ai"
                        className="linkBtn"
                      />{' '}
                      for more details.
                    </p>
                  ) : (
                    <p className={styles.supportEmail + ' paragraph'}>
                      Unfortunately, we're unable to approve your request,
                      <br /> Please reach out to{' '}
                      <ExternalLink
                        href="mailto:info@salestable.ai"
                        target="_blank"
                        linktext="info@salestable.ai"
                        className="linkBtn"
                      />{' '}
                      for more details.
                    </p>
                  )}
                  <CustomLink
                    to="/signup"
                    linktext={submitLoader ? 'Back to Home' : 'Back to Home'}
                    className={`primaryBtn submitBtn ${submitLoader ? ' disabledBtn' : ''}`}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </>
  );
}

import React from 'react'
import styles from './DeleteCard.module.scss';
import { imageBasePath } from 'constants';
import { CustomButton } from 'shared_ui_components';

const DeleteCard = ({setShowModal = () => null, handleDelete = () => null, disabled}) => {
  return (
    <div style={{ minHeight: '12rem' }}>
    <div className={styles.deleteTitle}>
      <img
        src={imageBasePath + 'close_gray.svg'}
        alt="close icon"
        className={styles.deleteCloseIcon}
        onClick={() => setShowModal(false)}
      />
    </div>
    <div className={styles.deleteBody}>
      <div className={styles.bodyText}>
        <strong>Are you sure you want to delete this payment method?</strong> <br />
        <br />
        This action cannot be undone, your data will be lost. Like tears in the rain.
      </div>
      <div className={styles.bodyButtons}>
        <button disabled={disabled} onClick={handleDelete} className={styles.delete}>
          Yes, Delete
        </button>
        <CustomButton disabled={disabled} onClick={() => setShowModal(false)} textTransform="none">
          No, Cancel
        </CustomButton>
      </div>
    </div>
  </div>
  )
}

export default DeleteCard

import { create, createStore } from 'zustand';

export const subscriptionStore = createStore((set) => ({
  isModalOpen: false,
  message: '',

  openModal: () => set(() => ({ isModalOpen: true })),
  closeModal: () => set(() => ({ isModalOpen: false, message: '' })),
}));

export const useSubscriptionStore = create(subscriptionStore);

import React from 'react';
import styles from '../../../assets/styles/home.module.scss';
import { goalFrequencies } from '../../../constants';

const TrackingFrequencyBadge = ({ frequency, margin = true }) => {
  const frequencyLabel = goalFrequencies?.find((item) => item.id === frequency)?.label;

  return !frequencyLabel ? null : (
    <span
      className={styles.infoBoxHighlight}
      style={{
        background: 'rgba(132, 153, 169, 0.29)',
        padding: '0px 6px 1px',
        borderRadius: '4px',
        fontSize: '0.8rem',
        marginLeft: margin ? '1rem' : 0,
      }}
    >
      {frequencyLabel}
    </span>
  );
};

export default TrackingFrequencyBadge;

import React from 'react';
import { TextField } from '@material-ui/core';

export default function InputText(props) {
  return (
    <div className="inputGroup">
      <TextField
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        accept={props.accept}
        {...props}
        error={props.error ? true : false}
      />
      {props.error ? <h6 className="errorMsg">{props.error}</h6> : null}
    </div>
  );
}

import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from 'assets/styles/onboarding.module.scss';

export default function Shimmer(props) {
  return (
    <>
      {props.type === 'StreamOverview' && (
        <>
          <section className={styles.onboardingStepsection}>
            <Grid container direction="row" spacing={2} className={styles.stepsRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={styles.introductionCardWrapper}>
                  <div className={styles.contentWrapper}>
                    <div
                      className="typeBlk"
                      style={{
                        width: '100%',
                        paddingLeft: '0px',
                        marginRight: '0px',
                        marginBottom: '25px',
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Skeleton
                        variant="circle"
                        width={50}
                        height={50}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                      <Skeleton
                        variant="text"
                        width="30%"
                        style={{ marginRight: '35%', marginLeft: '10px' }}
                      />
                      <Skeleton variant="text" width="20%" style={{}} />
                    </div>

                    <div style={{ marginBottom: '12px', marginLeft: '0px', marginTop: '-20px' }}>
                      {[0, 1].map((item, key) => (
                        <div key={key} className="paragraph" style={{ marginBottom: '12px' }}>
                          <Skeleton variant="text" width="100%" height={50} style={{}} />
                        </div>
                      ))}
                      <h4
                        className="heading4"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Skeleton variant="text" width="60%" height={50} style={{ marginRight: '5px'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px', marginLeft: '15%'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                      </h4>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={styles.introductionCardWrapper}>
                  <div className={styles.contentWrapper}>
                    <div
                      className="typeBlk"
                      style={{
                        width: '100%',
                        paddingLeft: '0px',
                        marginRight: '0px',
                        marginBottom: '25px',
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Skeleton
                        variant="circle"
                        width={50}
                        height={50}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                      <Skeleton
                        variant="text"
                        width="30%"
                        style={{ marginRight: '35%', marginLeft: '10px' }}
                      />
                      <Skeleton variant="text" width="20%" style={{}} />
                    </div>

                    <div style={{ marginBottom: '12px', marginLeft: '0px', marginTop: '-20px' }}>
                      {[0, 1].map((item, key) => (
                        <div key={key} className="paragraph" style={{ marginBottom: '12px' }}>
                          <Skeleton variant="text" width="100%" height={50} style={{}} />
                        </div>
                      ))}
                      <h4
                        className="heading4"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Skeleton variant="text" width="60%" height={50} style={{ marginRight: '5px'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px', marginLeft: '15%'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                      </h4>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </>
      )}
      {props.type === 'LoadingNewPost' && (
        <>
          <section className={styles.onboardingStepsection}>
            <Grid container direction="row" spacing={0} className={styles.stepsRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={styles.introductionCardWrapper}>
                  <div className={styles.contentWrapper}>
                    <div
                      className="typeBlk"
                      style={{
                        width: '100%',
                        paddingLeft: '0px',
                        marginRight: '0px',
                        marginBottom: '25px',
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Skeleton
                        variant="circle"
                        width={50}
                        height={50}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                      <Skeleton
                        variant="text"
                        width="30%"
                        style={{ marginRight: '35%', marginLeft: '10px' }}
                      />
                      <Skeleton variant="text" width="20%" style={{}} />
                    </div>

                    <div style={{ marginBottom: '12px', marginLeft: '0px', marginTop: '-20px' }}>
                      {[0, 1].map((item, key) => (
                        <div key={key} className="paragraph" style={{ marginBottom: '12px' }}>
                          <Skeleton variant="text" width="100%" height={50} style={{}} />
                        </div>
                      ))}
                      <h4
                        className="heading4"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Skeleton variant="text" width="60%" height={50} style={{ marginRight: '5px'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px', marginLeft: '15%'}} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                        <Skeleton variant="text" width="5%" style={{ marginRight: '5px' }} />
                      </h4>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </>
      )}
    </>
  );
}

import { useEffect } from 'react';
import { setDragElement } from '../DragContainer/Drag.logic';

const WebcamLogic = () => {
  useEffect(() => {
    setDragElement({
      element: document.getElementById('webcam'),
      elementHeight: document.getElementById('webcam')?.offsetHeight || 0,
      elementWidth: document.getElementById('webcam')?.offsetWidth || 0,
      isWebcam: true,
    });
  }, []);

  return {};
};

export default WebcamLogic;

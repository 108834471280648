export const roleConfig = {
  1: {
    canInviteUser: true,
    recruitment: true,
    settings: true,
  },
  2: {
    canInviteUser: true,
    recruitment: true,
    settings: true,
  },
  3: {
    canInviteUser: true,
    recruitment: false,
    settings: true,
  },
  4: {
    canInviteUser: false,
    recruitment: false,
    settings: false,
  },
};

import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent, Avatar } from '@material-ui/core';
import { CustomButton, TextArea, ExternalLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import { adminAssessmentFeedbackSubmitApi } from '../apiServices';
import { toastFlashMessage, getContentTypeImgURL } from '../../../utils';
import Shimmer from './Shimmer';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import AssessmentTextboxOption from './AssessmentTextboxOption';
import VideoPlayer from './VideoPlayer';
import { ReadOnlyWrapper } from 'controllers/_exports';
import { isReadOnly } from 'controllers/Subscriptions/ReadOnlyWrapper/ReadOnlyWrapper';
import MultipleChoiceFeedback from '../assessment/common/MultipleChoiceFeedback';
import FeedbackQuestionTitle from '../assessment/common/FeedbackQuestionTitle';

const StyledRating = withStyles({
  iconHover: {
    height: '17px',
    width: '50px',
    transform: 'none',
  },
  iconFocus: {
    height: '17px',
    width: '50px',
    transform: 'none',
  },
})(Rating);

export default function FeedbackAssessmentDialog(props) {
  const { open, userAssessment = {}, feedbackLoader, assessmentStatus, userType } = props;
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState({});
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    if (open && userAssessment?.assessment) {
      setQuestions([...userAssessment?.assessment]);
      setIsViewOnly(assessmentStatus === 2 || userType === 'salesrep' ? true : false);
      setLoader(false);
    }
  }, [open, userAssessment, assessmentStatus]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleMultipleChoiceRemarks = ({ item, value }) => {
    const questionId = item?.id;
    if (isReadOnly(questions?.find((q) => q.id === questionId))) return;
    let updatedQuestion = Object.assign(
      {},
      questions?.find((q) => q.id === questionId)
    );
    updatedQuestion.remarks = value;
    setQuestions(questions.map((qst) => (qst.id === questionId ? updatedQuestion : qst)));
  };

  const handleChange = (key, value, questionId, feebackQuestionId) => {
    if (isReadOnly(questions?.find((q) => q.id === questionId))) return;
    // const updatedAssessment = {...question, answer: {...question.value, loader : true}}
    let updatedAssessment = Object.assign(
      {},
      questions?.find((q) => q.id === questionId)
    );
    let updatedFeebackQuestion = {
      ...updatedAssessment?.feedback_questions.find((item) => item.id === feebackQuestionId),
    };
    updatedFeebackQuestion['answer'] = {
      ...updatedFeebackQuestion?.answer,
      [key]: value,
    };
    updatedFeebackQuestion['error'] = {
      ...updatedFeebackQuestion['error'],
      [key]: '',
    };
    updatedAssessment.feedback_questions = updatedAssessment.feedback_questions.map((q) =>
      q.id === feebackQuestionId ? updatedFeebackQuestion : q
    );
    setQuestions(questions.map((qst) => (qst.id === questionId ? updatedAssessment : qst)));
  };

  const handleSubmit = () => {
    setLoader(true);
    let updatedQuestions = questions.map((question) => {
      const updated = {
        ...question,
        feedback_questions: question.feedback_questions.map((item) => {
          if (question?.answer_type === 9) return { ...item, error: '' };
          const validateNewInput = {
            rating: isReadOnly(question)
              ? ''
              : item?.answer?.rating
              ? ''
              : 'Please select the rating',
          };
          return {
            ...item,
            error: validateNewInput,
          };
        }),
      };
      if (question?.answer_type === 9) {
        const answer = question?.answer?.value;
        const correct = question?.feedback_questions?.find((fq) => fq?.is_correct)?.id;
        const rating = answer == correct ? 5 : 1;
        return { ...updated, rating };
      }
      return updated;
    });
    const isError = updatedQuestions?.some((question) =>
      question?.feedback_questions?.some((item) =>
        Object.keys(item?.error)?.some((k) => item?.error[k] !== '')
      )
    );
    setQuestions(updatedQuestions);
    if (!isError) {
      updatedQuestions.forEach((question) => {
        question.feedback_questions.forEach((item) => delete item['error']);
      });
      adminAssessmentFeedbackSubmitApi(userAssessment.uuid, {
        questions: updatedQuestions,
      }).then((response) => {
        setLoader(false);
        if (response.statusCode === 200) {
          handleClose();
          props.updateUserAssessmentStatus(userAssessment.uuid, response.data?.assessment_status);
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
    setLoader(false);
  };

  const averageRating = useMemo(() => {
    if (!questions?.length > 0) return 0;
    if (questions?.filter((q) => q?.answer?.value)?.length) {
      let allRatings = [];
      questions
        ?.filter((q) => q?.answer?.value)
        .forEach((q) => {
          if (q?.rating) {
            allRatings.push(q?.rating);
          } else {
            q?.feedback_questions?.forEach((fq) => {
              allRatings.push(fq?.answer?.rating);
            });
          }
        });

      let _sumRating = allRatings.reduce((a, b) => a + b, 0);
      return Number((_sumRating / allRatings?.length).toFixed(1));
    } else {
      return 0;
    }
  }, [questions]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.assesmenteditDialog}`}
      >
        <DialogTitle id="add-video-title" className={styles.dialogTitle}>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={handleClose}
          />
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item>
              <span className={styles.title + ' heading4 flexCentered'}>
                <img src={imageBasePath + 'setup_training.svg'} alt="image" />
                <span>Training Assessment</span>
              </span>
            </Grid>
            {userType === 'salesrep' && (
              <Grid item>
                <div className={styles.assessmentCircularProgress}>
                  <div className={styles.circularProgressBar}>
                    <CircularProgressbar
                      strokeWidth={12}
                      value={averageRating}
                      maxValue={5}
                      styles={buildStyles({
                        textColor: ' #778DA9',
                        pathColor: '#0494FD',
                        trailColor: '#D8E1E9',
                        textSize: '16px',
                        strokeLinecap: 'butt',
                      })}
                    />
                  </div>
                  <p className="supportText"> Avg Rating: {averageRating}/5</p>
                </div>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        {userType !== 'salesrep' ? (
          <div className={styles.infoOuterWrapper}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.contentRow}
            >
              <Grid item lg={6} md={8} sm={8} xs={8}>
                <div className={styles.userInfoWrapper + ' textCenter flexCentered'}>
                  <div className={styles.userImgBlk}>
                    {userAssessment && userAssessment.profile_pic ? (
                      <img
                        className="profilePic"
                        src={userAssessment.profile_pic}
                        alt="Profile pic"
                      />
                    ) : (
                      <Avatar className={`avatar ${styles.userImg}`}>
                        {' '}
                        {userAssessment.first_name
                          ? userAssessment.first_name.substring(0, 1)
                          : userAssessment.email
                          ? userAssessment.email.substring(0, 1)
                          : ''}
                      </Avatar>
                    )}
                  </div>
                  <div className={styles.contentBlk}>
                    {userAssessment.first_name || userAssessment.last_name ? (
                      <h3 className="heading4">{`${
                        userAssessment.first_name
                          ? userAssessment.first_name
                          : userAssessment.last_name
                      } ${
                        userAssessment.first_name && userAssessment.last_name
                          ? userAssessment.last_name
                          : ''
                      }`}</h3>
                    ) : (
                      ''
                    )}
                    <p className={styles.designation + ' supportTextSemibold'}>
                      <img src={`${imageBasePath}designation_icon.svg`} alt="" />
                      <span>{userAssessment.designation ? userAssessment.designation : '--'} </span>
                    </p>
                    <p className="supportTextSemibold">
                      <img src={`${imageBasePath}email_icon.svg`} alt="" />
                      <span className={styles.email}>{userAssessment.email}</span>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <DialogContent>
          <div
            className={`${
              isViewOnly ? styles.viewOnlyMode + ' ' + styles.dialogBody : styles.dialogBody
            }`}
          >
            <div className={styles.formWrapper}>
              {feedbackLoader ? (
                <Shimmer type="FeedbackAssessmentDialog" />
              ) : questions && questions?.length ? (
                <>
                  {questions?.length > 0 &&
                    questions
                      ?.filter((q) => q?.answer?.value)
                      .map((item, index) => (
                        <div key={index} className={styles.formItem}>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={4}
                            className={styles.contentRow}
                          >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <ReadOnlyWrapper data={item}>
                                <div className={styles.assesmentContent}>
                                  <FeedbackQuestionTitle item={item} index={index} />
                                  <p className={styles.description + ' supportText'}>
                                    {item.description}
                                  </p>
                                  {item.answer?.value && (
                                    <div className={styles.viewBlk}>
                                      {item.answer_type === 9 ? (
                                        <MultipleChoiceFeedback question={item} />
                                      ) : item.answer_type === 6 ? (
                                        <AssessmentTextboxOption
                                          allowEdit={false}
                                          initValue={item.answer?.value}
                                        />
                                      ) : item.answer_type == 2 ? (
                                        <audio
                                          controls
                                          // autoplay={autoplay}
                                          className="audio-element"
                                          controlsList="nodownload"
                                          // ref={audioEl}
                                          key={item.answer.value}
                                        >
                                          <source src={item.answer.value} type="audio/ogg" />
                                          <source src={item.answer.value} type="audio/mpeg" />
                                          Your browser does not support the audio element.
                                        </audio>
                                      ) : item.answer_type == 1 || item.answer_type == 7 ? (
                                        <VideoPlayer question={item} />
                                      ) : (
                                        getContentTypeImgURL(item.answer.value)
                                      )}
                                      {[1, 2, 6, 7, 9].includes(item.answer_type) ? (
                                        ''
                                      ) : (
                                        <div className={styles.viewBtn}>
                                          <ExternalLink
                                            href={item.answer.value}
                                            target="_blank"
                                            // linktext="View"
                                            linktext={`${
                                              !item.answer.value.includes('digitaloceanspaces') ||
                                              item.answer.value.includes('.pdf') ||
                                              item.answer.value.includes('.txt') ||
                                              item.answer.value.includes('.jpg') ||
                                              item.answer.value.includes('.jpeg') ||
                                              item.answer.value.includes('.png') ||
                                              item.answer.value.includes('.svg') ||
                                              item.answer.value.includes('.rtf')
                                                ? `View`
                                                : `Download`
                                            }`}
                                            className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <Grid
                                    container
                                    direction="row"
                                    spacing={3}
                                    className={styles.titleRow}
                                  >
                                    {item.feedback_questions &&
                                      item.answer_type !== 9 &&
                                      item.feedback_questions.map((question, key) => (
                                        <Grid key={key} item lg={6} md={6} sm={12} xs={12}>
                                          {/* {
                                      userType === "salesrep" &&  question ?.answer?.rating ? 
                                    } */}
                                          <h4 className="heading5">{question.name}</h4>
                                          <div className={styles.customRating}>
                                            <StyledRating
                                              name={`unique-rating-${item.id}-${question.id}`}
                                              key={question.id}
                                              onChange={(e, newValue) => {
                                                handleChange(
                                                  'rating',
                                                  newValue,
                                                  item?.id,
                                                  question.id
                                                );
                                              }}
                                              // precision={0.5}
                                              max={5}
                                              value={question?.answer?.rating || 0}
                                              icon={<span className={styles.ratingItem}></span>}
                                            />

                                            <span
                                              className={styles.ratingScore + ' heading5'}
                                            >{`${Number(question?.answer?.rating || 0)}/5`}</span>
                                            {question.error?.rating ? (
                                              <p className="errorMsg">{question.error?.rating}</p>
                                            ) : null}
                                          </div>
                                          {userType === 'salesrep' ? (
                                            <p
                                              className={styles.reviewContent + ' paragraph'}
                                              style={{ marginBottom: '8px' }}
                                            >
                                              {question?.answer?.remarks && (
                                                <span
                                                  className="heading6"
                                                  style={{ color: '#193448' }}
                                                >
                                                  Comments:{' '}
                                                </span>
                                              )}
                                              <span> {question?.answer?.remarks}</span>
                                            </p>
                                          ) : (
                                            <TextArea
                                              placeholder="Provide your comments"
                                              rowsMin={3}
                                              rowsMax={4}
                                              onChange={(e) =>
                                                handleChange(
                                                  'remarks',
                                                  e.target.value,
                                                  item?.id,
                                                  question?.id
                                                )
                                              }
                                              value={question?.answer?.remarks}
                                            />
                                          )}
                                        </Grid>
                                      ))}
                                    {item?.answer_type === 9 && (
                                      <Grid xs={12}>
                                        {userType === 'salesrep' ? (
                                          <p
                                            className={styles.reviewContent + ' paragraph'}
                                            style={{ marginBottom: '8px' }}
                                          >
                                            {item?.remarks && (
                                              <span
                                                className="heading6"
                                                style={{ color: '#193448' }}
                                              >
                                                Comments:{' '}
                                              </span>
                                            )}
                                            <span> {item?.remarks}</span>
                                          </p>
                                        ) : (
                                          <TextArea
                                            placeholder="Provide your comments"
                                            rowsMin={3}
                                            rowsMax={4}
                                            onChange={(e) =>
                                              handleMultipleChoiceRemarks({
                                                value: e.target.value,
                                                item,
                                              })
                                            }
                                            value={item?.remarks}
                                          />
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                              </ReadOnlyWrapper>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                </>
              ) : (
                'No result Found'
              )}
            </div>
          </div>
        </DialogContent>
        {!isViewOnly ? (
          <DialogActions className={styles.dialogActions}>
            <CustomButton
              className={`tertiaryBtn`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.handleClose();
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              {loader ? 'Saving...' : 'Save'}
            </CustomButton>
          </DialogActions>
        ) : (
          ''
        )}
      </Dialog>
    </div>
  );
}

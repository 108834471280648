import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

const ReactHtmlParser = ({ content, isStreamContent = false }) => {
  const html = useMemo(() => {
    if (typeof content === 'string') return content;
    try {
      return content?.toString();
    } catch (error) {
      return JSON.stringify(content || '');
    }
  }, [content]);

  if (isStreamContent)
    return (
      <>
        {parse(
          sanitizeHtml(html, {
            allowedTags: allowedStreamTags,
            allowedAttributes: {
              div: ['class'],
              a: ['href', 'target', 'rel'],
              img: ['src'],
            },
          })
        )}
      </>
    );
  return (
    <>
      {parse(
        sanitizeHtml(html, {
          allowedTags: ['iframe', 'ul', 'li'],
          allowedAttributes: {
            iframe: ['src'],
          },
        })
      )}
    </>
  );
};

export default ReactHtmlParser;

const allowedStreamTags = [
  'div',
  'ul',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'br',
  'hr',
  'a',
  'img',
];

import React from 'react';
import styles from './AddTrainingModules.module.scss';
import User from 'infrastructure/User';
import { imageBasePath } from 'constants';
import { format, parseISO } from 'date-fns';

const TrainingSelectCard = ({ module, selected = false, toggleSelection = () => null }) => {
  const {
    uuid,
    title,
    description,
    published_at,
    updated_at,
    content_count,
    estimated_duration,
    created_by,
  } = module?.content || {};

  return (
    <div onClick={() => toggleSelection(uuid)} className={styles.trainingModuleCard}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <div className={`${styles.circle} ${selected ? styles.selected : ''}`}>✓</div>
          <div className={styles.headerTextTitle}>{title}</div>
          <div className={styles.headerTextAuthor}>By {`${User.getUserName(created_by)}`}</div>
        </div>
      </div>
      <div className={styles.description}>
        {description.slice(0, 1500)}
        {description.length >= 1500 ? '...' : ''}
      </div>
      <div className={styles.bubblesContainer}>
        {published_at && (
          <div>
            <img src={`${imageBasePath}PublishedActive.svg`} alt="icon" />
            <strong>{format(parseISO(published_at), 'MMM dd, yyyy')}</strong> Published date
          </div>
        )}
        {updated_at && (
          <div>
            <img src={`${imageBasePath}UpdatedActive.svg`} alt="icon" />
            <strong>{format(parseISO(updated_at), 'MMM dd, yyyy')} </strong> Updated
          </div>
        )}
        <div>
          <img src={`${imageBasePath}contentActiveDesign.svg`} alt="icon" />
          <strong>{content_count}</strong> Contents
        </div>
        <div>
          <img src={`${imageBasePath}UsersActive.svg`} alt="icon" />
          <strong>{estimated_duration} mins</strong> duration
        </div>
      </div>
    </div>
  );
};

export default TrainingSelectCard;

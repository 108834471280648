import { useState, useEffect } from 'react';
import { Popover } from '@material-ui/core';
import {
  isSameDay,
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
} from 'date-fns';
import { DateRange, DateRangePicker, defaultStaticRanges } from 'react-date-range';
import CustomButton from './CustomButton';
import { imageBasePath } from '../constants';

export default function CustomDateRangePicker(props) {
  const {
    filter,
    frequency = '',
    minDate,
    defaultFilter = {},
    type = '',
    flag,
    disabledDates = {},
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    let filterUpdated = {
      start_date: range[0].startDate ? format(new Date(range[0].startDate), 'yyyy-MM-dd') : null,
      end_date: range[0].endDate ? format(new Date(range[0].endDate), 'yyyy-MM-dd') : null,
    };
    props.handleApply(filterUpdated);
    handleClose();
  };
  const handleChangeDate = (range) => {
    // console.log('range', range)
    if (frequency === 1 && flag) {
      setRange([
        {
          ...range.selection,
          startDate: new Date(range.selection.startDate),
          endDate: new Date(range.selection.startDate),
        },
      ]);
    } else if (frequency === 2) {
      setRange([
        {
          ...range.selection,
          startDate: isSameDay(
            startOfWeek(new Date(range.selection.startDate), { weekStartsOn: 1 }),
            startOfWeek(new Date(range.selection.endDate), { weekStartsOn: 1 })
          )
            ? startOfWeek(new Date(range.selection.startDate), { weekStartsOn: 1 })
            : startOfWeek(new Date(range.selection.endDate), { weekStartsOn: 1 }),
          endDate: isSameDay(
            endOfWeek(new Date(range.selection.startDate), { weekStartsOn: 1 }),
            endOfWeek(new Date(range.selection.endDate), { weekStartsOn: 1 })
          )
            ? endOfWeek(new Date(range.selection.startDate), { weekStartsOn: 1 })
            : endOfWeek(new Date(range.selection.endDate), { weekStartsOn: 1 }),
        },
      ]);
    } else if (frequency === 3) {
      setRange([
        {
          ...range.selection,
          startDate: isSameDay(
            startOfMonth(new Date(range.selection.startDate)),
            startOfMonth(new Date(range.selection.endDate))
          )
            ? startOfMonth(new Date(range.selection.startDate))
            : startOfMonth(new Date(range.selection.endDate)),
          endDate: isSameDay(
            endOfMonth(new Date(range.selection.startDate)),
            endOfMonth(new Date(range.selection.endDate))
          )
            ? endOfMonth(new Date(range.selection.startDate))
            : endOfMonth(new Date(range.selection.endDate)),
        },
      ]);
    } else if (frequency === 4) {
      setRange([
        {
          ...range.selection,
          startDate: isSameDay(
            startOfQuarter(new Date(range.selection.startDate)),
            startOfQuarter(new Date(range.selection.endDate))
          )
            ? startOfQuarter(new Date(range.selection.startDate))
            : startOfQuarter(new Date(range.selection.endDate)),
          endDate: isSameDay(
            endOfQuarter(new Date(range.selection.startDate)),
            endOfQuarter(new Date(range.selection.endDate))
          )
            ? endOfQuarter(new Date(range.selection.startDate))
            : endOfQuarter(new Date(range.selection.endDate)),
        },
      ]);
    } else {
      setRange([range.selection]);
    }
  };
  const shortcutsItems = () => {
    switch (frequency) {
      case 1:
        return [defaultStaticRanges[0], defaultStaticRanges[1]];
      case 2:
        return [defaultStaticRanges[2], defaultStaticRanges[3]];
      case 3:
        return [defaultStaticRanges[4], defaultStaticRanges[5]];
      default:
        return [];
    }
  };
  useEffect(() => {
    if (filter && filter.start_date) {
      setRange([
        {
          startDate: new Date(filter.start_date + 'T00:00:00'),
          endDate: filter.end_date ? new Date(filter.end_date + 'T00:00:00') : new Date(),
          key: 'selection',
        },
      ]);
    } else {
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [filter]);

  const handleOpenFilter = (e) => {
    setAnchorEl(e.currentTarget);
    if (filter && filter.start_date) {
      setRange([
        {
          startDate: new Date(filter.start_date + 'T00:00:00'),
          endDate: filter.end_date ? new Date(filter.end_date + 'T00:00:00') : new Date(),
          key: 'selection',
        },
      ]);
    } else {
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
  };
  return (
    <div className="dateRangeFilterWrapper">
      <div className="dateValues" onClick={handleOpenFilter}>
        <p className="heading6">
          <span>
            {filter && filter.start_date
              ? `${format(new Date(filter.start_date + 'T00:00:00'), 'MMM dd, yyyy')} ${
                  isSameDay(
                    new Date(filter.start_date + 'T00:00:00'),
                    new Date(filter.end_date + 'T00:00:00')
                  )
                    ? ``
                    : `- ${
                        filter.end_date
                          ? format(new Date(filter.end_date + 'T00:00:00'), 'MMM dd, yyyy')
                          : ''
                      }`
                }`
              : defaultFilter && defaultFilter.start_date
              ? `${format(
                  new Date(defaultFilter.start_date + 'T00:00:00'),
                  frequency === 4 ? 'MMM dd, yyyy' : 'MMM dd'
                )} ${
                  isSameDay(
                    new Date(defaultFilter.start_date + 'T00:00:00'),
                    new Date(defaultFilter.end_date + 'T00:00:00')
                  )
                    ? ``
                    : ` - ${
                        defaultFilter.end_date
                          ? format(
                              new Date(defaultFilter.end_date + 'T00:00:00'),
                              frequency === 4 ? 'MMM dd, yyyy' : 'MMM dd'
                            )
                          : ''
                      }`
                }`
              : `Filter`}
          </span>
          {filter && filter.start_date ? (
            <img
              src={`${imageBasePath}close_gray.svg`}
              alt=""
              className="closeIcon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.handleApply({});
              }}
            />
          ) : (
            <img
              src={`${imageBasePath}down_arrow_light_gray.svg`}
              className="downArrowIcon"
              alt=""
            />
          )}
        </p>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="dateRangeSelectPopup"
      >
        <div className="dateRangeSelectWrapper">
          <div className="dateRangePicker">
            {!flag ? (
              <DateRange
                onChange={(item) => handleChangeDate(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={range}
                direction="horizontal"
                maxDate={new Date()}
                minDate={new Date(minDate)}
                inputRanges={[]}
                showDateDisplay={true}
              />
            ) : (
              <DateRangePicker
                onChange={(item) => handleChangeDate(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={range}
                direction="horizontal"
                maxDate={new Date()}
                minDate={new Date(minDate)}
                inputRanges={[]}
                showDateDisplay={true}
                staticRanges={shortcutsItems()}
                disabledDates={disabledDates}
              />
            )}
          </div>
          <div className="footer">
            <ul className="listInline">
              <li className="listInlineItem">
                <CustomButton className="tertiaryBtn smallBtn" onClick={handleClose}>
                  {'Cancel'}
                </CustomButton>
              </li>
              <li className="listInlineItem">
                <CustomButton onClick={handleSubmit} className={`primaryBtn smallBtn`}>
                  Apply
                </CustomButton>
              </li>
            </ul>
          </div>
        </div>
      </Popover>
    </div>
  );
}

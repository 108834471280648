import React, { useMemo } from 'react';
import styles from './ReadOnlyWrapper.module.scss';
import Organization from 'infrastructure/Organization';

const ReadOnlyWrapper = ({ data, label = 'modules', children }) => {
  const readOnly = useMemo(() => isReadOnly(data), [data]);

  const title = useMemo(() => {
    if (Organization.isRep()) return '';
    return `Please upgrade your plan to continue accessing and creating more ${label}`;
  }, [label]);

  if (!readOnly) return children;
  return (
    <div title={title}>
      <div className={styles.wrapper}>
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 102,
            opacity: 1,
            pointerEvents: 'none',
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default ReadOnlyWrapper;

export const isReadOnly = (data) => data?.read_only;

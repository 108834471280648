import React, { useMemo } from 'react';
import styles from './MyTeam.module.scss';
import Organization from 'infrastructure/Organization';
import MyTeamTable from './MyTeamTable';
import { useGetRoleplayTeamQuery } from 'features/roleplay/Roleplay.queries';
import { FeatureFlag } from 'controllers/_exports';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const MyTeam = () => {
  const { selectedDateFilterRoleplay } = useRoleplayStore();
  const { data, isLoading } = useGetRoleplayTeamQuery({
    time: FeatureFlag.enabled('ROLEPLAY_FILTERS') ? selectedDateFilterRoleplay.slug : 'all-time',
  });
  const isRep = useMemo(() => Organization.isRep(), []);
  if (isRep) return null;
  
  return (
    <div className={styles.container}>
      <MyTeamTable data={data} loading={isLoading} />
    </div>
  );
};

export default MyTeam;

import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from './EditBillingInfo.module.scss';
import { FeatureFlag } from 'controllers/_exports';
import EditBillingInfo from './EditBillingInfo';
const EditBillingInfoModal = ({ cardInfo = {} }) => {
  const [showModal, setShowModal] = useState(false);

  const show = useMemo(() => FeatureFlag.enabled('EDIT_SUBSCRIPTION'), []);

  if (!show) return null;

  return (
    <>
      <button variant="outlined" onClick={() => setShowModal(true)} className={styles.editButton}>
        Edit billing Info.
      </button>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent className={styles.CancelDialog}>
          <EditBillingInfo setShowModal={setShowModal} cardInfo={cardInfo} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditBillingInfoModal;

import React, { useEffect, useState } from 'react';
import styles from './AssignRoleplay.module.scss';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomLink, InputText } from 'shared_ui_components';
import { imageBasePath } from 'constants';
const RoleplayRatingCriteria = ({ title = 'Rating Criteria', points_ = [] }) => {
  const [points, setPoints] = useState(points_);
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const formDataCreateRoleplay = useRoleplayStore((state) => state?.formDataCreateRoleplay);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);
  const handleChange = (id, value) => {
    let criterial = formDataCreateRoleplay?.rating_criterials.find((c) => c.id === id);
    if (criterial) {
      criterial.name = value;
    }
    setFormDataCreateRoleplay({ ...formDataCreateRoleplay });
  };
  useEffect(() => {
    setPoints(formDataAssignRoleplay?.roleplay?.rating_criteria || []);
  }, [formDataAssignRoleplay]);

  const handleAddNewRatingCriteria = () => {
    // delete formDataCreateRoleplay?.rating_criterials;
    const id =
      formDataCreateRoleplay?.rating_criterials[
        formDataCreateRoleplay?.rating_criterials.length - 1
      ].id;
    let aux = {
      name: '',
      id: id + 1,
    };
    setFormDataCreateRoleplay({
      ...formDataCreateRoleplay,
      rating_criterials: [...formDataCreateRoleplay?.rating_criterials, aux],
    });
  };

  const handleRemoveRatingCriteria = (id) => {
    const aux = formDataCreateRoleplay?.rating_criterials.filter((c) => c.id !== id);
    setFormDataCreateRoleplay({
      ...formDataCreateRoleplay,
      rating_criterials: aux,
    });
  };
  return (
    <>
      {formDataAssignRoleplay?.roleplay?.value !== -1 ? (
        <div className={styles.noteBlock}>
          <p className="heading5" style={{ marginBottom: '0.75rem' }}>
            {title}
          </p>
          <ol style={{ marginLeft: '1rem' }}>
            {points.map((point, index) => {
              return (
                <li key={index} className="supportText">
                  {point.name}
                </li>
              );
            })}
          </ol>
        </div>
      ) : (
        <div>
          <p className={styles.deadlineTitle}>Rating Criteria</p>
          {formDataCreateRoleplay?.rating_criterials?.map(({ name, id }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '0.5rem',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <div style={{ width: '100%', height: '3rem' }}>
                  <InputText
                    id={id}
                    placeholder="Add rating criteria"
                    value={name}
                    onChange={(e) => handleChange(id, e.target.value)}
                  />
                </div>
                {formDataCreateRoleplay?.rating_criterials.length > 1 && (
                  <div className={styles.closeImage} onClick={() => handleRemoveRatingCriteria(id)}>
                    <img src={imageBasePath + 'close.svg'} alt="Delete Question" />
                  </div>
                )}
              </div>
            );
          })}
          <CustomLink
            className="customLinkPulse"
            linktext={'+ Add Rating Criteria'}
            onClick={() => handleAddNewRatingCriteria()}
          />
        </div>
      )}
    </>
  );
};

export default RoleplayRatingCriteria;

import React from 'react';
import styles from './MultipleChoice.module.scss';

const OptionNumber = ({ number }) => {
  return (
    <div style={{ paddingTop: '1rem' }}>
      <div className={styles.number}>{number}</div>
    </div>
  );
};

export default OptionNumber;

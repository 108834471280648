import React from 'react';
import { imageBasePath } from 'constants';
import styles from './AssignedLearningPathDetails.module.scss';

const ContentsChip = ({ total = 0, label = 'Modules' }) => {
  return (
    <div className={styles.chip}>
      <img src={`${imageBasePath}contentActiveDesign.svg`} alt="" />
      <strong>{total}</strong>
      <span>{label}</span>
    </div>
  );
};

export default ContentsChip;

import { useState, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/tracking.module.scss';
import { InputSelect } from '../../../shared_ui_components';
import { format, startOfMonth, addMonths, subMonths, getISODay } from 'date-fns';
import { getTeamActivityApi } from '../apiServices';
import { toastFlashMessage, TooltipCustom } from '../../../utils';
import DataBox from './DataBox';
import CheckinDataBox from './CheckinDataBox';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Shimmer from './Shimmer';

const options = [
  { value: '1', label: 'This week' },
  { value: '2', label: 'This week' },
  { value: '3', label: 'This week' },
];
let pointsTable = [
  { label: 'Min. of 5 Check-ins', backgroundColor: 'rgb(0,163,134)' },
  {
    label: 'Min. of 4 Check-ins',
    backgroundColor: 'rgba(0,163,134,0.5)',
  },
  {
    label: 'Min. of 3 Check-ins ',
    backgroundColor: 'rgba(0,163,134,0.3)',
  },
  {
    label: 'Min. of 2 Check-ins',
    backgroundColor: 'rgba(0,163,134,0.2)',
  },
  {
    label: 'Min. of 1 Check-ins',
    backgroundColor: 'rgba(0,163,134,0.1)',
  },
  {
    label: 'No Check-ins',
    backgroundColor: 'rgba(1, 22, 39, 0.1)',
  },
];
const MONTH_FORMAT = 'MMMM yyyy';
const DATE_FORMAT = 'yyyy-MM-dd';

// const checkIfDateIsFuture = (start_date, end_date) => {
//   if (checkPastPresentFutureDate(end_date)?.value !== 1) {
//     return start_date;
//   } else {
//     return moment().format('YYYY-MM-DD');
//   }
// };
const weekDay = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};
export default function ActivityHeatMap(props) {
  const { type = 'team', userId = '', activeCheckinDate = '' } = props;

  const [stats, setStats] = useState([]);
  // const [dateIndex, setDateIndex] = useState(0);
  const [unMount, setUnMount] = useState(true);
  const [month, setMonth] = useState(format(new Date(), MONTH_FORMAT));
  const [date, setDate] = useState(format(startOfMonth(new Date()), DATE_FORMAT));

  const handleChangeDate = (type) => {
    let newDate = 0;
    if (type === 'prev') {
      newDate = startOfMonth(subMonths(new Date(date + 'T00:00:00'), 1));
    } else {
      newDate = startOfMonth(addMonths(new Date(date + 'T00:00:00'), 1));
    }
    setMonth(format(new Date(newDate), MONTH_FORMAT));
    setDate(format(new Date(newDate), DATE_FORMAT));
  };

  const howManyDummyDatesToAdd = (dates) => {
    const first_date = dates[0]?.date;
    const first_day = getISODay(new Date(first_date + 'T00:00:00'));
    //  return first_day - 1
    return first_day == 7 ? 0 : first_day;
  };

  const addDummyDates = useCallback((dates) => {
    const dummyDate = {
      check_in: 0,
      date: '',
      weightage: 0,
      id: new Date().getTime(),
      isOriginal: false,
    };
    let dummyDatesNo = howManyDummyDatesToAdd(dates);

    let dummyDatesArray = [];
    for (let i = 0; i < dummyDatesNo; i++) {
      dummyDatesArray.push(dummyDate);
    }

    return [...dummyDatesArray, ...dates];
  }, []);

  const fixDummyDates = (dates) => {
    if (Array.isArray(dates) && dates?.length > 35 && dates.slice(0, 5).every((d) => !d?.date)) {
      return dates.slice(4);
    }
    return dates;
  };

  const fetchActivity = useCallback(
    (query) => {
      const successCallback = (res) => {
        if (res.data) {
          let responseDates = res.data.map((data) => {
            return { ...data, isOriginal: true, id: data.date };
          });

          //Add dummy dates to show correct order from monday to sunday
          let finalDatesArray = fixDummyDates(addDummyDates(responseDates));

          setUnMount(false);
          setStats([...finalDatesArray]);
          setUnMount(true);
        } else {
          setStats([]);
        }
      };
      const errorCallback = (err) => {
        toastFlashMessage(err.message, 'error');
      };
      getTeamActivityApi(query, successCallback, errorCallback);
    },
    [addDummyDates]
  );

  useEffect(() => {
    if (type == 'checkin' && userId) {
      fetchActivity({ start_date: date, user: userId });
    } else if (type == 'team') {
      fetchActivity({ start_date: date });
    }
  }, [date, userId]);
  return (
    <div className={styles.salesPerformersWrapper + ' ' + styles.teamActivityWrapper}>
      <div className={styles.topBlk}>
        <Grid container direction="row" alignItems="center" className={styles.topRow}>
          <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
            <h4 className="heading6">{type == 'team' ? `Team Activity` : `Check-in`}</h4>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={styles.rightBlk}>
            <div className={styles.filterBlk + ' hidden'}>
              <InputSelect
                className="reactSelect"
                closeMenuOnSelect={true}
                options={options}
                isSearchable={false}
                placeholder={'Select'}
                noOptionsMessage={() => 'No matches found'}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={styles.contentBlk}>
        <div className={styles.monthSelect}>
          <div className={styles.month + ' heading6'}>{month}</div>
          <div className={styles.buttons}>
            <div className={styles.buttonsWrapper}>
              <ArrowBackIosIcon
                onClick={() => handleChangeDate('prev')}
                className={`${styles.arrowIcon} ${styles.prev}`}
              />
              <ArrowForwardIosIcon
                onClick={() => handleChangeDate('next')}
                className={`${styles.arrowIcon} ${styles.next}`}
              />
            </div>
          </div>
          <div
            className={`${styles.monthUserGraph} ${
              type == 'checkin' ? styles.checkinMonthGraph : ''
            }`}
          >
            <ul className={`listInline ${styles.monthItems}`}>
              {unMount &&
                stats?.map((item, i) => {
                  return (
                    <li
                      className={`${styles.monthItem} listInlineItem ${
                        item.date ? '' : styles.hide
                      } ${
                        type == 'team'
                          ? item.date && format(new Date(), 'yyyy-MM-dd') === item.date
                            ? styles.today
                            : ''
                          : activeCheckinDate == item.date
                          ? item.check_in
                            ? styles.activeCheckinItem
                            : styles.active
                          : ''
                      }`}
                      key={i}
                    >
                      {type == 'team' ? (
                        <DataBox
                          weight={item.weightage}
                          date={
                            item.date ? format(new Date(item.date + 'T00:00:00'), 'MM/dd/yyyy') : ''
                          }
                          day={
                            item.date ? weekDay[getISODay(new Date(item.date + 'T00:00:00'))] : ''
                          }
                          checkins={item.check_in}
                        />
                      ) : (
                        <CheckinDataBox
                          checkinData={item}
                          date={
                            item.date ? format(new Date(item.date + 'T00:00:00'), 'MM/dd/yyyy') : ''
                          }
                          day={
                            item.date ? weekDay[getISODay(new Date(item.date + 'T00:00:00'))] : ''
                          }
                          handleViewCheckinDetails={props.handleViewCheckinDetails}
                        />
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className={`${styles.listColorValues} ${type == 'checkin' ? styles.checkin : ''}`}>
            {type == 'team' ? (
              <ul className="listInline flexCentered textCenter">
                <li className="listInlineItem">
                  <p className="supportTextSemibold">Less</p>
                </li>
                {pointsTable
                  .slice(0)
                  .reverse()
                  .map((data) => {
                    return (
                      <li className="listInlineItem">
                        <TooltipCustom
                          placement="bottom"
                          arrow
                          title={<div className="">{data.label}</div>}
                        >
                          <span
                            className={styles.squareBox}
                            style={{ backgroundColor: data.backgroundColor }}
                          ></span>
                        </TooltipCustom>
                      </li>
                    );
                  })}
                <li className="listInlineItem">
                  <p className="supportTextSemibold">More</p>
                </li>
              </ul>
            ) : (
              <ul className="listInline flexCentered textCenter">
                <li className="listInlineItem">
                  <span
                    className={`${styles.squareBox}`}
                    style={{ backgroundColor: `rgba(45, 195, 141, 0.4)` }}
                  ></span>
                </li>
                <li className="listInlineItem">
                  <p className="supportTextSemibold">Check-in</p>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* <Shimmer type="TrackingOverviewActivity" /> */}
    </div>
  );
}

import Organization from 'infrastructure/Organization';
import React from 'react';

const MultipleChoiceFeedback = ({ question }) => {
  const correct = question?.feedback_questions?.find((fq) => fq?.is_correct);
  const repAnswerValue = question?.answer?.value;
  const repAnswer = question.feedback_questions?.find((fq) => fq?.id == repAnswerValue)?.name;

  return (
    <div>
      <p style={{ color: '#193448CC', marginBottom: '0.75rem' }}>
        Correct answer: <strong>{correct?.name}</strong>
      </p>

      <p style={{ color: '#193448CC' }}>
        {Organization.isRep() ? 'Your ' : 'Sales rep '} answer: <strong>{repAnswer}</strong>
      </p>
    </div>
  );
};

export default MultipleChoiceFeedback;

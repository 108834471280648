import React from 'react';
import styles from './StreamContainer.module.scss';
import Banner from '../components/Banner/Banner';
import FilterPaginationController from '../Filters/FilterPaginationController/FilterPaginationController';
import TeamPulse from '../Analytics/TeamPulse/TeamPulse';
import NewPost from '../NewPost/NewPost';
import Filter from 'features/home/components/Filters/Filter';
import { FeatureFlag } from 'controllers/_exports';

export default function StreamContainer() {
  return (
    <>
      <Banner />
      <div className={styles.filterContainer}>

      {FeatureFlag.enabled("HOME_FILTERS") && <Filter page='pulse'/>}
      </div>
      <div className={styles.streamContainer}>
        <div className={styles.mainContent}>
          <NewPost />
          <br></br>
          <FilterPaginationController />
        </div>

        <div className={styles.sideContent}>
          <TeamPulse />
        </div>
      </div>
    </>
  );
}

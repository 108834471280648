import React, { useState, useEffect, useContext } from 'react';
import styles from '../../../assets/styles/onboarding.module.scss';
import { List, ListItem, Avatar, Grid, Menu, MenuItem, ClickAwayListener } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { InputText, CustomButton } from '../../../shared_ui_components';
import {
  createOnboardingCommentApi,
  updateOnboardingCommentApi,
  onboardingCommentlikeDislikeApi,
  deleteOnboardingCommentApi,
} from '../apiServices';
import { toastFlashMessage, lastActivity } from '../../../utils';
import {
  createTrainingCommentApi,
  updateTrainingCommentApi,
  trainingCommentlikeDislikeApi,
  deleteTrainingCommentApi,
} from '../../training/apiServices';
import { roleConfig } from '../roleConfiguration';
import { GlobalContext } from '../../../context/GlobalContext';

function textAreaAdjust(element) {
  element.style.height = '1px';
  element.style.height = 25 + element.scrollHeight + 'px';
}

function canDelete(commentedBy, roleId) {
  const loggedInUser = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).user
    : '';
  if (loggedInUser) {
    if (loggedInUser.uuid === commentedBy.uuid) {
      return true;
    } else if (roleConfig[roleId].candeleteOthersComments) {
      return true;
    } else {
      return false;
    }
  }
}

export default function Comments(props) {
  const { activeStep, updateActiveStep, page = 'onboarding' } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).user
    : '';
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { index } = props;
  const [comment, commentSet] = useState('');
  const [editComment, editCommentSet] = useState(null);
  const [allComments, allCommentsSet] = useState([]);
  const [submitLoader, submitLoaderSet] = useState(false);
  const [edtSubmitLoader, editSubmitLoaderSet] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(key);
  };

  const submitComment = () => {
    submitLoaderSet(true);
    if (page == 'onboarding') {
      createOnboardingCommentApi(activeStep.uuid, { comment: comment }).then((response) => {
        submitLoaderSet(false);
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          allCommentsSet([...allComments, ...[response.data]]);
          updateActiveStep('comments', [...allComments, ...[response.data]]);
          commentSet('');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      createTrainingCommentApi(activeStep.uuid, { comment: comment }).then((response) => {
        submitLoaderSet(false);
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          allCommentsSet([...allComments, ...[response.data]]);
          updateActiveStep('comments', [...allComments, ...[response.data]]);
          commentSet('');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
  };

  useEffect(() => {
    allCommentsSet(activeStep.comments ? activeStep.comments : []);
  }, [activeStep.uuid]);

  const updateComment = () => {
    editSubmitLoaderSet(true);
    if (page == 'onboarding') {
      updateOnboardingCommentApi(activeStep.uuid, editComment.id, {
        comment: editComment.comment,
      }).then((response) => {
        editSubmitLoaderSet(false);
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          allCommentsSet(
            allComments.map((comment) => (comment.id === editComment.id ? response.data : comment))
          );
          updateActiveStep(
            'comments',
            allComments.map((comment) => (comment.id === editComment.id ? response.data : comment))
          );
          editCommentSet(null);
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      updateTrainingCommentApi(activeStep.uuid, editComment.id, {
        comment: editComment.comment,
      }).then((response) => {
        editSubmitLoaderSet(false);
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          allCommentsSet(
            allComments.map((comment) => (comment.id === editComment.id ? response.data : comment))
          );
          updateActiveStep(
            'comments',
            allComments.map((comment) => (comment.id === editComment.id ? response.data : comment))
          );
          editCommentSet(null);
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
  };

  const likeDislikeComment = (comment, val) => {
    if (page == 'onboarding') {
      onboardingCommentlikeDislikeApi(activeStep.uuid, comment.id, { like: val }).then(
        (response) => {
          submitLoaderSet(false);
          if (response.statusCode === 200) {
            // toastFlashMessage(response.message, 'success');
            updateActiveStep('likeDislike', response.data);
            allCommentsSet(allComments.map((com) => (com.id === comment.id ? response.data : com)));
          } else {
            toastFlashMessage(response.message, 'error');
          }
        }
      );
    } else {
      trainingCommentlikeDislikeApi(activeStep.uuid, comment.id, { like: val }).then((response) => {
        submitLoaderSet(false);
        if (response.statusCode === 200) {
          // toastFlashMessage(response.message, 'success');
          updateActiveStep('likeDislike', response.data);
          allCommentsSet(allComments.map((com) => (com.id === comment.id ? response.data : com)));
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
  };

  const deleteComment = (comment) => {
    if (page == 'onboarding') {
      deleteOnboardingCommentApi(activeStep.uuid, comment.id).then((response) => {
        submitLoaderSet(false);
        if (response.statusCode === 200) {
          updateActiveStep('delete', comment);
          toastFlashMessage(response.message, 'success');
          allCommentsSet(allComments.filter((com) => com.id !== comment.id));
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      deleteTrainingCommentApi(activeStep.uuid, comment.id).then((response) => {
        submitLoaderSet(false);
        if (response.statusCode === 200) {
          updateActiveStep('delete', comment);
          toastFlashMessage(response.message, 'success');
          allCommentsSet(allComments.filter((com) => com.id !== comment.id));
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
  };

  return (
    <div className={styles.commentOuterWrapper}>
      <h4 className="heading6">
        <span>{allComments.length} </span> Comments
      </h4>
      <div className={styles.commentWrapper}>
        <div className={styles.userInfo}>
          {userInfo && userInfo.profile_pic ? (
            <img
              className={styles.profilePic + ' profilePic'}
              src={userInfo.profile_pic}
              alt="Profile pic"
            />
          ) : (
            <Avatar className={styles.avatar + ' avatar'}>
              {userInfo.first_name
                ? userInfo.first_name.substring(0, 1)
                : userInfo.email
                ? userInfo.email.substring(0, 1)
                : ''}
            </Avatar>
          )}
        </div>
        <div className={styles.enterComment}>
          <InputText
            id="comment"
            placeholder="Enter comment here"
            className="commentInputGroup"
            multiline
            value={comment}
            onChange={(e) => {
              commentSet(e.target.value);
            }}
          />
        </div>
        <div className={styles.postComment}>
          <CustomButton
            className={`primaryBtn smallBtn ${!comment || submitLoader ? 'disabledBtn' : ''}`}
            children={submitLoader ? 'Please wait...' : 'Comment'}
            onClick={() => {
              submitComment();
            }}
          />
        </div>
      </div>
      <div className={styles.commentPreviewWrapper}>
        {!allComments?.length ? (
          <h5 className={styles.noComments + ' supportText textCenter'}>No Comments Available</h5>
        ) : (
          <div className={styles.commentList}>
            {allComments.map((comment, index) => (
              <div key={index} className={styles.commentItem}>
                <div className={styles.userInfo}>
                  {comment?.user && comment?.user.profile_pic ? (
                    <img
                      className={styles.profilePic + ' profilePic'}
                      src={comment?.user.profile_pic}
                      alt="Profile pic"
                    />
                  ) : (
                    <Avatar className={styles.avatar + ' avatar'}>
                      {comment?.user?.first_name
                        ? comment?.user?.first_name.substring(0, 1)
                        : comment?.user?.email.substring(0, 1)}
                    </Avatar>
                  )}
                </div>
                <div className={styles.commentDetail}>
                  <Grid container direction="row" className={styles.commentRow}>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                      <h6 className="paragraph">
                        {comment?.user?.uuid === userInfo.uuid
                          ? 'You'
                          : comment.user?.first_name
                          ? comment.user?.first_name + '' + comment.user?.last_name
                          : comment.user?.email}
                      </h6>
                      {editComment?.id === comment.id ? (
                        <ClickAwayListener onClickAway={() => editCommentSet(null)}>
                          <div className={styles.updateCommentWrapper}>
                            <InputText
                              id="comment"
                              placeholder="Enter comment here"
                              className="commentInputGroup"
                              multiline
                              value={editComment.comment}
                              onChange={(e) =>
                                editCommentSet({ ...editComment, comment: e.target.value })
                              }
                            />
                            <CustomButton
                              className={`primaryBtn smallBtn ${
                                !editComment || edtSubmitLoader || !editComment?.comment
                                  ? 'disabledBtn'
                                  : ''
                              }`}
                              children={edtSubmitLoader ? 'Please wait...' : 'Update'}
                              onClick={() => {
                                updateComment();
                              }}
                            />
                          </div>
                        </ClickAwayListener>
                      ) : (
                        <>
                          <div className={styles.description + ' paragraph'}>
                            <InputText
                              id="comment"
                              placeholder="Enter comment here"
                              className="commentInputGroup"
                              multiline
                              value={comment.comment}
                              disabled
                            />
                          </div>
                        </>
                      )}
                      <List className={styles.engagementList + ' listInline engagementList'}>
                        <ListItem
                          className="listInlineItem"
                          onClick={() => likeDislikeComment(comment, 1)}
                        >
                          {comment.like_status === 1 ? (
                            <img src={imageBasePath + 'like_active.svg'} alt="Like" />
                          ) : (
                            <img src={imageBasePath + 'like_default.svg'} alt="Like" />
                          )}
                          <span className="paragraph">{comment.likes}</span>
                        </ListItem>
                        <ListItem
                          className="listInlineItem"
                          onClick={() => likeDislikeComment(comment, 2)}
                        >
                          {comment.like_status === 2 ? (
                            <img src={imageBasePath + 'dislike_active.svg'} alt="Dislike" />
                          ) : (
                            <img src={imageBasePath + 'dislike_default.svg'} alt="Dislike" />
                          )}
                          <span className="paragraph">{comment.dislikes}</span>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                      <List className={styles.infolList + ' listInline textRight'}>
                        <ListItem className="listInlineItem">
                          <span className={styles.date + ' supportText'}>
                            {lastActivity(comment.updated_at)}
                          </span>
                        </ListItem>
                        {canDelete(comment.user, state.role.id) ||
                        comment?.user?.uuid === userInfo.uuid ? (
                          <ListItem className="listInlineItem">
                            <span className={styles.more}>
                              <a
                                aria-controls={'simple-menu-' + index}
                                className={'more'}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleClick(e, index);
                                }}
                              >
                                <img
                                  className="more"
                                  src={imageBasePath + 'more_vertical.svg'}
                                  alt="More"
                                  style={{ verticalAlign: 'middle' }}
                                />
                              </a>
                            </span>
                            <Menu
                              id={'simple-menu-' + index}
                              anchorEl={anchorEl}
                              keepMounted
                              open={currentIndex == index && Boolean(anchorEl) ? true : false}
                              onClose={(e) => {
                                setAnchorEl(null);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              className="moreMenu"
                            >
                              {comment?.user?.uuid === userInfo.uuid ? (
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setAnchorEl(null);
                                    editCommentSet(comment);
                                  }}
                                >
                                  <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                                  <span className="supportText">Edit</span>
                                </MenuItem>
                              ) : null}
                              {canDelete(comment.user, state.role.id) ? (
                                <MenuItem
                                  className="deleteAction"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setAnchorEl(null);
                                    deleteComment(comment);
                                  }}
                                >
                                  <img src={imageBasePath + 'delete_red.svg'} alt="Delete" />
                                  <span className="supportText">Delete</span>
                                </MenuItem>
                              ) : null}
                            </Menu>
                          </ListItem>
                        ) : null}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import styles from './chatAssist.module.scss';
import PromptContainer from './PromptContainer/PromptContainer';
import MessagesContainer from './MessagesContainer/MessagesContainer';
import ChatInput from './ChatInput/ChatInput';
import IconButton from './IconButton/IconButton';
import { msgInfo, tipsText, errorResponse } from './constants';
import { useAskQuestionMutation } from '../ChatAssist.queries';
import { useSalesAssistStore } from '../ChatAssist.store';

const ChatAssistView = () => {
  const messagesEndRef = useRef(null);
  const [value, setValue] = useState('');

  const messages = useSalesAssistStore((state) => state?.messages);
  const addMessage = useSalesAssistStore((s) => s?.updateMessages);
  const addID = useSalesAssistStore((s) => s?.setId);
  const id = useSalesAssistStore((state) => state?.session_id);

  const { mutate: askQuestion, isLoading: isWaiting } = useAskQuestionMutation();

  const isJustSpaces = (m) => m.trim() === '';

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleNewMessages = (message, type = msgInfo?.sender) => {
    if (isJustSpaces(message)) return;
    const newMessage = {
      text: message,
      type,
    };
    addMessage(newMessage);
    const body = id === '' ? { prompt: message } : { prompt: message, session_id: id };
    askQuestion(body, {
      onSuccess: (response) => {
        const newResponse = {
          text: response?.answer,
          type: msgInfo?.recipient,
        };
        if (id === '') addID(response?.session_id);
        addMessage(newResponse);
      },
      onError: () => {
        addMessage(errorResponse);
      },
    });
    setValue('');
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    handleNewMessages(value, msgInfo?.sender);
  };

  return (
    <div className={styles.chatContainer}>
      {Object.keys(messages).length > 0 ? (
        <div className={styles.messagesContainer} ref={messagesEndRef}>
          <MessagesContainer messages={messages} />
        </div>
      ) : (
        <div className={styles.promptContainer}>
          {tipsText.map((item, index) => (
            <PromptContainer key={index} title={item?.title} text={item?.text} />
          ))}
        </div>
      )}
      {isWaiting && (
        <div className={styles.loadMessageContainer}>...Generating your answer, just a moment!</div>
      )}
      <div className={styles.inputContainer}>
        <ChatInput
          value={value}
          onChange={onChange}
          onKeyPress={handleSendMessage}
          disabled={isWaiting}
        />
        <IconButton
          onClick={handleSendMessage}
          disabled={isWaiting || value === '' || isJustSpaces(value)}
        />
      </div>
    </div>
  );
};

export default ChatAssistView;

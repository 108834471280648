import { create } from 'zustand';
import  {timeOptions} from 'constants'
export const useStreamStore = create((set, get) => ({
  pagination: { number_of_pages: 0 },
  latestPosts: [],
  setLatestPosts: (posts) => set(() => ({ latestPosts: posts })),
  hasFilters: false,
  setHasFilters: (hasFilters) => set(() => ({ hasFilters })),
  // New post
  loadingNewPost: false,
  setLoadingNewPost: (loadingNewPost) => set(() => ({ loadingNewPost })),
  userEmails: [],
  setUserEmails: (userEmails = []) => set(() => ({ userEmails })),
  postValue: ' ',
  setPostValue: (value) => set(() => ({ postValue: value })),
  selectedDateFilterPulse: timeOptions.find(opt=>opt.value===2),
  setSelectedDateFilterPulse: (selectedDateFilterPulse = {}) => set(() => ({ selectedDateFilterPulse })),
  resetPost: () => set(() => ({ postValue: '' })),
  clean: (id = '') => {
    const thumbnailSectionID = id ? `commentThumbnailsSection-${id}` : 'postThumbnailsSection';
    let thumbs = '';
    try {
      const deleteButtons = document.getElementById(thumbnailSectionID).querySelectorAll('.delete');
      deleteButtons.forEach((btn) => btn.remove());
      thumbs = document.getElementById(thumbnailSectionID).innerHTML.replace('<div></div>', '');
    } catch (error) {}

    const value = id ? get()[`comment-${id}`] : get().postValue;

    // Final string sent to the backend
    const withoutImg = removeImageTags(value);
    const withEmails = formatEmailTags(withoutImg, get()?.userEmails);

    const res = withEmails + thumbs;
    const resWithoutTags = res.replace(/(<([^>]+)>)/gi, '');
    if (!resWithoutTags?.trim() || resWithoutTags?.trim() === '&nbsp;') return '';
    return res;
  },
  getCleanPost: () => {
    const post = get()?.clean();
    return post;
  },
  // comments
  setCommentValue: (id = '', value = '') => set(() => ({ [`comment-${id}`]: value })),
  resetComment: (id = '') => set(() => ({ [`comment-${id}`]: '' })),
  getCleanComment: (id) => {
    if (!id) return '';
    const comment = get()?.clean(id);
    return comment;
  },
}));

// Formatting functions
const removeImageTags = (value = '') =>
  value?.replace(/(?!.*(?:className="thumbnail_img"))(<(img)\s[^>]+?)>/gi, '');

const formatEmailTags = (value = '', users = []) => {
  // Removes space after @
  if (!users?.length > 0) return value;
  let text = value;
  users.forEach((email) => {
    text = text.replaceAll(`@ ${email}`, `@${email}`);
  });
  return text;
};

import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';

import { useSubscriptionStore } from './Subscription.store';
import { CustomButton } from 'shared_ui_components';
import { GlobalContext } from 'context/GlobalContext';
import styles from 'features/payment/components/Payment.module.scss';
import PaymentForm from 'features/payment/components/PaymentForm/PaymentForm';
import Subscription from './Subscription';
import './SubscriptionModal.scss';
import { Payment } from 'features/payment/Payment.utils';

const SubscriptionModal = () => {
  const history = useHistory();

  const featureMessage = useSubscriptionStore((state) => state.message);
  const open = useSubscriptionStore((state) => state.isModalOpen);
  const onClose = useSubscriptionStore((state) => state.closeModal);

  const onClick = () => {
    onClose();
    // TODO change price selection depending on tier
    setOpenPaymentModal(true);
  };

  const { state } = useContext(GlobalContext);
  const isOwner = ['primary_admin'].includes(state?.role?.slug);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const onSuccess = () => {
    setOpenPaymentModal(false);
    history.push('/subscription');
  };

  const message = useMemo(() => {
    let message =
      "We're thrilled to hear that you're enjoying the freemium plan! If you're looking to unlock even more features, you can easily upgrade your plan. It's a great way to take your experience to the next level.";

    if (Subscription.isAccelerate())
      message =
        "We're thrilled to hear that you're enjoying the accelerate plan! If you're looking to unlock even more features, you can easily upgrade your plan. It's a great way to take your experience to the next level.";

    return message;
  }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="title">This is a premium feature</DialogTitle>
        <DialogContent>
          <p>{featureMessage}</p>
          <br />
          <p>{message}</p>
          <br />
          {!isOwner && (
            <span>
              Please contact your organization owner if you still want to access this feature.
            </span>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '1rem' }}>
          <CustomButton className="secondaryBtn" color="secondary" onClick={onClose}>
            Cancel
          </CustomButton>
          {isOwner && <CustomButton onClick={onClick}>Upgrade plan</CustomButton>}
        </DialogActions>
      </Dialog>

      {/* Subscription Upgrade */}
      <Dialog open={openPaymentModal} onClose={() => setOpenPaymentModal(false)}>
        <DialogContent className={styles.paymentDialog}>
          <PaymentForm
            fullPage={false}
            onSuccess={onSuccess}
            processType={Payment.PROCESS_TYPE.UPGRADE}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionModal;

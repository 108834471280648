import { Grid } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
// import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';
import { CloseBannerButton, useBanner } from 'shared_ui_components/CloseBannerButton';

export default function HomeIntroductionCard(props) {
  const { role } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).user
    : '';
  // const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).user : '')
  // useEffect(() => {
  //     setUserInfo(JSON.parse(localStorage.getItem('userInfo'))?.user)
  // }, [])

  // Close handler
  const [isBannerOpen, closeBanner] = useBanner('welcome_banner');
  if (!isBannerOpen) return null;

  return (
    <section
      className={`${styles.homeIntroductionSection} flexCentered ${
        ['user', 'sales_lead'].includes(role) ? styles.userIntroduction : ''
      }`}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className={styles.userInfoWrapper + ' textCenter'}>
            {['user', 'sales_lead'].includes(role) ? (
              <div className={styles.userImgBlk}>
                {userInfo && userInfo.profile_pic ? (
                  <img className="profilePic" src={userInfo.profile_pic} alt="Profile pic" />
                ) : (
                  <Avatar className={`avatar ${styles.userImg}`}>
                    {' '}
                    {userInfo.first_name
                      ? userInfo.first_name.substring(0, 1)
                      : userInfo.email
                      ? userInfo.email.substring(0, 1)
                      : ''}
                  </Avatar>
                )}
              </div>
            ) : null}
            <div className={styles.contentBlk}>
              <h3 className="heading2">
                {['user', 'sales_lead'].includes(role)
                  ? `Welcome to salestable,`
                  : `Welcome to salestable`}
              </h3>
              <p className="heading5Regular">
                {['user', 'sales_lead'].includes(role)
                  ? `The only growth enablement platform that helps sales leaders and salespeople to collaborate effectively and transparently.`
                  : `The only growth enablement platform that helps sales leaders and salespeople to collaborate effectively and transparently.`}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <CloseBannerButton isHome onClose={closeBanner} />
    </section>
  );
}

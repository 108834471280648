import React, { useState, useEffect } from 'react';
import styles from './AssignRoleplay.module.scss';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { TextArea } from 'shared_ui_components';

const RoleplayDescription = () => {
  const [roleplay, setRoleplay] = useState({ label: '', description: '' });
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const formDataCreateRoleplay = useRoleplayStore((state) => state?.formDataCreateRoleplay);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);
  const handleChange = (value) => {
    setFormDataCreateRoleplay({ ...formDataCreateRoleplay, description: value });
  };
  useEffect(() => {
    setRoleplay(formDataAssignRoleplay?.roleplay);
  }, [formDataAssignRoleplay, setRoleplay, roleplay]);
  return (
    <>
      {roleplay?.label && formDataAssignRoleplay?.roleplay?.value !== -1 ? (
        <div className={styles.noteBlock}>
          <p className="supportText">{roleplay?.description}</p>
        </div>
      ) : (
        <div>
          <p className={styles.deadlineTitle}>Description</p>
          <TextArea
            rows={3}
            placeholder="Enter new roleplay’s description"
            value={formDataCreateRoleplay?.description}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      )}
    </>
  );
};

export default RoleplayDescription;

import React, { useEffect, useState } from 'react';
import styles from 'assets/styles/tracking.module.scss';
import { AdminSalesGoalsView, Shimmer, UsersSalesGoalView } from 'features/tracking/components';
import { useGetHubspotTrackingQuery } from 'features/hubspot/Hubspot.queries';
import Organization from 'infrastructure/Organization';
import RefreshHubspotData from '../RefreshHubspotData';

const HubspotTrackingOverview = ({ userId, filters, refetchCheckIns = () => null }) => {
  // Get data
  const { data, isLoading } = useGetHubspotTrackingQuery({ userId, ...filters });
  const [hubspotOverview, setHubspotOverview] = useState({});
  useEffect(() => {
    if (isLoading) return;
    setHubspotOverview(data?.length > 0 ? data[0] : {});
  }, [data, isLoading]);

  if (Object.keys(hubspotOverview)?.length === 0) return null;
  return (
    <div className={styles.salesWrapper + ' ' + styles.contentOverviewWrapper}>
      <RefreshHubspotData refetchCheckIns={refetchCheckIns} data={hubspotOverview} id={userId} />

      {Organization.isRep() && (
        <UsersSalesGoalView
          appliedFilters={filters}
          overview={hubspotOverview}
          userId={userId}
          loader={isLoading}
          isFromHubspot={true}
          hideHeaders={true}
        />
      )}

      {!Organization.isRep() && (
        <AdminSalesGoalsView
          overview={hubspotOverview}
          userId={userId}
          appliedFilters={filters}
          loader={isLoading}
          isFromHubspot={true}
          hideHeaders={true}
        />
      )}
    </div>
  );
};

export default HubspotTrackingOverview;

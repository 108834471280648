import { API } from 'utils/globalApiServices';

export async function getHubspotSettingsService() {
  const url = '/organization/providers/hubspot/settings';
  const { data } = await API.get(url);
  return data;
}

export async function getHubspotTrackingService({ userId, start_date, end_date }) {
  let url = '/tracking/hubspot/overview/';
  const params = {};
  if (userId) params.user = userId;
  if (start_date) params.start_date = start_date;
  if (end_date) params.end_date = end_date;

  const { data } = await API.get(url, { params });
  return data;
}

export async function updateHubspotSettingsService({ id, settings }) {
  if (!settings?.goals?.length > 0 || typeof id === 'undefined') return {};
  const url = `/organization/providers/${id}/settings/`;
  const { data } = await API.post(url, settings);
  return data;
}

export async function updateHubspotInfoService({ id }) {
  let url = `/tracking/hubspot/update-info/`;
  if (id) url += `?user=${id}`;
  const { data } = await API.post(url);
  return data;
}

import { AddDocument, IndexSidebar, UploadedFilePreview } from '../../onboarding/components';
import styles from '../../../assets/styles/onboarding.module.scss';
import { Grid } from '@material-ui/core';

export default function ModuleContentsLibrary(props) {
  const {
    contents,
    handleReorderTraining,
    handleChangeContent,
    activeContent,
    handleAddContent,
    handleMarkAsComplete,
    handleDeleteContent,
    handleEnabelDisable,
    handleEditContent,
    isAdmin,
    handleLikeDislike,
    updateActiveContent,
    role,
    isPublished,
    handleMarkAsRead,
    showModal, 
    setShowModal,
    time,
    setTime
  } = props;
  return (
    <div className={styles.onboardingPage}>
      <section className={styles.onboardingStepsection}>
        <Grid container direction="row" spacing={2} className={styles.stepsRow}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <IndexSidebar
              steps={contents}
              handleChangeStep={handleChangeContent}
              activeStep={activeContent}
              handleAddCustomStep={handleAddContent}
              isAdmin={isAdmin}
              handleReorderTraining={handleReorderTraining}
              page="training"
              // handleDeleteCustomStep={() => setDeleteContent(true)}
            />
          </Grid>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <UploadedFilePreview
              handleDeleteCustomStep={handleDeleteContent}
              handleEnabelDisable={handleEnabelDisable}
              handleEditContent={handleEditContent}
              activeStep={activeContent}
              isAdmin={isAdmin}
              handleLikeDislike={handleLikeDislike}
              handleMarkAsComplete={handleMarkAsComplete}
              updateActiveStep={updateActiveContent}
              page="training"
              role={role}
              isPublished={isPublished}
              handleMarkAsRead={handleMarkAsRead}
              setShowModal={setShowModal}
              showModal={showModal}
              time={time}
              setTime={setTime}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
}

import React from 'react';
import styles from './RoleplayView.module.scss';
import RoleplayViewDescriptionHeader from './RoleplayViewDescriptionHeader';
import RoleplayViewDescriptionBody from './RoleplayViewDescriptionBody';
import RoleplayViewDescriptionFooter from './RoleplayViewDescriptionFooter';

const RoleplayViewDescription = () => {

  return (
    <div className={styles.background}>
      <RoleplayViewDescriptionHeader />
      <RoleplayViewDescriptionBody />
      <RoleplayViewDescriptionFooter />
    </div>
  );
};

export default RoleplayViewDescription;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toastFlashMessage } from 'utils';
import { API } from 'utils/globalApiServices';

const FUNNEL_KEYS = {
  TRACKING_STAGES: 'tracking_stages',
  FUNNEL_GRAPH: 'funnel_graph',
};

export const useGetFunnelGraphQuery = ({ isOverview, salesRepId, timeLapse, enabled }) => {
  return useQuery({
    queryKey: [FUNNEL_KEYS.FUNNEL_GRAPH, isOverview, salesRepId, timeLapse],
    queryFn: async () => {
      const params = {};
      if (timeLapse) params.time_lapse = timeLapse;
      if (!isOverview && salesRepId) params.user = salesRepId;

      const res = await API.get('/tracking/activity-funnel/', { params });
      return res?.data && Array.isArray(res?.data) ? res?.data : [];
    },
    enabled,
  });
};

export const useGetStagesQuery = ({ enabled }) => {
  const queryFn = async () => {
    const res = await API.get('/tracking/get/funnel-stages/');
    return res?.data || [];
  };
  return useQuery({
    queryKey: [FUNNEL_KEYS.TRACKING_STAGES],
    queryFn,
    enabled,
  });
};

export const useUpdateStagesMutation = () => {
  const mutationFn = async ({ body }) => {
    const res = await API.post('/tracking/funnel-stages/', body);
    return res;
  };

  const queryClient = useQueryClient();
  const onSuccess = (response) => {
    toastFlashMessage('Activity funnel stages have been updated', 'success');
    queryClient.invalidateQueries({ queryKey: [FUNNEL_KEYS.FUNNEL_GRAPH] });
    queryClient.setQueryData([FUNNEL_KEYS.TRACKING_STAGES], (prev) => {
      if (response?.statusCode === 200 && response?.data) return response.data;
      return prev;
    });
  };

  return useMutation({
    mutationFn,
    onSuccess,
  });
};

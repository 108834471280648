import React, { useState } from 'react';

import { TooltipCustom } from '../../../utils';
import CircleDot from './CircleDot';

import stopIcon from '../../../assets/recorder/stop.svg';
import '../RecorderButtons/RecorderButton.scss';

const IndicatorButton = ({ handleStop, isExpanded, isRecording, isPaused }) => {
  const [isHovering, setIsHovering] = useState(false);

  if (isExpanded || !isRecording)
    return (
      <div style={{ position: 'relative', marginRight: '0.3rem' }}>
        <CircleDot isPaused={isPaused} isRecording={isRecording} />
      </div>
    );

  return (
    <div
      style={{ position: 'relative', marginRight: '0.3rem' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <TooltipCustom title={isRecording ? 'Stop Recording' : ''} placement="bottom" arrow>
        <div className="recording-indicator-button" onClick={handleStop}>
          {isHovering && isRecording ? (
            <img
              width="12px"
              height="12px"
              src={stopIcon}
              alt="stop"
            />
          ) : (
            <CircleDot isPaused={isPaused} isRecording={isRecording} />
          )}
        </div>
      </TooltipCustom>
    </div>
  );
};

export default IndicatorButton;

import React, { useMemo } from 'react';
import iconSubmit from 'assets/roleplay/iconSubmit.svg';
import { STATUS_ID } from 'features/roleplay/RoleplayConstants';
import styles from './SubmitReview.module.scss';
import RoleplayRating from '../Common/RoleplayRating/RoleplayRating';
const SubmitReviewCard = ({
  title = '',
  description = '',
  qualified = {},
  index = 1,
  stars = 0,
}) => {
  const passed = useMemo(() => ![STATUS_ID.QUALIFIED].includes(qualified?.id), [qualified?.id]);
    return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.cardContainerHeader}>
          <div className={styles.cardTitle}>
            <div
              className={`${styles.cardIndex} ${
                qualified === null
                  ? styles.cardIndexUncompleted
                  : !passed
                  ? ''
                  : styles.cardIndexFailed
              }`}
            >
              <strong>{index}</strong>
            </div>
            <img src={iconSubmit} alt="icon" className={`${styles.cardIcon}`} />
            <div className="heading6">{title}</div>
          </div>

          <RoleplayRating stars={stars} withTooltip />
        </div>

        <div className="supportText">{description}</div>
      </div>
    </>
  );
};

export default SubmitReviewCard;

import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RoleplayRating from '../Common/RoleplayRating/RoleplayRating';
import styles from './History.module.scss';
import User from 'infrastructure/User';
import RoleplayLabel from '../Common/RoleplayLabel';

const HistoryCard = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const description = useMemo(() => {
    if (showMore) return data?.description;
    return `${data?.description.substring(0, 168)}...`;
  }, [showMore, data?.description]);

  const onReadMore = (e) => {
    e.stopPropagation();
    setShowMore(true);
  };

  const history = useHistory();
  const onClick = () => history.push(`/roleplay-review-qualified/${data?.uuid}`);

  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.content} style={{ background: data?.color_code }}>
        <p className={styles.title}>{data?.name}</p>
        <p>{data?.total_scenarios > 0 ? `${data?.total_scenarios} Scenarios` : `\u00A0`}</p>
        <p className={styles.description}>
          {description}{' '}
          {!showMore && (
            <span onClick={onReadMore} className={styles.more}>
              read more
            </span>
          )}
        </p>
        <p className={styles.user}>{User.getUserName(data?.assigned_by_user)}</p>
        <RoleplayRating stars={data?.stars} withTooltip />
      </div>
      <div className={styles.footer}>
        <span>{data?.submission_date}</span>
        <RoleplayLabel {...data?.status} />
      </div>
    </div>
  );
};

export default HistoryCard;

import React from 'react';
import { useHistory } from 'react-router-dom';
import arrow from 'assets/stream/arrow_l.svg';

import styles from './RoleplayHome.module.scss';
import icon from 'assets/roleplay/roleplay_logo.svg';

const RoleplayHomeCard = ({ title = 'Roleplays', children }) => {
  const history = useHistory();
  const onClick = () => history.push('./roleplay');

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.imageContainer}>
            <img alt="" src={icon} width={20} height={20} />
          </div>
          <span className={styles.title}>{title}</span>
        </div>
        <span className={styles.viewMore} onClick={onClick}>
          Details
          <img className={styles.icon} src={arrow} alt=">" />
        </span>
      </div>
      {children}
    </div>
  );
};

export default RoleplayHomeCard;

import React from 'react';
import DatePicker from './DatePicker';

const Until = ({ setFormData = () => null, formData }) => {
  const handleAcceptDate = (query = {}) => {
    setFormData({ ...formData, until_date: formatDate(new Date(query)) + ' 00:00:00' });
  };
  const formatDate = (date) => {
    const dateString = new Date(date);

    let month = dateString.getUTCMonth() + 1;
    if (month < 10) month = `0${month}`;
    let day = dateString.getUTCDate();
    if (day < 10) day = `0${day}`;
    let year = dateString.getUTCFullYear();

    const newDate = year + '-' + month + '-' + day;
    return newDate;
  };
  return (
    <div>
      <p className="supportText">Date</p>
      <DatePicker
        handleApply={(date) => handleAcceptDate(date)}
        defaultFilter={{
          date: formData?.until_date
            ? formatDate(new Date(formData?.until_date))
            : formatDate(new Date()),
        }}
      />
    </div>
  );
};

export default Until;

import { create } from 'zustand';

// New user payment process store handler
export const usePaymentStore = create((set, get) => ({
  selectedProduct: {}, // Stripe plan selected by user
  currentStep: '',
  paymentMethodData: {},
  billingData: '',
  couponData: {
    coupon: '',
    promo_code_id: '',
  },

  setSelectedProduct: (newProduct) => set(() => ({ selectedProduct: newProduct })),
  setStep: (newStep) => set(() => ({ currentStep: newStep })),
  // Billing data necessary for stripe
  setBillingDetails: (details) =>
    set(({ billingData }) => ({ billingData: { ...billingData, ...details } })),
  setBillingAddress: (address) =>
    set(({ billingData }) => ({ billingData: { ...billingData, address } })),
  // Stripe payment method
  setPaymentMethodData: (payment) => set(() => ({ paymentMethodData: payment })),
  // Coupons
  setCoupon: (coupon) => set(() => ({ couponData: { coupon, promo_code_id: '' } })),
  setPromoID: (id) => set(() => ({ couponData: { ...get()?.couponData, promo_code_id: id } })),
  resetCoupon: () => set(() => ({ couponData: { coupon: '', promo_code_id: '' } })),
}));

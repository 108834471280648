import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import { globalPostService } from '../../../utils/globalApiServices';
import { regExpression, imageBasePath } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import styles from '../../../assets/styles/auth.module.scss';
import queryString from 'query-string';
import { InputText, CustomButton, CustomLink, ToastMessage } from '../../../shared_ui_components';
import { GlobalContext } from '../../../context/GlobalContext';
import { PasswordRecoverySuccess } from '../components';
import { resetPasswordApi, handleSiginResponse } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        token: '',
        email: '',
        name: '',
        password: '',
        confirm_password: '',
      },
      passwordValidation: {
        minCharacter: false,
        oneNumber: false,
        oneUpperCase: false,
        oneLowerCase: false,
      },
      confirmPasswordValidation: {
        minCharacter: false,
        oneNumber: false,
        oneUpperCase: false,
        oneLowerCase: false,
      },
      error: {},
      errorCode: {
        password: {
          0: '',
          1: 'Please enter password',
          4: 'Invalid password',
        },
        passwordObj: {
          requiredFlag: true,
          regexPattern: regExpression.password,
        },
        confirmPassword: {
          0: '',
          1: 'Please enter password',
          4: 'Invalid password',
        },
        confirmPasswordObj: {
          requiredFlag: true,
          regexPattern: regExpression.password,
        },
      },
      loader: false,
    };
  }

  componentWillMount() {
    const querys = queryString.parse(this.props.location.search);
    const token = querys.token;
    const email = querys.email.replace(/\s/g, '+');
    const name = querys.name;
    this.setState({
      formData: {
        ...this.state.formData,
        token: token,
        email: email,
        name: name,
      },
    });
  }

  togglePassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        password_show: !this.state.formData.password_show,
      },
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        confirm_password_show: !this.state.formData.confirm_password_show,
      },
    });
  };

  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        confirm_password: '',
        [key]: '',
      },
    });
    if (key == 'password') {
      this.setState({
        passwordValidation: {
          ...this.state.passwordValidation,
          minCharacter: this.hasLength(val),
          oneNumber: this.hasNumber(val),
          oneUpperCase: this.hasUpperCase(val),
          oneLowerCase: this.hasLowerCase(val),
        },
      });
    }
    if (key == 'confirm_password') {
      this.setState({
        confirmPasswordValidation: {
          ...this.state.confirmPasswordValidation,
          minCharacter: this.hasLength(val),
          oneNumber: this.hasNumber(val),
          oneUpperCase: this.hasUpperCase(val),
          oneLowerCase: this.hasLowerCase(val),
        },
      });
    }
  };

  hasNumber = (str) => {
    return /\d/.test(str);
  };

  hasLength = (str) => {
    if (str.length > 7) {
      return true;
    } else {
      return false;
    }
  };

  hasLowerCase = (str) => {
    return /[a-z]/.test(str);
  };

  hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
  };

  googleLogin = (data) => {
    globalPostService(`api/social/signup/`, data).then((response) => {
      this.handleSiginResponse(response);
    });
  };

  handleSubmit = () => {
    const { formData, errorCode, passwordValidation } = this.state;
    let validateNewInput = {
      password:
        errorCode['password'][
          fieldValidation({ ...errorCode['passwordObj'], fieldval: formData.password })
        ],
      confirm_password:
        errorCode['confirmPassword'][
          fieldValidation({
            ...errorCode['confirmPasswordObj'],
            fieldval: formData.confirm_password,
          })
        ],
    };
    if (formData.password != formData.confirm_password) {
      validateNewInput = {
        ...validateNewInput,
        confirm_password: 'Passwords are not matching',
      };
    }
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      this.setState({
        loader: true,
      });
      resetPasswordApi({ formData: formData })
        .then((response) => {
          if (response.statusCode === 200) {
            toastFlashMessage(response.message, 'success');
            this.props.history.push('/signin');
          } else {
            toastFlashMessage(response.message, 'error');
          }
        })
        .catch((error) => {
          toastFlashMessage(error.message, 'error');
        });
      this.setState({
        loader: false,
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  render() {
    const { error, formData, passwordValidation, loader, confirmPasswordValidation } = this.state;
    return (
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div>
                  <div className={styles.cardWrapper + ' ' + styles.passwordResetWrapper}>
                    <img
                      className={styles.logo}
                      src={imageBasePath + 'logo.svg'}
                      alt="salestable"
                    />
                    <h1 className="heading3">Create a new password</h1>
                    <p className="paragraph">
                      Email: <span> {formData.email}</span>
                    </p>
                    <div className={styles.formWrapper + ' formWrapper'}>
                      <form
                        onSubmit={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.handleSubmit();
                        }}
                        className={styles.form}
                      >
                        <div className="inputGroup passwordGroup">
                          <Tooltip
                            title={
                              <div className={styles.tooltipWrapper}>
                                <p className="supportText">Password must contain</p>
                                <ul className="listUnstyled">
                                  <li className="paragraph">
                                    {passwordValidation.minCharacter ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 8</b> Characters minimum
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneNumber ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Number atleast
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneUpperCase ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Uppercase letter atleast (A-Z)
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneLowerCase ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Lowercase letter atleast (a-z)
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            }
                            placement="top"
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableHoverListener
                          >
                            <InputText
                              id="password"
                              label="New Password"
                              type={formData.password_show ? 'text' : 'password'}
                              autoComplete="current-password"
                              placeholder="********"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => this.handleChange('password', e.target.value.trim())}
                              value={formData.password}
                              error={error.password}
                              className={error.password ? 'errorGroup' : ' '}
                            />
                          </Tooltip>
                          <span
                            className="passwordShow metaText"
                            onClick={() => this.togglePassword()}
                          >
                            {formData.password_show ? (
                              <img src={imageBasePath + 'eye_hide.svg'} alt="Hide" />
                            ) : (
                              <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
                            )}
                          </span>
                        </div>
                        <div className="inputGroup passwordGroup">
                          <Tooltip
                            title={
                              <div className={styles.tooltipWrapper}>
                                <p className="supportText">Password must contain</p>
                                <ul className="listUnstyled">
                                  <li className="paragraph">
                                    {passwordValidation.minCharacter ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 8</b> Characters minimum
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneNumber ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Number atleast
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneUpperCase ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Uppercase letter atleast (A-Z)
                                    </span>
                                  </li>
                                  <li className="paragraph">
                                    {passwordValidation.oneLowerCase ? (
                                      <img src={imageBasePath + 'right.svg'} alt="image" />
                                    ) : (
                                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                                    )}
                                    <span>
                                      <b> 1</b> Lowercase letter atleast (a-z)
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            }
                            placement="top"
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableHoverListener
                          >
                            <InputText
                              id="confirm_password"
                              label="Re-enter the Password "
                              type={formData.confirm_password_show ? 'text' : 'password'}
                              autoComplete="current-password"
                              placeholder="********"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                this.handleChange('confirm_password', e.target.value)
                              }
                              value={formData.confirm_password}
                              error={error.confirm_password}
                              className={error.confirm_password ? 'errorGroup' : ' '}
                            />
                          </Tooltip>
                          <span
                            className="passwordShow metaText"
                            onClick={() => this.toggleConfirmPassword()}
                          >
                            {formData.confirm_password_show ? (
                              <img src={imageBasePath + 'eye_hide.svg'} alt="Hide" />
                            ) : (
                              <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
                            )}
                          </span>
                        </div>
                        <div className={styles.submitBlk + ' submitBlk'}>
                          <CustomButton
                            type="submit"
                            className={`primaryBtn submitBtn ${loader ? 'disabledBtn' : ''}`}
                            children={loader ? 'Submitting...' : 'Submit'}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <p className={styles.backBtn + ' paragraph textCenter'}>
                    Back to
                    <CustomLink className="linkBtn" linktext="Sign in" to="/signin" />
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(PasswordReset);

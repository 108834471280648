import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { EmailVerifiedCard } from '../components';
import { fieldValidation } from '../../../utils/formValidation';
import { verifyEmailApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';

export default class EmailVerified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      resendLoader: false,
      EmailVerified: false,
      errorMessage: '',
      email: '',
      token: '',
      user: {
        email: '',
      },
    };
  }

  componentDidMount() {
    const querys = queryString.parse(this.props.location.search, { decode: false });
    const token = querys.token;
    let email = querys.email;
    if (email) {
      email = email.replace(/\s/g, '+');
      this.setState({
        email: email,
      });
    }
    if (token && email) {
      verifyEmailApi({ token: token, email: email }).then((response) => {
        if (response.statusCode === 200) {
          localStorage.setItem('userInfo', JSON.stringify(response.data));
          this.setState({
            loader: false,
            EmailVerified: true,
            errorMessage: response.message,
          });
          toastFlashMessage(response.message, 'success');
          // if(userInfo.can_proceed == 'false'){
          //     this.props.history.push('/selectorganization');
          //   }
          //   else{
          //   this.props.history.push('/createorganization');
          // }
          // this.props.history.push('/signin');
        } else {
          this.setState({
            loader: false,
            EmailVerified: false,
            errorMessage: response.message,
          });
          toastFlashMessage(response.message, 'error');
          this.props.history.push('/signup');
        }
      }).catch((err) => {
        console.dir(err)
        this.setState({
          loader: false,
          EmailVerified: false,
          errorMessage: 'Something went wrong',
        });
        toastFlashMessage('Something went wrong', 'error');
        // this.props.history.push('/signup');
      });
    } else {
      this.props.history.push('/signup');
    }
  }

  render() {
    // const {  } = this.state;

    return (
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {this.state.loader && <p style={{ textAlign: 'center' }}>Loading...</p>}
                {this.state.EmailVerified && <EmailVerifiedCard />}
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

import React from 'react';

const RoleplayLabel = ({ label, color_code = '#0494FD', width }) => {
  if (!label) return null;
  return (
    <span
      style={{
        background: `${color_code}1a`,
        padding: '0 0.5rem',
        borderRadius: '3px',
        height: '22px',
        display: 'grid',
        placeItems: 'center',
        width: width || 'fit-content',
      }}
    >
      <p
        style={{
          color: color_code,
          fontWeight: '600',
          fontSize: '0.75rem',
          lineHeight: '10px',
          height: '10px',
          textWrap: 'nowrap',
        }}
      >
        {label}
      </p>
    </span>
  );
};

export default RoleplayLabel;

import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import styles from '../../../assets/styles/tracking.module.scss';
import { Skeleton } from '@material-ui/lab';
import { FeatureFlag } from 'controllers/_exports';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'AdminLanding' && (
        <>
          <section className={styles.adminLandingOverviewShimmer}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item lg={12} md={412} sm={12} xs={12}>
                <div className={styles.titleWrapper} style={{ position: 'relative' }}>
                  <div className={styles.imgBlk}>
                    <Skeleton
                      variant="circle"
                      width={48}
                      height={48}
                      style={{
                        background: 'rgba(0, 0, 0, 0.11)',
                        position: 'absolute',
                        top: -8,
                        left: 0,
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width="8%"
                      height={30}
                      style={{ position: 'absolute', left: 55 }}
                    />
                    <Skeleton
                      variant="text"
                      width="5%"
                      style={{ marginLeft: 210, display: 'inline-block', marginTop: 5 }}
                    />
                    <Skeleton
                      variant="text"
                      width="5%"
                      style={{ marginLeft: 20, display: 'inline-block' }}
                    />
                    <Skeleton
                      variant="text"
                      width="5%"
                      style={{ marginLeft: 20, display: 'inline-block' }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
            <section className={styles.trackingSetupSection}>
              <div className={styles.setupCardWrapper}>
                <Grid container direction="row" spacing={2} className={styles.trackingSetupRow}>
                  <Grid item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
                    <div className={`${styles.setupCard}`} style={{ width: '100%' }}>
                      <Skeleton
                        variant="circle"
                        width={48}
                        height={48}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                      <h2 className="heading4">
                        <Skeleton variant="text" width="40%" style={{}} />
                      </h2>
                      <p className="paragraph">
                        <Skeleton variant="text" width="80%" style={{}} />
                      </p>
                      <p className="paragraph">
                        <Skeleton variant="text" width="80%" style={{}} />
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={8} md={8} sm={12} xs={12}>
                    <div className={styles.cardsListWrapper}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        spacing={3}
                        className={styles.contentRow}
                      >
                        {[0, 1, 2].map((item, key) => (
                          <Grid item key={key} lg={4} md={4} sm={12} xs={12}>
                            <div className={styles.goalCard + ' ' + props.className}>
                              <div className={styles.cardWrapper}>
                                <div className={styles.topBlk + ' ' + props.bgclassName}>
                                  <Skeleton
                                    variant="circle"
                                    width={36}
                                    height={36}
                                    style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                                  />
                                  <h3 className="heading6">
                                    <Skeleton variant="text" width="40%" style={{}} />
                                  </h3>
                                </div>
                                <div className={styles.bottomBlk}>
                                  <h4 className="supportText">
                                    <Skeleton variant="text" width="60%" style={{}} />
                                  </h4>
                                  <h5 className="heading2">
                                    <Skeleton variant="text" width="40%" style={{}} />
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        spacing={3}
                        className={styles.btnRow + ' textCenter'}
                      >
                        <Grid item xs={12}>
                          <p className="paragraph textCenter">
                            <Skeleton
                              variant="text"
                              width="25%"
                              height={50}
                              style={{ margin: '0 auto' }}
                            />
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          )}
          <section className={styles.trackingSetupSection}>
            <div className={styles.setupCardWrapper}>
              <Grid container direction="row" spacing={2} className={styles.trackingSetupRow}>
                <Grid item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
                  <div
                    className={`${styles.setupCard} ${styles.salesCard}`}
                    style={{ width: '100%' }}
                  >
                    <Skeleton
                      variant="circle"
                      width={48}
                      height={48}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                    <h2 className="heading4">
                      <Skeleton variant="text" width="40%" style={{}} />
                    </h2>
                    <p className="paragraph">
                      <Skeleton variant="text" width="80%" style={{}} />
                    </p>
                    <p className="paragraph">
                      <Skeleton variant="text" width="80%" style={{}} />
                    </p>
                  </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div className={styles.cardsListWrapper}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                      spacing={3}
                      className={styles.contentRow}
                    >
                      {[0, 1, 2].map((item, key) => (
                        <Grid item key={key} lg={4} md={4} sm={12} xs={12}>
                          <div className={styles.goalCard + ' ' + props.className}>
                            <div className={styles.cardWrapper}>
                              <div className={styles.topBlk + ' ' + props.bgclassName}>
                                <Skeleton
                                  variant="circle"
                                  width={36}
                                  height={36}
                                  style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                                />
                                <h3 className="heading6">
                                  <Skeleton variant="text" width="40%" style={{}} />
                                </h3>
                              </div>
                              <div className={styles.bottomBlk}>
                                <h4 className="supportText">
                                  <Skeleton variant="text" width="60%" style={{}} />
                                </h4>
                                <h5 className="heading2">
                                  <Skeleton variant="text" width="40%" style={{}} />
                                </h5>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                      spacing={3}
                      className={styles.btnRow + ' textCenter'}
                    >
                      <Grid item xs={12}>
                        <p className="paragraph textCenter">
                          <Skeleton
                            variant="text"
                            width="25%"
                            height={50}
                            style={{ margin: '0 auto' }}
                          />
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </>
      )}

      {props.type == 'TrackingOrgOverview' && (
        <>
          {/* <section className={styles.overViewSection}>
                        <div className={styles.overviewWrapper}>
                            <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper} style={{ position: 'relative', marginBottom: 50 }}>
                                <Skeleton variant="circle" width={48} height={48} style={{ position: 'absolute', transform: 'none', top: 3 }} />
                                <Skeleton variant="text" width="15%" height={25} style={{ marginLeft: 60, marginBottom: 8 }} />
                                <Skeleton variant="text" width="40%" height={18} style={{ marginLeft: 60 }} />
                            </div>
                        </div>
                    </section> */}
          <div className={styles.TrackingOverviewShimmer}>
            <Grid container direction="row" className={styles.tableTitleRow}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlkShimmer}>
                <Skeleton
                  variant="text"
                  width="20%"
                  height={24}
                  style={{ marginLeft: 20, marginTop: 14 }}
                />
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                <List className="listInline">
                  {' '}
                  {/* add class active for active month */}
                  <ListItem className={styles.TrackingOverviewShimmerList}>
                    <Skeleton variant="text" width="70%" height={24} style={{ margin: '0 auto' }} />
                  </ListItem>
                  {[0, 1, 2].map((item, key) => (
                    <ListItem className={styles.TrackingOverviewShimmerList}>
                      <Skeleton
                        variant="text"
                        width="30%"
                        height={24}
                        style={{ margin: '0 auto' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <div className={styles.cardItem}>
              {[0, 1].map((item, key) => (
                <Grid container direction="row" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlkShimmer + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlkShimmer}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 30, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 80, marginTop: 35 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.rightBlkShimmer + ' flexNotCentered'}
                  >
                    <List className="listInline" style={{ width: '100%' }}>
                      {[0, 1, 2, 3].map((item, key) => (
                        <ListItem className={styles.TrackingOverviewShimmerList}>
                          <Skeleton
                            variant="text"
                            width="50%"
                            height={46}
                            style={{ margin: '0 auto' }}
                          />
                          <Skeleton
                            variant="text"
                            width="40%"
                            height={32}
                            style={{ margin: '0 auto' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        </>
      )}
      {props.type == 'TrackingSalesOverview' && (
        <>
          <div className={styles.salesCardItem + ' ' + styles.TrackingSalesOverviewShimmer}>
            <div className={styles.accordionHeader}>
              <div className={styles.accordionTitleBlk}>
                <Grid container direction="row" alignItems="center" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlk + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlk}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 23, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 13, marginBottom: 15 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.middleBlk + ' flexNotCentered'}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.innerContentRow}
                    >
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                        <div className={styles.customTarget + ' listInlineItem'}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 0 }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.middleBlk}>
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 80, marginTop: 0 }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className={styles.rightBlk}
                        style={{ paddingLeft: '100px' }}
                      >
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={styles.accordionBody}>
              <div className={styles.bodyWrapper}>
                <div className={styles.graphBlk}>
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={120}
                    style={{ transform: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.salesCardItem + ' ' + styles.TrackingSalesOverviewShimmer}>
            <div className={styles.accordionHeader}>
              <div className={styles.accordionTitleBlk}>
                <Grid container direction="row" alignItems="center" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlk + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlk}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 23, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 13, marginBottom: 15 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.middleBlk + ' flexNotCentered'}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.innerContentRow}
                    >
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                        <div className={styles.customTarget + ' listInlineItem'}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 0 }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.middleBlk}>
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 80, marginTop: 0 }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className={styles.rightBlk}
                        style={{ paddingLeft: '100px' }}
                      >
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={styles.salesCardItem + ' ' + styles.TrackingSalesOverviewShimmer}>
            <div className={styles.accordionHeader}>
              <div className={styles.accordionTitleBlk}>
                <Grid container direction="row" alignItems="center" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlk + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlk}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 23, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 13, marginBottom: 15 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.middleBlk + ' flexNotCentered'}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.innerContentRow}
                    >
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                        <div className={styles.customTarget + ' listInlineItem'}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 0 }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.middleBlk}>
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 80, marginTop: 0 }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className={styles.rightBlk}
                        style={{ paddingLeft: '100px' }}
                      >
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={styles.salesCardItem + ' ' + styles.TrackingSalesOverviewShimmer}>
            <div className={styles.accordionHeader}>
              <div className={styles.accordionTitleBlk}>
                <Grid container direction="row" alignItems="center" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlk + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlk}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 23, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 13, marginBottom: 15 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.middleBlk + ' flexNotCentered'}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.innerContentRow}
                    >
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                        <div className={styles.customTarget + ' listInlineItem'}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 0 }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.middleBlk}>
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 80, marginTop: 0 }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className={styles.rightBlk}
                        style={{ paddingLeft: '100px' }}
                      >
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={styles.salesCardItem + ' ' + styles.TrackingSalesOverviewShimmer}>
            <div className={styles.accordionHeader}>
              <div className={styles.accordionTitleBlk}>
                <Grid container direction="row" alignItems="center" className={styles.contentRow}>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={styles.leftBlk + ' flexNotCentered'}
                  >
                    <div className={styles.titleBlk}>
                      <Skeleton
                        variant="circle"
                        width={36}
                        height={36}
                        style={{ position: 'absolute', top: 23, left: 20 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 13, marginBottom: 15 }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={styles.middleBlk + ' flexNotCentered'}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={styles.innerContentRow}
                    >
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                        <div className={styles.customTarget + ' listInlineItem'}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 0 }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={12} xs={12} className={styles.middleBlk}>
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 80, marginTop: 0 }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className={styles.rightBlk}
                        style={{ paddingLeft: '100px' }}
                      >
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                        <Skeleton
                          variant="circle"
                          width={32}
                          height={32}
                          style={{ marginLeft: 0, marginTop: 0, display: 'inline-block' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
      )}

      {props.type == 'TrackingOverviewSalesPerformance' &&
        [0, 1, 2, 3].map((item, key) => (
          <div className={styles.salesPerformersWrapper}>
            <div className={styles.bottomBlk}>
              <div className={styles.performersItem}>
                <Grid container direction="row" alignItems="center" className={styles.usersRow}>
                  <Grid item lg={7} md={5} sm={12} xs={12} className={styles.leftBlk}>
                    <div className={styles.profileWrapper}>
                      <div className={styles.imgBlk}>
                        <Skeleton
                          variant="circle"
                          width={40}
                          height={40}
                          style={{ position: 'absolute', top: 3, left: 0 }}
                        />
                      </div>
                      <div className={styles.contentBlk}>
                        <h5 className="supportTextSemibold">
                          <Skeleton
                            variant="text"
                            width="45%"
                            height={24}
                            style={{ marginLeft: 10, marginTop: 8 }}
                          />
                          <Skeleton
                            variant="text"
                            width="65%"
                            height={18}
                            style={{ marginLeft: 10 }}
                          />
                        </h5>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12} className={styles.rightBlk}>
                    <div className={styles.progressBlk}>
                      <Skeleton
                        variant="text"
                        width="65%"
                        height={18}
                        style={{ marginLeft: 40, marginTop: 8 }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ))}
      {props.type == 'TrackingOverviewActivity' && (
        <>
          <div className={styles.TrackingOverviewActivityShimmer}>
            <Grid container direction="row">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Skeleton
                  variant="text"
                  width="72%"
                  height={150}
                  style={{ marginLeft: 60, marginTop: 14, transform: 'none', marginBottom: 20 }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {props.type == 'TrackingAdminOverviewTitle' && (
        <>
          <section className={styles.overViewSection}>
            <div className={styles.overviewWrapper}>
              {/* <Grid container direction="row" spacing={3} className={styles.downloadRow}>
                                <Grid item xs={12} className="textRight">
                                    <Skeleton variant="text" width="13%" height={40} style={{ marginRight: 10, transform: 'none', float: 'right' }} />
                                </Grid>
                            </Grid> */}
              <div
                className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}
                style={{ position: 'relative' }}
              >
                <Skeleton
                  variant="circle"
                  width={48}
                  height={48}
                  style={{ position: 'absolute', transform: 'none', top: 3 }}
                />
                <Skeleton
                  variant="text"
                  width="15%"
                  height={25}
                  style={{ marginLeft: 60, marginBottom: 8 }}
                />
                <Skeleton variant="text" width="40%" height={18} style={{ marginLeft: 60 }} />
              </div>
            </div>
          </section>
        </>
      )}
      {props.type == 'UserOverview' && (
        <>
          <div>
            <section className="headerSection hidden">
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="pageTitleBlock">
                    <h1>
                      {' '}
                      <Skeleton variant="text" width="40%" height={40} style={{}} />
                    </h1>
                    <List className="listInline tabList">
                      {[0, 1, 2, 3].map((tab, key) => (
                        <ListItem
                          key={key}
                          className={`listInlineItem heading6`}
                          onClick={() => {}}
                        >
                          <Skeleton variant="text" width="20%" height={18} style={{}} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </section>
          </div>
          <section className={styles.userOverviewGoalsSection + ' ' + styles.overViewSection}>
            <div className={styles.overviewWrapper}>
              <Grid container spacing={3} direction="row" className={styles.topRow}>
                <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                  <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}>
                    {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                      <div>
                        <Grid container direction="row" spacing={3} className={styles.titleRow}>
                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div className={styles.titleWrapper}>
                              <Skeleton
                                variant="circle"
                                width={48}
                                height={48}
                                style={{ position: 'absolute', top: 0, left: 0 }}
                              />
                              <div className={styles.info}>
                                <h2 className="heading5">
                                  <Skeleton
                                    variant="text"
                                    width="45%"
                                    height={16}
                                    style={{ marginLeft: 0 }}
                                  />
                                </h2>
                                <p className="paragraph">
                                  {' '}
                                  <Skeleton variant="text" width="85%" height={16} style={{}} />
                                </p>
                              </div>
                            </div>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Skeleton variant="text" width="45%" height={40} style={{}} />
                          </Grid>
                        </Grid>
                        <div className={styles.TrackingOverviewShimmer}>
                          <Grid container direction="row" className={styles.tableTitleRow}>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className={styles.leftBlkShimmer}
                            >
                              <Skeleton
                                variant="text"
                                width="20%"
                                height={24}
                                style={{ marginLeft: 20, marginTop: 14 }}
                              />
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                              <List className="listInline">
                                {' '}
                                {/* add class active for active month */}
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="70%"
                                    height={24}
                                    style={{ marginLeft: 50 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                          {[0, 1].map((key, item) => (
                            <div className={styles.cardItem}>
                              <Grid container direction="row" className={styles.contentRow}>
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  className={styles.leftBlkShimmer + ' flexNotCentered'}
                                >
                                  <div className={styles.titleBlkShimmer}>
                                    <Skeleton
                                      variant="circle"
                                      width={36}
                                      height={36}
                                      style={{ position: 'absolute', top: 23, left: 20 }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="45%"
                                      height={24}
                                      style={{ marginLeft: 80, marginTop: 30 }}
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  lg={9}
                                  md={9}
                                  sm={12}
                                  xs={12}
                                  className={styles.rightBlkShimmer + ' flexNotCentered'}
                                >
                                  <List className="listInline" style={{ width: '100%' }}>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="50%"
                                        height={24}
                                        style={{ marginLeft: 75, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        height={24}
                                        style={{ marginLeft: 87 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                  </List>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <Grid container direction="row" spacing={3} className={styles.titleRow}>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div className={styles.titleWrapper}>
                          <Skeleton
                            variant="circle"
                            width={48}
                            height={48}
                            style={{ position: 'absolute', top: 0, left: 0 }}
                          />
                          <div className={styles.info}>
                            <h2 className="heading5">
                              <Skeleton
                                variant="text"
                                width="45%"
                                height={16}
                                style={{ marginLeft: 0 }}
                              />
                            </h2>
                            <p className="paragraph">
                              {' '}
                              <Skeleton variant="text" width="85%" height={16} style={{}} />
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Skeleton variant="text" width="45%" height={40} style={{}} />
                      </Grid>
                    </Grid>
                    <div className={styles.TrackingOverviewShimmer}>
                      <Grid container direction="row" className={styles.tableTitleRow}>
                        <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlkShimmer}>
                          <Skeleton
                            variant="text"
                            width="20%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 14 }}
                          />
                        </Grid>
                        <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                          <List className="listInline">
                            {' '}
                            {/* add class active for active month */}
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="70%"
                                height={24}
                                style={{ marginLeft: 50 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                      {[0, 1, 2, 3].map((key, item) => (
                        <div className={styles.cardItem}>
                          <Grid container direction="row" className={styles.contentRow}>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className={styles.leftBlkShimmer + ' flexNotCentered'}
                            >
                              <div
                                className={styles.titleBlkShimmer}
                                style={{ background: '#EAF9F4' }}
                              >
                                <Skeleton
                                  variant="circle"
                                  width={36}
                                  height={36}
                                  style={{ position: 'absolute', top: 23, left: 20 }}
                                />
                                <Skeleton
                                  variant="text"
                                  width="45%"
                                  height={24}
                                  style={{ marginLeft: 80, marginTop: 30 }}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              lg={9}
                              md={9}
                              sm={12}
                              xs={12}
                              className={styles.rightBlkShimmer + ' flexNotCentered'}
                            >
                              <List className="listInline" style={{ width: '100%' }}>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="50%"
                                    height={24}
                                    style={{ marginLeft: 75, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="40%"
                                    height={24}
                                    style={{ marginLeft: 87 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                  <div className={styles.userCheckinCardOuterWrapper}>
                    <div className={styles.userCheckinContentWrapper}>
                      <div className={styles.tableTitleBlk}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={styles.tableTitleRow}
                        >
                          <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                            <h6 className="heading6">
                              <Skeleton
                                variant="text"
                                width="45%"
                                height={20}
                                style={{ margin: '0 0 8px 16px' }}
                              />
                            </h6>
                          </Grid>
                          {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={styles.rightBlk + ' textRight'}
                            >
                              <p
                                className="supportText textRight"
                                style={{ margin: '0 16px 0 auto' }}
                              >
                                <Skeleton
                                  variant="text"
                                  width="45%"
                                  height={20}
                                  style={{ margin: '0 16px 8px auto' }}
                                />
                              </p>
                            </Grid>
                          )}
                        </Grid>
                        {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                          <p>
                            <Skeleton
                              variant="text"
                              height={50}
                              style={{ margin: '8px 0', background: '#F2FAFF' }}
                            />
                          </p>
                        )}
                      </div>
                      {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                        <div className={styles.userTableContentBlk}>
                          {[0, 1, 2, 3].map((item, key) => (
                            <Grid
                              container
                              key={key}
                              direction="row"
                              alignItems="center"
                              className={styles.tableTitleRow}
                              style={{ margin: '0 0 12px 0' }}
                            >
                              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                                <h6 className="heading6" style={{ position: 'relative' }}>
                                  <Skeleton
                                    variant="circle"
                                    width={24}
                                    height={24}
                                    style={{ position: 'absolute', top: 0, left: 16 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="75%"
                                    height={20}
                                    style={{ margin: '0 0 8px 52px', display: 'block' }}
                                  />
                                </h6>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                className={styles.rightBlk + ' textRight'}
                              >
                                <p
                                  className="supportText textRight"
                                  style={{ margin: '0 16px 0 auto' }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width="45%"
                                    height={20}
                                    style={{ margin: '0 16px 8px auto' }}
                                  />
                                </p>
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      )}
                      <p>
                        <Skeleton
                          variant="text"
                          height={50}
                          style={{ margin: '8px 16px', background: '#EAF9F4' }}
                        />
                      </p>
                      <div className={styles.userTableContentBlk}>
                        {[0, 1, 2, 3, 4, 5].map((item, key) => (
                          <Grid
                            container
                            key={key}
                            direction="row"
                            alignItems="center"
                            className={styles.tableTitleRow}
                            style={{ margin: '0 0 12px 0' }}
                          >
                            <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                              <h6 className="heading6" style={{ position: 'relative' }}>
                                <Skeleton
                                  variant="circle"
                                  width={24}
                                  height={24}
                                  style={{ position: 'absolute', top: 0, left: 16 }}
                                />
                                <Skeleton
                                  variant="text"
                                  width="75%"
                                  height={20}
                                  style={{ margin: '0 0 8px 52px', display: 'block' }}
                                />
                              </h6>
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={styles.rightBlk + ' textRight'}
                            >
                              <p
                                className="supportText textRight"
                                style={{ margin: '0 16px 0 auto' }}
                              >
                                <Skeleton
                                  variant="text"
                                  width="45%"
                                  height={20}
                                  style={{ margin: '0 16px 8px auto' }}
                                />
                              </p>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </>
      )}
      {props.type == 'TableTitleRow' && (
        <Grid container direction="row" className={styles.tableTitleRow}>
          <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
            <span className="supportText">
              <Skeleton variant="text" width="40%" height={18} />
            </span>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
            <Grid container direction="row" className={styles.innerContentRow}>
              <Grid item lg={4} md={3} sm={12} xs={12} className={styles.leftBlk}>
                <span className={styles.achivement + ' supportText'}>
                  <Skeleton variant="text" width="40%" height={18} />
                </span>
              </Grid>
              <Grid item lg={4} md={3} sm={12} xs={12} className={styles.middleBlk}>
                <span className="supportText">
                  <Skeleton variant="text" width="40%" height={18} />
                </span>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12} className={styles.rightBlk}>
                <span className="supportText">
                  <Skeleton variant="text" width="40%" height={18} />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {props.type == 'TeamOverview' && (
        <>
          <section className={styles.userOverviewGoalsSection + ' ' + styles.overViewSection}>
            <div className={styles.overviewWrapper}>
              <Grid container spacing={3} direction="row" className={styles.topRow}>
                <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
                  <div
                    className={styles.userCheckinCardOuterWrapper}
                    style={{ background: 'rgba(255, 255, 255, 0.4)' }}
                  >
                    <div className={styles.userCheckinContentWrapper}>
                      <div className={styles.tableTitleBlk}>
                        {[0, 1, 2, 3, 4, 5, 6, 7].map((item, key) => (
                          <h6
                            className="heading6"
                            style={{ position: 'relative', margin: '0 0 24px' }}
                          >
                            <Skeleton
                              variant="circle"
                              width={34}
                              height={34}
                              style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                            <Skeleton
                              variant="text"
                              width="75%"
                              height={20}
                              style={{ margin: '0 0 4px 52px', display: 'block' }}
                            />
                            <Skeleton
                              variant="text"
                              width="40%"
                              height={14}
                              style={{ margin: '0 0 8px 52px', display: 'block' }}
                            />
                          </h6>
                        ))}
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                  <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}>
                    {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                      <div>
                        <Grid container direction="row" spacing={3} className={styles.titleRow}>
                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div className={styles.titleWrapper}>
                              <Skeleton
                                variant="circle"
                                width={48}
                                height={48}
                                style={{ position: 'absolute', top: 0, left: 0 }}
                              />
                              <div className={styles.info}>
                                <h2 className="heading5">
                                  <Skeleton
                                    variant="text"
                                    width="45%"
                                    height={16}
                                    style={{ marginLeft: 0 }}
                                  />
                                </h2>
                                <p className="paragraph">
                                  {' '}
                                  <Skeleton variant="text" width="85%" height={16} style={{}} />
                                </p>
                              </div>
                            </div>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Skeleton variant="text" width="45%" height={40} style={{}} />
                          </Grid>
                        </Grid>
                        <div className={styles.TrackingOverviewShimmer}>
                          <Grid container direction="row" className={styles.tableTitleRow}>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className={styles.leftBlkShimmer}
                            >
                              <Skeleton
                                variant="text"
                                width="20%"
                                height={24}
                                style={{ marginLeft: 20, marginTop: 14 }}
                              />
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                              <List className="listInline">
                                {' '}
                                {/* add class active for active month */}
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="70%"
                                    height={24}
                                    style={{ marginLeft: 50 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                          {[0, 1].map((key, item) => (
                            <div className={styles.cardItem}>
                              <Grid container direction="row" className={styles.contentRow}>
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  className={styles.leftBlkShimmer + ' flexNotCentered'}
                                >
                                  <div className={styles.titleBlkShimmer}>
                                    <Skeleton
                                      variant="circle"
                                      width={36}
                                      height={36}
                                      style={{ position: 'absolute', top: 23, left: 20 }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="45%"
                                      height={24}
                                      style={{ marginLeft: 80, marginTop: 30 }}
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  lg={9}
                                  md={9}
                                  sm={12}
                                  xs={12}
                                  className={styles.rightBlkShimmer + ' flexNotCentered'}
                                >
                                  <List className="listInline" style={{ width: '100%' }}>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="50%"
                                        height={24}
                                        style={{ marginLeft: 75, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        height={24}
                                        style={{ marginLeft: 87 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                    <ListItem className={styles.TrackingOverviewShimmerList}>
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90, marginBottom: 20 }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="30%"
                                        height={24}
                                        style={{ marginLeft: 90 }}
                                      />
                                    </ListItem>
                                  </List>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <Grid container direction="row" spacing={3} className={styles.titleRow}>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div className={styles.titleWrapper}>
                          <Skeleton
                            variant="circle"
                            width={48}
                            height={48}
                            style={{ position: 'absolute', top: 0, left: 0 }}
                          />
                          <div className={styles.info}>
                            <h2 className="heading5">
                              <Skeleton
                                variant="text"
                                width="45%"
                                height={16}
                                style={{ marginLeft: 0 }}
                              />
                            </h2>
                            <p className="paragraph">
                              {' '}
                              <Skeleton variant="text" width="85%" height={16} style={{}} />
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Skeleton variant="text" width="45%" height={40} style={{}} />
                      </Grid>
                    </Grid>
                    <div className={styles.TrackingOverviewShimmer}>
                      <Grid container direction="row" className={styles.tableTitleRow}>
                        <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlkShimmer}>
                          <Skeleton
                            variant="text"
                            width="20%"
                            height={24}
                            style={{ marginLeft: 20, marginTop: 14 }}
                          />
                        </Grid>
                        <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
                          <List className="listInline">
                            {' '}
                            {/* add class active for active month */}
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="70%"
                                height={24}
                                style={{ marginLeft: 50 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                            <ListItem className={styles.TrackingOverviewShimmerList}>
                              <Skeleton
                                variant="text"
                                width="30%"
                                height={24}
                                style={{ marginLeft: 90 }}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                      {[0, 1, 2, 3].map((key, item) => (
                        <div className={styles.cardItem}>
                          <Grid container direction="row" className={styles.contentRow}>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className={styles.leftBlkShimmer + ' flexNotCentered'}
                            >
                              <div
                                className={styles.titleBlkShimmer}
                                style={{ background: '#EAF9F4' }}
                              >
                                <Skeleton
                                  variant="circle"
                                  width={36}
                                  height={36}
                                  style={{ position: 'absolute', top: 23, left: 20 }}
                                />
                                <Skeleton
                                  variant="text"
                                  width="45%"
                                  height={24}
                                  style={{ marginLeft: 80, marginTop: 30 }}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item
                              lg={9}
                              md={9}
                              sm={12}
                              xs={12}
                              className={styles.rightBlkShimmer + ' flexNotCentered'}
                            >
                              <List className="listInline" style={{ width: '100%' }}>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="50%"
                                    height={24}
                                    style={{ marginLeft: 75, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="40%"
                                    height={24}
                                    style={{ marginLeft: 87 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                                <ListItem className={styles.TrackingOverviewShimmerList}>
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90, marginBottom: 20 }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={24}
                                    style={{ marginLeft: 90 }}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </>
      )}
      {props.type == 'CheckinDrawer' && (
        <div className={styles.checkinCustomInputWrapper}>
          <Grid container direction="row" spacing={3}>
            {[0, 1, 2, 3, 4, 5].map((item, key) => (
              <Grid key={key} item xs={6}>
                <div className={styles.checkinDrawerInput}>
                  <label className={styles.checkinLabel + ' supportText '}>
                    {' '}
                    <Skeleton variant="text" width="40%" height={24} style={{}} />
                  </label>
                  <div
                    className={styles.checkinInputBlk}
                    style={{ background: 'rgba(0, 0, 0, 0.11)', width: '100%', height: '44px' }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={12} className="flexNotCentered">
                        <div
                          className={styles.checkinDrawerCustomInput}
                          style={{ width: '100%' }}
                        ></div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    className={styles.checkinDrawerProgressWrapper}
                    style={{ position: 'relative' }}
                  >
                    <Skeleton
                      variant="text"
                      width="80%"
                      height={20}
                      style={{ borderRadius: '8px', position: 'absolute' }}
                    />
                    <Skeleton
                      variant="text"
                      width="15%"
                      height={20}
                      style={{ borderRadius: '8px', marginLeft: '85%' }}
                    />
                  </div>
                </div>
              </Grid>
            ))}
            <Grid item xs={12}>
              <div className={styles.checkinDrawerInput}>
                <label className={styles.checkinLabel + ' supportText '}>
                  {' '}
                  <Skeleton variant="text" width="40%" height={24} style={{}} />
                </label>
                <Skeleton
                  variant="text"
                  width="100%"
                  height={20}
                  style={{ height: '160px', marginTop: '-32px' }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.checkinDrawerInput} style={{ marginTop: '-32px' }}>
                <label className={styles.checkinLabel + ' supportText '}>
                  {' '}
                  <Skeleton variant="text" width="40%" height={24} style={{}} />
                </label>
                <Skeleton
                  variant="text"
                  width="100%"
                  height={20}
                  style={{ height: '160px', marginTop: '-32px' }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

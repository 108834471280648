import { useEffect } from 'react';

import { useGetPostsQuery } from 'features/stream/Stream.queries';
import { useStreamStore } from 'features/stream/Stream.store';
import { FeatureFlag } from 'controllers/_exports';

const FilterPaginationControllerLogic = ({ user, category }) => {
  const pagination = useStreamStore((state) => state?.pagination);
  const selectedDateFilterPulse = useStreamStore((state) => state?.selectedDateFilterPulse);

  const { data, isLoading, fetchNextPage, isFetching } = useGetPostsQuery({
    user,
    category,
    paginationData: pagination,
    time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilterPulse.slug : 'all-time',
    onSettled: () => setLoadingNewPost(false),
  });

  const setLoadingNewPost = useStreamStore((s) => s?.setLoadingNewPost);
  const setPosts = useStreamStore((state) => state?.setLatestPosts);
  const posts = useStreamStore((state) => state?.latestPosts);

  useEffect(() => {
    setPosts((data?.pages || []).flat());
  }, [data, setPosts]);

  const nextPage = () => !isFetching && fetchNextPage();

  const showLoader = pagination?.number_of_pages > pagination?.current_page && posts?.length > 0;

  // If filters are different than default, hasFilters should be true
  const setHasFilters = useStreamStore((s) => s?.setHasFilters);
  useEffect(() => {
    const hasFilters = user !== 'all' || !!category;
    setHasFilters(hasFilters);
  }, [user, category, setHasFilters]);

  return { isLoading, nextPage, showLoader, isFetching };
};

export default FilterPaginationControllerLogic;

import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';
import { Grid, Avatar } from '@material-ui/core';

export default function UserDetailsHeader(props) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    ? JSON.parse(localStorage.getItem('userInfo')).user
    : '';
  return (
    <div className={styles.userDetailsHeader}>
      <Grid container alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={styles.userInfoWrapper + ' textCenter'}>
            <div className={styles.userImgBlk}>
              {userInfo && userInfo.profile_pic ? (
                <img className="profilePic" src={userInfo.profile_pic} alt="Profile pic" />
              ) : (
                <Avatar className={`avatar ${styles.userImg}`}>
                  {' '}
                  {userInfo.first_name
                    ? userInfo.first_name.substring(0, 1)
                    : userInfo.email
                    ? userInfo.email.substring(0, 1)
                    : ''}
                </Avatar>
              )}
            </div>
            <div className={styles.contentBlk}>
              <h3 className="heading4">
                {userInfo.first_name || userInfo.last_name
                  ? `${userInfo.first_name ? userInfo.first_name : userInfo.last_name} ${
                      userInfo.first_name && userInfo.last_name ? userInfo.last_name : ''
                    }`
                  : ''}
              </h3>
              <p className="supportTextSemibold">
                <img src={`${imageBasePath}designation_icon.svg`} />
                <span>{userInfo.designation ? userInfo.designation : '--'} </span>
              </p>
              <p className="supportTextSemibold">
                <img src={`${imageBasePath}email_icon.svg`} />
                <span>{userInfo.email}</span>
              </p>
            </div>
          </div>
        </Grid>
        {/* <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div className={styles.goalAchievementWrapper}>
                        <p className="supportText">Monthly avg  goal achievement</p>
                        <h3 className="heading3">0%</h3>
                    </div>
                </Grid> */}
      </Grid>
    </div>
  );
}

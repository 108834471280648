import React from 'react';
import StreamContainer from '../UI/StreamContainer/StreamContainer';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import { Redirect } from 'react-router-dom';

const Stream = () => {
  if (
    !FeatureFlag.enabled('STREAM') ||
    !Subscription.allow({ key: Subscription.keys.stream, openModal: false })
  )
    return <Redirect to="/" />;
  return (
    <div style={{ paddingBottom: '2rem' }}>
      <StreamContainer />
    </div>
  );
};

export default Stream;

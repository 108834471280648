import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import React, { useState, useEffect } from 'react';
import styles from './RoleplayView.module.scss';
const RoleplayViewDescriptionBody = () => {
  const scenarioSelected = useRoleplayStore((state) => state?.scenarioSelected);
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [descriptionData, setDescriptionData] = useState({});
  useEffect(() => {
    setDescriptionData(scenarioSelected);
  }, [scenarioSelected]);
  return (
    <div className={styles.descriptionBody}>
      <div className={styles.descriptionBodyTitle}>
        <p className="heading4">How to pass roleplay</p>
        {scenarioSelected?.example_video !== null && (
          <p className={styles.link}>Roleplay Example</p>
        )}
      </div>
      <p className="supportText">{descriptionData?.description || roleplayView?.role_play.description}</p>
    </div>
  );
};

export default RoleplayViewDescriptionBody;

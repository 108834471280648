import React from 'react';
import { usePathValidations } from '../../LearningPaths.utils';
import { useLearningPathsStore } from '../../LearningPaths.store';
import DottedBtn from 'shared_ui_components/DottedBtn/DottedBtn';

const AddContentBtn = ({ label = 'Add content', onClick = () => null }) => {
  const { learningPath } = useLearningPathsStore();
  const { isPathOwner } = usePathValidations({ learningPath });

  if (!isPathOwner) return null;
  return <DottedBtn onClick={onClick} showPlusSign label={label} />;
};

export default AddContentBtn;

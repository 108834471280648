import { FeatureFlag } from 'controllers/_exports';
import { API } from 'utils/globalApiServices';

// Roleplays dashboard

export const getRoleplaysToCompleteService = async () => {
  const url = 'roleplay/to-complete';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const getRoleplayProgressService = async ({ home, time = 'all-time' }) => {
  const params = {};
  if (home) params.home = 1;
  if (FeatureFlag.enabled('ROLEPLAY_FILTERS')) params.time_lapse = time;
  const url = 'roleplay/progress';
  const { data } = await API.get(url, { params });
  return data;
};

export const getRoleplaysToReviewService = async () => {
  const url = 'roleplay/to-review';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const getRoleplayHistoryService = async ({ time = 'all-time' }) => {
  const url = 'roleplay/history';
  const params = {};
  params.time_lapse = time;
  const { data } = await API.get(url, { params });
  return data?.list || [];
};

export const getRoleplayTeamService = async ({ time = 'all-time' }) => {
  const list = [];
  let page = 1;
  let totalPages = 1;
  const params = {};
  params.time_lapse = time;
  do {
    const url = `roleplay/my-team?page=${page}&time_lapse=${time}`;
    const { data } = await API.get(url);
    totalPages = data?.pagination?.number_of_pages;
    list.push(...data?.list);
    page++;
  } while (page <= totalPages);

  return list;
};

export const getListUsersService = async () => {
  const url = 'roleplays/list/users/';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const getListRoleplaysService = async () => {
  const url = 'roleplays/organisation/roleplay/';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const getListScenariosPerRoleplayService = async ({ roleplayUuid = '' }) => {
  const url = `roleplays/list/${roleplayUuid}/scenarios/`;
  const { data } = await API.get(url);
  return data?.list || [];
};

export const createAssignRoleplay = async ({ roleplay = {} }) => {
  const url = `roleplays/assign/`;
  const data = await API.post(url, { ...roleplay });
  return data;
};

export const createSelfAssignRoleplay = async ({ roleplay = {} }) => {
  const url = `roleplays/self-assign/`;
  const data = await API.post(url, { ...roleplay });
  return data;
};

export const getRoleplayview = async ({ roleplay_id }) => {
  const url = `/roleplay/view-assign/${roleplay_id}/`;
  const { data } = await API.get(url);
  return data;
};

// Recording
export const uploadRecordingService = async ({ file }) => {
  const body = new FormData();
  body.append('file', file);

  const url = `/file/upload/`;
  const { data } = await API.post(url, body);
  return data;
};

export const updateRecordingInScenarioService = async ({ url_video, response_uuid }) => {
  const url = `/roleplay/${response_uuid}/scenario/update/`;
  const data = await API.post(url, { url_video });
  return data;
};

//submit roleplay
export const submitRoleplay = async ({ roleplay_id }) => {
  const url = `/roleplay/${roleplay_id}/submit/`;
  const data = await API.post(url);
  return data;
};

export const reviewScenario = async ({ roleplay, scenario, body }) => {
  const url = `roleplay/${roleplay}/scenario/${scenario}/review/`;
  const data = await API.post(url, body);
  return data;
};

export const submitReview = async ({ roleplay_id }) => {
  const url = `roleplay/${roleplay_id}/confirm-status/`;
  const data = await API.post(url);
  return data;
};

export const unassignRoleplayService = async ({ roleplay_id }) => {
  const url = `/roleplay/${roleplay_id}/unassign/`;
  const data = await API.post(url);
  return data;
};
export const deleteRoleplayService = async ({ roleplay_id }) => {
  const url = `/roleplay/${roleplay_id}/delete/`;
  const data = await API.del(url);
  return data;
};
export const reassignRoleplayService = async ({ roleplay_id }) => {
  const url = `/roleplay/${roleplay_id}/reassigned/`;
  const data = await API.post(url);
  return data;
};

// Home
export const roleplaysToPassService = async () => {
  const url = `roleplays/pass/`;
  const { data } = await API.get(url);
  return data?.list;
};

export const createCustomRoleplay = async ({ roleplay }) => {
  const url = `roleplays/create/custom-roleplay/`;
  const data = await API.post(url, roleplay);
  return data;
};

// Roleplay Users
export const getListUsersRepService = async () => {
  const url = 'roleplays/list/sales-rep/users';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const getListUserRepService = async ({ uuid = '' }) => {
  const url = `roleplays/list/sales-rep/users/?user=${uuid}`;
  const { data } = await API.get(url);
  return data?.list || [];
};

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { ProgressBar } from '../../../shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';

export default function IntroductionCard(props) {
  const { completed, total } = props;
  const [note, setNote] = useState(true);
  const closeNote = () => {
    setNote(false);
  };
  return (
    <>
      {note ? (
        <div className="note-block introduction-note-block">
          <p className="pararaph">
            <img src={imageBasePath + 'info_darkgrey.svg'} alt="info" /> Make sure to publish your
            goals soon, otherwise sales reps won't be able to do check-in
          </p>
          <a onClick={() => closeNote()} className="closeNote">
            <img src={imageBasePath + 'close.svg'} alt="info" />
          </a>
        </div>
      ) : null}
      <section className={styles.trackingIntroSection}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className={styles.titleWrapper}>
              <div className={styles.imgBlk}>
                <img src={imageBasePath + 'tracking_icon.svg'} alt="Image" />
              </div>
              <div className={styles.contentBlk}>
                <h1 className="heading4">Setup Goal Tracking</h1>
                <p className="supportText">
                  Define {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? '' : 'organizational and'}{' '}
                  salesperson goals to keep track of your revenue goals. Helps to better manage your
                  goals and track consistently. No more excuses!
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} className="textRight">
            <div className={styles.stepsWrapper}>
              <Grid container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item lg={9} md={9} sm={12} xs={12} className="textRight">
                  <div className={styles.progressBlk}>
                    <ProgressBar
                      max={100}
                      value={(completed / total) * 100}
                      className={styles.stepProgress + ' stepProgress'}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <div className={styles.stepsBlk}>
                    <h2 className="heading3">
                      <span className={styles.steps + ' heading3'}>{`${completed}/${total}`}</span>
                      <span className={styles.supportText + ' supportText'}> Goals</span>
                    </h2>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <img
          className={styles.patternBg}
          src={imageBasePath + 'onboarding_setup_pattern.svg'}
          alt="Image"
        />
      </section>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  CustomButton,
  CustomTooltipWhite,
  CustomSwitch,
  CustomCheckbox,
} from '../../../shared_ui_components';
import { SearchBar } from '../../../shared_elements';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';

export default function UpdateProviderJobs(props) {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [availableJobs, setAvailableJobs] = useState([]);
  const [searchedJobs, searchedJobsSet] = useState([]);
  const [enableSubmit, enableSubmitSet] = useState(true);
  const [serachText, serachTextSet] = useState('');
  const { currentIntegration, confirmLoaderSet, confirmLoader, fetchJobLoader } = props;

  const handleClose = () => {
    setOpen(false);
    setAvailableJobs([]);
    props.handleClose(false);
  };

  const isSelectedJob = (aJob) => {
    const recruitments = currentIntegration.recruitments;
    const isSelcted = recruitments.filter((rJob) => rJob.job_id == aJob.job_id);
    if (isSelcted && isSelcted.length) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setOpen(props.open);
    setAvailableJobs(
      props.availableJobs.map((ajob) => {
        return { ...ajob, isSelected: true };
      })
    );
    if (props.availableJobs && props.availableJobs.length) {
      if (currentIntegration.recruitments && currentIntegration.recruitments.length) {
        setAvailableJobs(
          props.availableJobs.map((aJob) =>
            isSelectedJob(aJob) ? { ...aJob, isSelected: true } : aJob
          )
        );
      }
    }
  }, [props.open, props.availableJobs]);

  const handleChange = (key, val, obj) => {
    let updatedJobs;
    if (key == 'isSelected') {
      let cJob = availableJobs.find((job) => job.job_id === obj.job_id);
      cJob = {
        ...cJob,
        isSelected: val,
      };
      updatedJobs = availableJobs.map((job) => (job.job_id === cJob.job_id ? cJob : job));
      let supdatedJobs = searchedJobs.map((job) => (job.job_id === cJob.job_id ? cJob : job));
      setAvailableJobs(updatedJobs);
      searchedJobsSet(supdatedJobs);
    }
    let canEnableSubmit = updatedJobs.filter((job) => job.isSelected === true);
    if (canEnableSubmit && canEnableSubmit.length) {
      enableSubmitSet(true);
    } else {
      enableSubmitSet(false);
    }
  };

  const handleSubmit = () => {
    const selectedJobs = availableJobs.filter((job) => job.isSelected === true);
    if (selectedJobs.length) {
      confirmLoaderSet(true);
      props.providerJobUpdate(selectedJobs);
    }
  };

  const handleSearch = (txt) => {
    serachTextSet(txt);
    if (txt) {
      let str = txt.toLowerCase();
      let sjobs = availableJobs.filter(
        (ajob) =>
          ajob.job_title.toLowerCase().includes(str) || ajob.location.toLowerCase().includes(str)
      );
      searchedJobsSet(sjobs);
    } else {
      searchedJobsSet([]);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.greenHouseContinueDialog}`}
      >
        <DialogTitle
          id="add-video-title"
          className={styles.dialogTitle + ' backgroundProp'}
          style={{ backgroundImage: `url(${imageBasePath + 'integration_continue_bg.png'})` }}
        >
          <List className="listInline flexCentered">
            <ListItem className="listInlineItem">
              <img className={styles.logo} src={currentIntegration.logo} alt="GreenHouse" />
            </ListItem>
            {currentIntegration.status !== 0 ? (
              <ListItem className="listInlineItem">
                <p className={styles.switchBlk + ' flexCentered'}>
                  <CustomSwitch
                    checked={currentIntegration.status === 1 ? true : false}
                    onChange={() => props.enableDisable(currentIntegration)}
                  />
                  <span className="supportText">
                    {currentIntegration.status === 1 ? 'Enabled' : 'Disabled'}
                  </span>
                </p>
              </ListItem>
            ) : null}
          </List>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className={`modalCloseIcon ${confirmLoader ? 'disabled' : ''}`}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          {fetchJobLoader ? (
            <div className={styles.dialogBody}>
              <p className="paragraph textCenter">Please wait...</p>
            </div>
          ) : availableJobs && availableJobs.length ? (
            <div className={styles.dialogBody}>
              {currentIntegration.status !== 1 ? (
                <h1 className="heading2 textCenter">
                  Congratulations! You have successfully Connected!
                </h1>
              ) : null}
              <p className="paragraph textCenter">Select the job you want to track in SalesTable</p>
              {currentIntegration.status === 1 ? (
                <SearchBar handleSearch={handleSearch} placeholder="Search job" />
              ) : null}
              <div className={styles.jobsList}>
                {(searchedJobs && searchedJobs.length) || serachText ? (
                  <>
                    {searchedJobs.length ? (
                      <>
                        {searchedJobs.map((job, index) => (
                          <div
                            key={index}
                            className={styles.jobItem}
                            onClick={() => handleChange('isSelected', !job.isSelected, job)}
                          >
                            <div className={styles.jobTitleBlk}>
                              <img
                                className={styles.jobIcon}
                                src={imageBasePath + 'job_icon.svg'}
                                alt="Image"
                              />
                              <div className={styles.info}>
                                <h3 className="heading6">{job.job_title}</h3>
                                <p className="supportText">{job.location}</p>
                              </div>
                            </div>
                            <div className={styles.checkBoxBlk}>
                              <CustomCheckbox
                                value={job.isSelected ? true : false}
                                onChange={(e) => console.log('click')}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="paragraph textCenter">
                        No result avilable for `<strong>{serachText}</strong>`
                      </p>
                    )}
                  </>
                ) : (
                  availableJobs.map((job, index) => (
                    <div
                      key={index}
                      className={styles.jobItem}
                      onClick={() => handleChange('isSelected', !job.isSelected, job)}
                    >
                      <div className={styles.jobTitleBlk}>
                        <img
                          className={styles.jobIcon}
                          src={imageBasePath + 'job_icon.svg'}
                          alt="Image"
                        />
                        <div className={styles.info}>
                          <h3 className="heading6">{job.job_title}</h3>
                          <p className="supportText">{job.location}</p>
                        </div>
                      </div>
                      <div className={styles.checkBoxBlk}>
                        <CustomCheckbox
                          value={job.isSelected ? true : false}
                          onChange={(e) => console.log('click')}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            <p className="paragraph textCenter">Jobs not available</p>
          )}
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn ${confirmLoader ? 'disabled' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`${
              !enableSubmit || confirmLoader || fetchJobLoader ? 'disabledBtn' : ''
            } primaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            {confirmLoader ? 'Please wait...' : 'Confirm'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

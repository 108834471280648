import { imageBasePath } from '../constants';
import { CustomNumberFormatter } from '../utils';

export default function CustomDeltaValue(props) {
  const { value } = props;
  return (
    <span className={`${value >= 0 ? `rise` : `fall`} supportTextSemibold customDeltaValue`}>
      {value >= 0 ? (
        <img src={imageBasePath + 'rise.svg'} alt="fall" />
      ) : (
        <img src={imageBasePath + 'fall.svg'} alt="rise" />
      )}
      <span>
        {value > 0
          ? `+${CustomNumberFormatter(value)}`
          : `-${CustomNumberFormatter(Math.abs(value))}`}
      </span>
    </span>
  );
}

import { FeatureFlag } from 'controllers/_exports';
import { useHubspotStore } from 'features/hubspot/Hubspot.store';
import HubspotUtils from 'features/hubspot/Hubspot.utils';
import Organization from 'infrastructure/Organization';

const { create } = require('zustand');

export const useTrackingStore = create((set, get) => ({
  activityGoalsData: {},
  organizationGoalsData: {},
  activityGoals: [],
  hubSpotGoalsData: {},
  hubSpotGoals: [],
  setActivityGoalsData: (activityGoalsData) =>
    set(() => ({
      activityGoalsData,
      activityGoals: activityGoalsData?.goals?.map((g) => ({ ...g, isActivityGoal: true })),
    })),
  setGoalsData: (data) =>
    set(() => ({
      activityGoalsData: data?.categories?.find((c) => !c?.is_organisation),
      organizationGoalsData: data?.categories?.find((c) => c?.is_organisation),
      activityGoals: data?.categories
        ?.find((c) => !c?.is_organisation)
        ?.goals?.map((g) => ({ ...g, isActivityGoal: true })),
    })),
  setHubSpotGoalsData: (data) =>
    set(() => ({
      hubSpotGoalsData: data,
      hubSpotGoals: [...data?.goals.map((g) => ({ ...g, isFromHubspot: true }))],
    })),
  all_goals_order: [],
  orderError: false,
  setOrder: (newOrderData = {}) => {
    return set(() => ({ all_goals_order: newOrderData?.all_goals || newOrderData?.active_goals }));
  },
  setAllGoalsOrder: (newUuids = []) => {
    return set(() => ({ all_goals_order: newUuids }));
  },
  getOrderedGoals: () => {
    return getOrderedList({
      order: get()?.all_goals_order,
      hubspotGoals: get()?.hubSpotGoals,
      activityGoals: get()?.activityGoals,
    });
  },
  // Overview
  hubspotTrackingData: {},
  setHubspotTracking: (hubspotTrackingData) => set(() => ({ hubspotTrackingData })),
  getTrackingGoals: (activityGoals) => {
    return getOrderedList({
      order: get()?.all_goals_order,
      hubspotGoals: get()?.hubspotTrackingData?.goals,
      activityGoals,
    });
  },
}));

const getOrderedList = ({ order = [], hubspotGoals = [], activityGoals = [] }) => {
  let goals = [...activityGoals];

  const isHubspotEnabled = Organization.isRep()
    ? HubspotUtils?.isIntegrated()
    : useHubspotStore?.getState().isEnabled;

  if (isHubspotEnabled)
    goals = [...goals, ...hubspotGoals.map((g) => ({ ...g, isFromHubspot: true }))];

  if (!order?.length > 0) return goals;

  if (!FeatureFlag.enabled('SWAP_SALES_GOALS')) return goals;
  const ordered = order
    ?.map((uuid) => goals.find((goal) => goal?.uuid === uuid))
    ?.filter((goal) => !!goal);

  return ordered;
};

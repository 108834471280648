import React from 'react';
import './RecordingOptions.scss';

const RecordingOptions = ({
  onScreenAndCamera = () => null,
  onScreenOnly = () => null,
  onWebcamOnly = () => null,
}) => {
  const handleChange = (e) => {
    const option = Number.parseInt(e.target.value);
    
    if (option === 0) return onScreenAndCamera();
    else if (option === 1) return onScreenOnly();
    else if (option === 2) return onWebcamOnly();
  };

  return (
    <select onChange={handleChange} className="recording-options-container">
      <option value={0}>Screen and camera</option>
      <option value={1}>Screen only</option>
      <option value={2}>Webcam only</option>
    </select>
  );
};

export default RecordingOptions;

import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './RecordVideo.module.css';
import { CustomButton } from 'shared_ui_components';
import Recorder from './Recorder';
import ContinueButtons from './ContinueButtons';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayAttempts from './RoleplayAttempts';

const RecordVideo = () => {
  // Retake handlers
  const [attempt, setAttempt] = useState(1);
  const showRetake = useRoleplayStore((state) => state?.showRetake);

  // Scenario data
  const roleplayData = useRoleplayStore((state) => state?.roleplayView);
  const scenarioSelected = useRoleplayStore((state) => state?.scenarioSelected);

  const scenarios = useMemo(() => roleplayData?.scenarios, [roleplayData]);
  const videoURL = useMemo(
    () =>
      attempt === 1 ? scenarioSelected?.response?.video : scenarioSelected?.response_retake?.video,
    [scenarioSelected, attempt]
  );

  const [wantsToRecord, setWantsToRecord] = useState(false);
  const onCancel = () => setWantsToRecord(false) && setAttempt(1);
  const onStart = () => setWantsToRecord(true);

  useEffect(() => {
    setWantsToRecord(false);
    setAttempt(1);
  }, [scenarioSelected]);

  const showReRecord = useMemo(() => {
    if (showRetake && attempt === 1) return false;
    return !!videoURL;
  }, [attempt, showRetake, videoURL]);

  if (wantsToRecord)
    return (
      <div>
        <Recorder
          onStart={onStart}
          onCancelRecord={onCancel}
          scenarioData={scenarioSelected}
          scenarios={scenarios}
        />
      </div>
    );

  return (
    <div className={`${styles.recorderWrapper} ${showRetake ? styles.recorderWrapperRetake : ''}`}>
      {showRetake && <RoleplayAttempts attempt={attempt} setAttempt={setAttempt} />}
      <div className={styles.videoContainer}>
        {videoURL ? (
          <ReactPlayer url={videoURL} controls width="100%" height="100%" />
        ) : (
          <CustomButton onClick={onStart} className="primaryBtn">
            Record video max 5 min
          </CustomButton>
        )}
      </div>
      <ContinueButtons
        showReRecord={showReRecord}
        disabled={!videoURL}
        onReRecordClick={onStart}
        scenarios={scenarios}
        scenarioData={scenarioSelected}
        setAttempt={setAttempt}
        attempt={attempt}
      />
    </div>
  );
};

export default RecordVideo;

import React, { Component, useState } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';
import { CustomButton, CustomSwitch, ExternalLink } from '../../../shared_ui_components';
import { contactSupportApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

export default function RecruitmentOptions(props) {
  const { providers, handleChangeToken, handleConfigure } = props;
  const [loader, setLoader] = useState(false);

  const handleContactSupport = () => {
    setLoader(true);
    contactSupportApi().then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  return (
    <section className={styles.recruitmentOptionsSection}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <h1 className="heading4">Configure your recruiting with these options</h1>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3} className={styles.contentRow}>
        {providers.map((job, key) => (
          <Grid key={key} item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
            <div className={styles.cardWrapper}>
              <div className={styles.imgBlk}>
                <img src={job.featured_image} alt={job.name} />
              </div>
              <div className={styles.contentBlk}>
                <h2 className="heading5">
                  {job.name}
                  {job.status === 1 ? (
                    <span className={styles.active + ' supportTextSemibold'}>Active</span>
                  ) : null}
                  {job.status !== 0 ? (
                    <p className={styles.switchBlk + ' flexCentered'}>
                      <CustomSwitch
                        checked={job.status === 1 ? true : false}
                        onChange={() => props.enableDisable(job)}
                      />
                      <span className="supportText">
                        {job.status === 1 ? 'Enabled' : 'Disabled'}
                      </span>
                    </p>
                  ) : null}
                </h2>
                <p className="paragraph">{job.description} </p>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className={styles.btnsRow}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ul className={`listInline ${job.status === 1 ? styles.activeList : ''}`}>
                      <li className="listInlineItem">
                        {job.code === 'jazzhr' ? (
                          // <ExternalLink
                          //   href={`mailto:support@salestable.ai?subject=JazzHR integration request&body=Hello, We wanted to have JazzHR integration enabled under our organization. Please do the needful.`}
                          //   linktext='Contact Support'
                          //   target={'_blank'}
                          //   className={styles.setupIntegrationBtn + ' secondaryBtn smallBtn'}
                          // />
                          <CustomButton
                            className={
                              styles.setupIntegrationBtn +
                              ` ${loader ? 'disabled' : ''} secondaryBtn smallBtn`
                            }
                            onClick={() => {
                              handleContactSupport();
                            }}
                          >
                            {loader ? `Contacting…` : `Contact Support`}
                          </CustomButton>
                        ) : (
                          <CustomButton
                            className={
                              styles.setupIntegrationBtn +
                              ` ${job.status === 1 ? 'primaryBtn' : 'secondaryBtn'} smallBtn`
                            }
                            onClick={
                              job.status >= 1
                                ? (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.handleConfigure(job);
                                    props.currentIntegrationSet(job);
                                  }
                                : (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.handleIntegrateProviders(true, job);
                                    props.currentIntegrationSet(job);
                                  }
                            }
                          >
                            {job.status >= 1 ? 'Configure' : 'Setup Integration'}
                          </CustomButton>
                        )}
                      </li>
                      {job.status === 1 ? (
                        <li className="listInlineItem">
                          <CustomButton
                            className={styles.setupIntegrationBtn + ' secondaryBtn smallBtn'}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              // props.handleIntegrateProviders(true, job);
                              // props.currentIntegrationSet(job);
                              handleChangeToken(job);
                            }}
                          >
                            Change Token
                          </CustomButton>
                        </li>
                      ) : null}
                    </ul>
                  </Grid>
                  {/* {
                      job.status !== 0 ?
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <p className={styles.switchBlk + ' flexCentered'}>
                            <CustomSwitch
                              checked={job.status === 1 ? true : false}
                            // onChange=""
                            />
                            <span className="supportText">Enabled</span>
                          </p>
                        </Grid>
                        : null
                    } */}
                </Grid>
              </div>
            </div>
          </Grid>
        ))}
        <Grid item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
          <div className={styles.cardWrapper}>
            <div className={styles.imgBlk}>
              <img
                className={styles.addManuallyImg}
                src={imageBasePath + 'add_manual_job_featured_full.svg	'}
                alt="JazzHR"
              />
            </div>
            <div className={styles.contentBlk}>
              <h2 className="heading5">Add Manually</h2>
              <p className="paragraph">
                We understand that sometimes you may be manually tracking. Here is a simple way for
                you to track your recruiting
              </p>
              <CustomButton
                className={styles.setupIntegrationBtn + ' secondaryBtn smallBtn'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.addJobManuallySet(true);
                }}
              >
                Add Job to track
              </CustomButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}

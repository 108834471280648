import React, { useState, useEffect } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';

const DropdownListScenarios = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(
      roleplayView?.scenarios.map((scenario, index) => {
        return {
          ...scenario,
          label: `${index + 1}. ${scenario?.name}`,
          name: scenario?.name,
          value: scenario?.uuid,
        };
      })
    );
  }, [roleplayView]);

  const handleChange = (value) => {
    setReviewScenarioSelected(value)
    hideSecondAttempt();
  };

  return (
    <InputSelect
      onChange={(value) => handleChange(value)}
      options={options}
      value={reviewScenarioSelected}
      closeMenuOnSelect={true}
      isSearchable={false}
    />
  );
};

export default DropdownListScenarios;

import React from 'react';

import shrink from '../../../assets/recorder/shrink.svg';
import expand from '../../../assets/recorder/expand.svg';
import './ExpandButton.scss';

const ExpandButton = ({ isExpanded, onClick = () => null }) => {
  return (
    <div className="expand_button" onClick={onClick}>
      <img alt={isExpanded ? 'minimize' : 'expand'} src={isExpanded ? shrink : expand} />
    </div>
  );
};

export default ExpandButton;

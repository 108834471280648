import { useEffect } from 'react';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';
import User from 'infrastructure/User';

const IntercomIntegration = () => {
  useEffect(() => {
    if (!FeatureFlag.enabled('INTERCOM')) return;
    const { first_name, email, last_name } = Organization.getUserInfo() || {};
    if (!email) return window.Intercom('shutdown');
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'jcejbg4d',
      name: User.getUserName({ first_name, last_name, email }),
      email,
    });
    return () => {
      window.Intercom('hide');
    };
  }, []);

  return null;
};

export default IntercomIntegration;

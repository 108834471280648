import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../../../assets/styles/recruitment.module.scss';
import { InputText, CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { updateJobManually } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';

export default function CheckinDrawer(props) {
  const { open, locale } = props;
  const [loader, setLoader] = useState(false);
  const [job, setJob] = useState({});
  const [state, setState] = useState({ right: open });
  const [formData, formDataSet] = useState({});
  const [error, errorSet] = useState({});
  const [errorCode, errorCodeSet] = useState({
    location: {
      0: '',
      1: 'Please enter location',
    },
    locationObj: {
      requiredFlag: true,
    },
    total_application: {
      0: '',
      1: 'Please enter total applications',
    },
    total_applicationObj: {
      requiredFlag: true,
    },
    interviews_conducted: {
      0: '',
      1: 'Please enter interviews conducted',
    },
    interviews_conductedObj: {
      requiredFlag: true,
    },
    offers_made: {
      0: '',
      1: 'Please enter offers made',
    },
    offers_madeObj: {
      requiredFlag: true,
    },
    accepted_candidates: {
      0: '',
      1: 'Please enter total candidates accepted',
    },
    accepted_candidatesObj: {
      requiredFlag: true,
    },
  });

  useEffect(() => {
    setState({ ...state, ['right']: open });
    if (open) {
      errorSet({});
      setJob(props.job);
      formDataSet({
        ...formData,
        total_application: props.job.total_application,
        interviews_conducted: props.job.interviews_conducted,
        offers_made: props.job.offers_made,
        accepted_candidates: props.job.accepted_candidates,
      });
    }
  }, [open]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) {
      props.handleClose();
    }
    setState({ ...state, [anchor]: open });
  };

  const handleChange = (key, value) => {
    formDataSet({
      ...formData,
      [key]: value,
    });
    errorSet({
      ...error,
      [key]: '',
    });
  };

  const hanldeSubmit = () => {
    setLoader(true);
    const validateNewInput = {
      total_application:
        errorCode['total_application'][
          fieldValidation({
            ...errorCode['total_applicationObj'],
            fieldval: formData.total_application.toString(),
          })
        ],
      interviews_conducted:
        errorCode['interviews_conducted'][
          fieldValidation({
            ...errorCode['interviews_conductedObj'],
            fieldval: formData.interviews_conducted.toString(),
          })
        ],
      offers_made:
        errorCode['offers_made'][
          fieldValidation({
            ...errorCode['offers_madeObj'],
            fieldval: formData.offers_made.toString(),
          })
        ],
      accepted_candidates:
        errorCode['accepted_candidates'][
          fieldValidation({
            ...errorCode['accepted_candidatesObj'],
            fieldval: formData.accepted_candidates.toString(),
          })
        ],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      updateJobManually(job.uuid, formData).then((response) => {
        setLoader(false);
        if (response.statusCode === 200) {
          props.handleClose();
          props.updateManualJob('update', response.data);
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      setLoader(false);
      errorSet(validateNewInput);
    }
  };

  return (
    <>
      <div>
        {['right'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              className={styles.checkinDrawer + ' checkinDrawer'}
            >
              <div className={styles.checkinWrapper}>
                <div className={styles.topWrapper}>
                  <h1 className="heading5">
                    <img
                      onClick={() => props.handleClose()}
                      src={imageBasePath + 'close.svg'}
                      alt="close"
                    />
                    <span>Check-in Status</span>
                  </h1>
                </div>
                <div className={styles.bodyWrapper}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.infoRow}
                  >
                    <Grid item xs={12}>
                      <div className="inputGroup">
                        <p className="supportText">Job Title</p>
                        <h3 className="heading6">{job.job_title}</h3>
                      </div>
                      <div className="inputGroup">
                        <p className="supportText">Location</p>
                        <h3 className="heading6">{job.location}</h3>
                      </div>
                    </Grid>
                  </Grid>
                  <h2 className="heading5">Application Status</h2>
                  <Grid container direction="row" spacing={2} className={styles.fieldsRow}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="total-application"
                        label="Total Applications"
                        placeholder="0"
                        type="text"
                        value={formData.total_application}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('total_application', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('total_application', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.total_application}
                        className={error.total_application ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="interviews-conducted"
                        label="Interviews Conducted"
                        placeholder="0"
                        type="text"
                        value={formData.interviews_conducted}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('interviews_conducted', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('interviews_conducted', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.interviews_conducted}
                        className={error.interviews_conducted ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="offers-made"
                        label="Offers Made"
                        placeholder="0"
                        type="text"
                        value={formData.offers_made}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('offers_made', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('offers_made', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.offers_made}
                        className={error.offers_made ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="candidated-accepted"
                        label="Candidates Accepted"
                        placeholder="0"
                        type="text"
                        value={formData.accepted_candidates}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('accepted_candidates', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('accepted_candidates', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.accepted_candidates}
                        className={error.accepted_candidates ? 'errorGroup' : ' '}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.bottomWrapper}>
                  <List className="listInline">
                    <ListItem className="listInlineItem">
                      <CustomButton className={`tertiaryBtn`} onClick={() => props.handleClose()}>
                        Cancel
                      </CustomButton>
                    </ListItem>
                    <ListItem className="listInlineItem">
                      <CustomButton
                        className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          hanldeSubmit();
                        }}
                      >
                        {loader ? 'Updating..' : 'Update'}
                      </CustomButton>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

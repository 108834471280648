import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory, useParams } from 'react-router';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import queryString from 'query-string';
import { CustomButton } from '../../../shared_ui_components';

export default function ViewFeedbackCard(props) {
  const { loader, status } = props;
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const query = queryString.parse(search);
    if (query.assessment) {
      props.handleAssesmentFeedback(true);
      history.push({ search: '' });
    }
  }, [search]);

  return (
    <>
      <div className={styles.assesmentPendingWrapper + ' ' + styles.viewFeedbackCardWrapper}>
        <Grid container direction="row" alignItems="center" className={styles.titleRow}>
          <Grid item lg={7} md={8} sm={12} xs={12}>
            <div className={styles.titleBlk}>
              <div className={styles.imgBlk}>
                <img src={imageBasePath + 'assesment_result.png'} alt="Image" />
              </div>
              <div className={styles.contentBlk}>
                <h1 className="heading4">
                  <span>Training assessment</span>
                </h1>
                <p className="paragraph">
                  {[1, 3].includes(status)
                    ? `You'll receive assessment feedback after its been reviewed`
                    : `We believe feedback is important part of learning. You will receive feedback based on your assessment`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={4} sm={12} xs={12} className="textRight">
            <div>
              <ul className="listInline">
                {status === 2 ? (
                  <li className="listInlineItem">
                    <CustomButton
                      className={`${styles.startBtn} secondaryBtn ${loader ? 'disabledBtn' : ''}`}
                      onClick={() => {
                        props.handleTakeAssessment(true);
                      }}
                    >
                      Retake Assessment
                    </CustomButton>
                  </li>
                ) : null}
                <li className="listInlineItem">
                  <CustomButton
                    className={`${styles.startBtn} primaryBtn ${
                      loader || [1, 3].includes(status) ? 'disabledBtn' : ''
                    }`}
                    onClick={() => {
                      status == 2
                        ? props.handleAssesmentFeedback(true)
                        : props.handleTakeAssessment(true);
                    }}
                  >
                    {[1, 3].includes(status)
                      ? `In Review`
                      : status == 2
                      ? `View Feedback`
                      : `Take Assessment`}
                  </CustomButton>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React, { useMemo, useState } from 'react';
import { CustomButton, CustomTooltipWhite, TextArea } from 'shared_ui_components/index';

import { useCreatePost } from '../../Stream.queries';
import styles from './NewPost.module.scss';
import Picture from '../components/Picture/Picture';
import CategoryFilter from '../Filters/CategoryFilter/CategoryFilter';
import PostTextArea from '../components/PostTextArea/PostTextArea';
import { FeatureFlag } from 'controllers/_exports';
import { useStreamStore } from 'features/stream/Stream.store';
import Dropzone from 'react-dropzone-uploader';
import { LinearProgress } from '@material-ui/core';
import { bytesToSize, getContentTypeImgPulse, getContentTypeURL } from 'utils';
import { imageBasePath } from 'constants';

export default function NewPost() {
  const newRTE = useMemo(() => FeatureFlag.enabled('NEW_POST_RTE'), []);
  const [fileURL, setFileURL] = useState('');
  const [removeFunction, setRemoveFunction] = useState(false);
  const getUploadParams = ({ meta, file }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.user) {
      let body = new FormData();
      body.append('file', file, file.name);
      return {
        url: `${process.env.REACT_APP_BASE_URL}/file/upload/`,
        body,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: userInfo.access,
        },
      };
    }
  };
  // Category handlers
  const [categoryId, setCategoryId] = useState('');
  const handleCategoryChange = ({ id }) => setCategoryId(id);

  // Post content
  const [postValue, setPostValue] = useState('');
  const { mutate: createPost, isLoading: loadingPost } = useCreatePost();

  const getPost = useStreamStore((state) => state.getCleanPost);
  const resetPost = useStreamStore((state) => state.resetPost);
  const setLoadingNewPost = useStreamStore((s) => s?.setLoadingNewPost);

  const handleSubmit = () => {
    let post = postValue;
    if (newRTE) post = getPost();

    if (!post?.trim().length > 0) return;
    setLoadingNewPost(true);
    createPost(
      { post, categoryId, additional_file: fileURL },
      {
        onSuccess: () => {
          setPostValue('');
          resetPost();
          setFileURL('');
          setRemoveFunction(true);
        },
      }
    );
  };
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const inputContent = (props) => {
    return (
      <div
        className={styles.FileUploadText}
        style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
      >
        <h6 className="paragraph">Add Attachment</h6>
        <CustomTooltipWhite
          placement="top"
          tooltipIcon="PulseFile.svg"
          title={<p>Add files up to 10 mb max.</p>}
        />
      </div>
      // </div>
    );
  };
  const Preview = ({ fileWithMeta, meta, xhr }) => {
    const { name, percent, status, type } = meta;
    const { cancel, remove, restart } = fileWithMeta;
    const previewUrl = useMemo(() => {
      if (status !== 'done') return '';
      return URL.createObjectURL(fileWithMeta.file);
    }, [fileWithMeta.file, status]);
    return (
      <div className="filePreviewC5omponent">
        <div
          className=""
          style={{ display: 'flex', flexWrap: 'nowrap', maxHeight: '120px', gap: '0.5rem' }}
        >
          <a href={previewUrl} target="_blannk">
            {meta?.type?.includes('image') && previewUrl ? (
              <img
                className="imagePreview"
                src={previewUrl}
                alt="UploadedImage"
                style={{ maxHeight: '80px', width: '80px' }}
              />
            ) : meta?.type?.includes('video') ? (
              <video style={{ width: '80px', height: '80px' }}>
                <source src={previewUrl} />
              </video>
            ) : type ? (
              getContentTypeImgPulse(getContentTypeURL(type).id)
            ) : (
              <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />
            )}
          </a>
          {/* Bottom buttons */}
          <div className="contentWrapper">
            <h6 className="heading6" style={{ marginBottom: '0px' }}>
              {name}
            </h6>
            {status !== 'error_upload' && status !== 'error_file_size' ? (
              <>
                <p
                  className="supportText flexNotCentered"
                  style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}
                >
                  <span>
                    {status !== 'done' ? (
                      'Uploading...'
                    ) : (
                      <span className="uploaded green flexCentered">
                        <img src={imageBasePath + 'green_check_rounded.svg'} alt="" />
                        Uploaded
                      </span>
                    )}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                {/* <LinearProgress
                  className="primary"
                  variant="determinate"
                  value={percent === 100 ? 99 : percent}
                /> */}
              </>
            ) : null}
            {status === 'error_upload' || status === 'error_file_size' ? (
              <>
                <p className="supportText flexNotCentered">
                  <span className="failed">
                    {status === 'error_file_size'
                      ? `Your file size is larger than 10 MB`
                      : 'Failed...'}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                <LinearProgress className="secondary" variant="determinate" value={percent} />
              </>
            ) : null}
            <div style={{ paddingTop: '0.5rem' }}>
              <CustomTooltipWhite
                placement="top"
                tooltipIcon="minus_circle.svg"
                title={<div>Remove</div>}
                onClick={remove}
              />
            </div>
          </div>
        </div>
        {removeFunction ? (
          <>
            {remove()}
            {setRemoveFunction(false)}
          </>
        ) : null}
      </div>
    );
  };
  const handleChangeStatus = ({ file, xhr }, status) => {
    if (status === 'done') {
      let newFiles = {
        name: file.name,
        ...JSON.parse(xhr.response),
      };
      setFileURL(newFiles.data.url);
    }
  };
  return (
    <div className={styles.postContainer}>
      <Picture
        profile_pic={userInfo.user.profile_pic}
        email={userInfo.user.email}
        first_name={userInfo.user.first_name}
      />

      <div className={styles.textContainer}>
        {newRTE ? (
          <PostTextArea isNewPost />
        ) : (
          <TextArea
            id="postContent"
            placeholder="Empower Our Sales Community: Share Your Insights, Tips, and Success Stories Here!"
            className={styles.textInput}
            rows="3"
            onChange={(e) => setPostValue(e.target.value)}
            value={postValue}
          />
        )}
        <div className={styles.postBottom}>
          {FeatureFlag.enabled('JUNE_REQUIREMENTS') && (
            <Dropzone
              styles={{
                dropzone: { minHeight: 40, maxHeight: '700px', width: 250, height: 'auto' },
              }}
              getUploadParams={getUploadParams}
              accept={'*'}
              inputContent={inputContent}
              maxFiles={1}
              PreviewComponent={Preview}
              maxSizeBytes={10 * 1024 * 1024}
              onChangeStatus={handleChangeStatus}
            />
          )}
          <CategoryFilter label="Share as" onChange={handleCategoryChange} isFilter={false} />
          <CustomButton
            style={{ padding: '0.65rem 1.5rem' }}
            color="secondary"
            onClick={handleSubmit}
            disabled={loadingPost}
          >
            Post
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

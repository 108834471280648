import React, { useMemo, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import inputContent from './InputContent/InputContent';
import { bytesToSize, getContentTypeImg, getContentTypeURL } from '../../utils';
import { imageBasePath } from '../../constants';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function FileUploader(props) {
  const { acceptedFiles, setAcceptedFiles, otherInputSet, otherInput, replaceFile, setReplaceFile } = props;
  let [loaders, setLoaders] = useState([]);
  const [statusFile, setStatusFile] = useState('');
  // specify upload params and url for your files
  const getUploadParams = ({ meta, file }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.user) {
      let body = new FormData();
      body.append('file', file, file.name);
      return {
        url: `${process.env.REACT_APP_BASE_URL}/file/upload/v2/`,
        body,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: userInfo.access,
        },
      };
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    setStatusFile(status);
    if (status === 'getting_upload_params') {
      setLoaders([...loaders, loaders.length + 1]);
    }
    if (status === 'removed') {
      if (!acceptedFiles.filter((acceptedFile) => acceptedFile.name === file.name).length) {
        setLoaders(loaders.filter((loader) => loader !== loaders.length));
      }
      const files = acceptedFiles.filter((acceptedFile) => acceptedFile.name !== file.name);
      if (!files.length) {
      }
      setAcceptedFiles(files);
    }

    if (status === 'done') {
      let newFiles = {
        name: file.name,
        ...JSON.parse(xhr.response),
      };
      const files = [...acceptedFiles, ...[newFiles]];
      setAcceptedFiles(files);
      otherInputSet({ ...otherInput, additional_file: newFiles.data.url });
      setReplaceFile(false);
      setLoaders(loaders.filter((loader) => loader !== loaders.length));
    }
  };

  const Preview = ({ fileWithMeta, meta }) => {
    const { name, percent, status, type } = meta;
    const { cancel, remove, restart } = fileWithMeta;

    const previewUrl = useMemo(() => {
      if (status !== 'done') return '';
      return URL.createObjectURL(fileWithMeta.file);
    }, [fileWithMeta.file, status]);

    return (
      <div className="filePreviewComponent">
        <div className="fileWrapper">
          <a href={previewUrl} target="_blannk">
            {meta?.type?.includes('image') && previewUrl ? (
              <img className="imagePreview" src={previewUrl} alt="UploadedImage" />
            ) : type ? (
              getContentTypeImg(getContentTypeURL(type).id)
            ) : (
              <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />
            )}
          </a>
          {/* Bottom buttons */}
          <div className="contentWrapper">
            <h6 className="heading6">{name}</h6>
            {status !== 'error_upload' && status !== 'error_file_size' ? (
              <>
                <p className="supportText flexNotCentered">
                  <span>
                    {status !== 'done' ? (
                      'Uploading...'
                    ) : (
                      <span className="uploaded green flexCentered">
                        <img src={imageBasePath + 'green_check_rounded.svg'} alt="" />
                        Uploaded
                      </span>
                    )}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                <LinearProgress
                  className="primary"
                  variant="determinate"
                  value={percent === 100 ? 95 : percent}
                />
              </>
            ) : null}
            {status === 'error_upload' || status === 'error_file_size' ? (
              <>
                <p className="supportText flexNotCentered">
                  <span className="failed">
                    {status === 'error_file_size'
                      ? `Your file size is larger than 200 MB`
                      : 'Failed...'}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                <LinearProgress className="secondary" variant="determinate" value={percent} />
              </>
            ) : null}
          </div>
        </div>
        {/* <ul className="listInline textCenter buttonList">
          <li className="listInlineItem">
            <button
              className="removeBtn flexCentered"
              onClick={() => {
                cancel();
                remove();
                setAcceptedFiles([]);
                otherInputSet({ ...otherInput, additional_file: null });
              }}
            >
              <img src={imageBasePath + 'minus_circle.svg'} alt="Remove" />
              <span className="heading6">Remove</span>
            </button>
          </li>
          <li className="listInlineItem">
            <button
              className="removeBtn flexCentered"
              onClick={() => {
                cancel();
                restart();
              }}
            >
              <img src={imageBasePath + 'reupload.svg'} alt="Reupload" />
              <span className="heading6">Reupload</span>
            </button>
          </li>
        </ul> */}
      </div>
    );
  };
  return (    
    <>
      {(!otherInput.additional_file || replaceFile) && 
      <Dropzone
        styles={{ dropzone: { minHeight: 200, maxHeight: 400 } }}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept={props.accept}
        inputContent={inputContent}
        maxFiles={props.maxFiles}
        PreviewComponent={Preview}
        maxSizeBytes={props.maxSizeBytes}
      />}
    </>    

  );
}

const User = (function () {
  const getUserName = (user) => {
    if (!user) return '';
    const { first_name, last_name, email } = user;
    let name = '';
    if (first_name || last_name) {
      if (first_name) name += `${first_name}`;
      if (last_name) name += first_name ? ` ${last_name}` : last_name;
    } else name = email;
    return name;
  };

  const getInitial = (user) => {
    return getUserName(user)?.substring(0, 1).toUpperCase() || '';
  };

  return { getUserName, getInitial };
})();

export default User;

import React from 'react';
import InputText from 'shared_ui_components/InputText';

const Ocurrences = ({ setFormData = () => null, formData }) => {
  return (
    <div>
      <p className="supportText">Number of ocurrences</p>
      <InputText
        type="number"
        onChange={(e) => setFormData({ ...formData, number_of_retakes: parseInt(e.target.value) })}
        value={formData?.number_of_retakes}
        InputProps={{ inputProps: { min: 1 } }}
      />
    </div>
  );
};

export default Ocurrences;

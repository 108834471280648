import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';
import { Skeleton } from '@material-ui/lab';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'RecruitmentOption' && (
        <>
          <section className={styles.setupTrainingIntroSection}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className={styles.titleWrapper}>
                  <div className={styles.imgBlk}>
                    <Skeleton
                      variant="circle"
                      width={48}
                      height={48}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </div>
                  <div className={styles.contentBlk}>
                    <h1 className="heading4">
                      {' '}
                      <Skeleton variant="text" width="20%" style={{}} />
                    </h1>
                    <p className="supportText">
                      <Skeleton variant="text" width="60%" style={{}} />
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          <section className={styles.recruitmentOptionsSection}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.titleRow}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h1 className="heading4">
                  <Skeleton variant="text" width="30%" style={{}} />
                </h1>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={3} className={styles.contentRow}>
              {[0, 1, 2].map((item, key) => (
                <Grid key={key} item lg={4} md={4} sm={12} xs={12} className="flexNotCentered">
                  <div className={styles.cardWrapper} style={{ width: '100%' }}>
                    <div className={styles.imgBlk}>
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={185}
                        style={{ marginTop: '-40px' }}
                      />
                    </div>
                    <div className={styles.contentBlk}>
                      <h2 className="heading5">
                        <Skeleton variant="text" width="30%" style={{}} />
                      </h2>
                      <p className="paragraph">
                        <Skeleton variant="text" width="90%" style={{}} />
                      </p>
                      <Skeleton variant="text" width="40%" height={48} style={{}} />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </section>
        </>
      )}
      {props.type == 'RecruitmentOverview' && (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={styles.headingRow}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={styles.titleBlk}>
                <Skeleton
                  variant="text"
                  width={40}
                  height={40}
                  style={{ position: 'absolute', top: 3, left: 0, transform: 'none' }}
                />
                <div className={styles.info}>
                  <h3 className="heading5">
                    <Skeleton
                      variant="text"
                      width="45%"
                      height={24}
                      style={{ marginLeft: 7, marginTop: 8 }}
                    />
                  </h3>
                  <p className="supportText">
                    {' '}
                    <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Skeleton
                variant="text"
                width={92}
                height={32}
                style={{ marginRight: 10, float: 'right', transform: 'none' }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {props.type == 'RecruitmentOverviewTable' && (
        <>
          <div className={styles.overviewWrapper}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.tableTitleRow}
            >
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <h4 className={styles.jobTitle + ' supportTextSemibold'}>
                  <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                </h4>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <h4 className="supportTextSemibold">
                  <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                </h4>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <h4 className="supportTextSemibold">
                  <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                </h4>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <h4 className="supportTextSemibold">
                  <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                </h4>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <h4 className="supportTextSemibold">
                  <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 7 }} />
                </h4>
              </Grid>
            </Grid>

            <div className={styles.contentWrapper}>
              <Grid container direction="row" spacing={2} className={styles.tableContentRow}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <div className={styles.jobTitleBlk}>
                    <Skeleton
                      variant="text"
                      width={22}
                      height={22}
                      style={{ position: 'absolute', top: 3, left: 0, transform: 'none' }}
                    />
                    <div className={styles.info}>
                      <h3 className="heading6">
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={24}
                          style={{ marginLeft: 7 }}
                        />
                      </h3>
                      <p className="supportText">
                        <Skeleton
                          variant="text"
                          width="65%"
                          height={18}
                          style={{ marginLeft: 7 }}
                        />
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <p className="heading5">
                    <Skeleton variant="text" width="65%" height={24} style={{ marginLeft: 7 }} />
                  </p>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <p className="heading5">
                    <Skeleton variant="text" width="65%" height={24} style={{ marginLeft: 7 }} />
                  </p>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  <p className="heading5">
                    <Skeleton variant="text" width="65%" height={24} style={{ marginLeft: 7 }} />
                  </p>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} className={styles.candidateAcceptedBlk}>
                  <span className={styles.count + ' heading5'}>
                    <Skeleton variant="text" width="65%" height={24} style={{ marginLeft: 7 }} />
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}

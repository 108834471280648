import React from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'notificationShimmer' &&
        [0, 1, 2, 3, 4].map((item, key) => (
          <div className="notificationShimmerStyle" style={{ padding: 16, position: 'relative' }}>
            <Skeleton
              variant="circle"
              width={60}
              height={60}
              style={{
                background: 'rgba(0, 0, 0, 0.11)',
                position: 'absolute',
                left: 8,
                top: 17,
              }}
              className="notificationShimmerImg"
            />
            <Skeleton
              variant="text"
              width="65%"
              height={30}
              style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: 10, marginLeft: 65 }}
              className="notificationShimmerText"
            />
            <Skeleton
              variant="text"
              width="30%"
              height={20}
              style={{ background: 'rgba(0, 0, 0, 0.11)', marginLeft: 65 }}
              className="notificationShimmerTime"
            />
            <Skeleton
              variant="text"
              width="5%"
              height={20}
              style={{
                background: 'rgba(0, 0, 0, 0.11)',
                position: 'absolute',
                right: 8,
                top: 20,
              }}
              className="notificationShimmerMore"
            />
          </div>
        ))}
    </>
  );
}

import React from 'react';
import Organization from 'infrastructure/Organization';
import RoleplayHomeCard from './RoleplayHomeCard';
import RoleplayToPass from '../RoleplayToPass/RoleplayToPass';
import { FeatureFlag } from 'controllers/_exports';
import { Grid } from '@material-ui/core';
import RoleplayProgress from '../RoleplayProgress/RoleplayProgress';

const RoleplayHome = ({ graph }) => {
  if (!FeatureFlag.enabled('ROLEPLAY')) return null;

  if (graph)
    return (
      <Grid item xs={12} md={6}>
        <RoleplayHomeCard title="Roleplays">
          <RoleplayProgress withHeader={false} home />
        </RoleplayHomeCard>
      </Grid>
    );

  if (Organization.isRep())
    return (
      <Grid item xs={12} md={6}>
        <RoleplayHomeCard title="Roleplays">
          <RoleplayToPass />
        </RoleplayHomeCard>
      </Grid>
    );

  return null;
};

export default RoleplayHome;

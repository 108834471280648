import { useTheme, useMotionConfig } from '@nivo/core';
import { useSpring, animated } from 'react-spring';

const PartLabel = ({ part }) => {
  const theme = useTheme();
  const { animate, config: motionConfig } = useMotionConfig();

  const animatedProps = useSpring({
    transform: `translate(${part.x}, ${part.y})`,
    color: '#272D37',
    config: motionConfig,
    immediate: !animate,
  });

  return (
    <animated.g transform={animatedProps.transform}>
      <animated.text
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          ...theme.labels.text,
          fill: animatedProps.color,
          pointerEvents: 'none',
        }}
      >
        {`${part.data?.percentage}%`}
      </animated.text>
    </animated.g>
  );
};

const FunnelLabels = ({ parts = [] }) => {
  return parts.map((part) => <PartLabel key={part.data.id} part={part} />);
};

export default FunnelLabels;

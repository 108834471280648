import Home from './containers/Home';
import Test from './containers/Test';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const homeRoutes = [
  {
    path: '/',
    component: pageLayoutHoc(Home),
    key: 'home',
  },
  {
    path: '/shared-components',
    component: pageLayoutHoc(Test),
    key: 'test',
  },
];

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/styles/app.scss';
import { authRoutes, organisationRoutes } from './features/auth/authRoutes';
import { paymentRoutes } from './features/payment/paymentRoutes';
import { homeRoutes } from './features/home/homeRoutes';
import { onboardingRoutes } from './features/onboarding/onboardingRoutes';
import { streamRoutes } from './features/stream/streamRoutes';
import { roleplayRoutes } from './features/roleplay/roleplayRoutes';
import { trainingRoute } from './features/training/trainingRoute';
import { usersRoutes } from './features/users/usersRoutes';
import { settingsRoutes } from './features/settings/settingsRoutes';
import { recruitmentRoutes } from './features/recruitment/recruitmentRoutes';
import { trackingRoutes } from './features/tracking/trackingRoutes';
import { chatAssistRoutes } from 'features/chatAssist/chatAssistRoutes';
import ErrorBoundary from './utils/ErrorBoundary';
import { useClearCache } from 'react-clear-cache';
import PrivateRoute from './hocs/PrivateRoute';
import OrgnisationRouteCheck from './hocs/isOrgnisationAvailable';
import GlobalContextProvider from './context/GlobalContext';
import { ToastMessage } from './shared_ui_components';
import { PageNotFound } from './shared_elements';
import { subscriptionRoutes } from './features/subscription/subscriptionRoutes';
import VideoRecorderWrapper from './shared_ui_components/VideoRecorder/VideoRecorderWrapper';

import './store/ZustandDevtools';
import { SubscriptionModal } from 'controllers/_exports';
import ClearCache from 'hocs/ClearCache';
import { learningPathRoutes } from 'features/training/components/LearningPaths/LearningPathsRoutes';

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({
    auto: false,
    duration: 60 * 1000,
  });

  useEffect(() => {
    if (
      window.location.pathname?.includes('emailverification') ||
      window.location.pathname?.includes('inviteuser')
    ) {
      return;
    }
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  return (
    <div className="pageWrapper">
      <GlobalContextProvider>
        <Router>
          <SubscriptionModal />
          <VideoRecorderWrapper />
          <ErrorBoundary>
            <Switch>
              {authRoutes.map(({ path, component: Component, key }, index) => (
                <Route
                  key={key}
                  exact
                  path={path}
                  render={(props) => (
                    <ClearCache>
                      <Component {...props} />
                    </ClearCache>
                  )}
                />
              ))}
              {[
                ...subscriptionRoutes,
                ...paymentRoutes,
                ...organisationRoutes,
                ...homeRoutes,
                ...onboardingRoutes,
                ...trainingRoute,
                ...usersRoutes,
                ...recruitmentRoutes,
                ...settingsRoutes,
                ...trackingRoutes,
                ...streamRoutes,
                ...roleplayRoutes,
                ...learningPathRoutes,
                ...chatAssistRoutes,
              ].map(({ path, component, key }, index) => (
                <OrgnisationRouteCheck exact path={path} component={component} key={key} />
              ))}
              {<Route path="*" exact={true} component={PageNotFound} />}
            </Switch>
            <ToastMessage />
          </ErrorBoundary>
        </Router>
      </GlobalContextProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import styles from 'assets/styles/settings.module.scss';
import { imageBasePath } from 'constants';
import { SettingsForm } from '../components';
import { ProfileDrawer, NotificationPopup, DashboardHeader } from 'shared_elements';
import { updateSettingApi } from '../apiServices';
import { toastFlashMessage } from 'utils';
import timezones from 'assets/timezone';
import Organization from 'infrastructure/Organization';
import Providers from 'features/providers/containers/Providers';
import { useHistory, useLocation } from 'react-router-dom';
import { FeatureFlag } from 'controllers/_exports';

const Settings = () => {
  const [org, setOrg] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.selectedOrg) {
      setOrg(userInfo.selectedOrg);
      setUserEmail(userInfo.user.email);
    }
  }, []);

  // Tab handlers
  const [activeTab, setActiveTab] = useState('configuration');
  const [tabs, setTabs] = useState([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('active');
    const state = queryParams.get('state');

    if (state) {
      try {
        const data = JSON.parse(state);
        if (data?.activeTab) return setActiveTab(data.activeTab);
      } catch (error) {}
    }

    setActiveTab(tab || 'configuration');
  }, [location.search]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/settings?active=${tab}`);
  };

  useEffect(() => {
    const tabs = [
      {
        value: 'configuration',
        title: 'Configuration',
        isActive: activeTab === 'configuration',
      },
    ];
    if (Organization.isOwner() || Organization.isAdmin())
      if (FeatureFlag.enabled('HUBSPOT'))
        tabs.push({
          value: 'integrations',
          title: 'Integrations',
          isActive: activeTab === 'integrations',
        });

    setTabs(tabs);
  }, [activeTab]);

  const handleSubmit = (data) => {
    updateSettingApi(data).then((response) => {
      setSubmitLoader(false);
      if (response.statusCode === 200) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        userInfo = {
          ...userInfo,
          selectedOrg: {
            ...userInfo.selectedOrg,
            name: response.data.name,
            category: response.data.category,
            logo: response.data.logo,
            brandedLogo: response.data.brandedLogo,
            size: response.data.size,
            timezone: response.data.timezone,
            website: response.data.website,
            sales_lead_require_onboarding: response.data.sales_lead_require_onboarding,
            recruiting_module_enabled: response.data.recruiting_module_enabled,
            flexible_checkin_windows: response.data.flexible_checkin_windows,
            sales_rep_require_onboard_training: response.data.sales_rep_require_onboard_training,
            support_email: response.data.support_email,
          },
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setOrg((prevOrg) => ({
          ...prevOrg,
          name: response.data.name,
          category: response.data.category,
          logo: response.data.logo,
          brandedLogo: response.data.brandedLogo,
          size: response.data.size,
          timezone: response.data.timezone,
          website: response.data.website,
          sales_lead_require_onboarding: response.data.sales_lead_require_onboarding,
          recruiting_module_enabled: response.data.recruiting_module_enabled,
          flexible_checkin_windows: response.data.flexible_checkin_windows,
          sales_rep_require_onboard_training: response.data.sales_rep_require_onboard_training,
          support_email: response.data.support_email,
        }));
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  return (
    <div className={styles.settingsPage}>
      <DashboardHeader
        dashboardLogo={imageBasePath + 'settings.svg'}
        handleChange={handleTabChange}
        title="Settings"
        tabs={tabs}
      />

      {activeTab === 'configuration' && (
        <section className={styles.settingsSection}>
          <ProfileDrawer />
          <Grid container direction="row" spacing={2} className={styles.settingsCompanyDetailsRow}>
            <Grid item xs={12}>
              <h3 className={styles.companyDetailsTitle + ' heading3 '}>Company Details</h3>
            </Grid>
          </Grid>
          <SettingsForm
            timezones={timezones}
            org={org}
            handleSubmit={handleSubmit}
            submitLoader={submitLoader}
            setSubmitLoader={(val) => setSubmitLoader(val)}
            userEmail={userEmail}
          />
          <NotificationPopup />
        </section>
      )}

      {activeTab === 'integrations' && <Providers />}
    </div>
  );
};

export default Settings;

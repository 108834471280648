import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath, goalUnits } from '../../../constants';
import { Grid } from '@material-ui/core';
import { CustomDeltaValue } from '../../../shared_ui_components';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../../../utils';
import TrackingFrequencyBadge from './TrackingFrequencyBadge';

export default function OrganizationGoalsStatus(props) {
  const { organizationGoals = [], frequency } = props;

  return (
    <div className={styles.organizationGoals}>
      <Grid container style={{ height: '100%' }}>
        <p className={styles.goalsStatusHeading}>
          Sales Goals <TrackingFrequencyBadge margin={false} frequency={frequency} />
        </p>
        {organizationGoals &&
          organizationGoals.map((goal, index) => (
            <Grid item xs={12} key={index}>
              <div className={`${styles.trackingInfoItem} textCenter`}>
                <h3 className={`heading6 ${styles.title}`}>
                  <img
                    src={`${imageBasePath}${goalUnits.find((item) => item.id == goal.unit).icon}`}
                    alt=""
                  />
                  <span>{goal.name}</span>
                </h3>
                <div className={`${styles.trackingValueWrapper} flexCentered`}>
                  {goal.delta_value ? <CustomDeltaValue value={goal.delta_value} /> : null}
                  <h3 className={`heading2`}>
                    {goal.target && goal.unit == 2 ? (
                      <>
                        <span>$</span>
                        <NumberFormat
                          format={CustomNumberFormatter}
                          displayType="text"
                          value={goal?.target}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </>
                    ) : (
                      CustomNumberFormatter(goal.target)
                    )}
                  </h3>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

import React from 'react';

import styles from './PostComments.module.scss';
import { useGetCommentsOnAPost } from 'features/stream/Stream.queries';
import Comment from './Comment/Comment';
import CommentShimmer from './Comment/CommentShimmer';

const PostCommentsSection = ({ post_id, showComments, total_comments, closeComments }) => {
  const { data: comments = [], isLoading } = useGetCommentsOnAPost({
    post_id,
    enabled: showComments && total_comments > 0,
  });

  if (!total_comments > 0 || !showComments) return null;
  if (isLoading) return <CommentShimmer />;

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.commentsList}>
        {comments.map((comment, index) => (
          <div key={index} className={styles.comment}>
            <Comment data={comment} postId={post_id}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostCommentsSection;

export const roleConfig = {
  1: {
    candeleteOthersComments: true,
  },
  2: {
    candeleteOthersComments: true,
  },
  3: {
    candeleteOthersComments: false,
  },
  4: {
    candeleteOthersComments: false,
  },
};

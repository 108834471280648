import React from 'react';

import {
  DiscardButton,
  MicButton,
  PlayPauseButton,
  RestartButton,
  StopButton,
  DragButton,
} from './RecorderButtons';
import VideoRecorderLogic from './VideoRecorder.logic';
import Timer from './Timer/Timer';
import './VideoRecorder.scss';
import Webcam from './Webcam/Webcam';
import DragContainer from './DragContainer/DragContainer';
import RecordingOptions from './RecordingOptions/RecordingOptions';
import ExpandButton from './RecorderButtons/ExpandButton';

// TODO prevent window close if recording

const VideoRecorder = () => {
  const {
    isPaused,
    isMuted,
    isRecordingActive,
    isEnableRecording,
    time,
    showWebcam,
    isFullWebcam,
    handleMute,
    handlePause,
    handleStop,
    handleStart,
    handleDiscard,
    handleRestart,
    //
    isExpanded,
    videoRecordingBar,
    toggleMinimizedBar,
    // recording options
    onScreenAndCamera,
    onScreenOnly,
    onWebcamOnly,
  } = VideoRecorderLogic();

  // Hide controls if not recording
  return isEnableRecording ? (
    <DragContainer>
      {/* Control bar */}
      <div className="recorder-bar" id={recorderBarId} ref={videoRecordingBar}>
        <DragButton recorderBarId={recorderBarId} />

        <RecordingOptions
          onScreenAndCamera={onScreenAndCamera}
          onScreenOnly={onScreenOnly}
          onWebcamOnly={onWebcamOnly}
        />

        <MicButton onClick={handleMute} isMuted={isMuted} />
        <StopButton onStop={handleStop} onStart={handleStart} isRecording={isRecordingActive} />

        {isRecordingActive && <PlayPauseButton onClick={handlePause} isPaused={isPaused} />}

        <Timer
          seconds={time}
          isPaused={isPaused}
          handlePause={handlePause}
          handleStop={handleStop}
          isExpanded={isExpanded}
          isRecording={isRecordingActive}
        />

        {isRecordingActive && <RestartButton onClick={handleRestart} />}
        <DiscardButton onClick={handleDiscard} />
        <ExpandButton onClick={toggleMinimizedBar} isExpanded={isExpanded} />
      </div>

      <Webcam show={showWebcam} isFullWebcam={isFullWebcam} />
    </DragContainer>
  ) : null;
};

export default VideoRecorder;

const recorderBarId = 'video-recorder-bar';

import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import { CustomLink } from '../../../shared_ui_components';
import { Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

export default function CheckinAlert(props) {
  const { btnText, message, type = '', last_checkin } = props;
  return (
    <div className={styles.checkinAlertMessage}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item md={8} lg={8} xs={8}>
          <div className={styles.textBlk}>
            {type == 'editCheckin' ? (
              <img src={`${imageBasePath}edit_checkin_alert_icon.svg`} />
            ) : (
              <img src={`${imageBasePath}checkin_alert_icon.svg`} />
            )}
            <p className={`paragraph`}>{message}</p>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xs={4}>
          <p className={styles.btnBlk}>
            <CustomLink
              className={`primaryBtn ${styles.customLink}`}
              linkimg={`target_icon_white.svg`}
              linktext={btnText}
              onClick={
                type == 'editCheckin'
                  ? () => {
                      props.handleOpenCheckin(true);
                      props.hanldeEditCheckin(format(parseISO(last_checkin), 'yyyy-MM-dd'), true);
                    }
                  : () => {
                      props.handleOpenCheckin(true);
                    }
              }
            />
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

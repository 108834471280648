import { useState, useMemo } from 'react';
import { toastFlashMessage } from '../../utils';
import { nudgeUserTrainingApi } from '../../features/training/apiServices';
import { nudgeUserOnboardingApi } from '../../features/onboarding/apiServices';
import { Subscription } from 'controllers/_exports';

const minCompletionPercentage = 25;
const minNumberOfDays = 14;

const NudgeLogic = ({ user, resource }) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const isTraining = resource === 'training';
  const isOnboarding = resource === 'onboarding';

  const handleClick = async () => {
    if (isTraining && !Subscription.allow({ key: Subscription.keys.nudge })) return;

    setLoading(true);

    if (isTraining) {
      await nudgeUserTrainingApi(user?.uuid).then((response) => {
        if (response.statusCode === 200) onSuccess(response);
        else toastFlashMessage(response.message, 'error');
      });
    } else if (isOnboarding) {
      await nudgeUserOnboardingApi(user?.uuid).then((response) => {
        if (response.statusCode === 200) onSuccess(response);
        else toastFlashMessage(response.message, 'error');
      });
    }
    setLoading(false);
  };

  const onSuccess = (response) => {
    toastFlashMessage(response.message, 'success');
    // If nudged successfully disable button
    setDisabled(true);
  };

  // Conditions to show nudge button

  // User must be under 25% of completion percentage
  const belowPercentage = useMemo(() => {
    if (isTraining) return user?.completed_percentage <= minCompletionPercentage;
    else if (isOnboarding)
      return user?.status?.value !== 1 && user?.status?.progress <= minCompletionPercentage;
  }, [
    isOnboarding,
    isTraining,
    user?.completed_percentage,
    user?.status?.progress,
    user?.status?.value,
  ]);

  // User must have 14 days or more in the organization
  const moreThanFourteenDays = useMemo(() => {
    if (!user?.created_at) return true;

    const d1 = new Date();
    const d2 = new Date(user.created_at);

    const diffTime = Math.abs(d1 - d2);
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return days >= minNumberOfDays;
  }, [user?.created_at]);

  // User must not have been nudged in the last 24hrs
  const notBeenNudged = useMemo(() => {
    if (!user?.last_nudge_notification) return true;

    const d1 = new Date();
    const d2 = new Date(user.last_nudge_notification);

    const diffTime = Math.abs(d1 - d2);
    const hours = Math.ceil(diffTime / (1000 * 60 * 60));

    return hours > 24;
  }, [user?.last_nudge_notification]);

  // is_onboarding_enabled must be true for onboarding nudge
  // is_training_enabled must be true for trining nudge
  const isRequiredForUser = useMemo(() => {
    if (isOnboarding) {
      // TODO remove in condition later
      if ('is_onboarding_enabled' in user) return !!user?.is_onboarding_enabled;
      return true;
    }
    if (isTraining) {
      // TODO remove in condition later
      if ('is_training_enabled' in user) return !!user?.is_training_enabled;
      return true;
    }
  }, [isOnboarding, isTraining, user]);

  // If a condition is not meet hide the button
  const showNudge = useMemo(
    () => belowPercentage && moreThanFourteenDays && notBeenNudged && isRequiredForUser,
    [belowPercentage, moreThanFourteenDays, notBeenNudged, isRequiredForUser]
  );

  return { showNudge, disabled, loading, handleClick };
};

export default NudgeLogic;

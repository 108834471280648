import React from 'react';
import { useRef } from 'react';

import styles from '../../../assets/styles/settings.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { Subscription } from 'controllers/_exports';

const ImageUploader = ({ handleChange, title, identifier }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    if (identifier === 'brandedLogo' || identifier === 'brandedIcon') {
      if (!Subscription.allow({ key: Subscription.keys.edit_logo })) return;
    }
    hiddenFileInput.current.click();
  };

  return (
    <>
      <CustomButton
        className={styles.settingsLogoPicker + ' secondaryBtn '}
        children={title}
        onClick={handleClick}
      />
      <input
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        id="file"
        type="file"
        label="Upload"
        accept="image/png,image/jpg"
        title=""
        onChange={handleChange}
      />
    </>
  );
};

export default ImageUploader;

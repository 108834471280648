import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateLearningPathContents } from './LearningPaths.services';
import { useLearningPathsStore } from './LearningPaths.store';
import { LEARNING_PATHS_CACHE_KEYS } from './LearningPaths.queries';

export const useUpdateLearningPathContents = () => {
  const queryClient = useQueryClient();
  const { learningPath, setContents, contents: currentContents } = useLearningPathsStore();
  const uuid = learningPath?.uuid;

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ removed_contents, contents }) => {
      return updateLearningPathContents({ removed_contents, contents, uuid });
    },
    onMutate: () => {
      // Save a copy of previous contents state before sending data and changing store
      const previousContents = [...currentContents];
      return { previousContents };
    },
    onSuccess: (response, request) => {
      setContents(response?.data);
      if (response?.data?.length === 0) {
        // Invalidate becuase status should have changed
        queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH, uuid]);
      }
    },
    onError: (response, request, { previousContents }) => {
      setContents(previousContents); // Revert changes
    },
  });

  const convert = (_contents) => {
    return _contents?.map(({ content_type, content }) => ({
      type: content_type,
      uuid: content?.uuid,
    }));
  };

  const removeContents = (contentID) => {
    const removed = currentContents?.find(({ content }) => content?.uuid === contentID);
    const _contents = currentContents?.filter((c) => c?.content?.uuid !== contentID);
    const contents = convert(_contents);

    mutate({ contents, removed_contents: convert([removed]) });
    setContents(_contents);
  };

  const addContents = (newContents) => {
    const _contents = [...currentContents, ...newContents];
    const contents = convert(_contents);

    mutate({ contents });
    setContents(_contents);
  };

  const reorderContents = (newContents) => {
    const contents = convert(newContents);

    mutate({ contents });
    setContents(newContents);
  };

  const resetContents = () => {
    setContents([]);
  };

  return { mutate, isLoading, removeContents, addContents, reorderContents, resetContents };
};

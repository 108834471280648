import React, { useState } from 'react';
import SmartMentorContainer from '../UI/SmartMentorContaier/SmartMentorContainer';
import styles from './SmartMentor.module.scss';
import logo512 from 'assets/logo512.png';
import { TooltipCustom } from 'utils';

const SmartMentor = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div onClick={handleOpen} className={styles.smartMentor}>
        <TooltipCustom
          placement="top"
          arrow
          title={
            <div className="supportText" style={{ color: `#fff` }}>
              Smart mentor
            </div>
          }
        >
          <img src={logo512} alt="logo icon" className={styles.iconLogo} onClick={handleOpen} />
        </TooltipCustom>
      </div>

      {open && <SmartMentorContainer open={open} handleOpen={handleOpen} />}
    </>
  );
};

export default SmartMentor;

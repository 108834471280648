import React, { useEffect, useMemo, useState } from 'react';
import styles from './MyTeam.module.scss';
import MyTeamTableRow from './MyTeamTableRow';
import { InputSelect } from 'shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';

const ACTIVE = 'active';
const UNASSIGNED = 'unassigned';
const ARCHIVED = 'archived';
const INITIAL_OPTION = { label: 'Active', value: ACTIVE };

const MyTeamTable = ({ data, loading }) => {
  const [filter, setFilter] = useState(INITIAL_OPTION);
  const onFilterChange = (opt) => setFilter(opt);

  const options = useMemo(() => {
    const opts = [INITIAL_OPTION];
    const notArchived = data?.filter((r) => ('archive' in r ? !r.archive?.status : true));

    if (FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) {
      const unassigned = notArchived?.filter((r) => ('assigned' in r ? !r?.assigned : true));
      if (unassigned?.length > 0) opts.push({ label: 'Unassigned', value: UNASSIGNED });
    }

    if (notArchived?.length > 0) opts.push({ label: 'Archived', value: ARCHIVED });
    return opts;
  }, [data]);

  const filteredData = useMemo(() => {
    if (data?.length <= 0) return [];
    let filterValue = filter.value;

    // Verify filter has data to avoid showing empty table
    if (!options?.find((o) => o.value === filter?.value)) {
      filterValue = INITIAL_OPTION.value;
      setFilter(INITIAL_OPTION);
    }

    const notArchived = data?.filter((r) => ('archive' in r ? !r.archive?.status : true));
    const unassigned = notArchived?.filter((r) => ('assigned' in r ? !r?.assigned : true));

    if (filterValue === UNASSIGNED) return unassigned;
    if (filterValue === ARCHIVED) return data?.filter((r) => r?.archive?.status);
    return notArchived?.filter((r) => ('assigned' in r ? Boolean(r.assigned) : true));
  }, [filter, data, options]);

  if (loading) return <p className={styles.message}>Loading...</p>;
  if (data?.length <= 0)
    return (
      <>
        <MyTeamTableRow isHeader />
        <p className={styles.message}>Your team activities will appear here.</p>
      </>
    );

  return (
    <div className={styles.myTeam}>
      <div className={styles.title}>
        <h1>My Team</h1>
        {options?.length > 1 && (
          <InputSelect
            className="reactSelect"
            closeMenuOnSelect
            options={options}
            onChange={onFilterChange}
            isSearchable={false}
            isClearable={false}
            value={filter}
          />
        )}
      </div>
      <div className={styles.table}>
        <MyTeamTableRow isHeader />
        {filteredData?.map((roleplay) => (
          <MyTeamTableRow key={roleplay?.uuid} roleplayData={roleplay} />
        ))}
        {filteredData?.length === 0 && filter.value === INITIAL_OPTION.value && (
          <p className={styles.message}>You don't have active roleplays at this moment</p>
        )}
      </div>
    </div>
  );
};

export default MyTeamTable;

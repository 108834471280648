import React, { useMemo } from 'react';
import styles from './NewSettings.module.scss';
import { imageBasePath, goalFrequencies } from 'constants';
import { useTrackingStore } from 'features/tracking/tracking.store';

const InfoFrequency = () => {
  const data = useTrackingStore((state) => state?.activityGoalsData);
  return (
    <div className={styles.info}>
      <img alt="" src={imageBasePath + 'info_icon.svg'} />
      <span>
        Targets are set for each sales representative on{' '}
        <FrequencyBadge frequency={data?.frequency} /> basis
      </span>
    </div>
  );
};

export const FrequencyBadge = ({ frequency }) => {
  const freq = useMemo(
    () => goalFrequencies?.find((f) => f.id === frequency || 1)?.label?.toLowerCase(),
    [frequency]
  );
  return <span className={styles.freq}>{freq}</span>;
};

export default InfoFrequency;

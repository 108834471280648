import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/recruitment.module.scss';
import {
  IntroductionCard,
  RecruitmentOptions,
  AddJobDialog,
  Overview,
  IntegrateWithGreenHouse,
  GreenHouseDialog,
  AdminRecruitmentContainer,
} from '../components';
import { ConfirmationDialog, SuccessDialog } from '../../../shared_elements';
import { imageBasePath } from '../../../constants';

export default class Recruitment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addJobManually: false,
      deleteJob: false,
      edit: false,
      greenhouseInteg: false,
      continueGreenhouseInteg: false,
      successFlag: false,
    };
  }

  handleAddJob = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      addJobManually: flag,
    }));
  };

  handleDeleteJob = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      deleteJob: flag,
    }));
  };

  handleGreenHouseIntegration = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      greenhouseInteg: flag,
    }));
  };

  handleGreenHouseContinueInteg = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      continueGreenhouseInteg: flag,
    }));
  };

  handleSuccess = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      successFlag: flag,
    }));
  };

  render() {
    const { addJobManually, deleteJob, greenhouseInteg, continueGreenhouseInteg, successFlag } =
      this.state;
    return (
      <div className={styles.recruitmentOuterPageWrapper}>
        <div className={styles.recruitmentOptionsPage}>
          <AdminRecruitmentContainer />
        </div>
      </div>
    );
  }
}

import React from 'react';
import { NoResult } from 'shared_ui_components';

const NoResults = ({ hasFilters }) => {
  return (
    <NoResult
      image={`no_user_found.svg`}
      title={
        hasFilters
          ? `Sorry, We could not find any posts`
          : 'Ready to create some posts? Be the first to post and kickstart the conversation!'
      }
      subtext={
        hasFilters
          ? `Please try with some other post category and users`
          : 'Be the first to make a post!'
      }
    />
  );
};

export default NoResults;

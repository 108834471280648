import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath, goalUnits } from '../../../constants';
import { CustomTargetValue, CustomDeltaValue } from '../../../shared_ui_components';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../../../utils';

export default function TrackingInfoItem(props) {
  const { goal = {} } = props;
  return (
    <div className={`${styles.trackingInfoItem} textCenter`}>
      <h3 className={`heading6 ${styles.title}`}>
        <img src={`${imageBasePath}${goalUnits.find((item) => item.id == goal.unit).iconBlue}`} />
        <span>{goal.name}</span>
      </h3>
      <div className={`${styles.trackingValueWrapper} flexCentered`}>
        {goal.goal_data?.find((item) => item.current)?.delta_value ? (
          <CustomDeltaValue value={goal.goal_data?.find((item) => item.current)?.delta_value} />
        ) : null}
        <h3 className={`heading2`}>
          {goal.goal_data?.find((item) => item.current)?.total_users_target && goal.unit == 2 ? (
            <>
              <span>$</span>
              <NumberFormat
                format={CustomNumberFormatter}
                displayType="text"
                value={`${goal.goal_data?.find((item) => item.current)?.total_users_target}`}
                thousandSeparator={true}
                prefix={'$'}
              />
            </>
          ) : (
            CustomNumberFormatter(goal.goal_data?.find((item) => item.current)?.total_users_target)
          )}
        </h3>
        <CustomTargetValue
          unit={goal.unit}
          value={goal.goal_data?.find((item) => item.current)?.expected_target}
        />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';

export default function CustomRadioButton(props) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControlLabel
        className="supportText radioButton"
        value={value}
        control={<Radio />}
        label={props.label}
        onChange={(e) => handleChange(e.target.value)}
        {...props}
      />
    </div>
  );
}

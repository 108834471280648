import Organization from 'infrastructure/Organization';

import features from './flags.json';

const FeatureFlag = (function () {
  const isProduction = process.env.REACT_APP_ENV === 'Production';
  const isStaging = process.env.REACT_APP_ENV === 'Staging';

  const allowedOrgs = ['8fw5HGhW', "0KfH9Gmm", "ISLGQqVZ", "c20ypivw", "p6wzv6ve", "fGecTNfq", "1nu81WCR"];

  const enabled = (identifier) => {
    const featureSettings = features.find(({ id }) => id === identifier);
    if (!featureSettings) return false;

    if (isProduction) return featureSettings?.production;
    else if (isStaging) {
      const organization = Organization.getSelectedOrg();

      if (featureSettings?.preStaging) return allowedOrgs.includes(organization?.slug);
      return featureSettings?.staging;
    }

    return false;
  };

  return { enabled };
})();

export default FeatureFlag;

import { Alert } from '@material-ui/lab';
import React, { useMemo } from 'react';

const DURATION = {
  ONCE: 1,
  FOREVER: 2,
  MONTHS: 3,
};

const PriceCouponInfo = ({ product, isBadge }) => {
  const data = useMemo(() => product?.price?.discount || {}, [product]);

  const message = useMemo(() => {
    if (isBadge) return '';
    if (data?.duration === DURATION.ONCE)
      return 'After the end of your initial month, your subscription will be renewed, and you will be charged the full price for the service.';
    if (data?.duration === DURATION.MONTHS)
      return `After the end of your ${data?.months} months offer, your subscription will be renewed, and you will be charged the full price for the service.`;
    return '';
  }, [data, isBadge]);

  if (isBadge)
    return !data?.coupon_price ? null : data?.duration ? (
      <span>
        {[DURATION.FOREVER, DURATION.MONTHS].includes(data?.duration)
          ? 'Special Offer'
          : 'New Member'}
      </span>
    ) : null;

  return message ? (
    <Alert severity="info" color="info" style={{ marginTop: '1rem' }}>
      {message}
    </Alert>
  ) : null;
};

export default PriceCouponInfo;

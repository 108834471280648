import React, { useState } from 'react';
import styles from './SmartMentorContainer.module.scss';
import { useSmartMentorStore } from 'features/smartMentor/SmartMentor.store';
import { useSearchContentMutation } from 'features/smartMentor/SmartMentor.queries';
import Messages from '../Messages/Messages';
import MessageInput from '../MessageInput/MessageInput';
import Header from '../Header/Header';

const SmartMentorContainer = ({ open = false, handleOpen = () => null }) => {
  const [message, setMessage] = useState('');

  const conversation = useSmartMentorStore((state) => state?.messages);
  const addMessage = useSmartMentorStore((s) => s?.addMessage);

  const { mutate, isLoading } = useSearchContentMutation({});

  const handleSend = async () => {
    if (message.length === 0) return;
    setMessage('');
    addMessage(message, 1);
    mutate({ input: message }, { onSuccess });
  };

  const onSuccess = ({ message }) => addMessage(message, 2);

  return (
    <div open={open} transition disablePortal className={styles.container}>
      <div className={styles.smartMentorWraper}>
        <Header handleOpen={handleOpen}/>
        <div className={styles.smartMentorContent}>
          <Messages conversation={conversation} isLoading={isLoading} />
          <MessageInput
            setMessage={setMessage}
            message={message}
            isLoading={isLoading}
            handleSend={handleSend}
          />
        </div>
      </div>
    </div>
  );
};

export default SmartMentorContainer;

import { Component } from 'react';
import styles from '../../../assets/styles/training.module.scss';
import {
  InitialAssesmentDialog,
  AssesmentSetupPending,
  CreateAssessmentDialog,
  TrainingAssessmentView,
  FeedbackAssessmentDialog,
  UserAssesmentDialog,
  ViewFeedbackCard,
} from '../components';

export default class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,
      type: '',
      assesmentInit: false,
      assesmentCreate: false,
      assesmentEdit: false,
      userAssement: false,
      assesmentFeedback: false,
      activeQuestion: '',
      userAssessment: {},
    };
  }

  handleAssesmentInit = (flag) => {
    this.setState((prevState) => ({
      ...prevState,
      assesmentInit: flag,
    }));
  };

  handleAssesmentCreate = (flag) => {
    // debugger
    this.setState((prevState) => ({
      ...prevState,
      assesmentCreate: flag,
    }));
  };

  handleAssesmentEdit = (flag) => {
    // debugger
    this.setState((prevState) => ({
      ...prevState,
      assesmentEdit: flag,
    }));
  };

  handleAssesmentFeedback = (flag) => {
    // debugger
    this.setState((prevState) => ({
      ...prevState,
      assesmentFeedback: flag,
    }));
  };

  handleUserAssesment = (flag) => {
    // debugger
    this.setState((prevState) => ({
      ...prevState,
      userAssement: flag,
    }));
  };

  handleActiveQuestion = (question) => {
    if (question) {
      this.setState({
        activeQuestion: question,
      });
    }
  };

  render() {
    const {
      assesmentInit,
      assesmentCreate,
      assesmentEdit,
      userAssement,
      assesmentFeedback,
      activeQuestion,
    } = this.state;
    return (
      <div className={styles.trainingPage}>
        {this.props.location.pathname == '/trainingassesment' ? (
          <>
            <InitialAssesmentDialog
              open={assesmentInit}
              handleClose={this.handleAssesmentInit}
              handleAssesmentInit={this.handleAssesmentInit}
            />
            <AssesmentSetupPending
              assesmentCreate={assesmentCreate}
              handleAssesmentCreate={this.handleAssesmentCreate}
            />

            <CreateAssessmentDialog
              open={assesmentCreate || assesmentEdit}
              handleClose={this.handleAssesmentCreate || this.handleAssesmentEdit}
              handleAssesmentCreate={this.handleAssesmentCreate}
              handleAssesmentEdit={this.handleAssesmentEdit}
              type={assesmentCreate ? 'create' : assesmentEdit ? 'edit' : ''}
              activeQuestion={activeQuestion ? activeQuestion : ''}
            />
            <TrainingAssessmentView handleAssesmentEdit={this.handleAssesmentEdit} />

            <FeedbackAssessmentDialog
              open={assesmentFeedback}
              handleClose={this.handleAssesmentFeedback}
              handleAssesmentFeedback={this.handleAssesmentFeedback}
            />
            <UserAssesmentDialog
              open={userAssement}
              handleClose={this.handleUserAssesment}
              handleUserAssesment={this.handleUserAssesment}
            />
            <div className="hidden">
              <ViewFeedbackCard
                handleAssesmentFeedback={this.handleAssesmentFeedback}
                assesmentFeedback={assesmentFeedback}
              />
              <p
                className="primaryBtn"
                onClick={() => {
                  this.handleAssesmentInit(true);
                }}
              >
                Initial Training Assestment
              </p>
              <p
                style={{ margin: '0 10px' }}
                className="primaryBtn"
                onClick={() => {
                  this.handleUserAssesment(true);
                }}
              >
                User Assestment
              </p>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import ReactPlayer from 'react-player';

import styles from './RecordVideo.module.css';
import { toastFlashMessage } from 'utils';
import ContinueButtons from './ContinueButtons';
import { useUploadRecordingMutation } from 'features/roleplay/Roleplay.queries';
import RecorderButtons from './RecorderButtons';

const Recorder = ({
  onStart = () => null,
  onCancelRecord = () => null,
  scenarioData = {},
  scenarios = [],
}) => {
  const [webcam, setWebcam] = useState(true);
  const toggleWebcam = () => setWebcam((w) => !w);

  // Hook
  const {
    error,
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    clearBlobUrl,
    mediaBlobUrl,
    isAudioMuted,
    muteAudio,
    unMuteAudio,
    previewStream,
  } = useReactMediaRecorder({
    video: true,
    audio: true,
  });

  // Handlers
  const [isRecording, setIsRecording] = useState(false);

  const reset = () => {
    setCounter(3);
    setTime(0);
    clearTimeout(timeoutID);
    clearInterval(intervalID);
    setPrevURL('');
    clearBlobUrl();
  };

  const start = () => {
    reset();
    startRecording();
    setIsRecording(true);
  };

  const onStop = () => {
    setIsRecording(false);
    if (mediaBlobUrl) onRecordingSuccess(mediaBlobUrl);
  };

  const onDelete = () => {
    stopRecording();
    onCancelRecord();
  };

  const onRestart = () => {
    setIsRecording(false);
    reset();
    startTimer();
  };

  const manualStop = () => stopRecording();

  useEffect(() => {
    // Error and status handlers
    if (error) toastFlashMessage('Permissions denied', 'info'); // TODO handle permissions
    if (['idle', 'acquiring_media', 'recording', 'stopping', 'paused'].includes(status)) return;
    onStop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  // Timeout and timer
  const [time, setTime] = useState(0);
  const [counter, setCounter] = useState(3);
  const [timeoutID, setTimeoutID] = useState(null);
  const [intervalID, setIntervalID] = useState(null);

  useEffect(() => {
    let interval = null;
    if (status === 'recording') interval = setInterval(() => setTime((t) => t + 1), 1000);
    else clearInterval(interval);
    return () => clearInterval(interval);
  }, [status]);

  const startTimer = () => {
    const id = setInterval(() => {
      setCounter((p) => p - 1);
    }, [1000]);
    setIntervalID(id);

    setTimeoutID(
      setTimeout(() => {
        start();
        clearInterval(id);
        setIntervalID(null);
      }, [3000])
    );
  };

  useEffect(() => {
    if (time >= 300) manualStop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Upload finished video to server
  const [prevURL, setPrevURL] = useState('');
  const [uploadedURL, setUploadedURL] = useState('');
  const { mutate, isLoading } = useUploadRecordingMutation();

  const onRecordingSuccess = async (blobUrl) => {
    setPrevURL(blobUrl);
    const file = await fetch(blobUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], `recording.mp4`, {
            type: 'video/mp4',
          })
      );
    mutate({ file }, { onSuccess });
  };

  const onSuccess = ({ url }) => setUploadedURL(url);

  const firstRender = useRef(true);

  useEffect(() => {
    return function clean() {
      if (firstRender.current) return;
      stopRecording();
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;
    startRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.recorderWrapper}>
      <div className={styles.videoContainer}>
        {!error && (
          <ReactPlayer
            url={prevURL || previewStream}
            controls={!!prevURL}
            muted={!prevURL}
            playing={!prevURL}
            height="100%"
            width="1280px"
            style={{ maxWidth: '100%' }}
          />
        )}
        {error && (
          <div style={{ textAlign: 'center' }}>
            <p>Something went wrong</p>
            <p>Try refreshing and providing the required permissions</p>
          </div>
        )}
        {intervalID && <div className={styles.counter}>{counter}</div>}
      </div>
      <RecorderButtons
        seconds={time}
        status={status}
        prevURL={prevURL}
        isRecording={isRecording}
        isAudioMuted={isAudioMuted}
        webcam={webcam}
        error={error}
        intervalID={intervalID}
        toggleWebcam={toggleWebcam}
        onMute={muteAudio}
        onPause={pauseRecording}
        onResume={resumeRecording}
        onStop={manualStop}
        onUnmute={unMuteAudio}
        onStart={startTimer}
        onCancelRecord={onCancelRecord}
        onDelete={onDelete}
        onRestart={onRestart}
      />
      {prevURL && (
        <ContinueButtons
          submitBtn
          loading={isLoading}
          onReRecordClick={() => {
            onCancelRecord();
            setTimeout(() => onStart(), 100);
          }}
          recordingURL={uploadedURL}
          scenarioData={scenarioData}
          scenarios={scenarios}
          showReRecord
        />
      )}
    </div>
  );
};

export default Recorder;

import React from 'react';
import { useState } from 'react';
import FileLink from './FileLink';

const VideoPlayer = ({ question }) => {
  const [videoError, setvideoError] = useState(false);

  return (
    <div>
      {videoError ? (
        <FileLink question={question} />
      ) : (
        <video
          controls
          key={question?.answer?.value}
          onError={() => {
            setvideoError(true);
          }}
        >
          <source src={question?.answer?.value} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;

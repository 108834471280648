import React, { useState, useEffect } from 'react';
import blueCheer from 'assets/stream/blueCheer.svg';
import cheer from 'assets/stream/cheer.svg';
import blueLove from 'assets/stream/blueLove.svg';
import love from 'assets/stream/love.svg';
import blueLike from 'assets/stream/blueLike.svg';
import like from 'assets/stream/like.svg';
import styles from './PostReactions.module.scss';
import {
  useCreateReactionCheer,
  useCreateReactionLike,
  useCreateReactionLove,
  useCreateReactionCheerComment,
  useCreateReactionLoveComment,
  useCreateReactionLikeComment,
} from 'features/stream/Stream.queries';
import Reaction from './Reaction/Reaction';
const Reactions = ({ data = {}, commentId = '', isComment = false, postId= ''}) => {
  const { mutate: createReactionCheer } = useCreateReactionCheer();
  const { mutate: createReactionLove } = useCreateReactionLove();
  const { mutate: createReactionLike } = useCreateReactionLike();
  const { mutate: createReactionCheerComment } = useCreateReactionCheerComment();
  const { mutate: createReactionLoveComment } = useCreateReactionLoveComment();
  const { mutate: createReactionLikeComment } = useCreateReactionLikeComment();
  const [isReacted, setIsReacted] = useState(data?.active_reacition);
  const handleCheer = () => {
    if (isComment) {
      createReactionCheerComment(
        { postId: postId, commentId: commentId },
        { onSuccess: () => setIsReacted('cheer') }
      );
    } else {
      createReactionCheer({ postId: data.id }, { onSuccess: () => setIsReacted('cheer') });
    }
  };
  const handleLove = () => {
    if (isComment) {
      createReactionLoveComment(
        { postId: postId, commentId: commentId },
        { onSuccess: () => setIsReacted('love') }
      );
    } else {
      createReactionLove({ postId: data.id }, { onSuccess: () => setIsReacted('love') });
    }
  };
  const handleLike = () => {
    if (isComment) {
      createReactionLikeComment(
        { postId: postId, commentId: commentId },
        { onSuccess: () => setIsReacted('like') }
      );
    } else {
      createReactionLike({ postId: data.id }, { onSuccess: () => setIsReacted('like') });
    }
  };
  useEffect(() => {
    setIsReacted(data?.active_reacition);
  }, [data?.active_reacition])
  
  return (
    <div className={styles.reactions}>
      <Reaction
        handleReaction={handleCheer}
        reaction={cheer}
        reactionActive={blueCheer}
        total={data.cheer_count}
        isReacted={isReacted === 'cheer'}
      />
      <Reaction
        handleReaction={handleLike}
        reaction={like}
        reactionActive={blueLike}
        total={data.like_count}
        isReacted={isReacted === 'like'}
      />
      <Reaction
        handleReaction={handleLove}
        reaction={love}
        reactionActive={blueLove}
        total={data.love_count}
        isReacted={isReacted === 'love'}
      />
    </div>
  );
};

export default Reactions;

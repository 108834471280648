import React, { useMemo } from 'react';
import { CustomButton } from 'shared_ui_components';
import styles from './ProviderCard.module.scss';
import { useProvidersStore } from 'features/providers/Providers.store';
import Organization from 'infrastructure/Organization';

const ProviderCard = ({
  onBeginIntegration,
  providers = [],
  code = '',
  onSetupIntegration = () => null,
  handleToggle = () => null,
}) => {
  const providerData = useMemo(() => {
    const provider = providers.find((p) => p?.code === code);
    return provider || {};
  }, [code, providers]);

  const { id, name = '', featured_image = '', description = '', integrated } = providerData;

  const setGlobalProviderData = useProvidersStore((state) => state?.setProviderData);

  const handleBeginIntegration = () => {
    if (typeof onBeginIntegration !== 'function') return;
    // If parent has a begin integration function check it first
    // It must return a boolean to know if modal should open
    if (Organization.isOwner() && onBeginIntegration()) setGlobalProviderData(providerData);
  };

  if (!id) return null;
  return (
    <div
      className={`${styles.providerCard} ${integrated ? styles.integrated : ''}`}
      onClick={() => integrated && onSetupIntegration()}
    >
      <div className={styles.imgBlk}>
        <img src={featured_image} alt={name} />
      </div>
      <div className={styles.contentBlk}>
        <h2 className="heading5">{name}</h2>
        <span>
          <p className="paragraph">{description}</p>
        </span>

        {/* Begin or setup integration buttons */}

        {!integrated && Organization.isOwner() && (
          <CustomButton
            style={{ paddingInline: '0.5rem' }}
            className="secondaryBtn"
            onClick={handleBeginIntegration}
          >
            Begin integration
          </CustomButton>
        )}
        {!integrated && !Organization.isOwner() && (
          <p className="paragraph">Ask your owner to make this integration!</p>
        )}
        {!!integrated && <span className={styles.connected}>Connected</span>}
      </div>
    </div>
  );
};

export default ProviderCard;

const VideoRecorderModule = (function () {
  const allowedOrgs = [
    '8fw5HGhW',
    'HJAC9lMz',
    'xu2xOEo0',
    'KzGY6qQE',
    'nJFKIhod',
    'LojGhCtd',
    'WXlVIpkn',
    'U1YlEo4t',
    '8M3Knh6H',
  ];

  const getOrganizationSlug = () => JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg?.slug;

  const show = () => allowedOrgs.includes(getOrganizationSlug());

  return { show };
})();

export default VideoRecorderModule;

import React from 'react';
import useAssessment from '../hooks/useAssessment';
import styles from './MultipleChoice.module.scss';

const CriteriaHeader = ({ item }) => {
  const { isMultipleChoice } = useAssessment();
  if (!isMultipleChoice({ item }))
    return <p style={{ fontSize: '12px', marginBottom: '6px' }}>Rating Criteria</p>;

  return (
    <>
      <p className={styles.label}>Possible Answers</p>
      <p className={styles.subtitle}>
        By default 'Option 1' is the correct answer and it will be shuffled to each sales rep
      </p>
    </>
  );
};

export default CriteriaHeader;

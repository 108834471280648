import { Checkbox, withStyles, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
const PrimaryCheckbox = withStyles({
  root: {
    color: '#6A61E7',
    '&$checked': {
      color: '#6A61E7',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SecondaryCheckbox = withStyles({
  root: {
    color: '#EEF1F6',
    '&$checked': {
      color: '#EEF1F6',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CustomCheckbox(props) {
  const {
    value,
    label,
    onChange,
    type = 'primary',
    isMarginBottomNeeded = true,
    disabled = false,
  } = props;
  return (
    <div
      className={`${isMarginBottomNeeded ? 'inputGroup' : ''} checkboxGroup`}
      style={{ marginBottom: props.noMargin ? '-2px' : '' }}
    >
      <FormControlLabel
        value={value}
        disabled={disabled}
        control={
          type == 'secondary' ? (
            <SecondaryCheckbox checked={value} onChange={(e) => onChange(e.target.checked)} />
          ) : (
            <PrimaryCheckbox checked={value} onChange={(e) => onChange(e.target.checked)} />
          )
        }
        label={label}
        labelPlacement="end"
      />
      {props.iserror ? <h6 className="errorMsg">{props.iserror}</h6> : null}
    </div>
  );
}

CustomCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.element,
  ]),
  type: PropTypes.oneOf(['primary', 'secondary']),
  onChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  value: true,
  label: '',
  type: 'primary',
  onChange: (e) => {
    console.log(e.target.value);
  },
};

import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from '../../../assets/styles/onboarding.module.scss';
import { FeatureFlag } from 'controllers/_exports';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'OnboardingLanding' && (
        <>
          <section className={styles.setupOnboardingsection} style={{ minHeight: '70px' }}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={styles.titleWrapper}>
                  <div className={styles.imgBlk}>
                    <Skeleton
                      variant="circle"
                      width={48}
                      height={48}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </div>
                  <div className={styles.contentBlk}>
                    <h1 className="heading4">
                      {' '}
                      <Skeleton variant="text" width="20%" style={{}} />
                    </h1>
                    <p className="supportText">
                      <Skeleton variant="text" width="60%" style={{}} />
                    </p>
                    <p className="supportText">
                      <Skeleton variant="text" width="60%" style={{}} />
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={styles.stepsWrapper}>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.progressBlk}>
                        <Skeleton variant="text" width="60%" style={{}} />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.stepsBlk}>
                        <h2 className="heading3">
                          <Skeleton variant="text" width="40%" style={{}} />
                        </h2>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
          <section className={styles.onboardingStepsection}>
            <Grid container direction="row" spacing={2} className={styles.stepsRow}>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <div className={styles.indexListWrapper}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.titleRow}
                  >
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                      <h5 className="heading6">
                        <Skeleton variant="text" width="40%" style={{}} />
                      </h5>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12} className="textRight">
                      <Skeleton variant="text" height={34} style={{}} />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.titleRow}
                  >
                    <Grid item xs={12}>
                      <div className="indexList">
                        {[0, 1, 2, 3, 4, 5, 6, 7].map((item, key) => (
                          <div key={key} className="listItem">
                            <a className="">
                              <div
                                className="typeBlk"
                                style={{ width: '30px', paddingLeft: '20px' }}
                              >
                                <Skeleton
                                  variant="circle"
                                  width={30}
                                  height={30}
                                  style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                                />
                              </div>
                              <span className={`title supportTextSemibold`}>
                                <Skeleton variant="text" width="90%" style={{}} />
                              </span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <div className={styles.introductionCardWrapper}>
                  <Grid container direction="row" spacing={2} className={styles.titleRow}>
                    <Grid item lg={9} md={8} sm={12} xs={12}>
                      <h3 className="heading4">
                        <Skeleton variant="text" width="30%" style={{}} />
                      </h3>
                      <p className="paragraph">
                        <Skeleton variant="text" width="70%" style={{}} />
                      </p>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12}>
                      <List className="listInline textRight">
                        <ListItem
                          className={styles.infoItem + ' listInlineItem'}
                          style={{ width: '40%' }}
                        >
                          <Skeleton variant="text" width="90%" style={{}} />
                        </ListItem>
                        <ListItem className="listInlineItem">
                          <Skeleton variant="text" width={40} style={{}} />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                  <div className={styles.contentWrapper}>
                    <div
                      className={styles.videoWrapper + ' ' + styles.shimmerVideoWrapper}
                      style={{ width: '370px', background: 'rgba(0, 0, 0, 0.11)' }}
                    >
                      <div className={styles.videoContent + ' hidden'}>
                        <p className="paragraph">
                          <Skeleton variant="text" width="40%" style={{}} />
                        </p>
                      </div>
                      <div className={styles.overlay}></div>
                    </div>

                    <div className={styles.contentBlk}>
                      <h4 className="heading4">
                        <Skeleton variant="text" width="20%" style={{}} />
                      </h4>
                      {[0, 1, 2, 3, 4].map((item, key) => (
                        <div key={key} className="paragraph" style={{ marginBottom: '12px' }}>
                          <Skeleton variant="text" width="90%" style={{}} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.addvideoBtnBlk + ' textCenter'}>
                    <Skeleton variant="text" width="40%" height={34} style={{}} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </>
      )}
      {props.type == 'OnboardingOverview' && (
        <>
          <section className="overviewProgressSection">
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} md={6} className="flexNotCentered">
                <div className="progressChart" style={{ position: 'relative' }}>
                  <h3 className="heading5">
                    <Skeleton variant="text" width="25%" height={34} style={{}} />
                  </h3>
                  <Skeleton
                    variant="circle"
                    width={150}
                    height={150}
                    style={{ marginLeft: 100, marginTop: 40 }}
                    className={styles.overviewCircle}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 83,
                      left: '56%',
                      width: '100%',
                    }}
                  >
                    <Skeleton variant="text" width="15%" height={34} style={{ marginBottom: 10 }} />
                    <Skeleton variant="text" width="35%" height={24} style={{ marginBottom: 25 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{ marginBottom: 20 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{ marginBottom: 20 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{}} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="completionTime" style={{ position: 'relative' }}>
                  <Skeleton
                    variant="text"
                    width={60}
                    height={90}
                    style={{ position: 'absolute', left: 20, top: 8 }}
                  />
                  <h3 className="heading3">
                    <Skeleton variant="text" width="40%" height={34} style={{ marginLeft: 16 }} />
                  </h3>
                  <p className="paragraph">
                    <Skeleton variant="text" width="60%" height={24} style={{ marginLeft: 16 }} />
                  </p>
                </div>

                <div className="overallRatings">
                  <h3>
                    <Skeleton variant="text" width="20%" height={24} style={{}} />
                  </h3>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <h6 className="heading1">
                        <Skeleton
                          variant="text"
                          width="20%"
                          height={45}
                          style={{ marginBottom: 15 }}
                        />
                      </h6>

                      <p className="paragraph">
                        <Skeleton
                          variant="text"
                          width="40%"
                          height={24}
                          style={{ marginBottom: 10 }}
                        />
                        <Skeleton
                          variant="text"
                          width="40%"
                          height={24}
                          style={{ marginBottom: 10 }}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} className="ratingList">
                      <ul className="listUnstyled">
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
          {/* <section className={' dashboardListing'}>
                        <Grid container direction="row">
                            <Grid item md={12}>
                                <div className="teamProgressWrapper">
                                    <Grid container direction="row" spacing={4} className="headingRow">
                                        <Grid item md={5}>
                                            <Skeleton variant="text" width="25%" height={34} style={{ marginBottom: 30 }} />
                                        </Grid>
                                        <Grid item md={7}>
                                            <Skeleton variant="text" width="36%" height={40} style={{ float: 'right' }} />
                                        </Grid>
                                    </Grid>
                                    <div className="teamProgressDetail">
                                        <Grid container direction="row" spacing={4} className="titleRow">
                                            <Grid item md={3}>
                                                <Skeleton variant="text" width="50%" height={24} style={{}} />
                                            </Grid>
                                            <Grid item md={2}>
                                                <Skeleton variant="text" width="50%" height={24} style={{}} />
                                            </Grid>
                                            <Grid item md={2}>
                                                <Skeleton variant="text" width="50%" height={24} style={{}} />
                                            </Grid>
                                            <Grid item md={2}>
                                                <Skeleton variant="text" width="50%" height={24} style={{}} />
                                            </Grid>
                                            <Grid item md={3}>
                                                <Skeleton variant="text" width="50%" height={24} style={{}} />
                                            </Grid>
                                        </Grid>

                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </section> */}
        </>
      )}
      {props.type == 'OnboardingOverviewUsers' &&
        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
          <section key={key} className={' dashboardListing'}>
            <Grid container direction="row">
              <Grid item md={12}>
                <div className="contentBlock" style={{ position: 'relative' }}>
                  <Grid container direction="row" spacing={4} className="titleRow">
                    <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 2 : 3}>
                      <Skeleton
                        variant="circle"
                        width={40}
                        height={40}
                        style={{ position: 'absolute', top: 15, left: 0 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 8 }}
                      />
                      <Skeleton variant="text" width="65%" height={18} style={{ marginLeft: 50 }} />
                    </Grid>
                    <Grid item md={2}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 15 }} />
                    </Grid>
                    <Grid item md={2}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 15 }} />
                    </Grid>
                    <Grid item md={2}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 15 }} />
                    </Grid>
                    {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && 
                      <Grid item md={2}>
                        <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 15 }} />
                      </Grid>
                    }
                    <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 2 : 3}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 15 }} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
        ))}
    </>
  );
}

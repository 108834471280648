import { Grid, Avatar } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { format, parseISO } from 'date-fns';
import { changeTimezone } from '../../../utils';

export default function MemberInfo(props) {
  const { user = {} } = props;
  return (
    <div className={styles.memberInfoWrapper}>
      <Grid container direction="row" spacing={3} className={styles.infoRow}>
        <Grid item xs={7} className={styles.leftBlk}>
          <div className={styles.profileWrapper}>
            <div className={styles.imgBlk}>
              {user?.user?.profile_pic ? (
                <img
                  className={styles.profilePic}
                  src={user?.user?.profile_pic}
                  alt="Profile pic"
                />
              ) : (
                <Avatar className={styles.profilePic + ' avatar ' + styles.avatar}>
                  {user?.user?.first_name
                    ? user?.user?.first_name[0]
                    : user?.user?.email
                    ? user?.user?.email[0]
                    : 'S'}
                </Avatar>
              )}
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">
                {user?.user?.first_name || user?.user?.last_name
                  ? `${user?.user?.first_name} ${
                      user?.user?.last_name ? user?.user?.last_name : ''
                    }`
                  : `--`}
                <span className="supportText">
                  <img src={imageBasePath + 'checkin_icon.svg'} alt="image" />
                  {`Last check-in: ${
                    user.last_checkin
                      ? format(changeTimezone(new Date(user.last_checkin)), 'do MMM yyyy, hh:mm aa')
                      : '--'
                  }`}
                </span>
              </h4>
              <h5 className="supportText">
                <img src={imageBasePath + 'designation_icon.svg'} alt="designation" />
                <span>{user?.designation ? user?.designation : '--'}</span>
              </h5>
              <h6 className="supportText">
                <img src={imageBasePath + 'email_icon.svg'} alt="image" />
                <span>{user?.user?.email}</span>
                {/* <span>{user?.user?.email.length < 26
                    ? user?.user?.email
                    : user?.user?.email.substring(0, 25)}
                </span> */}
              </h6>
            </div>
          </div>
        </Grid>
        <Grid item xs={5} className={styles.rightBlk + ' hidden'}>
          <p className="supportText">Monthly avg goal achievement</p>
          <h6 className="heading3">53%</h6>
          <div className={styles.graphBlk}></div>
        </Grid>
      </Grid>
    </div>
  );
}

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import arrow from 'assets/stream/arrow_l.svg';
import stream from 'assets/stream/stream.svg';
import styles from './HomeCard.module.scss';
import Icon from 'features/stream/UI/components/Icon/Icon';
import HomeCardLogic from './HomeCard.logic';
import { Shimmer } from 'features/home/components';

const HomeCard = ({ user }) => {
  const { options, data, isLoading, realData, hasData } = HomeCardLogic();
  if (isLoading) return <Shimmer type="AdminModuleSetup" />;
  if (!hasData) return null;

  return (
    <div className={styles.homeCardContainer}>
      <div className={styles.homeCardHeader}>
        <div className={styles.title}>
          <div className={styles.imageContainer}>
            <img className={styles.icon} src={stream} alt="icon" />
          </div>
          <h1>Pulse</h1>
        </div>
        <div className={styles.homeLink}>
          <a href="/pulse">Details</a>
          <img className={styles.icon} src={arrow} alt="comments" />
        </div>
      </div>

      <div>
        {user === 'user' ? ( //sales rep
          data?.total_user_posts === 0 ? (
            <div className={styles.homeCardContent} style={{ textAlign: 'center' }}>
              Your expertise matters! Join the conversation and help others learn from your
              knowledge
            </div>
          ) : (
            <div className={styles.homeCardContent}>
              <div className={styles.homeChart}>
                <div className={styles.ChartWrapper}>
                  <Doughnut options={options} data={realData} />
                </div>
                <div className={styles.totalPosts}>
                  <strong>{data?.total_user_posts || data?.total_posts || 0} </strong>
                  <span>Total Posts</span>
                </div>
              </div>

              <div className={styles.oneColumn}>
                <div className={styles.sixColumn}>
                  {data.categories.map((category) => {
                    return (
                      <div className={styles.contentLabel}>
                        <Icon
                          withBackground={false}
                          width={14}
                          height={14}
                          src={category?.category?.picture_icon}
                        />
                        <span>
                          {category?.category?.name} <strong>{category?.user_post_count}</strong>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )
        ) : //Owner, Admin
        data?.total_posts === 0 ? (
          <div className={styles.homeCardContent} style={{ textAlign: 'center' }}>
            Your expertise matters! Join the conversation and help others learn from your knowledge
          </div>
        ) : (
          <div className={styles.homeCardContentAdmin}>
            <div className={styles.oneColumnAdmin}>
              <div className={styles.sixColumnAdmin}>
                {data.categories.map((category) => {
                  return (
                    <div className={styles.contentLabelAdmin}>
                      <Icon
                        withBackground={false}
                        width={14}
                        height={14}
                        src={category?.category?.picture_icon}
                      />
                      <span>
                        {category?.category?.name} <strong>{category?.post_count}</strong>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.ChartWrapper}>
              <Doughnut options={options} data={realData} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeCard;

import React from 'react';
import styles from './SubmitReview.module.scss';
import SubmitReviewHeader from './SubmitReviewHeader';
import SubmitReviewContent from './SubmitReviewContent';
const SubmitReviewResume = () => {
  return (
  <div className={styles.background}>
    <SubmitReviewHeader />
    <SubmitReviewContent />
  </div>);
};

export default SubmitReviewResume;

import React, { useMemo, useState } from 'react';
import styles from './CardRep.module.scss';
import { Doughnut } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import DateChip from '../AssignedLearningPathDetails/DateChip';
import ContentsChip from '../AssignedLearningPathDetails/ContentsChip';
import DeadlineBubble from '../common/DeadlineChip';
import { useStartLearningPathMutation } from '../../LearningPaths.queries';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { TooltipCustom } from 'utils';

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  cutout: '80%',
  radius: '90%',
};

const CardRep = ({ data = {}, isLoading = false, completed = 1, hasInProgress = true }) => {
  const canBeClicked = useMemo(() => completed === 0 && !hasInProgress, [completed, hasInProgress]);

  if (isLoading) return null;
  if (completed === 1)
    return (
      <TooltipCustom
        placement="bottom"
        title={
          <p>Overall Completion percentage is displayed based on your previous training progress</p>
        }
      >
        <div className={`${styles.container} ${styles.interactive}`}>
          <Card
            completed={completed}
            data={data}
            hasInProgress={hasInProgress}
            canBeClicked={canBeClicked}
          />
        </div>
      </TooltipCustom>
    );
  return (
    <div className={`${styles.container} ${canBeClicked ? styles.interactive : ''}`}>
      <Card
        completed={completed}
        data={data}
        hasInProgress={hasInProgress}
        canBeClicked={canBeClicked}
      />
    </div>
  );
};
const Card = ({ data = {}, completed = 1, canBeClicked = false }) => {
  const { setSelectedRepLearningPathID } = useLearningPathsStore();

  const learningPath = useMemo(() => {
    return data?.learning_path;
  }, [data]);
  const chartData = useMemo(
    () => ({
      labels: ['Overall completion'],
      datasets: [
        {
          label: '',
          data: [100 - data?.overall_completion],
          borderWidth: 1,
          backgroundColor: ['#CFD8DC'],
          borderColor: ['#CFD8DC'],
          hoverBorderColor: ['#CFD8DC'],
          hoverBackgroundColor: ['#CFD8DC'],
          hoverBorderWidth: 0,
          hoverBorderRadius: 0,
          hoverOffset: 0,
        },
      ],
    }),
    [data?.overall_completion]
  );
  const chartDataCompleted = useMemo(
    () => ({
      labels: ['Overall completion'],
      datasets: [
        {
          label: '',
          data: [data?.overall_completion],
          borderWidth: 1,
          backgroundColor: ['#2DC38D'],
          borderColor: ['#2DC38D'],
          hoverBorderColor: ['#2DC38D'],
          hoverBackgroundColor: ['#2DC38D'],
          hoverBorderWidth: 0,
          hoverBorderRadius: 0,
          hoverOffset: 0,
        },
      ],
    }),
    [data?.overall_completion]
  );

  // Select a path yet to start
  const { mutate } = useStartLearningPathMutation();
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    const uuid = data?.learning_path?.uuid;
    if (completed === 1) {
      setSelectedRepLearningPathID(uuid);
      return;
    }
    if (!canBeClicked || clicked) return;
    setClicked(true);
    mutate({ uuid });
  };

  return (
    <div className={`${styles.main}`} onClick={handleClick}>
      <div className={styles.graphContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ height: '180px', position: 'relative', maxWidth: '200px' }}
        >
          <div className={styles.totalContainer}>
            {completed === 1 ? (
              <>
                <h6 className="heading1">{data?.overall_completion}%</h6>
                <p className="paragraph">Overall</p>
                <p className="paragraph">Completion</p>
              </>
            ) : (
              <>
                <p className="paragraph">Yet to</p>
                <p className="paragraph">start</p>
              </>
            )}
          </div>
          <Doughnut data={completed === 1 ? chartDataCompleted : chartData} options={options} />
        </Grid>
      </div>

      <div className={styles.mainText}>
        <div className={styles.headerText}>
          <div className={styles.headerTextTitle}>{learningPath?.title}</div>
          <div className={styles.headerTextAuthor}>
            By {`${learningPath?.created_by.first_name} ${learningPath?.created_by.last_name}`}
          </div>
        </div>
        <div className={styles.description}>
          {learningPath?.description.slice(0, 250)}
          {learningPath?.description.length >= 250 ? '...' : ''}
        </div>
        <div className={styles.bubblesContainer}>
          {learningPath?.updated_at && <DateChip isUpdated date={learningPath?.updated_at} />}
          <ContentsChip total={learningPath?.total_contents} />
        </div>
        <DeadlineBubble deadline={learningPath?.deadline} />
      </div>
    </div>
  );
};

export default CardRep;

import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
} from '../../utils/globalApiServices';

export function getTrackingGoalsApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/goal-categories/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function trackingSalesGoalsReorderApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/goal-categories/sales-goals/re-order/`, {
      sales_goals_names: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getOrganisationGoalsOverviewApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/organisation/overview/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getSalesGoalsOverviewApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/overview/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getSalesGoalProgressApi(id, query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/goal-progress/${id}/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getHubspotGoalProgressApi(id, query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/hubspot-progress/${id}/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTopPerformersApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/top-performers/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getBottomPerformersApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/bottom-performers/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// export function getTeamActivityApi(query={}, successCallback, errorCallback){
//     return new Promise((resolve, reject) => {
//         globalGetService(`/tracking/team-activity/`, query)
//         .then(response => {
//           resolve(response);
//         }).catch((err) => {reject(err)})
//     })
// }
export function getTeamActivityApi(query, successCallback, errorCallback) {
  globalGetService(`/tracking/team-activity/`, query).then((response) => {
    if (response.statusCode === 200) {
      if (successCallback) {
        successCallback(response);
      }
    } else {
      if (errorCallback) {
        errorCallback(response);
      }
    }
  });
}

export function createGoalsApi(id, formData = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/category/${id}/goals/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function publishTrackingApi() {
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/publish/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getCurrentCheckinApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/checkin/current/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUsersListApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/sales/reps/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function checkinUpdateApi(data, start_date) {
  if (!!start_date) return lateCheckin({ data, start_date });
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/goals/checkin/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function checkinHistory(data) {
  return new Promise((resolve, reject) => {
    globalGetService(`/tracking/history/checkin/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserPreferencesApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/category/details/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadReport() {
  return new Promise((resolve, reject) => {
    globalExportService(`/tracking/dashboard/download/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function checkinEditApi(data, start_date) {
  const body = {
    ...data,
    categories: data?.categories?.filter((c) => !c?.name?.toLowerCase().includes('hubspot')),
  };
  if (!!start_date) return lateCheckin({ body, start_date });
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/update-checkin/`, body)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const lateCheckin = async ({ data, start_date }) => {
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/goals/late/checkin/`, {
      ...data,
      start_date,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

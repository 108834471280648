import { useEffect, useMemo, useState } from 'react';
import styles from './PostTextArea.module.scss';
import Organization from 'infrastructure/Organization';

const UserItem = ({ user, active, onClick, setHovered }) => (
  <div
    className={`${styles.userItem} ${active ? styles.active : ''}`}
    onMouseEnter={() => setHovered(user?.email)}
    onClick={onClick}
  >
    <img className={styles.image} alt="" src={user?.profile_pic} />
    {user?.email}
  </div>
);

const UserTagsList = ({
  allUsers = [],
  showUsersList,
  cursorPosition,
  handleSelect,
  searchValue,
  loading,
  isComment,
}) => {
  const [email, setEmail] = useState('');

  // Users filter
  const users = useMemo(() => {
    // Remove current user
    const usersToTag = allUsers?.filter((u) => u?.uuid !== Organization.getUserInfo()?.uuid);
    if (!searchValue) return usersToTag;
    const search = searchValue?.substring(1)?.replaceAll('\n', '');
    const newUsers = usersToTag?.filter((u) => u?.email?.includes(search));
    return newUsers?.length > 0 ? newUsers : usersToTag;
  }, [allUsers, searchValue]);

  useEffect(() => {
    if (!users?.length > 0) return;
    setEmail(users[0]?.email);
  }, [users]);

  // Selected user to parent component
  const selectUser = (_email) => {
    if (!users?.length > 0 || !showUsersList) return;
    const check = _email || email || users[0]?.email;
    let u = users?.find((u) => u?.email === check);
    handleSelect(u);
  };

  // Up and Down arrow handlers
  const upPress = useKeyPress('ArrowUp');
  const downPress = useKeyPress('ArrowDown');

  useEffect(() => {
    if (!downPress || !users.length > 0) return;
    const currentIndex = users?.findIndex((u) => u?.email === email);
    const newMail = currentIndex === users?.length - 1 ? email : users[currentIndex + 1]?.email;
    setEmail(newMail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress, users]);

  useEffect(() => {
    if (!upPress || !users.length > 0) return;
    const currentIndex = users?.findIndex((u) => u?.email === email);
    const newMail = currentIndex === 0 ? email : users[currentIndex - 1]?.email;
    setEmail(newMail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress, users]);

  // Enter handler
  const enterPress = useKeyPress('Enter');
  useEffect(() => {
    if (enterPress) selectUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPress]);

  if (loading)
    return (
      <div
        style={{
          top: cursorPosition?.top + 15,
          left: cursorPosition?.left + 15,
          visibility: showUsersList ? 'visible' : 'hidden',
        }}
        className={styles.userTagList}
      >
        Loading users...
      </div>
    );
  if (!users?.length > 0) return null;
  return (
    <div
      style={{
        top: cursorPosition?.top + 15,
        left: cursorPosition?.left + 15,
        visibility: showUsersList ? 'visible' : 'hidden',
      }}
      className={`${styles.userTagList} ${isComment ? styles.commentList : ''}`}
    >
      {users?.map((user, index) => (
        <UserItem
          user={user}
          key={user?.uuid}
          index={index}
          active={user?.email === email}
          setHovered={(value) => setEmail(value)}
          onClick={() => selectUser(user?.email)}
        />
      ))}
    </div>
  );
};

export default UserTagsList;

const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);
  useEffect(() => {
    const downHandler = ({ key }) => key === targetKey && setKeyPressed(true);
    const upHandler = ({ key }) => key === targetKey && setKeyPressed(false);

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);
  return keyPressed;
};

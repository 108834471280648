import React, { useState, useEffect } from 'react';
import {
  IntroductionCard,
  RecruitmentOptions,
  IntegrateProviders,
  UpdateProviderJobs,
  Overview,
} from './index';
import { getProvidersApi, getJobsApi, connectApi, providerJobUpdateApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import { SuccessDialog } from '../../../shared_elements';

export default function Integration(props) {
  const {
    enableDisable,
    jobs,
    getJobs,
    handleIntegrateProviders,
    currentIntegrationSet,
    addJobManuallySet,
    handleConfigure,
    handleChangeToken,
  } = props;
  const [jobProviders, jobProvidersSet] = useState([]);

  useEffect(() => {
    jobProvidersSet(props.jobProviders);
  }, [props.jobProviders]);

  return (
    <div>
      <RecruitmentOptions
        handleIntegrateProviders={handleIntegrateProviders}
        providers={jobProviders}
        currentIntegrationSet={currentIntegrationSet}
        addJobManuallySet={addJobManuallySet}
        handleConfigure={handleConfigure}
        handleChangeToken={handleChangeToken}
        enableDisable={enableDisable}
      />
    </div>
  );
}

import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';

export default function OverviewTitle(props) {
  const { title, moduleLink = '/', icon, secondModuleLink } = props;
  return (
    <div className={styles.overviewTitleBlk}>
      <h3 className={`heading5`}>
        <img src={icon} className={styles.moduleIcon} />
        <span>{title}</span>
      </h3>
      <div className={styles.gotoModuleLinkContainer}>
        <Link to={moduleLink} className={styles.gotoModuleLink + ' supportTextSemibold'}>
          <span>Details</span>
          <img src={`${imageBasePath}right_arrow_icon.svg`} alt="" />
        </Link>

        {secondModuleLink && (
          <Link to={secondModuleLink} className={styles.gotoModuleLink + ' supportTextSemibold'}>
            <span>Continue setup</span>
            <img src={`${imageBasePath}right_arrow_icon.svg`} alt="" />
          </Link>
        )}
      </div>
    </div>
  );
}

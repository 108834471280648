import React, { useMemo } from 'react';
import { List, ListItem, Grid } from '@material-ui/core';
import { ProgressBar } from '../shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';

export default function DashboardHeader(props) {
  const { dashboardLogo, title, tabs = [], progress = '', steps = [] } = props;
  const timeEstimated = useMemo(() => {
    if (!steps?.length > 0) return 0;
    const seconds = steps?.reduce((acc, step) => {
      if (step?.is_required && step?.status !== 0) {
        return acc + (step?.suggest_duration || 0);
      } else {
        return acc;
      }
    }, 0);
    return (seconds / 60).toFixed(0);
  }, [steps]);

  return (
    <div>
      <section className={`${props.isRoleplayPage ? 'headerSectionRoleplay' : 'headerSection'}`}>
        <Grid container direction="row">
          <Grid
            item
            xs={
              progress && progress.totalSteps != undefined && progress.completedSteps != undefined
                ? 8
                : 12
            }
          >
            <div
              className="pageTitleBlock"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div>
                <h1>
                  <img src={dashboardLogo} alt="dashboard icon" />
                  {title}
                </h1>
                {tabs && tabs.length ? 
                (
                  <List className="listInline tabList">
                    {tabs.map((tab, key) => (
                      <ListItem
                        key={key}
                        className={`listInlineItem heading6 ${tab.isActive ? 'active' : ''}`}
                        onClick={() => props.handleChange(tab.value)}
                      >
                        {tab.title}
                      </ListItem>
                    ))}
                    {/* <ListItem className={`listInlineItem heading6 `}  >Library</ListItem> */}
                  </List>
                )
                 : null}
              </div>
              <div>
                {FeatureFlag.enabled('TRACK_TIME') &&
                timeEstimated !== 0 &&
                (tabs[1] ? tabs[1]?.isActive : true) ? (
                  <div className="supportText" style={{ marginRight: '1rem' }}>
                    Total estimated time of completion for the entire onboarding: <strong> {timeEstimated} min</strong>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </Grid>
          {progress && progress.totalSteps ? (
            <Grid item xs={4}>
              <div className={`dashboardProgressWrapper`}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className={`progressBlk`}>
                      <ProgressBar
                        value={(progress.completedSteps / progress.totalSteps) * 100}
                        max="100"
                        className={'stepProgress'}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className={`stepsBlk`}>
                      <h2 className="heading3">
                        <span
                          className={'steps heading3'}
                        >{`${progress.completedSteps}/${progress.totalSteps}`}</span>
                        <span className={'supportText'}> Mandatory Steps</span>
                      </h2>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </section>
    </div>
  );
}

import { Grid, Popover } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { CustomTooltipWhite, CustomDateRangePicker } from '../../../shared_ui_components';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import classNames from 'classnames';

const options = [
  { value: 'test1', label: 'test1' },
  { value: 'test2', label: 'test2' },
  { value: 'test3', label: 'test3' },
];

export default function OverviewTitle(props) {
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        className={classNames([styles.titleRow, props?.noMargin && styles.noMargin])}
      >
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={styles.titleWrapper}>
            <img
              className={styles.featuredImg}
              src={imageBasePath + props.image}
              alt={props.title}
            />
            <div className={styles.info}>
              <h2 className="heading5">
                {props.title}
                <span className={styles.tag + ' supportText'} style={{ margin: '0px 8px 0' }}>
                  {props.frequency}
                </span>
                {props.tooltip ? (
                  <CustomTooltipWhite
                    tooltipIcon="info_icon.svg"
                    title={props.tooltip}
                    className={styles.performanceInfoTooltip}
                    style={{ width: '350px;' }}
                  />
                ) : null}
              </h2>
              <p className="paragraph">{props.description}</p>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          {props.handleApplyFilter ? (
            <div className={styles.filterBlk}>
              {/* <InputSelect
                  closeMenuOnSelect={true}
                  options={options}
                  isSearchable={true}
                  placeholder={'Select Quater'}
                  noOptionsMessage={() => 'No matches found'}
                // onChange={(selectedOption) =>
                //   handleChange('portfolio', selectedOption ? selectedOption : [])
                // }
                // value={productInfo.portfolio}
                // isCreatable={true}
                // isClearable
                /> */}
                {/* <div>{console.log(props)}</div> */}
              <CustomDateRangePicker
                minDate={props.minDate}
                frequency={props.filterFrequency}
                filter={props.appliedFilters}
                handleApply={(query) => props.handleApplyFilter(query)}
                defaultFilter={props.defaultFilter}
                type={props.type}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

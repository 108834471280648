import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { InputText, CustomButton } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';
import { addJobManually, updateJobManually } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { toastFlashMessage } from '../../../utils';

export default function AddJobDialog(props) {
  const { type, job } = props;
  const [open, setOpen] = useState(false);
  const [formData, formDataSet] = useState({
    job_title: '',
    location: '',
    total_application: 0,
    interviews_conducted: 0,
    offers_made: 0,
    accepted_candidates: 0,
  });
  const [loader, loaderSet] = useState(false);
  const [error, errorSet] = useState({});
  const [errorCode, errorCodeSet] = useState({
    job_title: {
      0: '',
      1: 'Please enter your job title before continuing',
    },
    job_titleObj: {
      requiredFlag: true,
    },
    location: {
      0: '',
      1: 'Please enter your location before continuing',
    },
    locationObj: {
      requiredFlag: true,
    },
    total_application: {
      0: '',
      1: 'Please enter total applications',
    },
    total_applicationObj: {
      requiredFlag: true,
    },
    interviews_conducted: {
      0: '',
      1: 'Please enter interviews conducted',
    },
    interviews_conductedObj: {
      requiredFlag: true,
    },
    offers_made: {
      0: '',
      1: 'Please enter offers made',
    },
    offers_madeObj: {
      requiredFlag: true,
    },
    accepted_candidates: {
      0: '',
      1: 'Please enter total candidates accepted',
    },
    accepted_candidatesObj: {
      requiredFlag: true,
    },
  });

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
    formDataSet({
      job_title: '',
      location: '',
      total_application: 0,
      interviews_conducted: 0,
      offers_made: 0,
      accepted_candidates: 0,
    });
    errorSet({});
  };

  useEffect(() => {
    setOpen(props.open);
    if (props.type && props.type === 'edit') {
      formDataSet({
        job_title: job.job_title,
        location: job.location,
      });
    }
  }, [props.open]);

  const handleChange = (key, value) => {
    formDataSet({
      ...formData,
      [key]: value,
    });
    errorSet({
      ...error,
      [key]: '',
    });
  };

  const handleSubmit = () => {
    loaderSet(true);
    let validateNewInput = {
      job_title:
        errorCode['job_title'][
          fieldValidation({ ...errorCode['job_titleObj'], fieldval: formData.job_title })
        ],
      location:
        errorCode['location'][
          fieldValidation({ ...errorCode['locationObj'], fieldval: formData.location })
        ],
    };

    if (type !== 'edit') {
      validateNewInput = {
        ...validateNewInput,
        total_application:
          errorCode['total_application'][
            fieldValidation({
              ...errorCode['total_applicationObj'],
              fieldval: formData.total_application?.toString(),
            })
          ],
        interviews_conducted:
          errorCode['interviews_conducted'][
            fieldValidation({
              ...errorCode['interviews_conductedObj'],
              fieldval: formData.interviews_conducted?.toString(),
            })
          ],
        offers_made:
          errorCode['offers_made'][
            fieldValidation({
              ...errorCode['offers_madeObj'],
              fieldval: formData.offers_made?.toString(),
            })
          ],
        accepted_candidates:
          errorCode['accepted_candidates'][
            fieldValidation({
              ...errorCode['accepted_candidatesObj'],
              fieldval: formData.accepted_candidates?.toString(),
            })
          ],
      };
    }

    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      if (type === 'edit') {
        delete formData.total_application;
        delete formData.interviews_conducted;
        delete formData.offers_made;
        delete formData.accepted_candidates;
        updateJobManually(job.uuid, formData).then((response) => {
          loaderSet(false);
          if (response.statusCode === 200) {
            handleClose();
            props.updateManualJob('update', response.data);
            toastFlashMessage(response.message, 'success');
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
      } else {
        addJobManually(formData).then((response) => {
          loaderSet(false);
          if (response.statusCode === 200) {
            loaderSet(false);
            handleClose();
            props.callBackFn();
            toastFlashMessage(response.message, 'success');
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
      }
    } else {
      loaderSet(false);
      errorSet(validateNewInput);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.addNewJobDialog}`}
      >
        <DialogTitle id="add-video-title" className={styles.dialogTitle}>
          <h1 className={styles.title + ' heading4'}>
            <img src={imageBasePath + 'add_job_manually.svg'} alt="img" />
            {type === 'edit' ? (
              <span>Edit job: {job.job_title}</span>
            ) : (
              <span>Add Job to track Manually</span>
            )}
          </h1>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogBody}>
            <form>
              <InputText
                id="job-title"
                label="Job Title"
                placeholder="Sales Representative"
                value={formData.job_title}
                onChange={(e) => handleChange('job_title', e.target.value)}
                error={error.job_title}
                className={error.job_title ? 'errorGroup' : ' '}
              />
              <InputText
                id="location"
                label="Location"
                placeholder="New York"
                value={formData.location}
                onChange={(e) => handleChange('location', e.target.value)}
                error={error.location}
                className={error.location ? 'errorGroup' : ' '}
              />
              {type !== 'edit' ? (
                <>
                  <h2 className="heading5">Application Status</h2>
                  <Grid container direction="row" spacing={2} className={styles.fieldsRow}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="total-application"
                        label="Total Applications"
                        placeholder="0"
                        type="text"
                        // InputProps={{ inputProps: { min: 0} }}
                        value={formData.total_application}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('total_application', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('total_application', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.total_application}
                        className={error.total_application ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="interviews-conducted"
                        label="Interviews Conducted"
                        placeholder="0"
                        type="text"
                        value={formData.interviews_conducted}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('interviews_conducted', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('interviews_conducted', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.interviews_conducted}
                        className={error.interviews_conducted ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="offers-made"
                        label="Offers Made"
                        placeholder="0"
                        type="text"
                        value={formData.offers_made}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('offers_made', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('offers_made', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.offers_made}
                        className={error.offers_made ? 'errorGroup' : ' '}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputText
                        id="candidated-accepted"
                        label="Candidates Accepted"
                        placeholder="0"
                        type="text"
                        value={formData.accepted_candidates}
                        onChange={(e) =>
                          Number.isInteger(Number(e.target.value)) && Number(e.target.value) > 0
                            ? handleChange('accepted_candidates', parseInt(e.target.value))
                            : e.target.value === '' || e.target.value === '0'
                            ? handleChange('accepted_candidates', e.target.value)
                            : e.preventDefault()
                        }
                        error={error.accepted_candidates}
                        className={error.accepted_candidates ? 'errorGroup' : ' '}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </form>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loader ? 'Please wait...' : type === 'edit' ? 'Update' : 'Add job'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
} from '../../utils/globalApiServices';

export function getTimezoneApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/timezones/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSettingApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/settings/update/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function acceptOwnershipTranfer(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/ownership/accept/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

import React, { useMemo } from 'react';
import Star from './Star';
import HalfStar from './HalfStar';
import { TooltipCustom } from 'utils';

const RoleplayRating = ({ stars, setStars = () => null, withTooltip }) => {
  const full_stars = useMemo(() => [...Array(Math.round(stars) || 0).keys()], [stars]);
  const half_star = useMemo(() => full_stars?.length < stars, [full_stars, stars]);
  const empty_stars = useMemo(() => {
    const length = 5 - (full_stars.length + Number(half_star)) || 0;
    return [...Array(Math.round(length)).keys()];
  }, [full_stars, half_star]);

  return (
    <TooltipCustom title={withTooltip ? stars : ''}>
      <span style={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem', width: 'fit-content' }}>
        {full_stars.map((s) => (
          <Star key={s} filled />
        ))}
        {half_star && <HalfStar />}
        {empty_stars.map((s) => (
          <Star key={s} filled={false} />
        ))}
      </span>
    </TooltipCustom>
  );
};

export default RoleplayRating;

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGetRoleplayView } from 'features/roleplay/Roleplay.queries';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import SubmitReviewResume from './SubmitReviewResume';

const SubmitReview = () => {
  const { uuid } = useParams();
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const setRoleplayView = useRoleplayStore((state) => state?.setRoleplayView);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);

  const { data, isLoading: isRoleplayViewLoading } = useGetRoleplayView({
    roleplay_id: uuid,
    enable: true,
  });

  useEffect(() => {
    return () => {
      setReviewScenarioSelected({});
      setRoleplayView({});
    };
  }, [setReviewScenarioSelected, setRoleplayView]);

  useEffect(() => {
    if (!data?.scenarios?.length > 0) return;
    setRoleplayView(data);
  }, [data, setRoleplayView]);
  if (isRoleplayViewLoading || !roleplayView?.scenarios?.length > 0) return null;

  return (
    <Grid container direction="row" spacing={2} style={{ padding: '1rem 0 0 1rem', width: '100%' }}>
      <Grid item xs={12} md={8} lg={6} style={{ margin: '0 auto' }}>
        <SubmitReviewResume />
      </Grid>
    </Grid>
  );
};

export default SubmitReview;

import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AssessmentTextboxOption = ({
  onChange = () => null,
  maxWords = 1000,
  allowEdit = true,
  initValue = '',
}) => {
  const [numWords, setNumWords] = useState(0);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const reactQuillRef = useRef();
  const editor = useRef(null);

  useEffect(() => {
    editor.current = reactQuillRef.current?.getEditor();
  }, [reactQuillRef]);

  const handleQuillChange = (text) => {
    if (!allowEdit) return;

    const previousValue = value;
    setValue(text);

    const words = editor.current
      ?.getText()
      ?.replaceAll('\n', ' ')
      .split(' ')
      .filter((w) => w !== '')?.length;

    setNumWords(words);

    if (words > maxWords) {
      editor.current?.blur();
      setValue(previousValue);
    } else onChange(words > 0 ? text : '');
  };

  return (
    <div>
      <ReactQuill
        modules={{
          toolbar: allowEdit,
        }}
        readOnly={!allowEdit}
        ref={reactQuillRef}
        theme="snow"
        value={value}
        onChange={handleQuillChange}
      />
      {allowEdit && (
        <div
          style={{
            fontSize: '0.7rem',
            textAlign: 'end',
            marginBottom: '1rem',
            color: numWords >= maxWords ? '#f0c800' : '',
          }}
        >
          Word count: {numWords}
        </div>
      )}
    </div>
  );
};

export default AssessmentTextboxOption;

import React from 'react';
import ReactPlayer from 'react-player';
const VideoPlayer = ({ src = '', onStart = () => null, onResume = () => null, onPause = () => null }) => {
  return (
    <ReactPlayer
      url={src}
      controls
      width="100%"
      height="70vh"
      onStart={onStart}
      style={{ minHeight: '70vh' }}
      onPlay={onResume}
      onPause={onPause}
      onError={()=> 'An error ocurred while loading the video'}
    />
  );
};

export default VideoPlayer;

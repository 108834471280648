import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/users.module.scss';
import { Skeleton } from '@material-ui/lab';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'Users' &&
        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
          <Grid container direction="row" spacing={2} className={styles.usersListingShimmer}>
            <Grid item xs={7}>
              <Grid container direction="row" spacing={2} className={styles.usersListingShimmerRow}>
                <Grid item xs={4}>
                  <Skeleton
                    variant="circle"
                    width={34}
                    height={34}
                    style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    className={styles.imgPlaceholder}
                  />
                  <Skeleton
                    variant="text"
                    width={160}
                    height={20}
                    style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    className={styles.imgText}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className={styles.usersCustomParagraph + ' paragraph '}>
                    <Skeleton
                      variant="text"
                      width={280}
                      height={20}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <p className={styles.usersCustomParagraph + ' paragraph '}>
                    <Skeleton
                      variant="text"
                      width={150}
                      height={20}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={5}>
                  <p className={styles.usersCustomParagraph + ' paragraph '}>
                    <Skeleton
                      variant="text"
                      width={170}
                      height={20}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <p className={styles.usersCustomParagraph + ' paragraph '}>
                    <Skeleton
                      variant="text"
                      width={100}
                      height={20}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <div className={styles.usersMoreActionWrap}>
                    <div className={styles.userStatusWrap}>
                      <Skeleton
                        variant="text"
                        width={90}
                        height={20}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
}

import React, { useMemo, useState } from 'react';

import { diffInDays, changeTimezone, isPreviousFriday, isMondayDay, isPreviousSaturday, isPreviousSunday } from '../../utils';
import { CustomDateRangePicker } from '../../shared_ui_components';
import './FlexiblePeriod.scss';
import { FeatureFlag } from 'controllers/_exports';

const FlexiblePeriod = ({ salesGoals, date, handleSelectNewDate = () => null }) => {
  const { frequency } = salesGoals;
  const [appliedFilters, setAppliedFilters] = useState({});
  const flexibleCheckIn = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg
    ?.flexible_checkin_windows;

  const enableSubmit = useMemo(() => {
    let todayFormatted = changeTimezone(new Date());
    let difDays = diffInDays(todayFormatted, new Date(date.start_date));
    switch (frequency) {
      case 1:
        return difDays <= 1;
      case 2:
        return difDays <= 2;
      case 3:
        return difDays <= 5;
      case 4:
        return difDays <= 10;
      default:
        return false;
    }
  }, [date.start_date, frequency]);

  const formatDate = (date) => {
    const dateString = new Date(date);
    
    let month = dateString.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    let day = dateString.getDate();
    if (day < 10) day = `0${day}`;
    let year = dateString.getFullYear();

    const newDate = year + '-' + month + '-' + day;
    return newDate;
  };

  const subtractTime = (date) => {
    const actualDate = new Date(date);
    frequency === 2
      ? actualDate.setDate(actualDate.getDate() - 6)
      : frequency === 3
      ? actualDate.setMonth(actualDate.getMonth() - 1)
      : actualDate.setMonth(actualDate.getMonth() - 3);
    return actualDate.toString();
  };

  const handleAcceptDate = (query = {}) => {
    setAppliedFilters(query);
    handleSelectNewDate(query);
  };

  if (!FeatureFlag.enabled('GRACE_PERIOD')) return null;

  if (!flexibleCheckIn || !enableSubmit) return null;
  return (
    <>
      {frequency === 1 ? (
        <CustomDateRangePicker
          flag={1}
          minDate={ !isMondayDay(date.start_date) ? formatDate(date.start_date) : formatDate(isPreviousFriday(date.start_date))}
          filter={appliedFilters}
          frequency={frequency}
          handleApply={(date) => handleAcceptDate(date)}
          defaultFilter={{
            start_date: formatDate(date.start_date),
            end_date: formatDate(date.end_date),
          }}
          disabledDates={ isMondayDay(date.start_date) ? [(isPreviousSunday(date.start_date)), isPreviousSaturday(date.start_date)] : []}
        />
      ) : (
        <CustomDateRangePicker
          minDate={formatDate(subtractTime(date.start_date))}
          filter={appliedFilters}
          frequency={frequency}
          handleApply={(date) => handleAcceptDate(date)}
          defaultFilter={{
            start_date: formatDate(date.start_date),
            end_date: formatDate(date.end_date),
          }}
        />
      )}
    </>
  );
};

export default FlexiblePeriod;

import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import {
  InputText,
  CustomButton,
  InputSelect,
  TextArea,
  CustomLink,
} from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { toastFlashMessage } from '../../../utils';
import { createAssesmentApi, editAssesmentApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { updateLocalSubscription } from 'features/payment/Payment.utils';
import { Subscription } from 'controllers/_exports';
import { useAssessment } from '../assessment';
import DottedBtn from 'shared_ui_components/DottedBtn/DottedBtn';
import CriteriaHeader from '../assessment/admin/CriteriaHeader';
import OptionNumber from '../assessment/admin/OptionNumber';

const newRatingCreteria = {
  name: '',
  error: {
    name: '',
  },
};

const newQuestion = {
  name: '',
  answer_type: '',
  description: '',
  feedback_questions: [{ ...newRatingCreteria }],
  error: '',
};

const errorCode = {
  name: {
    0: '',
    1: 'Please enter your question',
  },
  nameObj: {
    requiredFlag: true,
  },
  answer_type: {
    0: '',
    1: 'Please select the upload type',
  },
  answer_typeObj: {
    requiredFlag: true,
  },
  description: {
    0: '',
    1: 'Please enter your description',
  },
  descriptionObj: {
    requiredFlag: true,
  },
  feedback_questions: {
    name: {
      0: '',
      1: 'Please enter your rating criteria',
    },
    name_choice: {
      0: '',
      1: 'Please enter your option',
    },
    nameObj: {
      requiredFlag: true,
    },
  },
  feedback_questionsObj: {
    requiredFlag: false,
  },
};

export default function CreateAssessmentDialog(props) {
  const { type, open, activeAsssessment } = props;
  const [formData, setFormData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [removedFeedbackQuestions, setRemovedFeedbackQuestions] = useState([]);

  const {
    feedbackContentTypes,
    needs_description,
    criteria_placeholder,
    max_criteria_label,
    max_rating_criteria,
    can_delete_criteria,
    add_criteria_label,
    isMultipleChoice,
    repeated_error,
  } = useAssessment();

  useEffect(() => {
    if (type === 'edit' && open) {
      setFormData([
        {
          ...activeAsssessment,
          answer_type: feedbackContentTypes.find(
            (contentType) => contentType.value == activeAsssessment.answer_type
          ),
          error: {},
        },
      ]);
    } else if (open) {
      setFormData([{ ...newQuestion }]);
    }
    setRemovedFeedbackQuestions([]);
    setLoader(false);
  }, [open, activeAsssessment, type, feedbackContentTypes]);

  const handleChange = ({ key, val, index }) => {
    const prev = formData[index];
    if (!prev) return;

    // Multiple choice handlers
    const nowIsMultiple = key === 'answer_type' && prev != 9 && val?.value == 9;

    // Trim or adjust questions when changing answer type
    const questions = [...prev?.feedback_questions];
    // Add 1 question if now is multiple choice and had only 1
    if (nowIsMultiple && questions.length < 2) questions.push({});
    // Trim if it was a multiple choice with more than 3 criteria
    if (questions.length > 3) questions.length = 3;

    // Clean errors
    const feedback_questions = questions.map((q) => ({ ...q, error: {} }));

    const updatedQuestion = {
      ...prev,
      correct_choice: 0,
      feedback_questions,
      [key]: val,
      error: {
        ...prev['error'],
        [key]: '',
      },
    };

    setFormData(formData.map((item, i) => (i === index ? updatedQuestion : item)));
  };

  const handleChangeRatingCriteria = (key, value, questionIndex, ratingIndex) => {
    const question = formData[questionIndex];
    let updatedFeedbackQn = question.feedback_questions[ratingIndex];
    updatedFeedbackQn = {
      ...updatedFeedbackQn,
      [key]: value,
      error: {
        ...updatedFeedbackQn['error'],
        [key]: '',
      },
    };
    question.feedback_questions = question.feedback_questions.map((q, index) =>
      index === ratingIndex ? updatedFeedbackQn : q
    );
    setFormData(formData.map((q, index) => (index === questionIndex ? question : q)));
  };
  const handleAddNewRatingCriteria = (questionIndex) => {
    const question = Object.assign({}, formData[questionIndex]);
    question['feedback_questions'] = [...question['feedback_questions'], { ...newRatingCreteria }];
    setFormData(formData.map((q, index) => (index === questionIndex ? question : q)));
  };
  const handleDeleteRatingCriteria = (questionIndex, ratingIndex) => {
    const question = Object.assign({}, formData[questionIndex]);
    if (type === 'edit' && question?.feedback_questions[ratingIndex]?.id) {
      setRemovedFeedbackQuestions((prevData) => [
        ...prevData,
        question.feedback_questions[ratingIndex]?.id,
      ]);
    }

    // Adjust correct choice if it was greater than the new length
    const len = question?.feedback_questions?.length - 1;
    if (len <= question?.correct_choice) question.correct_choice = len - 1;

    setFormData((prevData) =>
      prevData.map((q, index) =>
        index === questionIndex
          ? {
              ...question,
              feedback_questions: question.feedback_questions.filter(
                (item, index) => index !== ratingIndex
              ),
            }
          : q
      )
    );
  };

  const handleAddNewQuestion = () => {
    if (
      !Subscription.allow({
        key: Subscription.keys.assessment,
        data: { extra: formData?.length },
      })
    )
      return;

    setFormData([
      ...formData,
      {
        ...newQuestion,
        feedback_questions: [
          {
            ...newQuestion.feedback_questions,
            error: {
              name: '',
            },
          },
        ],
      },
    ]);
  };

  const handleDeleteQuestion = (index) => {
    if (formData[index]) {
      setFormData(formData.filter((item, i) => i !== index));
    }
  };

  const handleSetCorrectChoice = (questionIndex, ratingIndex) => {
    const originalQuestions = formData[questionIndex]?.feedback_questions;
    if (originalQuestions?.some((q) => q?.is_correct)) return; // Is editing and cant change correct options

    const question = Object.assign({}, formData[questionIndex]);
    question['correct_choice'] = ratingIndex;
    setFormData(formData.map((q, index) => (index === questionIndex ? question : q)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoader(true);
    let questionsList = [...formData];
    questionsList.forEach((question, index) => {
      let validateNewInput = {
        name: errorCode['name'][
          fieldValidation({
            ...errorCode['nameObj'],
            fieldval: question.name,
          })
        ],
        answer_type:
          errorCode['answer_type'][
            fieldValidation({
              ...errorCode['answer_typeObj'],
              fieldval: question.answer_type,
            })
          ],
      };

      // Remove description validation for multiple choice
      if (!isMultipleChoice({ item: question }))
        validateNewInput.description =
          errorCode['description'][
            fieldValidation({
              ...errorCode['descriptionObj'],
              fieldval: question.description,
            })
          ];

      question.error = validateNewInput;
      question.feedback_questions.forEach((item) => {
        let validateNewInput = {
          name: errorCode['feedback_questions'][
            isMultipleChoice({ item: question }) ? 'name_choice' : 'name'
          ][
            fieldValidation({
              ...errorCode['feedback_questions']['nameObj'],
              fieldval: item.name,
            })
          ],
        };
        item.error = validateNewInput;
      });

      // Do not repeat criteria
      const criteria = question.feedback_questions
        .filter((v) => v?.name)
        .map(({ name = '' }) => name?.trim()?.toLocaleLowerCase());

      const repeated = [];
      for (let index = 0; index < criteria.length; index++) {
        const value = criteria[index];
        const compare = criteria.slice();
        compare.splice(index, 1);

        if (compare.includes(value)) {
          if (repeated.includes(value))
            question.feedback_questions[index].error = { name: repeated_error({ item: question }) };
          else repeated.push(value);
        }
      }
    });
    setFormData(questionsList);
    const isValid = !questionsList.some((question) => {
      if (Object.keys(question.error).some((key) => question.error[key] !== '')) {
        return true;
      } else {
        return question.feedback_questions.some((item) =>
          Object.keys(item.error).some((key) => item.error[key] !== '')
        );
      }
    });
    if (isValid) {
      const updatedFormData = questionsList.map((q) => {
        const question = {
          ...q,
          answer_type: q?.answer_type?.value,
        };
        delete question['error'];
        question.feedback_questions.forEach((item, i) => {
          delete item['error'];
          item.is_correct = 'is_correct' in item ? item?.is_correct : q?.correct_choice === i;
        });

        if (isMultipleChoice({ item: q })) question.description = '';
        delete question['correct_choice'];
        return question;
      });
      if (type === 'edit') {
        editAssesmentApi(activeAsssessment?.id, {
          ...updatedFormData[0],
          removed_feedback_questions: removedFeedbackQuestions,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              setLoader(false);
              toastFlashMessage(response.message, 'success');
              props.onSuccessHandler(response.data);
            } else {
              setLoader(false);
              toastFlashMessage(response.message, 'error');
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        createAssesmentApi({ questions: updatedFormData })
          .then((response) => {
            setLoader(false);
            if (response.statusCode === 200) {
              toastFlashMessage(response.message, 'success');

              const total_assessments = response?.data?.length;
              updateLocalSubscription({ total_assessments });

              props.onSuccessHandler(response.data);
            } else {
              toastFlashMessage(response.message, 'error');
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      setLoader(false);
    }
  };

  const handleClose = () => {
    props.handleClose();
    clearErros();
  };

  const clearErros = () => {
    let questionsList = [...formData];
    questionsList.forEach((question, index) => {
      delete question.error;
      question.feedback_questions.forEach((item) => {
        delete item.error;
      });
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.assesmentcreateDialog}`}
      >
        <DialogTitle id="add-video-title" className={styles.dialogTitle}>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={() => handleClose()}
          />
          <span className={styles.title + ' heading4'}>
            <img src={imageBasePath + 'setup_training.svg'} alt="image" />
            <span>
              {type == 'edit' ? 'Edit Training Assessment' : 'Training Assessment'}
              <br />
              <span className="paragraph">
                You can provide feedback to your team based on the ratings criteria that you add
                here. You can insert questions,
                <br /> descriptions and file types. A ratings criteria will be added to each
                question and allows you to provide feedback <br /> to your team.
              </span>
            </span>
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogBody}>
            {formData.map((item, index) => (
              <div className={styles.formWrapper} key={index}>
                <Grid container direction="row" spacing={2} className={styles.titleRow}>
                  <Grid item lg={8} md={8} sm={12} xs={12} style={{ paddingBottom: 0 }}>
                    <InputText
                      id="name"
                      placeholder="What is your question?"
                      onChange={(e) => handleChange({ key: 'name', val: e.target.value, index })}
                      value={item?.name}
                      error={item?.error?.name}
                      label="Question"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12} style={{ paddingBottom: 0 }}>
                    <div className={styles.selectType}>
                      <InputSelect
                        label="Response type"
                        closeMenuOnSelect={true}
                        options={feedbackContentTypes}
                        isSearchable={true}
                        placeholder={'Response Type'}
                        noOptionsMessage={() => 'No matches found'}
                        onChange={(selectedOption) =>
                          handleChange({
                            key: 'answer_type',
                            val: selectedOption ? selectedOption : [],
                            index,
                          })
                        }
                        value={item?.answer_type}
                        error={item?.error?.answer_type}
                        // isCreatable={true}
                        // isClearable
                      />
                      {item?.error?.answer_type ? (
                        <h6 className="errorMsg">{item?.error?.answer_type}</h6>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
                {needs_description({ item }) && (
                  <Grid container direction="row" spacing={2} className={styles.titleRow}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <TextArea
                        placeholder="Description"
                        rowsMin={3}
                        rowsMax={4}
                        onChange={(e) =>
                          handleChange({ key: 'description', val: e.target.value, index })
                        }
                        value={item?.description}
                        error={item?.error?.description}
                        label="Description"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}></Grid>
                  </Grid>
                )}
                <Grid container direction="row" spacing={2} className={styles.creteriaRow}>
                  <Grid item lg={8} md={8} sm={12} xs={12} className={styles.creteriaBlk}>
                    <CriteriaHeader item={item} />
                    {item.feedback_questions.map((feedbackItem, feedbackIndex) => (
                      <div className={styles.criteriaWrapper} key={feedbackIndex}>
                        {isMultipleChoice({ item }) && <OptionNumber number={feedbackIndex + 1} />}
                        <InputText
                          id="rating"
                          placeholder={criteria_placeholder({ item, index: feedbackIndex })}
                          onChange={(e) =>
                            handleChangeRatingCriteria('name', e.target.value, index, feedbackIndex)
                          }
                          value={feedbackItem?.name}
                          error={feedbackItem?.error?.name}
                        />
                        <div
                          className={`${styles.criteriaDelete} ${
                            isMultipleChoice({ item }) ? styles.isMultiple : ''
                          }`}
                        >
                          {isMultipleChoice({ item }) && feedbackIndex === 0 && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="16" height="16" rx="3" fill="#2DC38D" />
                              <path
                                d="M4.5 8.5L7 11.5L12 4"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          )}
                          {isMultipleChoice({ item }) && feedbackIndex > 0 && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="16" height="16" rx="3" fill="#E83A3A" />
                              <path
                                d="M4.5 4.5L8.25 8M12 11.5L8.25 8M11.75 4.25L8.25 8M4.75 11.75L8.25 8"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                          {can_delete_criteria({
                            assessmentQuestion: item,
                            criteria: feedbackItem,
                            feedbackIndex,
                          }) ? (
                            <button
                              onClick={() => handleDeleteRatingCriteria(index, feedbackIndex)}
                            >
                              <img
                                src={imageBasePath + 'minus_circle.svg'}
                                alt="Remove"
                                title="Remove"
                              />
                            </button>
                          ) : (
                            <div style={{ width: '1rem' }} />
                          )}
                        </div>
                      </div>
                    ))}
                    {item.feedback_questions.length >= max_rating_criteria({ item }) ? (
                      <div className={styles.addMoreCreteria}>
                        <p className="supportText errorMsg">{max_criteria_label({ item })}</p>
                      </div>
                    ) : (
                      <div className={styles.addMoreCreteria}>
                        <CustomLink
                          className="linkBtn"
                          linktext={add_criteria_label({ item })}
                          onClick={() => handleAddNewRatingCriteria(index)}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
                {formData.length > 1 && (
                  <a className={styles.questionDelete} onClick={() => handleDeleteQuestion(index)}>
                    <img src={imageBasePath + 'delete_red.svg'} alt="Delete Question" />
                  </a>
                )}
              </div>
            ))}
            {type === 'create' && (
              <DottedBtn
                smallHeight
                showPlusSign
                label={'Add Question'}
                onClick={handleAddNewQuestion}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${loader ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setLoader(true);
              handleSubmit(e);
            }}
          >
            {loader ? 'Saving...' : 'Save'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

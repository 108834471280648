import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { FeatureSlider } from '../components';
import { CustomLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { resendEmailForVerificationApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

export default class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...props.formData,
      },
      loader: true,
      submitLoader: false,
    };
  }

  componentWillMount() {
    this.setState({
      formData:
        this.props.history && this.props.history.location && this.props.history.location.state
          ? this.props.history.location.state.formData
          : this.state.formData,
    });
  }

  handleResendEmailForVerification = () => {
    const { formData } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      submitLoader: true,
    }));
    resendEmailForVerificationApi({ formData: formData }).then((response) => {
      if (response.statusCode === 200) {
        toastFlashMessage(response.message, 'success');
        this.setState((prevState) => ({
          ...prevState,
          submitLoader: false,
        }));
      } else {
        toastFlashMessage(response.message, 'error');
        this.setState((prevState) => ({
          ...prevState,
          submitLoader: false,
        }));
      }
    });
  };

  componentDidMount() {
    if (!this.props?.history?.location?.state?.formData) {
      this.props.history.push('/signup');
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo?.user?.email_verified) {
      this.props.history.push('/');
    }
  }

  render() {
    const { formData, loader, submitLoader } = this.state;
    return (
      <div className={styles.signUpPage}>
        <section className={styles.signUpSection}>
          <div className={styles.signUpWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signUpRow}>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <FeatureSlider />
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className={styles.authWrapper + ' flexCentered'}>
                  <p className={styles.signInLink + ' paragraph'}>
                    Already have an account ?
                    <CustomLink className="linkBtn" linktext={`Sign in`} to="/signin" />
                  </p>
                  <div className={styles.verifyContentWrapper}>
                    <img src={imageBasePath + 'verify_email.svg'} alt="Verify your Email" />
                    <h1 className="heading3">Verify your Email</h1>
                    <h3 className="paragraph">
                      An email verification link has been sent to you <br /> at:{' '}
                      <span>{formData.email}</span>
                    </h3>
                    <p className={styles.sendAgain + ' paragraph'}>
                      Didn’t receive the email?
                      <CustomLink
                        className={`linkBtn ${submitLoader ? ' disabledBtn' : ''}`}
                        linktext={submitLoader ? 'Sending' : 'Please send it again'}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.handleResendEmailForVerification();
                        }}
                      />
                    </p>
                    <h3 className="supportText italic">
                      Note: If you can't find it in your inbox, Please check your SPAM or junk mail
                      folder
                    </h3>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addPaymentMethodService,
  createNewSubscriptionService,
  createOldSubscriptionService,
  getPaymentPlansService,
  getSubscriptionDetailsService,
  upgradeSubscriptionService,
  cancelSubscriptionService,
  editPaymentMethodService,
  changeDefaultPaymentMethodService,
  deletePaymentMethodService,
  getSubscriptionDataService,
} from './Payment.services';
import { toastFlashMessage } from '../../utils';

const paymentCacheKeys = {
  plans: 'payment_plans',
  subscription: 'subscription_details',
  subscription_data: 'subscription_data',
};

export const useGetPaymentPlansQuery = ({ coupon = '', price = '' }) => {
  const queryClient = useQueryClient();
  return useQuery([paymentCacheKeys.plans, coupon], () => getPaymentPlansService({ coupon }), {
    onSuccess: (response) => {
      queryClient.setQueryData([paymentCacheKeys.plans, coupon], (prev) => {
        if (response?.coupon?.statusCode === 200) return prev;
        const newProducts = (prev?.products || [])?.filter(
          (prod) => !!(prod?.prices || [])?.find((p) => p?.stripe_id === price)
        );
        return newProducts?.length > 0 ? { ...prev, products: newProducts } : prev;
      });
    },
  });
};

export const useCreateNewSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewSubscriptionService, {
    onSuccess: () => {
      toastFlashMessage('Subscription created', 'success');
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
    },
  });
};

export const useGetSubscriptionDetailsQuery = () =>
  useQuery([paymentCacheKeys.subscription], () => getSubscriptionDetailsService());

export const useCreateOldSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((body) => createOldSubscriptionService(body), {
    onSuccess: () => {
      toastFlashMessage('Subscription created', 'success');
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
    },
  });
};

export const useAddPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(addPaymentMethodService, {
    onSuccess: ({ message }) => {
      toastFlashMessage(message, 'success');
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
    },
  });
};

export const useUpgradeSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(upgradeSubscriptionService, {
    onSuccess: ({ message }) => {
      toastFlashMessage(message, 'success');
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
      queryClient.invalidateQueries([paymentCacheKeys.subscription_data]);
    },
  });
};

export const useCancelSubscriptionMutation = () => {
  return useMutation((body) => cancelSubscriptionService(body), {
    onSuccess: ({ message }) => {
      toastFlashMessage(message, 'success');
    },
  });
};

export const useEditPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body, payment_method_id) => editPaymentMethodService(body, payment_method_id),
    {
      onSuccess: ({ message }) => {
        queryClient.invalidateQueries([paymentCacheKeys.subscription]);
        // toastFlashMessage(message, 'success'); // TODO remove when text has been corrected in backend
        toastFlashMessage('You have successfully updated your payment method!', 'success');
      },
    }
  );
};

export const useChangeDefaultPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payment_method_id) => changeDefaultPaymentMethodService(payment_method_id), {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
      toastFlashMessage(message, 'success');
    },
  });
};

export const useDeletePaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((payment_method_id) => deletePaymentMethodService(payment_method_id), {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([paymentCacheKeys.subscription]);
      toastFlashMessage(message, 'success');
    },
  });
};

export const useGetSubscriptionDataQuery = () =>
  useQuery([paymentCacheKeys.subscription_data], getSubscriptionDataService);

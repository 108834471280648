import React from 'react';
import styles from './ReviewQualifiedRoleplay.module.css';
import RatingCriteriaTooltip from '../Common/RatingCriteriaTooltip';
import RoleplayRating from '../Common/RoleplayRating/RoleplayRating';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';

const ShowRatingCriteria = ({ selectedScenario }) => {
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);
  return (
    <div>
      <RatingCriteriaTooltip />
      {!secondAttempt &&
        selectedScenario?.response?.rating_criterials?.map(({ rating_criteria, stars }, index) => (
          <div key={index} className={styles.rating}>
            <span>{rating_criteria?.name}</span>
            <RoleplayRating withTooltip stars={stars} />
          </div>
        ))}
      {secondAttempt &&
        selectedScenario.response_retake !== null &&
        selectedScenario?.response_retake?.rating_criterials?.map(
          ({ rating_criteria, stars }, index) => (
            <div key={index} className={styles.rating}>
              <span>{rating_criteria?.name}</span>
              <RoleplayRating withTooltip stars={stars} />
            </div>
          )
        )}
    </div>
  );
};

export default ShowRatingCriteria;

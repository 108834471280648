import React from 'react';
import { Card, Grid } from '@material-ui/core';
import styles from './PaymentCard.module.scss';
import Card_ from 'assets/Card.svg';
import ListPaymentMethods from 'features/payment/components/BillingPage/ListPaymentMethods/ListPaymentMethods';
const PaymentCard = ({ cardInfo = {}, index, payments = {} }) => {
  return (
    <Grid key={index} item xs={12} md={6}>
      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          <div className={styles.headerText}>
            <h1 className={styles.first}>Payment method</h1>
            <span className={styles.second}>Change how you pay for your plan.</span>
          </div>
          <div className={styles.headerInfo}>
            <div>
              <img
                className={styles.icon}
                onClick={null}
                src={Card_}
                alt="Card icon"
                style={{ marginRight: '0.5rem' }}
              />
              <span>Visa ending in {cardInfo?.card?.last4} </span>
            </div>
            <span>
              {' '}
              Expiry {cardInfo?.card?.exp_month}/{cardInfo?.card?.exp_year}
            </span>
          </div>
        </div>
        <div className={styles.cardActionButtons}>
          <ListPaymentMethods cardInfo={cardInfo} payments={payments} />
        </div>
      </Card>
    </Grid>
  );
};

export default PaymentCard;

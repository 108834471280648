import React from 'react';
import styles from './AssignRoleplay.module.scss';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomLink, InputText } from 'shared_ui_components';
import { imageBasePath } from 'constants';
const CreateScenarios = () => {
  const formDataCreateRoleplay = useRoleplayStore((state) => state?.formDataCreateRoleplay);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);
  const handleChange = (id, value) => {
    let criterial = formDataCreateRoleplay?.scenarios.find((c) => c.id === id);
    if (criterial) {
      criterial.name = value;
    }
    setFormDataCreateRoleplay({ ...formDataCreateRoleplay });
  };
  const handleAddNewScenario = () => {
    const id = formDataCreateRoleplay?.scenarios[formDataCreateRoleplay?.scenarios.length - 1].id;
    let aux = {
      name: '',
      id: id + 1,
    };
    setFormDataCreateRoleplay({
      ...formDataCreateRoleplay,
      scenarios: [...formDataCreateRoleplay?.scenarios, aux],
    });
  };

  const handleRemoveScenario = (id) => {
    const aux = formDataCreateRoleplay?.scenarios.filter((c) => c.id !== id);
    setFormDataCreateRoleplay({
      ...formDataCreateRoleplay,
      scenarios: aux,
    });
  };
  return (
    <div style={{ marginTop: '2rem' }}>
      <p className={styles.deadlineTitle}>Scenarios (Optional)</p>
      {formDataCreateRoleplay?.scenarios?.map(({ name, id }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <div style={{ width: '100%', height: '3rem' }}>
              <InputText
                id={id}
                placeholder="Add new scenario"
                value={name}
                onChange={(e) => handleChange(id, e.target.value)}
              />
            </div>
            {formDataCreateRoleplay?.scenarios.length > 1 && (
              <div className={styles.closeImage} onClick={() => handleRemoveScenario(id)}>
                <img src={imageBasePath + 'close.svg'} alt="Delete Question" />
              </div>
            )}
          </div>
        );
      })}
      <CustomLink
        className="customLinkPulse"
        linktext={'+ Add Scenario'}
        onClick={() => handleAddNewScenario()}
      />
    </div>
  );
};

export default CreateScenarios;

import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid } from '@material-ui/core';
import { CustomArcProgress } from '../../../shared_elements';

export default function RecruitOverview(props) {
  const { recruitment = {} } = props;
  return (
    <div className={styles.moduleOverview}>
      <OverviewTitle
        icon={`${imageBasePath}recruit_default.svg`}
        title={`Recruiting`}
        moduleLink={'/recruitment'}
      />
      <Grid
        container
        className={`${styles.recruitOverviewWrapper} ${styles.defaultOverviewWrapper}`}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <div className={styles.infoWrapper}>
            <h3 className="heading2">{recruitment.no_of_jobs}</h3>
            <p className="paragraph">No. of jobs</p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.infoWrapper}>
            <h3 className="heading2">
              {recruitment?.accepted_count ? recruitment?.accepted_count : 0}
            </h3>
            <p className="paragraph">Candidates accepted</p>
          </div>
        </Grid>
        {/* <Grid item xs={12} md={4}>
                    <div className={styles.progressGraph}>
                        <CustomArcProgress progress={.75} text="75%"/>
                    </div>
                </Grid> */}
      </Grid>
    </div>
  );
}

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './CloseBannerButton.module.css';
import { useCloseBannerMutation } from 'shared_ui_components/CloseBannerButton/useCloseBannerMutation';
import Organization from 'infrastructure/Organization';

const CloseBannerButton = ({ isHome = false, isPulse = false, onClose = () => null }) => {
  const { mutate: closeBanner } = useCloseBannerMutation();

  const handleClose = () => {
    onClose();
    if (isHome) {
      Organization.updateOrgStorage({ key: 'welcome_banner', value: false });
      closeBanner({ isHome: true });
    }
    if (isPulse) {
      Organization.updateOrgStorage({ key: 'pulse_banner', value: false });
      closeBanner({ isPulse: true });
    }
  };

  return <CloseIcon className={styles.closeIcon} onClick={handleClose} />;
};

export default CloseBannerButton;

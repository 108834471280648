import { Alert } from '@material-ui/lab';
import React from 'react';
import { getTrialDaysText, showTrialDaysAlert } from '../../Payment.utils';

const TrialPeriodAlert = () => {
  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const days = selectedOrg?.remaining_days ?? 14;

  return showTrialDaysAlert() ? (
    <Alert
      style={{
        position: 'sticky',
        top: '64px',
        zIndex: '201',
      }}
      severity="info"
    >
      {getTrialDaysText({ days, variant: 1 })}
    </Alert>
  ) : null;
};

export default TrialPeriodAlert;

import React from 'react';
import styles from './SubmitRoleplay.module.scss';
import SubmitRoleplayHeader from './SubmitRoleplayHeader';
import SubmitRoleplayContent from './SubmitRoleplayContent';

const SubmitRoleplayResume = () => {
  return (
    <div className={styles.background}>
      <SubmitRoleplayHeader />
      <SubmitRoleplayContent />
    </div>
  );
};

export default SubmitRoleplayResume;

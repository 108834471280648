import axios from 'axios';
import { toastFlashMessage } from '../utils';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';

var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosInstance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem('userInfo')) {
      config['headers']['Authorization'] = JSON.parse(localStorage.getItem('userInfo'))['access'];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
      return response.data;
    } else if (response.data.statusCode === 1001) {
      //token expired
      return new Promise((resolve, reject) => {
        const originalReq = response.config;
        originalReq._retry = true;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let res = fetch(`${process.env.REACT_APP_BASE_URL}/auth/refresh/`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          referrer: 'no-referrer',
          body: JSON.stringify({
            refresh: userInfo.refresh,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res?.data?.access) {
              userInfo = {
                ...userInfo,
                access: res.data.access,
              };
              localStorage.setItem('userInfo', JSON.stringify(userInfo));
              originalReq.headers['Authorization'] = res.data.access;
              return axios(originalReq);
            } else {
              toastFlashMessage(response.data.message, 'error');
              setTimeout(() => {
                localStorage.clear();
                window.location.href = '/';
              }, 5000);
              return Promise.reject(response);
            }
          });
        resolve(res);
      });
    } else if (response.data.statusCode >= 1002 && response.data.statusCode <= 1004) {
      toastFlashMessage(response.data.message, 'error');
      setTimeout(() => {
        localStorage.clear();
        window.history.pushState({}, '', '/');
      }, 5000);
      return response.data;
    } else if ([3001, 5001, 2002, 1005].includes(response.data.statusCode)) {
      setTimeout(() => {
        localStorage.clear();
        window.history.pushState({}, '', '/');
      }, 5000);
      return response.data;
    } else if ([6001].includes(response.data.statusCode)) {
      setTimeout(() => {
        // localStorage.clear();
        window.history.pushState({}, '', '/verifydomain');
      }, 5000);
      return response.data;
    } else if ([6002].includes(response.data.statusCode)) {
      setTimeout(() => {
        // localStorage.clear();
        window.history.pushState({}, '', '/verificationrejected');
      }, 5000);
      return response.data;
    } else if ([4001].includes(response.data.statusCode)) {
      return Promise.reject(response?.data);
    } else {
      return response.data;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;

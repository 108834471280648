import React from 'react';
import ChatAssistView from '../components/ChatAssistView';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';
import { Redirect } from 'react-router-dom';
const ChatAssist = () => {
  const showChatAIPage = () => {
    return FeatureFlag.enabled('CHAT_ASSISTANT') && Organization.getSelectedOrg()?.ai_integration;
  };

  return showChatAIPage() ? <ChatAssistView /> : <Redirect to="/" />;
};

export default ChatAssist;

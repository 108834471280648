import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import AutoLinks from 'quill-auto-links';

import styles from './PostTextArea.module.scss';
import thumbnailStyles from './Thumbnails.module.scss';
import 'react-quill/dist/quill.snow.css';

import UserTagsList from './UserTagList';
import { useGetURLMetadataServiceMutation, useGetUsersQuery } from 'features/stream/Stream.queries';
import { ReactHtmlParser } from 'shared_ui_components';
import { useStreamStore } from 'features/stream/Stream.store';

import { FeatureFlag } from 'controllers/_exports';
import { imageBasePath } from 'constants';

Quill.register('modules/autoLinks', AutoLinks);

// TODO remove image tags without a tag

const PostTextArea = ({ initialValue = '', isNewPost = false, isComment = false, postID = '' }) => {
  const [value, setValue] = useState('');
  const onChange = (newValue) => setValue(newValue);

  const setPostValue = useStreamStore((state) => state?.setPostValue);
  const setCommentValue = useStreamStore((state) => state?.setCommentValue);

  useEffect(() => {
    if (!value) return;
    // Saves value in store
    isComment ? setCommentValue(postID, value) : setPostValue(value);
  }, [value, setPostValue, postID, isComment, setCommentValue]);

  // Reset values if value in store has been reset (by onSuccess)
  const postValue = useStreamStore((state) => state.postValue);
  const commentInStore = useStreamStore((state) => state[`comment-${postID}`]);
  useEffect(() => {
    if (isComment && isNewPost && commentInStore === '' && value !== '') setValue('');
    else if (isNewPost && postValue === '' && value !== '') {
      setValue('');
      resetThumbnailsSection();
    }
  }, [commentInStore, isComment, isNewPost, postValue, value]);

  // Users that can be tagged
  const { data: users = [], isLoading } = useGetUsersQuery({ enabled: isNewPost });
  const formattedUsers = useMemo(() => formatUsers(users?.map(({ user }) => user)), [users]);

  // React quill configuration
  const reactQuillRef = useRef();
  const editor = useRef(null);

  useEffect(() => {
    try {
      // Ref initialization
      editor.current = reactQuillRef.current?.getEditor();
    } catch (error) {}
  }, [reactQuillRef]);

  const handleQuillChange = (content, delta, source) => {
    tagFiltering(delta);
    handleThumbnail({ delta });
    onChange(content);
    if (source === 'user' && delta?.ops?.at(-1)?.delete > 0) handleDelete();
  };

  // TAG SYSTEM
  const [showUsersList, setShowUsersList] = useState(false);

  // Tag filtering by user
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // Resets search value each time list opens/closes
    setSearchValue('');
  }, [showUsersList]);

  useEffect(() => {
    // Close list if @ not found
    setShowUsersList(searchValue?.includes('@'));
  }, [searchValue]);

  const tagFiltering = (delta) => {
    if (!showUsersList) return;

    if ('delete' in delta?.ops?.at(-1)) return setSearchValue((pv) => pv.slice(0, -1));
    if ('insert' in delta?.ops?.at(-1))
      return setSearchValue((pv) => pv + delta?.ops?.at(-1)?.insert);
  };

  // Insert tag
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const [cursorLastIndex, setCursorLastIndex] = useState(null);

  useEffect(() => {
    // Closes list of users on document click
    const detectClick = () => setShowUsersList(false);
    document.addEventListener('click', detectClick);
    return () => {
      document.removeEventListener('click', detectClick);
    };
  }, []);

  const setTag = (userData) => {
    try {
      const { current } = editor;

      // Replace @ with the user photo and name
      current.insertText(cursorLastIndex + 1, ` ${userData?.email} `);
      current.insertEmbed(cursorLastIndex, 'image', userData?.profile_pic);

      const contents = current.getContents();
      const newContent = contents.map((c, index) => {
        if (
          'insert' in c &&
          typeof c?.insert === 'string' &&
          c?.insert.replace(/[^@]/g, '').length >= 2
        ) {
          // Remove double spaces and break lines
          let insert = c?.insert;
          if (insert?.includes('\n\n')) insert = c.insert?.replace('\n\n', '\n');

          // Remove searching string
          const charsToRemove = searchValue?.length;
          // TODO fix tag in the middle
          if (charsToRemove > 0 && index >= contents?.ops?.length - 1) {
            let res = '';
            res += insert?.slice(0, userData?.email?.length + 3);
            res += insert?.slice(userData?.email?.length + 2 + charsToRemove + 1);
            insert = res;
          }
          return { ...c, insert };
        }
        return c;
      });

      current.setContents(newContent);
      current.setSelection(cursorLastIndex + userData?.email?.length + 4, 0);
      setShowUsersList(false);
    } catch (error) {}
  };

  // Keyboard handlers
  const handleAtKey = (key) => {
    if (key !== '@') return;
    try {
      //  TODO Check if is a space before, otherwise may be an email
      const { current } = editor;
      const index = current?.getSelection()?.index;

      // Show user list, save index of @
      setShowUsersList(true);
      setCursorLastIndex(index);
      setCursorPosition(current?.getBounds(index));
    } catch (error) {}
  };

  const handleKeyPress = (e) => {
    const { key } = e;
    handleAtKey(key);
  };

  // Remove tag handler
  const [prevContents, setPrevContents] = useState();
  useEffect(() => {
    // Saves a copy of previous contents
    setPrevContents(editor?.current?.getContents());
  }, [value]);

  const emails = useMemo(() => formattedUsers?.map((u) => u?.email), [formattedUsers]);

  const setUserEmails = useStreamStore((state) => state?.setUserEmails);
  useEffect(() => {
    setUserEmails(emails);
  }, [emails, setUserEmails]);

  const handleDelete = () => {
    // Remove incomplete tags
    if (showUsersList) return;
    try {
      const { current } = editor;

      let deleted = false;
      let cursorIndex = 0;
      let newContent = [];
      const contents = current.getContents()?.ops;

      prevContents.forEach((content, index) => {
        if (
          !content?.insert ||
          typeof content?.insert !== 'string' ||
          !content?.insert?.includes('@') ||
          contents[index]?.insert === content?.insert // contents are the same
        )
          return newContent.push(content);

        emails?.forEach((e) => {
          try {
            if (
              // This email is not in the previous content tag
              content?.insert?.slice(2, e?.length + 2) !== e ||
              // Tag / Name is still in current content
              contents[index]?.insert?.includes(`@ ${e}`)
            )
              return;

            newContent.pop(); // Remove last content, user image
            const insert = content?.insert?.slice(e.length + 2)?.trim(); // Remove tag

            deleted = true;
            newContent.push({ insert });
            cursorIndex = current?.getSelection()?.index - (e?.length + 2);
          } catch (error) {}
        });
      });

      if (!deleted) return; // No tag removed

      current.setContents(newContent);
      current.setSelection(cursorIndex >= 0 ? cursorIndex : current?.getLength(), 0);
    } catch (error) {}
  };

  // Quill keyboard bindings config
  const bindings = useMemo(
    () => ({
      tab: {
        key: 9,
        handler: function () {},
      },
      up: {
        key: 38,
        handler: function () {},
      },
      down: {
        key: 40,
        handler: function () {},
      },
      // enter: {
      //   key: 13,
      //   handler: function () {},
      // },
      escape: {
        key: 27,
        handler: function () {
          setShowUsersList(false);
        },
      },
    }),
    []
  );

  // Insert link thumbnail
  const handleThumbnail = ({ delta }) => {
    if (!FeatureFlag.enabled('THUMBNAIL_LINKS')) return;
    if (!editor.current || !isNewPost || isComment) return;
    let link = '';
    if (delta?.ops?.length === 1) link = delta?.ops[0]?.attributes?.link;
    if (delta?.ops?.length >= 2) link = delta?.ops[1]?.attributes?.link;
    if (link && link?.match(urlRegex)) setTimeout(() => insertThumbnail({ link }), 1);
  };

  const { mutate: getURLData } = useGetURLMetadataServiceMutation();

  const insertThumbnail = ({ link }) => {
    getURLData(
      { url: link },
      {
        onSuccess: (data) => {
          if (!Object.keys(data)?.length > 0) return;

          // Create thumbnail div with ID
          const id = new Date().valueOf();

          const div = document.createElement('div');
          div.className = 'thumbnail_v1';
          div.id = id;
          div.innerHTML = `${
            data?.image ? `<img alt="" src="${data.image}"/>` : ''
          }<div><a target="_blank" href="${link}">${data.title || link}</a><p>${
            data.description || ''
          }</p><div>`;

          // Add delete button
          const img = document.createElement('img');
          img.alt = 'x';
          img.src = `${imageBasePath}delete.svg`;

          const del = document.createElement('span');
          del.className = 'delete';
          del.append(img);
          del.addEventListener('click', () => removeThumbnail(id));
          div.append(del);

          const container = document.getElementById(thumbnailsSectionID);
          container.append(div);
        },
      }
    );
  };

  const removeThumbnail = (id) => {
    const div = document.getElementById(id);
    div.remove();
  };

  const resetThumbnailsSection = () =>
    (document.getElementById(thumbnailsSectionID).innerHTML = '');

  const content = useMemo(() => {
    return replaceEmailsWithTags(initialValue, formattedUsers);
  }, [initialValue, formattedUsers]);

  const postedContent = useRef();
  useEffect(() => {
    if (!postedContent.current) return;
    try {
      postedContent.current.querySelectorAll('img').forEach((img) => {
        img.onerror = function () {
          this.style.display = 'none';
        };
      });
    } catch (error) {}
  }, [content]);

  if (!isNewPost)
    return (
      <div
        className={`
          ${styles?.postTextArea} 
          ${styles.postContent} 
          ${isComment ? styles.commentArea : ''}`}
      >
        <div className="ql-container ql-snow">
          <div className={`ql-editor ${thumbnailStyles.thumbnailSection}`} ref={postedContent}>
            <ReactHtmlParser isStreamContent content={content} />
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={`${styles.postTextAreaContainer} ${!isComment && isNewPost ? styles.isPost : ''}`}
      style={{ zIndex: isComment ? (isNewPost ? 1 : 0) : isNewPost ? 2 : 0 }}
    >
      <ReactQuill
        readOnly={!isNewPost}
        ref={reactQuillRef}
        theme="snow"
        value={value}
        onKeyPress={handleKeyPress}
        onChange={handleQuillChange}
        className={`
          ${styles?.postTextArea} 
          ${isComment ? styles.commentInput : ''}`}
        placeholder={
          isComment
            ? 'Enter reply'
            : 'Empower Our Sales Community: Share Your Insights, Tips, and Success Stories Here! Tag Someone by typing @'
        }
        modules={{
          toolbar: isNewPost ? [] : false,
          autoLinks: true,
          keyboard: {
            bindings,
          },
        }}
      />
      <div id={thumbnailsSectionID} className={thumbnailStyles.thumbnailSection}></div>
      {isNewPost && (
        <UserTagsList
          cursorPosition={cursorPosition}
          handleSelect={(user) => !!user && setTag(user)}
          showUsersList={showUsersList}
          allUsers={formattedUsers}
          searchValue={searchValue}
          loading={isLoading}
          isComment={isComment}
        />
      )}
    </div>
  );
};

export default PostTextArea;

// User functions
const formatUsers = (users = []) => {
  if (!users?.length > 0) return [];
  return users?.map((u) => {
    const name = getUserName(u);
    const label = (u?.first_name || u?.last_name || u?.email)?.replace(' ', '');
    return {
      ...u,
      name,
      profile_pic:
        u?.profile_pic ||
        `https://ui-avatars.com/api/?rounded=true&background=87a0b4&length=1&color=ffffff&bold=true&font-size=0.6&name=${label}`,
    };
  });
};

const getUserName = (user) => {
  if (!user) return;
  const { first_name, last_name, email } = user;
  let name = '';
  if (first_name || last_name) {
    if (first_name) name += `${first_name}`;
    if (last_name) name += first_name ? ` ${last_name}` : last_name;
  } else name = email.substring(0, email.lastIndexOf('@'));
  return name;
};

// Initial value formatters - For previous posts and comments
const replaceEmailsWithTags = (initialValue, users) => {
  if (!initialValue) return '';
  let finalValue = initialValue;
  const _users = formatUsers(users);
  _users.forEach(({ name, email, profile_pic }) => {
    finalValue = finalValue.replaceAll(
      `@${email}`,
      `<img title=${email} src=${profile_pic}>&nbsp;&nbsp;@${name}`
    );
  });
  return finalValue;
};

const urlRegex = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
);

const thumbnailsSectionID = 'postThumbnailsSection';

import React from 'react';
import { useState } from 'react';
import image from '../assets/maintenance.svg';
import logo from '../assets/logo.svg';
import { imageBasePath } from '../constants';
import { FeatureFlag } from 'controllers/_exports';

const Maintenance = ({ children }) => {
  const [counter, setCounter] = useState(0);

  if (!FeatureFlag.enabled('MAINTENANCE_SCREEN')) return children;

  return counter < 20 ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        backgroundImage: `url(${imageBasePath}singnin_background.png)`,
      }}
    >
      <img style={{ maxHeight: '20vh' }} alt="" src={logo} />
      <img style={{ maxHeight: '30vh' }} alt="" src={image} />
      <span
        style={{
          textAlign: 'center',
          marginTop: '2rem',
          fontSize: '1.2rem',
        }}
      >
        We are working on something new...
      </span>
      <span
        style={{
          textAlign: 'center',
          marginTop: '1rem',
          fontSize: '1.5rem',
        }}
        onClick={() => setCounter((cs) => cs + 1)}
      >
        Thanks for the patience!
      </span>
    </div>
  ) : (
    children
  );
};

export default Maintenance;

import { useState, useEffect } from 'react';
import { Grid, List, ListItem, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { CustomButton } from '../../../shared_ui_components';
import { getAssesmentQuestionssApi, deleteAssessmentQuestionpApi } from '../apiServices';
import { CreateAssessmentDialog } from './';
import { toastFlashMessage } from '../../../utils';
import { ConfirmationDialog } from '../../../shared_elements';
import Shimmer from './Shimmer';
import { ReadOnlyWrapper, Subscription } from 'controllers/_exports';
import { updateSubscriptionAssessments } from 'features/payment/Payment.utils';
import { useAssessment } from '../assessment';

export default function TrainingAssessmentView(props) {
  const [loader, setLoader] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [createAsssessment, setCreateAsssessment] = useState(false);
  const [editAsssessment, setEditAsssessment] = useState(false);
  const [activeAsssessment, setActiveAsssessment] = useState({});
  const [deleteAsssessmentQuestion, setDeleteAsssessmentQuestion] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const fetchAssesmentQuestionsList = () => {
    getAssesmentQuestionssApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader(false);
        let questions = response.data;
        setQuestions(questions);
      }
    });
  };

  const handleCreateAsssessment = () => {
    if (!Subscription.allow({ key: Subscription.keys.assessment })) return;
    setCreateAsssessment(true);
  };
  const handleEditAsssessment = (activeAsssessment) => {
    setActiveAsssessment(activeAsssessment);
    setEditAsssessment(true);
  };
  const handleDeleteAssessmentClick = (activeAsssessment) => {
    setActiveAsssessment(activeAsssessment);
    setDeleteAsssessmentQuestion(true);
  };
  const handleDeleteQuestion = () => {
    setDeleteLoader(true);
    deleteAssessmentQuestionpApi(activeAsssessment.id).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode == 200) {
        updateSubscriptionAssessments(-1);
        setQuestions((prevState) => [
          ...prevState.filter((item) => item.id !== activeAsssessment.id),
        ]);
        toastFlashMessage(response.message, 'success');
        setDeleteAsssessmentQuestion(false);
        setActiveAsssessment({});
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  useEffect(() => {
    fetchAssesmentQuestionsList();
  }, []);

  const handleUpdateAssessmentList = (payload = {}) => {
    if (editAsssessment) {
      setQuestions((prevData) => prevData.map((item) => (item.id === payload.id ? payload : item)));
    } else {
      setQuestions(payload); // payload will be updated list of questions
    }
  };
  return (
    <>
      <div className={styles.assesmentViewWrapper}>
        <div className={styles.titleWrapper}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={styles.titleRow}
          >
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <h2 className="heading4">Training Assessment</h2>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className="textRight">
              <CustomButton
                className={`${styles.editBtn} primaryBtn smallBtn${loader ? 'disabledBtn' : ''}`}
                onClick={() => {
                  handleCreateAsssessment(true);
                }}
              >{`Add`}</CustomButton>
            </Grid>
          </Grid>
        </div>
        {loader ? (
          <Shimmer type="TrainingAssessment" />
        ) : questions && questions?.length ? (
          <div className={styles.contentWrapper}>
            {questions.map((item, index) => (
              <TrainingAssessmentItem
                index={index}
                question={item}
                handleEditAsssessment={handleEditAsssessment}
                handleDeleteAssessmentClick={handleDeleteAssessmentClick}
                questions={questions}
              />
            ))}
          </div>
        ) : (
          'No result found'
        )}
        {editAsssessment || createAsssessment ? (
          <CreateAssessmentDialog
            open={true}
            handleClose={() => {
              setCreateAsssessment(false);
              setEditAsssessment(false);
              setActiveAsssessment({});
            }}
            activeAsssessment={activeAsssessment}
            type={editAsssessment ? 'edit' : 'create'}
            handleCreateAsssessment={handleCreateAsssessment}
            onSuccessHandler={(data) => {
              handleUpdateAssessmentList(data);
              setCreateAsssessment(false);
              setEditAsssessment(false);
            }}
          />
        ) : null}

        <ConfirmationDialog
          open={deleteAsssessmentQuestion}
          title={
            <>
              Are you sure you want to delete{' '}
              <strong className="heading3">"{activeAsssessment?.name}"</strong> ?
            </>
          }
          description=""
          acceptText="Yes, Delete"
          rejectText="No, Cancel"
          image={imageBasePath + 'delete_confirmation.svg'}
          handleClose={() => {
            setDeleteAsssessmentQuestion(false);
          }}
          handleSubmit={handleDeleteQuestion}
          loader={deleteLoader}
        />
      </div>
    </>
  );
}

function TrainingAssessmentItem(props) {
  const { question, index, questions } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { feedbackContentTypes, criteria_title } = useAssessment();

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const getFeedbackContentType = (type) => {
    let contentType;
    contentType = feedbackContentTypes.find((content) => content.value == type);
    return contentType;
  };

  return (
    <ReadOnlyWrapper data={question} label="assessments">
      <div className={styles.cardItem}>
        <Grid container direction="row" spacing={2} className={styles.titleRow}>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            {question.name ? (
              <h3 className="heading5">
                <span>{index + 1}. </span> {question.name}
              </h3>
            ) : (
              ''
            )}
            <p className="supportText">{question.description}</p>
            {question?.answer_type && (
              <List className="listInline">
                <ListItem className={' supportText listInlineItem'}>
                  <span className={styles.listTitle}>Response Type: </span>
                </ListItem>
                <ListItem className={' supportText listInlineItem'}>
                  <span className="supportText">
                    {getFeedbackContentType(question.answer_type)?.label}
                  </span>
                </ListItem>
              </List>
            )}
            {question?.feedback_questions?.length > 0 && (
              <List className="listInline">
                <ListItem className={' supportText listInlineItem'}>
                  <span className={styles.listTitle}>{criteria_title({ item: question })} </span>
                </ListItem>
                {question?.feedback_questions?.map((item, index) => (
                  <ListItem key={index} className={' supportText listInlineItem'}>
                    <span className={styles.tag}>{item.name}</span>
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className="textRight">
            <div className={styles.moreBtn}>
              <span className={styles.more}>
                <a
                  aria-controls={'simple-menu-' + index}
                  className={'more'}
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                >
                  <img
                    className="more"
                    src={imageBasePath + 'more_vertical_gray.svg	'}
                    alt="More"
                    style={{ verticalAlign: 'middle' }}
                  />
                </a>
              </span>
            </div>
            <Menu
              id={'simple-menu-' + index}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={(e) => {
                setAnchorEl(null);
              }}
              className="moreMenutraining  moreMenu"
            >
              <MenuItem
                onClick={(e) => {
                  setAnchorEl(null);
                  props.handleEditAsssessment(question);
                }}
              >
                <img src={imageBasePath + 'edit.svg'} alt="Edit" style={{ marginRight: '4px' }} />
                <span className="supportText">Edit</span>
              </MenuItem>
              {questions && questions.length > 1 ? (
                <MenuItem
                  className="deleteAction"
                  onClick={(e) => {
                    setAnchorEl(null);
                    props.handleDeleteAssessmentClick(question);
                  }}
                >
                  <img
                    src={imageBasePath + 'delete_red.svg'}
                    alt="Delete"
                    style={{ marginRight: '4px' }}
                  />
                  <span className="supportText">Delete</span>
                </MenuItem>
              ) : (
                ''
              )}
            </Menu>
          </Grid>
        </Grid>
      </div>
    </ReadOnlyWrapper>
  );
}

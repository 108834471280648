import React, { useMemo } from 'react';
import styles from './StatusLabel.module.scss';
import { imageBasePath } from 'constants';

const StatusLabel = ({ disabled, published, draft, label }) => {
  const style = useMemo(() => {
    if (disabled) return styles.disabled;
    if (published) return styles.published;
    if (draft) return styles.draft;
  }, [disabled, published, draft]);

  const image = useMemo(() => {
    if (disabled) return `${imageBasePath}disabled_module_icon.svg`;
    if (published) return `${imageBasePath}module_published_icon.svg`;
    return '';
  }, [disabled, published]);

  const _label = useMemo(() => {
    if (label) return label;
    if (disabled) return 'Disabled';
    if (published) return 'Published';
    if (draft) return 'Draft';
  }, [disabled, published, draft, label]);

  return (
    <div className={`${styles.moduleStatus} ${style}`}>
      {image && <img src={image} alt="" />}
      <span className="supportText" style={{ textWrap: 'nowrap' }}>
        {_label}
      </span>
    </div>
  );
};

export default StatusLabel;

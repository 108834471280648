import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { imageBasePath } from '../constants';

const TooltipCustom = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#415766',
    fontSize: 12,
    padding: '16px',
    boxShadow: '0px 4px 9px rgba(170, 170, 170, 0.25)',
    borderRadius: '4px',
    maxWidth: 230,
  },
  arrow: {
    color: '#ffffff',
  },
}))(Tooltip);

export default function CustomTooltipWhite(props) {
  const rest = { ...props };
  delete rest?.tooltipIcon;

  return (
    <TooltipCustom
      className={props.className}
      title={<div className="supportText">{props.title}</div>}
      arrow
      {...rest}
    >
      <img alt="" src={imageBasePath + props.tooltipIcon} className="customTooltipIcon" style={{width: '14px', height: '14px'}}/>
    </TooltipCustom>
  );
}

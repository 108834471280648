import { FeatureFlag } from 'controllers/_exports';
import { API } from '../../utils/globalApiServices';
import { useStreamStore } from './Stream.store';

export async function getPostCategories() {
  const url = '/stream/category/post/';
  const { data } = await API.get(url);
  return data;
}

export async function getHomeDetails({ time = 'all-time' }) {
  let url = '/home/stream/';
  if (FeatureFlag.enabled('HOME_FILTERS')) url += `?time_lapse=${time}`;
  const { data } = await API.get(url);
  return data;
}

export async function getListComments({ post_id }) {
  const url = `/stream/post/${post_id}/comment/`;
  const { data } = await API.get(url);
  return data;
}

export async function createReactionCheer({ postId }) {
  const url = `/stream/post/${postId}/reaction/cheer/`;
  const { data } = await API.post(url);
  return data;
}
export async function createReactionLike({ postId }) {
  const url = `/stream/post/${postId}/reaction/like/`;
  const { data } = await API.post(url);
  return data;
}
export async function createReactionLove({ postId }) {
  const url = `/stream/post/${postId}/reaction/love/`;
  const { data } = await API.post(url);
  return data;
}

export async function createComment({ postId, comment }) {
  const url = `/stream/post/${postId}/comment/create/`;
  const { data } = await API.post(url, { comment: comment });
  return data;
}

export async function createPost({ categoryId, post, additional_file }) {
  const url = `/stream/category/${categoryId}/post/create/`;
  const { data } = await API.post(url, { post: post, additional_file: additional_file });
  return data;
}

export async function getPostsService({ user, category, page, time = 'all-time' }) {
  let url = `/stream/post/v2/?user=${user}&page=${page}`;
  if (FeatureFlag.enabled('HOME_FILTERS')) url += `&time_lapse=${time}`;
  if (category) url += `&category=${category}`;
  const { data } = await API.get(url);
  useStreamStore.setState({ pagination: data?.pagination });
  return data?.list;
}

export async function getFeaturedPostsService() {
  let url = `/stream/featured-posts/`;
  const { data } = await API.get(url);
  return data?.list;
}

export async function getUsersService() {
  const users = [];
  let page = 1;
  let totalPages = 1;

  do {
    const url = `/users/?status=1&page=${page}`;
    const { data } = await API.get(url);
    totalPages = data?.pagination?.number_of_pages;
    users.push(...data?.list);
    page++;
  } while (page <= totalPages);

  return users;
}

export async function deletePost({ postId }) {
  const url = `/stream/${postId}/delete-post/`;
  const response = await API.del(url);
  return response;
}

export async function createReactionCheerComment({ postId, commentId }) {
  const url = `/stream/post/${postId}/reaction/cheer/${commentId}/`;

  const { data } = await API.post(url);
  return data;
}

export async function createReactionLikeComment({ postId, commentId }) {
  const url = `/stream/post/${postId}/reaction/like/${commentId}/`;
  const { data } = await API.post(url);
  return data;
}

export async function createReactionLoveComment({ postId, commentId }) {
  const url = `/stream/post/${postId}/reaction/love/${commentId}/`;
  const { data } = await API.post(url);
  return data;
}

export async function createFeaturedPost({ postId }) {
  const url = `/stream/post/${postId}/featured-post/`;
  const { data } = await API.post(url);
  return data;
}

export async function getFeaturedPostCount() {
  const url = `/stream/featured-posts/count/`;
  const resp = await API.get(url);
  return resp;
}

export async function getURLMetadataService({ url }) {
  const _url = `/unfurl_link/`;
  const { data } = await API.post(_url, { url });
  return data;
}

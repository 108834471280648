import React, { useState } from 'react';
import styles from './LearningPathDetails.module.scss';
import { CONTENT_TYPES, usePathValidations } from '../../LearningPaths.utils';
import User from 'infrastructure/User';
import { imageBasePath } from 'constants';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { ConfirmationDialog } from 'shared_elements';
import Organization from 'infrastructure/Organization';
import RepStatusLabel from '../AssignedLearningPathDetails/RepStatusLabel';
import DateChip from '../AssignedLearningPathDetails/DateChip';

const ContentCard = ({ contentData, handleRemove = () => null }) => {
  const {
    uuid,
    title,
    description,
    published_at,
    updated_at,
    content_count,
    estimated_duration,
    created_by,
  } = contentData?.content || {};

  const isRep = Organization.isRep();

  const [showDnDIcon, setShowDnDIcon] = useState(false);
  const { learningPath } = useLearningPathsStore();
  const { isPathOwner } = usePathValidations({ learningPath });
  const [showModal, setShowModal] = useState(false);
  const handleClickDelete = () => setShowModal(true);
  const [deleteLoader, setDeleteLoader] = useState(false);

  // TODO separate per file when more tpes of content are added
  if (contentData?.content_type === CONTENT_TYPES.TRAINING_MODULES)
    return (
      <>
        <ConfirmationDialog
          open={showModal}
          title={<>Are you sure you want to remove this module?</>}
          description=""
          acceptText="Yes, Remove"
          rejectText="No, Cancel"
          isDelete={true}
          image={imageBasePath + 'delete_confirmation.svg'}
          handleClose={() => {
            setShowModal(false);
            setDeleteLoader(false);
          }}
          handleSubmit={() => {
            handleRemove(uuid);
            setShowModal(false);
          }}
          loader={deleteLoader}
        />
        <div
          className={styles.trainingCard}
          onMouseEnter={() => setShowDnDIcon(true)}
          onMouseLeave={() => setShowDnDIcon(false)}
        >
          <div className={styles.header}>
            <div className={styles.headerText}>
              <div className={styles.headerTextTitle}>{title}</div>
              <div className={styles.headerTextAuthor}>By {`${User.getUserName(created_by)}`}</div>
              {isRep && <RepStatusLabel status={contentData?.status} />}
            </div>
            {!isRep && handleRemove && (
              <img
                src={imageBasePath + 'deleteIconLP.svg'}
                alt="Delete"
                onClick={() => handleClickDelete()}
                className={styles.delete}
              />
            )}
          </div>
          <div className={styles.description}>
            {description.slice(0, 1500)}
            {description.length >= 1500 ? '...' : ''}
          </div>
          <div className={styles.bubblesIcon}>
            <div className={styles.bubblesContainer}>
              {published_at && <DateChip date={published_at} />}
              {updated_at && <DateChip isUpdated date={updated_at} />}
              <div>
                <img src={`${imageBasePath}contentActiveDesign.svg`} alt="icon" />
                <strong>{content_count}</strong> Contents
              </div>
              <div>
                <img src={`${imageBasePath}clockActive.svg`} alt="icon" />
                <strong>{estimated_duration} mins</strong> duration
              </div>
            </div>
            {!isRep && showDnDIcon && isPathOwner && (
              <img className={styles.dndIcon} src={`${imageBasePath}dndIcon.svg`} alt="icon" />
            )}
          </div>
        </div>
      </>
    );

  return null;
};

export default ContentCard;

import React, { useState, useRef } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath, contentTypes } from '../../../constants';
import styles from '../../../assets/styles/onboarding.module.scss';
import {
  CustomButton,
  CustomSwitch,
  CustomTooltipWhite,
  ReactHtmlParser,
} from '../../../shared_ui_components';
import { getContentTypeImg } from '../../../utils';
import VideoDialog from './VideoDialog';

export default function IntroductionCard(props) {
  const { activeStep } = props;
  const [open, setOpen] = useState(false);
  const vidRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    vidRef.current.pause();
  };

  const downloadSample = (content) => {
    const win = window.open(content, '_blank');
    win.focus();
  };

  return (
    <>
      <div className={styles.introductionCardWrapper}>
        <Grid container direction="row" spacing={2} className={styles.titleRow}>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <h3 className="heading4">{activeStep.name}</h3>
            <p className="paragraph">{activeStep.description}</p>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <List className="listInline textRight">
              <ListItem className={styles.infoItem + ' listInlineItem'}>
                <CustomTooltipWhite
                  placement="top"
                  tooltipIcon="info_icon.svg"
                  title={
                    <div className="customTooltipContent">
                      <p className="supportText">
                        {activeStep.status
                          ? `The Enabled step is visible to your team!`
                          : `The Disabled step won't be visible to your team!`}
                      </p>
                    </div>
                  }
                />
                <span className={styles.text + ' supportTextSemibold'}>
                  {activeStep.status ? `Enabled` : `Disabled`}
                </span>
              </ListItem>
              <ListItem className="listInlineItem">
                <CustomSwitch
                  checked={activeStep.status ? true : false}
                  onChange={() => props.handleEnabelDisable()}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        {activeStep.content_type === 1 ? (
          <div className={styles.contentWrapper + '  ' + styles.contentWrapperSample}>
            <div className={styles.imageWrapper}>
              <img src={imageBasePath + 'video_sample_image.png'} alt="text" />
              {/* <p className={styles.downloadBtn + ' textCenter'}>
                <CustomButton 
                  className={`secondaryBtn smallBtn ${styles.downloadSampleBtn}`}
                  children={`Download Sample ${contentTypes.find(type => type.id === activeStep.content_type)?.label}`}
                  onClick={() => downloadSample(activeStep.content)}
                />
              </p> */}
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">Tips</h4>
              <div className="paragraph">{<ReactHtmlParser content={activeStep.tips} />}</div>
            </div>
          </div>
        ) : (
          // <div className={styles.contentWrapper}>
          //   <div className={styles.videoWrapper}>
          //     <div className={styles.videoContent} onClick={() => {handleClickOpen()}} >
          //       <img className={styles.playIcon} src={imageBasePath+'play_btn.svg'} alt="play" />
          //       <p className="paragraph">View Sample Video</p>
          //     </div>
          //     <video
          //       muted
          //       ref={vidRef}
          //     >
          //       <source src={activeStep.content ? activeStep.content : "loom_recording.mp4"} type="video/mp4" />
          //       <source src={activeStep.content ? activeStep.content : "loom_recording.mp4"} type="video/ogg" />
          //       Your browser does not support the video tag.
          //     </video>
          //     <div className={styles.overlay}></div>
          //   </div>

          //   <div className={styles.contentBlk}>
          //     <h4 className="heading4">Tips</h4>
          //     <div className="paragraph">
          //       {ReactHtmlParser(activeStep.tips)}
          //     </div>
          //   </div>
          // </div>
          // :
          <div className={styles.contentWrapper + '  ' + styles.contentWrapperSample}>
            <div className={styles.imageWrapper}>
              {getContentTypeImg(activeStep.content_type)}
              {/* <p className={styles.downloadBtn + ' textCenter'}>
                <CustomButton 
                  className={`secondaryBtn smallBtn ${styles.downloadSampleBtn}`}
                  children={`Download Sample ${contentTypes.find(type => type.id === activeStep.content_type)?.label}`}
                  onClick={() => downloadSample(activeStep.content)}
                />
              </p> */}
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">Tips</h4>
              <div className="paragraph">{<ReactHtmlParser content={activeStep.tips} />}</div>
            </div>
          </div>
        )}

        <div className={styles.addvideoBtnBlk + ' textCenter'}>
          <CustomButton
            className={`primaryBtn`}
            children={`Add a ${
              contentTypes.find((type) => type.id === activeStep.content_type)?.label
            }`}
            onClick={() => props.handleAddContent(true)}
          />
        </div>
      </div>

      <VideoDialog open={open} onClose={handleClose} activeStep={activeStep} />
    </>
  );
}

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ProviderConstants from 'features/providers/Providers.constants';
import styles from './SuccessModal.module.scss';
import { CustomButton } from 'shared_ui_components';

import { useHubspotStore } from 'features/hubspot/Hubspot.store';

const SuccessModal = ({ data, onClose = () => null }) => {
  const history = useHistory();
  useEffect(() => {
    if (!data) return;
    history.push(`/settings?active=integrations`);
  }, [data, history]);

  // Handle continue depending on provider
  const openHubspotSettings = useHubspotStore((state) => state?.openSettings);

  const handleContinue = () => {
    if (data?.code === ProviderConstants.providerCodes.HUBSPOT) openHubspotSettings();
    onClose();
  };

  return (
    <Dialog className={styles.successContainer} open={!!data}>
      <DialogTitle>
        <img alt="" src={data.success_image} />
      </DialogTitle>
      <DialogContent className={styles.title}>
        <h2>Synced data from {data?.name}</h2>
        <p>You have successfully added data from {data?.name}</p>
        <div className={styles.buttons}>
          <CustomButton className="secondaryBtn" onClick={onClose}>
            Close
          </CustomButton>
          <CustomButton onClick={handleContinue}>Continue</CustomButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;

import React, { useMemo } from 'react';
import styles from './MultipleChoiceSelector.module.scss';

const MultipleChoiceSelector = ({ question, handleChoiceChange = () => null }) => {
  const selected = question?.answer?.value;
  const onClick = (id) => handleChoiceChange({ choiceID: id, question });

  const questions = useMemo(() => {
    if (question?.feedback_questions?.length === 0) return;
    return shuffle(question?.feedback_questions);
  }, [question.feedback_questions]);

  return (
    <div className={styles.choicesContainer}>
      {questions?.map(({ id, name }) => (
        <div className={styles.choice} onClick={() => onClick(id)} key={id}>
          <div className={selected == id ? styles.selected : styles.radio}>
            {selected == id && (
              <svg
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2942 1.29164L3.79423 7.79164L0.815063 4.81248L1.57881 4.04873L3.79423 6.25873L9.53048 0.527893L10.2942 1.29164Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
};

function shuffle(original) {
  const array = [...original];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
export default MultipleChoiceSelector;

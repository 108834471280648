import { useTheme, useMotionConfig } from '@nivo/core';
import { useSpring, animated } from 'react-spring';

const PartSeparator = ({ part, separator }) => {
  const theme = useTheme();
  const { animate, config: motionConfig } = useMotionConfig();

  const animatedProps = useSpring({
    transform: `translate(${separator.x0 + 6}, 20)`,
    transformLabel: `translate(${separator.x0 + 6}, 40)`,
    color: '#000000',
    label_color: '#87A0B4',
    config: motionConfig,
    immediate: !animate,
  });

  return (
    <>
      <animated.g transform={animatedProps.transform}>
        <animated.text
          textAnchor="start"
          dominantBaseline="center"
          style={{
            fill: animatedProps.color,
            fontSize: '20px',
            pointerEvents: 'none',
          }}
        >
          {part.data?.count}
        </animated.text>
      </animated.g>
      <animated.g transform={animatedProps.transformLabel}>
        <animated.text
          textAnchor="start"
          dominantBaseline="start"
          style={{
            fill: animatedProps.label_color,
            fontSize: '10px',
            textTransform: 'uppercase',
            pointerEvents: 'none',
          }}
        >
          {part.data?.label}
        </animated.text>
      </animated.g>
    </>
  );
};

const FunnelSeparators = (props) => {
  return props?.parts.map((part, i) => (
    <PartSeparator key={part.data.id} part={part} separator={props?.afterSeparators[i]} />
  ));
};

export default FunnelSeparators;

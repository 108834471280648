import { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import Rating from '@material-ui/lab/Rating';
import { Grid, Avatar, List, ListItem } from '@material-ui/core';
import styles from '../../../assets/styles/training.module.scss';
import { imageBasePath } from '../../../constants';
import {
  ProgressBar,
  CustomTooltipWhite,
  CustomButton,
  Nudge,
} from '../../../shared_ui_components';
import { OverviewHero } from '../../../shared_elements';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { downloadFileType, TooltipCustom } from '../../../utils';
import 'react-circular-progressbar/dist/styles.css';
import {
  getTrainingTeamProgressApi,
  getTrainingProgressRatingApi,
  downloadReport,
  getTrainingUserReadinessApi,
  getModuleStatistics,
} from '../apiServices';
import Shimmer from './Shimmer';
import FeedbackAssessmentDialog from './FeedbackAssessmentDialog';
import { getUserAssesmentApi } from '../apiServices';
import queryString from 'query-string';
import { FeatureFlag, ReadOnlyWrapper } from 'controllers/_exports';
import stylesTrackin from '../../../assets/styles/tracking.module.scss';
import DateTime from 'infrastructure/Datetime';
import Info_red from 'assets/icons/Important_red.svg';
import {
  useGetLearningPathDetailsListQuery,
  useGetLearningPathProgressListQuery,
} from './LearningPaths/LearningPaths.queries';
import LearningPathItem from './LearningPathItem';
import Filter from 'features/home/components/Filters/Filter';
import { useTrainingStore } from '../Training.store';
import style from './Training.module.scss';
export default function TrainingDashboard() {
  const [teamProgress, setTeamProgress] = useState([]);
  const [userReadinessIndex, setUserReadinessIndex] = useState('');
  const [progressRating, setProgressRating] = useState({});
  const [moduleDetails, setModuleDetails] = useState('');
  const [downloadReportLoader, downloadReportLoaderSet] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [count, setCount] = useState(1);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [feedbackAsssessment, setFeedbackAsssessment] = useState(false);
  const [userAssessment, setUserAssessment] = useState({});
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [user, setUser] = useState('');
  const [realTime, setRealTime] = useState('');

  const orderedTeamProgress = useMemo(() => orderTeamProgress(teamProgress), [teamProgress]);
  const { selectedDateFilterTraining } = useTrainingStore();

  const [loader, setLoader] = useState({
    progressRating: false,
    averageTimeTaken: false,
  });
  const { data: LearningPathsDetails } = useGetLearningPathDetailsListQuery({
    time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilterTraining.slug : 'all-time',
  });
  const { data: LearningPathProgress, isLoading } = useGetLearningPathProgressListQuery({
    time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilterTraining.slug : 'all-time',
  });

  const LearningPathProgressData = useMemo(() => {
    if (isLoading)
      return {
        completed_count: 0,
        in_progress_count: 0,
        total_module: 0,
        total_module_optional: 0,
        total_published_modules: 0,
        yet_to_start_count: 0,
      };
    return {
      completed_count: LearningPathProgress[0]?.total_learning_paths_completed || 0,
      in_progress_count: LearningPathProgress[0]?.total_learning_paths_progress || 0,
      total_module: LearningPathProgress[0]?.total_learning_paths || 0,
      total_module_optional: 0,
      total_published_modules: LearningPathProgress[0]?.total_learning_paths || 0,
      yet_to_start_count: LearningPathProgress[0]?.total_learning_paths_start || 0,
    };
  }, [LearningPathProgress, isLoading]);
  const { search } = useLocation();
  const history = useHistory();
  const handleFeedbackAssessment = (user) => {
    setFeedbackAsssessment(true);
    setFeedbackLoader(true);
    getUserAssesmentApi(user?.uuid).then((response) => {
      if (response.statusCode === 200) {
        setFeedbackLoader(false);
        setUserAssessment(response.data);
      }
    });
  };

  useEffect(() => {
    const query = queryString.parse(search);

    if (query.assessment) {
      handleFeedbackAssessment({ uuid: query.assessment });
      history.push({ search: '' });
    }
  }, [search]);

  const fetchTeamProgress = (query = {}) => {
    setPaginationLoader(true);
    setViewLoader(true);
    getTrainingTeamProgressApi({
      ...query,
      time_lapse: FeatureFlag.enabled('HOME_FILTERS')
        ? selectedDateFilterTraining.slug
        : 'all-time',
    })
      .then((response) => {
        if (response.statusCode === 200) {
          if (response?.data?.list) {
            if (response?.data?.pagination?.current_page > 1)
              setTeamProgress([...teamProgress, ...response?.data?.list]);
            else setTeamProgress([...response?.data?.list]);
          } else {
            setTeamProgress([]);
          }

          setPagination(response?.data?.pagination || []);
          setCount(response?.data?.pagination?.current_page || 1);
          setRealTime(response?.data?.real_time || realTime);
        }
      })
      .finally(() => {
        setPaginationLoader(false);
        setViewLoader(false);
      });
  };

  const handleViewMore = () => {
    if (count <= pagination.number_of_pages) {
      setCount((prevCount) => prevCount + 1);
      fetchTeamProgress({ page: count + 1 });
    }
  };

  useEffect(() => {
    getTrainingUserReadinessApi({
      time_lapse: FeatureFlag.enabled('HOME_FILTERS')
        ? selectedDateFilterTraining.slug
        : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setUserReadinessIndex(response?.data?.average_overall_readiness_index);
      }
    });
  }, [selectedDateFilterTraining]);
  useEffect(() => {
    getModuleStatistics({
      time: FeatureFlag.enabled('HOME_FILTERS') ? selectedDateFilterTraining.slug : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setModuleDetails(response.data);
      }
    });
  }, [selectedDateFilterTraining]);
  useEffect(() => {
    fetchTeamProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateFilterTraining]);
  useEffect(() => {
    getTrainingProgressRatingApi({
      time_lapse: FeatureFlag.enabled('HOME_FILTERS')
        ? selectedDateFilterTraining.slug
        : 'all-time',
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevState) => ({
          ...prevState,
          progressRating: false,
        }));
        setProgressRating(response.data);
      }
    });
  }, [selectedDateFilterTraining]);
  const downloadDashboard = () => {
    downloadReportLoaderSet(true);
    downloadReport()
      .then((response) => {
        downloadReportLoaderSet(false);
        if (response) {
          downloadFileType(response, 'Training Dashboard', '.pdf');
        } else {
          console.log(response, 'err');
        }
      })
      .catch((err) => {
        // console.error(err,"err");
      });
  };
  const updateUserAssessmentStatus = (uuid, status) => {
    const updatedUser = Object.assign(
      {},
      teamProgress.find((user) => user.uuid === uuid)
    );
    updatedUser['assessment_status'] = status;
    setTeamProgress((prevData) =>
      prevData.map((user) => (user.uuid === uuid ? updatedUser : user))
    );
  };

  const handleUpdateUserDialog = (val) => {
    setUser(val);
    handleFeedbackAssessment(val);
  };

  function orderTeamProgress(teamProgress = []) {
    // Order by completion index
    const arrCompleted = teamProgress
      .filter((item) => item?.completed_percentage > 0)
      .sort((a, b) => b?.completed_percentage - a?.completed_percentage);
    // Order by in progress index
    const arrProgress = teamProgress
      .filter((item) => !item?.completed_percentage && item.inprogress_percentage > 0)
      .sort((a, b) => b?.inprogress_percentage - a?.inprogress_percentage);
    // Order by not started index
    const arrStarted = teamProgress
      .filter(
        (item) =>
          !item?.completed_percentage &&
          !item?.inprogress_percentage &&
          item.not_started_percentage >= 0
      )
      .sort((a, b) => b?.not_started_percentage - a?.not_started_percentage);

    return [...arrCompleted, ...arrProgress, ...arrStarted];
  }

  return (
    <>
      <FeedbackAssessmentDialog
        open={feedbackAsssessment}
        handleClose={() => {
          setFeedbackAsssessment(false);
          setUserAssessment({});
        }}
        userAssessment={userAssessment}
        feedbackLoader={feedbackLoader}
        updateUserAssessmentStatus={updateUserAssessmentStatus}
        assessmentStatus={user?.assessment_status}
      />
      <section className="filterSection">
        <Grid container direction="row" spacing={2} justifyContent="flex-end">
          <Grid item lg={6} md={6} sm={8} xs={12}>
            <div className="filterBlocker flexCentered">
              <div style={{ display: 'flex', maxHeight: '3rem', gap: '1rem' }}>
                <button
                  className={`paragraph downloadBtn ${downloadReportLoader ? 'disabledBtn' : ''}`}
                  onClick={() => downloadDashboard()}
                >
                  <img src={imageBasePath + 'download.svg'} alt="download icon" />
                  {downloadReportLoader ? 'Downloading...' : 'Download Report'}
                </button>
                {FeatureFlag.enabled('HOME_FILTERS') && (
                  <div className={style.filter}>
                    <span>Period</span>
                    <Filter page="training" />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <OverviewHero
        chartData={{
          title: 'Module Progress',
          totalText: 'Total modules available',
          progress: progressRating?.progress,
        }}
        overallRating={{
          title: 'Overall Ratings',
          ratingInfo: {
            averageRating: progressRating?.ratings?.total_rating,
            rating1: progressRating?.ratings?.rating_1,
            rating1Users: progressRating?.ratings?.rating_1_users,
            rating1UsersLeft: progressRating?.ratings?.rating_1_users_left,
            rating1Percentage: progressRating?.ratings?.rating_1_percentage,
            rating2: progressRating?.ratings?.rating_2,
            rating2Users: progressRating?.ratings?.rating_2_users,
            rating2UsersLeft: progressRating?.ratings?.rating_2_users_left,
            rating2Percentage: progressRating?.ratings?.rating_2_percentage,
            rating3: progressRating?.ratings?.rating_3,
            rating3Users: progressRating?.ratings?.rating_3_users,
            rating3UsersLeft: progressRating?.ratings?.rating_3_users_left,
            rating3Percentage: progressRating?.ratings?.rating_3_percentage,
            rating4: progressRating?.ratings?.rating_4,
            rating4Users: progressRating?.ratings?.rating_4_users,
            rating4UsersLeft: progressRating?.ratings?.rating_4_users_left,
            rating4Percentage: progressRating?.ratings?.rating_4_percentage,
            rating5: progressRating?.ratings?.rating_5,
            rating5Users: progressRating?.ratings?.rating_5_users,
            rating5UsersLeft: progressRating?.ratings?.rating_5_users_left,
            rating5Percentage: progressRating?.ratings?.rating_5_percentage,
            totalReviews: progressRating?.ratings?.total_reviews,
          },
        }}
        chartDataLearningPaths={{
          title: 'Learning Path Progress',
          totalText: 'Total paths available',
          progress: LearningPathProgressData,
        }}
        progressStatus={{
          title: `${userReadinessIndex ? `${Math.round(userReadinessIndex)}%` : '--'}`,
          description: 'Sales Readiness Index',
          image: imageBasePath + 'readiness_index.svg',
        }}
        page="training"
      />

      {/* <Shimmer type="TrainingOverview" /> */}
      <section className={`${styles.teamProgress} dashboardListing`}>
        <Grid container direction="row">
          <Grid md={12}>
            <div className="teamProgressWrapper">
              <Grid container direction="row" spacing={4} className="headingRow">
                <Grid item md={5}>
                  <h3 className="heading5">Team Progress</h3>
                </Grid>
                {/* <Grid item md={7}>
                                    <ul className={`listInline filtersList`}>
                                        <li className="listInlineItem inputGroup">
                                            <input type="text" className="search" placeholder="Search for a user" />
                                        </li>
                                        <li className="listInlineItem">
                                            <button className="supportText">
                                                Filter
                                                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33333 0C0.873095 0 0.5 0.373096 0.5 0.833333C0.5 1.29357 0.873096 1.66667 1.33333 1.66667H14.6667C15.1269 1.66667 15.5 1.29357 15.5 0.833333C15.5 0.373096 15.1269 0 14.6667 0H1.33333ZM6.33374 9.16646C6.33374 9.6267 6.70684 9.9998 7.16707 9.9998H8.83374C9.29398 9.9998 9.66707 9.6267 9.66707 9.16646C9.66707 8.70623 9.29398 8.33313 8.83374 8.33313H7.16707C6.70684 8.33313 6.33374 8.70623 6.33374 9.16646ZM3.83364 5.83415C3.3734 5.83415 3.00031 5.46105 3.00031 5.00081C3.00031 4.54058 3.3734 4.16748 3.83364 4.16748H12.167C12.6272 4.16748 13.0003 4.54058 13.0003 5.00081C13.0003 5.46105 12.6272 5.83415 12.167 5.83415H3.83364Z" fill="#BFD4DF"/>
                                                </svg>
                                            </button>
                                        </li>
                                    </ul>
                                </Grid> */}
              </Grid>
              <div className="teamProgressDetail">
                <Grid container direction="row" spacing={4} className="titleRow">
                  <Grid item md={3}>
                    <h6 className="supportTextSemibold">User</h6>
                  </Grid>
                  <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                    <h6 className="supportTextSemibold">Completion Index</h6>
                  </Grid>
                  <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 6 : 5}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                        <h6 className={`supportTextSemibold ${styles.readinessIndex}`}>
                          <img src={imageBasePath + 'info_icon.svg'} alt="info" />
                          Readiness Index
                        </h6>
                      </Grid>
                      {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && (
                        <Grid item md={3} style={{ display: 'flex', gap: '0.5rem' }}>
                          <CustomTooltipWhite
                            tooltipIcon="info_icon.svg"
                            title={
                              <p className={stylesTrackin.checkinTooltipContent + ' heading6 '}>
                                This Field Shows The Actual Vs Planned Time Of Completion for
                                mandatory content
                              </p>
                            }
                            className={stylesTrackin.checkinTooltip}
                            placement="top"
                          />
                          <h6 className={`supportTextSemibold ${styles.readinessIndex}`}>
                            Duration ({DateTime.formatSecondsToHHMM(realTime)})
                          </h6>
                        </Grid>
                      )}
                      <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                        <h6 className="supportTextSemibold">Feedback</h6>
                      </Grid>

                      <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                        <h6 className="supportTextSemibold">
                          {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 'Assessment' : ''}
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="contentBlock">
                  {teamProgress && teamProgress.length ? (
                    <>
                      {orderedTeamProgress.map((user) => (
                        <TeamMember
                          key={user.uuid}
                          user={user}
                          handleUpdateUserDialog={handleUpdateUserDialog}
                          // handleFeedbackAssessment={handleFeedbackAssessment}
                          updateUserAssessmentStatus={updateUserAssessmentStatus}
                        />
                      ))}
                      {pagination.total && pagination.total > 10 ? (
                        <div className={styles.viewMoreBtn}>
                          <p
                            className={
                              pagination.total == teamProgress.length ? 'hidden' : 'textCenter'
                            }
                            style={{ margin: '24px 0 8px' }}
                          >
                            <CustomButton className="secondaryBtn" onClick={handleViewMore}>
                              {viewLoader ? 'Loading...' : 'View More'}
                            </CustomButton>
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : paginationLoader ? (
                    <p className="paragraph">
                      {' '}
                      <Shimmer type="TrainingTeamProgress" />
                    </p>
                  ) : (
                    <p className="paragraph">No data available</p>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <br />
      <section className={`${styles.moduleDetails} dashboardListing`}>
        <Grid container direction="row">
          <Grid md={12}>
            <div className="teamProgressWrapper">
              <Grid container direction="row" spacing={4} className="headingRow">
                <Grid item md={5}>
                  <h3 className="heading5">Module Details</h3>
                </Grid>
                {/* <Grid item md={7}>
                                    <ul className={`listInline filtersList`}>
                                        <li className="listInlineItem inputGroup">
                                            <input type="text" className="search" placeholder="Search for a user" />
                                        </li>
                                        <li className="listInlineItem">
                                            <button className="supportText">
                                                Filter
                                                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33333 0C0.873095 0 0.5 0.373096 0.5 0.833333C0.5 1.29357 0.873096 1.66667 1.33333 1.66667H14.6667C15.1269 1.66667 15.5 1.29357 15.5 0.833333C15.5 0.373096 15.1269 0 14.6667 0H1.33333ZM6.33374 9.16646C6.33374 9.6267 6.70684 9.9998 7.16707 9.9998H8.83374C9.29398 9.9998 9.66707 9.6267 9.66707 9.16646C9.66707 8.70623 9.29398 8.33313 8.83374 8.33313H7.16707C6.70684 8.33313 6.33374 8.70623 6.33374 9.16646ZM3.83364 5.83415C3.3734 5.83415 3.00031 5.46105 3.00031 5.00081C3.00031 4.54058 3.3734 4.16748 3.83364 4.16748H12.167C12.6272 4.16748 13.0003 4.54058 13.0003 5.00081C13.0003 5.46105 12.6272 5.83415 12.167 5.83415H3.83364Z" fill="#BFD4DF"/>
                                                </svg>
                                            </button>
                                        </li>
                                    </ul>
                                </Grid> */}
              </Grid>
              <div className="teamProgressDetail">
                <Grid container direction="row" spacing={4} className="titleRow">
                  <Grid item md={3}>
                    <h6 className="supportTextSemibold">Module Name</h6>
                  </Grid>
                  <Grid item md={4}>
                    <h6 className="supportTextSemibold">Users Completed</h6>
                  </Grid>
                  <Grid item md={2}></Grid>
                  <Grid item md={3}>
                    <h6 className="supportTextSemibold">Votes & Comments</h6>
                  </Grid>

                  {/* <Grid item md={2}>
                                        <h6 className="supportTextSemibold">UPVOTES</h6>
                                    </Grid>
                                    <Grid item md={2}>
                                        <h6 className="supportTextSemibold">DOWNVOTES</h6>
                                    </Grid>
                                    <Grid item md={2}>
                                        <h6 className="supportTextSemibold">COMMENTS</h6>
                                    </Grid> */}
                </Grid>
                <div className="contentBlock">
                  {moduleDetails ? (
                    moduleDetails.length ? (
                      moduleDetails
                        .sort((a, b) => b?.completed_user - a?.completed_user)
                        .map((module) => (
                          <ReadOnlyWrapper key={module.uuid} data={module}>
                            <ModuleItem module={module} />
                          </ReadOnlyWrapper>
                        ))
                    ) : (
                      <p className="paragraph">No data available</p>
                    )
                  ) : (
                    <p className="paragraph">
                      <Shimmer type="TrainingModuleDetails" />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      {FeatureFlag.enabled('LEARNING_PATHS') && (
        <section className={`${styles.moduleDetails} dashboardListing`}>
          <Grid container direction="row">
            <Grid md={12}>
              <div className="teamProgressWrapper">
                <Grid container direction="row" spacing={4} className="headingRow">
                  <Grid item md={5}>
                    <h3 className="heading5">Learning Paths Details</h3>
                  </Grid>
                </Grid>
                <div className="teamProgressDetail">
                  <Grid container direction="row" spacing={4} className="titleRow">
                    <Grid item md={3}>
                      <h6 className="supportTextSemibold">Path Name</h6>
                    </Grid>
                    <Grid item md={4}>
                      <h6 className="supportTextSemibold">Users Completed</h6>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <h6 className="supportTextSemibold">users</h6>
                    </Grid>
                  </Grid>
                  <div className="contentBlock">
                    {LearningPathsDetails ? (
                      LearningPathsDetails.length ? (
                        LearningPathsDetails.sort(
                          (a, b) => b?.completed_user - a?.completed_user
                        ).map((learningPath) => (
                          <ReadOnlyWrapper key={learningPath?.uuid} data={learningPath}>
                            <LearningPathItem learningPath={learningPath} />
                          </ReadOnlyWrapper>
                        ))
                      ) : (
                        <p className="paragraph">No data available</p>
                      )
                    ) : (
                      <p className="paragraph">
                        <Shimmer type="TrainingModuleDetails" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
}

function ModuleItem(props) {
  const { module = {} } = props;

  const colorClass = useMemo(() => {
    if (module?.completed_user >= 90) return 'status1';
    return 'status2Bg';
  }, [module?.completed_user]);

  return (
    <div className="item">
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item md={3}>
          <h5 className="heading5">{module.title}</h5>
        </Grid>
        <Grid item md={4}>
          <ProgressBar
            colorClass={colorClass}
            className="stepProgress thick-progress"
            value={{
              completed: module.completed_user,
              inProgress: 0,
              notStarted: 0,
            }}
            max="100"
            isMulti={true}
          />
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={3}>
          <List className="listInline flexCentered usersVoteList">
            <ListItem className="listInlineItem" style={{ paddingLeft: '0' }}>
              <div className={styles.upvote}>
                <img src={imageBasePath + 'upvote.svg'} alt="upvote" />
                <span className="supportText">{module.up_votes}</span>
              </div>
            </ListItem>
            <ListItem className="listInlineItem">
              <div className={styles.downvote}>
                <img src={imageBasePath + 'downvote.svg'} alt="downvote" />
                <span className="supportText">{module.down_votes}</span>
              </div>
            </ListItem>
            <ListItem className="listInlineItem">
              <div className="comments">
                <img src={imageBasePath + 'comment_icon.svg'} alt="comment" />
                <span className="supportText">{module.comment_count}</span>
              </div>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
function TeamMember(props) {
  const { user = {}, handleUpdateUserDialog } = props;
  const [openAlert, setOpenAlert] = useState(false);

  const tooltipProps = { ...props };
  delete tooltipProps.handleUpdateUserDialog;
  delete tooltipProps.updateUserAssessmentStatus;

  const copyEmail = () => {
    var copyText = document.getElementById(`userEmail${user.uuid}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setOpenAlert(true);
    // setTimeout(() => {
    //     setOpenAlert(false)
    // }, 1000);
  };

  return (
    <>
      <div className="item">
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item md={3}>
            <div className="userDetail flexCentered" style={{ minHeight: '34px' }}>
              {user.profile_pic ? (
                <Avatar className="imageBlk avatar" src={user.profile_pic}>
                  H
                </Avatar>
              ) : (
                <Avatar className="imageBlk avatar">
                  {user.first_name ? user.first_name.substring(0, 1) : user.email.substring(0, 1)}
                </Avatar>
              )}
              <h5
                className="heading6"
                style={{
                  display: 'flex',
                  gap: 15,
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                {`${user.first_name || '\u00A0'} ${user.last_name || '\u00A0'}`}
                <Nudge user={user} />
              </h5>
              <p className="supportText">
                {`${user.email}`}
                <TooltipCustom
                  onClose={() =>
                    setTimeout(() => {
                      setOpenAlert(false);
                    }, 300)
                  }
                  arrow
                  title={
                    <div className="supportText" style={{ color: `#fff` }}>
                      {openAlert ? `Email copied to clipboard` : 'Copy email'}
                    </div>
                  }
                  {...tooltipProps}
                  placement="top"
                >
                  <img
                    className="copyText"
                    onClick={() => copyEmail()}
                    src={imageBasePath + 'copy_icon.svg'}
                    alt="copy"
                  />
                </TooltipCustom>
              </p>
              <input
                type="text"
                value={user.email}
                id={`userEmail${user.uuid}`}
                style={{ opacity: 0, position: 'absolute', zIndex: '-100' }}
              />
            </div>
          </Grid>
          <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
            <ProgressBar
              className="stepProgress"
              value={{
                completed: user.completed_percentage,
                inProgress: user.inprogress_percentage,
                notStarted: user.not_started_percentage,
              }}
              max="100"
              isMulti={true}
            />
          </Grid>
          <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 6 : 5}>
            <Grid container direction="row" spacing={2}>
              <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                <p className="supportText flexCentered">
                  {Math.round(user?.readinessindex || 0)}%
                  <div className="circularProgressbar">
                    <CircularProgressbar
                      strokeWidth={6}
                      value={user?.readinessindex || 0}
                      styles={buildStyles({
                        textColor: ' #778DA9',
                        pathColor: '#00D2AD',
                        trailColor: '#D8E1E9',
                        textSize: '16px',
                        strokeLinecap: 'butt',
                      })}
                    />
                  </div>
                </p>
              </Grid>
              {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && (
                <Grid item md={3}>
                  {FeatureFlag.enabled('TIME_TRACK_HIGHLIGHT') && user.highlight && (
                    <img src={Info_red} alt="info" style={{ marginRight: '0.5rem' }} />
                  )}
                  {FeatureFlag.enabled('TIME_TRACK_HIGHLIGHT')
                    ? isNaN(user.time_taken)
                      ? user.time_taken
                      : DateTime.formatSecondsToHHMM(user.time_taken)
                    : user.time_taken
                    ? DateTime.formatSecondsToHHMM(user.time_taken)
                    : '--'}
                </Grid>
              )}
              <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                {user.feedback_rating || user.feedback_comment ? (
                  <>
                    {user.feedback_rating ? (
                      <Rating
                        name="read-only"
                        precision={0.1}
                        value={user.feedback_rating}
                        readOnly
                      />
                    ) : null}
                    {user.feedback_comment ? (
                      <div className="comments">
                        <CustomTooltipWhite
                          placement="top"
                          tooltipIcon={'comment_icon.svg'}
                          title={
                            <div className="customTooltipContent">
                              <p className="paragraph">{user.feedback_comment}</p>
                            </div>
                          }
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Rating name="read-only" precision={0.1} value={user.feedback_rating} readOnly />
                )}
              </Grid>
              <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                {user.assessment_status != 0 ? (
                  <div className={styles.viewAssessment}>
                    <CustomButton
                      className={`secondaryBtn smallBtn`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleUpdateUserDialog(user);
                        // handleFeedbackAssessment(user);
                      }}
                    >
                      {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 'View' : 'View assessment'}
                    </CustomButton>
                    {user.assessment_status == 1 || user.assessment_status == 3 ? (
                      <span className={styles.pending}></span>
                    ) : null}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

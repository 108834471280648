import { useState, useMemo } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import { CustomTooltipWhite, CustomButton } from '../../../shared_ui_components';
import { ConfirmationDialog } from '../../../shared_elements';
import { FeatureFlag } from 'controllers/_exports';

export default function ModuleTitle(props) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { module, isAdmin, loader } = props;

  const timeEstimated = useMemo(() => {
    if (!module) return 0;
    
    const seconds = module?.contents?.reduce((acc, step) => {
      if (step?.is_required) {
        return acc + (step?.suggest_duration || 0);
      } else {
        return acc;
      }
    }, 0);
  
    return (seconds / 60).toFixed(0);
  }, [module]);

  return (
    <>
      <div className={styles.moduleTitleWrapper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          className={styles.titleRow}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className={styles.titleBlk}>
              <a onClick={props.handleGoBack} className={styles.backBtn}>
                <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
              </a>
              <div className={styles.contentBlk}>
                <h1 className="heading4">
                  <span>{module.title}</span>
                  {isAdmin ? (
                    <CustomTooltipWhite
                      placement="right"
                      tooltipIcon="info_icon.svg"
                      title={
                        <div className="customTooltipContent">
                          <p className="heading6">What content to add ? </p>
                          <List className="listInline">
                            <ListItem className="supportText">
                              Include what sales process is
                            </ListItem>
                            <ListItem className="supportText">
                              What is followed in your company
                            </ListItem>
                            <ListItem className="supportText">Best practices in sales</ListItem>
                          </List>
                        </div>
                      }
                    />
                  ) : null}
                </h1>
                <p className="paragraph">{module.description}</p>
              </div>
            </div>
          </Grid>
          {isAdmin && module?.contents?.length && !module?.published_at ? (
            <Grid item lg={4} md={4} sm={12} xs={12} className="textRight">
              {/* <CustomButton className={`${styles.publishBtn} primaryBtn ${loader ? 'disabledBtn' : ''}`} onClick={props.handlePublish}>{`Publish Now`}</CustomButton> */}
              <CustomButton
                className={`${styles.publishBtn} primaryBtn ${loader ? 'disabledBtn' : ''}`}
                onClick={() => setOpenConfirm(true)}
              >
                {loader ? `Publishing...` : `Publish Now`}
              </CustomButton>
            </Grid>
          ) : FeatureFlag.enabled('TRACK_TIME') && timeEstimated !== 0 ? (
            <div className="supportText" style={{ marginRight: '1rem' }}>
              Total estimated time of completion for this module: <strong> {timeEstimated} min</strong>
            </div>
          ) : null}
        </Grid>
      </div>
      <ConfirmationDialog
        open={openConfirm}
        title={
          <>
            Are you sure you want to publish module{' '}
            <strong className="heading3">"{module.title}"</strong>?
          </>
        }
        description="This action can't be undone, but you still can add and update your content in this module"
        acceptText="Yes, Publish"
        rejectText="No, Cancel"
        image={imageBasePath + 'module_added_success.svg'}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          props.handlePublish();
          setOpenConfirm(false);
        }}
        loader={loader}
      />
    </>
  );
}

import React from 'react';
import Seconds from './Seconds';
import IndicatorButton from './IndicatorButton';
import './Timer.scss';

const Timer = ({ seconds, handleStop, isExpanded, isRecording, isPaused }) => {
  return (
    <span className="timer-container">
      <IndicatorButton
        handleStop={handleStop}
        isExpanded={isExpanded}
        isPaused={isPaused}
        isRecording={isRecording}
      />
      <Seconds seconds={seconds} />
    </span>
  );
};

export default Timer;

import React from 'react';
import Post from '../Post/Post';
import styles from './FeaturedPostsList.module.scss';
import { useGetFeaturedPostsQuery } from 'features/stream/Stream.queries';
import Shimmer from '../../components/Shimmer';
import NoResults from '../../components/NoResults';

const FeaturedPostsList = () => {
  const { data: featuredPosts, isLoading } = useGetFeaturedPostsQuery();

  if (isLoading) return <Shimmer type="StreamOverview" />;
  if (!featuredPosts?.length > 0) return <NoResults />;

  return (
    <div className={styles.featuredPosts}>
      {featuredPosts?.map((post) => (
        <Post key={post?.id} postData={post} />
      ))}
    </div>
  );
};

export default FeaturedPostsList;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getProvidersService,
  removeIntegrationService,
  updateStatusService,
  validateKeyService,
} from './Providers.services';
import Organization from 'infrastructure/Organization';

export const provider_cache_keys = {
  PROVIDERS: 'providers',
};

export const useGetProvidersQuery = () => {
  const enabled = !Organization.isRep();
  return useQuery([provider_cache_keys.PROVIDERS], getProvidersService, { enabled });
};

export const useValidateKeyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(validateKeyService, {
    onSuccess: () => queryClient.invalidateQueries([provider_cache_keys.PROVIDERS]),
  });
};

export const useUpdateStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateStatusService(), {
    onSuccess: () => queryClient.invalidateQueries([provider_cache_keys.PROVIDERS]),
  });
};

export const useRemoveIntegrationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(removeIntegrationService, {
    onSuccess: () => queryClient.invalidateQueries([provider_cache_keys.PROVIDERS]),
  });
};

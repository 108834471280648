import { imageBasePath } from '../constants';
import NumberFormat from 'react-number-format';
import { CustomNumberFormatter } from '../utils';

export default function CustomTargetValue(props) {
  const { unit, value } = props;
  return (
    <div className="customTargetValue">
      <p className="heading5">
        <img src={`${imageBasePath}target_icon.svg`} />
        {unit && unit == 2 ? (
          <>
            <span>$</span>
            <NumberFormat
              displayType="text"
              format={CustomNumberFormatter}
              value={value}
              thousandSeparator={true}
              prefix={'$'}
            />
          </>
        ) : (
          <span>{CustomNumberFormatter(value)}</span>
        )}
      </p>
    </div>
  );
}

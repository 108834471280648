import Recruitment from './containers/Recruitment';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';

export const recruitmentRoutes = [
  {
    path: '/recruitment',
    component: pageLayoutHoc(Recruitment),
    key: 'recruitment',
  },
  {
    path: '/recruitment/overview',
    component: pageLayoutHoc(Recruitment),
    key: 'recruitment',
  },
];

import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import React from 'react';
import styles from './ReviewRoleplay.module.scss';
const ReviewRoleplayDescriptionHeader = () => {
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  return (
    <div style={{marginBottom: '1rem'}}>
      <div className={styles.headerTitle}>
        <p className="heading5">How to rate</p>
        {reviewScenarioSelected?.example_video !== null && (
          <p className={styles.link}>Roleplay Example</p>
        )}
      </div>
      <p className="supportText">
        We have put together the best practices templates to onboard your team, feel free to add any
        additional steps as you see fit.
      </p>
    </div>
  );
};

export default ReviewRoleplayDescriptionHeader;

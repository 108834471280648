import React from 'react';
import { imageBasePath } from '../constants';
import PropTypes from 'prop-types';

export default function ExternalLink(props) {
  return (
    <a
      href={props.href}
      target={props.target}
      className={props.className}
      onClick={props.onClick}
      {...props}
    >
      {props.linkimg ? <img src={imageBasePath + props.linkimg} alt={props.alt} /> : null}
      <span>{props.linktext}</span>
    </a>
  );
}

ExternalLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  linkimg: PropTypes.string,
  linktext: PropTypes.string,
  target: PropTypes.string,
};

ExternalLink.defaultProps = {
  href: '',
  className: '',
  alt: 'Image',
  onClick: () => {},
  linkimg: '',
  linktext: '',
  target: '_blank',
};

import React, { useState, useMemo } from 'react';
import { ListItem, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath } from 'constants';
import { useDeletePostMutation } from 'features/stream/Stream.queries';
import { FeatureFlag } from 'controllers/_exports';
import { ConfirmationDialog } from 'shared_elements';

export const DeletePost = ({ postId = '', data = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { mutate } = useDeletePostMutation({});
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [showModal, setShowModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(key);
  };

  const handleClickDelete = () => {
    setShowModal(true);
  };

  const deleteComment = () => {
    mutate({ postId: postId }, { onSuccess });
  };

  const onSuccess = () => {
    setShowModal(false);
  };

  const ownPost = useMemo(() => userInfo.user.email === data.user.email, [userInfo, data]);
  const hide = useMemo(() => !FeatureFlag.enabled('STREAM_IMPROVEMENTS'), []);
  if (hide || !ownPost) return null;

  return (
    <div>
      <ConfirmationDialog
        open={showModal}
        title={
          <>
            Are you sure you want to delete the content <br />
          </>
        }
        description="Comments will be deleted as well"
        acceptText="Yes, Delete"
        rejectText="No, Cancel"
        isDelete={true}
        image={imageBasePath + 'delete_confirmation.svg'}
        handleClose={() => {
          setShowModal(false);
          setDeleteLoader(false);
        }}
        handleSubmit={deleteComment}
        loader={deleteLoader}
      />
      <ListItem className="">
        <span>
          <a
            aria-controls={'simple-menu-'}
            className={'more'}
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(e, postId);
            }}
            href="."
          >
            <img
              className="more"
              src={imageBasePath + 'more_vertical.svg'}
              alt="More"
              style={{ verticalAlign: 'middle' }}
            />
          </a>
        </span>
        <Menu
          id={'simple-menu-' + postId}
          anchorEl={anchorEl}
          keepMounted
          open={currentIndex === postId && Boolean(anchorEl) ? true : false}
          onClose={(e) => {
            setAnchorEl(null);
            e.preventDefault();
            e.stopPropagation();
          }}
          className="moreMenu"
        >
          <MenuItem
            className="deleteAction"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAnchorEl(null);
              handleClickDelete();
            }}
          >
            <img src={imageBasePath + 'delete_red.svg'} alt="Delete" />
            <span className="supportText">Delete</span>
          </MenuItem>
        </Menu>
      </ListItem>
    </div>
  );
};

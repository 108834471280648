import React from 'react';
import RecorderButton from './RecorderButton';

import play from '../../../assets/recorder/play.svg';
import pause from '../../../assets/recorder/pause.svg';

const PlayPauseButton = ({ isPaused, onClick = () => null }) => {
  return (
    <RecorderButton
      onClick={onClick}
      src={isPaused ? play : pause}
      alt={isPaused ? 'resume' : 'pause'}
      tooltip={isPaused ? 'Resume Recording' : 'Pause Recording'}
      preventHide
    />
  );
};

export default PlayPauseButton;

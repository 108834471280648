import React, { useState, useEffect, useMemo } from 'react';
import { Grid, List, ListItem, Avatar } from '@material-ui/core';
import { imageBasePath, goalUnits } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { CustomTargetValue, ProgressBar, CustomDeltaValue } from '../../../shared_ui_components';
import { CustomGraph } from '../../../shared_elements';
import { getHubspotGoalProgressApi, getSalesGoalProgressApi } from '../apiServices';
import NumberFormat from 'react-number-format';
import { TooltipCustom, CustomNumberFormatter } from '../../../utils';
import Shimmer from './Shimmer';
import { HubspotSubtleIcon } from 'features/hubspot/_exports';
import { FeatureFlag } from 'controllers/_exports';
import { useTrackingStore } from '../tracking.store';
import { useGetHubspotTrackingQuery } from 'features/hubspot/Hubspot.queries';

export default function AdminSalesGoalsView(props) {
  const { overview = {}, userId = '', loader, isFromHubspot, hideHeaders, appliedFilters } = props;
  const { data: hubspotData } = useGetHubspotTrackingQuery({ ...appliedFilters, userId });

  const getOrderedGoals = useTrackingStore((state) => state?.getTrackingGoals);

  const goals = useMemo(() => {
    if (FeatureFlag.enabled('SWAP_SALES_GOALS')) return getOrderedGoals(overview?.goals);

    if (!overview?.goals?.length > 0) return [];
    return overview?.goals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderedGoals, overview?.goals, hubspotData]);

  return (
    <div className={styles.viewContentWrapper + ' ' + styles.saleViewWrapper}>
      {userId && !hideHeaders ? (
        <Grid container direction="row" className={`${styles.tableTitleRow} ${styles.noTransform}`}>
          <Grid item lg={4} md={4} sm={12} xs={12} className={styles.leftBlk}>
            <span className="supportText">Goal</span>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12} className={styles.rightBlk}>
            <Grid container direction="row" className={styles.innerContentRow}>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                <span className={styles.achivement + ' supportText'}>Achievement</span>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.middleBlk}>
                <span className="supportText">Progress</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        !hideHeaders && (
          <Grid
            container
            direction="row"
            className={`${styles.tableTitleRow} ${styles.noTransform}`}
          >
            <Grid item lg={3} md={3} sm={12} xs={12} className={styles.leftBlk}>
              <span className="supportText">Goal</span>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} className={styles.rightBlk}>
              <Grid container direction="row" className={styles.innerContentRow}>
                <Grid item lg={4} md={3} sm={12} xs={12} className={styles.leftBlk}>
                  <span className={styles.achivement + ' supportText'}>Achievement</span>
                </Grid>
                <Grid item lg={4} md={3} sm={12} xs={12} className={styles.middleBlk}>
                  <span className="supportText">Progress</span>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12} className={styles.rightBlk}>
                  <span className="supportText">Top performers</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      )}
      {loader ? (
        <Shimmer type="TrackingSalesOverview" />
      ) : (
        goals?.map((goal, index) => (
          <SalesGoalItem
            appliedFilters={props.appliedFilters}
            index={index}
            goal={goal}
            key={goal.uuid}
            userId={userId}
            isFromHubspot={isFromHubspot || goal?.isFromHubspot}
          />
        ))
      )}
    </div>
  );
}

function SalesGoalItem(props) {
  const { goal, index, userId = '', appliedFilters = {}, isFromHubspot } = props;

  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState('');

  const handleToggleView = () => {
    if (open) {
      setOpen(false);
    }
    // else if(chartData){
    //   setOpen(true)
    // }
    else {
      fetchGoalProgress(appliedFilters);
    }
  };

  const handleGoalData = (response) => {
    if (response.statusCode == 200) {
      let graphProgress = response.data;
      setOpen(true);
      if (graphProgress && graphProgress.progress) {
        setChartData(graphProgress.progress);
      } else {
        setChartData('');
      }
    }
  };

  const fetchGoalProgress = (query = {}) => {
    const body = userId ? { ...query, user: userId } : query;
    if (isFromHubspot)
      getHubspotGoalProgressApi(goal.uuid, body).then((response) => handleGoalData(response));
    else getSalesGoalProgressApi(goal.uuid, body).then((response) => handleGoalData(response));
  };

  //  useEffect(() => {
  //     if(index === 0 && !userId){
  //       fetchGoalProgress(appliedFilters)
  //     }
  //  }, [])

  useEffect(() => {
    if (open && appliedFilters.start_date) {
      fetchGoalProgress(appliedFilters);
    } else if (open) {
      fetchGoalProgress(appliedFilters);
    }
  }, [appliedFilters.start_date]);

  useEffect(() => {
    if (userId) {
      if (index != 0) {
        setChartData('');
        setOpen(false);
      } else {
        fetchGoalProgress(appliedFilters);
      }
    }
  }, [userId]);
  return (
    <div className={styles.salesCardItem}>
      <div className={styles.accordionHeader} onClick={handleToggleView}>
        <div className={styles.accordionTitleBlk}>
          {userId ? (
            <Grid container direction="row" alignItems="center" className={styles.contentRow}>
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className={styles.leftBlk + ' flexNotCentered'}
              >
                <div className={styles.titleBlk}>
                  <h3 className="heading5">
                    <img
                      src={`${imageBasePath}${
                        goalUnits?.find((item) => item.id == goal.unit)?.icon
                      }`}
                      alt="Leads"
                    />
                    <span>
                      {goal.name} {isFromHubspot && <HubspotSubtleIcon />}
                    </span>
                  </h3>
                </div>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                sm={12}
                xs={12}
                className={styles.rightBlk + ' flexNotCentered'}
              >
                <Grid container direction="row" className={styles.innerContentRow}>
                  <Grid item lg={6} md={3} sm={12} xs={12} className={styles.leftBlk}>
                    <List className={styles.targetList + ' listInline flexCentered'}>
                      {goal?.goal_data?.delta_value ? (
                        <ListItem className="listInlineItem">
                          <CustomDeltaValue value={goal?.goal_data?.delta_value} />
                        </ListItem>
                      ) : null}
                      <ListItem className="listInlineItem">
                        <span className="heading2">
                          {goal.unit === 2 && goal?.goal_data?.total_users_target ? (
                            <>
                              <span>$</span>
                              <NumberFormat
                                displayType="text"
                                format={CustomNumberFormatter}
                                value={goal?.goal_data?.total_users_target}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </>
                          ) : (
                            CustomNumberFormatter(goal?.goal_data?.total_users_target)
                          )}
                        </span>
                      </ListItem>
                      <ListItem className={styles.customTarget + ' listInlineItem'}>
                        <CustomTargetValue
                          unit={goal.unit}
                          value={goal?.goal_data?.expected_target}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} className={styles.middleBlk}>
                    <ProgressBar
                      max={100}
                      value={goal?.goal_data?.progress}
                      className={`${styles.salesProgressBar} ${
                        goal?.goal_data?.progress < 50 ? styles.danger : ''
                      }`}
                    />
                  </Grid>
                  <img
                    className={`${styles.caret} ${open ? styles.opened : ''}`}
                    src={imageBasePath + 'caret_down.svg'}
                    alt="image"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" alignItems="center" className={styles.contentRow}>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className={styles.leftBlk + ' flexNotCentered'}
              >
                <div className={styles.titleBlk}>
                  <h3 className="heading5">
                    <img
                      src={`${imageBasePath}${
                        goalUnits?.find((item) => item.id == goal.unit)?.icon
                      }`}
                      alt="Leads"
                    />
                    <span>
                      {goal.name} {isFromHubspot && <HubspotSubtleIcon />}
                    </span>
                  </h3>
                </div>
              </Grid>
              <Grid
                item
                lg={9}
                md={9}
                sm={12}
                xs={12}
                className={styles.rightBlk + ' flexNotCentered'}
              >
                <Grid container direction="row" className={styles.innerContentRow}>
                  <Grid item lg={4} md={3} sm={12} xs={12} className={styles.leftBlk}>
                    <List className={styles.targetList + ' listInline flexCentered'}>
                      {goal?.goal_data?.delta_value ? (
                        <ListItem className="listInlineItem">
                          <CustomDeltaValue value={goal?.goal_data?.delta_value} />
                        </ListItem>
                      ) : null}
                      <ListItem className="listInlineItem">
                        <span className="heading2">
                          {goal.unit === 2 && goal?.goal_data?.total_users_target ? (
                            <>
                              <span>$</span>
                              <NumberFormat
                                format={CustomNumberFormatter}
                                displayType="text"
                                value={goal?.goal_data?.total_users_target}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </>
                          ) : (
                            CustomNumberFormatter(goal?.goal_data?.total_users_target)
                          )}
                        </span>
                      </ListItem>
                      <ListItem className={styles.customTarget + ' listInlineItem'}>
                        <CustomTargetValue
                          unit={goal.unit}
                          value={goal?.goal_data?.expected_target}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item lg={4} md={3} sm={12} xs={12} className={styles.middleBlk}>
                    <ProgressBar
                      max={100}
                      value={goal?.goal_data?.progress}
                      className={`${styles.salesProgressBar} ${
                        goal?.goal_data?.progress < 50 ? styles.danger : ''
                      }`}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={12} xs={12} className={styles.rightBlk}>
                    <List className={styles.profileList + ' listInline flexCentered'}>
                      {goal?.goal_data?.top_users?.length
                        ? goal?.goal_data?.top_users?.map(
                            (user, index) =>
                              index <= 4 && (
                                <ListItem key={index} className="listInlineItem">
                                  <TooltipCustom
                                    placement="top"
                                    arrow
                                    title={<div className="">{user?.full_name}</div>}
                                  >
                                    {user.profile_pic ? (
                                      <img
                                        className={styles.profilePic}
                                        src={user.profile_pic}
                                        alt="Profile pic"
                                      />
                                    ) : (
                                      <Avatar
                                        className={styles.profilePic + ' avatar ' + styles.avatar}
                                      >
                                        {user.full_name ? user.full_name[0] : `S`}
                                      </Avatar>
                                    )}
                                  </TooltipCustom>
                                </ListItem>
                              )
                          )
                        : '--'}
                    </List>
                    <img
                      className={`${styles.caret} ${open ? styles.opened : ''}`}
                      src={imageBasePath + 'caret_down.svg'}
                      alt="image"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      {open ? (
        <div className={styles.accordionBody}>
          <div className={styles.bodyWrapper}>
            <Grid container direction="row" className={styles.tableTitleRow}>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}>
                <span className="supportTextSemibold">{goal.name} Trend</span>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={styles.leftBlk}></Grid>
            </Grid>
            <div className={styles.graphBlk}>
              <CustomGraph chartData={chartData ? chartData : {}} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

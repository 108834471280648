import React from 'react';
import { Avatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './RoleplayToPass.module.scss';
import RoleplayLabel from '../Common/RoleplayLabel';
import User from 'infrastructure/User';
import { useGetRoleplayToPassQuery } from 'features/roleplay/Roleplay.queries';
import { CustomButton } from 'shared_ui_components';

const RoleplayToPass = () => {
  const { data = [], isLoading } = useGetRoleplayToPassQuery();

  const history = useHistory();
  const onClick = (uuid) => history.push(`roleplay/${uuid}`);

  return (
    <div className={styles.card}>
      {data?.length === 0 && (
        <span>{isLoading ? 'Loading...' : "You don't have assigned roleplays"}</span>
      )}
      {data?.length > 0 &&
        data?.map((r) => (
          <div className={styles.roleplay}>
            <div className={styles.dataContainer}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>{r?.name}</span>
                <RoleplayLabel {...r?.status} />
              </div>
              <div className={styles.managerContainer}>
                <Avatar className={styles.img} alt="" src={r?.assigned_by_user?.profile_pic}>
                  {User.getInitial(r?.assigned_by_user)}
                </Avatar>
                <div>
                  <p className={styles.name}>{User.getUserName(r?.assigned_by_user)}</p>
                  <p>{r?.deadline}</p>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <CustomButton onClick={() => onClick(r?.uuid)} className="secondaryBtn">
                Continue
              </CustomButton>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RoleplayToPass;

import React, { useState, useEffect, useContext } from 'react';
import { InviteUserDialog } from '../../../shared_elements';
import { roleConfig } from './roleConfiguration';
import queryString from 'query-string';
import { CustomLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/users.module.scss';
import { GlobalContext } from '../../../context/GlobalContext';
import { useLocation } from 'react-router-dom';
import { allowInviteUsers } from '../../payment/Payment.utils';
import { Subscription } from 'controllers/_exports';

export default function InviteUser(props) {
  const [openInvitePop, setInvitePop] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  let location = useLocation();

  const querys = queryString.parse(location.search, { decode: false });
  useEffect(() => {
    if (querys.invite && querys.email) {
      setInvitePop(true);
    }
  }, [querys.invite, querys.email]);

  if (!allowInviteUsers()) return null;

  return (
    <>
      {roleConfig[state.role?.id] &&
        roleConfig[state.role?.id].canInviteUser &&
        Subscription.showInviteButton() && (
          <CustomLink
            className={styles.inviteBtn + ' primaryBtn '}
            linkimg="invite_user_white.svg"
            linktext="Invite Users"
            onClick={() =>
              Subscription.allow({ key: Subscription.keys.users }) && setInvitePop(true)
            }
          />
        )}
      <InviteUserDialog
        open={openInvitePop}
        handleClose={() => setInvitePop(false)}
        email={querys.email}
      />
    </>
  );
}

export function setDragElement({ dragElement, element, elementWidth, elementHeight, isWebcam }) {
  let pos1, pos2, pos3, pos4;

  (dragElement || element).onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();

    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    const newTop = element.offsetTop - pos2;
    const newLeft = element.offsetLeft - pos1;

    const screenWidth = document.getElementById('video-recording-drag-container').offsetWidth;
    const screenHeight = document.getElementById('video-recording-drag-container').offsetHeight;

    // set the element's new position:
    if (newTop + elementHeight <= screenHeight && newTop >= 0) element.style.top = newTop + 'px';
    if (newLeft + elementWidth <= screenWidth && newLeft >= 0) element.style.left = newLeft + 'px';
    else if(newLeft + elementWidth > screenWidth) element.style.left = `${screenWidth - elementWidth}px`;
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

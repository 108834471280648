import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { globalPostService } from '../../../utils/globalApiServices';
import { regExpression } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import styles from '../../../assets/styles/auth.module.scss';
import { imageBasePath } from '../../../constants';
import { InputText, CustomButton, CustomLink, ToastMessage } from '../../../shared_ui_components';
import { GlobalContext } from '../../../context/GlobalContext';
import { PasswordRecoverySuccess } from '../components';
import { forgotPasswordApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';

class PasswordRecovery extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
      },
      error: {},
      errorCode: {
        email: {
          0: '',
          1: 'Please enter email address',
          4: 'Invalid email address',
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
      },
      loader: false,
      emailSent: false,
    };
  }

  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: '',
      },
    });
  };

  handleSubmit = () => {
    const { formData, errorCode } = this.state;
    const validateNewInput = {
      email:
        errorCode['email'][fieldValidation({ ...errorCode['emailObj'], fieldval: formData.email })],
    };
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      this.setState({
        loader: true,
      });
      forgotPasswordApi({ formData: formData }).then((response) => {
        if (response.statusCode === 200) {
          toastFlashMessage(response.message, 'success');
          this.setState({
            emailSent: true,
          });
        } else {
          toastFlashMessage(response.message, 'error');
          this.setState({
            emailSent: false,
            loader: false,
          });
        }
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  render() {
    const { error, formData, loader, emailSent } = this.state;
    return (
      <div className={styles.signInPage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {emailSent ? (
                  <>
                    <PasswordRecoverySuccess />
                    <p className={styles.backBtn + ' paragraph textCenter hidden'}>
                      Back to
                      <CustomLink className="linkBtn" linktext="Sign in" to="/signin" />
                    </p>
                  </>
                ) : (
                  <div className="">
                    <div className={styles.cardWrapper + ' ' + styles.passwordRecoveryWrapper}>
                      <img
                        className={styles.logo}
                        src={imageBasePath + 'logo.svg'}
                        alt="salestable"
                      />
                      <img
                        className={styles.passwordRecovery}
                        src={imageBasePath + 'password_recovery.svg'}
                        alt="salestable"
                      />
                      <h1 className="heading3">Password recovery</h1>
                      <p className="paragraph">
                        Enter your registered email address below <br />
                        Your password reset link has been sent to the provided email address
                      </p>
                      <form
                        onSubmit={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.handleSubmit();
                        }}
                        className={styles.form}
                      >
                        <div className="inputGroup">
                          <InputText
                            id="email"
                            label="Email address "
                            placeholder="Enter your registered email address"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => this.handleChange('email', e.target.value.trim())}
                            value={formData.email}
                            error={error.email}
                            className={error.email ? 'errorGroup' : ' '}
                          />
                        </div>
                        <div className={styles.submitBlk + ' submitBlk'}>
                          <CustomButton
                            type="submit"
                            className={`primaryBtn submitBtn ${loader ? 'disabledBtn' : ''}`}
                            children={loader ? 'Submitting...' : 'Submit'}
                          />
                        </div>
                      </form>
                    </div>
                    <p className={styles.backBtn + ' paragraph textCenter'}>
                      Back to
                      <CustomLink className="linkBtn" linktext="Sign in" to="/signin" />
                    </p>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PasswordRecovery);

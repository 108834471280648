import {
  globalGetService,
  globalPostService,
  globalDeleteService,
} from '../../utils/globalApiServices';

export function inviteUsers(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/invite-users/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetchAllUsersApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/users/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updatedUserRoleApi(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/user/${uuid}/update-role/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function transferOwnerShip(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/ownership/${uuid}/change/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deactivateUserApi(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/user/${uuid}/deactivate/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function resendInvitationApi(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/user/${uuid}/resend-invite/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function reactivateUserApi(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/user/${uuid}/re-activate/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function removeInvitationApi(uuid, formData) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/user/${uuid}/remove-invite/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getInvitationLink(uuid) {
  return new Promise((resolve, reject) => {
    globalGetService(`/user/${uuid}/invite-link/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
import { useMemo } from 'react';
import Organization from 'infrastructure/Organization';

export const CONTENT_TYPES = {
  TRAINING_MODULES: 1,
};

export const PATH_STATUS = {
  DELETE: 0,
  DRAFT: 1,
  PUBLISH: 2,
};

export const REP_PATH_STATUS = {
  YET_TO_START: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
};

export const usePathValidations = ({ learningPath }) => {
  const user = useMemo(() => Organization.getUserInfo(), []);

  const canPerformActions = useMemo(
    () => user.uuid === learningPath?.created_by?.uuid,
    [learningPath, user?.uuid]
  );

  const isPathOwner = useMemo(
    () => user.uuid === learningPath?.created_by?.uuid,
    [learningPath, user?.uuid]
  );

  // Status
  const canDelete = useMemo(() => isPathOwner, [isPathOwner]);

  const canPublish = useMemo(() => {
    if (learningPath?.published_at !== null) return false;
    if (learningPath?.total_users < 1 || learningPath?.total_contents < 1) return false;
    return true;
  }, [learningPath]);

  return { canPerformActions, canDelete, canPublish, isPathOwner };
};

import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
} from '../../utils/globalApiServices';

export function getTrainingModulesListApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/modules/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getRecentlyAccessedModulesApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/recent-modules/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function submitTrainingFeedbackApi(formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/rating/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reorderTrainingApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/${uuid}/re-order/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getModuleDetailsApi(id) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/module/${id}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addNewCustomModuleApi(formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/create/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function updateModuleApi(id, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/${id}/update/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function deleteCustomModulepApi(id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/training/module/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function addNewContentApi(id, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/${id}/content/create/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function editContentApi(id, formData) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${id}/update/`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteContentpApi(id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/training/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function togggleModuleStatusApi(query) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/${query.uuid}/enable/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function publishModuleApi(id) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/module/${id}/publish/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createTrainingCommentApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/comment/create/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function updateTrainingCommentApi(uuid, commentId, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/comment/${commentId}/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function trainingCommentlikeDislikeApi(uuid, commentId, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/comment/${commentId}/like/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function traininglikeDislikeApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/like/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function trainingModuelReorderApi(data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/modules/re-order/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function deleteTrainingCommentApi(uuid, id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/training/${uuid}/comment/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getTrainingTeamProgressApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/dashboard/team-progress/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTrainingProgressRatingApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/dashboard/progress-rating/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTrainingUserReadinessApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/dashboard/user-readiness/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadReport() {
  return new Promise((resolve, reject) => {
    globalExportService(`/training/dashboard/download/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getModuleStatistics(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/training/dashboard/module-detail/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function markAsCompleteApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/mark-complete/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getUserPreferencesApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/category/details/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function markAsReadApi(uuid) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/read-content/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createAssesmentApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/assessment/create/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAssesmentQuestionssApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/assessment/questions/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function editAssesmentApi(id, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/assessments/${id}/update/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteAssessmentQuestionpApi(id) {
  return new Promise((resolve, reject) => {
    globalDeleteService(`/assessments/${id}/delete/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserAssesmentApi(uuid) {
  return new Promise((resolve, reject) => {
    globalGetService(`/assessment/user/${uuid}/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllAssessmentQuestions(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/assessment/questions/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fileUploadApi(data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/file/upload/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function submitAssessmentApi(data) {
  return new Promise((resolve, reject) => {
    globalPostService(`/assessment/submit/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function adminAssessmentFeedbackSubmitApi(uuid, data = {}) {
  return new Promise((resolve, reject) => {
    globalPostService(`/assessment/feedback/${uuid}/submit/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function nudgeUserTrainingApi(uuid) {
  return new Promise((resolve, reject) => {
    globalPostService(`/training/${uuid}/nudge-notification/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  getTrainingModulesListApi,
  togggleModuleStatusApi,
  deleteCustomModulepApi,
  getUserPreferencesApi,
  trainingModuelReorderApi,
} from '../apiServices';
import {
  TrainingDashboard,
  AddModule,
  TrainingSetupIntro,
  ModuleCardDnD,
  Shimmer,
  TrainingAssessmentView,
  AssesmentSetupPending,
  CreateAssessmentDialog,
} from './';
import { DashboardHeader, ConfirmationDialog } from '../../../shared_elements';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../../../assets/styles/training.module.scss';
import { toastFlashMessage } from '../../../utils';
import { imageBasePath } from '../../../constants';
import { createMuiTheme, Grid, MuiThemeProvider } from '@material-ui/core';
import { CustomButton, NoResult } from '../../../shared_ui_components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { FeatureFlag, ReadOnlyWrapper, Subscription } from 'controllers/_exports';
import { updateSubscriptionModules } from 'features/payment/Payment.utils';
import ModuleFilterSelector from './ModuleFilterSelector';
import LearningPathsView from './LearningPaths/Container/LearningPathsView';

export default function AdminTrainingContainer(props) {
  let history = useHistory();
  let location = useLocation();
  const [loader, setLoader] = useState({
    training: true,
    userPreferences: props.role == 'sales_lead' ? true : false,
  });
  const [isCompleted, setIsCompleted] = useState('');
  const [modules, setModules] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModule, setDeleteModule] = useState(false);
  const [addCustomModule, setAddCustomModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [activeModule, setActiveModule] = useState({});
  const [publishSusccess, setPublishSuccess] = useState(false);
  const [userPreferences, setUserPreferences] = useState({});
  const [startAsssesmentSetup, setStartAsssesmentSetup] = useState(false);
  const [assessmentAdded, setAssessmentAdded] = useState(false);

  const fetchTrainingModulesList = () => {
    getTrainingModulesListApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          training: false,
        }));
        const modules = response.data?.list;
        const isCompleted = !modules.some(
          (mod) => !mod.published_at && mod.status === 1 && !mod.is_custom
        );
        setIsCompleted(isCompleted);
        setModules(modules);
        setAssessmentAdded(response?.data?.assessment_added);
        if (!isCompleted) {
          history.push(`/training`);
        }
      }
    });
  };

  const handleUpdateModulesOrder = (data) => {
    if (data) {
      trainingModuelReorderApi({ modules_id: data }).then((response) => {
        if (response.statusCode === 200) {
          setModules(response.data);
        }
      });
    }
  };
  useEffect(() => {
    if (props.role == 'sales_lead') {
      getUserPreferencesApi().then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevLoader) => ({
            ...prevLoader,
            userPreferences: false,
          }));
          setUserPreferences(response.data);
        }
      });
    }
  }, []);
  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (Object.keys(querys).length && querys.active) {
      const active = querys.active;
      if (activeTab != active) {
        setActiveTab(active);
      }
    } else {
      setActiveTab('overview');
    }
  }, [location]);

  useEffect(() => {
    fetchTrainingModulesList();
    // setAssessmentAdded(assessmentAdded)
    // if(startAsssesmentSetup){
    //     setStartAsssesmentSetup(startAsssesmentSetup)
    // }
  }, []);

  const handleChageTab = (val) => {
    history.push(`/training?active=${val}`);
  };
  const handleEnabelDisable = (activeModule) => {
    setActiveModule(activeModule);
    if (
      activeModule.published_at ||
      (activeModule.status === 0 && activeModule.contents?.length === 0)
    ) {
      setToggleStatus(true);
    } else {
      handleToggleModuleStatus(activeModule);
    }
  };
  const handleAddCustomModule = () => {
    if (!Subscription.allow({ key: Subscription.keys.modules })) return;
    setAddCustomModule(true);
  };
  const handleEditModule = (activeModule) => {
    setActiveModule(activeModule);
    setEditModule(true);
  };
  const handlePublishSuccess = (module) => {
    setActiveModule(module);
    setPublishSuccess(true);
  };
  const handleDeleteCustomModule = () => {
    setDeleteLoader(true);
    deleteCustomModulepApi(activeModule.uuid).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        updateSubscriptionModules(-1);
        setActiveModule('');
        fetchTrainingModulesList();
        setDeleteModule(false);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleToggleModuleStatus = (activeModule) => {
    setDeleteLoader(true);
    FeatureFlag.enabled('ENABLE_MODULES') &&
    activeModule?.status === 0 &&
    activeModule.contents.length < 1
      ? history.push({
          pathname: `/training/${activeModule?.uuid}`,
          state: {
            prevPath: `${location.pathname}${location.search ? location.search : ''}`,
          },
        })
      : togggleModuleStatusApi({
          uuid: activeModule.uuid,
          enable: activeModule.status ? 0 : 1,
        }).then((response) => {
          setDeleteLoader(false);
          if (response.statusCode == 200) {
            setActiveModule('');
            fetchTrainingModulesList();
            setToggleStatus(false);
            toastFlashMessage(response.message, 'success');
          } else {
            toastFlashMessage(response.message, 'error');
          }
        });
  };

  const handleStartAsssesmentSetup = () => {
    setStartAsssesmentSetup(true);
  };

  const tabs = useMemo(() => {
    const _tabs = [
      {
        value: 'overview',
        title: 'Overview',
        isActive: activeTab == 'overview' ? true : false,
      },
      {
        value: 'modules',
        title: 'Modules',
        isActive: activeTab == 'modules' ? true : false,
      },
    ];

    if (FeatureFlag.enabled('LEARNING_PATHS'))
      _tabs.push({
        value: 'learningPaths',
        title: 'Learning Paths',
        isActive: activeTab === 'learningPaths' ? true : false,
      });
    if (assessmentAdded)
      _tabs.push({
        value: 'trainingassesment',
        title: 'Assessment',
        isActive: activeTab === 'trainingassesment' ? true : false,
      });
    return _tabs;
  }, [activeTab, assessmentAdded]);

  const Header = () => (
    <DashboardHeader
      dashboardLogo={imageBasePath + 'training_dashboard_icon.svg'}
      title="Training"
      modules={modules}
      tabs={tabs}
      handleChange={handleChageTab}
    />
  );

  return (
    <>
      {activeTab === 'learningPaths' ? (
        <div className={styles.dashboard}>
          <Header />
          <LearningPathsView />
        </div>
      ) : loader && (loader.training || loader.userPreferences) ? (
        <Shimmer type="ModuleCard" />
      ) : props.role == 'sales_lead' &&
        (userPreferences?.onboarding?.required
          ? !userPreferences?.onboarding?.completed
          : false) ? (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'training_dashboard_icon.svg'}
            title="Training"
          />
          <NoResult
            image={`training_no_state.svg`}
            title={`Training not available`}
            subtext={`Please finish onboarding before Training`}
          />
        </div>
      ) : isCompleted ? (
        <div className={styles.dashboard}>
          <Header />
          {!assessmentAdded && (
            <AssesmentSetupPending handleStartAsssesmentSetup={handleStartAsssesmentSetup} />
          )}
          {activeTab === 'overview' ? (
            <TrainingDashboard />
          ) : activeTab === 'trainingassesment' ? (
            <TrainingAssessmentView />
          ) : (
            <TrainingModulesList
              modules={modules}
              role={props.role}
              handleAddCustomModule={handleAddCustomModule}
              handleDeleteModule={(module) => {
                setActiveModule(module);
                setDeleteModule(true);
              }}
              handleEnabelDisable={handleEnabelDisable}
              handleEditModule={handleEditModule}
              handleUpdateModulesOrder={handleUpdateModulesOrder}
              fetchTrainingModulesList={fetchTrainingModulesList}
              handlePublishSuccess={handlePublishSuccess}
            />
          )}
        </div>
      ) : (
        <>
          <TrainingSetupIntro
            total={modules.filter((mod) => mod.is_predefined && mod.status == 1).length}
            completed={
              modules.filter((mod) => mod.is_predefined && mod.status == 1 && mod.published_at)
                .length
            }
            isAdmin={true}
            role={props.role}
          />
          <TrainingModulesList
            modules={modules}
            role={props.role}
            handleAddCustomModule={handleAddCustomModule}
            handleDeleteModule={(module) => {
              setActiveModule(module);
              setDeleteModule(true);
            }}
            handleEnabelDisable={handleEnabelDisable}
            handleEditModule={handleEditModule}
            handleUpdateModulesOrder={handleUpdateModulesOrder}
            fetchTrainingModulesList={fetchTrainingModulesList}
            handlePublishSuccess={handlePublishSuccess}
          />
        </>
      )}
      {editModule || addCustomModule ? (
        <AddModule
          open={true}
          handleClose={() => {
            setAddCustomModule(false);
            setEditModule(false);
            setActiveModule('');
          }}
          fetchMouleList={fetchTrainingModulesList}
          activeModule={activeModule}
          type={editModule ? 'edit' : 'add'}
        />
      ) : null}
      {deleteModule || toggleStatus || publishSusccess ? (
        <ConfirmationDialog
          open={true}
          title={
            deleteModule ? (
              <>
                Are you sure you want to delete the
                <br />
                <strong className="heading3">"{activeModule?.title}"</strong> module
              </>
            ) : toggleStatus ? (
              <>
                {!FeatureFlag.enabled('ENABLE_MODULES') ? (
                  <>
                    {' '}
                    Are you sure you want to {activeModule?.status ? `disable` : `enable`} the{' '}
                    <strong>“{activeModule?.title}”</strong> module ?
                  </>
                ) : activeModule.contents.length > 0 ? (
                  <>
                    {' '}
                    Are you sure you want to {activeModule?.status ? `disable` : `enable`} the{' '}
                    <strong>“{activeModule?.title}”</strong> module ?
                  </>
                ) : (
                  <>
                    You need to add content before enable <strong>“{activeModule?.title}”</strong>{' '}
                    module{' '}
                  </>
                )}
              </>
            ) : publishSusccess ? (
              <>
                <strong>“{activeModule.title}”</strong> module has been <br /> Published
              </>
            ) : (
              ''
            )
          }
          description={
            deleteModule ? (
              "Once it's deleted, this action can't be undone"
            ) : publishSusccess ? (
              'Any additional content which is added to this module will be autopublished unless disabled'
            ) : toggleStatus ? (
              <>
                {activeModule?.status
                  ? `Your team members no longer able to access it as you are disabling it`
                  : FeatureFlag.enabled('ENABLE_MODULES') && activeModule.contents.length < 1
                  ? 'This module doesn´t have any content'
                  : `Now your team members can access it as you are enabling it`}
              </>
            ) : (
              ''
            )
          }
          acceptText={
            deleteModule
              ? `Yes, Delete`
              : toggleStatus
              ? activeModule?.status
                ? `Disable`
                : FeatureFlag.enabled('ENABLE_MODULES') && activeModule.contents.length < 1
                ? 'Add content'
                : 'Enable'
              : publishSusccess
              ? `Setup Modules`
              : ``
          }
          rejectText={
            deleteModule
              ? `No, Cancel`
              : toggleStatus
              ? `Cancel`
              : publishSusccess
              ? `Add Content`
              : ``
          }
          isDelete={deleteModule ? true : false}
          image={
            deleteModule
              ? imageBasePath + 'delete_confirmation.svg'
              : toggleStatus
              ? imageBasePath + 'disable_module.svg'
              : publishSusccess
              ? imageBasePath + 'module_added_success.svg'
              : ''
          }
          handleClose={() => {
            setDeleteModule(false);
            setDeleteLoader(false);
            setToggleStatus(false);
            setActiveModule('');
            setPublishSuccess(false);
          }}
          loader={deleteLoader}
          handleSubmit={() => {
            if (deleteModule) {
              handleDeleteCustomModule();
            } else if (publishSusccess) {
              setPublishSuccess(false);
            } else {
              handleToggleModuleStatus(activeModule);
            }
          }}
          handleReject={
            publishSusccess ? () => history.push(`/training/${activeModule.uuid}`) : null
          }
        />
      ) : null}
      <CreateAssessmentDialog
        open={startAsssesmentSetup}
        handleClose={() => setStartAsssesmentSetup(false)}
        type={'create'}
        onSuccessHandler={() => {
          setStartAsssesmentSetup(false);
          setAssessmentAdded(true);
        }}
      />
    </>
  );
}

function TrainingModulesList(props) {
  const { modules = [], handleUpdateModulesOrder } = props;

  const [reorderedIds, setReorderedIds] = useState('');
  const [moduleList, setModuleList] = useState([]);

  useEffect(() => {
    setModuleList(modules);
  }, [modules]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = moduleList[dragIndex];
      const _reordered = update(
        update(moduleList, {
          $splice: [[dragIndex, 1, moduleList[hoverIndex]]],
        }),
        { $splice: [[hoverIndex, 1, moduleList[dragIndex]]] }
      );

      const _reordered_ids = _reordered.map((item) => item.uuid).join(',');

      setReorderedIds(_reordered_ids);

      setModuleList(_reordered);
    },
    [moduleList]
  );

  // Module Filtering
  const [filter, setFilter] = useState('active');
  useEffect(() => {
    let filteredModules = [];
    switch (filter) {
      case 'all':
        filteredModules = modules;
        break;
      case 'disabled':
        filteredModules = modules.filter((module) => module.status === 0);
        break;
      case 'active':
        filteredModules = modules.filter((module) => module.status === 1);
        break;
      case 'published':
        filteredModules = modules.filter((module) => module?.published_at && module?.status === 1);
        break;
      default:
        break;
    }
    setModuleList(filteredModules);
  }, [filter, modules]);

  const handleFilterChange = (e) => setFilter(e.value);

  return (
    <section className={styles.setupModuleSection}>
      <div className={styles.setupModuleWrapper}>
        <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h2 className="heading4">Setup Modules</h2>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="textRight">
            <ModuleFilterSelector handleFilterChange={handleFilterChange} filter={filter} />

            <CustomButton
              className={styles.addNewModuleBtn + ' primaryBtn smallBtn'}
              onClick={props.handleAddCustomModule}
            >
              + Add new module
            </CustomButton>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3} className={styles.contentRow}>
          <DndProvider backend={HTML5Backend}>
            {moduleList &&
              moduleList.length > 0 &&
              moduleList.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  style={{ position: 'relative' }}
                  key={item.uuid}
                >
                  <ReadOnlyWrapper data={item}>
                    <ModuleCardDnD
                      allowDrag={filter === 'all'}
                      module={item}
                      index={index}
                      reorderedIds={reorderedIds}
                      id={item.uuid}
                      handleUpdateModulesOrder={handleUpdateModulesOrder}
                      moveCard={moveCard}
                      {...props}
                    />
                  </ReadOnlyWrapper>
                  <span
                    className={styles.dragWrapper + ' dragWrapper hidden'}
                    style={{
                      position: 'absolute',
                      top: '60px',
                      right: '40px',
                      cursor: 'move',
                    }}
                  >
                    {/* <img src={imageBasePath+'drag_to_drop.svg'} alt="Drag" /> */}
                    <img src={imageBasePath + 'drag_to_do.svg'} alt="Drag" />
                  </span>
                </Grid>
              ))}
          </DndProvider>
        </Grid>
      </div>
    </section>
  );
}

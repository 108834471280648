import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RoleplayViewContent from './RoleplayViewContent';
import RoleplayViewDescription from './RoleplayViewDescription';
import { Grid } from '@material-ui/core';
import { useGetRoleplayView } from 'features/roleplay/Roleplay.queries';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { STATUS_ID } from 'features/roleplay/RoleplayConstants';

const RoleplayView = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const setRoleplayView = useRoleplayStore((state) => state?.setRoleplayView);
  const setScenarioSelected = useRoleplayStore((state) => state?.setScenarioSelected);
  const scenarioSelected = useRoleplayStore((state) => state?.scenarioSelected);

  // Retrieve roleplay data from backend
  const { uuid } = useParams();
  const { data, isLoading: isRoleplayViewLoading } = useGetRoleplayView({
    roleplay_id: uuid,
    enable: true,
  });

  // Save roleplay data in store
  useEffect(() => {
    if (!data?.scenarios?.length > 0) return;
    let _data = { ...data };

    if (data?.status?.id === STATUS_ID.RETAKEN) {
      _data.scenarios = data?.scenarios?.filter((sc) => !!sc?.response_retake);
    }

    setRoleplayView(_data);
    // Set first scenario as selected if theres no other selected before
    if (!Object.keys(scenarioSelected).length > 0) setScenarioSelected(_data.scenarios[0]);
  }, [data, setRoleplayView, setScenarioSelected, scenarioSelected]);

  useEffect(() => {
    // Clean roleplay and scenario data on exit view
    return function clean() {
      setRoleplayView({});
      setScenarioSelected({});
    };
  }, [setRoleplayView, setScenarioSelected]);

  if (isRoleplayViewLoading || !roleplayView?.scenarios?.length > 0) return null;
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ padding: '1rem 0 0 1rem', width: '100%' }}
      >
        <Grid item xs={12} md={8}>
          <RoleplayViewContent />
        </Grid>
        <Grid item xs={12} md={4}>
          <RoleplayViewDescription />
        </Grid>
      </Grid>
    </>
  );
};

export default RoleplayView;

import React from 'react';
import styles from './Common.module.scss';
import ReactPlayer from 'react-player';

const Video = ({ source = '' }) => {
  return (
      <ReactPlayer
        url={source}
        controls
        width="90%"
        height="60vh"
        style={{ minHeight: '60vh' }}
      />
  );
};

export default Video;

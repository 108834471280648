import { useQuery } from '@tanstack/react-query';
import {
  getHomeOnboardingCompletion,
  getHomeTrainingCompletion,
  getHomeRoleplayCompletion,
  getHomeRampTime,
} from './Home.services';
export const HOME_CACHE_KEYS = {
  ONBOARDING_COMPLETION: 'onboarding_completion',
  TRAINING_COMPLETION: 'training_completion',
  ROLEPLAY_COMPLETION: 'roleplay_completion',
  RAMP_TIME: 'ramp_time',
};

export const useGetHomeOnboardingCompletionQuery = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [HOME_CACHE_KEYS.ONBOARDING_COMPLETION, time],
    queryFn: () => getHomeOnboardingCompletion({ time }),
  });
};
export const useGetHomeTrainingCompletionQuery = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [HOME_CACHE_KEYS.TRAINING_COMPLETION, time],
    queryFn: () => getHomeTrainingCompletion({ time }),
  });
};
export const useGetHomeRoleplayCompletionQuery = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [HOME_CACHE_KEYS.ROLEPLAY_COMPLETION, time],
    queryFn: () => getHomeRoleplayCompletion({ time }),
  });
};
export const useGetHomeRampTimeQuery = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [HOME_CACHE_KEYS.RAMP_TIME, time],
    queryFn: () => getHomeRampTime({ time }),
  });
};

import { NoResult } from '../shared_ui_components';

export default function PageNotFound(props) {
  return (
    <div className="pageNotFound">
      <NoResult
        image="page_not_found.svg"
        title="Sorry page not found"
        subtext="We cannot find the page that  you are looking for."
        btnText="Back to home"
        link="/"
      />
    </div>
  );
}

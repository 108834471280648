import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CustomNumberFormatter } from '../utils';

const options = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      backgroundColor: `#EAF9F4`,
      borderRadius: 4,
      font: {
        weight: 'bold',
        size: 12.5,
      },
      formatter: function (val) {
        return CustomNumberFormatter(val);
      },
      padding: {
        top: 2,
        right: 4,
        bottom: 1,
        left: 4,
      },
      color: 'white',
      anchor: 'end',
      align: 'top',
      offset: 1,
      clip: false,
      clamp: true,
    },
  },
  animation: {
    duration: 0,
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        drawBorder: false,
      },
      ticks: {
        // stepSize: 1,
        sampleSize: 20,
        padding: 10,
        precision: 0,
      },
    },
    y: {
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        drawBorder: false,
      },
      ticks: {
        // stepSize: 1,
        sampleSize: 20,
        padding: 10,
        precision: 0,
        beginAtZero: true,
        min: 0,
        suggestedMin: 0,
      },
      beginAtZero: true,
    },
  },
};

export default function CustomGraph(props) {
  const { chartData = {} } = props;

  const fetchData = (type) => {
    let values = [];
    if (type === 'expected') {
      values = Object.keys(chartData).map((key) => chartData[key][0]);
    } else {
      values = Object.keys(chartData).map((key) => chartData[key][1]);
    }
    return values;
  };

  const fetchLabels = () => {
    return chartData && Object.keys(chartData).length ? Object.keys(chartData) : [];
  };
  // const data = {
  //     labels: fetchLabels(),
  //     datasets: [
  //       {
  //         label: 'Value',
  //         data: fetchData(`actual`),
  //         fill: true,
  //         spanGaps: true,
  //         backgroundColor: '#EAF9F4',
  //         borderColor: '#2DC38D',
  //         tension: 0.4,
  //         pointBackgroundColor: '#2DC38D',
  //         datalabels: {
  //             color: '#2DC38D'
  //         }
  //       }, {
  //         label: 'Expected',
  //         data: fetchData('expected'),
  //         fill: true,
  //         backgroundColor: `rgba(231, 240, 245, 0.4)`,
  //         borderColor: '#E7F0F5',
  //         tension: 0.4,
  //         pointBackgroundColor: '#E7F0F5',
  //         datalabels: {
  //             display: false
  //         }
  //       },
  //     ],
  // };
  const data = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradientActual = ctx.createLinearGradient(0, 0, 0, 200);
    gradientActual.addColorStop(0, 'rgba(45, 195, 141, 0.9)');
    gradientActual.addColorStop(0.5, 'rgba(45, 195, 141, 0.3)');
    gradientActual.addColorStop(1, 'rgba(45, 195, 141, 0)');
    const gradientExpected = ctx.createLinearGradient(0, 0, 0, 150);
    gradientExpected.addColorStop(0, 'rgba(231, 240, 245, 1)');
    gradientExpected.addColorStop(0.5, 'rgba(231, 240, 245, 0.3)');
    gradientExpected.addColorStop(1, 'rgba(231, 240, 245, 0)');

    return {
      labels: fetchLabels(),
      datasets: [
        {
          label: 'Value',
          data: fetchData(`actual`),
          fill: true,
          spanGaps: true,
          backgroundColor: gradientActual,
          borderColor: '#2DC38D',
          tension: 0.4,
          pointBackgroundColor: '#2DC38D',
          datalabels: {
            color: '#2DC38D',
          },
        },
        {
          label: 'Expected',
          data: fetchData('expected'),
          fill: true,
          backgroundColor: gradientExpected,
          borderColor: '#E7F0F5',
          tension: 0.4,
          pointBackgroundColor: '#E7F0F5',
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };
  return <Line data={data} options={options} height={200} plugins={[ChartDataLabels]} />;
}

import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/recruitment.module.scss';
import { CustomButton } from '../../../shared_ui_components';

export default function IntroductionCard(props) {
  const { jobProviders } = props;

  const showDashboardBtn = () => {
    const job = jobProviders.filter((job) => job.status === 1);
    if (job && job.length) {
      return true;
    } else {
      return false;
    }
  };

  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const showRecruitmentButton = () => selectedOrg?.recruiting_module_enabled;

  return (
    <section className={styles.setupTrainingIntroSection}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        {showRecruitmentButton() && (
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className={styles.titleWrapper}>
              <div className={styles.imgBlk}>
                <img src={imageBasePath + 'recruitment_featured.svg'} alt="Image" />
              </div>
              <div className={styles.contentBlk}>
                <h1 className="heading4">Setup Recruiting</h1>
                <p className="supportText">
                  View your sales team recruitment from sourcing to offer process. You have several
                  choices to integrate and track progress
                </p>
              </div>
            </div>
          </Grid>
        )}
        {showDashboardBtn() ? (
          <Grid item lg={5} md={5} sm={12} xs={12} className="textRight">
            <CustomButton
              className={styles.addContentBtn + ' primaryBtn'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.getJobs();
                props.getProviders();
              }}
            >
              View dashboard
            </CustomButton>
          </Grid>
        ) : null}
      </Grid>
      <img
        className={styles.patternBg}
        src={imageBasePath + 'onboarding_setup_pattern.svg'}
        alt="Image"
      />
    </section>
  );
}

import React, { useMemo, useState } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import Organization from 'infrastructure/Organization';
import { InputSelect } from 'shared_ui_components';
import { useGetListUsersQuery, useGetListUsersRepQuery } from 'features/roleplay/Roleplay.queries';
import User from 'infrastructure/User';
import { FeatureFlag } from 'controllers/_exports';
import styles from './AssignRoleplay.module.scss';

const RoleplaySelectAssign = () => {
  // Get users information
  const { data: usersList = [] } = useGetListUsersQuery();
  const { data: usersListRep = [] } = useGetListUsersRepQuery();

  const isRep = Organization.isRep();
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const userWithRoleplay = useRoleplayStore((state) => state?.userWithRoleplay);
  const setUserWithRoleplay = useRoleplayStore((state) => state?.setUserWithRoleplay);
  const scenariosList = useRoleplayStore((state) => state?.scenariosList);
  const [valueList, setValueList] = useState([]);

  const options = useMemo(() => {
    if (isRep) {
      if (!usersList?.length > 0) return [];
      return usersList.map((user) => {
        return {
          label: User.getUserName(user?.user),
          value: user.user.uuid,
        };
      });
    } else {
      if (!usersListRep?.length > 0) return [];
      return usersListRep.map((user) => {
        return {
          label: User.getUserName(user?.user),
          value: user.user.uuid,
          roleplay: user.role_plays,
        };
      });
    }
  }, [isRep, usersList, usersListRep]);

  useMemo(() => {
    setUserWithRoleplay(
      formDataAssignRoleplay?.users?.filter((user) => {
        return user?.roleplay?.some((role) => {
          const matchesRoleplay = role.uuid === formDataAssignRoleplay?.roleplay?.value;
          const selectedScenarios = scenariosList.filter((scenario) => scenario.isSelected);
          const matchDefaultScenario = role.scenarios[0].name === 'Default';
          const matchesScenario =
            role.scenarios.length === selectedScenarios.length &&
            role.scenarios?.every((scenario) =>
              selectedScenarios?.some((selectedScenario) => selectedScenario.uuid === scenario.uuid)
            );
          return matchesRoleplay && (matchesScenario || matchDefaultScenario);
        });
      })
    );
  }, [formDataAssignRoleplay, scenariosList, setUserWithRoleplay]);

  const handleChange = (values) => {
    delete formDataAssignRoleplay.users;
    let newValue = values;
    if (!isRep)
      if (values.some((item) => item.value === -1)) {
        newValue = usersListRep.map((user) => {
          return {
            label: User.getUserName(user?.user),
            value: user.user.uuid,
            roleplay: user.role_plays,
          };
        });
        setValueList(newValue);
        setFormDataAssignRoleplay({ users: newValue, ...formDataAssignRoleplay });
        return;
      }
    setValueList(newValue);
    setFormDataAssignRoleplay({ users: newValue, ...formDataAssignRoleplay });
  };

  return (
    <div className={styles.selectAssignWrapper}>
      {isRep ? (
        <InputSelect
          label="Manager"
          placeholder="Select Manager"
          onChange={(values) => handleChange(values)}
          options={options}
          closeMenuOnSelect={true}
          noOptionsMessage={() => 'No matches found'}
        />
      ) : (
        <InputSelect
          isMulti
          closeMenuOnSelect={true}
          options={[{ label: 'All Users', value: -1 }, ...options]}
          label="Sales Reps"
          placeholder="Select Sales Reps"
          onChange={(values) => handleChange(values)}
          noOptionsMessage={() => 'No matches found'}
          isClearable
          value={valueList}
          errorValues={
            FeatureFlag.enabled('ROLEPLAY_NEW_LOGIC_ASSIGN')
              ? userWithRoleplay?.map((u) => u?.value)
              : []
          }
        />
      )}
      {FeatureFlag.enabled('ROLEPLAY_NEW_LOGIC_ASSIGN') && userWithRoleplay?.length > 0 && (
        <div className={styles.usersWithRoleplayContainer}>
          <p className={styles.userWRText}>
            This roleplay will be assigned to {valueList.length - userWithRoleplay?.length} of{' '}
            {valueList.length} selected Sales Reps.
          </p>
        </div>
      )}
    </div>
  );
};

export default RoleplaySelectAssign;

import React, { useMemo, useState } from 'react';
import { imageBasePath } from 'constants';
import { FeatureFlag } from 'controllers/_exports';
import styles from './UnassignRoleplay.module.scss';
import { STATUS_ID } from 'features/roleplay/RoleplayConstants';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { ConfirmationDialog } from 'shared_elements';
import Organization from 'infrastructure/Organization';
import {
  useDeleteRoleplayMutation,
  useReassignRoleplayMutation,
  useUnassignRoleplayMutation,
} from 'features/roleplay/Roleplay.queries';

const UnassignRoleplay = ({ roleplay = {}, small = false }) => {
  // Handlers
  const { mutate: unassign, isLoading: l_unassign } = useUnassignRoleplayMutation();
  const { mutate: reassign, isLoading: l_reassign } = useReassignRoleplayMutation();
  const { mutate: delete_r, isLoading: l_delete } = useDeleteRoleplayMutation();

  const [modified, setModified] = useState(false); // To stop showing options immediately
  const onSuccess = () => {
    setModified(true);
    closeModal();
  };

  const roleplay_id = roleplay?.uuid;
  const handleUnassign = () => unassign({ roleplay_id }, { onSuccess });
  const handleDelete = () => delete_r({ roleplay_id }, { onSuccess });
  const handleReassign = () => reassign({ roleplay_id }, { onSuccess });

  // Validations
  const hasStatus = useMemo(
    () =>
      [
        STATUS_ID.INITIATED,
        STATUS_ID.IN_PROGRESS,
        STATUS_ID.LAST_DAY,
        STATUS_ID.ONE_DAY_LEFT,
        STATUS_ID.OUT_OF_TIME,
      ].includes(roleplay?.status?.id),
    [roleplay]
  );

  const canBeUnassigned = useMemo(() => {
    const archived = 'archive' in roleplay ? Boolean(roleplay.archive?.status) : false;
    const assigned = 'assigned' in roleplay ? Boolean(roleplay.assigned) : true;
    const self_assigned = Boolean(roleplay.self_assigned);
    setModified(false);
    if (Organization.isRep()) return assigned && hasStatus && self_assigned && !archived;
    return assigned && hasStatus && !archived;
  }, [roleplay, hasStatus]);

  const canBeDeleted = useMemo(() => {
    setModified(false);
    return canBeUnassigned;
  }, [canBeUnassigned]);

  const canBeReassigned = useMemo(() => {
    const archived = 'archive' in roleplay ? Boolean(roleplay.archive?.status) : false;
    const assigned = 'assigned' in roleplay ? Boolean(roleplay.assigned) : true;
    setModified(false);
    return !assigned && !archived;
  }, [roleplay]);

  // Menu and modal handlers
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const [unassignConfirmation, setUnassignConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const closeModal = () => {
    setUnassignConfirmation(false);
    setDeleteConfirmation(false);
  };
  const onModalConfirm = () => {
    if (!FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) return;
    if (unassignConfirmation) handleUnassign();
    else if (deleteConfirmation) handleDelete();
  };

  if (!FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) return null;
  if (!canBeUnassigned && !canBeReassigned) return small ? null : <div style={{ width: 16 }} />;
  if (modified) return <CircularProgress size={small ? 14 : 16} style={{ color: '#a5b3be' }} />;

  return (
    <div>
      <img
        alt="More"
        src={imageBasePath + 'more_vertical.svg	'}
        className={styles.icon}
        width={small ? 14 : 16}
        height={small ? 14 : 16}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className="moreMenu"
      >
        {canBeUnassigned && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setUnassignConfirmation(true);
            }}
          >
            <svg className={styles.unassign} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M1,10V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
            </svg>
            <span className="supportText">Unassign</span>
          </MenuItem>
        )}
        {canBeDeleted && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDeleteConfirmation(true);
            }}
          >
            <img src={imageBasePath + 'delete_red.svg'} alt="Delete" />
            <span className="supportText">Delete</span>
          </MenuItem>
        )}
        {canBeReassigned && !l_reassign && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleReassign();
            }}
          >
            <span className="supportText">Reassign</span>
          </MenuItem>
        )}
      </Menu>
      {(unassignConfirmation || deleteConfirmation) && (
        <ConfirmationDialog
          open
          title={
            <>
              <br />
              Are you sure you want to {unassignConfirmation ? 'unassign' : 'delete'}{' '}
              {roleplay?.name || 'this'} roleplay?
            </>
          }
          description={
            unassignConfirmation
              ? 'You can always re assign the roleplay'
              : 'This action cannot be undone'
          }
          acceptText={unassignConfirmation ? 'Unassign' : 'Delete'}
          rejectText="Cancel"
          isDelete={deleteConfirmation}
          handleClose={closeModal}
          handleSubmit={onModalConfirm}
          loader={l_delete || l_unassign}
        />
      )}
    </div>
  );
};

export default UnassignRoleplay;

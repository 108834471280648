import pageLayoutHoc from 'hocs/pageLayoutHoc';
import LearningPathDetails from './Components/LearningPathDetails/LearningPathDetails';

export const learningPathRoutes = [
  {
    path: '/learning-path/:id',
    key: 'learning-path-details',
    component: pageLayoutHoc(LearningPathDetails),
  },
];

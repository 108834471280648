import React, { useRef, useState } from 'react';
import styles from './NewSettings.module.scss';
import { CustomSwitch, InputText } from 'shared_ui_components';
import GoalDescription from 'features/tracking/components/GoalDescription';
import { useDrag, useDrop } from 'react-dnd';
import { imageBasePath } from 'constants';
import { GoalMenu } from '../SalesGoalsDialog';

const Goal = ({
  goal,
  index,
  onTargetChange = () => null,
  onStatusChange = () => null,
  onDescriptionChange = () => null,
  handleEdit = () => null,
  handleDelete = () => null,
  moveCard = () => null,
}) => {
  const handleTarget = (e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    onTargetChange(goal?.uuid, value);
  };

  const toggleStatus = (e) => onStatusChange(goal?.uuid, e.target.checked ? 1 : 0);
  const handleDescription = (e) => onDescriptionChange(goal?.uuid, e.target.value);

  // DND handler
  const name = goal?.name;

  const [, drag] = useDrag({
    type: 'card',
    item: () => {
      return { name, index };
    },
    collect: (monitor) => ({
      dragId: monitor.isDragging() ? name : '',
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        hoverId: monitor.isOver() ? name : '',
        handlerId: monitor.getHandlerId(),
        hovered: monitor.isOver(),
      };
    },
    drop() {},
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = useRef(null);
  drag(drop(ref));

  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-handler-id={handlerId}
      ref={ref}
      style={{ position: 'relative' }}
    >
      {hover && <img className="dragButton" alt=":::" src={imageBasePath + 'drag_to_do.svg'} />}
      {goal?.is_custom && <span className={styles.customLabel}>CUSTOM</span>}
      <GoalMenu goal={goal} handleDelete={handleDelete} handleEdit={handleEdit} />
      <div className={styles.goal} style={{ marginBottom: '20px' }}>
        <div className={styles.goalContent}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            {goal?.isFromHubspot ? (
              <div className={styles.hubspotIcon}>
                <img alt="" src={imageBasePath + 'hubspot_logo.svg'} />
              </div>
            ) : (
              <img
                width="36px"
                height="36px"
                alt=""
                src={`${imageBasePath}${goal?.unit === 1 ? 'hash_icon.svg' : 'revenue_icon.svg'}`}
              />
            )}

            <div>
              <h3>{goal?.name}</h3>
              <CustomSwitch
                checked={!!goal?.status}
                label={!!goal?.status ? 'Enabled' : 'Disabled'}
                onChange={toggleStatus}
              />
            </div>
          </div>
          <div>
            <label>
              Set a target for the goal
              <InputText placeholder="eg. 150" value={goal?.target} onChange={handleTarget} />
            </label>
          </div>
        </div>
        <GoalDescription
          value={goal?.description}
          custom={goal?.is_custom}
          onChange={handleDescription}
          isActivity
        />
      </div>
    </div>
  );
};

export default Goal;

import React, { useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';
import { useGetListRoleplaysQuery } from 'features/roleplay/Roleplay.queries';
import DateTime from 'infrastructure/Datetime';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';

const RoleplayDropdownList = () => {
  const { data: roleplaysList = [] } = useGetListRoleplaysQuery();
  const isRep = Organization.isRep();
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const setScenariosList = useRoleplayStore((state) => state?.setScenariosList);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);

  const options = useMemo(() => {
    if (!roleplaysList.length > 0) return [];
    const aux = roleplaysList.map((roleplay) => {
      return {
        label: roleplay?.name,
        value: roleplay?.uuid,
        description: roleplay?.description,
        scenarios: roleplay?.scenarios,
        rating_criteria: roleplay?.rating_criteria,
      };
    });
    if (!FeatureFlag.enabled('CUSTOM_ROLEPLAY') || isRep) return aux;
    else
      return [
        ...aux,
        {
          label: 'Create a Custom Roleplay',
          value: -1,
          description: '',
          scenarios: [],
          rating_criteria: [],
        },
      ];
  }, [isRep, roleplaysList]);

  const handleChange = (value) => {
    delete formDataAssignRoleplay.roleplay;
    setFormDataAssignRoleplay({ roleplay: value, ...formDataAssignRoleplay });
    setScenariosList(
      value.scenarios.map((scenario) => {
        return { ...scenario, isSelected: false };
      })
    );
    setFormDataCreateRoleplay({
      scenarios: [{ name: '', id: 1 }],
      rating_criterials: [{ name: '', id: 1 }],
      deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    });
  };

  return (
    <InputSelect
      label={`Roleplay`}
      placeholder="Select roleplay"
      onChange={(value) => handleChange(value)}
      options={options}
      value={formDataAssignRoleplay?.roleplay}
      closeMenuOnSelect={true}
      noOptionsMessage={() => 'No matches found'}
    />
  );
};

export default RoleplayDropdownList;

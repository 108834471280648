import React from 'react';
import styles from './AssignRoleplay.module.scss';
import { InputText } from 'shared_ui_components';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
const RoleplayTitle = () => {
  const formDataCreateRoleplay = useRoleplayStore((state) => state?.formDataCreateRoleplay);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);
  const handleChange = (value) => {
    setFormDataCreateRoleplay({ ...formDataCreateRoleplay, name: value });
  };
  return (
    <>
      <p className={styles.deadlineTitle}>Name</p>
      <InputText
        id="goalTarget"
        placeholder="Enter new roleplay’s name"
        value={formDataCreateRoleplay?.name}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};

export default RoleplayTitle;

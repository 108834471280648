import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { imageBasePath } from '../constants';
import { CustomButton } from '../shared_ui_components';
export default function ConfirmationDialog(props) {
  const {
    image,
    title,
    description,
    acceptText,
    rejectText,
    open,
    loader,
    withCloseIcon = true,
  } = props;
  // const [loader, setLoader] = React.useState(false);

  const handleClose = () => {
    props.handleClose();
    // props.handleAddVideo(false);
  };

  useEffect(() => {
    if (props.open) {
      // setLoader(false)
    }
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={props.classNames + ' confirmationDialog'}
      >
        <div className="textCenter dialogBody">
          {withCloseIcon && (
            <img
              src={imageBasePath + 'close_gray.svg'}
              alt="close icon"
              className="modalCloseIcon"
              onClick={handleClose}
            />
          )}
          {image && <img src={image} alt="confirmation" className="confirmationFig" />}
          <h4 className="heading4">{title}</h4>
          <p className="paragraph">{description}</p>
          <ul className="listInline">
            <li className="listInlineItem">
              <CustomButton
                className="secondaryBtn"
                onClick={props.handleReject ? props.handleReject : handleClose}
              >
                {rejectText ? rejectText : 'Cancel'}
              </CustomButton>
            </li>
            <li className="listInlineItem">
              <CustomButton
                onClick={props.handleSubmit}
                className={`primaryBtn ${props.isDelete ? 'deleteBtn' : ''} ${
                  loader ? 'disabled' : ''
                }`}
              >
                {acceptText ? acceptText : 'Submit'}
              </CustomButton>
            </li>
          </ul>
        </div>
      </Dialog>
    </div>
  );
}

import React from 'react';
import styles from './DottedBtn.module.scss';

const DottedBtn = ({ onClick, showPlusSign = true, label, smallHeight = false }) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.addButton} ${smallHeight ? styles.smallHeight : ''}`}
    >
      <span className={styles.label}>
        {showPlusSign && <span className={styles.plus}>+</span>}
        <span>{label}</span>
      </span>
    </div>
  );
};

export default DottedBtn;

import React, { useMemo, useEffect } from 'react';
import DescriptionRatingCriteria from './DescriptionRatingCriteria';
import DescriptionComment from './DescriptionComment';
import { CustomButton } from 'shared_ui_components';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useReviewScenario } from 'features/roleplay/Roleplay.queries';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { STATUS_ID } from '../../RoleplayConstants';
import styles from './ReviewRoleplay.module.scss';
const ReviewRoleplayDescriptionRating = () => {
  let location = useLocation();
  let history = useHistory();
  const { uuid } = useParams();

  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);
  const setResponseRetake = useRoleplayStore((state) => state?.setResponseRetake);
  const showSecondAttempt = useRoleplayStore((state) => state?.showSecondAttempt);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);
  const { mutate: reviewScenario } = useReviewScenario();
  const scenarioSelectedIndex = useMemo(
    () =>
      roleplayView?.scenarios.findIndex(
        (scenario) => scenario.uuid === reviewScenarioSelected?.uuid
      ),
    [roleplayView, reviewScenarioSelected]
  );

  if (!Object.keys(reviewScenarioSelected).length > 0)
    setReviewScenarioSelected({
      ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
      label: `${scenarioSelectedIndex + 2}. ${
        roleplayView?.scenarios[scenarioSelectedIndex + 1].name
      }`,
    });

  const navigate = () => {
    history.push({
      pathname: `/roleplay-review/${uuid}/submit`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };
  const buttonLabel = useMemo(() => {
    if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) return 'Continue';
    return 'Next';
  }, [roleplayView?.scenarios.length, scenarioSelectedIndex]);

  const handleFail = () => {
    const body = formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
      return {
        rating_criteria_uuid: criteria.rating_criteria_uuid,
        stars: criteria.stars,
      };
    });
    const extraData = !showRetake ? { retake: true } : { pass: false };
    const scenarioResponse = {
      rating_criterials_uuid: body,
      comment: formDataScenarioReview.comment,
      ...extraData,
    };
    reviewScenario(
      {
        roleplay: roleplayView.uuid,
        scenario: !showRetake ? reviewScenarioSelected.response.uuid : responseRetake.uuid,
        body: scenarioResponse,
      },
      {
        onSuccess: () => {
          formDataScenarioReview.rating_criterials_uuid.forEach(function (criteria) {
            criteria.stars = 0;
          });
          setResponseRetake({});
          hideSecondAttempt();
          setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
          if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1)
            setReviewScenarioSelected({
              ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
              label: `${scenarioSelectedIndex + 2}. ${
                roleplayView?.scenarios[scenarioSelectedIndex + 1].name
              }`,
            });
          if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) navigate();
        },
      }
    );
  };
  const handlePass = () => {
    const body = formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
      return {
        rating_criteria_uuid: criteria.rating_criteria_uuid,
        stars: criteria.stars,
      };
    });

    const scenarioResponse = {
      rating_criterials_uuid: body,
      comment: formDataScenarioReview.comment,
      pass: true,
    };
    reviewScenario(
      {
        roleplay: roleplayView.uuid,
        scenario: !showRetake ? reviewScenarioSelected.response.uuid : responseRetake.uuid,
        body: scenarioResponse,
      },
      {
        onSuccess: () => {
          formDataScenarioReview.rating_criterials_uuid.forEach(function (criteria) {
            criteria.stars = 0;
          });
          setResponseRetake({});
          hideSecondAttempt();
          setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
          if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1)
            setReviewScenarioSelected({
              ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
              label: `${scenarioSelectedIndex + 2}. ${
                roleplayView?.scenarios[scenarioSelectedIndex + 1].name
              }`,
            });
          if (roleplayView?.scenarios.length === scenarioSelectedIndex + 1) navigate();
        },
      }
    );
  };

  const handleNext = () => {
    hideSecondAttempt();
    setResponseRetake({});
    if (roleplayView?.scenarios.length !== scenarioSelectedIndex + 1) {
      setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
      setReviewScenarioSelected({
        ...roleplayView?.scenarios[scenarioSelectedIndex + 1],
        label: `${scenarioSelectedIndex + 2}. ${
          roleplayView?.scenarios[scenarioSelectedIndex + 1].name
        }`,
      });
    } else navigate();
  };

  const handlePrevious = () => {
    hideSecondAttempt();
    setResponseRetake({});
    try {
      const newScenario = roleplayView?.scenarios[scenarioSelectedIndex - 1];
      if (newScenario)
        setReviewScenarioSelected({
          ...newScenario,
          label: `${scenarioSelectedIndex}. ${newScenario.name}`,
        });
    } catch (error) {}
  };

  const disabled = useMemo(
    () =>
      formDataScenarioReview.comment === '' ||
      !formDataScenarioReview?.rating_criterials_uuid.every((item) => item.stars > 0)
        ? true
        : false,
    [formDataScenarioReview]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const seeNext = useMemo(() => {
    if (reviewScenarioSelected?.response?.rating_criterials.length > 0) return true;
    return false;
  }, [reviewScenarioSelected]);
  useEffect(() => {
    if (roleplayView?.status.id === STATUS_ID.RETAKEN) {
      const nextScenario = roleplayView?.scenarios.find(
        (sc) =>
          sc?.response?.rating_criterials?.length === 0 ||
          sc?.response_retake?.rating_criterials?.length === 0
      );
      if (!nextScenario) navigate();
      setReviewScenarioSelected({ ...nextScenario, label: `${nextScenario?.name}` });
    } else if (reviewScenarioSelected?.response?.rating_criterials?.length > 0) {
      const nextScenario = roleplayView?.scenarios.find(
        (sc) => sc?.response?.rating_criterials?.length === 0
      );
      if (!nextScenario) navigate();
      setReviewScenarioSelected({ ...nextScenario, label: `${nextScenario?.name}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);
  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);
  return (
    <div className={styles.descriptionRating}>
      <div>
        <DescriptionRatingCriteria />
        <DescriptionComment />
      </div>
      <div
        style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', flexWrap: 'wrap' }}
      >
        {scenarioSelectedIndex > 0 ? (
          <CustomButton className="secondaryBtn" onClick={handlePrevious}>
            Previous Scenario
          </CustomButton>
        ) : (
          <div> </div>
        )}
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {!seeNext || (showRetake && !isRetakeQualified) ? (
            <>
              <CustomButton className="secondaryBtn" onClick={handleFail} disabled={disabled}>
                {showRetake ? 'Fail' : 'Retake'}
              </CustomButton>
              <CustomButton onClick={handlePass} disabled={disabled}>
                Pass
              </CustomButton>
            </>
          ) : isRetakeQualified ||
            showRetake ||
            reviewScenarioSelected?.response_retake === null ? (
            <CustomButton onClick={handleNext}>{buttonLabel}</CustomButton>
          ) : (
            <CustomButton
              onClick={() => {
                setResponseRetake(reviewScenarioSelected?.response_retake);
                showSecondAttempt();
              }}
            >
              Check 2nd Attempt
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewRoleplayDescriptionRating;

import React from 'react';
import styles from './LearningPaths.module.scss';
import { CustomButton } from 'shared_ui_components';
import CreateLearningPath from '../Components/CreateLearningPath/CreateLearningPath';
import { useLearningPathsStore } from '../LearningPaths.store';
import SeeLearningPaths from '../Components/SeeLearningPaths/SeeLearningPaths';

const LearningPathsView = () => {
  const openModal = useLearningPathsStore((state) => state?.openCreateModal);

  return (
    <>
      <CreateLearningPath />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            <div className={styles.headerTitle}>Learning paths</div>
            <div className={styles.headerDescription}>
              Enhance the sales training experience by providing personalized learning paths
              tailored to each sales rep needs.
            </div>
          </div>
          <div>
            <CustomButton onClick={openModal}>+ Add New Learning path</CustomButton>
          </div>
        </div>
        <SeeLearningPaths />
      </div>
    </>
  );
};

export default LearningPathsView;

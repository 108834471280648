import React, { useMemo, useState } from 'react';
// import { useIsFetching } from '@tanstack/react-query';

import FilterPaginationControllerLogic from './FilterPaginationController.logic';
import CategoryFilter from '../CategoryFilter/CategoryFilter';
import Shimmer from '../../components/Shimmer';
import LoadMorePosts from './LoadMorePosts';
import UserFilter from '../UserFilter/UserFilter';
import './FilterPagination.scss';
import FeaturedPostsList from '../../PostFeed/FeaturedPostsList/FeaturedPostsList';
import LatestPostList from '../../PostFeed/LatestPostsList/LatestPostList';
// import { streamCacheKeys } from 'features/stream/Stream.queries';
import { useStreamStore } from 'features/stream/Stream.store';
import { FeatureFlag } from 'controllers/_exports';

const FilterPaginationController = () => {
  // User filter
  const [user, setUser] = useState('all');
  const onUserChange = (userId) => setUser(userId);

  // Category filter
  const [category, setCategory] = useState(null);
  const onCategoryChange = ({ value }) => setCategory(value);

  const { isLoading, nextPage, showLoader, isFetching } = FilterPaginationControllerLogic({
    user,
    category,
  });

  // Tab control
  const [selectedTab, setSelectedTab] = useState('latest');

  //New Post loader
  const loadingNewPost = useStreamStore((state) => state?.loadingNewPost);

  const showNewLoader = useMemo(() => {
    if (!loadingNewPost) return false;
    return FeatureFlag.enabled('PULSE_SHIMMER') && isFetching;
  }, [loadingNewPost, isFetching]);

  return (
    <div className="stream-filter-container">
      <div className="section-selector">
        <span
          className={selectedTab === 'featured' ? 'selected' : ''}
          onClick={() => setSelectedTab('featured')}
        >
          Pinned posts
        </span>
        <span
          className={selectedTab === 'latest' ? 'selected' : ''}
          onClick={() => setSelectedTab('latest')}
        >
          Latest posts
        </span>
        {selectedTab === 'latest' && (
          <div className="stream-filters">
            <CategoryFilter onChange={onCategoryChange} />
            <UserFilter onChange={onUserChange} />
          </div>
        )}
      </div>

      {selectedTab === 'latest' && (
        <div>
          {showNewLoader && <Shimmer type="LoadingNewPost" />}
          {isLoading && <Shimmer type="StreamOverview" />}
          {!isLoading && <LatestPostList />}
          <LoadMorePosts showLoader={showLoader} loadMore={nextPage} />
        </div>
      )}
      {selectedTab === 'featured' && <FeaturedPostsList />}
    </div>
  );
};

export default FilterPaginationController;

import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styles from './RoleplayDashboardList.module.scss';

const RoleplaysListShimmer = () => {
  return (
    <div style={{ margin: '2rem 0' }}>
      <Skeleton variant="text" width="100px" height="22px" />
      <Grid container spacing={2} style={{ marginTop: '1.5rem' }}>
        {[1, 2, 3].map((i) => (
          <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className={styles.card}>
              <Skeleton variant="circle" width={32} height={32} />
              <Skeleton variant="text" width="60%" style={{ marginTop: '1rem' }} />
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="text" width="100%" height="50px" />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RoleplaysListShimmer;

import React from 'react';
import styles from './Messages.module.scss';
const Messages = ({ conversation = [], isLoading = false }) => {
  return (
    <div className={styles.contentChat}>
      <div className={styles.scrollbar}>
        {conversation.map((message, key) => {
          if (message.from === 1) return <span key={key} className={styles.own}>{message?.message}</span>;
          else return <span key={key} className={styles.chat}>{message?.message}</span>;
        })}
        {isLoading && <span className={styles.loading}>Loading...</span>}
      </div>

    </div>
  );
};

export default Messages;

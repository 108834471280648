import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CustomDoughnutChart from './CustomDoughnutChart';
import { TooltipCustom, TooltipCustomDash } from '../utils';
import User from 'infrastructure/User';
import { FeatureFlag } from 'controllers/_exports';

export default function OverviewHero(props) {
  const {
    progressStatus,
    chartData,
    overallRating,
    page = 'onboarding',
    chartDataLearningPaths = {},
  } = props;

  const tooltipProps = { ...props };
  delete tooltipProps.chartData;
  delete tooltipProps.progressStatus;
  delete tooltipProps.overallRating;
  delete tooltipProps.chartDataLearningPaths;

  const fetchRatingPercentage = (rating) => {
    let total =
      0 +
      overallRating.ratingInfo.rating1 +
      overallRating.ratingInfo.rating2 +
      overallRating.ratingInfo.rating3 +
      overallRating.ratingInfo.rating4 +
      overallRating.ratingInfo.rating5;
    return (rating / total) * 100;
  };

  const separators = [[''], [''], [' and'], [',', ' and'], [',', ',', '']];
  return (
    <section className="overviewProgressSection">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={6} className="flexNotCentered">
          <div className="progressChart">
            <h3 className="heading5">{chartData.title}</h3>
            <CustomDoughnutChart chartData={chartData} page={page} />
          </div>
        </Grid>
        {FeatureFlag.enabled('LEARNING_PATHS') &&
          Object.keys(chartDataLearningPaths).length > 0 && (
            <Grid item xs={12} md={6} className="flexNotCentered">
              <div className="progressChart">
                <h3 className="heading5">{chartDataLearningPaths.title}</h3>
                <CustomDoughnutChart chartData={chartDataLearningPaths} page={page} />
              </div>
            </Grid>
          )}
        {FeatureFlag.enabled('LEARNING_PATHS') && Object.keys(chartDataLearningPaths).length > 0 ? (
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="overallRatings" style={{ minHeight: '130px', marginLeft: '10px' }}>
                <h3 className="heading5">{overallRating.title}</h3>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <h6 className="heading1">{overallRating?.ratingInfo?.averageRating}</h6>
                    <Rating
                      name="read-only"
                      precision={0.1}
                      value={
                        props.overallRating?.ratingInfo?.averageRating
                          ? parseInt(props.overallRating?.ratingInfo?.averageRating)
                          : 0
                      }
                      readOnly
                    />
                    <p className="paragraph">
                      {overallRating?.ratingInfo?.totalReviews
                        ? `based on ${overallRating?.ratingInfo?.totalReviews} reviews`
                        : 'No reviews yet'}
                    </p>
                  </Grid>
                  {overallRating?.ratingInfo?.averageRating ? (
                    <Grid item xs={12} md={6} className="ratingList">
                      <ul className="listUnstyled">
                        <TooltipCustomDash
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                                {overallRating?.ratingInfo?.rating5Users.map((user) => {
                                  return (
                                    <Avatar
                                      style={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        border: '0.20rem solid #FFF',
                                        marginLeft: '-0.5rem',
                                      }}
                                      alt=""
                                      src={user.user?.profile_pic}
                                    >
                                      {User.getInitial(user.user)}
                                    </Avatar>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating5UsersLeft > 0 && (
                                  <p
                                    style={{
                                      fontSize: '1.25rem',
                                      marginLeft: '0.5rem',
                                      fontWeight: '300',
                                      color: '#FFFFFFCC',
                                    }}
                                  >
                                    {' '}
                                    +{overallRating?.ratingInfo?.rating5UsersLeft}
                                  </p>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '0.5rem',
                                }}
                              >
                                {overallRating?.ratingInfo?.rating5Users.map((user, index) => {
                                  return (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        marginRight: '0.2rem',
                                        fontWeight: '500',
                                        color: '#FFFFFF',
                                        textWrap: 'noWrap',
                                      }}
                                    >
                                      {user.user.first_name !== ''
                                        ? user.user.first_name
                                        : user.user.email.split('@')[0]}
                                      {overallRating?.ratingInfo?.rating5UsersLeft !== 0
                                        ? separators[4][index]
                                        : separators[
                                            overallRating?.ratingInfo?.rating5Users.length
                                          ][index]}
                                    </p>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating5UsersLeft !== 0 && (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    and others
                                  </p>
                                )}
                              </div>
                            </div>
                          }
                          {...tooltipProps}
                          placement="top"
                          arrow
                        >
                          <li
                            className={`five`}
                            style={{
                              width: `${fetchRatingPercentage(
                                overallRating?.ratingInfo?.rating5
                              )}%`,
                            }}
                          >
                            <span className="paragraph">5</span>
                          </li>
                        </TooltipCustomDash>
                        <TooltipCustomDash
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                                {overallRating?.ratingInfo?.rating4Users.map((user) => {
                                  return (
                                    <Avatar
                                      style={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        border: '0.20rem solid #FFF',
                                        marginLeft: '-0.5rem',
                                      }}
                                      alt=""
                                      src={user.user?.profile_pic}
                                    >
                                      {User.getInitial(user.user)}
                                    </Avatar>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating4UsersLeft > 0 && (
                                  <p
                                    style={{
                                      fontSize: '1.25rem',
                                      marginLeft: '0.5rem',
                                      fontWeight: '300',
                                      color: '#FFFFFFCC',
                                    }}
                                  >
                                    {' '}
                                    +{overallRating?.ratingInfo?.rating4UsersLeft}
                                  </p>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '0.5rem',
                                }}
                              >
                                {overallRating?.ratingInfo?.rating4Users.map((user, index) => {
                                  return (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        marginRight: '0.2rem',
                                        fontWeight: '500',
                                        color: '#FFFFFF',
                                        textWrap: 'noWrap',
                                      }}
                                    >
                                      {user.user.first_name !== ''
                                        ? user.user.first_name
                                        : user.user.email.split('@')[0]}
                                      {overallRating?.ratingInfo?.rating4UsersLeft !== 0
                                        ? separators[4][index]
                                        : separators[
                                            overallRating?.ratingInfo?.rating4Users.length
                                          ][index]}
                                    </p>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating4UsersLeft !== 0 && (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    and others
                                  </p>
                                )}
                              </div>
                            </div>
                          }
                          {...tooltipProps}
                          placement="top"
                          arrow
                        >
                          <li
                            className={`four`}
                            style={{
                              width: `${fetchRatingPercentage(
                                overallRating?.ratingInfo?.rating4
                              )}%`,
                            }}
                          >
                            <span className="paragraph">4</span>
                          </li>
                        </TooltipCustomDash>
                        <TooltipCustomDash
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                                {overallRating?.ratingInfo?.rating3Users.map((user) => {
                                  return (
                                    <Avatar
                                      style={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        border: '0.20rem solid #FFF',
                                        marginLeft: '-0.5rem',
                                      }}
                                      alt=""
                                      src={user.user?.profile_pic}
                                    >
                                      {User.getInitial(user.user)}
                                    </Avatar>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating3UsersLeft > 0 && (
                                  <p
                                    style={{
                                      fontSize: '1.25rem',
                                      marginLeft: '0.5rem',
                                      fontWeight: '300',
                                      color: '#FFFFFFCC',
                                    }}
                                  >
                                    {' '}
                                    +{overallRating?.ratingInfo?.rating3UsersLeft}
                                  </p>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '0.5rem',
                                }}
                              >
                                {overallRating?.ratingInfo?.rating3Users.map((user, index) => {
                                  return (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        marginRight: '0.2rem',
                                        fontWeight: '500',
                                        color: '#FFFFFF',
                                        textWrap: 'noWrap',
                                      }}
                                    >
                                      {user.user.first_name !== ''
                                        ? user.user.first_name
                                        : user.user.email.split('@')[0]}
                                      {overallRating?.ratingInfo?.rating3UsersLeft !== 0
                                        ? separators[4][index]
                                        : separators[
                                            overallRating?.ratingInfo?.rating3Users.length
                                          ][index]}
                                    </p>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating3UsersLeft !== 0 && (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    and others
                                  </p>
                                )}
                              </div>
                            </div>
                          }
                          {...tooltipProps}
                          placement="top"
                          arrow
                        >
                          <li
                            className={`three`}
                            style={{
                              width: `${fetchRatingPercentage(
                                overallRating?.ratingInfo?.rating3
                              )}%`,
                            }}
                          >
                            <span className="paragraph">3</span>
                          </li>
                        </TooltipCustomDash>
                        <TooltipCustomDash
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                                {overallRating?.ratingInfo?.rating2Users.map((user) => {
                                  return (
                                    <Avatar
                                      style={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        border: '0.20rem solid #FFF',
                                        marginLeft: '-0.5rem',
                                      }}
                                      alt=""
                                      src={user.user?.profile_pic}
                                    >
                                      {User.getInitial(user.user)}
                                    </Avatar>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating2UsersLeft > 0 && (
                                  <p
                                    style={{
                                      fontSize: '1.25rem',
                                      marginLeft: '0.5rem',
                                      fontWeight: '300',
                                      color: '#FFFFFFCC',
                                    }}
                                  >
                                    {' '}
                                    +{overallRating?.ratingInfo?.rating2UsersLeft}
                                  </p>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '0.5rem',
                                }}
                              >
                                {overallRating?.ratingInfo?.rating2Users.map((user, index) => {
                                  return (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        marginRight: '0.2rem',
                                        fontWeight: '500',
                                        color: '#FFFFFF',
                                        textWrap: 'noWrap',
                                      }}
                                    >
                                      {user.user.first_name !== ''
                                        ? user.user.first_name
                                        : user.user.email.split('@')[0]}
                                      {overallRating?.ratingInfo?.rating2UsersLeft !== 0
                                        ? separators[4][index]
                                        : separators[
                                            overallRating?.ratingInfo?.rating2Users.length
                                          ][index]}
                                    </p>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating2UsersLeft !== 0 && (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    and others
                                  </p>
                                )}
                              </div>
                            </div>
                          }
                          {...tooltipProps}
                          placement="top"
                          arrow
                        >
                          <li
                            className={`two`}
                            style={{
                              width: `${fetchRatingPercentage(
                                overallRating?.ratingInfo?.rating2
                              )}%`,
                            }}
                          >
                            <span className="paragraph">2</span>
                          </li>
                        </TooltipCustomDash>
                        <TooltipCustomDash
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                                {overallRating?.ratingInfo?.rating1Users.map((user) => {
                                  return (
                                    <Avatar
                                      style={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        border: '0.20rem solid #FFF',
                                        marginLeft: '-0.5rem',
                                      }}
                                      alt=""
                                      src={user.user?.profile_pic}
                                    >
                                      {User.getInitial(user.user)}
                                    </Avatar>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating1UsersLeft > 0 && (
                                  <p
                                    style={{
                                      fontSize: '1.25rem',
                                      marginLeft: '0.5rem',
                                      fontWeight: '300',
                                      color: '#FFFFFFCC',
                                    }}
                                  >
                                    {' '}
                                    +{overallRating?.ratingInfo?.rating1UsersLeft}
                                  </p>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '0.5rem',
                                }}
                              >
                                {overallRating?.ratingInfo?.rating1Users.map((user, index) => {
                                  return (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        marginRight: '0.2rem',
                                        fontWeight: '500',
                                        color: '#FFFFFF',
                                        textWrap: 'noWrap',
                                      }}
                                    >
                                      {user.user.first_name !== ''
                                        ? user.user.first_name
                                        : user.user.email.split('@')[0]}
                                      {overallRating?.ratingInfo?.rating1UsersLeft !== 0
                                        ? separators[4][index]
                                        : separators[
                                            overallRating?.ratingInfo?.rating1Users.length
                                          ][index]}
                                    </p>
                                  );
                                })}
                                {overallRating?.ratingInfo?.rating1UsersLeft !== 0 && (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    and others
                                  </p>
                                )}
                              </div>
                            </div>
                          }
                          {...tooltipProps}
                          placement="top"
                          arrow
                        >
                          <li
                            className={`one`}
                            style={{
                              width: `${fetchRatingPercentage(
                                overallRating?.ratingInfo?.rating1
                              )}%`,
                            }}
                          >
                            <span className="paragraph">1</span>
                          </li>
                        </TooltipCustomDash>
                      </ul>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </Grid>
            {progressStatus && (
              <Grid item xs={12} md={6}>
                <div
                  className="completionTime"
                  style={{ minHeight: '130px', marginRight: '10px', alignSelf: 'center' }}
                >
                  <img src={progressStatus.image} alt="progress status" />
                  <h3 className="heading3">{progressStatus.title ? progressStatus.title : '--'}</h3>
                  <p className="paragraph">{progressStatus.description}</p>
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            {progressStatus ? (
              <div className="completionTime">
                <img src={progressStatus.image} alt="progress status" />
                <h3 className="heading3">{progressStatus.title ? progressStatus.title : '--'}</h3>
                <p className="paragraph">{progressStatus.description}</p>
              </div>
            ) : null}
            <div className="overallRatings">
              <h3 className="heading5">{overallRating.title}</h3>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <h6 className="heading1">{overallRating?.ratingInfo?.averageRating}</h6>
                  <Rating
                    name="read-only"
                    precision={0.1}
                    value={
                      props.overallRating?.ratingInfo?.averageRating
                        ? parseInt(props.overallRating?.ratingInfo?.averageRating)
                        : 0
                    }
                    readOnly
                  />
                  <p className="paragraph">
                    {overallRating?.ratingInfo?.totalReviews
                      ? `based on ${overallRating?.ratingInfo?.totalReviews} reviews`
                      : 'No reviews yet'}
                  </p>
                </Grid>
                {overallRating?.ratingInfo?.averageRating ? (
                  <Grid item xs={12} md={6} className="ratingList">
                    <ul className="listUnstyled">
                      <TooltipCustomDash
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                              {overallRating?.ratingInfo?.rating5Users.map((user) => {
                                return (
                                  <Avatar
                                    style={{
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '0.20rem solid #FFF',
                                      marginLeft: '-0.5rem',
                                    }}
                                    alt=""
                                    src={user.user?.profile_pic}
                                  >
                                    {User.getInitial(user.user)}
                                  </Avatar>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating5UsersLeft > 0 && (
                                <p
                                  style={{
                                    fontSize: '1.25rem',
                                    marginLeft: '0.5rem',
                                    fontWeight: '300',
                                    color: '#FFFFFFCC',
                                  }}
                                >
                                  {' '}
                                  +{overallRating?.ratingInfo?.rating5UsersLeft}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '0.5rem',
                              }}
                            >
                              {overallRating?.ratingInfo?.rating5Users.map((user, index) => {
                                return (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      marginRight: '0.2rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    {user.user.first_name !== ''
                                      ? user.user.first_name
                                      : user.user.email.split('@')[0]}
                                    {overallRating?.ratingInfo?.rating5UsersLeft !== 0
                                      ? separators[4][index]
                                      : separators[overallRating?.ratingInfo?.rating5Users.length][
                                          index
                                        ]}
                                  </p>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating5UsersLeft !== 0 && (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: '500',
                                    color: '#FFFFFF',
                                    textWrap: 'noWrap',
                                  }}
                                >
                                  and others
                                </p>
                              )}
                            </div>
                          </div>
                        }
                        {...tooltipProps}
                        placement="top"
                        arrow
                      >
                        <li
                          className={`five`}
                          style={{
                            width: `${fetchRatingPercentage(overallRating?.ratingInfo?.rating5)}%`,
                          }}
                        >
                          <span className="paragraph">5</span>
                        </li>
                      </TooltipCustomDash>
                      <TooltipCustomDash
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                              {overallRating?.ratingInfo?.rating4Users.map((user) => {
                                return (
                                  <Avatar
                                    style={{
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '0.20rem solid #FFF',
                                      marginLeft: '-0.5rem',
                                    }}
                                    alt=""
                                    src={user.user?.profile_pic}
                                  >
                                    {User.getInitial(user.user)}
                                  </Avatar>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating4UsersLeft > 0 && (
                                <p
                                  style={{
                                    fontSize: '1.25rem',
                                    marginLeft: '0.5rem',
                                    fontWeight: '300',
                                    color: '#FFFFFFCC',
                                  }}
                                >
                                  {' '}
                                  +{overallRating?.ratingInfo?.rating4UsersLeft}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '0.5rem',
                              }}
                            >
                              {overallRating?.ratingInfo?.rating4Users.map((user, index) => {
                                return (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      marginRight: '0.2rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    {user.user.first_name !== ''
                                      ? user.user.first_name
                                      : user.user.email.split('@')[0]}
                                    {overallRating?.ratingInfo?.rating4UsersLeft !== 0
                                      ? separators[4][index]
                                      : separators[overallRating?.ratingInfo?.rating4Users.length][
                                          index
                                        ]}
                                  </p>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating4UsersLeft !== 0 && (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: '500',
                                    color: '#FFFFFF',
                                    textWrap: 'noWrap',
                                  }}
                                >
                                  and others
                                </p>
                              )}
                            </div>
                          </div>
                        }
                        {...tooltipProps}
                        placement="top"
                        arrow
                      >
                        <li
                          className={`four`}
                          style={{
                            width: `${fetchRatingPercentage(overallRating?.ratingInfo?.rating4)}%`,
                          }}
                        >
                          <span className="paragraph">4</span>
                        </li>
                      </TooltipCustomDash>
                      <TooltipCustomDash
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                              {overallRating?.ratingInfo?.rating3Users.map((user) => {
                                return (
                                  <Avatar
                                    style={{
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '0.20rem solid #FFF',
                                      marginLeft: '-0.5rem',
                                    }}
                                    alt=""
                                    src={user.user?.profile_pic}
                                  >
                                    {User.getInitial(user.user)}
                                  </Avatar>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating3UsersLeft > 0 && (
                                <p
                                  style={{
                                    fontSize: '1.25rem',
                                    marginLeft: '0.5rem',
                                    fontWeight: '300',
                                    color: '#FFFFFFCC',
                                  }}
                                >
                                  {' '}
                                  +{overallRating?.ratingInfo?.rating3UsersLeft}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '0.5rem',
                              }}
                            >
                              {overallRating?.ratingInfo?.rating3Users.map((user, index) => {
                                return (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      marginRight: '0.2rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    {user.user.first_name !== ''
                                      ? user.user.first_name
                                      : user.user.email.split('@')[0]}
                                    {overallRating?.ratingInfo?.rating3UsersLeft !== 0
                                      ? separators[4][index]
                                      : separators[overallRating?.ratingInfo?.rating3Users.length][
                                          index
                                        ]}
                                  </p>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating3UsersLeft !== 0 && (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: '500',
                                    color: '#FFFFFF',
                                    textWrap: 'noWrap',
                                  }}
                                >
                                  and others
                                </p>
                              )}
                            </div>
                          </div>
                        }
                        {...tooltipProps}
                        placement="top"
                        arrow
                      >
                        <li
                          className={`three`}
                          style={{
                            width: `${fetchRatingPercentage(overallRating?.ratingInfo?.rating3)}%`,
                          }}
                        >
                          <span className="paragraph">3</span>
                        </li>
                      </TooltipCustomDash>
                      <TooltipCustomDash
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                              {overallRating?.ratingInfo?.rating2Users.map((user) => {
                                return (
                                  <Avatar
                                    style={{
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '0.20rem solid #FFF',
                                      marginLeft: '-0.5rem',
                                    }}
                                    alt=""
                                    src={user.user?.profile_pic}
                                  >
                                    {User.getInitial(user.user)}
                                  </Avatar>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating2UsersLeft > 0 && (
                                <p
                                  style={{
                                    fontSize: '1.25rem',
                                    marginLeft: '0.5rem',
                                    fontWeight: '300',
                                    color: '#FFFFFFCC',
                                  }}
                                >
                                  {' '}
                                  +{overallRating?.ratingInfo?.rating2UsersLeft}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '0.5rem',
                              }}
                            >
                              {overallRating?.ratingInfo?.rating2Users.map((user, index) => {
                                return (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      marginRight: '0.2rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    {user.user.first_name !== ''
                                      ? user.user.first_name
                                      : user.user.email.split('@')[0]}
                                    {overallRating?.ratingInfo?.rating2UsersLeft !== 0
                                      ? separators[4][index]
                                      : separators[overallRating?.ratingInfo?.rating2Users.length][
                                          index
                                        ]}
                                  </p>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating2UsersLeft !== 0 && (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: '500',
                                    color: '#FFFFFF',
                                    textWrap: 'noWrap',
                                  }}
                                >
                                  and others
                                </p>
                              )}
                            </div>
                          </div>
                        }
                        {...tooltipProps}
                        placement="top"
                        arrow
                      >
                        <li
                          className={`two`}
                          style={{
                            width: `${fetchRatingPercentage(overallRating?.ratingInfo?.rating2)}%`,
                          }}
                        >
                          <span className="paragraph">2</span>
                        </li>
                      </TooltipCustomDash>
                      <TooltipCustomDash
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '-1rem' }}>
                              {overallRating?.ratingInfo?.rating1Users.map((user) => {
                                return (
                                  <Avatar
                                    style={{
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '0.20rem solid #FFF',
                                      marginLeft: '-0.5rem',
                                    }}
                                    alt=""
                                    src={user.user?.profile_pic}
                                  >
                                    {User.getInitial(user.user)}
                                  </Avatar>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating1UsersLeft > 0 && (
                                <p
                                  style={{
                                    fontSize: '1.25rem',
                                    marginLeft: '0.5rem',
                                    fontWeight: '300',
                                    color: '#FFFFFFCC',
                                  }}
                                >
                                  {' '}
                                  +{overallRating?.ratingInfo?.rating1UsersLeft}
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '0.5rem',
                              }}
                            >
                              {overallRating?.ratingInfo?.rating1Users.map((user, index) => {
                                return (
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      marginRight: '0.2rem',
                                      fontWeight: '500',
                                      color: '#FFFFFF',
                                      textWrap: 'noWrap',
                                    }}
                                  >
                                    {user.user.first_name !== ''
                                      ? user.user.first_name
                                      : user.user.email.split('@')[0]}
                                    {overallRating?.ratingInfo?.rating1UsersLeft !== 0
                                      ? separators[4][index]
                                      : separators[overallRating?.ratingInfo?.rating1Users.length][
                                          index
                                        ]}
                                  </p>
                                );
                              })}
                              {overallRating?.ratingInfo?.rating1UsersLeft !== 0 && (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: '500',
                                    color: '#FFFFFF',
                                    textWrap: 'noWrap',
                                  }}
                                >
                                  and others
                                </p>
                              )}
                            </div>
                          </div>
                        }
                        {...tooltipProps}
                        placement="top"
                        arrow
                      >
                        <li
                          className={`one`}
                          style={{
                            width: `${fetchRatingPercentage(overallRating?.ratingInfo?.rating1)}%`,
                          }}
                        >
                          <span className="paragraph">1</span>
                        </li>
                      </TooltipCustomDash>
                    </ul>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </section>
  );
}

import React from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/subscriptions.module.scss';
import { imageBasePath } from '../../../constants';
import { useGetSubscriptionDetailsQuery } from '../../payment/Payment.queries';
import CancelSubscriptionModal from 'features/payment/components/PaymentButtons/CancelSubscription/CancelSubscriptionModal';
import PaymentCard from './PaymentCard/PaymentCard';
import ProductCard from './ProductCard/ProductCard';

const SubscriptionDetails = () => {
  const { data = {}, isLoading } = useGetSubscriptionDetailsQuery();
  const { payment_methods: payments = [], subscription = {}, product: products = [] } = data;

  return (
    <div>
      <div className={styles.paymentTitleWrapper}>
        <img src={imageBasePath + 'subscription_default2.png'} alt="" />
        <h4 className="heading4">Plans & Billing</h4>
      </div>
      <Grid container spacing={2}>
        {products?.length > 0 && <ProductCard product={products[0]} subscription={subscription} />}
        {payments
          ?.filter((card) => card?.default_payment_method === 1)
          ?.map((cardInfo, index) => (
            <PaymentCard cardInfo={cardInfo} index={index} payments={payments} />
          ))}
      </Grid>
      <br />
      {!isLoading && <CancelSubscriptionModal loading={isLoading} />}
    </div>
  );
};

export default SubscriptionDetails;

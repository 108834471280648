import React from 'react';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import styles from '../../Payment.module.scss';
import { Payment } from '../../../Payment.utils';
import ProductSelector from '../ProductSelector/ProductSelector';
import { usePaymentStore } from 'features/payment/Payment.store';
import { Subscription } from 'controllers/_exports';

const Introduction = ({ onNextPage = () => null, processType }) => {
  const selectedProduct = usePaymentStore((s) => s?.selectedProduct?.selectedPrice);
  const isFree = selectedProduct?.isFree;
  const proceedToCheckout = processType === 'upgrade' && (isFree || Subscription.hasPayment());

  return (
    <div>
      <ProductSelector />

      {!!selectedProduct && !isFree && processType === Payment.PROCESS_TYPE.NEW_SUBSCRIPTION && (
        <Alert style={{ marginTop: '1rem' }} severity="info">
          {Payment.getTrialText()}
        </Alert>
      )}

      {!!selectedProduct && (
        <div className={styles.nextButtonContainer}>
          <Button variant="contained" onClick={onNextPage}>
            Proceed to {isFree || proceedToCheckout ? 'checkout' : 'billing details'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Introduction;

import React from 'react';
import NotificationSettingsLogic from './NotificationSetting.logic';
import { Grid, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './NotificationSettings.css';

const NotificationSettings = ({ onGoBack = () => null, onNotificationsChange = () => null }) => {
  const { onToggleChange, notifications } = NotificationSettingsLogic({ onNotificationsChange });

  const GreenSwitch = withStyles({
    root: {
      cursor: 'pointer',
      width: 28,
      height: 16,
      padding: 0,
      overflow: 'visible',
    },
    switchBase: {
      padding: 2,
      color: '#FFF',
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#FFF',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#2DC38D',
          borderColor: '#2DC38D',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #BFBFBF`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#87A0B4',
    },
    checked: {},
  })(Switch);

  return (
    <Grid item xs={12}>
      <div>
        <ArrowBackIcon onClick={onGoBack} className="arrowBack" />
        <h3 className={`heading5`}>Notifications Preferences</h3>
      </div>

      {notifications.length > 0 &&
        notifications?.map(({ id, description, title, is_enabled, is_optional }) => {
          const isDisabled = !is_optional;

          return (
            <div key={id} className="notificationSetting">
              <span
                style={{ height: 'fit-content', opacity: isDisabled ? 0.4 : '' }}
                onClick={() => !isDisabled && onToggleChange(id)}
              >
                <GreenSwitch checked={is_enabled} disabled={isDisabled} />
              </span>
              <div>
                <span className="notificationTitle">{title}</span>
                <p className="notificationDescription">{description}</p>
                {isDisabled && (
                  <p className="notificationDisabled">This notification can't be disabled</p>
                )}
              </div>
            </div>
          );
        })}
    </Grid>
  );
};

export default NotificationSettings;

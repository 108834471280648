import { useState, useEffect } from 'react';
import {
  getModuleDetailsApi,
  traininglikeDislikeApi,
  markAsCompleteApi,
  markAsReadApi,
} from '../apiServices';
import { ModuleContentsLibrary, ModuleTitle, Shimmer } from './';
import { toastFlashMessage } from '../../../utils';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import styles from '../../../assets/styles/training.module.scss';
import { NoResult } from '../../../shared_ui_components';
import { useLearningPathsStore } from './LearningPaths/LearningPaths.store';

export default function UserModuleDetailContainer(props) {
  const { role } = props;
  let location = useLocation();
  let history = useHistory();
  let params = useParams();
  const [state, setState] = useState({
    contents: [],
    activeContent: {},
  });
  const [module, setModule] = useState({});
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState(0);
  const { contents, activeContent } = state;

  const fetchModuleDetail = (activeContent = '') => {
    if (activeContent) {
      setState((prevState) => ({
        ...prevState,
        activeContent: activeContent,
      }));
    }
    getModuleDetailsApi(params.id).then((response) => {
      if (response.statusCode == 200) {
        if (!response?.data?.published_at) history.push('/training');
        if (!activeContent) {
          setState((prevState) => ({
            ...prevState,
            activeContent: response.data?.contents[0],
          }));
        }
        setState((prevState) => ({
          ...prevState,
          contents: response.data?.contents,
        }));
        setLoader(false);
        setModule(response.data);
      }
    });
  };
  const handleChangeContent = (step) => {
    setState({
      ...state,
      activeContent: step,
    });
  };
  const updateActiveContent = (key, val) => {
    switch (key) {
      case 'comments':
        setState({
          ...state,
          activeContent: {
            ...state.activeContent,
            comments: val,
          },
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid ? { ...state.activeContent, comments: val } : step
          ),
        });
        return true;
      case 'likeDislike':
        let activeContentUpdated = {
          ...state.activeContent,
          comments: state.activeContent.comments.map((comment) =>
            comment.id === val.id ? val : comment
          ),
        };
        setState({
          ...state,
          activeContent: activeContentUpdated,
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid ? activeContentUpdated : step
          ),
        });
        return true;
      case 'delete':
        setState({
          ...state,
          activeContent: {
            ...state.activeContent,
            comments: state.activeContent.comments.filter(({ id }) => id !== val.id),
          },
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid
              ? {
                  ...state.activeContent,
                  comments: state.activeContent.comments.filter(({ id }) => id !== val.id),
                }
              : step
          ),
        });
        return true;
      default:
        return false;
    }
  };
  const handleLikeDislike = (val) => {
    traininglikeDislikeApi(activeContent.uuid, { like: val }).then((response) => {
      if (response.statusCode === 200) {
        setState({
          ...state,
          activeContent: response.data ? response.data : activeContent,
        });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  useEffect(() => {
    fetchModuleDetail();
  }, []);
  const handleMarkAsComplete = (contentType) => {
    let auxData = {};
    if (contentType === 1 && time === 0) auxData = { time_duration: 1 };
    else auxData = { time_duration: time };
    markAsCompleteApi(activeContent.uuid, auxData).then((response) => {
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        fetchModuleDetail(response.data);
        setShowModal(false);
      } else {
        toastFlashMessage(response.message, 'error');
        setShowModal(false);
      }
    });
  };
  const { setSelectedRepLearningPathID: setLP } = useLearningPathsStore();
  const handleGoBack = () => {
    const id = location?.state?.rep_lp_id || '';
    if (id) setLP(id);

    if (location.state && location.state.prevPath) {
      history.push(location.state.prevPath);
    } else {
      history.push('/training');
    }
  };

  const handleMarkAsRead = (content) => {
    markAsReadApi(content.uuid).then((response) => {
      if (response.statusCode == 200) {
        setState({
          ...state,
          activeContent: response.data ? response.data : activeContent,
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid ? response.data : step
          ),
        });
      }
    });
  };

  return (
    <>
      {loader ? (
        <Shimmer type="ModuleCard" />
      ) : (
        <>
          <ModuleTitle module={module} isAdmin={false} handleGoBack={handleGoBack} />
          {contents && contents.length ? (
            <ModuleContentsLibrary
              contents={contents}
              handleChangeContent={handleChangeContent}
              activeContent={activeContent}
              isAdmin={false}
              handleLikeDislike={handleLikeDislike}
              updateActiveContent={updateActiveContent}
              role={role}
              isPublished={module.published_at ? true : false}
              handleMarkAsComplete={handleMarkAsComplete}
              handleMarkAsRead={handleMarkAsRead}
              setShowModal={setShowModal}
              showModal={showModal}
              time={time}
              setTime={setTime}
            />
          ) : (
            <div className={styles.noStateWrapper}>
              <NoResult
                className="flexCentered"
                image={'no_content.svg'}
                title={
                  <p>
                    No content available in <span>{module.title}</span> Module
                  </p>
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

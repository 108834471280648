import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
// import {} from '../components';
import {
  CustomButton,
  CustomLink,
  ExternalLink,
  NoResult,
  InputText,
  ErrorMessage,
  InputSelect,
  ToastMessage,
  TextArea,
  // ProgressSlider,
} from '../../../shared_ui_components';

const options = [
  { value: 'test1', label: 'test1' },
  { value: 'test2', label: 'test2' },
  { value: 'test3', label: 'test3' },
];

export default class Test extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.homePage}>
        <section>
          <p className="paragraph">Salestable</p>
          <CustomButton className="primaryBtn " />
          <CustomButton className="primaryBtn disabledBtn" />
          <CustomButton className="secondaryBtn" />
          <CustomButton className="tertiaryBtn" />
          <CustomButton className="linkBtn" />
          <CustomLink className="backBtn" linkText="button" />
          <ExternalLink />

          <InputText
            id="email"
            label={`Work Email Address`}
            placeholder="eg. example@domain.com"
            // onChange=""
            // value=""
            // isError=""
          />
          <InputText
            id="email"
            label={`Work Email Address`}
            placeholder="eg. example@domain.com"
            className="disabledInputGroup"

            // onChange=""
            // value=""
            // isError=""
          />
          <TextArea placeholder="Maximum 4 rows" rowsMin={3} rowsMax={4} label="Message" />
          <InputSelect
            closeMenuOnSelect={true}
            label={`select`}
            options={options}
            isSearchable={true}
            placeholder={'eg. Marketing'}
            noOptionsMessage={() => 'No matches found'}
            // onChange={(selectedOption) =>
            //   handleChange('portfolio', selectedOption ? selectedOption : [])
            // }
            // value={productInfo.portfolio}
            // isCreatable={true}
            // isClearable
          />
          <ErrorMessage errorMsg="Validation Error Message" />
          <InputSelect />
          <ToastMessage type="success" message="lorem ipsum" />
        </section>
      </div>
    );
  }
}

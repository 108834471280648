import React, { Component } from 'react';
import {
  HomeIntroductionCard,
  AdminHomeContainer,
  UserHomeContainer,
  SalesLeadHomeContainer,
} from '../components';
import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import { timezoneUpdateApi } from '../apiServices';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openIntroduction: true,
      loader: false,
      role: this.props.role?.slug,
    };
    this.timezoneUpdateApi = timezoneUpdateApi.bind(this);
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo?.user?.timezone === 'UTC' || !userInfo.user?.timezone) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timezoneUpdateApi({ timezone: timezone });
    }
  }

  render() {
    const { openIntroduction } = this.state;
    const { role } = this.state;
    return (
      <div className={styles.homePage}>
        {role === 'user' ? null : <HomeIntroductionCard role={role} />}
        <section className={`${styles.homePageSection} ${role == 'user' ? styles.salesRep : ''}`}>
          {['admin', 'primary_admin'].includes(role) ? (
            <AdminHomeContainer />
          ) : role == 'user' ? (
            <UserHomeContainer />
          ) : role == 'sales_lead' ? (
            <SalesLeadHomeContainer />
          ) : null}
        </section>
      </div>
    );
  }
}

import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
// import { getChartCenterText } from '../utils'

const options = (total) => ({
  plugins: {
    tooltip: {
      enabled: total > 0,
      callbacks: {
        label: function (context) {
          return ` ${context?.label}: ${context?.formattedValue}%`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  cutout: 60,
  cutoutPercentage: 10,
  elements: {
    center: {
      text: 'Red is 2/3 of the total numbers',
      color: '#FF6384', // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      sidePadding: 20, // Default is 20 (as a percentage)
      minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
      lineHeight: 25, // Default is 25 (in px), used for when text wraps
    },
  },
});

export default function CustomDoughnutChart(props) {
  const { chartData = {}, direction = 'row', alignItems, page = 'onboarding', type = '' } = props;

  const total = useMemo(() => {
    if (page === 'training') return chartData?.progress?.total_published_modules;
    if (page === 'onboarding') return chartData?.progress?.onboarded_users_count;
    return chartData?.progress?.completed_count;
  }, [page, chartData?.progress]);

  const data = useMemo(() => {
    const fetchChartData = (chartData) => {
      if (page == 'onboarding') {
        if (chartData?.progress?.onboarded_users_count) {
          return [
            Math.round(
              (chartData?.progress?.completed_users_count /
                chartData.progress?.onboarded_users_count) *
                100
            ),
            Math.round(
              (chartData?.progress?.in_progress_users_count /
                chartData.progress?.onboarded_users_count) *
                100
            ),
            Math.round(
              (chartData?.progress?.yet_to_start_users_count /
                chartData.progress?.onboarded_users_count) *
                100
            ),
          ];
        } else {
          return [1];
        }
      } else if (page == 'training') {
        if (chartData?.progress?.total_published_modules) {
          return [
            Math.round(
              (chartData?.progress?.completed_count / chartData.progress?.total_published_modules) *
                100
            ),
            Math.round(
              (chartData?.progress?.in_progress_count /
                chartData.progress?.total_published_modules) *
                100
            ),
            Math.round(
              (chartData?.progress?.yet_to_start_count /
                chartData.progress?.total_published_modules) *
                100
            ),
          ];
        } else {
          return [1];
        }
      } else {
        if (chartData?.progress?.completed_count) {
          let total =
            chartData?.progress?.completed_count +
            chartData?.progress?.in_progress_count +
            chartData?.progress?.yet_to_start_count;
          return [
            Math.round((chartData?.progress?.completed_count / total) * 100),
            Math.round((chartData?.progress?.in_progress_count / total) * 100),
            Math.round((chartData?.progress?.yet_to_start_count / total) * 100),
          ];
        } else {
          return [1];
        }
      }
    };

    const colors = total === 0 ? ['#e3e3e3'] : ['#2DC38D', '#EB9B42', '#F6CB5C'];

    return {
      labels: ['Completed', 'In Progress', 'Yet to Start'],
      datasets: [
        {
          label: '',
          data: fetchChartData(chartData),
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    };
  }, [chartData, page, total]);

  return (
    <div className="customProgressChartWrapper">
      <Grid container direction={direction} spacing={0} alignItems={`center`}>
        <Grid item xs={12} md={7}>
          <Doughnut
            data={data}
            options={options(total)}
            // plugins={[getChartCenterText('48%')]}
          />
        </Grid>
        <Grid item xs={12} md={5} className="chartLegend">
          {chartData && type != 'dashboard' ? (
            <div className="overall-info">
              <h6 className="heading1">
                {page == 'onboarding'
                  ? chartData?.progress?.onboarded_users_count
                  : chartData?.progress
                  ? chartData?.progress?.completed_count +
                    chartData?.progress?.in_progress_count +
                    chartData?.progress?.yet_to_start_count
                  : '--'}
              </h6>
              <p className="paragraph">{chartData?.totalText}</p>
            </div>
          ) : null}
          <ul className="listUnstyled">
            <li className={`completed paragraph`}>
              Completed{' '}
              <strong>
                {page == 'onboarding'
                  ? chartData?.progress?.completed_users_count
                  : chartData?.progress?.completed_count}
              </strong>
            </li>
            <li className={`inProgress paragraph`}>
              In Progress{' '}
              <strong>
                {page == 'onboarding'
                  ? chartData?.progress?.in_progress_users_count
                  : chartData?.progress?.in_progress_count}
              </strong>
            </li>
            <li className={`yetToStart paragraph`}>
              Yet to Start{' '}
              <strong>
                {page == 'onboarding'
                  ? chartData?.progress?.yet_to_start_users_count
                  : chartData?.progress?.yet_to_start_count}
              </strong>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

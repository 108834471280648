import React, { useState, useMemo } from 'react';
import InputSelect from 'shared_ui_components/InputSelect';
import InputText from 'shared_ui_components/InputText';
import styles from './ScheduleTraining.module.scss';
import { optionsRecurring, optionsFrequency } from 'constants';
import Repetition from './Components/Repetition';
import Ocurrences from './Components/Ocurrences';
import Until from './Components/Until';
const ScheduleTraining = ({ untilDate = '', setUntilDate = () => null, formData, setFormData }) => {
  const [recurring, setRecurring] = useState(optionsRecurring[0]);
  const [frequency, setFrequency] = useState(optionsFrequency[0]);
  const [repetition, setRepetition] = useState('always');

  useMemo(() => {
    if (formData?.is_retake) {
      if (formData?.interval > 0) {
        setRecurring(
          optionsRecurring[optionsRecurring.find((e) => e.value === formData?.interval)?.value || 0]
        );
        if (formData.interval > 5) {
          setRecurring(optionsRecurring[6]);
          setFrequency(optionsFrequency[formData?.interval - 6]);
        }
      }
      if (formData?.number_of_retakes > 0) {
        setRepetition('times');
      } else {
        if (formData?.until_date) {
          setRepetition('until');
        }
      }
    }
  }, [formData]);
  if (!window.location.href.includes('training')) return null;

  return (
    <>
      <InputSelect
        label={`Recurring`}
        value={recurring}
        options={optionsRecurring}
        onChange={(value) => {
          setRecurring(value);
          if (value.value !== 'None') {
            setFormData({
              ...formData,
              interval: value.value,
              is_retake: true,
              number_of_retakes: -1,
            });
            if (value.value === 'Custom')
              setFormData({ ...formData, interval: 6, is_retake: true });
          } else {
            setFormData({ ...formData, interval: 0, is_retake: false });
          }
        }}
      />
      {recurring.value !== 'None' && (
        <>
          {recurring.value === 'Custom' && (
            <>
              <p className="supportText">Frequency</p>
              <div className={styles.frequencyInputs}>
                <div className={styles.frequencyInput}>
                  <InputText
                    type="number"
                    onChange={(e) => {
                      setFormData({ ...formData, frequency: parseInt(e.target.value) });
                    }}
                    value={formData?.frequency}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                <div className={styles.frequencyInput}>
                  <InputSelect
                    value={frequency}
                    options={optionsFrequency}
                    onChange={(e) => {
                      setFrequency(e);
                      setFormData({ ...formData, interval: e.value });
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <Repetition
            repetition={repetition}
            setRepetition={setRepetition}
            formData={formData}
            setFormData={setFormData}
          />
          {repetition === 'times' && <Ocurrences formData={formData} setFormData={setFormData} />}
          {repetition === 'until' && <Until formData={formData} setFormData={setFormData} />}
        </>
      )}
    </>
  );
};

export default ScheduleTraining;

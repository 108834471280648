import React from 'react';

const Icon = ({ src, color, width = 24, height = 24, withBackground = true }) => {
  return (
    <div
      style={
        withBackground
          ? {
              backgroundColor: `${color}1a`,
              borderRadius: '50%',
              aspectRatio: '1/1',
              padding: '1rem',
              display: 'grid',
            }
          : { display: 'grid' }
      }
    >
      <img src={src} alt={''} width={width} height={height} />
    </div>
  );
};

export default Icon;

import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid } from '@material-ui/core';
import { CustomDoughnutChart } from '../../../shared_elements';
import { CustomLink } from '../../../shared_ui_components';

export default function TrainingProgress(props) {
  const { training = {} } = props;
  return (
    <div className={`${styles.progressContentWrapper}`}>
      <OverviewTitle
        icon={`${imageBasePath}training_default.svg`}
        title={`Training`}
        moduleLink={'/training'}
      />
      <Grid
        container
        className={`${styles.trainingProgressContent} ${styles.defaultProgressContent}`}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <CustomDoughnutChart
            direction="column"
            page="training"
            type="dashboard"
            chartData={training}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.infoWrapper}>
            <h3 className="heading2">{training?.progress?.total_published_modules}</h3>
            <p className="paragraph">Total modules available</p>
          </div>
          <div className={`${styles.infoWrapper}`}>
            <h3 className="heading2">{training?.readiness_index}%</h3>
            <p className="paragraph">Your Sales Readiness Index</p>
          </div>
          {training.completed_moduels < training.total_modules ? (
            <div className={styles.btnBlk}>
              <p>
                <CustomLink
                  className={`primaryBtn`}
                  linktext={`Continue training`}
                  to="/training"
                />
              </p>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

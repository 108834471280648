import React, { Component } from 'react';
import { verifyOwnership } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';
import { NoResult } from '../../../shared_ui_components';
export default class AccessOwnership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      image: '',
      description: '',
      loader: false,
    };
    this.verifyOwnership = verifyOwnership.bind(this);
  }

  componentDidMount() {
    const querys = queryString.parse(this.props.location.search);
    const token = querys.token;
    if (token) {
      this.setState({ loader: true });
      verifyOwnership({ token }).then((response) => {
        if (response.statusCode === 200) {
          this.props.history.push({ search: '' });
          this.setState({
            image: 'success_accept.svg',
            message: 'Ownership transferred successfully!',
            description: "You've successfully accepted the ownership request.",
            loader: false,
          });
          toastFlashMessage(response.message, 'success');
        } else {
          this.setState({
            image: 'error_accept.svg',
            message: 'Ownership transfer request expired!',
            description:
              "You're ownership request has been expired or invalid. Please contact your current organization owner.",
            loader: false,
          });
          toastFlashMessage(response.message, 'error');
        }
        localStorage.clear();
      });
    } else {
      this.props.history.push('/');
      toastFlashMessage('Invalid Session', 'error');
    }
  }
  render() {
    const { message, loader, description, image } = this.state;
    return (
      <div className="pageNotFound">
        <NoResult
          image={image ? image : 'default_accept.svg'}
          title={loader ? 'Verifying Ownership Request...' : message}
          subtext={
            description ? description : "We're verifying your ownership request. Please wait.."
          }
          btnText="Login"
          link="/"
        />
      </div>
    );
  }
}

import styles from '../../../assets/styles/home.module.scss';
import { imageBasePath } from '../../../constants';
import OverviewTitle from './OverviewTitle';
import { Grid } from '@material-ui/core';
import { CustomDoughnutChart } from '../../../shared_elements';

export default function TrainingOverview(props) {
  const { training = {} } = props;
  return (
    <div className={styles.moduleOverview}>
      <OverviewTitle
        icon={`${imageBasePath}training_default.svg`}
        title={`Training`}
        moduleLink={'/training'}
        secondModuleLink={'/training?active=modules'}
      />
      <Grid
        container
        className={`${styles.onboardingOverviewWrapper} ${styles.defaultOverviewWrapper} ${styles.trainingOverviewWrapper}`}
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <div className={styles.infoWrapper}>
            <h3 className="heading2">{training?.progress?.total_published_modules}</h3>
            <p className="paragraph">Total modules available</p>
          </div>
          {/* <div className={`${styles.infoWrapper}`}>
            <h3 className="heading2">{Math.round(training?.average_overall_readiness_index)}%</h3>
            <p className="paragraph">Sales Readiness Index</p>
          </div> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <div className={styles.chartWrapper}>
            <CustomDoughnutChart
              direction="row-reverse"
              page="training"
              type="dashboard"
              chartData={training}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, List, ListItem, Menu, MenuItem } from '@material-ui/core';
import {
  InputSelect,
  CustomLink,
  CustomTargetValue,
  NoResult,
  CustomTooltipWhite,
} from '../../../shared_ui_components';
import { imageBasePath, checkinUnits, goalFrequencies } from '../../../constants';
import { diffInDays, CustomNumberFormatter, changeTimezone } from '../../../utils';
import styles from '../../../assets/styles/tracking.module.scss';
import { format, parseISO, startOfWeek, endOfWeek } from 'date-fns';
import queryString from 'query-string';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import { HubspotSubtleIcon } from 'features/hubspot/_exports';
import RefreshHubspotData from 'features/hubspot/components/RefreshHubspotData';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';

export default function CheckinHistory(props) {
  const { checkins, refetchCheckIns, latestCheckin, lastUpdate, hanldeEditCheckin } = props;
  const location = useLocation();
  const [salesGoals, salesGoalsSet] = useState({});
  const [frequency, frequencySet] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showLessons, setShowLessons] = useState(false);
  const [showPractises, setShowPractises] = useState(false);
  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(key);
  };

  useEffect(() => {
    if (props.currentCheckin && props.currentCheckin.length) {
      let salesGoals = props.currentCheckin.find((cat) => !cat.is_organisation);
      if (salesGoals) {
        salesGoalsSet(salesGoals);
        frequencySet(salesGoals.frequency);
      }
    }
  }, [props.currentCheckin]);

  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (Object.keys(querys).length && querys.openCheckin) {
      if (latestCheckin.checkin_exists) {
        props.chekcinOpenSet(true);
        hanldeEditCheckin(format(parseISO(latestCheckin.last_checkin), 'yyyy-MM-dd'), true);
      } else {
        props.chekcinOpenSet(true);
      }
    }
  }, [location.search]);

  const flexibleCheckIn = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg
    ?.flexible_checkin_windows;

  const enableEdit = (checkin_date) => {
    if (!flexibleCheckIn) return false;
    if (!checkin_date) return false;

    let todayFormatted = changeTimezone(new Date());
    let difDays = diffInDays(todayFormatted, parseISO(checkin_date));
    let difWeeks = differenceInWeeks(todayFormatted, parseISO(checkin_date)) + 1;
    switch (frequency) {
      case 1:
        return difDays <= 3 ? true : false;
      case 2:
        if (difWeeks == 1) {
          let weekIndex = diffInDays(
            todayFormatted,
            changeTimezone(startOfWeek(todayFormatted, { weekStartsOn: 1 }))
          );
          return weekIndex < 3 ? true : false;
        }
        return false;
      case 3:
        return difDays < 3 ? true : false;
      case 4:
        return difDays > 3 ? true : false;
      default:
        return false;
    }
    // true - show edit and false - hide edit
  };

  return (
    <>
      <section className={styles.checkinHistoryWrapper}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <div className={styles.checkinFilterWrapper}>
              {/* <InputSelect
                            className="reactSelect"
                            closeMenuOnSelect={true}
                            options={checkinFilterSelect}
                            isSearchable={false}
                            placeholder={"All"}
                            noOptionsMessage={() => "No matches found"}
                        /> */}
              <CustomTooltipWhite
                tooltipIcon="info_icon.svg"
                title={
                  <p className={styles.checkinTooltipContent + ' heading6 '}>
                    {' '}
                    Please submit your Check-in details for this{' '}
                    <span>
                      {goalFrequencies.find((item) => item.id === frequency)?.singleLable}.
                    </span>{' '}
                    You have a one day grace period to update your Check-in
                  </p>
                }
                className={styles.checkinTooltip}
                style={{ width: '350px;' }}
              />
              <CustomLink
                className={styles.checkinFilterBtn + ' primaryBtn '}
                linkimg={'goal_icon_white.svg'}
                linktext={latestCheckin.checkin_exists ? 'Edit Check-in' : 'Check-in'}
                onClick={
                  latestCheckin.checkin_exists
                    ? () => {
                        props.chekcinOpenSet(true);
                        hanldeEditCheckin(
                          format(
                            changeTimezone(new Date(latestCheckin.last_checkin)),
                            'yyyy-MM-dd'
                          ),
                          true
                        );
                      }
                    : () => {
                        props.chekcinOpenSet(true);
                      }
                }
              />
            </div>
          </Grid>
        </Grid>
        {Object(checkins) && Object.keys(checkins).length ? (
          <>
            <RefreshHubspotData
              align="end"
              lastUpdate={lastUpdate}
              id={Organization.getUserInfo()?.uuid}
              refetchCheckIns={refetchCheckIns}
              translate={false}
            />
            <Grid container direction="row" spacing={2} className={styles.checkinHistoryTitleRow}>
              <Grid item xs={12}>
                <div className={styles.checkinTitleWrapper}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className={styles.checkinHistoryTitleRowOuter}
                  >
                    <Grid item xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 2 : 5}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 12 : 5}>
                          <p className={styles.checkingHistoryTitle + ' heading6 '}>
                            Check-in Date
                          </p>
                        </Grid>
                        {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
                          <Grid item xs={7}>
                            <p className={styles.checkingHistoryTitle + ' heading6 '}>
                              Sales Goals
                            </p>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 10 : 7}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className={styles.checkinHistoryTitleRowInner}
                      >
                        <Grid item xs={7}>
                          <p className={styles.checkingHistoryTitle + ' heading6 '}>
                            Sales Activity
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            {Object.keys(checkins).map((checkin, key) => {
              const salesGoalsList = [
                ...checkins[checkin]?.sales?.sales_list,
                ...(checkins[checkin]?.hubspot?.hubspot_list.map((h) => ({
                  ...h,
                  goal: h?.activities_hubspot,
                  isFromHubspot: true,
                })) || []),
              ];
              return (
                <div key={checkin} className={styles.checkinBlkWrapper}>
                  <Grid container direction="row" spacing={2} className={styles.checkinBlkRow}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className={styles.checkinBlkRowOuter}
                      >
                        <Grid
                          item
                          xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 2 : 5}
                          className="flexNotCentered"
                        >
                          <Grid container direction="row" className={styles.checkinBlkRowOuter}>
                            <Grid
                              item
                              xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 12 : 5}
                              className="flexNotCentered"
                            >
                              <div className={styles.checkinDateWrapper}>
                                <p className={styles.checkingDate + ' heading6 '}>
                                  {format(parseISO(checkin), 'M/dd/yyyy')}
                                </p>
                                {/* <p className={styles.checkingDay + ' supportText '}>Today, 10AM</p> */}
                              </div>
                            </Grid>
                            {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
                              <Grid item xs={7}>
                                {checkins[checkin]?.organisation?.organisation_list &&
                                checkins[checkin]?.organisation?.organisation_list.length ? (
                                  <div className={styles.checkinOrganizationGoals}>
                                    {checkins[checkin].organisation.organisation_list.map(
                                      (list, key) => (
                                        <div
                                          key={key}
                                          className={styles.checkinOrganizationGoalsCountWrapper}
                                        >
                                          <img
                                            src={
                                              imageBasePath +
                                              checkinUnits.find(
                                                (unit) => unit.id === list.goal?.unit
                                              )?.img
                                            }
                                            className={styles.checkinOrganizationGoalsIcon}
                                          />
                                          <p
                                            className={
                                              styles.checkinOrganizationCountTitle +
                                              ' supportTextSemibold '
                                            }
                                          >
                                            {list?.goal?.name}
                                          </p>
                                          <p
                                            className={
                                              styles.checkinOrganizationCount + ' heading3 '
                                            }
                                          >
                                            {checkinUnits.find(
                                              (unit) => unit.id === list.goal?.unit
                                            )?.label +
                                              ' ' +
                                              CustomNumberFormatter(list.target)}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : null}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 10 : 7}
                          className="flexNotCentered"
                        >
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            className={styles.checkinBlkRowInner}
                          >
                            <Grid item xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 8 : 7}>
                              {salesGoalsList && salesGoalsList.length ? (
                                <div className={styles.checkinSalesGoalsWrapper}>
                                  {salesGoalsList.map((list, key) => (
                                    <div key={key} className={styles.checkinSalesGoals}>
                                      <Grid container direction="row" spacing={1}>
                                        <Grid item xs={6}>
                                          <div className={styles.checkinSalesGoalsBlk}>
                                            <img
                                              className={styles.checkinSalesGoalsBlkIcon}
                                              src={
                                                imageBasePath +
                                                checkinUnits.find(
                                                  (unit) => unit.id === list.goal?.unit
                                                )?.img
                                              }
                                              alt="image"
                                            />
                                            <p
                                              className={
                                                styles.checkinSalesGoalsBlkText + ' heading6 '
                                              }
                                            >
                                              <span style={{ marginRight: '0.5rem' }}>
                                                {list?.goal?.name}
                                              </span>
                                              {list?.isFromHubspot && (
                                                <HubspotSubtleIcon size={16} margin={false} />
                                              )}
                                            </p>
                                          </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <div className={styles.checkinSalesGoalsBlkRight}>
                                            <div
                                              className={styles.checkinSalesGoalsBlkRightContent}
                                            >
                                              <List className="listInline" disablePadding={true}>
                                                <ListItem
                                                  className={
                                                    styles.customlistInlineItem + ' listInlineItem '
                                                  }
                                                  disableGutters={true}
                                                >
                                                  <p
                                                    className={
                                                      styles.checkinSalesGoalsBlkRightCount +
                                                      ' heading4 '
                                                    }
                                                  >
                                                    {' '}
                                                    {CustomNumberFormatter(list.target)}
                                                  </p>
                                                </ListItem>
                                                <ListItem
                                                  className={
                                                    styles.customlistInlineItem + ' listInlineItem '
                                                  }
                                                  disableGutters={true}
                                                >
                                                  <CustomTargetValue
                                                    unit={list.goal?.unit}
                                                    value={list.expected_target}
                                                  />
                                                </ListItem>
                                              </List>
                                              {/* <p className={styles.checkinSalesGoalsBlkRightCount + ' heading4 '}>{list.target}</p>
                                                                                                  <CustomTargetValue unit={list.goal?.unit} value={list.goal?.target} /> */}
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 4 : 5}>
                              <div className={styles.checkinLesson}>
                                <List className={styles.checkinLessonList} disablePadding>
                                  {checkins[checkin]?.sales?.lessons_learned ? (
                                    <ListItem className={styles.checkinLessonListItem}>
                                      <p
                                        className={
                                          styles.checkinLessonListHeading + ' supportText '
                                        }
                                      >
                                        Lesson Learned
                                      </p>
                                      {/* {console.log(checkins[checkin]?.sales?.lessons_learned?.length)} */}
                                      {showLessons ? (
                                        <p
                                          className={
                                            styles.checkinLessonListContent + ' supportText '
                                          }
                                        >
                                          {checkins[checkin]?.sales?.lessons_learned}{' '}
                                        </p>
                                      ) : (
                                        <p
                                          className={
                                            styles.checkinLessonListContent + ' supportText '
                                          }
                                        >
                                          {checkins[checkin]?.sales?.lessons_learned.substring(
                                            0,
                                            119
                                          )}{' '}
                                          <span>
                                            {checkins[checkin]?.sales?.lessons_learned.length > 119
                                              ? '...'
                                              : null}
                                          </span>
                                        </p>
                                      )}
                                      {checkins[checkin]?.sales?.lessons_learned.length > 120 ? (
                                        <div
                                          className={
                                            styles.viewMore +
                                            '  ' +
                                            `${showLessons ? styles.viewMoreClear : ' '}`
                                          }
                                        >
                                          <CustomLink
                                            className="linkBtn"
                                            linktext={showLessons ? 'View Less' : 'View More'}
                                            onClick={() => setShowLessons(!showLessons)}
                                          />
                                        </div>
                                      ) : null}
                                    </ListItem>
                                  ) : null}
                                  {checkins[checkin]?.sales?.best_practices ? (
                                    <ListItem className={styles.checkinLessonListItem}>
                                      <p
                                        className={
                                          styles.checkinLessonListHeading + ' supportText '
                                        }
                                      >
                                        Best practices
                                      </p>
                                      {showPractises ? (
                                        <p
                                          className={
                                            styles.checkinLessonListContent + ' supportText '
                                          }
                                        >
                                          {checkins[checkin]?.sales?.best_practices}{' '}
                                        </p>
                                      ) : (
                                        <p
                                          className={
                                            styles.checkinLessonListContent + ' supportText '
                                          }
                                        >
                                          {checkins[checkin]?.sales?.best_practices.substring(
                                            0,
                                            119
                                          )}{' '}
                                          <span>
                                            {checkins[checkin]?.sales?.best_practices.length > 119
                                              ? '...'
                                              : null}
                                          </span>
                                        </p>
                                      )}
                                      {checkins[checkin]?.sales?.best_practices.length > 120 ? (
                                        <div
                                          className={
                                            styles.viewMore +
                                            '  ' +
                                            `${showPractises ? styles.viewMoreClear : ' '}`
                                          }
                                        >
                                          <CustomLink
                                            className="linkBtn"
                                            linktext={showPractises ? 'View Less' : 'View More'}
                                            onClick={() => setShowPractises(!showPractises)}
                                          />
                                        </div>
                                      ) : null}
                                    </ListItem>
                                  ) : null}
                                </List>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {enableEdit(checkins[checkin]?.sales?.date) ? (
                      <div className={styles.checkinMoreActionWrapper}>
                        <a
                          aria-controls={'simple-menu-' + key}
                          className={'more'}
                          aria-haspopup="true"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClick(e, key);
                          }}
                        >
                          <img
                            src={imageBasePath + 'more_vertical_gray.svg'}
                            className={styles.checkinMoreAction}
                          />
                        </a>
                        <Menu
                          id={'simple-menu-' + key}
                          anchorEl={anchorEl}
                          keepMounted
                          open={currentIndex == key ? true : false}
                          onClose={(e) => {
                            setAnchorEl(null);
                            setCurrentIndex(null);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          className="moreMenu"
                        >
                          <MenuItem
                            onClick={(e) => {
                              setAnchorEl(null);
                              setCurrentIndex(null);
                              hanldeEditCheckin(checkin);
                            }}
                          >
                            <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                            <span className="supportText">Edit</span>
                          </MenuItem>
                        </Menu>
                      </div>
                    ) : null}
                  </Grid>
                </div>
              );
            })}
          </>
        ) : (
          <div className={styles.noCheckinResult}>
            <NoResult
              image="no_checkin.svg"
              title="No check-in yet"
              subtext="Seems you haven’t done your checkin for this week/month yet."
            />
          </div>
        )}
      </section>
    </>
  );
}

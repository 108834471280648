import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import Flag from '../Other/Flag';
import { Country, State } from 'country-state-city';
import 'react-phone-input-2/lib/material.css';
import styles from '../../Payment.module.scss';
import { usePaymentStore } from '../../../Payment.store';

const countries = Country.getAllCountries();

const BillingAddress = ({ onNextPage = () => null }) => {
  const set_address = usePaymentStore((state) => state?.setBillingAddress);

  const [billingAddress, setBillingAddress] = useState({
    country: '',
    state: '',
    city: '',
    line1: '',
    //
    line2: '',
  });

  const onInputChange = (e) =>
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });

  const disabled = useMemo(() => {
    return (
      !billingAddress.country ||
      !billingAddress.state ||
      !billingAddress.city?.trim() ||
      !billingAddress.line1?.trim()
    );
  }, [billingAddress]);

  const saveBillingAddress = (e) => {
    e.preventDefault();
    if (disabled) return;

    set_address(billingAddress);
    onNextPage();
  };

  // Cities
  const [states, setStates] = useState([]);
  useEffect(() => {
    setBillingAddress({ ...billingAddress, state: '' });
    setStates(State.getStatesOfCountry(billingAddress.country));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress.country]);

  const stateInputType = useMemo(() => {
    if (billingAddress.country && !states?.length) return 'text';
    return 'select';
  }, [billingAddress.country, states]);

  useEffect(() => {
    setBillingAddress({ ...billingAddress, city: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress.state]);

  return (
    <form className={styles.billingForm} onSubmit={saveBillingAddress}>
      <Grid container direction="row">
        <Grid item xs={10}>
          <FormControl style={{ width: '100%' }} variant="outlined" size="small" required>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              onChange={onInputChange}
              label="Country"
              name="country"
              value={billingAddress.country}
            >
              {countries.map(({ isoCode, name, flag }, key) => (
                <MenuItem value={isoCode} key={key}>
                  <span
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingRight: '2rem',
                    }}
                  >
                    {name}
                    {isoCode !== billingAddress.country && <span>{flag}</span>}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {billingAddress?.country && <Flag big country={billingAddress?.country} />}
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          {stateInputType === 'select' && (
            <FormControl
              style={{ width: '100%' }}
              variant="outlined"
              size="small"
              disabled={!billingAddress.country}
              required
            >
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                onChange={onInputChange}
                label="State"
                name="state"
                value={billingAddress.state}
              >
                {states.map(({ name }, key) => (
                  <MenuItem key={key} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {stateInputType === 'text' && (
            <TextField
              size="small"
              label="State"
              name="state"
              value={billingAddress.state}
              onChange={onInputChange}
              variant="outlined"
              required
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={!billingAddress.state}
            size="small"
            label="City"
            name="city"
            value={billingAddress.city}
            onChange={onInputChange}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <TextField
        size="small"
        label="Address Line 1"
        name="line1"
        value={billingAddress.line1}
        onChange={onInputChange}
        variant="outlined"
        required
      />

      <TextField
        size="small"
        label="Address Line 2 (Optional)"
        name="line2"
        value={billingAddress.line2}
        onChange={onInputChange}
        variant="outlined"
      />

      <div className={styles.nextButtonContainer}>
        <Button type="submit" variant="contained">
          Save and proceed to payment
        </Button>
      </div>
    </form>
  );
};

export default BillingAddress;

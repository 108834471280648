import { useState, useEffect } from 'react';
import {
  getModuleDetailsApi,
  deleteContentpApi,
  publishModuleApi,
  traininglikeDislikeApi,
  reorderTrainingApi,
} from '../apiServices';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { ModuleTitle, Shimmer } from '../components';
import { imageBasePath } from '../../../constants';
import { toastFlashMessage } from '../../../utils';
import { ConfirmationDialog, SuccessDialog } from '../../../shared_elements';
import { AddDocument } from '../../onboarding/components';
import { ModuleContentsLibrary } from './';
import styles from '../../../assets/styles/training.module.scss';
import { NoResult } from '../../../shared_ui_components';
import { useRecorderStore } from '../../../shared_ui_components/VideoRecorder/VideoRecorder.store';
import { FeatureFlag } from 'controllers/_exports';

export default function AdminModuleDetailContainer(props) {
  const { role } = props;
  let params = useParams();
  let location = useLocation();
  let history = useHistory();

  const [state, setState] = useState({
    contents: [],
    activeContent: {},
  });
  const [module, setModule] = useState({});
  const [loader, setLoader] = useState(true);
  const [addContent, setAddContent] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteContent, setDeleteContent] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [publishSusccess, setPublishSuccess] = useState(false);

  const { contents, activeContent } = state;

  const fetchModuleDetail = (activeContent = '') => {
    if (activeContent) {
      setState((prevState) => ({
        ...prevState,
        activeContent: activeContent,
      }));
    }
    if (state.contents && state.contents?.length == 0 && activeContent) {
      setState((prevState) => ({
        ...prevState,
        contents: [activeContent],
      }));
    }
    getModuleDetailsApi(params.id).then((response) => {
      if (response.statusCode == 200) {
        setLoader(false);
        if (!activeContent) {
          setState((prevState) => ({
            ...prevState,
            activeContent:
              response.data?.contents && response.data?.contents.length
                ? response.data?.contents[0]
                : '',
          }));
        }
        setState((prevState) => ({
          ...prevState,
          contents: response.data?.contents,
        }));
        setModule(response.data);
        if (response.data?.contents?.length == 0) {
          setAddContent(true);
        }
      }
    });
  };
  useEffect(() => {
    fetchModuleDetail();
  }, []);

  useEffect(() => {
    if (location.state && location.state.addContent && module.uuid) {
      setAddContent(true);
      history.push({
        pathname: location.pathname,
        state: { ...location.state, addContent: false },
      });
    }
  }, [module?.uuid]);

  const handleAddContent = () => {
    setAddContent(true);
  };

  // Handle automatic open after video recording
  const recorded_file = useRecorderStore((s) => s.video);

  useEffect(() => {
    if (recorded_file) handleAddContent();
  }, [recorded_file]);

  const handleChangeContent = (step) => {
    setState({
      ...state,
      activeContent: step,
    });
  };
  const handleEnabelDisable = () => {};

  const handleReorderTraining = (contents_id) => {
    let uuid = params.id;
    reorderTrainingApi(uuid, { contents_id }).then((response) => {
      if (response.statusCode === 200) {
        const { contents } = response.data;
        setState({ ...state, contents });
      }
    });
  };
  const handlePublishModule = () => {
    setPublishLoader(true);
    publishModuleApi(params.id).then((response) => {
      setPublishLoader(false);
      if (response.statusCode == 200) {
        fetchModuleDetail(activeContent);
        toastFlashMessage(response.message, 'success');
        setPublishSuccess(true);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleDeleteContent = () => {
    setDeleteLoader(true);
    deleteContentpApi(activeContent.uuid).then((response) => {
      setDeleteLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        fetchModuleDetail();
        setTimeout(() => {
          setDeleteContent(false);
        }, 500);
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const updateActiveContent = (key, val) => {
    switch (key) {
      case 'comments':
        setState({
          ...state,
          activeContent: {
            ...state.activeContent,
            comments: val,
          },
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid ? { ...state.activeContent, comments: val } : step
          ),
        });
        return true;
      case 'likeDislike':
        let activeContentUpdated = {
          ...state.activeContent,
          comments: state.activeContent.comments.map((comment) =>
            comment.id === val.id ? val : comment
          ),
        };
        setState({
          ...state,
          activeContent: activeContentUpdated,
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid ? activeContentUpdated : step
          ),
        });
        return true;
      case 'delete':
        setState({
          ...state,
          activeContent: {
            ...state.activeContent,
            comments: state.activeContent.comments.filter(({ id }) => id !== val.id),
          },
          contents: state.contents.map((step) =>
            step.uuid === activeContent.uuid
              ? {
                  ...state.activeContent,
                  comments: state.activeContent.comments.filter(({ id }) => id !== val.id),
                }
              : step
          ),
        });
        return true;
      default:
        return false;
    }
  };
  const handleEditContent = () => {
    setEditContent(true);
  };
  const returnFormData = (activeContent = {}) => {
    const formData = {
      name: activeContent?.name ? activeContent.name : '',
      description: activeContent?.description ? activeContent.description : '',
      tags:
        activeContent?.tags && activeContent?.tags.length
          ? activeContent.tags.map((tag) => {
              return { label: tag.name, value: tag.name };
            })
          : [],
      is_required: activeContent.is_required === false ? false : true,
      content: activeContent?.content ? activeContent.content : '',
      input_type: activeContent?.input_type ? activeContent.input_type : 1,
      content_type: activeContent?.content_type ? activeContent?.content_type : 0,
      is_retake: activeContent?.is_retake ? activeContent?.is_retake : false,
      interval: activeContent?.interval ? activeContent?.interval : 0,
      frequency: activeContent?.frequency ? activeContent?.frequency : 0,
      number_of_retakes: activeContent?.number_of_retakes ? activeContent?.number_of_retakes : -1,
      until_date: activeContent?.until_date ? activeContent?.until_date : null,
      suggest_duration: activeContent?.suggest_duration ? activeContent?.suggest_duration : 300,
    };
    return formData;
  };
  const handleLikeDislike = (val) => {
    traininglikeDislikeApi(activeContent.uuid, { like: val }).then((response) => {
      if (response.statusCode === 200) {
        setState({
          ...state,
          activeContent: response.data ? response.data : activeContent,
        });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleGoBack = () => {
    if (location.state && location.state.prevPath) {
      history.push(location.state.prevPath);
    } else {
      history.push('/training');
    }
  };
  return (
    <>
      {loader ? (
        <Shimmer type="ModuleCard" />
      ) : (
        <>
          <ModuleTitle
            module={module}
            isAdmin={true}
            handlePublish={handlePublishModule}
            loader={publishLoader}
            handleGoBack={handleGoBack}
          />
          {contents && contents.length && activeContent ? (
            <ModuleContentsLibrary
              contents={contents}
              handleChangeContent={handleChangeContent}
              activeContent={activeContent}
              handleAddContent={handleAddContent}
              handleDeleteContent={() => setDeleteContent(true)}
              handleEditContent={handleEditContent}
              handleReorderTraining={handleReorderTraining}
              handleEnabelDisable={handleEnabelDisable}
              isAdmin={true}
              handleLikeDislike={handleLikeDislike}
              updateActiveContent={updateActiveContent}
              role={role}
              isPublished={module.published_at ? true : false}
            />
          ) : (
            <div className={styles.noStateWrapper}>
              <NoResult
                className="flexCentered"
                image={'no_content.svg'}
                title={
                  <p>
                    You must add content to your <span>{module.title}</span> Module!
                  </p>
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAddContent();
                }}
                btnText={'+ Add Content'}
              />
            </div>
          )}
        </>
      )}
      {addContent || editContent ? (
        <AddDocument
          id={addContent ? params.id : editContent ? activeContent.uuid : ''}
          open={true}
          handleClose={() => {
            setAddContent(false);
            setEditContent(false);
          }}
          type={addContent ? 'addContent' : editContent ? 'editContent' : ''}
          formData={
            addContent ? returnFormData() : editContent ? returnFormData(activeContent) : {}
          }
          handleUpdateList={(updatedStep) => fetchModuleDetail(updatedStep)}
          page="training"
          moduleTitle={{ title: module.title, description: module.description }}
        />
      ) : null}
      <ConfirmationDialog
        open={deleteContent}
        title={
          contents.length > 1 ? (
            <>
              Are you sure you want to delete the content <br />{' '}
              <strong className="heading3">{`${activeContent?.name}?`}</strong>
            </>
          ) : (
            <>
              Are you sure you want to delete the content <br />{' '}
              <strong className="heading3">{`${activeContent?.name}?`}</strong>
            </>
          )
        }
        description={
          FeatureFlag.enabled('ENABLE_MODULES')
            ? contents.length > 1
              ? "This action can't be undone once its deleted"
              : 'This is the last content of your module and if you delete the last content, your module will be disabled'
            : "This action can't be undone once its deleted"
        }
        acceptText={
          FeatureFlag.enabled('ENABLE_MODULES')
            ? contents.length > 1
              ? 'Yes, Delete'
              : 'Delete & Disable'
            : 'Yes, Delete'
        }
        rejectText="No, Cancel"
        isDelete={true}
        image={imageBasePath + 'delete_confirmation.svg'}
        handleClose={() => {
          setDeleteContent(false);
          setDeleteLoader(false);
        }}
        handleSubmit={handleDeleteContent}
        loader={deleteLoader}
      />
      <SuccessDialog
        open={publishSusccess}
        image={`module_added_success.svg`}
        title={
          <>
            The <strong>“{module.title}”</strong> module has been <br /> Published
          </>
        }
        description={`Any additional content that is added to this module will be auto-published unless it's disabled`}
        continueText={`+ Add Content`}
        acceptText={`Setup Modules`}
        handleSubmit={() => handleGoBack()}
        handleClose={() => setPublishSuccess(false)}
        handleContinue={() => {
          setPublishSuccess(false);
          setAddContent(true);
        }}
      />
    </>
  );
}

import React, { useEffect, useMemo, useState } from 'react';

import { reactSelectStyles } from 'constants/index';
import { useGetPostCategoriesQuery } from 'features/stream/Stream.queries';
import StreamInputSelect from '../StreamInputSelect/StreamInputSelect';

const CategoryFilter = ({ onChange = () => null, label = 'Filter', isFilter = true }) => {
  const [color, setColor] = useState('');
  const { data } = useGetPostCategoriesQuery();

  const categories = useMemo(() => {
    const options =
      data?.map((category) => ({
        value: category.id,
        id: category.uuid,
        label: category.name,
        icon: category.picture_icon,
        iconColor: category.color_code,
      })) || [];

    if (isFilter) options?.unshift({ label: 'All categories', value: null, hideIcon: true });

    return options;
  }, [data, isFilter]);

  useEffect(() => {
    if (categories.length > 0) {
      setColor(categories[0].iconColor);
      onChange(categories[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleChange = (category) => {
    const selected = categories?.find((c) => c?.value === category);
    setColor(selected?.iconColor);
    onChange(selected);
  };

  return (
    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      <div style={isFilter ? {} : { color: '#87939a' }}>{label}</div>

      {categories?.length > 0 && (
        <StreamInputSelect
          containerClassName="stream-category-filter"
          options={categories}
          isSearchable={false}
          className={`${isFilter ? '' : 'withBorders'}`}
          size="small"
          onChange={({ value }) => handleChange(value)}
          defaultValue={categories[0]}
          styles={
            isFilter
              ? { ...reactSelectStyles({}) }
              : {
                  ...reactSelectStyles({}),
                  singleValue: (styles) => ({
                    ...styles,
                    color,
                    border: `1px solid ${color}`,
                  }),
                }
          }
        />
      )}
    </div>
  );
};

export default CategoryFilter;
